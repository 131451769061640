import { Box, Divider } from '@mui/material';
import { FC } from 'react';
import { SubSectionNamePropsType } from '../../../configs/interfaces';
export const SubSectionName: FC<SubSectionNamePropsType> = ({ title, noDivider = false }) => {
  return (
    <>
      <Box display="flex" alignItems="center" className="subSection__heading--bold" pb={3}>
        {title}
      </Box>
      {noDivider ? null : <Divider />}
    </>
  );
};
