export const validationSmartHandler = (structure, state) => {
  let errorArray: Array<string> = [];
  let titleErrorArray: Array<string> = [];

  if (Array.isArray(structure)) {
    structure = sanitizingMandatoryFields(structure);

    const res = validationHandler(structure, state, errorArray);
    errorArray = res.errorArray;
  }
  return errorArray;
};

export const sanitizingMandatoryFields = (structure) => {
  structure = structure.filter((field) => field?.render?.required);
  return structure;
};

export const validationHandler = (structure, state, errorArray, titleErrorArray: string[] = []) => {
  structure.forEach((data) => {
    if (state.hasOwnProperty(data.dataKey)) {
      validValueFilter(state[data.dataKey], errorArray, data.dataKey, data.title, titleErrorArray);
    } else {
      errorArray.push(data.dataKey);
      titleErrorArray.push(data.title);
    }
  });

  return {
    errorArray,
    titleErrorArray,
  };
};

export const validValueFilter = (value, errorArray, key, title = "", titleErrorArray: string[] = []) => {
  if (value === "" || value == null || value == undefined) {
    errorArray.push(key);
    titleErrorArray.push(title);
  }
};
