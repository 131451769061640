import {
    Divider,
    ListItem,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { loadSystemUsers } from "../../../../../../services/userService";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const UserSelectionModal = ({ applicationId, callBackOnAction }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const [selectionList, setSelectionList] = React.useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async (force = false) => {
        try {
            setIsLoading(true);
            const users = await loadSystemUsers();
            setSelectionList(users);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Stack flex={1} alignItems={"center"}>
                <Paper elevation={0}>
                    <CircularProgressComponent size={30} />
                </Paper>
            </Stack>
        );
    }

    const buildName = (u) => {
        let name = u.NAME ?? u.name ?? u.USERNAME ?? "N/A";
        name = name.replaceAll("undefined", "").replaceAll("null", "");
        if (!name) {
            name = u.USER_ID;
        }
        return name;
    };

    return (
        <Stack spacing={2}>
            <TextBoxComponent placeholder={"Search User"} onChange={(e) => setSearchText(e.target.value)}></TextBoxComponent>
            <Divider></Divider>
            <Stack width={"100%"} overflow={"auto"} height={"200px"}>
                {selectionList.filter(item => buildName(item).toLowerCase().includes(searchText.toLowerCase())).map((item: any) => (
                    <ListItem divider key={""}>
                        <Stack
                            key={""}
                            direction="row"
                            width={"100%"}
                            style={{ cursor: "pointer" }}
                            justifyContent={"space-between"}
                            onClick={(e: any) => {
                                callBackOnAction(item.USER_ID);
                            }}
                        >

                            <Typography>
                                {buildName(item)}
                            </Typography>
                            <Typography color={"GrayText"} variant={"caption"}>
                                {item.USER_ID}
                            </Typography>
                        </Stack>
                    </ListItem>
                ))}
            </Stack>
            <Divider></Divider>
            <Stack direction={"row-reverse"}>
                <ButtonComponent title={"Close"} onClick={closeGlobalModal}></ButtonComponent>
            </Stack>
        </Stack>
    );
};

export default UserSelectionModal;
