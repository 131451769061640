import { Downgraded } from "@hookstate/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import EditAttributesIcon from "@mui/icons-material/ModeEditRounded";
import PendingIcon from "@mui/icons-material/Pending";
import PreviewIcon from "@mui/icons-material/Preview";
import SearchIcon from "@mui/icons-material/Search";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useMemo, useState } from "react";
import ReactLoading from "react-loading";
import { bufferToBlobUrl } from "../../../configs/base64Conversion";
import { SectionComponentPropsTypes } from "../../../configs/interfaces";
import { applicationFormErrorSectionIndexState, userDetails } from "../../../configs/mainStore";
import { panelExpandIconBox } from "../../../styles";
import AppConfig from "../../../utility/AppConfig";
import { addToaster } from "../GlobalToast";
import { NavigationButtons } from "../NavigationButtons/NavigationButtons";

export const MainSectionsWrapper: FC<SectionComponentPropsTypes> = ({ title, subTitle = "", children, onBack, onNext, noNext, noBack, keyCode, onExpand, noBtn, status, errorMessage, icon, isSectionExpand = false, disabled = false, id, nextLoading, expandLoading, showStatus = true, showSubText = true, decisionState = null, showSearchIcon, file, handleAdvanceEdit = () => { }, isDocument = false }) => {
  const [Visibility, setVisibility] = useState(false);
  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  useEffect(() => {
    setVisibility(isSectionExpand);
  }, [isSectionExpand]);

  let hasPermission = useMemo(() => {
    let permission: boolean = false;

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin" && AppConfig.control) {
          permission = true
        }
      }
    }

    return permission
  }, [userInGroups])

  const handleExpand = () => {
    applicationFormErrorSectionIndexState.set(0);
    onExpand && onExpand();
    setVisibility(true);
  };

  const handleClick = () => {
    let message: any = "";

    try {
      message = errorMessage.attach(Downgraded).get();
    } catch (error) {
      message = errorMessage;
    }

    try {
      addToaster({
        status: status,
        title: title,
        message: message,
        manualClosing: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const previewBtnClicked = (docObj) => {
    if (docObj) {
      const fileBuffer = docObj?.fileData.data;
      const contentType = docObj?.contentType;

      const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

      window.open(
        `${blobUrl}`,
        "targetWindow",
        `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=500,
           height=500`
      );
    }
  };

  return (
    <Stack
      className={`${Visibility ? "template__panel--show" : "template__panel--hide"}`}
      key={keyCode}
      id={id}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Box mr={2}>{showStatus === true ? status ? status === "success" ?
          <CheckCircleIcon sx={{ color: "#00AB71" }} /> : status === "" ?
            <PendingIcon sx={{ color: "#FFC107" }} /> : status === null ?
              <PendingIcon sx={{ color: "#FFC107" }} /> : status === "error" ?
                <InfoIcon sx={{ color: "#FF0000" }} onClick={isDocument === false ? handleClick : undefined} /> :
                <PendingIcon sx={{ color: "#ffdd00" }} /> : icon ? <CheckCircleIcon sx={{ color: "#00AB71" }} /> :
          <PendingIcon sx={{ color: "#ffdd00" }} /> : showSearchIcon === true ? <SearchIcon /> : <></>}</Box>
        <Box
          sx={{
            width: expandLoading ? "70%" : "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box className="mainSection__heading--bold" id={"jbtitle"}>{title}</Box>
            <Box>{showSubText && <small className="mainSection__description">{subTitle ? subTitle : ""}</small>}</Box>
          </Box>
        </Box>

        {decisionState !== null && (
          <Box mr={2}>
            <Stack className={`basic-font font-size-14 label ${decisionState === true ? "enable-label" : "disable-label"}`} style={{ textAlign: "center" }}>
              {decisionState === true ? "Pass" : "Fail"}
            </Stack>
          </Box>
        )}

        {expandLoading ? (
          <Box display="flex" alignItems="center" sx={{ marginLeft: "auto" }}>
            <Box mr={2} className="screen_description--basic">
              Preparing Your Changes
            </Box>
            <Box>
              <ReactLoading type="spin" color="#00AB71" height="30px" width="30px" />
            </Box>
          </Box>
        ) : (
          <>
            {hasPermission && <EditAttributesIcon onClick={handleAdvanceEdit} fontSize="small" />}
            {!disabled && (
              <Box sx={panelExpandIconBox} display={"flex"} alignItems={"center"}>
                {file?.fileData !== undefined && (
                  <Stack>
                    <PreviewIcon
                      onClick={() => {
                        previewBtnClicked(file);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Stack>
                )}
                {Visibility ? (
                  <ExpandLessIcon onClick={() => setVisibility(false)} fontSize="large" />
                ) : (
                  <ExpandMoreIcon onClick={handleExpand} fontSize="large" />
                )}
              </Box>
            )}
          </>
        )}
      </Box>

      {/* //Section */}
      <Stack className={nextLoading ? "disabled-container" : "mainSection_content--background"} pt={2}>{children}</Stack>

      {/* //Navigation Buttons */}
      {!noBtn && (
        <Box mt={2}>
          {/* No next button appear */}
          {noNext && <NavigationButtons onBack={onBack} noNext />}

          {/* No back button appear */}
          {noBack && <NavigationButtons onNext={onNext} noBack loading={nextLoading} />}

          {!noBack && !noNext && <NavigationButtons onNext={onNext} onBack={onBack} loading={nextLoading} noBack disabled={disabled} />}
        </Box>
      )}
    </Stack>
  );
};

export const MainSectionsPreviewWrapper = ({ title, subTitle = "", children, status, code, isSectionExpand = false, openFile = () => { } }) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(isSectionExpand);
  }, [isSectionExpand]);

  const BuildIndex = () => {
    let color = "#FFC107";

    if (status === "success") color = "#00AB71";

    if (status === "error") color = "#FF0000";

    return <Box sx={{ bgcolor: color, width: "30px", height: "30px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}> <Typography>{code + 1}</Typography> </Box>;
  }

  return (
    <Stack
      mb={2}
      key={code}
      style={{ boxShadow: "0px 10px 10px #747a880d", paddingBottom: "10px" }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <BuildIndex />

          <Stack>
            <Typography variant="subtitle1" color={"#6f6f6f"}>{title}</Typography>
            <Typography variant="caption" color={"gray"}>{title}</Typography>
          </Stack>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} spacing={2} style={{ cursor: "pointer" }}>
          <PreviewIcon
            onClick={() => {
              openFile();
            }}
            style={{
              cursor: "pointer",
            }}
          />
          {visibility ? (
            <ExpandLessIcon onClick={() => setVisibility(false)} fontSize="large" />
          ) : (
            <ExpandMoreIcon onClick={() => setVisibility(true)} fontSize="large" />
          )}
        </Stack>
      </Stack>

      {visibility && <Stack>{children}</Stack>}

    </Stack >
  );
}