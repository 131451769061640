import { Typography, Stack, Grid } from "@mui/material";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { closeBtn } from "../../../styles";
import { Downgraded, useHookstate, useState } from "@hookstate/core";
import { getDocumentDataById } from "../../../services/documentUploadService";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import React, { useEffect } from "react";
import { appSetting, creditData } from "../../../configs/mainStore";
import { CheckBoxComponent } from "../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { participantTypes } from "../../../configs/constants/contants";

const AddDisbursementDocuments = ({ dataArray, documentTypes, scheduleType, applicationId, setDocumentArray, documentArray, existingDocuments }) => {
    let tempDoc;
    const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
    const normalFileList: any = useState([]);
    const [payload, setPayload] = React.useState<any>([]);
    const appSettings: any = useState(appSetting);
    const [checkedIds, setCheckedIds] = React.useState<any>([]);
    const [productDocumentIds, setProductDocumentIds] = React.useState<any>([]);
    const participantTypesList: any = participantTypes();

    useEffect(() => {
        if (documentTypes?.length > 0) {
            let array: any = [];
            const idsArray = existingDocuments.map(obj => obj.id);

            documentTypes?.map((item: any) => {

                if (!idsArray.includes(item.documentId)) {
                    array.push({
                        name: `${item.name} (${participantTypesList.find((type: any) => Number(type.id) === item.documentType)?.name})`,
                        documentId: item.documentId,
                        id: item.id,
                        isActive: item.isActive
                    });
                }

            });
            normalFileList.set(array);
        }
    }, [])

    const onSubmit = async () => {
        setDocumentArray(productDocumentIds);
        closeGlobalModal();
    };

    const handleOnChange = (isChecked, item) => {

        let _idsChecked: any = [];
        let array: any = [];
        tempDoc = getDocumentDataById(documentDetailsList, item);

        if (checkedIds.includes(item.documentId)) {
            _idsChecked = checkedIds.filter((b) => parseInt(b) !== item.documentId);
        } else {
            _idsChecked = [...checkedIds, item.documentId];
            array.push({
                productDocId: item.id,
                documentId: item.documentId,
                name: tempDoc[appSettings.get().language]?.name
            })
        }

        setProductDocumentIds([...productDocumentIds, ...array, ...existingDocuments]);
        setCheckedIds(_idsChecked);
    };

    return (
        <>
            <div>
                <Stack padding={'5px'}>
                    <Typography variant="subtitle1" color={"gray"} display={'flex'} alignItems={'center'}>
                        Choose Documents.
                    </Typography>
                    {normalFileList.attach(Downgraded).get()?.map((key: any, index) => {

                        return (
                            <Grid item xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    alignSelf="center"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <CheckBoxComponent checked={checkedIds.includes(parseInt(key.documentId)) ? true : false} onChange={(e) => {
                                        handleOnChange(e.target.checked, key)
                                    }} />
                                    <Grid
                                        item
                                        xs={12}
                                        alignSelf="center"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        {key?.name}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="CLOSE"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={() => {
                            closeGlobalModal()
                        }}
                        loadingbtn={true}
                        style={closeBtn}
                    />
                    <ButtonComponent
                        startIcon={<DoneIcon />}
                        title="SUBMIT"
                        variant="contained"
                        onClick={() => {
                            onSubmit()
                        }}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </>
    );
}

export default AddDisbursementDocuments;