import { useState } from "@hookstate/core";
import { Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import SubSectionHeader from "../../Helpers/SubSectionHeader/SubSectionHeader";
import { financialPositionOfTheCompanyFinalObject, profitAndLossAccountsTypeState } from "../../Stores/MicroAppraisalStore";
import { doesTheApplicantHasEnoughFinancialInformation, profitAndLossAccountsType } from "../../Utilities/Util";
import ComprehensiveContainer from "./Components/Comprehensive/ComprehensiveContainer";
import Generalized from "./Components/Generalized/Generalized";

const FinancialPositionOfTheCompany = (props) => {
  const { isAmountBelowTwoPointFive } = props;
  const { t: translate } = useTranslation();
  const currentKey = useState(profitAndLossAccountsTypeState);
  const financialPositionOfTheCompany: any = useState<any>(financialPositionOfTheCompanyFinalObject);

  useEffect(() => {
    return () => {
      currentKey.set("generalized")
    }
  }, [])

  const componentMapping = {
    generalized: Generalized,
    comprehensive: ComprehensiveContainer,
  };

  return (
    <Stack width={"100%"} gap={2} component={Paper} elevation={0}>
      <Stack flex={3} direction={"row"} gap={1}>
        <Stack flex={1} pt={3}>
          <AutoCompleteProxyComponent
            options={doesTheApplicantHasEnoughFinancialInformation}
            cls="select_basic"
            onChange={(e) => financialPositionOfTheCompany?.doesTheApplicantHasEnoughFinancialInformation.set(e.id)}
            label={translate("doesTheApplicantHasEnoughFinancialInformation")}
            value={financialPositionOfTheCompany?.doesTheApplicantHasEnoughFinancialInformation.get()} />
        </Stack>
        <Stack flex={1}></Stack>
      </Stack>
      <SubSectionHeader
        isNeedDropdown={!isAmountBelowTwoPointFive}
        title={"Profit and Loss Accounts"}
        dropDownProps={{
          options: profitAndLossAccountsType,
          profitAndLossAccounts: "",
        }}
      />
      {React.createElement(componentMapping[currentKey.get()], props)}
    </Stack>
  );
};

export default FinancialPositionOfTheCompany;
