import { Button, Stack } from "@mui/material";
import { getWorkflowActionById, getWorkflowStageById } from "../../../../../services/workflowServices";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { edgeColors } from "../../../WorkflowComponents/utils";

const ActionComponent = ({ data, callBackOnAction, assignPermission, documentUploadApi }) => {
  const _wfData = data.wfData;
  const _revertStages = data.revertStages ?? "";

  const revertStages = _revertStages ? _revertStages.split(",") : "";

  const checkRevertIsEnableInTheStage = () => {
    let status = true;
    const stageData = getWorkflowStageById(data?.workflowStage);

    if (!stageData?.showRevert) {
      status = false;
    }

    return status;
  };

  const actionBtnClicked = async (params) => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "sub-workflow-stages-validation",
      close: false,
      modalParams: {
        data,
        isRevert: params?.isRevert,
        revertStages,
        action: params?.action,
        callBackOnAction: handleCallBackOnAction,
        documentUploadApi: documentUploadApi
      },
    });
  };

  const handleCallBackOnAction = (data) => {
    callBackOnAction(data);
    // const risk = JSON.parse(JSON.stringify(riskList.get()));
    // const sortedList = [...risk, newRisk]?.sort((a, b) => compare(b, a, "id"));
    // riskList.set(sortedList);
  };

  if (Object.keys(_wfData).length > 0) {
    const actions = _wfData?.actionArray;

    return (
      <Stack direction={"row"} justifyContent={"center"} pb={1}>
        {actions?.map((item, i) => {
          let tempAction = getWorkflowActionById(item);
          return (
            <Button
              className="basic-font font-size-14 btn-label border-radius-5"
              style={{
                color: edgeColors[tempAction?.actionKey]?.color,
                margin: 5,
                minWidth: "90px",
                border: "2px solid #e0e1e4",
                boxShadow: "2px 2px",
              }}
              key={i}
              onClick={() => {
                actionBtnClicked({ action: tempAction?.id, isRevert: false });
              }}
              disabled={assignPermission}
            >
              {tempAction?.name}
            </Button>
          );
        })}
        {revertStages.length > 0 && checkRevertIsEnableInTheStage() && (
          <Button
            className="basic-font font-size-16 btn-label border-radius-5"
            style={{
              color: edgeColors["revert"]?.color,
              // color: 'white',
              margin: 2,
              // height: '45px',
              minWidth: "90px",
              border: "1px solid #e0e1e4",
            }}
            onClick={() => {
              actionBtnClicked({ action: "", isRevert: true });
            }}
            disabled={assignPermission}
          >
            Revert
          </Button>
        )}
      </Stack>
    );
  }

  return null;
};

export default ActionComponent;
