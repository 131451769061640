import { useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { appSetting } from "../../../../../configs/mainStore";
import { getScoreCardGroupData } from "../../../../../services/apiCalls";
import { getScoreCardData } from "../../../../../services/creditFileApiCall";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { ScoreCardMainSectionWrapper } from "./ScoreCardMainSectionWrapper";

const InspectionScoreCard = (params) => {
  const [scoreCardList, setScoreCardList]: any = React.useState([]);
  const appSettings: any = useState(appSetting);
  const [isLoading, setIsLoading] = React.useState(true);
  const [fieldMappingList, setFieldMappingList] = React.useState<any>([]);

  useEffect(() => {
    (async () => {
      scoreCrardData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getScoreCardGroupData();

      let obj: any = [];

      if (Object.keys(data)?.length > 0) {
        const _data = data?.data;

        for (const item in _data) {
          const _item = _data[item]?.data[appSettings?.language.get()];

          if (Object.keys(_item).length > 0) {
            if (_item?.publish === 1) {
              obj.push({
                id: _item?.id,
                name: _item?.scoreCardGroupName,
              });
            }
          }
        }
      }

      setFieldMappingList(obj);

      setIsLoading(false);
    })();
  }, []);

  const scoreCrardData = async () => {
    const applicationId = params?.applicationId;
    const response = await getScoreCardData(applicationId);

    setScoreCardList(response?.data);
  };

  const handleClose = () => {
    closeGlobalModal();
  };

  const viewScoreCard = async (item) => {
    const modifiedObject = {};

    for (const key in item.children) {
      if (Object.hasOwnProperty.call(item.children, key)) {
        const id = parseInt(key);
        const correspondingName = fieldMappingList.find((item) => item.id === id)?.name;

        if (correspondingName) {
          modifiedObject[correspondingName] = item.children[key];
        }
      }
    }

    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: "view-score-card",
      close: false,
      modalParams: {
        item: modifiedObject,
        templateName: item,
        handleClose: () => {
          handleClose();
        },
      },
    });
  };

  return (
    <>
      {scoreCardList?.map((scoreCardListChildObj, index) => {
        return (
          <ScoreCardMainSectionWrapper
            cardDetails={scoreCardListChildObj}
            viewScoreCard={() => {
              viewScoreCard(scoreCardListChildObj);
            }}
          />
        );
      })}
    </>
  );
};

export default InspectionScoreCard;
