import TrialCalculator from "./UIJobComponents/TrialCalculator";
import ARMCodeMapping from "./UIJobComponents/ARMCodeMapping";
import ManualAllocation from "./UIJobComponents/ManualAllocation";
import ManualInspectionAllocation from "./UIJobComponents/ManualInspectionAllocation";

export const uiComponentMapping = {
  component1: ARMCodeMapping,
  component2: TrialCalculator,
  component3: ManualAllocation,
  component4: ManualInspectionAllocation,
};
