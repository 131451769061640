import React, { useEffect } from "react";
import Stack from "@mui/material/Stack/Stack";
import { nextBtnStyles } from "../../../../../../../../../configs/constants/styleValues";
import { StyledButton } from "../../../../../../../../InputComponents/ButtonComponent/StyledButton";
import AlertDialogSlide from "../DialogModalComponent/DialogModal";
import FileUploadDropone from "../FileUploadDropone";
import GoogleMapFC from "./GoogleMap";
import { getFilledPendingInpectionFormData } from "../../../../../../../../../services/apiCalls";
import { SectionKey } from "../../Helpers/inspectionKeyEnums";
import { Downgraded, useState } from "@hookstate/core";
import { inspectionForm } from "../../Helpers/inspectionStores/dataStore";
import { InspectionProps } from "../../Helpers/OnSubmitInspections/SaveChangesInspection";

export default function GoogleMapContainer({ key, type, metaData, value, onChange, otherParams }) {
  const { applicationId, formData: { personalData: { individualId } } } = otherParams;
  const [open, setOpen] = React.useState<boolean>(false);
  const [mainKey, setKey] = React.useState<string>("theGoogleMap");
  const inspectionFormState = useState<InspectionProps>(inspectionForm);
  const [updatedValueState, setUpdatedValueState] = React.useState(value);

  useEffect(() => {

    (async () => {

      let googleMapLocationData = updatedValueState["googleMapLocationData"];

      if (value["isLocationSameAsPA"]) {

        try {
          const { borrowers } = inspectionFormState.attach(Downgraded).get();

          if (borrowers && Object.values(borrowers).length > 0) {
            if (borrowers.hasOwnProperty(individualId)) {
              googleMapLocationData = borrowers[individualId]["googleMapLocationData"] ? borrowers[individualId]["googleMapLocationData"] : {}
            }

          } else {
            let response: { data: Object, status: number } | any = await getFilledPendingInpectionFormData(applicationId, SectionKey.primaryApplicantInspectionDataKey);
            response = JSON.parse(response?.data?.INSPECTION_DATA?.borrowers ?? {});
            inspectionFormState.borrowers.set(response);
            googleMapLocationData = response[individualId]["googleMapLocationData"] ? response[individualId]["googleMapLocationData"] : {};
          }

        } catch (error) { }

      }

      setUpdatedValueState(prev => ({
        ...prev,
        googleMapLocationData
      }));

    })();

  }, [value.isLocationSameAsPA]);

  const propsSet = {
    key,
    type,
    metaData,
    value: updatedValueState,
    onChange,
    open,
    setOpen,
    mainKey,
    otherParams
  };

  return (
    <div style={{ width: "100%" }}>
      <GoogleMapFrontView setOpen={setOpen} setKey={setKey} />
      <ModalWrapper {...propsSet} />
    </div>
  );
}

const UploadRoadMap = ({ key, type, metaData, value, onChange, handleDocUpload, setOpen, otherParams }) => {
  const props = {
    key,
    type,
    metaData,
    value,
    onChange,
    handleDocUpload,
    setOpen,
  };
  return (
    <Stack direction={"row"} justifyContent={"center"}>
      <FileUploadDropone {...props} />;
    </Stack>
  );
};

const GoogleMapFrontView = ({ setOpen, setKey }) => {

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1} flex={5} width={"100%"} pb={1}>
      <Stack flex={3}></Stack>
      <Stack flex={1}>
        <StyledButton
          styles={nextBtnStyles}
          title={"Select Road Map"}
          onClick={() => {
            setKey("theGoogleMap");
            setOpen(true);
          }}
        />
      </Stack>
      <Stack flex={1}>
        <StyledButton
          styles={nextBtnStyles}
          title={"Upload Road Map"}
          onClick={() => {
            setKey("uploadRoadMap");
            setOpen(true);
          }}
        />
      </Stack>
    </Stack>
  );
};

const GoogleMap = ({ metaData, value, onChange }) => {

  const props = {
    metaData,
    value,
    onChange,
  };

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <GoogleMapFC {...props} />
    </div>
  );
};

const ModalWrapper = ({ open, setOpen, mainKey, type, metaData, value, onChange, key, otherParams }) => {

  const handleClose = () => setOpen(false);
  const elementFC = {
    theGoogleMap: GoogleMap,
    uploadRoadMap: UploadRoadMap,
  };

  const title = mainKey === "uploadRoadMap" ? "Upload The Road Map" : "";

  const params = { key, type, metaData, value, onChange, setOpen, otherParams }

  let Component = elementFC[mainKey];

  return (
    <AlertDialogSlide handleClose={handleClose} open={open} setOpen={setOpen} title={title}>
      <Component {...params} />
    </AlertDialogSlide>
  );
};
