import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { creditData, formStructureSet, multipleDisbursementState, selectedApplicationReferenceToEditState } from "../../../../../configs/mainStore";
import { propertyDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { getFilledPendingInpectionFormData, saveAppFormDataJson } from "../../../../../services/apiCalls";
import { deleteMultipleInputItem, resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { Property } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Immovable/Property/Property";
import ValuationOfProperty from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Immovable/Property/ValuationOfProperty";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import { collateralSectionDeletion } from "./collateral-utils/Utility";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditProperty = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, innerComponentData, tabsToEdit }) => {
  const [loading, setLoading]: any = React.useState(false);
  const requestId = data?.requestId;
  const creditDetailsState: any = useState(creditData);
  const { immovable } = creditDetailsState?.formData?.securityData;
  const { formData }: any = creditDetailsState.attach(Downgraded).get();
  const [fullObj, setFullObj]: any = React.useState({});
  const [fields, setFields]: any = React.useState({});
  const existingData = useState<Array<any>>([]);
  const fetching = useState<boolean>(false);
  let itemIndex = 0;
  const exisitingObject = data?.formData?.securityData?.immovable?.property ?? [];
  const selectedApplicationReferenceId = useState(selectedApplicationReferenceToEditState);
  const multipleDisbursement: any = useState(multipleDisbursementState);
  const downgradedMultipleDisbursement = multipleDisbursement?.attach(Downgraded).get();
  const appFormSet = useState(formStructureSet);
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });
  const inspectionFormCollaterals = useState([]);

  const handleGetApplicationData = async () => {
    await getApplicationForm();

    let inspectionFormJson: any | any[] = [];

    try {
      inspectionFormJson = await getFilledPendingInpectionFormData(applicationId, "collateral");
      inspectionFormJson = JSON.parse(inspectionFormJson["data"]["INSPECTION_DATA"] ?? {});
      inspectionFormJson = inspectionFormJson["property"];

      if (inspectionFormJson && inspectionFormJson.length > 0) {
        inspectionFormCollaterals.set(inspectionFormJson);
      }

    } catch (e) { }
  };

  useEffect(() => {
    fetching.set(true);
    multipleDisbursementDocuments();
    handleGetApplicationData()
      .then(() => {
        setExistingData();
      })
      .then(() => {
        if (formData?.securityData?.immovable) {
          setFullObj(JSON.parse(JSON.stringify(formData?.securityData?.immovable ?? {})));
        } else setFullObj([]);
      });
    fetching.set(false);
  }, []);

  const getApplicationForm = async () => {
    const key = "property";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const setExistingData = () => {
    if (exisitingObject && exisitingObject.length > 0) {
      const arrayWithPropertyValue = JSON.parse(JSON.stringify(exisitingObject)).map((obj) => {
        if (obj?.propertyValuation) {
          return obj;
        } else
          return {
            ...obj,
            propertyValuation: propertyValuation,
          };
      });
      existingData.set(arrayWithPropertyValue);
    }
  };

  const handleFieldsAdding = () => {
    let property = {
      itemIndex: 0,
      entryId: 0,
      ...JSON.parse(JSON.stringify(propertyDefault)),
      propertyValuation: propertyValuation,
    };

    try {
      if (existingData.get()?.length > 0) {
        property["itemIndex"] = existingData.get()?.length;
        property["entryId"] = existingData.get()?.length;
      }
      const tempArray = existingData.get() ? [...existingData.get(), property] : [property];
      existingData.set(JSON.parse(JSON.stringify(tempArray)));
    } catch (error) { }
  };

  const PROPERTY_MANDATORY = [...getMandatoryFields(fields)];

  const handleSave = async (props) => {
    setLoading(true);
    const mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), PROPERTY_MANDATORY);

    const finalArr: any = [];

    if ("property" in fullObj) {
      delete fullObj.property;
    }

    let keyIndex = 0;

    for (let index = 0; index < existingData.get().length; index++) {
      const element = existingData[index].get();

      if (!element || element === null) continue;

      if (element["removedItem"]) continue;

      let obj = {
        type: "property",
        itemIndex: index,
        entryId: index,
        ...element,
      };
      keyIndex += 1;
      finalArr[finalArr.length] = obj;
    }

    let finalObj = {
      immovable: {
        property: finalArr,
        ...fullObj,
      },
    };

    let errorArray = collateralValidationErrorArray("immovable", "property", finalObj);

    let lastValidation: Array<any> = [];

    if (Object.values(props).length > 0) {
      lastValidation = [...errorArray, ...mandatoryRes];
    }

    if (lastValidation?.length > 0) {
      const errorMessage: string = lastValidation.join("\n");
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${errorMessage}`,
      });
      setLoading(false);
    } else {
      let data = JSON.parse(JSON.stringify(finalObj));
      let res = await saveAppFormDataJson(requestId, "securityData", data);

      if (res?.status === 1) {
        immovable.set({ ...data.immovable });
        setLoading(false);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Security Details Updated",
        });
      } else if (res?.error) {
        setLoading(false);
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update Error",
        });
      } else {
        setLoading(false);
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    }
  };

  const deleteDispatcher = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type && type == "all") {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "app-section-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete all collaterals except guarantors!.",
          callBackOnAction: () => onDeleteSection(type, index),
        },
      });
    } else {
      onDeleteSection(type, index, selectedSecurityTypeItem, securityType);
    }
  };

  const removeFullState = () => {
    // For now there is no full state delete button on collateral then this will be activated later if needed
  };

  const onDeleteSection = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type) {
      closeGlobalModal();
    }

    let securityTypes: any[] = securityType.split(":") || [];

    try {
      let dataObj: any = {};

      if (type) {
        dataObj = {
          applicationId: selectedApplicationReferenceId?.get(),
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}`,
        };
      } else {
        dataObj = {
          applicationId: selectedApplicationReferenceId?.get(),
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}[${index}]`,
        };
      }
      // let response = await applicationFormSectionDetailsDelete(dataObj);

      deleteMultipleInputItem(selectedSecurityTypeItem);

      handleSave({});
      addToaster({
        status: "success",
        title: "Deleted!",
        message: `${securityTypes[2]} deleted successfully!`,
      });

    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Error ${securityTypes[2]} deletion, try again shortly!`,
      });
    }
  };

  let propertyValuation = {};

  const multipleDisbursementDocuments = () => {
    let tabList: any = [];

    for (const [key, value] of Object.entries(downgradedMultipleDisbursement?.disbSchedule ?? {})) {
      propertyValuation[key] = {
        assetValue: "",
        valuationOfficer: "",
        valuationDate: null,
      };
      const disbursement: any = value;
      if (disbursement.status === "Pending") {
        tabList.push(disbursement);
        break;
      }
      tabList.push(disbursement);
    }

    return tabList;
  };

  return (
    <>
      {fetching.get() ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        <>
          <div className="full-width full-height" style={{ position: "relative" }}>
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            {isMainContactDetails && (
              // <Header handleFieldsAdding={handleFieldsAdding} />
              <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"Property"} />
            )}

            {/* Security Form */}
            <Grid container className="inner-component-height">
              <Stack spacing={1} m={1} className="full-width">
                {existingData.get()?.length > 0 ? (
                  existingData?.map((doc: any, index: number) => {
                    if (!doc.get()) return <></>;
                    const elementKeys = Object.keys(doc);
                    if (!elementKeys.includes("removedItem")) {
                      itemIndex = itemIndex + 1;

                      return (
                        <Box display="flex" alignItems="center" mb={1} key={`Property_${itemIndex * itemIndex}`}>
                          <Box style={{ width: "100%" }}>
                            <Property doc={doc} fields={fields} index={index} isNotNeedComponentTitle={true} inspectionFormCollaterals={inspectionFormCollaterals} />
                            {multipleDisbursement?.isHaveMultipleDisbursement?.get() ? multipleDisbursementDocuments().map((label, index) => <ValuationOfProperty disbIndex={index + 1} doc={doc} />) : <></>}
                          </Box>
                          <Box>
                            <Box pb={3}>
                              <Tooltip title={"Reset"}>
                                <Box>
                                  <ButtonComponent
                                    iconbtn={true}
                                    onClick={() => {
                                      resetInputItems(doc, "immovable", "property");
                                    }}
                                    style={{ border: "1px solid gray" }}
                                    size="small"
                                  >
                                    <RestartAltIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box pt={3}>
                              <Tooltip title={"Delete"}>
                                <Box>
                                  <ButtonComponent iconbtn children={<DeleteIcon />} onClick={() => {
                                    collateralSectionDeletion({ applicationId, collateralDataState: existingData, itemIndex: index, collateralKeyString: "immovable:property:Immovable Property" })
                                  }} />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  })
                ) : (
                  <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                )}
              </Stack>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
