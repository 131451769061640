export const SectionKey = {
  primaryApplicantInspection: "primaryApplicant",
  primaryApplicantInspectionDataKey: "borrowers",
  jbInspection: "jointBorrower",
  inspectionSummery: "summery",
  inspectionParticipation: "participation",
  collateralInspection: "collateral",
  businessInspection: "business",
  scoreCardQuestion: "scoreCard",
  otherQuestions: "otherQuestions",
  pledge: "collateral",
  mmMachineryEquipments: "collateral",
  immMachineryEquioments: "collateral",
  property: "collateral",
  cultivationModel: "cultivationModel",
  otherThancultivationModel: "otherThancultivationModel",
  sustainableEnvironmentalAndSafeguard: "sustainableEnvironmentalAndSafeguard"
};

export const enum ApplicationFormKey {
  primaryApplicantInspection = "1705390152027",
  jbInspection = "1708489209692",
  inspectionSummery = "1705390007680",
  inspectionParticipation = "1705391297122",
  // collateralInspection = "1705405107227",
  businessInspection = "1705390706736",
  // agricultureModelIndividual = "1705391241639",
  // agricultureModelBusiness = "1708865866128",
  // manufactureTradeAndServiceIndividual = "1708865900366",
  cultivationModel = "1708869587724",
  property = "1705391122416",
  otherThancultivationModel = "1708870121193",
  pledge = "1705405619574",
  mmMachineryEquipments = "1705407388197",
  immMachineryEquioments = "1706764529544",
  otherQuestions = "1705391512269",
  sustainableEnvironmentalAndSafeguard = "1712035933882",
}
