import { FC } from "react";
import { Switch } from "@mui/material";
import { SwitchPropsTypes } from "../../../configs/interfaces";
import { styled } from "@mui/material/styles";

export const SwitchComponent: FC<SwitchPropsTypes> = ({
  checked,
  checkedIcon,
  classes,
  color,
  defaultChecked,
  disabled,
  disableRipple,
  edge,
  icon,
  id,
  inputProps,
  inputRef,
  onChange,
  required,
  size,
  sx,
  value,
  commonStatus,
}) => {
  const CommonStatusSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#e61515",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#e61515",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#e61515",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#2e7d32",
      // "&:hover": {
      //   backgroundColor: "#2e7d32",
      // },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#39A21A",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#2e7d32",
    },
  }));

  return (
    <>
      {commonStatus ? (
        <CommonStatusSwitch
          checked={checked}
          // checkedIcon={checkedIcon}
          classes={classes}
          color={color}
          defaultChecked={defaultChecked}
          disabled={disabled}
          disableRipple={disableRipple}
          edge={edge}
          // icon={icon}
          id={id}
          inputProps={inputProps}
          inputRef={inputRef}
          onChange={onChange}
          required={required}
          size={size}
          sx={sx}
          value={value}
        />
      ) : (
        <Switch
          checked={checked}
          // checkedIcon={checkedIcon}
          classes={classes}
          color={color}
          defaultChecked={defaultChecked}
          disabled={disabled}
          disableRipple={disableRipple}
          edge={edge}
          // icon={icon}
          id={id}
          inputProps={inputProps}
          inputRef={inputRef}
          onChange={onChange}
          required={required}
          size={size}
          sx={sx}
          value={value}
        />
      )}
    </>
  );
};
