import { Downgraded, useState } from "@hookstate/core";
import EditIcon from "@mui/icons-material/Edit";
import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { renderingDetails, selectedApplicationReferenceToEditState } from "../../../../../configs/mainStore";
import { isNotPermittedAll } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isNotPermittedDocs";
import { adminPermission } from "../../../../../utility/helpers/getPrivilageState";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

const ApplicationHeader = ({ title, formData, requestId, applicationId, isAppraisal, isRawVersion, headerButton = true, disableAccordingToTab = false }) => {
  let userData: any = {};

  try {
    userData = formData.attach(Downgraded).get();
  } catch (error) {
    userData = formData;
  }

  const renderingDivDetails = useState(renderingDetails);
  const selectedApplicationReferenceId = useState(selectedApplicationReferenceToEditState);
  let privilage = adminPermission(privilegeKeys.APPLICATION_TAB_EDIT_APPILCATION);
  privilage = disableAccordingToTab ? disableAccordingToTab : privilage ? privilage : false;
  const { creditData: { type, sector, scheme, applicantType } } = userData;

  const onActionEditClick = () => {
    renderingDivDetails.set({
      renderId: "application-form",
      renderParams: {
        formData: userData,
        requestId: requestId,
        applicationId: applicationId,
        isAppraisal: isAppraisal,
        isEditMode: true,
        type,
        sector,
        scheme,
        applicantType,
      },
    });
    selectedApplicationReferenceId.set(applicationId);
  };

  return <InnerSectionHeaderComponent innerComponentData={{ title: title, headerActionButton: headerButton, headerActionButtonTitle: "Edit" }} IconComponent={EditIcon} callBackOnAction={onActionEditClick} isPageRenderingClick={true} disabled={isNotPermittedAll(privilage)} isRawVersion={isRawVersion} />;
};

export default ApplicationHeader;
