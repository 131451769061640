import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { branchDetails, masterDataDetails } from "../../../../../configs/mainStore";
import { figureFormat } from "../../../../../utility/fomatters";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";

const BankDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const bankDataList: any = data?.formData?.bankData;
  const institutionState: any = useState(branchDetails);

  const { t: translate } = useTranslation();
  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Bank Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Bank Details
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
            <Grid container>
              {bankDataList?.length > 0 &&
                bankDataList
                  ?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)
                  ?.map((bankData, index) => {
                    const accountType = masterData?.BANK_ACCOUNT_TYPE?.get()?.find((t) => t.id == bankData?.accountType);
                    const bankAccountOwnership = masterData?.BANK_ACC_OWNERSHIP?.get()?.find((t) => t.id == bankData?.accountOwnership);
                    return (
                      <Grid container m={1} className="data-box" key={index}>
                        <Grid container item m={1} spacing={1}>
                          {bankData?.bankName && bankData?.bankName == "No Other Bank Accounts" ? (
                            <Grid item xs={3}>
                              <DetailItem title="Bank Name" value={bankData?.bankName ? bankData?.bankName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={3}>
                                <DetailItem title="Bank Name" value={bankData?.bankName ? bankData?.bankName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Branch" value={bankData?.branch ? bankData.branch : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Account Type" value={accountType?.name ? accountType.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Account Ownership" value={bankAccountOwnership?.name ? bankAccountOwnership.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Account Holder Name" value={bankData?.accountName ? bankData.accountName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Account Number" value={bankData?.accountNo ? bankData.accountNo : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title={`${translate("accountOpenDate")} (DD/MM/YYYY)`} value={bankData?.accountOpenDate && bankData?.accountOpenDate === "Invalid date" ? "-" : bankData?.accountOpenDate !== "Invalid date" ? bankData?.accountOpenDate : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title={translate("maximumBalance")} value={bankData?.maximumBalance ? figureFormat(bankData.maximumBalance) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title={`${translate("asAtDate")} (DD/MM/YYYY)`} value={bankData?.asAtDate && bankData?.asAtDate === "Invalid date" ? "-" : bankData?.asAtDate !== "Invalid date" ? bankData?.asAtDate : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title={translate("balanceAsAtDate")} value={bankData?.balanceAsAtDate ? figureFormat(bankData.balanceAsAtDate) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={6}>
                                <DetailItem title={translate("averageDepositLastSixMonths")} value={bankData?.averageDepositLastSixMonths ? figureFormat(bankData.averageDepositLastSixMonths) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={6}>
                                <DetailItem title={translate("averageWithdrawalLastSixMonths")} value={bankData?.averageWithdrawalLastSixMonths ? figureFormat(bankData.averageWithdrawalLastSixMonths) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BankDetails;
