import { useHookstate } from "@hookstate/core";
import { Stack, Typography } from "@mui/material";
import { CheckBoxComponent } from "../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import SearchComponent from "../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";
import { workflowMasterData } from "../../configs/mainStore";

const AddWorkflowStageModal = ({ currentStages, action, actionRemove }) => {
    const { wf_stages: workflowStages }: any = useHookstate(workflowMasterData);
    const searchText = useHookstate("");

    let stageMap = {};

    const wfList = (workflowStages.get() || []).reduce((acc: any, stage) => {
        const { name, description, id } = stage;
        const item = { id, name, description };
        acc.push(item);
        stageMap[id] = item;
        return acc;
    }, []);

    return <Stack direction={"column"} >
        <Stack pr={2}>
            <SearchComponent placeholder={"Search Workflow Stage"} handleChangeSearchText={(text) => {
                searchText.set(text);
            }}></SearchComponent>
        </Stack>
        <Stack style={{ height: "100%", overflowY: "auto", }} pr={2}>
            {wfList.filter((item) => item.name.toLowerCase().includes(searchText.get().toLowerCase())).map((obj, index) => <Stack key={index} direction={"row"} alignItems={"center"} justifyContent={"space-between"} flex={1} py={1} onClick={() => {
                if (!currentStages.includes(obj.id)) {
                    action(obj.id);
                } else {
                    actionRemove(obj.id);
                }
            }} style={{ cursor: "pointer" }}>
                <CheckBoxComponent onChange={() => { }} checked={currentStages.includes(obj.id)} />
                <Stack direction={"row"} flex={1}>
                    <Typography color={"gray"}>{obj.name}</Typography>
                </Stack>
                <Typography color={"gray"} variant="caption">{obj.id}</Typography>
            </Stack>)}
        </Stack>
    </Stack>;
}

export default AddWorkflowStageModal;