import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { standardDateFormatter } from "los-util";
import moment from "moment";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../utility/fomatters";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import { AddressViewComponent } from "../AddressInformation";
import { RenderingHelper, duration } from "./Components";
import { ContactDetails } from "./ContactDetails";

export const PreviousEmployeements = (props) => {
  const deprecatedDateFormatterToggle = moment(formattedDate("1/2/2024")).isAfter(props?.applicationLastUpdatedDate);
  const previousEmployments = props?.previousEmployeements || [];
  const masterDataStore = MasterDataProvider.provideMasterData();
  const masterData: any = useState(masterDataDetails);
  const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);
  const prevEmployStatus = [
    { id: 1, name: "Retired" },
    { id: 2, name: "Resigned" },
  ];
  const typedData2 = (masterType, key) => prevEmployStatus.find((t) => t.id === key);

  const renderTitle = (item: any = {}) => {
    let value: any = "";

    let title = "Previous Employment -";
    let companyName: any = "";

    if (item?.employmentStatus !== 1) {
      return title;
    }

    if (item?.employerName == 1) {
      if (item?.companyName != null && item?.companyName != "") {
        value = item?.companyName;
      }
    } else if (item?.employerName == "OTHER") {
      value = item?.companyName ?? "";
    } else {
      value = item?.employerName;
    }

    if (value) {
      if (value?.toString()?.length > 3) {
        companyName = value;
      } else {
        const companies: any[] = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies?.find((c) => c?.id?.get() == value);
        companyName = company?.name?.get() ?? "";
      }
    }

    title = `${title} ${companyName}`;

    return title;
  };

  const getEmployerName = (obj: any = {}) => {
    let value: any = "";

    if (obj?.employerName == 1) {
      if (obj?.companyName != null && obj?.companyName != "") {
        value = obj?.companyName;
      }
    } else if (obj?.employerName == "OTHER") {
      value = obj?.companyName ?? "";
    } else {
      value = obj?.employerName;
    }

    let companyName: any = "";

    if (value) {
      if (value.toString().length > 3) {
        companyName = value;
      } else {
        const companies: any[] = masterDataStore?.getMasterData(MD_KEYS?.EMPLOYER_NAME) || [];
        const company = companies?.find((c) => c?.id?.get() == value);
        companyName = company?.name?.get() ?? "";
      }
    }

    return companyName;
  };

  const getValidDate = (date) => {
    if (deprecatedDateFormatterToggle) return standardDateFormatter(date);

    else if (moment("10/07/2024").isAfter(props?.applicationLastUpdatedDate)) return moment(date, "MM/DD/YYYY").format("DD/MM/YYYY");

    else return date;
  };

  return (
    <div className="full-width margin-xs-b">
      <Grid container>
        {previousEmployments?.filter((obj) => obj)
          ?.filter((obj) => !obj.removedItem)?.map((employment, index) => {
            const employmentType = typedData("EMPLOYMENT_TYPE", employment?.employmentType);

            const occupation = typedData("OCCUPATION", employment?.occupation);
            const employmentStatus = typedData2("EMPLOYMENT_STATUS", parseInt(employment?.employmentStatus));
            const natureOfEmployment = [
              { id: 1, name: "Contract" },
              { id: 2, name: "Apprenticeship" },
              { id: 3, name: "Traineeship" },
              { id: 4, name: "Employment on commission" },
              { id: 5, name: "Probation" },
            ].find((t) => t?.id === employment?.natureOfEmployment);
            const industryType = typedData("INDUSTRY_TYPE", employment?.industryType);

            return (
              <>
                {" "}
                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                  {renderTitle(employment)}
                </Grid>
                <MandatoryFilterComponent items={previousEmployments} mandatory={["employmentType", "occupation", "employmentStatus", "industryType", "endDate", "startDate", "permenantEmployment", "permanentDate"] as never[]} options={{ title: "Current Employment" }}>
                  <div className="full-width" key={index}>
                    <Grid container item p={1} spacing={1} className="data-box">
                      {RenderingHelper.addItem(employment, "employmentType", employmentType?.name)}
                      {RenderingHelper.addItem(employment, "occupation", occupation?.name)}
                      {RenderingHelper.addItem(employment, "designation")}
                      {RenderingHelper.addItem(employment, "startDate", employment?.startDate !== undefined ? getValidDate(employment?.startDate) : "-", true)}
                      {RenderingHelper.addItem(employment, "endDate", employment?.endDate !== undefined ? getValidDate(employment?.endDate) : "-", true)}
                      {RenderingHelper.addItem(employment, "pensionableDate", employment?.pensionableDate, true)}
                      {RenderingHelper.addItem(employment, "duration", duration(employment, "noOfYearsAtEmp", "noOfMonthsAtEmp"))}
                      {RenderingHelper.addItem(employment, "companyName", employment?.employmentStatus == 1 ? getEmployerName(employment) : "-")}
                      {RenderingHelper.addItem(employment, "employmentStatus", employmentStatus?.name)}
                      {employment?.permenantEmployment != null && employment?.permenantEmployment > 0 && employment?.permanentDate != null && employment?.permanentDate != "" ? RenderingHelper.addItem(employment, "permanentDate", getValidDate(employment?.permanentDate)) : ""}
                      {employment?.permenantEmployment != null && employment?.permenantEmployment < 1 ? RenderingHelper.addItem(employment, "natureOfEmployment", natureOfEmployment?.name) : ""}
                      {RenderingHelper.addItem(employment, "employmentTimePeriod")}
                      {RenderingHelper.addItem(employment, "industryType", industryType?.name)}

                      <AddressViewComponent addressData={employment?.address} title="Address Information" viewType="tab" />
                    </Grid>

                    {employment?.contactTypes?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)?.map((type, index) => (
                        <ContactDetails contactDetails={type} index={index} nohead={true} isBusinessDetails={false}></ContactDetails>
                      ))}
                  </div>
                </MandatoryFilterComponent>
              </>
            );
          })}
      </Grid>
    </div>
  );
};
