import React from "react";
import { searchAndVerifyWidget } from "../SearchAndVerifyWidget";
import { Paper, Stack } from "@mui/material";
import { isTabActionPrivilege } from "../../../../../../../../utility/helpers/ApplicationMandatoryValidations/isNotPermittedDocs";

export interface FormBuilderProps {
  formJsonData: any;
  key?: string;
  type?: string;
  inspectionFormData?: any;
  setInspectionFormData?: any;
  metaData?: any;
  value: any;
  onChange?: any;
  alphaChildren?: Array<any>;
  isFromTbCell?: boolean;
  otherParams?: any;
}

const ChildBuilder: React.FC<FormBuilderProps> = ({ formJsonData, value, onChange, isFromTbCell, key, otherParams }: FormBuilderProps) => {
  const BuildContent = () => {
    return (
      <React.Fragment key={"ChildBuilder" + key}>
        {React.createElement(searchAndVerifyWidget(formJsonData?.inputOptions?.type ? formJsonData?.inputOptions?.type : formJsonData?.type), {
          key: "element" + key,
          type: formJsonData?.inputOptions?.type ? formJsonData?.inputOptions?.type : formJsonData?.type,
          metaData: formJsonData,
          alphaChildren: formJsonData?.children,
          value,
          onChange,
          isFromTbCell,
          otherParams,
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <BuildContent />
    </React.Fragment>
  );
};

export default ChildBuilder;
