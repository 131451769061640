import { Box, Grid, Paper } from "@mui/material";
import React, { useRef } from "react";
import {
  innerNavigationConfig,
  componentMapping,
} from "../../../../../configs/constants/creditFileConfigs";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import EditIcon from "@mui/icons-material/Edit";
import {
  creditData,
  renderingDetails,
  userDetails,
} from "../../../../../configs/mainStore";
import { Downgraded, useState } from "@hookstate/core";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { AppraisalSummary } from "./AppraisalSummary";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SummaryAccordion } from "./SummaryAccordion";

const ApplicationAppraisalSummary = ({
  componentListMapping,
  otherParams,
  setPreviewOpen,
}) => {
  const applicationComponentList = componentListMapping?.application;
  const filteredApplicationComponent = applicationComponentList.filter((list) => list != "single-customer-view-details");  
  const appraisalComponentList = componentListMapping?.appraisal;
  const { userId }: any = userDetails.attach(Downgraded).get();
  let { currentAssignee }: any = creditData.attach(Downgraded).get();
  const renderingDivDetails = useState(renderingDetails);
  const componentRef: any = useRef();
  const [expanded, setExpanded] = React.useState<string | false>("");
  const [printMode, setPrintMode] = React.useState(false);

  // for below main tabs, edit should be enable only for the assignee of the application
  const isApplicationOrAppraisal =
    otherParams?.selectMainTab === "application" ||
    otherParams?.selectMainTab === "appraisal";
  const isLoggedInUserNotTheAssignee = userId !== currentAssignee;

  const onClickEdit = () => {
    const creditData =
      otherParams?.data?.formData?.["creditData"] &&
      otherParams?.data?.formData?.["creditData"];
    const formData = otherParams?.data?.formData;
    const requestId = otherParams?.data?.requestId;

    renderingDivDetails.set({
      renderId: "application-form",
      renderParams: {
        formData: formData,
        requestId: requestId,
        applicationId: otherParams?.applicationId,
        isAppraisal: false,
        isEditMode: true,
        type: creditData.type,
        sector: creditData.sector,
        scheme: creditData.scheme,
        applicantType: creditData.applicantType,
      },
    });
    closeGlobalModal();
  };

  const handlePrintRef = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    setPrintMode(true);
    otherParams.printMode = true

    setTimeout(() => {
      handlePrintRef();
    }, 1000);

    setTimeout(() => {
      setPrintMode(false);
    }, 1500);
  };

  const handleAccordionExpand =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Grid container>
      <Box sx={{ width: "100%", margin: "5px 0px" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          padding={1}
        >
          <Grid
            item
            className="basic-font basic-font-color-bold font-size-18 align-left"
          >
            Application Summary
          </Grid>
          <Grid item>
            <Grid container columns={24} spacing={1}>
              <Grid item>
                <ButtonComponent
                  startIcon={<ArrowBackIcon />}
                  title={"Back"}
                  variant="contained"
                  onClick={() => {
                    setPreviewOpen(false);
                  }}
                  disabled={
                    isApplicationOrAppraisal && isLoggedInUserNotTheAssignee
                  }
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  startIcon={<EditIcon />}
                  title={"Edit"}
                  variant="contained"
                  onClick={onClickEdit}
                  disabled={
                    isApplicationOrAppraisal && isLoggedInUserNotTheAssignee
                  }
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  startIcon={<LocalPrintshopIcon />}
                  title={"Print PDF"}
                  variant="contained"
                  onClick={() => {
                    handlePrint();
                  }}
                  style={{ maxHeight: "40px", marginTop: "auto" }}
                  disabled={false}
                  loadingbtn={true}
                  loading={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper style={{ overflow: "auto", height: "calc(100vh - 130px)" }}>
          <Paper
            className="padding-sm main-bar"
            elevation={0}
            ref={componentRef}
          >
            {printMode &&
              filteredApplicationComponent?.map((compId) => {
                const componentId = innerNavigationConfig[compId]?.componentId;
                return (
                  <div key={componentId}>
                    {componentId &&
                      componentMapping[componentId] &&
                      React.createElement(
                        componentMapping[componentId],
                        otherParams
                      )}
                  </div>
                );
              })}

            {!printMode && filteredApplicationComponent?.map((compId) => {
              const componentId = innerNavigationConfig[compId]?.componentId;
              return (
                <SummaryAccordion
                  componentId={componentId}
                  componentMapping={componentMapping}
                  handleAccordionExpand={handleAccordionExpand}
                  otherParams={otherParams}
                  expanded={expanded}
                />
              );
            })}

            {appraisalComponentList && (
              <AppraisalSummary applicationId={otherParams?.applicationId} />
            )}
          </Paper>
        </Paper>
        {/* <UserCreditFile /> */}
      </Box>
    </Grid>
  );
};

export default ApplicationAppraisalSummary;
