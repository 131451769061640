import { validateEmail, validateLandLineNumber, validatelength, validateMandatory, validateMobileNumber, validatePhoneNumber, validateTextWithCharactersAndSpaces } from "../../validation-rules/validationRules";

export const contactDetailsValidationOnNext = (changes) => {
  //================== Other Contact Numbers Validation - Start ==================//
  const otherContactNumbersValidation = (value) => {
    const contactTypes = {};
    for (let i = 0; i < value.length; i++) {
      if (!value[i].removedItem) {
        const otherContactNumber = otherContactNoValidation(`${i + 1} : Other Phone Number`, value[i]?.contactNumber, 10);
        if (otherContactNumber) {
          contactTypes[i] = {
            contactNumber: otherContactNumber,
          };
        }
      }
    }
    if (Object.keys(contactTypes).length > 0) {
      return contactTypes;
    } else return null;
  };
  //================== Other Contact Numbers Validation - End ==================//

  //================== Other Email Validation - Start ==================//
  const otherEmailsValidation = (value) => {
    const emailTypes = {};
    for (let i = 0; i < value?.length; i++) {
      if (!value[i].removedItem) {
        const otherEmail = otherEmailValidation(`${i + 1} : Other Email`, value[i]?.emailAddress);

        if (otherEmail) {
          emailTypes[i] = {
            emailAddress: otherEmail,
          };
        }
      }
    }
    if (Object.keys(emailTypes).length > 0) {
      return emailTypes;
    } else return null;
  };
  //================== Other Email Validation - End ==================//

  // ================== Contact Details Erros Object - Start ==================//

  const contactDetailsErrors: any = {};
  const primaryContactVal = changes.primaryContact ? primaryContactNoValidation("Primary Contact No", changes?.primaryContact, 10) : null;
  const primarySms = changes.primarySms ? primarySmsNoValidation("Primary Sms No", changes?.primarySms, 10) : null;
  const otherContactNumbers = changes.contactTypes ? otherContactNumbersValidation(changes.contactTypes) : null;
  const primaryEmailVal = changes?.primaryEmail ? primaryEmailValidation("Primary Email", changes?.primaryEmail) : null;
  const otherEmails = changes?.emailTypes ? otherEmailsValidation(changes?.emailTypes) : null;

  const relationNameVal = changes?.relationName ? relationNameValidation("Relation Name", changes?.relationName) : null;
  const landNumberVal = changes?.relationLandNumber ? relationContactNumberValidation("Land Number", changes?.relationLandNumber, 10) : null;
  const relationshipVal = changes?.relationship ? relationshipValidation("Relationship", changes?.relationship) : null;

  if (primaryContactVal) contactDetailsErrors.primaryContact = primaryContactVal;
  if (primarySms) contactDetailsErrors.primarySms = primarySms;
  if (otherContactNumbers) contactDetailsErrors.contactTypes = otherContactNumbers;
  if (primaryEmailVal) contactDetailsErrors.primaryEmail = primaryEmailVal;
  if (otherEmails) contactDetailsErrors.emailTypes = otherEmails;
  if (relationNameVal) contactDetailsErrors.relationName = relationNameVal;
  if (landNumberVal) contactDetailsErrors.relationLandNumber = landNumberVal;
  if (relationshipVal) contactDetailsErrors.relationship = relationshipVal;

  return Object.keys(contactDetailsErrors).length > 0 ? contactDetailsErrors : 1;
  //================== Contact Details Erros Object - End ==================//
};

export const primaryContactNoValidation = (fieldname, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validateMobileNumber(fieldname, value);
  const checkLength = validatelength(fieldname, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const primarySmsNoValidation = (fieldname, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validateMobileNumber(fieldname, value);
  const checkLength = validatelength(fieldname, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const otherContactNoValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validateMobileNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const primaryEmailValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkEmail = validateEmail(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkEmail) result.push(checkEmail);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const otherEmailValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkEmail = validateEmail(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkEmail) result.push(checkEmail);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const relationNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const landNumberValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkLandNumber = validateLandLineNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkLandNumber) result.push(checkLandNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const relationContactNumberValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validatePhoneNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const relationshipValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkRelationship = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkRelationship) result.push(checkRelationship);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
