import { Downgraded } from "@hookstate/core";
import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { postalCodeDetails } from "../../../../../../../configs/mainStore";
import isProductOf from "../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { LengthItemValidate } from "../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { addItem } from "../Helpers";
import { ExitRemoveItem } from "./Helpers/exitRemoveItem";

export const BusinessDetailsDirector = ({ business, isMainContactDetails }) => {
  const directorData = LengthItemValidate.validatedArray(business?.directorDetails || []);
  const masterData = MasterDataProvider.provideMasterData();
  const postalCodeState = postalCodeDetails.attach(Downgraded).get();

  const masterDataObjProvider = (mapedDirectorData) => {
    const province = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.PROVINCE, mapedDirectorData?.directorD_province);
    const district = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.DISTRICT, mapedDirectorData?.directorD_distric);
    const postalCode = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.POSTAL_CODE, postalCodeState[mapedDirectorData?.directorD_postalCode]?.id);
    return {
      province,
      district,
      postalCode,
    };
  };
  const BuildDirectorDataDetails = ({ directorsData, index = null, masterDataObject }) => {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(directorsData, "Name", directorsData?.directorD_name ?? "N/A")}
          {addItem(directorsData, "New NIC", directorsData?.directorD_newNic ?? "N/A")}
          {addItem(directorsData, "Old NIC", directorsData?.directorD_oldNic ?? "N/A")}
          {addItem(directorsData, "Address Line 1", directorsData?.directorD_addressLine1 ?? "N/A")}
          {addItem(directorsData, "Relationship Witht The Primary Applicant", directorsData?.directorD_relationshipWithtThePrimaryApplicant ?? "N/A")}
          {addItem(directorsData, "Address Line 2", directorsData?.directorD_addressLine2 ?? "N/A")}
          {addItem(directorsData, "Address Line 3", directorsData?.directorD_addressLine3 ?? "N/A")}

          {addItem(directorsData, "Address Line 4", directorsData?.directorD_addressLine4 ?? "N/A")}
          {addItem(directorsData, "City", directorsData?.directorD_city ?? "N/A")}

          {addItem(directorsData, "Province", masterDataObject?.province ?? "N/A")}
          {addItem(directorsData, "District", masterDataObject?.district ?? "N/A")}
          {addItem(directorsData, "Postal Code", masterDataObject?.postalCode ?? "N/A")}
        </Grid>
      </Grid>
    );
  };

  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <div className="full-width margin-xs-b">
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
          Director Details
        </Grid>

        {directorData.length > 0 &&
          new ExitRemoveItem(directorData).removeRemovedItem().map((directorsData, index) => {
            const masterDataObject = masterDataObjProvider(directorsData);
            return <BuildDirectorDataDetails index={index} directorsData={directorsData} masterDataObject={masterDataObject} />;
          })}
      </div>
    );
  } else {
    return <></>;
  }
};
