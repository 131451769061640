import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from '@hookstate/core';
import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { userDetails } from '../../../../../configs/mainStore';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';
import { saveConditionData } from '../../../../../services/creditFileApiCall';
import { addToaster } from '../../../GlobalToast';
import { responseSuccessCode } from '../../../../../utility/other';
import ModalInputComponent from '../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { closeBtn } from '../../../../../styles';
import { SelectComponent } from '../../../../InputComponents/SelectComponent/SelectComponent';
import appStrings from '../../../../../configs/constants/appStrings';
import { createApplicationsHistory } from '../../../../../services/applicationHistoryApi';

const ConditionModal = (params) => {
  const initialData = { title: '', description: '' };
  const showErrors = useState(false);
  const predisbursementState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { productType, sector, scheme } = params;

  const addCondition = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        approveState: true,
        type: 'Agreement Special Conditions',
        ...predisbursementState.get(),
      };
      const res = await saveConditionData(data);

      const changedItems = {
        ...predisbursementState.get(),
        date: new Date(),
        createdBy: loginUser?.userId?.get(),
      };

      if (res?.status === responseSuccessCode) {
        addToaster({
          status: 'success',
          title: 'Success',
          message: res?.data?.msg,
        });

        createApplicationsHistory(
          params?.applicationId,
          `New Agreement condition`,
          null,
          changedItems,
          "/add-condition"
        );

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return predisbursementState.title.get() === '' || predisbursementState.description.get() === '';
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Add Special Conditions</div>
      </Stack>
      <Grid container spacing={2} mt={4} className="modal-text-field" px={2}>
        <Stack width={'100%'}>
          <Typography variant="subtitle2" color={'gray'}>
            Condition Title <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={predisbursementState?.title?.get()}
            onChange={(e) => {
              resetError();
              predisbursementState.title.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={1}
          />
        </Stack>

        <Stack width={'100%'} mt={2}>
          <Typography variant="subtitle2" color={'gray'}>
            Condition Description <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={predisbursementState?.description.get()}
            onChange={(e) => {
              resetError();
              predisbursementState.description.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={4}
          />
        </Stack>
      </Grid>
      {showErrors.get() && (
        <Typography variant="caption" className="error">
          Title and Description required to proceed
        </Typography>
      )}

      <Stack direction="row" spacing={2} justifyContent="end" pt={3} mt={2} pr={2} pb={1}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Add"
          variant="contained"
          onClick={addCondition}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default ConditionModal;
