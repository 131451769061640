import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, Grid, Stack } from "@mui/material"
import React from "react"
import { closeBtn } from "../../../../../../styles"
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent"
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent"
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent"
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal"

function SecurityDocumentTypeModal(params) {
  const loanAgreementType = params?.type

  const [type, setType] = React.useState("")

  const saveCondition = () => {
    if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
      params.callBackOnAction(type, loanAgreementType)
    }
    closeGlobalModal()
  }

  const closeCondition = () => {
    closeGlobalModal()
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{params?.modalHeading}</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title={params?.modalInputLabel}
            component={
              <Grid xs={5} item>
                <TextBoxComponent
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value)
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            }
            isMandatory={false}
            showErrors={false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={saveCondition} loadingbtn={true} color="info" />
      </Stack>
    </Box>
  )
}

export default SecurityDocumentTypeModal
