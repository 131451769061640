import { useState } from "@hookstate/core";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Grid, Paper, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../../../../configs/base64Conversion";
import { FILE_STATUS } from "../../../../../../../../configs/constants/documentUploadKeys";
import { appSetting } from "../../../../../../../../configs/mainStore";
import { getInspectionDocument } from "../../../../../../../../services/documentApis";
import { getUserDetailsById } from "../../../../../../../../services/userService";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { DocumentPreviewRow } from "../../../../DocumentPreviewRow";
import { ErrorMessageComponent } from "../../../../UserCreditComponents/ErrorMessageComponent";

export const InspectionDocumentPreviewComponent: FC = (params?: any) => {
  const appSettings: any = useState(appSetting);
  const [previewLoading, setPreviewLoading] = React.useState(true);
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<any>(null);
  const [documentMeta, setDocumentMeta] = React.useState<any>(null);
  const [documentContentType, setDocumentContentType] = React.useState<any>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const fileDetails = params?.fileDetails;
  const [progress, setProgress] = React.useState<number>(0);

  useEffect(() => {
    (async () => {
      setPreviewLoading(true);

      let documentDetails = {
        docId: fileDetails?.id
      };

      const result = await getInspectionDocument(documentDetails, (progress) => {
        setProgress(progress);
      });

      if (result?.fileData && result?.contentType) {
        const fileBuffer = result?.fileData.data;
        const contentType = result?.contentType;

        const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

        setDocumentBlobUrl(blobUrl);
        setDocumentMeta(result);
        setDocumentContentType(contentType.split("/")[0]);
      } else {
        setErrorMessage("File not Found!");
      }

      setPreviewLoading(false);
    })();
  }, [fileDetails]);

  const previewClicked = (blobUrl) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const BuildProgress = (progress: number) => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={"500px"}>
        <CircularProgressComponent variant={progress < 10 ? undefined : "determinate"} size={28} value={progress} />
      </Box>
    );
  };

  return (
    <Paper elevation={0} sx={{ minHeight: "70vh" }}>
      {errorMessage ? (
        <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
      ) : (
        <Grid container direction="column" sx={{ minHeight: "60vh" }}>
          <Grid container item spacing={2} direction="row" xs={12}>
            <Grid item xs={12} lg={5} style={{ borderRight: "1px solid #454c661a" }}>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ backgroundColor: "#ffffff", borderRadius: "5px", textAlign: "center" }}>
                <Grid item xs={6} justifyContent="center" style={{ padding: "30px 0" }}>
                  {previewLoading ? (
                    BuildProgress(progress)
                  ) : (
                    <ButtonComponent
                      startIcon={<VisibilityIcon />}
                      title={"Preview Document"}
                      variant="contained"
                      onClick={() => {
                        previewClicked(documentBlobUrl);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              {documentContentType === "image" && <img src={documentBlobUrl} style={{ width: "100%" }}></img>}
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container item spacing={1} direction="row" xs={12}>
                <Grid item xs={12} lg={12}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <h4 className="basic-font" style={{ marginBottom: 0 }}>
                      Current File Details
                    </h4>
                    <Button
                      onClick={() => params.onNext()}
                      disabled={params.onNextDisable}
                    >
                      Next
                    </Button>
                  </Stack>
                  <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                  <Box sx={{ boxShadow: 1 }}>
                    <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={CreditCardIcon} subTxt={"Status"} mainTxt={FILE_STATUS[documentMeta?.STATUS]} status={documentMeta?.STATUS} />
                      </Grid>
                    </Grid>

                    <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={documentMeta?.CREATED_BY ? getUserDetailsById(documentMeta?.CREATED_BY)[appSettings?.get().language]?.name : "--"} status={documentMeta?.STATUS} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={documentMeta?.CREATED_DATE ? dateFormatWithTimeChange(documentMeta?.CREATED_DATE) : "--"} status={documentMeta?.STATUS} />
                      </Grid>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Verified By"} mainTxt={documentMeta?.verifiedBy ? getUserDetailsById(documentMeta?.verifiedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.STATUS} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Verified Date"} mainTxt={documentMeta?.verifiedDate ? dateFormatWithTimeChange(documentMeta?.verifiedDate) : "--"} status={documentMeta?.STATUS} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
