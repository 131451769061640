import { useState } from "@hookstate/core";
import {
	Box,
	Checkbox,
	Grid,
	List,
	ListItem,
	ListItemButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SearchComponent from "../../../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";

import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { decisionRuleTypes } from "../../../../configs/mainStore";

function AllowedValidation({ configs, onChange, onSave, stageId }) {
	const searchText = useState("");
	const decisionRules = useState(decisionRuleTypes);
	const loading: any = useState("");
	const saving: any = useState(false);
	const [rulesList, setRulesList] = React.useState<any>([]);
	const [totalRules, setTotalRules] = React.useState<any>([]);

	const processSearchText = (text) => {
		searchText.set(text);
	};

	useEffect(() => {
		fetchSelectedRules();
	}, []);

	useEffect(() => {
		let selectedStage = stageId || configs?.selectedStage?.get() || "*";
		const list: any = configs?.validations?.get() ?? {};
		const stringifiedObject = JSON.parse(JSON.stringify(list));
		const selectedValidations = stringifiedObject[selectedStage] || stringifiedObject["*"];

		if (selectedValidations && selectedValidations.length > 0) {
			setRulesList([...selectedValidations]);
		} else {
			setRulesList([]);
		}
	}, [stageId, configs?.selectedStage?.get()]);

	const fetchSelectedRules = async () => {
		const rules: any = decisionRules?.get();
		const list = Object.values(rules)?.map((obj: any) => { return { id: obj.key, name: obj.title } });
		if (list) {
			setTotalRules(list);
		}
	};

	const handleSelection = (id) => {
		let rules: any = [];

		if (rulesList.includes(id)) {
			rules = rulesList.filter((ruleId) => ruleId !== id);
		} else {
			rules = [...rulesList, id];
		}
		setRulesList(rules);
	};

	const handleSave = (section) => {
		try {
			loading.set(true);
			const activeConfigs = configs?.validations?.get() ?? {};
			const selectedStage = stageId || configs?.selectedStage?.get() || "*";
			const stringifiedObject = JSON.parse(JSON.stringify(activeConfigs));

			stringifiedObject[selectedStage] = rulesList;

			configs.validations.set(stringifiedObject);

			onSave(section);
			loading.set(false);
		} catch (error) { }
	};

	return <Paper elevation={0}>
		<Box p={1}>
			<Grid container className="align-center" columns={8} pl={1} pr={1}>
				<Grid item xs={8} sm={8} className="align-center">
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<Stack justifyContent="flex-start" direction="row">
							<Stack direction="column" alignItems="flex-start">
								<Typography color="gray">Allowed Evaluation Rules</Typography>
								<Typography variant="caption" color="gray">
									{`Selected Rules (${rulesList.length}/${totalRules.length})`}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Box>
		<Box p={1}>
			<div className="relative w-[200px] h-[40px] items-center justify-center">
				<div>
					<Box m={2}>
						<SearchComponent
							placeholder={undefined}
							handleChangeSearchText={processSearchText}
						/>
					</Box>
				</div>
			</div>

			{loading.get() ? (
				<Box
					sx={{ height: "200px" }}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<CircularProgressComponent size={25} />
				</Box>
			) : (
				<List component="nav">
					<div
						className="basic-font basic-font-color font-size-15 font-weight-100"
						style={{
							marginBottom: 0,
							marginTop: 0,
							overflowX: "hidden",
							overflowY: "scroll",
							maxHeight: "calc(100vh - 350px)",
						}}
					>
						{totalRules?.length > 0 &&
							totalRules
								.filter((p: any) =>
									p?.name?.toLowerCase()?.includes(searchText.get())
								)
								.reduce((objs, element) => {
									if (rulesList.includes(element.id)) {
										return [element, ...objs];
									}
									return [...objs, element];
								}, [])
								.map((p: any, index) => (
									<ListItem divider key={index} secondaryAction={
										<Checkbox
											edge="end"
											checked={rulesList.includes(p.id)}
											onClick={(e) => {
												handleSelection(p.id);
											}}
										/>
									}
										disablePadding sx={{ paddingTop: 1, paddingBottom: 1 }}>
										<ListItemButton
											style={{ height: 55 }}
											selected={rulesList.includes(p.id)}
											onClick={(e) => {
												handleSelection(p.id);
											}}
										>
											<Tooltip title={p.id}>
												{p.name}
											</Tooltip>

										</ListItemButton>
									</ListItem>
								))}
					</div>
				</List>
			)}
			<Box>
				<Stack direction="row-reverse" spacing={2}>
					<Box
						className="relative w-[200px] h-[40px] items-center justify-center basic-font basic-font-color font-size-14 align-left"
						style={{ marginTop: "15px", marginBottom: "18px" }}
					></Box>
					{saving.get() ? (
						<CircularProgressComponent size={25} />
					) : (
						<ButtonComponent
							disabled={false}
							variant="contained"
							loadingbtn={false}
							loading={false}
							onClick={() => {
								handleSave("update-rules");
							}}
							title={"Save"}
						/>
					)}
					<ButtonComponent
						disabled={false}
						variant="contained"
						loadingbtn={false}
						loading={false}
						onClick={() => { closeGlobalModal() }}
						title={"Close"}
					/>
				</Stack>
			</Box>
		</Box>
	</Paper>;
}

export default AllowedValidation;
