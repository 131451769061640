import Api from "./apiCallsHandler";
import { ERROR_CODES } from "../configs/constants/errorCode";
import URL from "../configs/connection";
const api = Api.getApi();

const sendApplicationsHistory = `${URL.REACT_APP_BACKEND_HOST}/los/applications-history`;

export const createApplicationsHistory = async (
  applicationId,
  message,
  requestId = null,
  payload = {},
  url = ""
) => {
  try {
    const historyData = {
      applicationId: applicationId,
      requestId: requestId,
      message: message,
      url: url,
      payload: JSON.stringify(payload),
    };
    sessionStorage.setItem(
      "los/applications-history/" + applicationId,
      JSON.stringify(historyData)
    );

    const { data } = await api.post(`${sendApplicationsHistory}`, historyData);

    if (data.status === ERROR_CODES.success) {
      sessionStorage.removeItem("los/applications-history" + applicationId);
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
