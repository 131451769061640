import { Downgraded, useHookstate } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { CORE_BANK_KEYS, securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import { branchDetails, creditData } from "../../../../../../configs/mainStore";
import { getCoreBankData } from "../../../../../../services/bankServiceInvorker";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import { SinglePageBreak } from "../CommonPageBreaks";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import Body from "./Body";
import Header from "./Header";
import Signatures from "./Signatures";

export const SignatureVerification = ({ data, applicationId }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const componentRef: any = useRef();
  const [isLoading, setIsLoading] = React.useState(true);
  const documentId = securityDocumentPermissions.signatureVerification || "signature_verification";
  const [typeCase, setTypeCase] = React.useState(1);
  const payload = useHookstate({});
  const [typeCaseClass, settypeCaseClass] = React.useState();

  const permissionToPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });


  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const { trialData, coreData } = data || {};
      const { REC_ACC: recoveryAccount = "" } = coreData || await Promise.all([getCoreBankData(applicationId, CORE_BANK_KEYS.REC_ACC)]);

      let { LoanAmount: loanAmount } = trialData?.trailCalParams || {};
      const { scheme } = creditFileData;
      if (!loanAmount) {
        const { loanAmount: _loanAmount } = creditFileData.formData.creditData;
        loanAmount = _loanAmount;
      }

      payload.set({ loanAmount, recoveryAccount, scheme });
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <>
      {isLoading ? (
        <Stack direction="row" justifyContent="center" alignItems="center" height={"300px"}><CircularProgressComponent
          size={30}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        /></Stack>
      ) : (
        <Grid container className="inner-component-full-height basic-font">
          <style>{getPageMargins()}</style>
          <div className="full-width full-height">
            <SecurityDoceumentsFileDocumentsViewSection data={data} applicationId={applicationId} isSecurityDocument={true} securityDocumentKey={"signature-verification"} />
            <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef} columns={24}>
              <CommonSecurityDocHeader documentName={"Signature Verification Form"} textCase={"lower"} />
              <Grid item pt={0} pr={1} pb={1} pl={1} xs={24}>
                <Header branch={nearestBranch} payload={payload.get()} typeCaseClass={typeCaseClass} />
              </Grid>
              <Grid item p={1} xs={24}>
                <Body data={creditFileData} typeCaseClass={typeCaseClass} />
              </Grid>
              <SinglePageBreak />
              <Grid item p={1} xs={24}>
                <Signatures />
              </Grid>
            </Grid>
            <Box m={1}>
              <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Grid item xs={4} pl={1} pr={1}>
                  <SelectComponent label={"Select Typecase"} value={typeCase} values={Object.values(securityDocumentTextCase)} onChange={(e) => setTypeCase(e.target.value)} required={false} defaultPlaceholder={true} />
                </Grid>
                <Grid item xs={4}>
                  <ButtonComponent
                    startIcon={<LocalPrintshopIcon />}
                    title={"Print PDF"}
                    variant="contained"
                    onClick={() => {
                      handlePrint();
                    }}
                    style={{ maxHeight: "40px", marginTop: "auto" }}
                    disabled={permissionToPrint}
                    loadingbtn={true}
                    loading={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      )}
    </>
  );
};
