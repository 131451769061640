import { Stack } from "@mui/material";
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalModal } from "./components/OtherComponents/GlobalModal/GlobalModal";
import GlobalToast from "./components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "./components/OtherComponents/ProgressComponent/ProgressComponent";
import { useInitializer } from "./utility/CustomHooks/Initializer";

function App(props: any) {
  const { loading, authenticated } = useInitializer();

  const MainLayout = lazy(() => import("./layouts/MainLayout"));
  const LoginForm = lazy(() => import("./layouts/LoginForm"));

  const ScreenManager = () => {
    if (loading) {
      return <Stack style={{ height: "100vh", width: "100vw" }} justifyContent={"center"} alignItems={"center"}><CircularProgressComponent
        size={30}
      /></Stack>;
    }

    if (!authenticated) {
      return <LoginForm />
    }

    return <MainLayout />;
  }

  return (
    <BrowserRouter>
      <GlobalToast />
      <GlobalModal />
      {<Suspense fallback={<Stack style={{ height: "100vh", width: "100vw" }} justifyContent={"center"} alignItems={"center"}><CircularProgressComponent
        size={30}
      /></Stack>}>
        <ScreenManager />
      </Suspense>}
    </BrowserRouter>
  );
}

export default App;
