import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useState } from "react";
import { MASTER_DATA_KEYS as MD_KEYS } from '../../../../../../../../../../configs/constants/masterDataKeys';
import MasterDataProvider from "../../../../../../../../../../utility/MasterDataProvider";
import AutoCompleteProxyComponent from "../../../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from '../../../../../../../../../InputComponents/ButtonComponent/ButtonComponent';
import { closeGlobalModal } from '../../../../../../../../GlobalModal/GlobalModal';
import { inspectionBusinessLiabilityExpensesCategories } from "../../../../../../CreditInspection/Helpers/utility";

const BalanceSheetModal = ({ assetDescription, liabilityDescription }) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const selectedTypes = [{ id: "assetDescription", name: "Asset" }, { id: "liabilityDescription", name: "Liability" }];
    const [type, setType] = useState("");
    // const defaultDataObject = { title: "Total Assets", dataKey: "totalAssets", type: "autoPopulated" }

    const selectedTypeData = {
        assetDescription: { state: assetDescription, title: "Asset Category", subTitle: "Asset Type", masterDataMap: masterDataStore.getMasterData(MD_KEYS.ASSETS_CATEGORY), subMasterDataMap: masterDataStore.getMasterData(MD_KEYS.ASSET_TYPE) },
        liabilityDescription: { state: liabilityDescription, title: "Liability Category", subTitle: "", masterDataMap: inspectionBusinessLiabilityExpensesCategories },
    }

    const handleClose = () => closeGlobalModal();

    return (
        <>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <ButtonComponent onClick={handleClose} startIcon={<CloseIcon color="error" fontSize="medium" />} />
            </Box>
            <Grid container gap={2}>
                <Grid item xs={12}>
                    <Typography color={"gray"} pb={2} children={"Add additional Assets or Liabilities"} />
                    <Divider />
                </Grid>
                <Grid item xs={3} py={2}>
                    <AutoCompleteProxyComponent
                        options={selectedTypes}
                        cls="select_basic"
                        onChange={(e) => setType(e.id)}
                        label={"Type Of Item"}
                        value={type} />
                </Grid>
                <Grid item xs={3} py={2}>
                    <AutoCompleteProxyComponent
                        options={selectedTypeData[type]?.masterDataMap || []}
                        cls="select_basic"
                        onChange={(e) => { }}
                        label={selectedTypeData[type]?.title}
                        value={type} />
                </Grid>
                {
                    selectedTypeData[type]?.subMasterDataMap ?
                        < Grid item xs={3} py={2}>
                            <AutoCompleteProxyComponent
                                options={selectedTypeData[type]?.subMasterDataMap || []}
                                cls="select_basic"
                                onChange={(e) => { }}
                                label={selectedTypeData[type]?.subTitle}
                                value={type} />
                        </Grid>
                        : <></>
                }
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} gap={2}>
                    <ButtonComponent
                        onClick={() => { }}
                        variant="contained"
                        title={"Apply"}
                    />
                    <ButtonComponent
                        onClick={handleClose}
                        variant="contained"
                        title={"Close"}
                    />
                </Grid>

            </Grid >
        </>
    )
}

export default BalanceSheetModal
