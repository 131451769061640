import { FC } from "react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProviderPropsTypes } from "../../../configs/interfaces";

export const LocalizationProviderComponent: FC<
  LocalizationProviderPropsTypes
> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {children}
    </LocalizationProvider>
  );
};
