import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ApplicationHeader from "./ApplicationHeader";
import GuarantorDetails from "./security/GuarantorDetails";
import { IntermediateDetailsSanitizer } from "./securitySubSectionDataBuilder/IntermediateDetailsSanitizer";

const SecurityDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  const securityData: any = data?.formData?.securityData;

  const paperDocDetails = securityData?.paperDocs ? securityData?.paperDocs : {};
  const imMovableDetails = securityData?.immovable ? securityData?.immovable : {};
  const movableDetails = securityData?.movable ? securityData?.movable : {};

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={" Security Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container xs={12} md={12} className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Collateral Details
            </Grid>
            <Grid item xs={12} md={12}>
              <IntermediateDetailsSanitizer collateralData={paperDocDetails} title={translate("paperDocs")} />
            </Grid>
            <Grid item xs={12} md={12}>
              <IntermediateDetailsSanitizer collateralData={movableDetails} title={translate("movableDetails")} />
            </Grid>
            <Grid item xs={12} md={12}>
              <IntermediateDetailsSanitizer collateralData={imMovableDetails} title={translate("immovable")} />
            </Grid>
            <Grid item xs={12} md={12}>
              <GuarantorDetails data={data} applicationId={applicationId} mainTabData={mainTabData} isRawVersion={isRawVersion} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SecurityDetails;
