import React from "react";
import { validationSmartHandler, validValueFilter } from "../Helpers/ValidationExamingPlot";

export const inspectionSummery = (structure, state, validationMap) => {
  let errorArray: any[] = validationSmartHandler(structure, state);
  const { summery: { inspectionOfficerData } } = validationMap;

  let intermediateErrorArray: any[] = [];
  for (const object of state.inspectionOfficerData) {
    if (object.removedItem === true) {
      continue;
    }

    inspectionOfficerData.forEach((key: string) => {
      if (object.hasOwnProperty(key)) {
        validValueFilter(object[key], intermediateErrorArray, key);
      } else {
        intermediateErrorArray.push(key);
      }
    });

    if (intermediateErrorArray.length > 0) {
      break;
    }
  }

  errorArray = [...errorArray, ...intermediateErrorArray];

  return errorArray;
};
