import { Tooltip } from "@mui/material";

export const InfoToolTip = ({ data, children }) => {
  let population = data?.populated ?? "";

  return (
    <Tooltip
      title={
        <>
          <div>Status - {data?.status ?? ""}</div>
          <div>{population}</div>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export const SelectComponentTooltip = ({ data, children }) => {
  return (
    <Tooltip title={<>{data}</>}>
      <div>{children}</div>
    </Tooltip>
  );
};
