import { useHookstate } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { getActivityHistoryList, getApplicationHistoryDetails, getApplicationHistoryFilteredData } from "../../../../../services/creditFileApiCall";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import InfoIcon from "@mui/icons-material/Info";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import { historySectionMapping } from "../../../../../configs/constants/contants";
import { VARIANT } from "../../../../../configs/constants/styleValues";
import { prepareDateText } from "../../../../../services/prepareDateText";
import { getUserDetailsById } from "../../../../../services/userService";
import { select_basic } from "../../../../../styles";
import { dateFormatWithDate, dbDateFormatterFn, timeFormatWithTimeChange } from "../../../../../utility/fomatters";
import { Access, getUserDesignation, userName } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import ValidateDatePicker from "../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { InputFieldsStack } from "../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { IconRowComponent } from "../IconRowComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

var oldDate = new Date();
oldDate.setMonth(oldDate.getMonth() - 1);

const ApplicationHistoryRevamp = ({ data, applicationId, innerComponentData }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [activityHistoryList, setActivityItemList] = React.useState<any>();
  const [fromDateSelect, setFromDateSelect] = React.useState<any>(oldDate);
  const [toDateSelect, setToDateSelect] = React.useState<any>(new Date());

  const [sectionDropdownValue, setSectionDropdownValue] = React.useState<any>("");
  const [userDropdownValue, setUserDropdownValue] = React.useState<any>("");
  let lock = false;
  const dropDowns = useHookstate({ users: [], sections: [] });

  useEffect(() => {
    loadData();

    return () => {
      lock = true;
    };
  }, [applicationId]);

  const loadData = async () => {
    const [listData = [], { data }] = await Promise.all([getActivityHistoryList(applicationId), getApplicationHistoryDetails({
      applicationId: applicationId,
    })]);

    let applicationSections: any = data?.sections?.filter((obj) => obj.URL != "/audit/log");

    let sections: any, users: any = [];

    sections = applicationSections?.reduce((acc, item) => {
      const { URL } = item;
      const mappedValue = historySectionMapping[URL];
      acc.push({
        id: URL,
        name: mappedValue,
      });
      return acc;
    }, []);

    users = data?.users?.reduce((acc: any, item) => {
      const { RESPONSIBLE_BY: id } = (item as any);
      acc.push({
        id,
        name: userName(id),
      });
      return acc;
    }, []);

    if (lock) {
      return;
    }

    dropDowns.set({
      users: users,
      sections: sections,
    });

    setActivityItemList(listData);

    setIsLoading(false);
  }

  const submitApplicationFilter = async (e?: any) => {
    setIsLoading(true);

    const params = {
      applicationId: applicationId,
      fromDate: dbDateFormatterFn(fromDateSelect),
      toDate: dbDateFormatterFn(toDateSelect),
      section: sectionDropdownValue,
      user: userDropdownValue,
    };

    const { data = [] } = await getApplicationHistoryFilteredData(params);
    setActivityItemList(data);

    setIsLoading(false);
  };

  const BuildActivityLogItem = ({ item }) => {
    const dataObject = JSON.parse(item?.other);
    const newDataJson = dataObject?.data?.newJson ?? [];
    const oldDataJson = dataObject?.data?.oldJson ?? [];
    const type = historySectionMapping?.[item?.url?.replaceAll("/audit/log/", "/")];

    return (
      <Grid container item direction="row" xs={12}>
        <Grid container item sm={3} style={{ textAlign: "left" }}>
          <IconRowComponent Icon={InsertInvitationIcon} subTxt={""} mainTxt={item?.created ? dateFormatWithDate(item?.created) : "--"} color={"#1976d2"} secondaryTxt={item?.created ? timeFormatWithTimeChange(item?.created) : "--"} />
        </Grid>

        <Grid container item sm={3} style={{ textAlign: "left" }}>
          <div>
            <IconRowComponent Icon={PersonIcon} subTxt={""} mainTxt={item?.responsibleBy ? getUserDetailsById(item?.responsibleBy)["EN"]?.name : "--"} color={"#1976d2"} designation={item?.responsibleBy ? getUserDesignation(item?.responsibleBy) : "Designation Not Available"} />
          </div>
        </Grid>
        <Grid container item sm={2.5} style={{ textAlign: "left" }}>
          <IconRowComponent Icon={PlaceIcon} subTxt={""} mainTxt={item?.url ? type : "--"} color={"#1976d2"} />
        </Grid>
        <Grid container item sm={3.5} style={{ textAlign: "left" }} justifyContent={"space-between"}>
          <IconRowComponent Icon={ChatBubbleIcon} subTxt={""} mainTxt={item?.message ? item?.message : "--"} color={"#1976d2"} />
          {newDataJson?.length !== 0 && oldDataJson?.length !== 0 && (
            <span onClick={() => handleOnClick(item)} style={{ cursor: "pointer" }}>
              <IconRowComponent Icon={InfoIcon} subTxt={""} mainTxt={""} color={"#1976d2"} />
            </span>
          )}
        </Grid>
      </Grid>
    );
  };

  const handleOnClick = (item) => {
    openGlobalModal({
      modalSize: "md",
      title: `${item?.message}`,
      bodyId: "application-changes-modal",
      close: false,
      modalParams: {
        item: item,
      },
    });
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={[]} disabled={!Access.privilege} />
      <Grid item container>
        <Stack spacing={1} m={1} className="full-width" alignItems="right">
          <InputFieldsStack>
            <InputGrid visibility={1} variant={VARIANT.small}>
              <ValidateDatePicker
                label={"From (DD/MM/YYYY)"}
                value={fromDateSelect}
                onChange={(date) => {
                  setFromDateSelect(prepareDateText(date));
                }}
                required={true}
                enableFuture={false}
                disabled={isLoading}
              />
            </InputGrid>
            <InputGrid visibility={1} variant={VARIANT.small}>
              <ValidateDatePicker
                label={"To (DD/MM/YYYY)"}
                value={toDateSelect}
                onChange={(date) => {
                  setToDateSelect(prepareDateText(date));
                }}
                required={true}
                enableFuture={false}
                disabled={isLoading || (fromDateSelect ? false : true)}
              />
            </InputGrid>
            <InputGrid visibility={1} variant={VARIANT.small}>
              <SelectComponent sx={select_basic} label={"Section"} value={sectionDropdownValue} values={dropDowns.sections.get() || []} onChange={(e) => setSectionDropdownValue(e.target.value)} defaultPlaceholder={true} disabled={isLoading} />
            </InputGrid>
            <InputGrid visibility={1} variant={VARIANT.small}>
              <SelectComponent sx={select_basic} label={"User"} value={userDropdownValue} values={dropDowns.users.get() || []} onChange={(e) => setUserDropdownValue(e.target.value)} defaultPlaceholder={true} disabled={isLoading} />
            </InputGrid>
            <InputGrid visibility={1} variant={VARIANT.small}>
              <ButtonComponent title={"Submit"} variant="contained" style={{ width: "120px" }} onClick={() => submitApplicationFilter()} disabled={isLoading || (fromDateSelect && toDateSelect ? false : true)} loading={true} sx={{ alignSelf: "center" }} />
            </InputGrid>
          </InputFieldsStack>
        </Stack>
      </Grid>
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <Stack flex={1} justifyContent={'center'} alignItems={'center'} height={"200px"}><CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            /></Stack>
          ) : activityHistoryList && activityHistoryList.length > 0 ? (
            <>
              <Grid container className="full-width border-radius-5" alignItems="center" p={1} style={{ background: "#f8f9fa" }}>
                <Grid item xs={3}>
                  <div className=" font-size-14  border-radius-5 align-left basic-font font-size-16 basic-font-color" style={{ margin: 2, marginLeft: 25, minWidth: "100% " }}>
                    Time
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className=" font-size-14  border-radius-5 align-left basic-font font-size-16 basic-font-color" style={{ margin: 2, marginLeft: 25, minWidth: "100% " }}>
                    User
                  </div>
                </Grid>
                <Grid item xs={2.5}>
                  <div className=" font-size-14  border-radius-5 align-left basic-font font-size-16 basic-font-color" style={{ margin: 2, marginLeft: 25, minWidth: "100% " }}>
                    Section
                  </div>
                </Grid>

                <Grid item xs={3.5}>
                  <div className=" font-size-14  border-radius-5 align-left basic-font font-size-16 basic-font-color" style={{ margin: 2, marginLeft: 25, minWidth: "100% " }}>
                    Change
                  </div>
                </Grid>
              </Grid>

              {activityHistoryList?.map((item, i) => {
                return (
                  <Grid container direction="column" className="data-box" p={2} key={i}>
                    <BuildActivityLogItem item={item} />
                  </Grid>
                );
              })}
            </>
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ApplicationHistoryRevamp;
