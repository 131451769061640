import {
  validateEmail,
  validatelength,
  validateMandatory,
  validateMobileNumber,
  validateName,
  validateNewNic,
  validateNewNicNumbering,
  validateNic,
  validateOldNic,
  validateOldNicNumbering,
  validatePhoneNumber,
  validateTextWithCharactersAndSpaces,
} from '../validation-rules/validationRules';

export const leadPersonalInfoRevampValidationHandler = (personalInfo) => {

  const initialsInFullVal = personalInfo?.initialsInFull.get()
    ? initialsInFullValidation('Initials in Full', personalInfo?.initialsInFull.get())
    : null;
  const lastNameVal = personalInfo?.primaryLastName.get()
    ? lastNameValidation('Last Name', personalInfo?.primaryLastName.get())
    : null;
  const usedNameVal = personalInfo?.usedName.get() ? usedNameValidation('Used Name', personalInfo?.usedName.get()) : null;
  const initialsVal = personalInfo?.initials.get() ? initialsValidation('Initials', personalInfo?.initials.get()) : null;
  const othernamesVal = personalInfo?.primaryOtherName.get()
    ? otherNamesValidation('Other Names', personalInfo?.primaryOtherName.get())
    : null;
  const oldNicVal = personalInfo?.oldNic.get() ? oldNicValidation('Old NIC', personalInfo?.oldNic.get()) : null;
  const newNicVal = personalInfo?.newNic.get() ? newNicValidation('New NIC', personalInfo?.newNic.get()) : null;

  const primaryContactVal = personalInfo?.primaryContact.get()
    ? primaryContactNoValidation('Primary Contact No', personalInfo?.primaryContact.get(), 10)
    : null;
  const primaryEmailVal = personalInfo?.primaryEmail.get()
    ? primaryEmailValidation('Primary Email Address', personalInfo?.primaryEmail.get())
    : null;

  const leadPersonalInfoValidationErrors: any = {};
  if (initialsInFullVal) leadPersonalInfoValidationErrors.initialsInFull = initialsInFullVal;
  if (lastNameVal) leadPersonalInfoValidationErrors.primaryLastName = lastNameVal;
  if (usedNameVal) leadPersonalInfoValidationErrors.usedName = usedNameVal;
  if (initialsVal) leadPersonalInfoValidationErrors.initials = initialsVal;
  if (othernamesVal) leadPersonalInfoValidationErrors.primaryOtherName = othernamesVal;
  if (oldNicVal) leadPersonalInfoValidationErrors.oldNic = oldNicVal;
  if (newNicVal) leadPersonalInfoValidationErrors.newNic = newNicVal;
  if (primaryContactVal) leadPersonalInfoValidationErrors.primaryContact = primaryContactVal;
  if (primaryEmailVal) leadPersonalInfoValidationErrors.primaryEmail = primaryEmailVal;
  // }

  return Object.keys(leadPersonalInfoValidationErrors).length > 0 ? leadPersonalInfoValidationErrors : 1;

  //================== Lead Personal Info Erros Object - End ==================//
};

const initialsInFullValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const lastNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const usedNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const initialsValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const otherNamesValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const oldNicValidation = (fieldName, value) => {
  const checkNewNic = validateOldNic(value);
  const checkLength = validatelength(fieldName, value);
  const checkNumbering = validateOldNicNumbering(value);
  const result: any[] = [];
  if (checkNewNic) result.push(checkNewNic);
  if (checkLength) result.push(checkLength);
  if (checkNumbering) result.push(checkNumbering);
  return result.length > 0 ? result : null;
};

export const newNicValidation = (fieldName, value) => {
  const checkNewNic = validateNewNic(value);
  const checkLength = validatelength(fieldName, value);
  const checkNumbering = validateNewNicNumbering(value);
  const result: any[] = [];
  if (checkNewNic) result.push(checkNewNic);
  if (checkLength) result.push(checkLength);
  if (checkNumbering) result.push(checkNumbering);
  return result.length > 0 ? result : null;
};

export const primaryContactNoValidation = (fieldname, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validateMobileNumber(fieldname, value);
  const checkLength = validatelength(fieldname, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const primaryEmailValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkEmail = validateEmail(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkEmail) result.push(checkEmail);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
