import { Downgraded, useState } from "@hookstate/core";
import React, { createRef, forwardRef, useEffect, useImperativeHandle } from "react";

import { Box, Grid } from "@mui/material";


import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import { checkIfAlreadyUploaded, getDocumentDataById } from "../../../services/documentUploadService";
import { SingleImageUploadComponent } from "../DropzoneComponent/SingleImageUploadComponent";

import { getPriorDocumentList } from "../../../services/applicationApis";
import { getCribRecordList } from "../../../services/creditFileApiCall";
import { disableAccess } from "../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { CribDocumentComponent } from "../DropzoneComponent/CribDocumentComponent";
import { PriorApprovalDocumentComponent } from "../DropzoneComponent/PriorApprovalDocumentComponent";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
interface ApplicationDocumentUploadSectionProps {
  primaryFiles?: any;
  applicantType?: any;
  applicationId?: any;
  participantId?: any;
  uploadedBy?: any;
  primaryUploadedFiles?: any;
  normalFiles?: any;
  normalUploadedFiles?: any;
  documentTypes?: any;
  uploadedDocumentTypes?: any;
  documentComponentId?: any;
  disbursed?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  reloadComponent?: any;
  isSecurityDocumentUpload?: any;
  currentWorkflowStageId?: any;
}

export type Ref = any;

export const ApplicationDocumentUploadSection = forwardRef<Ref, ApplicationDocumentUploadSectionProps>(({ applicantType, applicationId, participantId, uploadedBy, documentTypes = [], uploadedDocumentTypes, documentComponentId, disableAccordingToTab = false, reloadComponent = false, disbursed, isLoggedInUserNotTheAssignee }: ApplicationDocumentUploadSectionProps, docUploadRef) => {
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const [normalFileList, setNormalFileList] = React.useState<any>([]);
  const [isFilesHave, setIsFilesHave] = React.useState(false);
  const [cribDataItems, setCribDataItems] = React.useState([]);
  const [cribItemsLoading, setCribItemsLoading] = React.useState<boolean>(false);
  const [priorApprovalLoading, setPriorApprovalLoading] = React.useState<boolean>(false);
  const [priorApprovalDataItems, setPriorApprovalDataItems] = React.useState([]);
  const { documentList }: any = useState(documentUploadData);

  useEffect(() => {
    let tempDoc;

    setIsFilesHave(false);
    if (documentDetailsList) {
      setIsFilesHave(false);

      if (documentTypes.length > 0) {
        while (normalFileList.length > 0) {
          normalFileList.pop();
        }
        documentTypes.map((item: any) => {
          tempDoc = getDocumentDataById(documentDetailsList, item);
          const matchingFile = uploadedDocumentTypes.find(itemObj => itemObj.documentId === item.documentId);
          normalFileList.push({
            ...tempDoc,
            ...{
              isMandatory: item.isMandatory,
              ref: createRef(),
              status: item.status,
              fileName: matchingFile ? matchingFile.fileName : ''
            },
          });
        });

        setIsFilesHave(true);
      }

      if (normalFileList instanceof Array && normalFileList.length) {
        normalFileList?.sort((a, b) => (a.isMandatory > b.isMandatory ? -1 : b.isMandatory > a.isMandatory ? 1 : 0));
      }

      documentList.set(normalFileList);
    }

    return () => {
      documentList.set([]);
    }
  }, [documentDetailsList, documentTypes]);

  useEffect(() => {
    let filteredDocumentList: any[] = [];
    let nicsaArr: any[] = [];
    // Filter and remove NIC Front & NIC Back placeholders if not uploaded for old applications
    normalFileList.forEach((obj) => {
      if (obj.documentKey == "NIC_FRONT" || obj.documentKey == "NIC_BACK") {
        if (!checkNicDataAvailability(obj.documentId)) {
          nicsaArr.push(obj.documentId);
        }
      }
    });

    filteredDocumentList = normalFileList.filter((element) => {
      return nicsaArr.includes(element.documentId) == false;
    });

    setNormalFileList(filteredDocumentList);
  }, [uploadedDocumentTypes]);

  useEffect(() => {
    const mainDocTypes: any = ["borrowers_basic_documents", "joint_borrower_documents", "guarantor_documents"];
    if (mainDocTypes.includes(documentComponentId)) {
      (async () => {
        setCribItemsLoading(true);
        const cribResponse = await getCribRecordList(applicationId);

        let cribRecords: any = [];

        if (cribResponse) {
          const itemID = cribResponse?.participantEntryMap?.[participantId];
          const resultCribData = cribResponse?.resultCribList?.[itemID];

          if (resultCribData?.length > 0) {
            resultCribData.map((element: any) => {
              if (element?.isActive === 1) {
                cribRecords.push(element);
              }
            });
          }
        }

        setCribDataItems(cribRecords);
        setCribItemsLoading(false);
      })();
    } else if (documentComponentId === "other_documents") {
      (async () => {
        setPriorApprovalLoading(true);
        const { data } = await getPriorDocumentList({ applicationId });

        setPriorApprovalDataItems(data || []);
        setPriorApprovalLoading(false);
      })();
    }
  }, []);

  useImperativeHandle(docUploadRef, () => ({
    async normalDocumentUploadFunc() {
      const promises: any = [];

      normalFileList.map((item: any) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });

      return await Promise.all(promises).then(() => {
        return true;
      });
    },
  }));

  const checkNicDataAvailability = (id) => {
    let avalability: any = false;

    uploadedDocumentTypes.map((element) => {
      if (element.documentId == id) {
        if (element.filePath != "" && element.filePath != null) {
          avalability = true;
        }
      }
    });
    return avalability;
  };

  return (
    <Box>
      {isFilesHave ? (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Grid container rowSpacing={2}>
              {cribItemsLoading ? (
                <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
                  <CircularProgressComponent size={30} />
                </Grid>
              ) : (
                cribDataItems.map((item: any, i) => {
                  return <CribDocumentComponent key={i} fileDetails={item} />;
                })
              )}
              {priorApprovalLoading ? (
                <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
                  <CircularProgressComponent size={30} />
                </Grid>
              ) : (
                priorApprovalDataItems.map((item: any, i) => {
                  return <PriorApprovalDocumentComponent key={i} fileDetails={item} />;
                })
              )}
              {normalFileList.map((item: any, i) => {
                let { isHave, status } = checkIfAlreadyUploaded(item?.documentId, uploadedDocumentTypes);

                const documentPrivilages = disableAccess({
                  secDocumentFileId: item?.documentId,
                  documentComponentId: documentComponentId,
                  isTabInEditString: disableAccordingToTab,
                  privilegeKeyforSec: "SEC_DOC_UPLOAD_SIGNED_DOCUMENTS",
                  privilegeKeyforOther: "DOC_MANAGE_SYSTEM_UPLOAD_REPLACE_DOCUMENT",
                });

                return (
                  <SingleImageUploadComponent
                    key={i}
                    fileDetails={item}
                    uploadDataObject={{
                      applicationId: applicationId,
                      applicantType: applicantType,
                      participantId: participantId,
                      documentId: item?.documentId,
                      uploadedBy: uploadedBy,
                      isPrimary: false,
                      scheduleId: 1
                    }}
                    isPreviewHave={isHave}
                    status={status}
                    permissionToUploadDocs={documentPrivilages}
                    documentComponentId={documentComponentId}
                    reloadComponent={reloadComponent}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>Empty Document List</>
      )}
    </Box>
  );
});
