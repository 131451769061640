import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { branchCodeMap } from "los-util";
import React, { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import AutoCompleteProxyComponent from "../../components/InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { CommunicationLanguageSelect } from "../../components/InputComponents/SelectComponent/LanguageSelectComponent";
import { SelectComponent } from "../../components/InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../components/OtherComponents/FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { closeGlobalModal, openGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster, toastNotification } from "../../components/OtherComponents/GlobalToast";
import { InputFieldsStack } from "../../components/OtherComponents/InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../components/OtherComponents/InputGrid/InputGrid";
import { ScreenFooterButtons } from "../../components/OtherComponents/LoanCalculatorComponent/Calculator/Calculator";
import { CircularProgressComponent } from "../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { MASTER_DATA_KEYS } from "../../configs/constants/masterDataKeys";
import { privilegeKeys } from "../../configs/constants/privilegeKeys";
import { endpoints } from "../../configs/endpoints";
import { branchDetails, userDetails } from "../../configs/mainStore";
import Api from "../../services/apiCallsHandler";
import { OnDemandValueFormatter, OnDemandValueValidator } from "../../services/onDemandValidators";
import { isPrivilegeHave } from "../../services/utilService";
import AppConfig from "../../utility/AppConfig";
import MasterDataProvider from "../../utility/MasterDataProvider";
import { hashValue } from "../../utility/other";

export default function UserRegisterLayout({ /*setScreen*/ /*loadUsers*/ componentId = "", userDetail = null, callBackOnAction, nOption = false }) {
  const details: any = userDetail;
  const institutionState: any = useHookstate(branchDetails);
  const [userId, setUserId] = useState(details?.USER_ID);
  const [loading, setLoading] = useState(false);
  const [districtBranchValidation, setDistrictBranchValidation] = useState<any>();
  const [saveClicked, setSaveClicked] = useState(false);
  const api = Api.getApi();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();

  // New States
  const [userTypeList, setUserTypeList]: any = React.useState([]);
  const [locationList, setLocationList]: any = React.useState([]);
  const [userRoleList, setUserRoleList]: any = React.useState([]);
  const userRoleMapping = AppConfig.config.userRoleMapping || {};

  useEffect(() => {
    setUserTypeDetails();
    if (userData?.userType != 3) {
      setLocationDetails(userData?.userType || "");
    }

    setUserRoleDetails(userData?.userType || "", userData?.location || "");
  }, []);

  const findDistric = (branch) => {
    let districs = institutionState?.branchListByInstitutionCode?.["rdb"].get();
    let distric: any = null;
    Object.keys(districs).forEach((key) => {
      districs[key].filter((item) => {
        if (branch === item?.id) {
          distric = key;
        }
      });
    });
    return parseInt(distric);
  };

  const [userData, setUserData]: any = useState({
    empId: details ? details.EMPLOYEE_ID : "",
    userName: details ? details.USERNAME?.toLowerCase() : "",
    firstName: details ? details.FIRST_NAME : "",
    middleName: details ? details.MIDDLE_NAME : "",
    lastName: details ? details.LAST_NAME : "",
    email: details ? details?.EMAIL : "",
    contactNumber: details ? details?.MOBILE_NUMBER : "",
    language: details ? details?.LANGUAGE_ID : "",
    userType: details ? details?.USER_TYPE : null,
    userRole: details ? details?.DESIGNATION : null,
    location: details ? details?.OFFICE_LOCATION : "",
    distric: details ? findDistric(details?.ASSIGNED_BRANCH) : "",
    branch: details ? details?.ASSIGNED_BRANCH : "",
    password: null,
    retypedPassword: null,
    managerisccu: districtBranchValidation,
  });

  const nearestBranches = (userData.distric && branchDetails?.branchListByInstitutionCode?.["rdb"]?.[userData.distric]?.get()) || [];

  const setUserTypeDetails = () => {
    setLocationList([]);
    setUserRoleList([]);

    let userTypeKeys: any[] = [];
    const userTypes: any[] = [];
    userTypeKeys = Object.keys(userRoleMapping);
    if (userTypeKeys.length > 0) {
      userTypeKeys.forEach((type) => {
        let listObj = {
          id: userRoleMapping[type].id,
          name: userRoleMapping[type].name,
        };
        userTypes.push(listObj);
      });
      setUserTypeList(userTypes);
    }
  };

  const setLocationDetails = (selectedUserType: any = "") => {
    setUserRoleList([]);
    if (selectedUserType) {
      let mappedLoacationKeys: any[] = [];
      const mappedLoacations: any[] = [];
      mappedLoacationKeys = Object.keys(userRoleMapping[selectedUserType]["children"]);

      if (mappedLoacationKeys.length > 0) {
        mappedLoacationKeys.forEach((location) => {
          let listObj = {
            id: location,
            name: userRoleMapping[selectedUserType]["children"][location].name,
          };
          mappedLoacations.push(listObj);
        });

        setLocationList(mappedLoacations);
      }
    } else {
      setLocationList([]);
    }
  };

  const setUserRoleDetails = (selectedUserType: any = "", selectedLocation: any = "") => {
    if (selectedLocation && selectedUserType && selectedUserType != 3) {
      let mappedUserRoleKeys: any[] = [];
      const mappedUserRoles: any[] = [];

      mappedUserRoleKeys = Object.keys(userRoleMapping?.[selectedUserType]?.["children"]?.[selectedLocation]?.["children"] || {});

      if (mappedUserRoleKeys.length > 0) {
        mappedUserRoleKeys.forEach((userRole) => {
          let listObj = {
            id: userRole,
            name: userRoleMapping[selectedUserType]["children"][selectedLocation]["children"][userRole].name,
          };
          mappedUserRoles.push(listObj);
        });

        setUserRoleList(mappedUserRoles);
      }
    } else if (selectedUserType == 3) {
      let mappedUserRoleKeys: any[] = [];
      const mappedUserRoles: any[] = [];

      mappedUserRoleKeys = Object.keys(userRoleMapping?.[selectedUserType]?.["children"] || {});

      if (mappedUserRoleKeys.length > 0) {
        mappedUserRoleKeys.forEach((userRole) => {
          let listObj = {
            id: userRoleMapping[selectedUserType]["children"][userRole].id,
            name: userRoleMapping[selectedUserType]["children"][userRole].name,
          };
          mappedUserRoles.push(listObj);
        });

        setUserRoleList(mappedUserRoles);
      }
    } else {
      setUserRoleList([]);
    }
  };

  const viewGroups = (modalBodyKey) => {
    openGlobalModal({
      modalSize: "md",
      title: "Assign To Privilege Group(s)",
      bodyId: modalBodyKey,
      close: true,
      modalParams: {
        userId: userId,
        actionBackToList: () => {
          navigateToMainScreen(null);
        },
      },
    });
  };

  const extractCodeMappedBranches = () => {
    let branches: any = [];
    for (const _branch of nearestBranches) {
      const code = branchCodeMap[_branch["name"]];
      let branch = { ..._branch };
      if (code) {
        branch["name"] = `${_branch["name"]} - ${code}`;
      }
      branches.push(branch);
    }
    return branches;
  };

  const handleSave = () => {
    setUserData({});
    setLoading(false);
  };

  const mandatoryValidation = (data) => {
    let toasterMsg: any = [];

    const validationMap = {
      empId: "Employee ID can not be empty.",
      userName: "User Name can not be empty.",
      firstName: "First name cannot be empty.",
      lastName: "Last name cannot be empty.",
      language: "Language cannot be empty.",
      userType: "User Type cannot be empty.",
    };
    const validationLocationMap = {
      distric: "Distric cannot be empty.",
      branch: "Branch cannot be empty.",
      location: "Office / Location cannot be empty.",
    };

    for (const [key, message] of Object.entries(validationMap)) {
      if (!data[key]) {
        toasterMsg.push(message);
      }
    }

    if (data.userType !== 1) {
      if (!data.userRole) {
        let msg = "Designation can not be empty.";
        toasterMsg.push(msg);
      }
    }

    if (data.userType === 2) {
      if (!data.location) {
        toasterMsg.push(validationLocationMap["location"]);
      }
      if (data.managerisccu === true) {
        if (!data.distric) {
          toasterMsg.push(validationLocationMap["distric"]);
        }
        if (!data.branch) {
          toasterMsg.push(validationLocationMap["branch"]);
        }
      }
    }

    if (!details?.EMPLOYEE_ID && (!data.password || !data.retypedPassword)) {
      let msg = "Password can not be empty.";
      toasterMsg.push(msg);
    }

    if (!details?.EMPLOYEE_ID && (!data.repeatePassword || !data.retypedPassword)) {
      if (data.password !== data.retypedPassword) {
        let msg = "Pasword is not matched.";
        toasterMsg.push(msg);
      }
    }

    return toasterMsg;
  };

  const onSave = async () => {
    toastNotification.length > 0 && toastNotification.set([]);
    setSaveClicked(true);

    let postData = extractPayload();

    userData.managerisccu = districtBranchValidation;

    const validationErrors = mandatoryValidation(userData);

    if (validationErrors?.length > 0) {
      addToaster({
        status: "error",
        title: "Please Fill Mandatory Fields !",
        message: validationErrors,
        manualClosing: true,
      });
      return;
    }

    let response: any = {};
    const responsePayload = {
      status: "error",
      title: "User Update",
      message: "Failed to Update user details !",
    };

    let mode = details?.EMPLOYEE_ID ? "Update" : "Create";
    let stopNavigate = false;

    try {
      setLoading(true);

      if (details?.EMPLOYEE_ID) {
        response = await api.put(endpoints.registerUser, postData);

        let { userId } = response.data;
        if (!userId) {
          userId = response.data?.userData?.auth_user_data_set?.[1] || response.data?.userData || {};
        }
        setUserId(userId);
      } else {
        let query = endpoints.registerUser;
        if (userData?.updateIfExist) {
          query = `${query}?updateIfExist=1`;
        }
        response = await api.post(query, postData);
      }

      if (response.status === -3) {
        throw new Error(response.data);
      }

      if (response.status === 1) {
        responsePayload.title = `User ${mode}`;
        responsePayload.message = `User ${mode}d successfully !`;
        responsePayload.status = "success";
      } else if (response.status === 2) {
        stopNavigate = true;
        responsePayload.status = "warning";
        responsePayload.message = `User already registered with Employee Id or Username!`;
      } else {
        throw new Error(`Failed to process request.`);
      }
    } catch (error: any) {
      responsePayload.status = "error";
      responsePayload.message = error?.message || `Failed to ${mode} user details !`;
    } finally {
      setLoading(false);
      addToaster(responsePayload);
      if (!stopNavigate) {
        handleSave();
        navigateToMainScreen(response?.data);
      }
    }
  };

  function extractPayload() {
    return {
      userId: details?.USER_ID,
      employeeId: userData.empId,
      userName: userData.userName?.toLowerCase(),
      firstName: userData.firstName,
      lastName: userData.lastName,
      middleName: userData.middleName,
      email: userData.email,
      contactNumber: userData.contactNumber,
      language: userData.language,
      userType: userData.userType,
      userRole: userData.userRole,
      officeLocation: userData.location,
      distric: userData.distric,
      branch: userData.branch,
      password: hashValue(userData.password),
      retypedPassword: hashValue(userData.retypedPassword),
    };
  }

  const navigateToMainScreen = (data) => {
    callBackOnAction(data);
  };

  return (
    <>
      <Box>
        <Stack>
          <FieldSectionWrapper>
            <InputFieldsStack>
              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"Employee ID"}
                  value={userData.empId || undefined}
                  required
                  fullWidth={true}
                  disabled={details?.USER_ID || details?.userId}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        empId: e.target.value,
                      };
                    });
                  }}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"Username"}
                  value={userData.userName || undefined}
                  required
                  fullWidth={true}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        userName: e.target.value.toLowerCase(),
                      };
                    });
                  }}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                />
              </InputGrid>
            </InputFieldsStack>
          </FieldSectionWrapper>

          <FieldSectionWrapper>
            <InputFieldsStack>
              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"First Name"}
                  value={userData.firstName || undefined}
                  required
                  fullWidth={true}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        firstName: e.target.value,
                      };
                    });
                  }}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"Middle Name"}
                  value={userData.middleName || undefined}
                  fullWidth={true}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        middleName: e.target.value,
                      };
                    });
                  }}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"Last Name"}
                  value={userData.lastName || undefined}
                  required
                  fullWidth={true}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        lastName: e.target.value,
                      };
                    });
                  }}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                />
              </InputGrid>
            </InputFieldsStack>
          </FieldSectionWrapper>

          <FieldSectionWrapper>
            <InputFieldsStack>
              <InputGrid visibility={1}>
                <TextBoxComponent
                  label={"Email"}
                  value={userData.email || undefined}
                  fullWidth={true}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        email: e.target.value,
                      };
                    });
                  }}
                  error={OnDemandValueValidator.email(userData.email)}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                <PatternFormat
                  format="(###) ### ####"
                  label={"Contact Number"}
                  defaultValue={userData.contactNumber}
                  fullWidth={true}
                  allowEmptyFormatting={false}
                  mask="_"
                  customInput={TextBoxComponent}
                  error={OnDemandValueValidator.phoneNumber(userData.contactNumber)}
                  onValueChange={(values, _) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        contactNumber: values.value,
                      };
                    });
                  }}
                />
              </InputGrid>
            </InputFieldsStack>
          </FieldSectionWrapper>

          <FieldSectionWrapper>
            <InputFieldsStack>
              <InputGrid visibility={1}>
                <CommunicationLanguageSelect
                  label={"Language"}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        language: e.target.value,
                      };
                    });
                  }}
                  required={true}
                  value={userData.language}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                <SelectComponent
                  label={"User Type"}
                  value={userData.userType || ``}
                  values={userTypeList}
                  required
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        userType: e.target.value,
                      };
                    });

                    // Locations excluded for external user type
                    if (e.target.value != 3) {
                      setLocationDetails(e.target.value);
                    } else {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          location: "",
                        };
                      });
                      setLocationList([]);
                    }

                    setUserRoleDetails(e.target.value, userData?.location);
                  }}
                  defaultPlaceholder={true}
                />
              </InputGrid>

              {userData.userType != 3 && (
                <InputGrid visibility={1}>
                  <SelectComponent
                    label={"Office / Location"}
                    value={userData?.location || ``}
                    required
                    values={locationList}
                    onChange={(e) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          location: e ? e.target.value : null,
                        };
                      });
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          userRole: "",
                        };
                      });
                      setUserRoleDetails(userData?.userType, e.target.value);
                    }}
                    defaultPlaceholder={true}
                  />
                </InputGrid>
              )}
            </InputFieldsStack>
          </FieldSectionWrapper>

          <FieldSectionWrapper>
            <InputFieldsStack>
              <InputGrid visibility={1}>
                <SelectComponent
                  label={"Designation"}
                  value={userData.userRole || ``}
                  values={userRoleList}
                  required
                  onChange={(e, menuItem) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        userRole: e.target.value,
                      };
                    });
                    let menuText = menuItem.props.children;
                    let hasCCU = menuText.includes("- CCU");

                    // Reference: user_role_mapping.json
                    const location = parseInt(userData?.location);

                    if (hasCCU || location === 5) {
                      setDistrictBranchValidation(false);
                    } else {
                      setDistrictBranchValidation(true);
                    }
                  }}
                  defaultPlaceholder={true}
                />
              </InputGrid>

              <InputGrid visibility={1}>
                {
                  <AutoCompleteProxyComponent
                    options={masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT)}
                    fullWidth={true}
                    onChange={(e) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          distric: e ? e.id : null,
                        };
                      });
                    }}
                    label={"District"}
                    value={userData.distric || undefined}
                    cls="select_basic"
                    required={districtBranchValidation ? true : false}
                  />
                }
              </InputGrid>
              <InputGrid visibility={1}>
                <SelectComponent
                  label={"Branch"}
                  value={userData?.branch || ``}
                  required={districtBranchValidation ? true : false}
                  values={extractCodeMappedBranches()}
                  onChange={(e) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        branch: e ? e.target.value : null,
                      };
                    });
                  }}
                />
              </InputGrid>
            </InputFieldsStack>
          </FieldSectionWrapper>

          {!details?.EMPLOYEE_ID && (
            <FieldSectionWrapper>
              <InputFieldsStack>
                <InputGrid visibility={1}>
                  <TextBoxComponent
                    label={"Password"}
                    value={userData.password || undefined}
                    required
                    fullWidth={true}
                    onChange={(e) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          password: e.target.value,
                        };
                      });
                    }}
                    type={"password"}
                  />
                </InputGrid>

                <InputGrid visibility={1}>
                  <TextBoxComponent
                    label={"Repeat Password"}
                    value={userData.retypedPassword || undefined}
                    required
                    fullWidth={true}
                    onChange={(e) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          retypedPassword: e.target.value,
                        };
                      });
                    }}
                    type={"password"}
                  />
                </InputGrid>
              </InputFieldsStack>
            </FieldSectionWrapper>
          )}

          {nOption && <Divider sx={{ marginTop: 1 }}></Divider>}
          {nOption && (
            <Stack direction={"row-reverse"} px={2} alignItems={"center"} mt={1}>
              <Stack>
                <ButtonComponent title={details?.USER_ID || details?.userId ? "Update" : "Register"} variant="outlined" onClick={onSave} startIcon={loading ? <CircularProgressComponent size={12} /> : <></>} disabled={loading} />
              </Stack>
              <Stack flex={1}></Stack>
              {!(details?.USER_ID || details?.userId) && (
                <Stack direction={"row"} alignItems={"center"}>
                  <CheckBoxComponent
                    disabled={loading}
                    checked={userData.updateIfExist || false}
                    onChange={(e) =>
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          updateIfExist: e.target.checked,
                        };
                      })
                    }
                  />
                  <Stack direction={"column"}>
                    <Typography color={"gray"} fontWeight={"400"}>
                      {" "}
                      Update If User Exist
                    </Typography>
                    <Typography variant="caption" color={"gray"}>
                      Details will be updated if the Employee Id or the Username already exist
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}

          {!nOption && (
            <Box pb={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ScreenFooterButtons
                  titleOne="Cancel"
                  onCancel={() => {
                    closeGlobalModal();
                  }}
                  titleTwo="Save"
                  onNext={onSave}
                  loading={loading}
                  disableSubmit={!isPrivilegeHave(userPrivilege, privilegeKeys.CREATE_USER_ACCOUNT)}
                />
                <Box marginTop={"15px"} padding={"10px"}>
                  <ButtonComponent
                    title="Assign User Group"
                    variant="contained"
                    onClick={() => {
                      viewGroups("privelege-group-modal");
                    }}
                    disabled={!userId}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
}
