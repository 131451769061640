import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../configs/constants/styleValues";
import { creditData, productDetails, userDetails } from "../../../../../configs/mainStore";
import { createApplicationsHistory } from "../../../../../services/applicationHistoryApi";
import { getCoreBankData } from "../../../../../services/bankServiceInvorker";
import { createPredisbursementChecklist, getPredisbursementChecklistList, getTrailCalData } from "../../../../../services/creditFileApiCall";
import { getCalculateInterestRate } from "../../../../../services/productApis";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { StyledButton } from "../../../../InputComponents/ButtonComponent/StyledButton";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import PredisbursementItemRow from "./PredisbursementItemRow";

const Predisbursement = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const [predisbursementListNew, setPredisbursementListNew]: any = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData?.disbursed;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [trialCalcParams, setTrialCalcParams]: any = React.useState({});
  const [trialCalcResult, setTrialCalResult]: any[] = React.useState([]);
  const [currentChange, setCurrentChange] = React.useState({});
  const { productType, sector, scheme } = currentApplicationWfData;
  const productCatelog = `${productType}-${sector}-${scheme}`;
  const [recommendedInterestRate, setRecomendInterestRate] = React.useState(0);
  const [regularPaymentFactor, setRegularPaymentFactor] = React.useState(0);

  const productMasterData: any = useHookstate(productDetails);
  const productCatalogKeyData = productMasterData?.productCatalogData?.allProductCatalogsWithKeys?.get();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      if (applicationId) {
        const previouslyAddedPredisubrsementData = await getPredisbursementChecklistList(applicationId);
        const standingOrderAmount = await getCoreBankData(applicationId, CORE_BANK_KEYS.INVESTMENT_AMOUNT);

        if (standingOrderAmount) {
          creditFileData.formData.creditData.standingOrderAmount = standingOrderAmount || "0";
        }
        let previouslyAddedMappsIds: any[] = [];

        previouslyAddedPredisubrsementData.forEach((element) => {
          previouslyAddedMappsIds.push(element);
        });
        setPredisbursementListNew(previouslyAddedPredisubrsementData);

        if (previouslyAddedPredisubrsementData.length > 0) {
          const ids = previouslyAddedPredisubrsementData.map((obj) => obj.ID);
          sessionStorage.addedApprovingConditions = JSON.stringify(ids);
        } else {
          sessionStorage.addedApprovingConditions = null;
        }

        // Get Trial Calculator Data
        const trialInvokedData = await getTrailCalData(applicationId);

        if (trialInvokedData) {
          if (trialInvokedData?.trailCalResult != null && trialInvokedData?.trailCalResult != undefined) {
            setTrialCalResult(trialInvokedData?.trailCalResult);
          }

          if (trialInvokedData?.trailCalParams != null && trialInvokedData?.trailCalParams != undefined) {
            setTrialCalcParams(trialInvokedData?.trailCalParams);
          }
        }

        const productData = productCatalogKeyData[productCatelog];

        if (productData) {
          let obj = {
            productId: productData?.PRODUCT_ID,
            applicationId: applicationId,
            applicantTypeId: 1,
          };

          await getCalculateInterestRate(obj).then(({ status, data }) => {
            if (status === 1) {
              setRecomendInterestRate(data);
            }
          });

          setRegularPaymentFactor(productData?.REGULAR_PAYMENT_FACTOR)
        }
      }
      setIsLoading(false);
    })();

    return () => {
      sessionStorage.addedApprovingConditions = null;
    };
  }, []);

  const callBackOnAction = (newConditions) => {
    const formattedItems = newConditions.map((item) => {
      const _item = item;
      _item.MAPPING_ID = _item.ID;
      _item.ID = 0;
      return _item;
    });

    const items = [...predisbursementListNew, ...formattedItems];

    let addedItems: any[] = items.map((obj) => obj.MAPPING_ID);
    sessionStorage.addedApprovingConditions = JSON.stringify(addedItems);

    setPredisbursementListNew(items);
  };

  const setNewDataArray = (updatedConditions) => {
    setPredisbursementListNew([...predisbursementListNew, ...[updatedConditions]]);
  };

  const hasFilledManualEntries = (rowsToAdd) => {
    let nonEmptyPlaceholders: string[] = [];

    for (const item of rowsToAdd) {
      const json = (item as any).PLACEHOLDERS;
      try {
        const placeholder = JSON.parse(json);
        const keys = Object.keys(placeholder);
        for (const key of keys) {
          const placeholderNode = placeholder[key];
          if (placeholderNode.type === "manual") {
            if (!placeholderNode.value) {
              nonEmptyPlaceholders.push(key);
            }
          }
        }
      } catch (error) {
        continue;
      }
    }

    return nonEmptyPlaceholders;
  };

  const handleSave = async () => {
    const newItemsAvailable = newItems();
    if (newItemsAvailable) {
      const rowsToAdd = predisbursementListNew.filter((obj) => obj.ID === 0);

      const nonEmptyEntries = hasFilledManualEntries(rowsToAdd);
      if (nonEmptyEntries.length > 0) {
        let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

        for (const item of nonEmptyEntries) {
          formattedMessage.push(`\n\n👉 ${item}`.replaceAll("__", "").replaceAll("_", " ").toUpperCase());
        }

        addToaster({
          status: "error",
          title: "Error",
          message: formattedMessage,
        });
        return;
      }

      if (rowsToAdd.length === 0) {
        setIsFetching(false);
        return;
      }

      let obj = {
        predisbursementList: rowsToAdd,
        applicationId: applicationId,
      };
      setIsFetching(true);

      const res: any = await createPredisbursementChecklist(obj);

      if (res?.msg) {
        setIsFetching(false);

        createApplicationsHistory(
          applicationId,
          `New Agreement (Predisbursement) condition/s`,
          null,
          currentChange,
          "/predisbursement-checklist"
        );

        addToaster({
          status: "success",
          title: "Updated",
          message: "Check List Item Updated",
        });
      } else {
        setIsFetching(false);
        addToaster({
          status: "warning",
          title: "Error",
          message: res?.error,
        });
      }
    } else {
      setIsFetching(false);
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went Wrong!",
      });
    }
  };

  const onClickCreate = (modalBodyKey) => {
    let addedItems: any[] = predisbursementListNew.map((obj) => obj.MAPPING_ID);
    sessionStorage.addedApprovingConditions = JSON.stringify(addedItems);

    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        ...currentApplicationWfData,
      },
    });
  };

  const addCustomItem = (data) => {
    if (data.predisbursementList) {
      setCurrentChange(data);
      setPredisbursementListNew([...predisbursementListNew, ...data.predisbursementList]);
    }
  };

  const openCustomConditions = (modalBodyKey) => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        addItem: addCustomItem,
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        ...currentApplicationWfData,
      },
    });
  };

  const actionDelete = (response) => {
    if (response != null && response != "") {
      let deletedObj = response[0];

      const id = deletedObj.MAPPING_ID;

      setPredisbursementListNew(
        predisbursementListNew.filter((obj) => {
          return obj.MAPPING_ID != id;
        })
      );
    }
  };

  const newItems = () => {
    if (!predisbursementListNew || predisbursementListNew.length === 0) {
      return false;
    }
    return predisbursementListNew.filter((obj) => obj.ID === 0).length > 0;
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee
    }
    return isDisabled
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={callBackOnAction} modalSize="lg" disabled={checkPrivilegeStatus()}>
        <ButtonComponent title={"Pre-Defined Conditions"} variant="contained" onClick={() => onClickCreate(innerComponentData?.modalBodyKey)} disabled={checkPrivilegeStatus()} />
        <Box ml={0.5} mr={0.5}></Box>
        <ButtonComponent title={"Custom Conditions"} variant="contained" onClick={() => openCustomConditions("pre-disbursement-custom-modal")} disabled={checkPrivilegeStatus()} />
        <Box ml={0.5} mr={0.5}></Box>
        {newItems() ? <StyledButton styles={isFetching ? loadingButtonStyle : continueButtonStyle} title="Save" onClick={handleSave} loading={isFetching} disabled={isFetching ? true : false || checkPrivilegeStatus()} /> : <></>}
      </InnerSectionHeaderComponent>
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : predisbursementListNew && predisbursementListNew.length > 0 ? (
            predisbursementListNew?.map((data: any, index) => <PredisbursementItemRow data={data} key={index} disbursed={disbursed} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} innerComponentData={innerComponentData} setNewDataArray={setNewDataArray} creditFileData={creditFileData} actionDelete={actionDelete} disableAccordingTo={checkPrivilegeStatus()} trialCalcResult={trialCalcResult} trialCalcParams={trialCalcParams} recommendedInterestRate={recommendedInterestRate} regularPaymentFactor={regularPaymentFactor} />)
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default Predisbursement;
