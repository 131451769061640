import React, { useEffect } from "react";

import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import {
  appSetting,
  creditData,
  userDetails,
} from "../../../../../configs/mainStore";
import { getFundReleaseApprovedList } from "../../../../../services/creditFileApiCall";
import { getUserDetailsById } from "../../../../../services/userService";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import FundReleaseApprovalTable from "./FundReleaseApprovalTable";

import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";

const FundReleaseApproval = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const appSettings: any = useState(appSetting);
  const masterDataStore = MasterDataProvider.provideMasterData();

  const [isLoading, setIsLoading] = React.useState(true);
  const [fundReleaseItemList, setFundReleaseItemList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [fundApproveStatus, setFundApproveStatus] = React.useState<any>([]);

  const [isChanged, setIsChanged] = React.useState(false);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getFundReleaseApprovedList(applicationId);

      if (data) {
        setFundReleaseItemList(data);
      } else {
        setErrorMessage("Something went Wrong!");
      }

      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const extractApprover = (item) => {
    const approvedBy = item?.approvedBy;
    if (!approvedBy) {
      return "N/A";
    }

    const language = appSettings.get().language ?? "EN";
    if (!language) {
      return "N/A";
    }

    let result = getUserDetailsById(approvedBy);

    return result ? result[language]?.name : "N/A";
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee
    }
    return isDisabled
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
        disabled={checkPrivilegeStatus()}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} p={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent
              headMessage={"Error!"}
              errorMessage={errorMessage}
            />
          ) : fundReleaseItemList?.length > 0 ? (
            /*-------------------------Grid component start----------------------------*/
            // <FundReleaseApprovalGrid
            //   fundReleaseItemList={fundReleaseItemList}
            //   masterDataStore={masterDataStore}
            //   appSettings={appSettings}
            // />
            /*------------------------------------------------------------------------*/

            /*-------------------------Table component start----------------------------*/
            <FundReleaseApprovalTable
              fundReleaseItemList={fundReleaseItemList}
              masterDataStore={masterDataStore}
              appSettings={appSettings}
            />
          ) : (
            /*------------------------------------------------------------------------*/

            <ErrorMessageComponent
              headMessage={"No data available"}
              errorMessage={""}
            />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default FundReleaseApproval;
