import { Downgraded, useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import AutoCompleteProxyComponent from "../../../components/InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { Toast } from "../../../components/OtherComponents/GlobalToast";
import { cloneConditions } from "../../../services/apiCalls";
import { select_basic } from "../../../styles";

const ConditionCloneModal = ({ selectedCatalog, checkedConditions, callback, optionsPool: { conditionType }, products }) => {
  const configurations = {
    productCatalogue: undefined,
    customerType: undefined,
    checklist: {},
    applicantType: undefined,
    type: undefined,
  };

  const configs: any = useState(configurations);
  let { productCatalogue }: any = configs.attach(Downgraded).get();
  let { type }: any = configs.attach(Downgraded).get();
  let { applicantType }: any = configs.attach(Downgraded).get();

  const applyProductDetails = async () => {
    try {
      await cloneConditions(type, selectedCatalog, productCatalogue, checkedConditions, applicantType);

      Toast.success("Checklists cloned successfully!");
    } catch (error) {
      Toast.error("Failed to clone checklists.")
    } finally {
      callback();
      closeGlobalModal();
    }
  };

  return (
    <Stack
      p={2}
      direction={"column"}
      justifyContent={"stretch"}
      flex={1}
      spacing={6}
      className={"data-box"}
    >
      <AutoCompleteProxyComponent
        options={products.get()}
        fullWidth={true}
        onChange={(e) => {
          configs.productCatalogue.set(e ? e.id : null);
        }}
        label={"Product Catalogue"}
        value={configs.productCatalogue.get()}
        cls="select_basic"
        required
      />
      <AutoCompleteProxyComponent
        options={conditionType}
        fullWidth={true}
        onChange={(e) => {
          configs.type.set(e.id);
        }}
        label={"Type"}
        value={configs.type.get()}
        cls="select_basic"
        required
      />
      <AutoCompleteProxyComponent
        sx={select_basic}
        label="Applicant Type: "
        value={configs.applicantType.get()}
        options={[
          { id: 1, name: "Individual" },
          { id: 2, name: "Corporate" },
        ]}
        onChange={(e) => {
          configs.applicantType.set(e ? e.id : null);
        }}
      />
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent={"flex-end"}
      ></Stack>
      <Stack direction={"row-reverse"} spacing={2}>
        <ButtonComponent
          variant={"outlined"}
          onClick={closeGlobalModal}
          title={"Cancel"}
        />
        <ButtonComponent
          size={"small"}
          variant={"outlined"}
          onClick={applyProductDetails}
          title={"Apply"}
        />
      </Stack>
    </Stack>
  );
};

export default ConditionCloneModal;
