import DoneIcon from "@mui/icons-material/Done";
import { Box, Paper, Stack, Typography } from '@mui/material';
import { isPermitted } from '../../../../../../../../utility/helpers/getIshavePermission';
import { ButtonComponent } from '../../../../../../../InputComponents/ButtonComponent/ButtonComponent';

const MainHeader = ({ onSubmit, title, children }) => {
    return (
        <Stack className='data-box' component={Paper} elevation={0}>
            <Stack
                style={{
                    backgroundColor: `#7fb4de3d`,
                }}
            >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                        className="basic-font basic-font-color-bold font-size-16 padding-m align-left">{title}</Typography>
                    {
                        onSubmit ?
                            <Box> <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={onSubmit} loadingbtn={true} color="info"
                                disabled={isPermitted()}
                            /></Box> : <></>
                    }
                </Stack>
            </Stack>
            <Stack p={2} pt={2}>
                {children}
            </Stack>
        </Stack >
    )
}

export default MainHeader