export const getApplicantData = (index: any, creditData: any, individualId: any) => {
  let personalDataObj: any = [];
  if (index === "jb") {
    personalDataObj = creditData?.formData?.jointBorrowerData || [];
  } else if (index === "gr") {
    personalDataObj = creditData?.formData?.securityData?.guarantors || [];
  }

  if (personalDataObj.length > 0) {
    const matchingItem = personalDataObj.find((item) => item.personalData.individualId === individualId);

    const nicList: any = []

    if (matchingItem) {
      const oldNic = matchingItem?.personalData?.oldNic;
      const newNic = matchingItem?.personalData?.newNic;


      if (oldNic) nicList.push(oldNic)
      if (newNic) nicList.push(newNic)

      const name = `${matchingItem?.personalData?.usedName}${" "}${matchingItem?.personalData?.primaryLastName}`;

      return { nic: nicList.join(" - "), name: name };
    }
  }
};
