import { Divider, Stack, Typography } from "@mui/material";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { OnDemandValueFormatter } from "../../../services/onDemandValidators";

const ProductManageModal = (props) => {
    const [payload, setPayload] = useState({ id: -1, ...props?.data });
    const [submitting, setSubmitting] = useState(false);

    const updateName = (e) => {
        const value = e.target.value;
        setPayload({ ...payload, name: value });
    }

    const updateDescription = (e) => {
        const value = e.target.value;
        setPayload({ ...payload, desc: value });
    }

    const unpublishItem = async () => {
        setSubmitting(true);
        await props.remove(payload);
        setSubmitting(false);
        closeGlobalModal();
    }

    const validateAndSubmit = async () => {
        setSubmitting(true);
        await props.submit(payload);
        setSubmitting(false);
        closeGlobalModal();
    }

    const validate = (property: any) => {
        let errors: any = {
            name: [],
            desc: []
        };

        if (!payload.name) {
            errors.name = [...errors.name, "Name can not be empty."];
        }

        if (!payload.desc) {
            errors.desc = [...errors.desc, "Description can not be empty."];
        }

        return !property ? errors : errors[property];
    }

    return <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack>
                <Typography>Product {props.type} {`${payload?.id > 0 ? `(${payload?.id})` : ''}`}</Typography>
                <Typography variant="caption">add / edit product {props.type}</Typography>
            </Stack>
            <Stack onClick={() => { closeGlobalModal(); }}>
                <CloseIcon style={{ cursor: "pointer" }} />
            </Stack>
        </Stack>
        <Divider></Divider>
        <Stack direction={"row"}>
            <Stack flex={3} spacing={3}>
                <Stack>
                    <TextBoxComponent placeholder={"Name"} value={payload?.name} onChange={updateName} error={validate("name").length > 0} onInput={OnDemandValueFormatter.alphaNumericSpaces}></TextBoxComponent>{validate("name").length > 0 && <span style={{ color: "red" }}>{validate("name").join(" ")}</span>}
                </Stack>
                <Stack>
                    <TextBoxComponent placeholder={"Description"} value={payload?.desc} onChange={updateDescription} error={validate("desc").length > 0} onInput={OnDemandValueFormatter.alphaNumericSpaces}></TextBoxComponent>{validate("desc").length > 0 && <span style={{ color: "red" }}>{validate("desc").join(" ")}</span>}
                </Stack>
            </Stack>
            <Stack flex={1}></Stack>
        </Stack>
        <Divider></Divider>
        {!submitting ? <Stack direction={"row"} spacing={2}>
            {payload?.id > 0 && <ButtonComponent variant={"outlined"} onClick={unpublishItem} title={payload.status ? "Unpublish" : "Publish"}></ButtonComponent>}
            <Stack flex={1}></Stack>
            <ButtonComponent variant={"outlined"} onClick={closeGlobalModal} title={"Close"}></ButtonComponent>
            <ButtonComponent variant={"contained"} onClick={validateAndSubmit} title={payload?.id === -1 ? "Add" : "Update"}></ButtonComponent>
        </Stack> : <CircularProgressComponent size={30} />}
    </Stack>
}
export default ProductManageModal;