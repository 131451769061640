export const FIELDS_WRAPPER_VALUES = {
  margin: 1,
  marginTop: 1.5,
  padding: 0.5,
};

export const SUB_SECTION_WRAPPER_VALUES = {
  margin: 1,
  padding: 1,
};

export const SUB_SECTION_WHITE_SPACE_VALUES = {
  margin: 0,
  padding: 2,
};

export const MULTI_VALUE_INPUT_COMPONENT_RESET_BTN_VALUES = {
  margin: 2,
};

export const INPUT_STACK_VALUES = {
  direction: 'row',
  spacing: 2,
  margin: 2,
};

export const VARIANT = {
  outlined: 'outlined',
  contained: 'contained',
  standard: 'standard',
  longGrid: 'longGrid',
  small: 'small',
  fullWidth: 'fullWidth',
  extraLongGrid: 'ExtralongGrid',
};

export const DIRECTION = {
  row: 'row',
  column: 'column',
};

export const JUSTIFY_CONTENT = {
  flexEnd: 'flex-end',
  center: 'center',
};

export const MENU_ITEMS = [
  {
    title: 'Home',
  },
  {
    title: 'Services',
    submenu: [
      {
        title: 'web design',
      },
      {
        title: 'web development',
        submenu: [
          {
            title: 'Frontend',
          },
          {
            title: 'Backend',
            submenu: [
              {
                title: 'NodeJS',
              },
              {
                title: 'PHP',
              },
            ],
          },
        ],
      },
      {
        title: 'SEO',
      },
    ],
  },
  {
    title: 'About',
    submenu: [
      {
        title: 'Who we are',
      },
      {
        title: 'Our values',
      },
    ],
  },
];

export const APPLICATION_TAB_OPTIONS = [
  { id: 1, title: 'Application', content: 'Application' },
  { id: 2, title: 'Workflow History', content: 'Workflow History' },
  { id: 3, title: 'Decision Engine', content: 'Decision Engine' },
];

export const VERTICLE_TAB_OPTIONS = [
  {
    id: 1,
    key: 'primary-applicant-details',
    title: 'Primary Applicant Details',
  },
  { id: 2, key: 'contact-details', title: 'Contact Details' },
  { id: 3, key: 'additional-details', title: 'Additional Details' },
  { id: 4, key: 'document-details', title: 'Document Details' },
];

export const INPUT_GROUP = {
  padding: 2,
  margin: 0,
  marginTop: 2,
};

const nextBtnStyleObj = {
  // paddingLeft: '3%',
  // paddingRight: '3%',
  // paddingTop: '1%',
  // paddingBottom: '1%',
  fontFamily: 'Poppins !important',
  fontSize: ' 16px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#ffffff',
  opacity: 1,
  background: ' #4386BE 0% 0% no-repeat padding-box',
  borderRadius: '6px',
  border: 'none',
};

export const nextBtnStyles = {
  ...nextBtnStyleObj,
  '&:hover': {
    ...nextBtnStyleObj,
    background: ' #5ba0d9 0% 0% no-repeat padding-box',
  },
};

export const hoverStyle = {
  '&:hover': {
    background: ' #ffffff',
  },
};

export const annualIncomeLabelStyles = {
  fontSize: '14px',
  color: '#636363',
};

export const backBtnStyles = {
  paddingLeft: '3%',
  paddingRight: '3%',
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 16px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#454C66',
  opacity: 1,
  border: ' 1px solid #9d9d9e',
  borderRadius: '6px',
};

export const disSelectButtonStyles = {
  // width: "120px",
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 16px',
  textAlign: 'center',
  letterSpacing: '0px',
  // color: '#454C66',
  color: 'gray',
  opacity: 1,
  border: ' 1px solid #E3E4E7',
  borderRadius: '6px',
};

export const resetButtonStyle = {
  paddingLeft: '3%',
  paddingRight: '3%',
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 14px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#454C66',
  opacity: 1,
  border: ' 1px solid #cfcfcf',
  borderRadius: '6px',
  margin: '1px',
};

export const leadNextBackBtn = {
  width: "130px",
  paddingLeft: "3%",
  marginLeft: "35%",
  paddingRight: "3%",
  height: "38px",
  fontFamily: "Poppins !important",
  fontSize: " 14px",
  textAlign: "center",
  letterSpacing: "0px",
  color: "#454C66",
  opacity: 1,
  border: " 1px solid #cfcfcf",
  borderRadius: "6px",
  margin: "1px",
};

export const leadPerformance = {
  width: "130px",
  paddingLeft: "3%",
  marginLeft: "35%",
  paddingRight: "3%",
  height: "38px",
  fontFamily: "Poppins !important",
  fontSize: " 14px",
  textAlign: "center",
  letterSpacing: "0px",
  color: "#454C66",
  opacity: 1,
  border: " 1px solid #cfcfcf",
  borderRadius: "6px",
  margin: "1px",
};

export const hiddenSpace = {
  width: "30%",
  hiddenTextField: {
    display: "none",
  },
};

export const continueButtonStyle = {
  width: '130px',
  paddingLeft: '3%',
  paddingRight: '3%',
  height: '38px',
  background: '#4386BE',
  fontFamily: 'Poppins !important',
  fontSize: ' 14px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: 1,
  border: ' 1px solid #E3E4E7',
  borderRadius: '6px',
  margin: '1px',

  '&:hover': {
    // width: '120px',
    paddingLeft: '3%',
    paddingRight: '3%',
    height: '38px',
    background: '#5ba0d9',
    fontFamily: 'Poppins !important',
    fontSize: ' 14px',
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
    border: ' 1px solid #E3E4E7',
    borderRadius: '6px',
  },
};

export const loadingButtonStyle = {
  width: "130px",
  paddingLeft: '3%',
  paddingRight: '3%',
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 14px',
  textAlign: 'center',
  letterSpacing: '0px',
  opacity: 1,
  borderRadius: '6px',
  margin: '1px',
};

export const employmentDataWrapper = (item) => {
  return {
    border: '1px solid #9c9c9c',
    borderRadius: '20px',
    height: item?.isSectionCollapsed?.get() ? '100px' : 'auto',
    overflow: 'hidden',
    padding: '10px',
  };
};

export const previewBtnStyles = {
  width: '100%',
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 16px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#ffffff',
  opacity: 1,
  background: ' #4386BE 0% 0% no-repeat padding-box',
  borderRadius: '6px',
  border: 'none',
};

export const continueButtonStyleInNewLead = {
  paddingLeft: '1.5%',
  paddingRight: '1.5%',
  height: '38px',
  background: '#4386BE',
  fontFamily: 'Poppins !important',
  fontSize: ' 14px',
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: 1,
  border: ' 1px solid #E3E4E7',
  borderRadius: '6px',
  margin: '1px',

  '&:hover': {
    paddingLeft: '1.5%',
    paddingRight: '1.5%',
    height: '38px',
    background: '#5ba0d9',
    fontFamily: 'Poppins !important',
    fontSize: ' 14px',
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
    border: ' 1px solid #E3E4E7',
    borderRadius: '6px',
  },
};

export const loadingButtonStyleInLead = {
  paddingLeft: '1.5%',
  paddingRight: '1.5%',
  height: '38px',
  fontFamily: 'Poppins !important',
  fontSize: ' 14px',
  textAlign: 'center',
  letterSpacing: '0px',
  opacity: 1,
  borderRadius: '6px',
  margin: '1px',
};

export const selectedBtn = nextBtnStyles;

export const deSelectedBtn = disSelectButtonStyles;
