import AutorenewIcon from "@mui/icons-material/Autorenew";
import { LoadingButton } from "@mui/lab";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const StyledButton = ({
  id = "",
  styles,
  title,
  onClick,
  disabled = false,
  loading = false,
}) => {
  const ColorButton = styled(Button)<ButtonProps>(() => ({
    ...styles,
  }));

  if (loading === true) {
    return (
      <LoadingButton
        id={`loading-${id}`}
        size="small"
        style={styles}
        loading={loading}
        loadingPosition="start"
        variant="contained"
        startIcon={<AutorenewIcon />}
      >
        {title}
      </LoadingButton>
    );
  }
  return (
    <ColorButton id={`color-button-${id}`} onClick={onClick} variant="text" disabled={disabled}>
      {title}
    </ColorButton>
  );
};
