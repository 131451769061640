import { Grid } from "@mui/material";

import { Downgraded, useState } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { PRIMARY_PARTICIPANT_ID } from "../../../../../configs/constants/contants";
import { applicantTypes } from "../../../../../configs/constants/creditFileConfigs";
import { appSetting, creditData, userDetails } from "../../../../../configs/mainStore";
import { deleteCribDocument, getCribRecordDocFile } from "../../../../../services/creditFileApiCall";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { Toast } from "../../../GlobalToast";
import CribItemRow from "./CribItemRow";

export const CribRecordRightComponentRevamp = ({ data, participantTypeId, participantEntryMap, cribList, reloadPage, applicationId, tabsToEdit, innerComponentData }) => {
  const { t: translate } = useTranslation();
  const appSettings: any = useState(appSetting);
  const masterDataStore = MasterDataProvider.provideMasterData();

  const creditDetailsState: any = useState(creditData);
  const creditDataResponse: any = creditDetailsState.attach(Downgraded).get();
  const disbursed = creditDataResponse?.disbursed;

  const { userId }: any = userDetails.attach(Downgraded).get();

  const creditFileData: any = creditData.attach(Downgraded).get();

  const isLoggedInUserNotTheAssignee = userId !== creditFileData?.currentAssignee;

  const primaryApplicant = creditDataResponse?.formData || [];
  const jointBorrower = creditDataResponse?.formData?.jointBorrowerData || [];
  const guarantor = creditDataResponse?.formData?.securityData?.guarantors || [];

  const primaryApplicantData = [
    {
      address: {
        addressLine1: primaryApplicant?.addressData?.permanentAddress?.addressLine1,
        addressLine2: primaryApplicant?.addressData?.permanentAddress?.addressLine2,
        addressLine3: primaryApplicant?.addressData?.permanentAddress?.addressLine3,
        addressLine4: primaryApplicant?.addressData?.permanentAddress?.addressLine4,
        postalCode: primaryApplicant?.addressData?.permanentAddress?.postalCode,
      },
      initialsInFull: primaryApplicant?.personalData?.initialsInFull,
      nic: primaryApplicant?.personalData?.oldNic ? primaryApplicant?.personalData?.oldNic : primaryApplicant?.personalData?.newNic,
      phoneNumber: primaryApplicant?.contactData?.primaryContact,
      email: primaryApplicant?.contactData?.primaryEmail,
      participantId: primaryApplicant?.personalData?.individualId,
    },
  ];

  const jointBorrowerData = jointBorrower?.map((element) => {
    return {
      address: {
        addressLine1: element?.addressData?.permanentAddress?.addressLine1,
        addressLine2: element?.addressData?.permanentAddress?.addressLine2,
        addressLine3: element?.addressData?.permanentAddress?.addressLine3,
        addressLine4: element?.addressData?.permanentAddress?.addressLine4,
        postalCode: element?.addressData?.permanentAddress?.postalCode,
      },
      initialsInFull: element?.personalData?.initialsInFull,
      nic: element?.personalData?.oldNic ? element?.personalData?.oldNic : element?.personalData?.newNic,
      phoneNumber: element?.contactData?.primaryContact,
      email: element?.contactData?.primaryEmail,
      participantId: element?.personalData?.individualId,
    };
  });

  const guarantorData = guarantor?.map((element) => {
    return {
      address: {
        addressLine1: element?.addressData?.permanentAddress?.addressLine1,
        addressLine2: element?.addressData?.permanentAddress?.addressLine2,
        addressLine3: element?.addressData?.permanentAddress?.addressLine3,
        addressLine4: element?.addressData?.permanentAddress?.addressLine4,
        postalCode: element?.addressData?.permanentAddress?.postalCode,
      },
      initialsInFull: element?.personalData?.initialsInFull,
      nic: element?.personalData?.oldNic ? element?.personalData?.oldNic : element?.personalData?.newNic,
      phoneNumber: element?.contactData?.primaryContact,
      email: element?.contactData?.primaryEmail,
      participantId: element?.personalData?.individualId,
    };
  });

  const previewBtnClicked = async (docId) => {
    const resultFile = await getCribRecordDocFile(docId);

    if (resultFile?.fileData?.data) {
      const fileBuffer = resultFile?.fileData.data;
      const contentType = resultFile?.contentType;

      const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

      window.open(
        `${blobUrl}`,
        "targetWindow",
        `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=500,
           height=500`
      );
    }
  };

  const callBackOnAction = () => {
    closeGlobalModal();
    reloadPage();
  };

  const uploadCribRecord = (participantId, clientType) => {
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "upload-crib-record",
      close: false,
      modalParams: {
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        entryId: participantEntryMap[participantId],
        clientType: clientType, // PA || JB || G
        clientMeta: { nics: clientMeta(clientType, participantId) },
      },
    });
  };

  const clientMeta = (clientType, participantId) => {
    if (clientType === 1) {
      return getPANicData();
    } else if (clientType === 2) {
      return getJBNicData(participantId);
    } else if (clientType === 3) {
      return getGRNicData(participantId);
    }
  };

  const getPANicData = () => {
    let nicArr: any[] = [];
    if (creditDataResponse) {
      let newNicObj = {
        id: creditDataResponse.formData?.personalData?.newNic,
        name: creditDataResponse.formData?.personalData?.newNic,
      };

      let oldNicObj = {
        id: creditDataResponse.formData?.personalData?.oldNic,
        name: creditDataResponse.formData?.personalData?.oldNic,
      };

      nicArr.push(newNicObj);
      nicArr.push(oldNicObj);
    }
    return nicArr;
  };

  const getJBNicData = (participantId) => {
    let nicArr: any[] = [];
    if (!creditDataResponse) {
      return nicArr;
    }

    let jointBorrowers = creditDataResponse.formData?.jointBorrowerData ?? [];

    jointBorrowers = jointBorrowers.filter((obj) => obj);

    if (jointBorrowers.length === 0) {
      return nicArr;
    }

    let jointBorrower = jointBorrowers.filter((obj) => obj.personalData?.individualId === participantId);

    if (!jointBorrower || jointBorrower.length === 0) {
      return nicArr;
    } else {
      jointBorrower = jointBorrower[0];
    }

    let newNicObj = {
      id: jointBorrower.personalData?.newNic,
      name: jointBorrower.personalData?.newNic,
    };

    let oldNicObj = {
      id: jointBorrower.personalData?.oldNic,
      name: jointBorrower.personalData?.oldNic,
    };

    nicArr.push(newNicObj);
    nicArr.push(oldNicObj);

    return nicArr;
  };

  const getGRNicData = (participantId) => {
    let nicArr: any[] = [];
    if (!creditDataResponse) {
      return nicArr;
    }

    let gurantors = creditDataResponse.formData?.securityData?.guarantors ?? [];

    if (gurantors.length === 0) {
      return nicArr;
    }

    gurantors = gurantors.filter((obj) => obj);

    let gurantor = gurantors.filter((obj) => obj.personalData?.individualId === participantId);

    if (!gurantor || gurantor.length === 0) {
      return nicArr;
    } else {
      gurantor = gurantor[0];
    }

    let newNicObj = {
      id: gurantor.personalData?.newNic,
      name: gurantor.personalData?.newNic,
    };

    let oldNicObj = {
      id: gurantor.personalData?.oldNic,
      name: gurantor.personalData?.oldNic,
    };

    nicArr.push(newNicObj);
    nicArr.push(oldNicObj);

    return nicArr;
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  const removeCribDocument = async (cribdata, index) => {
    cribdata.applicationId = applicationId;

    try {
      const data = await deleteCribDocument(cribdata);
      closeGlobalModal();
    } catch (error) {
      Toast.error("Failed to delete crib document.");
    } finally {
      reloadPage();
    }
  };

  return (
    <Grid>
      <>
        <Grid container p={1} className="data-box test-abc" spacing={1}>
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-18 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            Primary Applicant
          </Grid>
          <Grid container>
            <CribItemRow key={0} title={primaryApplicantData[0]?.initialsInFull ? primaryApplicantData[0].initialsInFull : "-"}
              subTitle={participantTypeId === PRIMARY_PARTICIPANT_ID ? `${translate(applicantTypes[participantTypeId])}` : `${translate(applicantTypes[participantTypeId])} ${1}`}
              keyCode={0} user={primaryApplicantData[0]} uploadCribRecord={uploadCribRecord} masterDataStore={masterDataStore} participantEntryMap={participantEntryMap}
              cribList={cribList} appSettings={appSettings} previewBtnClicked={previewBtnClicked} disbursed={disbursed}
              clientType={1} disabled={checkPrivilegeStatus()}
              removeCribDocument={removeCribDocument}
            />
          </Grid>
        </Grid>
        {jointBorrowerData?.length > 0 && (
          <Grid container p={1} className="data-box" spacing={1}>
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-18 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Joint Borrower
            </Grid>
            <Grid container>
              {jointBorrowerData?.map((user, i) => {
                return <CribItemRow key={i} title={user?.initialsInFull ? user.initialsInFull : "-"} subTitle={participantTypeId === PRIMARY_PARTICIPANT_ID ? `${translate(applicantTypes[participantTypeId])}` : `${translate(applicantTypes[participantTypeId])} ${i + 1}`}
                  keyCode={i} user={user} uploadCribRecord={uploadCribRecord} masterDataStore={masterDataStore} participantEntryMap={participantEntryMap} cribList={cribList}
                  appSettings={appSettings} previewBtnClicked={previewBtnClicked} disbursed={disbursed} clientType={2} disabled={checkPrivilegeStatus()}
                  removeCribDocument={removeCribDocument}
                />;
              })}
            </Grid>
          </Grid>
        )}
        {guarantorData?.length > 0 && (
          <Grid container p={1} className="data-box" spacing={1}>
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-18 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Guarantor
            </Grid>
            <Grid container>
              {guarantorData?.map((user, i) => {
                return <CribItemRow key={i} title={user?.initialsInFull ? user.initialsInFull : "-"}
                  subTitle={participantTypeId === PRIMARY_PARTICIPANT_ID ? `${translate(applicantTypes[participantTypeId])}` : `${translate(applicantTypes[participantTypeId])} ${i + 1}`} keyCode={i}
                  user={user} uploadCribRecord={uploadCribRecord} masterDataStore={masterDataStore} participantEntryMap={participantEntryMap}
                  cribList={cribList} appSettings={appSettings} previewBtnClicked={previewBtnClicked} disbursed={disbursed} clientType={3}
                  disabled={checkPrivilegeStatus()}
                  removeCribDocument={removeCribDocument}
                />;
              })}
            </Grid>
          </Grid>
        )}
      </>
    </Grid>
  );
};
