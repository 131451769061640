import { FC } from "react";

import { userContactPropsTypes } from "../../../configs/interfaces";
import { Grid } from "@mui/material";
import { VARIANT } from "../../../configs/constants/styleValues";
import MultiSelectComponent from "../../InputComponents/MultiSelectComponent/MultiSelectComponent";

export const UserContactsInputComponent: FC<userContactPropsTypes> = ({
  hierarchies,
  groups,
  users,
  onChange,
  selectedHierarchies,
  selectedGroups,
  selectedUsers,
}: any) => {
  return (
    <>
      <Grid container item xs={12} spacing={1} pr={1}>
        <Grid item xs={4} >
          <MultiSelectComponent
            label="Hierarchies"
            sx={{ minWidth: 120 }}
            size="small"
            values={hierarchies}
            value={selectedHierarchies}
            onChange={onChange}
            type={'hierarchies'} />
        </Grid>
        <Grid item xs={4} >
          <MultiSelectComponent
            label="Groups"
            sx={{ minWidth: 120 }}
            size="small"
            values={groups}
            value={selectedGroups}
            onChange={onChange}
            type={'groups'} />
        </Grid>
        <Grid item xs={4} >
          <MultiSelectComponent
            label="Users"
            sx={{ minWidth: 120 }}
            size="small"
            values={users}
            value={selectedUsers}
            onChange={onChange}
            type={'users'} />
        </Grid>
      </Grid>
    </>
  );
};
