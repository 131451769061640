export interface LineChartDefaultData {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    dataKey: string;
}

export const defaultDatasets: LineChartDefaultData[] = [
    {
        label: 'Revenue / Income',
        data: [0, 0, 0, 0],
        dataKey: "revenueIncome",
        backgroundColor: '#fffdaf',
        borderColor: ' #FF5733 ',
        borderWidth: 1,
    },
    {
        label: 'Gross Profit',
        data: [0, 0, 0, 0],
        dataKey: "grossProfit",
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
    },
    {
        label: 'Expenses',
        data: [0, 0, 0, 0],
        dataKey: "netExpenses",
        backgroundColor: '#FF7074',
        borderColor: '#FF474D',
        borderWidth: 1,
    },
    {
        label: 'Net Profit Before Interest and Depreciation',
        data: [0, 0, 0, 0],
        dataKey: "netProfitBeforeInterestDepreciation",
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
    },
    {
        label: "Net Profit After Depriciation",
        dataKey: "netAfterDepriciation",
        data: [0, 0, 0, 0],
        backgroundColor: 'rgb(153, 255, 153, 0.2)',
        borderColor: 'rgb(0, 204, 0, 1)',
        borderWidth: 1,
    },
    {
        label: 'Net Profit after Tax',
        data: [0, 0, 0, 0],
        dataKey: "netProfitAfterTax",
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
    },
];

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'right' as const,
        },
    },
};