import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { SearchSuffixComponent } from '../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent';
import { fetchEmployersData } from '../services/masterDataApis';

export enum Model {
    application = "APPLICATION",
    creditFile = "CREDIT_FILE",
}

function SalesSupportEmployers({ mode = undefined, setEmployerName }: { mode?: Model | undefined, setEmployerName?: (empId: number) => void }) {
    const height: string = mode === Model.application ? "calc(100vh - 350px)" : "calc(100vh - 200px)";
    const [selectedEmployerName, setSelectedEmployerName] = React.useState<number | null>(null);
    const [searchKey, setSearchKey] = React.useState("");
    const [employerData, setEmployerData] = React.useState([
        { code: 0, active: 1, name: "Fetching..." },
    ]);

    useEffect(() => {
        prepareDataSource();
    }, []);

    const prepareDataSource = async () => {
        try {
            const data = await fetchEmployersData(searchKey);
            if (data.length) {
                setEmployerData(data);
            }
        } catch (error) {
            setEmployerData([]);
        }
    }

    return <Stack p={2} spacing={2}>
        <Paper elevation={0}>
            <Stack py={1} px={2} justifyContent={"center"}>
                <Typography variant='h6'>Employers Registry</Typography>
            </Stack>
        </Paper>
        <Paper elevation={0} style={{ padding: "0px" }}>
            <Stack p={2} spacing={2} style={{ height: `${height}`, overflow: "auto" }}>
                <Stack style={{ top: "-16px", zIndex: 10, position: "sticky", backgroundColor: "white" }} spacing={2}>
                    <Stack direction={"row"} width={mode === Model.application ? "100%" : "400px"}>
                        <SearchSuffixComponent right={""} placeholder={"Search"} handleChangeSearchText={(text) => setSearchKey(text)} />
                    </Stack>
                    <Divider />
                </Stack>
                <Stack width={mode === Model.application ? "100%" : "400px"}>
                    {employerData.filter(item => item.name.toLowerCase().includes(searchKey.toLowerCase())).map(item => {
                        if (mode === Model.application && item.code != 0) {
                            return <Button
                                variant={selectedEmployerName == item.code ? 'contained' : 'text'}
                                onClick={() => {
                                    setSelectedEmployerName(item.code);
                                    setEmployerName && setEmployerName(item.code);
                                }}
                                key={item.code} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", marginBottom: '16px' }}>
                                <Typography textAlign={"left"}>{item.name}</Typography>
                                {item.code != 0 && <Typography variant={"caption"} color={selectedEmployerName == item.code ? "white" : "gray"}>({item.code})</Typography>}
                            </Button>
                        }
                        return <Stack key={item.code} direction={"row"} justifyContent={"space-between"} mb={2}>
                            <Typography>{item.name}</Typography>
                            {item.code != 0 && <Typography variant={"caption"} color={"gray"}>({item.code})</Typography>}
                        </Stack>
                    }

                    )}
                </Stack>
            </Stack>
        </Paper>
    </Stack>;
}

export default SalesSupportEmployers;
