import { useEffect } from 'react'
import SalesInformation from './Components/SalesInformation/SalesInformation';
import React from 'react';
import { CircularProgressComponent } from '../../../../../../../../ProgressComponent/ProgressComponent';
import { getSalesAndPurchaseInformations, saveSalesAndPurchaseInformations } from '../../../../Api/AppraisalApi';
import { addToaster } from '../../../../../../../../GlobalToast';
import { Downgraded, useState } from '@hookstate/core';
import { purchaseInformationStateData, salesandPurchaseInformationData } from '../../../../Stores/MicroAppraisalStore';
import SubSectionHeader from '../../../../Helpers/SubSectionHeader/SubSectionHeader';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
const PurchaseInformation = React.lazy(() => import("./Components/PurchaseInformation/PurchaseInformation"))

const SalesAndPurchaseInformationContainer = ({ applicationId }) => {
    const salesandPurchaseInformation = useState(salesandPurchaseInformationData);
    const purchaseInformationData = useState(purchaseInformationStateData);
    const { t: translate } = useTranslation();

    useEffect(() => {
        (async function () {

            const response = await getSalesAndPurchaseInformations(applicationId);
            if (response.status < 0) {

                addToaster({
                    status: "error",
                    title: "Error",
                    message: response.error,
                });

            } else if (Object.keys(response.data).length > 0) {
                salesandPurchaseInformation.set(response.data.salesandPurchaseInformation);
                purchaseInformationData.set(response.data.purchaseInformationData);
            }
        }())

    }, []);

    const handleSubmit = async () => {
        const data = {
            salesandPurchaseInformation: salesandPurchaseInformation.attach(Downgraded).get(),
            purchaseInformationData: purchaseInformationData.attach(Downgraded).get(),
        }

        const toBeSubmittedData = {
            applicationId,
            salesAndPurchaseInformations: { ...data }
        }

        const response = await saveSalesAndPurchaseInformations(toBeSubmittedData);

        if (response.status < 0) {
            addToaster({
                status: "success",
                title: "Saved!",
                message: "Submission is successful!",
            });

        } else {
            addToaster({
                status: "error",
                title: "Error",
                message: "Submission is Failed due to the unexpected Error!",
            });
        }
    }

    return (
        <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
            <Stack width={"100%"} gap={2}>
                <SubSectionHeader
                    isNeedDropdown={false}
                    title={translate("salesandPurchaseInformation")}
                    onSubmit={handleSubmit}
                    dropDownProps={{
                        options: [],
                        profitAndLossAccounts: "",
                    }}
                />

                <SalesInformation />
                <PurchaseInformation />
            </Stack>
        </React.Suspense>
    )
}

export default SalesAndPurchaseInformationContainer