import { Downgraded } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid, Stack } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import { branchDetails, creditData, userDetails } from "../../../../../../configs/mainStore";
import {
  disableAccess
} from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import CommonWitnessBlock from "../CommonWitnessBlock";

interface LetterofSetOffPAPropType {
  data?: Object;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  setDocumentDataUpdated?: any;
  currentApplicationWfData?: any;
  applicationId?: any;
}

export const LetterofSetOffPA: FC<LetterofSetOffPAPropType> = ({
  data,
  applicationId,
}) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const componentRef: any = useRef();
  const userData: any = userDetails.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const initialsInFull = creditFileData?.formData?.personalData?.initialsInFull
    ? creditFileData?.formData?.personalData?.initialsInFull
    : "";
  const primaryLastName = creditFileData?.formData?.personalData?.primaryLastName
    ? creditFileData?.formData?.personalData?.primaryLastName
    : "";
  const fullName = `${initialsInFull} ${primaryLastName}`;
  const primaryAddress = creditFileData?.formData?.addressData?.permanentAddress;
  const documentId = securityDocumentPermissions.letterOfSetOffPA || "letter_of_set_off_pa";
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();

  const permissionToEditSave = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_EDIT_SAVE_CHANGES",
  });

  const permissionToPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      #Header, #Footer {
        display: none !important;
      }
    }
  `,
  });

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [
      primaryAddress?.addressLine1 || "",
      primaryAddress?.addressLine2 || "",
      primaryAddress?.addressLine3 || "",
      primaryAddress?.addressLine4 || "",
    ].filter((obj) => obj);

    return (
      <>
        {paAddressSegments.map((item, i) => {
          if (paAddressSegments.length - 1 != i) {
            return <span key={i}>{item}, </span>;
          } else {
            return <span key={i}>{item}</span>;
          }
        })}
      </>
    );
  };

  const renderNicDetails = () => {
    let nic = "";

    if (creditFileData.formData.personalData.newNic != null && creditFileData.formData.personalData.newNic != "") {
      nic = creditFileData.formData.personalData.newNic;
    } else if (
      creditFileData.formData.personalData.oldNic != null &&
      creditFileData.formData.personalData.oldNic != ""
    ) {
      nic = creditFileData.formData.personalData.oldNic;
    }
    return nic;
  };

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const BuildWitnessSignatureBlock = ({ index, fields }) => {
    return (
      <Stack direction={"row"} flex={1}>
        <Stack mr={2}>{index}.</Stack>
        <Stack flex={1}>
          {fields.map((obj) => {
            return (
              <Stack flex={1} direction={"row"} mb={1.25}>
                <Stack width={150} direction={"row"}>
                  <Stack flex={1}>{obj}</Stack>
                  <Stack width={5}>:</Stack>
                </Stack>
                <Stack style={{ borderBottom: "dotted 2px grey" }} width={"50%"}></Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <Grid container className="inner-component-full-height basic-font">
      <style>{getPageMargins()}</style>
      <div className="full-width full-height">
        <SecurityDoceumentsFileDocumentsViewSection
          data={data}
          applicationId={applicationId}
          isSecurityDocument={true}
          securityDocumentKey={"letter-of-set-off-pa"}
        />
        <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef} columns={12}>
          <CommonSecurityDocHeader documentName={"Letter of setoff"} textCase={"upper"} />
          <Grid item xs={12} mb={5}>
            <Grid container columns={12}>
              <Grid item xs={8}>
                <div>The Manager</div>
                Pradeshiya Sanwardhana Bank
                <br />
                <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranch}</span> Branch
              </Grid>
              <Grid item xs={4} pl={2}>
                Date:
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={5} className="letter-setoff-wrapper">
            Dear Sir,
            <br />
            <br />I{" "}
            <span className={`letter-setoff-bold-text security-doc-type-variable ${typeCaseClass}`}>
              {initialsInFull + " " + primaryLastName}
            </span>{" "}
            of{" "}
            <span className={`letter-setoff-bold-text security-doc-type-variable ${typeCaseClass}`}>
              {<PAAdress />}
            </span>{" "}
            hereby authorize the{" "}
            <span className="letter-setoff-bold-text">
              <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span>
            </span>{" "}
            to set off appropriate at any time and at all times hereafter all monies now lying or hereafter to lie to my
            credit or now or hereafter to be held by the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> or any of its offices or
            branches of which now or hereafter may become due owning or payble by the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> or any of its offices or
            branches to me in my individual capacity or in any other capacity whatsoever on, fixed deposit accounts
            ,savings accounts, or any other account or transaction of whatsoever kind or nature opened or had by me
            whether in my own name or in any other name style whatsoever or of whether solely or jointly with any other
            person or corporation in or with the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> in any of its offices or
            branches ,against all monies now or hereafter to become due owning of payble to the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> and all its branches or offices
            on account of loans,credits,advances or other pecuniary aid-assistance and banking facilities afforded or to
            be afforded by the <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> or any of
            its offices or branches to me and all indebteness and liabilities in my individual capacity whatsoever
            whether solely or jointly with any other person or corporation or whether as principal debtor.surety or
            guarantor whether solely or jointly with any other person or corporation.
            <br />
            <br />
            Hereby declare that this authority to the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> is irrevocable and shall
            continue to be in full force and virtue until expressly revoked with the consent of the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span>
            <br />
            <br />
            The <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> shall at any time and at
            all time have the power and authority to exercise the right of set off or lien or appropriation authorised
            or created hereby without previous notice to me and without any previous demand whatsoever made from me for
            payment of any money due by me to the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> notwithstanding anything to the
            contrary contained in any written or other law or any contract or writing executed hereafter by me or the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span>
            <br />
            <br />I hereby futher undertake to hold the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> indemnified and saved harmless
            from and against all losses,damages,interests,costs,charges,expenses and detriment of whatsoever kind or
            nature consequent on or attributable to the{" "}
            <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> acting or purporting to act in
            the exercise of any right of set off or lien or appropriation in terms hereof and thereby or otherwise
            dishonoring any document or other such like document or orders for payment of money or demands or
            withdrawals on or from the <span className="letter-setoff-bold-text">Pradeshiya Sanwardhana Bank</span> or
            any of its offices or branches and made signed or delivered whether under my hand alone or jointly with any
            other person or corporation in my personal capacity or any other capacity whatsoever on or in respect of any
            account or accounts whatsoever.
            <br />
            <br />I hereby agree to bind myself ,my heirs,executors and administrators by this writing.
          </Grid>

          <Grid item xs={12} mb={4}>
            <Grid container columns={12}>
              <Grid xs={12} mb={3}>
                Yours faithfully,
                <br />
                <br />
                <br /> ..............................................
                <br /> Signature
              </Grid>
              <Grid xs={12}>
                NIC : <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails()}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"column"} flex={1}>
              <Stack mb={2}>Witnesses :</Stack>
              <CommonWitnessBlock index={1} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
              <Stack mt={2}></Stack>
              <CommonWitnessBlock index={2} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
            </Stack>
          </Grid>
        </Grid>
        <Box m={1}>
          <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={4} pr={1}>
              <SelectComponent
                label={"Select Typecase"}
                value={typeCase}
                values={Object.values(securityDocumentTextCase)}
                onChange={(e) => setTypeCase(e.target.value)}
                required={false}
                defaultPlaceholder={true}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonComponent
                startIcon={<LocalPrintshopIcon />}
                title={"Print PDF"}
                variant="contained"
                onClick={() => {
                  handlePrint();
                }}
                style={{ maxHeight: "40px", marginTop: "auto" }}
                disabled={permissionToPrint}
                loadingbtn={true}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};
