import { Box } from "@mui/material";

const CircularProgress = () => {
  return (
    <Box sx={{ display: "flex", width: 100, justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default CircularProgress;
