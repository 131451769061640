import { useState } from '@hookstate/core';
import React, { useEffect } from 'react';
import { appSetting, userDetails } from "../../configs/mainStore";
import { useLoadLanguageData } from './languageData';
import { useLoadMasterData } from './useLoadMasterData';
import { sessionRefresh } from './useSessionRefresh';
import { useSetAppSettings } from './useSetAppSettings';

export const useInitializer = () => {
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState(0);

  useSetAppSettings(appSetting);
  const { loadingMasterData } = useLoadMasterData(appSetting);
  const { loadingLanguageData } = useLoadLanguageData();
  const { sessionAuthenticated } = useSessionExist();

  useEffect(() => {
    setStatus(prev => prev + 1);
    if (status === 3) {
      setLoading(false);
    }
  }, [loadingMasterData, loadingLanguageData, sessionAuthenticated]);

  return { loading, authenticated: sessionAuthenticated === 1 };
};

const useSessionExist = () => {
  const [sessionAuthenticated, setSessionAuthenticated] = React.useState(0);
  const userData = useState(userDetails);

  useEffect(() => {
    const data = resumeSessionData();
    if (data) {
      const jwtPayload = JSON.parse(window.atob(data?.accessToken?.split('.')?.[1] || ""))

      if (Date.now() >= jwtPayload.exp * 1000) {
        sessionRefresh().then(session => {
          procceed(resumeSessionData());
        }).catch((error) => {
          procceed(null);
        });
      } else {
        procceed(data);
      }
    } else {
      procceed(data);
    }
  }, [userData.userId]);

  const procceed = (data) => {
    if (!data) {
      setSessionAuthenticated(-1);
      return;
    }
    userData.merge({ authenticate: 1, userId: data?.user?.id, accessToken: data?.accessToken, userDla: data?.permissionObj?.dla, userPrivilege: data?.permissionObj?.privilege, userDetails: data?.user, userInGroups: data?.userInGroups });
    setSessionAuthenticated(!!data.accessToken ? 1 : -1);
  }

  const resumeSessionData = () => {
    const { data } = JSON.parse(sessionStorage.session || "{}");
    return data;
  };

  return { sessionAuthenticated };
}
