import { useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { addToaster } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspectionOfficerData, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { inspectionOfficerDetails } from "../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

const InspectionParticipation = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  let toBeSubmittedFormDetails: any = {};
  const [formDetails, setFormDetails]: any = React.useState(toBeSubmittedFormDetails);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const { formData } = data;
  const inspetionId = useState<any>(inspetionIdState);
  const valueSet = useState<any[]>(inspectionOfficerData);
  const isLoading = useState<boolean>(true);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
    isLoading: isLoading.get()
  };

  useEffect(() => {
    inspectionFormDate();

    return () => {
      valueSet.set(JSON.parse(JSON.stringify([inspectionOfficerDetails])));
    }
  }, []);

  const inspectionFormDate = async () => {
    try {
      const { response: data, status }: any = await getFilledPendingInpectionFormData(applicationId);
      let responseData: any = {};
      let inspetionIdVal = null;

      if (status === 1 && Object.keys(data).length > 0) {
        inspetionIdVal = data["ID"] ?? null;
        inspetionId.set(inspetionIdVal);
        responseData = data ? JSON.parse(data["INSPECTION_DATA"]) : {};
      }
      let { summery = {} } = responseData;

      if (summery?.inspectionOfficerData && summery?.inspectionOfficerData.length > 0) {
        valueSet.set(summery?.inspectionOfficerData);
      }

      inspectionFormState.participation.set(responseData["participation"]);

      setFormDetails({
        ...formDetails,
        ...JSON.parse(JSON.stringify(inspectionFormState?.participation.get() ?? {})),
        inspetionId: inspetionIdVal,
        applicationId,
      });
    } catch (error) {

    } finally {
      isLoading.set(false)
    }
  };

  const onChange = (metaData: any, value: any) => {
    setFormDetails(value);
  };

  const onSubmit = async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(ApplicationFormKey.inspectionParticipation, theDataTobeSubmitted, SectionKey.inspectionParticipation);

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = inspectionMessageMappings(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${preparedErrorMessagges}`,
      });
    }

    const respose = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.inspectionParticipation, inspectionFormState, applicationId);

    if (respose.status == 1) {
      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.inspectionParticipation);
    } catch (error: any) {
      GlobalLogger.log("inspection participation", error.message);
    }
  };

  // if (isLoading.get()) return <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />;

  return (
    <ErrorBoundary>
      <FormBuilder applicationDataObject={formDetails} onChange={onChange} formId={ApplicationFormKey.inspectionParticipation} title={translate("inspectionParticipation")} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
    </ErrorBoundary>
  );
};

export default InspectionParticipation;
