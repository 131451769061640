import { Grid } from "@mui/material";
import ScoreCardRow from "./ScoreCardRow";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import React from "react";

const AthamaruScoreCardLayout = ({ isLoading, scoreCardData, masterDataStore }) => {

    return (
        <React.Fragment>
            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Borrower Risk</div>
            </Grid>
            <ScoreCardRow
                title={scoreCardData["age"]?.displayName}
                subTxt={scoreCardData["age"]?.displayCondition}
                score={scoreCardData["age"]?.score}
            />

            <ScoreCardRow
                title={scoreCardData["educationLevel"]?.displayName}
                subTxt={scoreCardData["educationLevel"]?.displayCondition}
                score={scoreCardData["educationLevel"]?.score}
            />
            <ScoreCardRow
                title={scoreCardData["experienceRunningTheBusiness"]?.displayName}
                subTxt={scoreCardData["experienceRunningTheBusiness"]?.displayCondition}
                score={scoreCardData["experienceRunningTheBusiness"]?.score}
            />
            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Business Risk</div>
            </Grid>
            <ScoreCardRow
                title={scoreCardData["noOfLoanCycles"]?.displayName}
                subTxt={scoreCardData["noOfLoanCycles"]?.displayCondition}
                score={scoreCardData["noOfLoanCycles"]?.score}
            />
            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Industry/Environmental
                    Risk
                </div>
            </Grid>
            <ScoreCardRow
                title={scoreCardData["vulnerabilityClimateEnvFact"]?.displayName}
                subTxt={scoreCardData["vulnerabilityClimateEnvFact"]?.displayCondition}
                score={scoreCardData["vulnerabilityClimateEnvFact"]?.score}
            />
            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left"> Financial Risk</div>
            </Grid>
            <ScoreCardRow
                title={scoreCardData["loanToNetWorth"]?.displayName}
                subTxt={scoreCardData["loanToNetWorth"]?.displayCondition}
                score={scoreCardData["loanToNetWorth"]?.score}
            />
            <ScoreCardRow
                title={scoreCardData["trendsInPerformanceOfSales"]?.displayName}
                subTxt={scoreCardData["trendsInPerformanceOfSales"]?.displayCondition}
                score={scoreCardData["trendsInPerformanceOfSales"]?.score}
            />

            <ScoreCardRow
                title={scoreCardData["debtHandlingCapacity"]?.displayName}
                subTxt={scoreCardData["debtHandlingCapacity"]?.displayCondition}
                score={scoreCardData["debtHandlingCapacity"]?.score}
            />

            <ScoreCardRow
                title={scoreCardData["dependentIncome"]?.displayName}
                subTxt={scoreCardData["dependentIncome"]?.displayCondition}
                score={scoreCardData["dependentIncome"]?.score}
            />
            <ScoreCardRow
                title={scoreCardData["percentageLoanInstallIncome"]?.displayName}
                subTxt={scoreCardData["percentageLoanInstallIncome"]?.displayCondition}
                score={scoreCardData["percentageLoanInstallIncome"]?.score}
            />

            <ScoreCardRow
                title={scoreCardData["sourceIncome"]?.displayName}
                subTxt={scoreCardData["sourceIncome"]?.displayCondition}
                score={scoreCardData["sourceIncome"]?.score}
            />

            <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Transactional Risk</div>
            </Grid>
            <ScoreCardRow
                title={scoreCardData["presenceOfRegulatedFinancialSystem"]?.displayName}
                subTxt={scoreCardData["presenceOfRegulatedFinancialSystem"]?.displayCondition}
                score={scoreCardData["presenceOfRegulatedFinancialSystem"]?.score}
            />
            <ScoreCardRow
                title={scoreCardData["cribRecord"]?.displayName}
                subTxt={
                    scoreCardData["cribRecord"]?.value
                        ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS.CRIB_RECODE, scoreCardData["cribRecord"]?.value)
                        : "--"
                }
                score={isLoading ? <CircularProgressComponent size={20} /> : scoreCardData["cribRecord"]?.score}
            />
            <ScoreCardRow
                title={scoreCardData["bankRelation"]?.displayName}
                subTxt={
                    scoreCardData["bankRelation"]?.value
                        ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS.BANK_RELATION, scoreCardData["bankRelation"]?.value)
                        : "--"
                }
                score={scoreCardData["bankRelation"]?.score}
            />
        </React.Fragment>
    )
}

export default AthamaruScoreCardLayout;
