export const assetDataKeys = [
    {
        title: "Fixed Assets",
        dataKey: "fixedAssets",
        type: "textBox",
    },
    {
        title: "Debtors",
        dataKey: "debtors",
        type: "textBox",
    },
    {
        title: "Stocks",
        dataKey: "stocks",
        type: "textBox",
    },
    {
        title: "Current Asset (Without Debtors & Stocks)",
        dataKey: "currentAsset",
        type: "textBox",
    },
    {
        title: "Other Assets",
        dataKey: "otherAssets",
        type: "textBox",
    },
    {
        title: "Total Assets",
        dataKey: "totalAssets",
        type: "autoPopulated",
    },
];

export const liabilityDataKeys = [
    {
        title: "Trade Creditors",
        dataKey: "tradeCreditors",
        type: "textBox",
    },
    {
        title: "Bank Loans/ Lease/ ODs",
        dataKey: "bankLoansLeaseODs",
        type: "textBox",
    },
    {
        title: "Other Liabilities",
        dataKey: "otherLiabilities",
        type: "textBox",
    },
    {
        title: "Total Liabilities",
        dataKey: "totalLiabilities",
        type: "autoPopulated",
    },
    {
        title: "Equity",
        dataKey: "equity",
        type: "autoPopulated",
    },
];

export const balanceSheetData = [...assetDataKeys, ...liabilityDataKeys];