import { addToaster } from '../components/OtherComponents/GlobalToast';
import { ERROR_CODES } from '../configs/constants/errorCode';
import Api from './apiCallsHandler';
import URL from "../configs/connection";

const api = Api.getApi();

const emailTemplateListUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/email-template-list?languageId=`;
const addNewEmailTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/add-new-email-template`;
const emailTemplateByIdUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/email-template-by-id?templateId=`;
const editEmailTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/edit-email-template`;
const emailTemplateDefByIdLanguageUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/email-definition-by-template-id-language?`;
const emailTemplateDefLanguagesByIdUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/available-email-definition-lan-by-template-id?`;
const addNewEmailTemplateDefinitionUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/add-new-email-definition`;
const editEmailDefinitionUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/edit-email-definition`;
const templateFiledMappingListUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/template-field-list?languageId=`;

export const getEmailTemplateList = async (languageId) => {
  try {
    const data = await api.get(`${emailTemplateListUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addNewEmailTemplateApi = async (isActive: any, templateData: any) => {
  try {
    const data = await api.post(`${addNewEmailTemplateUrl}`, {
      isActive: isActive ? 1 : 0,
      templateData,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getEmailTemplateById = async (templateId) => {
  try {
    const data = await api.get(`${emailTemplateByIdUrl}${templateId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const editEmailTemplateApi = async (templateId: any, isActive: any, templateData: any) => {
  try {
    const data = await api.patch(`${editEmailTemplateUrl}`, { templateId, isActive: isActive ? 1 : 0, templateData });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getEmailTemplateDefLanguagesById = async (templateId) => {
  try {
    const data = await api.get(`${emailTemplateDefLanguagesByIdUrl}templateId=${templateId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getEmailTemplateDefByIdLanguage = async (templateId, languageId) => {
  try {
    const data = await api.get(
      `${emailTemplateDefByIdLanguageUrl}templateId=${templateId}&languageId=${languageId}`,
      []
    );

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addNewEmailTemplateDefinitionApi = async (templateId: any, languageId: any) => {
  try {
    const data = await api.post(`${addNewEmailTemplateDefinitionUrl}`, {
      templateId,
      languageId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    } else {
      let msg = 'Something went Wrong!';

      if (data?.data?.errorMessage) {
        msg = data?.data?.errorMessage;
      }

      addToaster({
        status: 'error',
        title: 'Update Error',
        message: msg,
      });
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const editEmailDefinitionApi = async (
  templateId: any,
  languageId: any,
  meta: any,
  htmlBody: any,
  subject: any
) => {
  try {
    const data = await api.patch(`${editEmailDefinitionUrl}`, { templateId, languageId, meta, htmlBody, subject });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getTemplateFiledMappingList = async (languageId) => {
  try {
    const data = await api.get(`${templateFiledMappingListUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
