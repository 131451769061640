import { Grid, Stack } from "@mui/material";
import CommonWitnessBlock from "../CommonWitnessBlock";
import { SinglePageBreak } from "../CommonPageBreaks";

const LoanReceiptWitness = () => {
  return (
    <>
      <Grid item xs={24} p={1} mt={1}>
        <Stack direction={"column"} flex={1}>
          <Stack mb={2}>Witnesses :</Stack>
          <CommonWitnessBlock index={1} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
          <SinglePageBreak />
          <Stack mt={2}></Stack>
          <CommonWitnessBlock index={2} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
        </Stack>
      </Grid>
    </>
  );
};

export default LoanReceiptWitness;
