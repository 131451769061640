import { Downgraded } from "@hookstate/core";
import { creditData, creditDetails } from "../configs/mainStore";
import AppConfig from "./AppConfig";

function cacheDepositedFieldValidator() {
    let status = false;

    try {
        let currentProductCatalog: string;
        currentProductCatalog = getProductCatalog();
        const cashDepositValidatedCatalogs = AppConfig.config?.cashDepositValidatedCatalogs || [];

        if (cashDepositValidatedCatalogs.includes(currentProductCatalog)) {
            status = true;
        } else {
            status = false;
        }
    } catch (error) {
        status = false;
    } finally {
        return status;
    }
}

const getProductCatalog = () => {
    let { type, sector, scheme }: any = creditDetails.attach(Downgraded).get();
    if (!scheme && !sector) {
        let result = creditData?.formData["creditData"].attach(Downgraded).get();
        type = result.type;
        sector = result.sector;
        scheme = result.scheme;
    }

    if (!scheme) {
        return "";
    }

    return [type, sector, scheme].join("-");
};

export default cacheDepositedFieldValidator;
