import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { yearAndMonthFormatter } from "../../../../../../utility/fomatters";
import { getAnnualIncome, getGrossIncome, getNetIncome, incomeExpenseFrequency, jointBorrowerIncomeHolder, securityDataIncomeHolder } from "../../../../../../utility/util";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import DetailItem from "../../DetailItem";

const PersonnelIncome = ({ personnelIncome, masterData, isGuarantor, isJointBurrower }) => {
  const { t: translate } = useTranslation();

  const totalIncomeFunc = () => {
    let total = 0;
    personnelIncome.forEach((personnel) => {
      const totalItem = getAnnualIncome(personnel?.basicSalary, personnel?.averageVariableAllowance, personnel?.fixedAllowance, personnel?.lastThreeAverageDeduction, personnel?.frequencyForYear, personnel?.frequencyForMonth);
      total += totalItem;
    });
    return `LKR ${formatCurrency(total)}`;
  };

  return (
    <div className="full-width margin-xs-b">
      {(personnelIncome && personnelIncome?.length > 0) ? (
        <>
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            Personal Income
          </Grid>
          <Grid container>
            <Grid container p={1} className="data-box" spacing={1}>
              <Grid container item mt={1}>
                <Grid item xs={3}>
                  <DetailItem title="Net Annual Income" value={totalIncomeFunc()} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
              </Grid>
              <Grid item container>
                {personnelIncome
                  ?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)
                  ?.map((incomeData, key) => {
                    let getMasterDataOfIncomeHolder = isGuarantor ? securityDataIncomeHolder : isJointBurrower ? jointBorrowerIncomeHolder : masterData?.INCOME_HOLDER?.get();
                    const incomeHolder = getMasterDataOfIncomeHolder?.find((t) => t.id == incomeData?.incomeHolder);
                    const salaryAssignment = masterData?.SALARY_ASSIGNMENT?.get()?.find((t) => t.id == incomeData?.salaryAssignment);
                    const sourceOfIncome = masterData?.INCOME_SOURCE?.get()?.find((t) => t.id == incomeData?.sourceOfIncome);
                    const incomeFrequency: any = incomeExpenseFrequency?.find((t) => t.id == incomeData?.incomeFrequency);
                    const frequencyName: any = incomeFrequency?.name ? translate(incomeFrequency.name) : "";

                    const getGrossIncomeFunc = (salary, allowances, fixedAllowances) => {
                      const val = getGrossIncome(salary, allowances, fixedAllowances);
                      return `LKR ${formatCurrency(val)}`;
                    };

                    const getNetIncomeFunc = (salary, allowances, fixedAllowances, deduction) => {
                      const val = getNetIncome(salary, allowances, fixedAllowances, deduction);
                      return `LKR ${formatCurrency(val)}`;
                    };

                    const getAnnualIncomeFunc = (salary, allowances, fixedAllowances, deduction, year, month) => {
                      const val = getAnnualIncome(salary, allowances, fixedAllowances, deduction, year, month);
                      return `LKR ${formatCurrency(val)}`;
                    };

                    return (
                      <MandatoryFilterComponent
                        items={personnelIncome}
                        mandatory={["incomeHolder", "sourceOfIncome", "salarySlipBasedOn", "frequencyForMonth", "incomeFrequency", "basicSalary"] as never[]}
                        options={{
                          title: "Personal Income",
                        }}
                      >
                        <Grid container p={1} key={key} className="data-box">
                          <Grid container item spacing={1}>
                            <Grid item xs={3}>
                              <DetailItem title="Income Holder" value={incomeHolder?.name ? incomeHolder.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Salary Assignment" value={salaryAssignment?.name ? salaryAssignment.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Source of Income" value={sourceOfIncome?.name ? sourceOfIncome.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Salary Slip Baseds on" value={incomeData?.salarySlipBasedOn ? yearAndMonthFormatter(incomeData.salarySlipBasedOn) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Frequency" value={frequencyName ? frequencyName : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Occurrences for Month" value={incomeData?.frequencyForMonth ? incomeData.frequencyForMonth : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Frequency for Annual" value={incomeData?.frequencyForYear ? incomeData.frequencyForYear : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Salary/Pension Amount" value={incomeData?.basicSalary ? `LKR ${formatCurrency(incomeData.basicSalary)}` : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title={`Average Variable Allowances Last 3 ${frequencyName} average`} value={incomeData?.averageVariableAllowance ? `LKR ${formatCurrency(incomeData.averageVariableAllowance)}` : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Fixed Allowances" value={incomeData?.fixedAllowance ? `LKR ${formatCurrency(incomeData.fixedAllowance)}` : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Gross Income" value={getGrossIncomeFunc(incomeData?.basicSalary, incomeData?.averageVariableAllowance, incomeData?.fixedAllowance)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title={`Long Term Deductions`} value={incomeData?.lastThreeAverageDeduction ? formatCurrency(incomeData.lastThreeAverageDeduction) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Net Income" value={getNetIncomeFunc(incomeData?.basicSalary, incomeData?.averageVariableAllowance, incomeData?.fixedAllowance, incomeData?.lastThreeAverageDeduction)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Annual Income" value={getAnnualIncomeFunc(incomeData?.basicSalary, incomeData?.averageVariableAllowance, incomeData?.fixedAllowance, incomeData?.lastThreeAverageDeduction, incomeData?.frequencyForYear, incomeData?.frequencyForMonth)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                          </Grid>
                        </Grid>
                      </MandatoryFilterComponent>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PersonnelIncome;
