import { validateCitizenship, validateDate, validateEmail, validateFutureDate, validatelength, validateMobileNumber, validateName, validateNewNic, validateNewNicNumbering, validateOldNic, validateOldNicNumbering, validatePastDate, validateTextWithCharactersAndSpaces, nicvalidateDate } from "../../validation-rules/validationRules";

export const primaryIdentificationDetailsValidationOnNext = (personalData) => {
  //================== Primary Applicant Details Erros Object - Start ==================//

  //================== Other Identification Details - Start ==================//

  const otherIdentificationDetailsValidation = (value) => {
    const otheridTypes = {};
    for (let i = 0; i < value.length; i++) {
      if (!value[i].removedItem) {
        const otherIdExp = otherIdExpValidation(value[i]?.idExp, i);
        if (otherIdExp) otheridTypes[i] = { ...otheridTypes[i], idExp: otherIdExp };
      }
    }
    if (Object.keys(otheridTypes).length > 0) {
      return otheridTypes;
    } else return null;
  };

  //================== Other Identification Details - End ==================//

  const primaryIdentificationDetailsErrors: any = {};
  const initialsInFullVal = personalData?.initialsInFull ? initialsInFullValidation("Initials in Full", personalData?.initialsInFull) : null;
  const lastNameVal = personalData?.primaryLastName ? lastNameValidation("Last Name", personalData?.primaryLastName) : null;
  const usedNameVal = personalData?.usedName ? usedNameValidation("Used Name", personalData?.usedName) : null;
  const initialsVal = personalData?.initials ? initialsValidation("Initials", personalData?.initials) : null;
  const othernamesVal = personalData?.primaryOtherName ? otherNamesValidation("Other Names", personalData?.primaryOtherName) : null;
  const oldNicVal = personalData?.oldNic ? oldNicValidation("Old NIC", personalData?.oldNic) : null;
  const newNicVal = personalData?.newNic ? newNicValidation("New NIC", personalData?.newNic) : null;
  const primaryContactVal = personalData?.primaryContact ? primaryContactNoValidation("Primary Contact", personalData?.primaryContact, 10) : null;
  const primaryEmailVal = personalData?.primaryEmail ? primaryEmailValidation("Primary Email", personalData?.primaryEmail) : null;
  const otherIdentificationDetailsVal = personalData?.idTypes ? otherIdentificationDetailsValidation(personalData.idTypes) : null;
  const spouseFullNameVal = personalData?.spouseFullName ? spouseNameValidation("Spouse Name in Full", personalData?.spouseFullName) : null;
  const spouseOldNicVal = personalData?.spouseOldNic ? oldNicValidation("Spouse Old NIC", personalData?.spouseOldNic) : null;
  const spouseNewNicVal = personalData?.spouseNewNic ? newNicValidation("Spouse New NIC", personalData?.spouseNewNic) : null;
  const sriLankanCitizenshipVal = personalData?.sriLankanCitizenship ? citizenShipValidation(personalData?.sriLankanCitizenship) : null;
  const taxIdentificationNumberValidationRes = personalData?.individualTaxNo ? taxIdentificationNumberValidation(`Tax Identification Number`, personalData?.individualTaxNo, 5, 15) : null;

  if (taxIdentificationNumberValidationRes) primaryIdentificationDetailsErrors.individualTaxNo = taxIdentificationNumberValidationRes;
  if (initialsInFullVal) primaryIdentificationDetailsErrors.initialsInFull = initialsInFullVal;
  if (lastNameVal) primaryIdentificationDetailsErrors.primaryLastName = lastNameVal;
  if (usedNameVal) primaryIdentificationDetailsErrors.usedName = usedNameVal;
  if (initialsVal) primaryIdentificationDetailsErrors.initials = initialsVal;
  if (othernamesVal) primaryIdentificationDetailsErrors.primaryOtherName = othernamesVal;
  if (oldNicVal) primaryIdentificationDetailsErrors.oldNic = oldNicVal;
  if (newNicVal) primaryIdentificationDetailsErrors.newNic = newNicVal;
  if (primaryContactVal) primaryIdentificationDetailsErrors.primaryContact = primaryContactVal;
  if (primaryEmailVal) primaryIdentificationDetailsErrors.primaryEmail = primaryEmailVal;
  if (otherIdentificationDetailsVal) primaryIdentificationDetailsErrors.idTypes = otherIdentificationDetailsVal;
  if (spouseFullNameVal) primaryIdentificationDetailsErrors.spouseFullName = spouseFullNameVal;
  if (spouseOldNicVal) primaryIdentificationDetailsErrors.spouseOldNic = spouseOldNicVal;
  if (spouseNewNicVal) primaryIdentificationDetailsErrors.spouseNewNic = spouseNewNicVal;
  if (sriLankanCitizenshipVal) primaryIdentificationDetailsErrors.sriLankanCitizenship = sriLankanCitizenshipVal;

  return Object.keys(primaryIdentificationDetailsErrors).length > 0 ? primaryIdentificationDetailsErrors : 1;

  //================== Primary Applicant Details Erros Object - End ==================//
};

//================== Field Validation Functons - Start ==================//

export const birthDateValidation = (value) => {
  const result: any[] = [];
  const checkDate = validateDate("Birth Day", value);
  if (checkDate) {
    result.push(checkDate);
    return result;
  } else {
    const checkDOB = validatePastDate("Birth Day", value);
    if (checkDOB) {
      result.push(checkDOB);
      return result;
    } else return null;
  }
};

export const otherIdExpValidation = (value, i) => {
  const result: any[] = [];
  const checkDate = validateDate("Date", value);
  if (checkDate) {
    result.push(checkDate);
    return result;
  } else {
    return null;
  }
};

const initialsInFullValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const lastNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const usedNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const initialsValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const otherNamesValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const oldNicValidation = (fieldName, value) => {
  const checkNewNic = validateOldNic(value);
  const checkLength = validatelength(fieldName, value);
  const checkNumbering = validateOldNicNumbering(value);
  const result: any[] = [];
  if (checkNewNic) result.push(checkNewNic);
  if (checkLength) result.push(checkLength);
  if (checkNumbering) result.push(checkNumbering);
  return result.length > 0 ? result : null;
};

export const newNicValidation = (fieldName, value) => {
  const checkNewNic = validateNewNic(value);
  const checkLength = validatelength(fieldName, value);
  const checkNumbering = validateNewNicNumbering(value);
  const result: any[] = [];
  if (checkNewNic) result.push(checkNewNic);
  if (checkLength) result.push(checkLength);
  if (checkNumbering) result.push(checkNumbering);
  return result.length > 0 ? result : null;
};

export const primaryContactNoValidation = (fieldname, value, length) => {
  const result: any[] = [];
  const checkContactNumber = validateMobileNumber(fieldname, value);
  const checkLength = validatelength(fieldname, value, length);
  if (checkContactNumber) result.push(checkContactNumber);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const primaryEmailValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkEmail = validateEmail(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkEmail) result.push(checkEmail);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const spouseNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkName = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkName) result.push(checkName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const ageValidation = (value) => { };

export const citizenShipValidation = (value) => {
  const result: any[] = [];
  const checkCitizenship = validateCitizenship(value);
  if (checkCitizenship) result.push(checkCitizenship);
  return result.length > 0 ? result : null;
};

export const taxIdentificationNumberValidation = (fieldName, value, min, max) => {
  const result: any[] = [];
  const checkLength = validatelength(fieldName, value + '', null, min, max);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

//================== Field Validation Functons - End ==================//
