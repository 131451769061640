import { ApplicationFormKey } from "../../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import MachineryEquipmentsInspections from "../MachineryEquipments/MachineryEquipmentsInspections";
import InspectionPledge from "../Pledge/InspectionPledge";
import InspectionProperty from "../Property/InspectionProperty";


export const collateralTabRendering :any[] = [
    {
        id: "ins-imm-property",
        title: "Property"

    },
    {
        id: "ins-imm-machinery-equipments",
        title: "Immovable Machinery Equipments"

    },
    {
        id: "ins-mm-machinery-equipments",
        title: "Movable Machinery Equipments"

    },
    {
        id: "ins-mm-pledge",
        title: "Pledge"

    },
];
export const collaterlComponentMapping = {
    "ins-imm-property": InspectionProperty,
    "ins-imm-machinery-equipments": MachineryEquipmentsInspections,
    "ins-mm-machinery-equipments": MachineryEquipmentsInspections,
    "ins-mm-pledge": InspectionPledge,
}

export const collateralTitle = {
    "ins-imm-property": "Property",
    "ins-imm-machinery-equipments": "Immovable Machinery Equipments",
    "ins-mm-machinery-equipments": "Movable Machinery Equipments",
    "ins-mm-pledge": "Pledge"
};

export const requetsDtlKeyMapping = {
    "ins-imm-property": "immovable_property",
    "ins-imm-machinery-equipments": "immovable_immovableMachineryEquipement",
    "ins-mm-machinery-equipments": "movable_machineryEquipment",
    "ins-mm-pledge": "movable_pledge"
};

export const formKeys = {
    "ins-imm-property": ApplicationFormKey.property,
    "ins-imm-machinery-equipments": ApplicationFormKey.immMachineryEquioments,
    "ins-mm-machinery-equipments": ApplicationFormKey.mmMachineryEquipments,
    "ins-mm-pledge": ApplicationFormKey.pledge
};
