import { TableBody } from '@mui/material'
import React from 'react'

const TableBodyWrapper = ({ children }) => {
    return (
        <TableBody>
            {children}
        </TableBody>
    )
}

export default TableBodyWrapper