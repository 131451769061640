import {
  Divider,
  Stack,
  Switch,
  Typography,
  alpha,
  styled
} from "@mui/material";
import React from "react";
import { OnDemandValueFormatter } from "../../../services/onDemandValidators";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../CommunicationService/CMTextInput";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";

const ScorecardTemplateModal = ({ handleSave, scorecardTemplate: template }) => {
  const [error, setError]: any = React.useState({ name: null, description: null });

  const GreenSwitch = styled(Switch)(({ theme }: any) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#00AB71",
      "&:hover": {
        backgroundColor: alpha("#00AB71", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00AB71",
    },
  }));

  const handleChange = (e) => {
    if (e.target.name === "isActive") {
      template.isActive = e.target.checked ? 1 : 0;
    } else {
      template[e.target.name] = e.target.value;

      setError({ ...error, [e.target.name]: "" });
    }
  };

  const validateAndSave = () => {
    let err: any = { ...error };
    if (!template.name) {
      err = { ...err, name: "Name can not be empty" }
    }
    if (!template.description) {
      err = { ...err, description: "Description can not be empty" };
    }

    setError(err);

    if (err.name || err.description) {
      return;
    }

    handleSave(template);
  };

  return <Stack>
    <Divider style={{ marginBottom: 10 }} />

    <CMTextInput
      desc={"Published"}
      component={
        <GreenSwitch
          name="isActive"
          checked={template?.isActive === 1 ? true : false}
          onChange={handleChange}
        />
      }
    />

    <CMTextInput
      desc={"Key"}
      component={
        <TextBoxComponent
          name={"key"}
          value={template?.key || ""}
          fullWidth={true}
          onChange={handleChange}
          onInput={OnDemandValueFormatter.alphaNumericLowerCaseUnderscore}
        />
      }
    />
    {error.key && <Typography color={"error"} variant="caption">{error.key}</Typography>}
    <CMTextInput
      desc={"Name"}
      component={
        <TextBoxComponent
          name={"name"}
          value={template?.name || ""}
          fullWidth={true}
          onChange={handleChange}
        />
      }
    />
    {error.name && <Typography color={"error"} variant="caption">{error.name}</Typography>}
    <CMTextInput
      desc={"Description"}
      component={
        <TextBoxComponent
          name={"description"}
          value={template?.description || ""}
          fullWidth={true}
          onChange={handleChange}
        />
      }
    />
    {error.description && <Typography color={"error"} variant="caption">{error.description}</Typography>}

    <Divider style={{ marginTop: 20 }} />
    <Stack mt={1} direction={"row"} justifyContent={"flex-end"} spacing={2}>
      <ButtonComponent
        variant={"outlined"}
        onClick={() => {
          closeGlobalModal();
        }}
        title={"Close"}
      />
      <ButtonComponent variant={"contained"} onClick={validateAndSave} title={"Save"} />
    </Stack>
  </Stack>;
};

export default ScorecardTemplateModal;
