import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { getHistoryDataForApplication, getStageColors } from "../../../../../services/creditFileApiCall";
import HistoryItemRow from "./HistoryItemRow";

import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

const WorkflowHistory = ({ data, applicationId, currentApplicationWfData, innerComponentData }) => {
  const historyData: any = useState([]);
  const { wf_stepTypes }: any = useState(workflowMasterData);

  const [isLoading, setIsLoading] = React.useState(true);

  const { userId, userInGroups }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [stageColors, setStageColors] = React.useState();

  useEffect(() => {
    readStages(null);
  }, []);

  const readStages = async function (params) {
    if (!applicationId) {
      return;
    }
    setIsLoading(true);
    const [res = [], stages = []] = await Promise.all([getHistoryDataForApplication(applicationId), getStageColors()]);

    if (stages) {
      setStageColors(stages);
    }

    if (res) {
      let formattedArray: any = [];
      const stageType = wf_stepTypes?.get()?.find((t) => t.stepTypeKey == "stage");
      const jobType = wf_stepTypes?.get()?.find((t) => t.stepTypeKey == "job");

      for (let i = 0; i < res.length; i++) {
        let data = {};
        if (res[i].sequence == 1) {
          formattedArray[formattedArray.length] = {
            nextStage: res[i].stage,
            nextStepType: stageType?.id,
            sequence: 0,
          };
        }
        if (i === 0) {
          data = {
            ...res[i],
            nextStage: currentApplicationWfData.currentWorkflowStage,
            nextStepType: stageType?.id,
          };
        } else {
          if (stageType.id == res[i - 1].stepType) {
            data = {
              ...res[i],
              nextStage: res[i - 1].stage,
              nextStepType: res[i - 1].stepType,
            };
          } else if (jobType.id == res[i - 1].stepType) {
            data = {
              ...res[i],
              nextJob: res[i - 1].job,
              nextJobStatus: res[i - 1].jobStatus,
              nextStepType: res[i - 1].stepType,
            };
          }
        }

        formattedArray[formattedArray.length] = data;
      }

      let sortedArray = formattedArray.sort((a, b) => compare(b, a, "sequence"));
      const data = { ...sortedArray[sortedArray.length - 2], ...sortedArray[sortedArray.length - 1] };
      sortedArray[sortedArray.length - 1] = data;
      sortedArray[sortedArray.length - 1].timeStamp = currentApplicationWfData.applicationSubmittedDate;
      historyData.set(sortedArray);
    }

    setIsLoading(false);

    if (params && sessionStorage.currentApplication) {
      window.location.reload();
    }
  };

  let stageCount = historyData.get().filter((item) => item.stepType === 1).length;

  if (isLoading) {
    return (
      <div className="full-width">
        <InnerSectionHeaderComponent
          innerComponentData={innerComponentData}
          applicationId={applicationId}
          currentApplicationWfData={currentApplicationWfData}
          disabled={isLoggedInUserNotTheAssignee}
        />
        <Grid container maxHeight={"calc(100vh - 200px)"} sx={{ overflowY: "auto" }}>
          <Stack spacing={1} m={1} className="full-width">
            <CircularProgressComponent
              size={30}
              sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", zIndex: 100 }}
            />
          </Stack>
        </Grid>
      </div>
    );
  }

  return (
    <div className="full-width">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={currentApplicationWfData}
        disabled={isLoggedInUserNotTheAssignee}
      />
      <Grid container maxHeight={"calc(100vh - 200px)"} sx={{ overflowY: "auto" }}>
        <Stack spacing={1} m={1} className="full-width">
          {historyData && historyData.length > 0 ? (
            <>
              <Grid
                container
                className="full-width border-radius-5"
                alignItems="center"
                p={1}
                style={{ background: "#f8f9fa" }}
              >
                <>
                  <Grid item xs={1} className="align-center">
                    #
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={3}>
                      <div
                        className="basic-font font-size-14  border-radius-5 align-center font-size-16 basic-font-color"
                        style={{
                          margin: 2,
                        }}
                      >
                        Action
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        className=" font-size-14  border-radius-5 align-center basic-font font-size-16 basic-font-color"
                        style={{ margin: 2, minWidth: "100% " }}
                      >
                        Stage
                      </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                      <div className="basic-font  font-size-16 basic-font-color" style={{ margin: 2 }}>
                        Actioned By
                      </div>
                    </Grid>
                  </Grid>
                </>

                <Grid item xs={4}>
                  <div className="align-left  basic-font font-size-16 basic-font-color" style={{ margin: 2 }}>
                    Assigned To
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}></Grid>
              </Grid>

              {historyData?.get()?.map((data, idx) => (
                <HistoryItemRow
                  data={{ ...data, index: data.sequence + 1, idx, stageCount }}
                  key={data.sequence}
                  stageColors={stageColors}
                  revertHandled={readStages}
                />
              ))}
            </>
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default WorkflowHistory;
