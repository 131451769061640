import { useHookstate } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { SwitchComponent } from "../../../components/InputComponents/SwitchComponent/SwitchComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { productMapping } from "../../../services/productApis";
import { ComponentMapper } from "./ComponentMapper";

export default ({ callback, values: addedItems }) => {
    const [values, setValues]: any = useState([]);

    const setup: any = useHookstate({
        activeWF: true,
        WFtype: true,
        selections: [],
        loading: true,
        workflows: [],
        wfMap: {},
        disabled: false
    });

    useEffect(() => {
        loadCurrentWorkflowMappings();
    }, []);

    const loadCurrentWorkflowMappings = async () => {
        try {
            setup.loading.set(true);
            const activeWorkflows = (setup.workflows.get().length > 0) ? setup.workflows.get() : (await productMapping())?.activeWorkflows;

            setup.workflows.get().length === 0 && setup.workflows.set(activeWorkflows);
            processList(activeWorkflows);
        } catch (error) { }
    }

    const processList = async (list = null) => {
        const _toggleWorkflowType = setup.WFtype.get() === true ? 1 : 2;

        const to = setTimeout(() => {
            const preItems = addedItems.map(wf => wf.id);
            const map = {};
            const items = (list || setup.workflows.get()).map(item => {
                const { id, active, name, desc, workflowType } = item;

                let _disabled = false;

                if (preItems.includes(id)) {
                    if (workflowType === 1) {
                        setup.disabled.set(true)
                    }
                    return null;
                }

                map[id] = item;

                if (setup.activeWF.get() && active === 0) {
                    return null;
                }

                if (!setup.activeWF.get() && active === 1) {
                    return null;
                }

                return {
                    id,
                    name,
                    desc: `${desc} | Active: ${active ? "Yes" : "No"}`,
                    workflowType,
                    active: active === 1 ? true : false,
                    disabled: _disabled
                };
            }).filter((item) => {
                return setup.activeWF.get() === item?.active && _toggleWorkflowType === item?.workflowType
            });

            setValues(items);
            setup.loading.set(false);
            setup.wfMap.set(map);
            clearTimeout(to);
        }, 500);
    }

    const addItemsFromToMapping = async () => {
        const list: any = [];
        for (const item of setup.workflows.get()) {
            if (setup.selections.get().includes(item.id)) {
                list.push(item);
            }
        }
        callback(list, setup.wfMap.get());
    }

    const handleSelection = async (status, data) => {
        setup.selections.set([data.id]);
    }

    if (setup.loading.get()) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Paper elevation={0}><CircularProgressComponent size={30} /></Paper></Stack>;
    }

    if (values.length === 0) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Paper elevation={0}><Typography color={"gray"}>No {setup.activeWF.get() ? 'Active' : 'Inactive'} items found.</Typography></Paper></Stack>;
    }

    return <ComponentMapper title={`${setup.activeWF.get() ? 'Active' : 'Inactive'} Workflows`} items={values} selections={setup.selections.get()} handleSelection={handleSelection} loading={setup.loading.get()} disabled={setup.disabled.get()}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Stack direction={"row"} alignItems={'center'}>
                <Typography color={"gray"}>{setup.activeWF.get() ? 'Active' : 'Inactive'}</Typography>
                <SwitchComponent checked={setup.activeWF.get()} onChange={(e) => {
                    setup.activeWF.set(e.target.checked);
                    loadCurrentWorkflowMappings();
                }}></SwitchComponent>
            </Stack>
            <Stack direction={"row"} alignItems={'center'}>
                <Typography color={"gray"}>{setup.WFtype.get() ? 'Main Workflow' : 'Sub Workflow'}</Typography>
                <SwitchComponent checked={setup.WFtype.get()} onChange={(e) => {
                    setup.WFtype.set(e.target.checked);
                    loadCurrentWorkflowMappings();
                }}></SwitchComponent>
            </Stack>
            {setup.selections.get().length > 0 && <ButtonComponent title={"Add"} onClick={addItemsFromToMapping}></ButtonComponent>}
            <ButtonComponent title={<CloseIcon />} onClick={closeGlobalModal}></ButtonComponent>
        </Stack>
    </ComponentMapper>;
};
