import { Autocomplete, TextField } from "@mui/material";
import React, { FC } from "react";
import { AutoCompletePropTypes } from "../../../configs/interfaces";
import { SelectComponentTooltip } from "../../OtherComponents/Info";

const AutoCompleteProxyComponent: FC<AutoCompletePropTypes> = ({
  id,
  options,
  sx,
  fullWidth,
  clearIcon,
  disabled,
  size,
  value,
  onChange,
  onClose,
  labelKey,
  cls,
  label,
  required,
  tooltipData,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  let rawOptions = [];

  try {
    rawOptions = options.get();
    rawOptions = Object.assign([], rawOptions).map((proxy) =>
      Object.assign({}, proxy)
    );
  } catch {
    try {
      rawOptions = Object.assign([], options).map((option) =>
        Object.assign({}, option)
      );
    } catch {
      rawOptions = [];
    }
  }

  try {
    let objects = rawOptions.filter((obj) => value === obj["id"]);
    if (objects && objects.length > 0) {
      value = objects[0];
    }
  } catch {
    rawOptions = [];
  }

  return (
    <Autocomplete
      id={id}
      options={rawOptions}
      fullWidth={fullWidth}
      sx={sx}
      value={value || null}
      size={size ? size : "small"}
      onClose={onClose}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        (option?.id || option?.toString()) === (value?.id || option?.toString())
      }
      clearIcon={clearIcon}
      ListboxProps={{ style: { maxHeight: "180px" } }}
      renderInput={(params) => {
        if (tooltipData) {
          return (
            <SelectComponentTooltip data={tooltipData}>
              <TextField {...params} label={label} required={required} />
            </SelectComponentTooltip>
          );
        } else {
          return <TextField {...params} label={label} required={required} />;
        }
      }}
      onChange={(event: any, newValue: string | null) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(item) => {
        return Object.keys(item)?.length !== 0
          ? labelKey
            ? item?.[labelKey]
            : item["name"]
              ? item["name"]
              : ""
          : "";
      }}
      className={cls}
    />
  );
};

export default AutoCompleteProxyComponent;
