import { Downgraded } from "@hookstate/core";
import React, { useMemo } from "react";
import { openGlobalModal } from "../../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../../GlobalToast";
import { TableRowWrapper } from "../../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableColumnsTextWrapperAnyNew from "../../../../Helpers/inputHelpers/TableColumnsTextWrapperAnyNew";
import TableColumnsTextBoxWrapperAlphanumericNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperAlphanumericNew";
import TableColumnsTextBoxWrapperNumberNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperNumberNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";

const CurrentAccountDetailsTableBuildRow = ({ data, callBackOnDelete }) => {
  const dataKeys: any[] = Object.keys(data) || [];
  const [totalDebits, setTotalDebits]: any = React.useState(0);
  const [totalCredits, setTotalCredits]: any = React.useState(0);
  const [totalBalanceOfMonthEnd, setTotalBalanceOfMonthEnd]: any = React.useState(0);
  const [totalDebitsAverage, setTotalDebitsAverage]: any = React.useState(0);
  const [totalCreditsAverage, setTotalCreditsAverage]: any = React.useState(0);
  const [totalBalanceOfMonthEndAverage, setTotalBalanceOfMonthEndAverage]: any = React.useState(0);

  useMemo(() => {
    let itemCount = dataKeys.length || 0;

    // Totals
    let _debit = 0;
    let _credit = 0;
    let _balanceOfMonthEnd = 0;

    // Averages
    let _debitAverage = 0;
    let _creditAverage = 0;
    let _balanceOfMonthEndAverage = 0;

    dataKeys?.map((key, index) => {
      const { debit, credit, balanceOfMonthEnd } = data[key];
      _debit += parseFloat(debit?.get());
      _credit += parseFloat(credit?.get());
      _balanceOfMonthEnd += parseFloat(balanceOfMonthEnd?.get());
    });

    if (itemCount > 0) {
      _debitAverage = parseFloat((_debit / itemCount).toFixed(2));
      _creditAverage = parseFloat((_credit / itemCount).toFixed(2));
      _balanceOfMonthEndAverage = parseFloat((_balanceOfMonthEnd / itemCount).toFixed(2));
    }

    setTotalDebits(_debit);
    setTotalCredits(_credit);
    setTotalBalanceOfMonthEnd(_balanceOfMonthEnd);
    setTotalDebitsAverage(_debitAverage);
    setTotalCreditsAverage(_creditAverage);
    setTotalBalanceOfMonthEndAverage(_balanceOfMonthEndAverage);
  }, [data]);

  const dataObj = data.attach(Downgraded).get();
  const onchangeHandler = (value, dataKey, dataIndex) => {
    dataObj[dataIndex][dataKey] = value;
    data?.set(dataObj);
  };

  const itemRowDeleteDispatcher = (key: any, type = "") => {
    if (key) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete the row item!.",
          callBackOnAction: () => callBackOnDelete(key, type),
        },
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty Item Key",
        message: "Item key is required to proceed the deletion",
      });
    }
  };

  return (
    <>
      {dataKeys?.length > 0 &&
        dataKeys?.map((key, index) => {
          const { bank, accountNumber, debit, credit, balanceOfMonthEnd } = data[key];

          return (
            <>
              <TableRowWrapper>
                <TableColumnsTextWrapperAnyNew index={index + "bank"} dataKey={"bank"} dataIndex={key} onchangeHandler={onchangeHandler}>
                  <>{bank?.get()}</>
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "accountNumber"} value={accountNumber} index={index + "accountNumber"} title={""} dataKey={"accountNumber"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "debit"} value={debit} index={index + "debit"} title={""} dataKey={"debit"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "credit"} value={credit} index={index + "credit"} title={""} dataKey={"credit"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "balanceOfMonthEnd"} value={balanceOfMonthEnd} index={index + "balanceOfMonthEnd"} title={""} dataKey={"balanceOfMonthEnd"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextWrapperAnyNew key={index + "actions"} index={index + "actions"} style={{ textAlign: "right" }}>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => itemRowDeleteDispatcher(key, "accountDetailsBank")}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableColumnsTextWrapperAnyNew>
              </TableRowWrapper>
            </>
          );
        })}

      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"total_label"}>
          <>Total</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty1"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty2"}>
          <>{formatCurrency(totalDebits)}</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty3"}>
          <>{formatCurrency(totalCredits)}</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_outstanding"}>
          <>{formatCurrency(totalBalanceOfMonthEnd)}</>
        </TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"total_label"}>
          <>Average</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty1"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty2"}>
          <>{formatCurrency(totalDebitsAverage)}</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty3"}>
          <>{formatCurrency(totalCreditsAverage)}</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_outstanding"}>
          <>{formatCurrency(totalBalanceOfMonthEndAverage)}</>
        </TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
    </>
  );
};

export default CurrentAccountDetailsTableBuildRow;
