import { yearArrayObject } from "../../../../../../CreditInspection/FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";
import { onChangeHandler } from "../../Calculations/GenaralizedDataSetter";

export const generalizedSetStateFromTheBusinessData = ({ state, busineeData }) => {

    let yearArray: number[] = Object.values(yearArrayObject);

    const { businessIncome = {}, deductionsCostsOfGoods = {}, businessExpenses = {}, totaleBusinessAssertOtherDetails = {} } = busineeData;

    let itemObject = yearArray.reduce((dataObject: any, year: number) => {

        dataObject = onChangeHandler({ value: getTotal(businessIncome?.[year], "annualBusinessIncome"), yearDataKey: year, itemDataKey: "revenueIncome", formData: dataObject });

        dataObject = onChangeHandler({ value: getTotal(deductionsCostsOfGoods?.[year], "annualDeductionsCOG"), yearDataKey: year, itemDataKey: "reductions", formData: dataObject });

        dataObject = onChangeHandler({ value: getTotal(businessExpenses?.[year], "annualExpense"), yearDataKey: year, itemDataKey: "expenses", formData: dataObject });

        dataObject = onChangeHandler({ value: getTotal(totaleBusinessAssertOtherDetails?.[year], "depreciationExpense"), yearDataKey: year, itemDataKey: "depreciation", formData: dataObject })

        return dataObject;
    }, {});
    state(itemObject);
}

const getTotal = (state, key: string) => {
    return Array.isArray(state) ? state.reduce((cal, item) => cal += Number(item?.[key] || 0), 0) : 0;
}