import { Downgraded } from '@hookstate/core';
import { privilegeClassTypes, privilegeKeys } from '../configs/constants/privilegeKeys';
import { masterDataDetails, userDetails } from '../configs/mainStore';

export const isValidApproveLimit = (catalogKey, applicantTypeId, loanValue) => {
  const { userDla }: any = userDetails.attach(Downgraded).get();

  if (userDla) {
    let minIntValue = Number.MIN_SAFE_INTEGER;
    let loanLimit = minIntValue;
    let categoryKeyArr = catalogKey.split('-');

    let lastAsteriskKey = `${categoryKeyArr[0]}-${categoryKeyArr[1]}-*`;
    let lastTwoAsteriskKey = `${categoryKeyArr[0]}-*-*`;
    if (userDla[catalogKey]) {
      loanLimit = userDla[catalogKey]['limit']
        ? userDla[catalogKey]['limit'][applicantTypeId]
          ? userDla[catalogKey]['limit'][applicantTypeId]
          : minIntValue
        : minIntValue;
    } else if (userDla[lastAsteriskKey]) {
      loanLimit = userDla[lastAsteriskKey]['limit']
        ? userDla[lastAsteriskKey]['limit'][applicantTypeId]
          ? userDla[lastAsteriskKey]['limit'][applicantTypeId]
          : minIntValue
        : minIntValue;
    } else if (userDla[lastTwoAsteriskKey]) {
      loanLimit = userDla[lastTwoAsteriskKey]['limit']
        ? userDla[lastTwoAsteriskKey]['limit'][applicantTypeId]
          ? userDla[lastTwoAsteriskKey]['limit'][applicantTypeId]
          : minIntValue
        : minIntValue;
    }

    if (loanLimit >= loanValue) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const setPrivilegeClass = (userPrivilege, key, type = privilegeClassTypes.hide) => {
  const classNames = {
    [privilegeClassTypes.hide]: 'privilege-hide',
    [privilegeClassTypes.disabled]: 'privilege-disable',
  };
  const returnClass = classNames[type] ? classNames[type] : 'hide-class';

  if (userPrivilege && userPrivilege[key]) {
    return '';
  } else {
    return returnClass;
  }
};

export const isPrivilegeHave = (userPrivilege, key) => {
  if (userPrivilege && userPrivilege[key]) {
    return true;
  } else {
    return false;
  }
};

export const getMasterDataByKeyAndId = (key, id) => {
  const masterData: any = masterDataDetails.attach(Downgraded).get();

  let returnValue = { id: null, name: '--' };

  // if (masterData[key]) {
  //   returnValue = masterData[key]?.find((data) => data?.id == id);
  // }

  if (returnValue) {
    return returnValue;
  } else {
    return { id: null, name: '--' };
  }
};
