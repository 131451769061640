import { Downgraded, useHookstate } from "@hookstate/core";
import CalculateIcon from "@mui/icons-material/Calculate";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CORE_BANK_KEYS, creditSummaryHints } from "../../../../configs/constants/contants";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../configs/constants/masterDataKeys";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import {
  branchDetails,
  creditData,
  currentWorkflowStageDetails,
  masterDataDetails,
  productDetails,
  userDetails,
} from "../../../../configs/mainStore";
import { getCoreBankData, setCoreBankData } from "../../../../services/bankServiceInvorker";
import CachingStorage from "../../../../services/cacheStorage";
import { getCribRecordList, getTrailCalData } from "../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../services/currencyFormater";
import { getCalculateInterestRate, getSchemesByProduct } from "../../../../services/productApis";
import AppConfig from "../../../../utility/AppConfig";
import isProductOf from "../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../utility/MasterDataProvider";
import { Access } from "../../../../utility/helpers/creditFileUtility";
import ProductDataProvider from "../../../../utility/productDataProvider";
import { getCopy } from "../../../../utility/util";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { Summary } from "../../ApplicationForm/Sections/LoanSummary/Summary";
import { ErrorMessageComponent } from "../../ErrorMessageComponent";
import { openGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import microCommercialAppraisal from "../Components/Appraisal/Micro/index";
import AbilityToPayLoanAndProposedLoanAmountSummaryLayout from "./Appraisal/Micro/Components/AbilityToPayLoanAndProposedLoanAmount/AbilityToPayLoanAndProposedLoanAmountSummaryLayout";
import ComprehensiveContainer from "./Appraisal/Micro/Components/FinancialPositionOfTheCompany/Components/Comprehensive/ComprehensiveContainer";
import Generalized from "./Appraisal/Micro/Components/FinancialPositionOfTheCompany/Components/Generalized/Generalized";
import RatioAnalysisVersionTwo from "./Appraisal/Micro/Components/RatioAnalysis/RatioAnalysisVersionTwo";
import { RatioAnalysisContext } from "./Appraisal/Micro/Components/RatioAnalysis/Stores/Context";
import { balanceSheetData } from "./Appraisal/Micro/Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs";
import { abilityToRepayLoanDefaultData, defaultComprehensiveProfitAndLoanAccounts, defaultGeneralizedProfitAndLoanAccounts } from "./Appraisal/Micro/Stores/MicroAppraisalDefaultData";
import { abilityToRepayLoanData, comprehensiveProfitAndLoanAccountsData, generalizedProfitAndLoanAccountsData, viewMode } from "./Appraisal/Micro/Stores/MicroAppraisalStore";
import { BuildHeader, BuildRow, Controller, SummaryHeaderExpander } from "./Controller";
import ScheduleDisbursements from "./ScheduleDisbursements";
import SummaryTableBuilder from "./SummaryComponent/Components/AppraisalSummaryComponents/SummaryTableBuilder";

const SummaryItemRow = ({
  title,
  proposed,
  recommended,
  disbursement,
  fontColor,
  reqTooltipHint,
  recommendedTooltipHint,
  disbursementTooltipHint,
}) => {
  return (
    <Grid container item p={1}>
      <Grid item xs={3}>
        <Grid item>
          <Stack>
            <Typography color={grey[600]}>{title}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item>
          <Stack alignItems={"flex-end"}>
            <Tooltip title={reqTooltipHint}>
              <Typography fontWeight={300} color={fontColor}>
                {proposed}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item>
          <Stack alignItems={"flex-end"}>
            <Tooltip title={recommendedTooltipHint}>
              <Typography fontWeight={300} color={fontColor}>
                {recommended}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item>
          <Stack alignItems={"flex-end"}>
            <Tooltip title={disbursementTooltipHint}>
              <Typography fontWeight={"bold"} color={fontColor}>
                {disbursement}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SummaryLayout = ({ data, applicationId, innerComponentData }) => {
  const productMasterData: any = useHookstate(productDetails);
  const productCatalogKeyData = productMasterData?.productCatalogData?.allProductCatalogsWithKeys?.get();
  const institutionState: any = useHookstate(branchDetails);
  const creditDataState: any = useHookstate(creditData);
  const creditFileData: any = creditData.attach(Downgraded).get();
  const keyString = `${creditFileData?.formData?.creditData?.type}-${creditFileData?.formData?.creditData?.sector}-${creditFileData?.formData?.creditData?.scheme}`;
  const disbursed = creditFileData?.disbursed;
  const [currentLoanAmount, setCurrentLoanAmount] = useState(0);
  const [proposedLoanAmount, setProposedLoanAmount] = useState(0);
  const [disbursementLoanAmount, setDisbursementLoanAmount] = useState(0);
  const [numTerms, setNumTerms] = useState(0);
  const [proposedInterestRate, setProposeInterestRate] = useState(0);
  const [recommendInterestRate, setRecomendInterestRate] = useState(0);
  const [disbursementInterestRate, setDisbursementInterestRate] = useState(0);
  const [draftedScheme, setDraftedScheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [otherChargesList, setOtherChargesList] = useState(null);
  const [trailCalParams, setTrailCalParams] = useState<any>(null);
  const [trailCalResult, setTrailCalResult] = useState(null);
  const [schemeList, setSchemeList] = useState<any>([]);
  const [schemeListObject, setSchemeListObject] = useState<any>({});
  const [selectedScheme, setSelectedScheme] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [salaryPropotion, setSalaryPropotion]: any = useState("0.0");
  const [productRefCode, setProductRefCode] = useState("");
  const [productId, setProductId] = useState("");
  const [requestedLoanTenor, setRequestedLoanTenor]: any = React.useState(0.0);
  const [disburseLoanTenor, setDisburseLoanTenor]: any = React.useState(0.0);
  let controller: Controller;
  const masterDataStore = useState(masterDataDetails);
  const [cribDeduction, setcribDeduction]: any = React.useState(0);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [disbursementPreviousData, setDisbursementPreviousData]: any = useState({});
  const [noOfDisbursements, setNoOfDisbursements]: any = useState();
  let keyPressDisabled: any = false;
  const currentProductCatalog: any = creditFileData?.currentProductCatalogKey;
  const currentWorkflowStageData = useHookstate(currentWorkflowStageDetails);

  function schedulingNoOfRows(noOfTerms) {
    if (Object.keys(disbursementPreviousData).length > noOfTerms) {
      delete disbursementPreviousData[Object.keys(disbursementPreviousData).length];
      return;
    } else {
      const nextKey = Object.keys(disbursementPreviousData).length + 1;

      const newData = {
        percentage: "",
        disbursementAmount: "",
        status: "Pending",
        date: "",
      };

      disbursementPreviousData[nextKey] = newData;
    }
  }

  useEffect(() => {
    if (productCatalogKeyData[keyString]) {
      setProductRefCode(productCatalogKeyData[keyString]?.PRODUCT_CODE);
      setProductId(productCatalogKeyData[keyString]?.PRODUCT_ID);
    }
    getCribRecords();
  }, []);

  useEffect(() => {
    setTrialCalculaterInfo();
  }, [applicationId]);

  useEffect(() => {
    if (creditFileData) {
      setNumTerms(creditFileData?.formData?.creditData?.loanTerms);
      setCurrentLoanAmount(creditFileData?.formData?.creditData?.loanAmount);
      setProposeInterestRate(creditFileData?.formData?.creditData?.interestRate);
      setRequestedLoanTenor(creditFileData?.formData?.creditData?.loanTerms);

      let trialLoanAmount = trailCalParams?.LoanAmount ?? trailCalParams?.loanAmount;
      let trialInterestRate = trailCalParams?.LoanInterestRate ?? trailCalParams?.loanInterestRate;
      let trialLoanTenor = trailCalParams?.LoanTenure;

      // Set Loan Amount
      if (trialLoanAmount) {
        const maxLoanAmount = productCatalogKeyData[keyString]?.MAX_LOAN_AMOUNT;
        let recommandedAmount = maxLoanAmount > currentLoanAmount ? currentLoanAmount : maxLoanAmount;
        setProposedLoanAmount(recommandedAmount);
        setDisbursementLoanAmount(trialLoanAmount);
      } else {
        setProposedLoanAmount(creditFileData?.formData?.creditData?.loanAmount);
        setDisbursementLoanAmount(creditFileData?.formData?.creditData?.loanAmount);
      }

      // Set Disbursement Loan Interest Rate
      if (trialInterestRate) {
        setDisbursementInterestRate(trialInterestRate);
      } else {
        setDisbursementInterestRate(creditFileData?.formData?.creditData?.interestRate);
      }

      if (trialLoanTenor) {
        setDisburseLoanTenor(trialLoanTenor);
      } else {
        setDisburseLoanTenor(creditFileData?.formData?.creditData?.loanTerms);
      }
    }
  }, [creditFileData, trailCalParams]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (productRefCode) {
        const data = await getSchemesByProduct(productRefCode);
        if (data) {
          const selectedSchemeObj = await getCoreBankData(
            applicationId,
            CORE_BANK_KEYS.SCHEME + "," + CORE_BANK_KEYS.DISB_SCHEDULE
          );

          let obj = {
            productId: productId,
            applicationId: applicationId,
            applicantTypeId: 1,
          };

          getCalculateInterestRate(obj).then(({ status, data }) => {
            if (status === 1) {
              setRecomendInterestRate(data);
            }
          });

          if (selectedSchemeObj) {
            setSelectedScheme(selectedSchemeObj?.SCHEME);
            prepareDisbursementSchedule(selectedSchemeObj?.DISB_SCHEDULE ?? {});
          }

          let schemeListObjectTemp = {};
          let tempDropdownList: any = [];
          data?.map((item) => {
            tempDropdownList.push({
              id: item?.schemeCode,
              name: `${item?.schemeDescription} (${item?.schemeCode})`,
            });
            schemeListObjectTemp[item?.schemeCode] = item;
          });

          setSchemeListObject(schemeListObjectTemp);
          setSchemeList(tempDropdownList);
        }
      }
      setIsLoading(false);
    })();
  }, [productRefCode, refresh]);

  function prepareDisbursementSchedule(schedule) {
    setNoOfDisbursements(Object.keys(schedule).length === 0 ? 1 : Object.keys(schedule).length);

    if (Object.keys(schedule).length === 0) {
      schedule = {
        1: {
          percentage: "",
          disbursementAmount: proposedLoanAmount,
          status: "Pending",
          date: "",
        },
      };

      setDisbursementPreviousData(schedule);
    } else {
      setDisbursementPreviousData(schedule);
    }
  }

  function minimumNoOfDisbursements() {
    const values: any = Object.values(disbursementPreviousData);
    const completedDisbursements = {};
    values.forEach((item, index) => {
      if (item.status === "Complete") {
        completedDisbursements[index + 1] = item;
      }
    });
    return Object.keys(completedDisbursements).length;
  }

  const onChangeScheme = (value) => {
    let selectedSchemeData = schemeListObject[value];

    if (selectedSchemeData) {
      const currentLocalStorage = CachingStorage.read('offerLetter');

      setDraftedScheme(selectedSchemeData?.interestRate);

      let initialData = {
        [applicationId]: { rateofinterest: selectedSchemeData?.interestRate },
      };

      if (currentLocalStorage) {
        if (Object.keys(currentLocalStorage).includes(applicationId.toString())) {
          currentLocalStorage[applicationId.toString()].rateofinterest = selectedSchemeData?.interestRate;
        }
        CachingStorage.store('offerLetter', { ...initialData, ...currentLocalStorage });
      } else {
        CachingStorage.store('offerLetter', initialData);
      }
    }
  };

  type calculateSalaryProp = { incomeHolder: string, basicSalary: string, fixedAllowance: string, averageVariableAllowance: string, sourceOfIncome: number };

  function salaryProportionValidIdsArr(): any[] {
    const masterDataStoreInstance = MasterDataProvider.provideMasterData();
    const incomeHolders = masterDataStoreInstance?.getMasterData(MD_KEYS?.INCOME_HOLDER);
    const salaryProportionValidIds: any = [];

    incomeHolders?.get().map((obj) => {
      if (obj.name == "Primary Applicant") {
        salaryProportionValidIds.push(obj.id);
      }
    });
    return salaryProportionValidIds;
  }

  const calculateSalary = (obj: calculateSalaryProp) => {
    const salaryProportionConfigs: any = AppConfig?.config?.salaryProportionConfigs || {};
    const salaryPropValue: number = new ProductDataProvider(creditDataState).salaryProportionFactor() || 0;
    let salaryPropotion: number = 0.00;
    let salaryProportionValidIdsList = salaryProportionValidIdsArr();

    try {
      const { incomeHolder, basicSalary, fixedAllowance, averageVariableAllowance, sourceOfIncome } = obj;
      if (salaryProportionValidIdsList.length) {
        if (salaryProportionValidIdsList.includes(incomeHolder)) {
          if (Object.keys(salaryProportionConfigs).includes(currentProductCatalog)) {
            let incomeValue: any = 0.0;
            let salaryProportionFactor = 0.0;

            if (salaryProportionConfigs[salaryProportionConfigs][sourceOfIncome]) {
              incomeValue = basicSalary;
              salaryProportionFactor = salaryProportionConfigs[salaryProportionConfigs][sourceOfIncome]
            }
            salaryPropotion = (Number(incomeValue) + Number(fixedAllowance) + Number(averageVariableAllowance)) * salaryProportionFactor;
          } else {
            salaryPropotion = (Number(basicSalary) + Number(fixedAllowance) + Number(averageVariableAllowance)) * salaryPropValue;
          }
        }
      }
    } catch (error) {
      new Error("| Credit | SummaryLayout | calculateSalary |")
    } finally {
      return salaryPropotion;
    }
  };

  const getSalary = (incomeData) => {
    let segments = incomeData.map(calculateSalary);
    let sum = segments.reduce((sum, value) => sum + value);
    return sum;
  };

  const bindSalaryPropotion = () => {
    let salaryPayload = creditFileData?.formData?.incomeData?.personnelIncome ?? {};

    try {
      if (salaryPayload) {
        let salary = getSalary(salaryPayload);
        setSalaryPropotion(isNaN(salary) ? 0.0 : salary);
      }
    } catch (error) {
    }
  };

  const onClickedSend = async () => {
    if (selectedScheme !== "") {
      setIsLoading(true);
      setIsLoading(false);
      const schemeRate = draftedScheme ?? disbursementInterestRate;
      setDraftedScheme(null);
      setDisbursementInterestRate(schemeRate);
    } else {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Select a Schemed",
      });
    }
    setTrialCalculaterInfo();
  };

  const invokeTc = () => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "trail-calculator-layout",
      close: true,
      modalParams: {
        title: false,
        applicationId,
        numTerms,
        loanAmount: proposedLoanAmount,
        interestRate: draftedScheme ?? disbursementInterestRate,
        trailCalParams,
        saveButonDisplay: true,
        schemeSubmit: onClickedSend,
        selectedScheme: selectedScheme,
      },
    });
  };

  const viewTC = () => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "view-tc",
      close: true,
      modalParams: {
        title: false,
        applicationId,
        numTerms,
        loanAmount: proposedLoanAmount,
        interestRate: draftedScheme ?? disbursementInterestRate,
        trailCalParams,
        trailCalResult,
        schemeSubmit: onClickedSend,
      },
    });
  };

  const setTrialCalculaterInfo = () => {
    (async function () {
      setIsLoading(true);
      const data = await getTrailCalData(applicationId);
      if (data) {
        if (data?.otherChargesList) {
          setOtherChargesList(data?.otherChargesList);
        }
        if (data?.trailCalParams) {
          setTrailCalParams(data?.trailCalParams);

          currentWorkflowStageData?.["trialData"].set(data)

          let disburseInterestRate =
            data?.trailCalParams?.LoanInterestRate ??
            data?.trailCalParams?.interestRate ??
            data?.trailCalParams?.applicableRate ??
            creditFileData?.formData?.creditData?.interestRate ??
            0.0;
          setDisbursementInterestRate(disburseInterestRate);
          setDisburseLoanTenor(data?.trailCalParams?.LoanTenure ?? creditFileData?.formData?.creditData?.loanTerms);
        }

        if (data?.trailCalResult) {
          setTrailCalResult(data?.trailCalResult);
        }
      } else {
        //TODO error handle
      }
      bindSalaryPropotion();

      setIsLoading(false);
    })();
  };

  const revokedAction = !(Access.privilege(privilegeKeys.SUMMARY_OFFER_TAB_INVOKE_TC) && Access.currentAssignee && !!selectedScheme);

  const DefaultComponent = () => {
    const salaryProportionFactor = new ProductDataProvider(creditDataState).salaryProportionFactor();

    return (
      <>
        <Grid container p={1}>
          <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
            <div className="basic-font basic-font-color-bold font-size-16 align-left">Credit Summary</div>
          </Grid>
          <Stack flex={1} borderRadius={1} bgcolor={`#7fb4de3d`} mt={1} direction={"row"} p={2} alignItems={"center"}>
            {!isLoading && selectedScheme && <Stack>
              <Typography color={grey[600]}>
                {selectedScheme ? "Scheme Code: " + selectedScheme : "Scheme Code: N/A"}
              </Typography>
            </Stack>}
            <Stack flex={1}></Stack>
            {isLoading ? <CircularProgressComponent size={30} /> : <Stack direction={"row"} spacing={1}>
              <Stack flex={1} spacing={1} direction="row" alignItems={"center"}>
                <Tooltip
                  title={
                    <>
                      <Stack direction={"column"}>
                        <Stack>{creditSummaryHints?.selectScheme?.status}</Stack>
                        <Stack>{creditSummaryHints?.selectScheme?.populated}</Stack>
                      </Stack>
                    </>
                  }
                >
                  <ErrorOutlineIcon fontSize={"small"} color="disabled" />
                </Tooltip>

                <Stack width={"300px"}>
                  <SelectComponent
                    label={"Select Scheme"}
                    defaultPlaceholder="Select Scheme"
                    value={selectedScheme}
                    values={schemeList}
                    onChange={(e) => {
                      onChangeScheme(e.target.value);
                      setSelectedScheme(e.target.value);
                    }}
                    disabled={isLoading || disbursed || isLoggedInUserNotTheAssignee}
                  />
                </Stack>
                <ButtonComponent
                  iconbtn
                  children={<RefreshIcon className="basic-font-color" />}
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                  disabled={isLoading || isLoggedInUserNotTheAssignee}
                />
                {AppConfig.config.displayScheduleDisbursement == 0 ? (
                  <Stack width={"300px"}>
                    <TextBoxComponent
                      value={noOfDisbursements}
                      label={"Number of Disbursement"}
                      required
                      onChange={(e) => {
                        setNoOfDisbursements(e.target.value);
                        schedulingNoOfRows(e.target.value);
                      }}
                      type="number"
                      InputProps={{ inputProps: { min: minimumNoOfDisbursements(), max: 10 } }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                        keyEntered({ event: e });
                      }}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>}
          </Stack>
          <Grid container p={1} className="data-box">
            <Grid container p={1}>
              <Grid container item p={1}>
                <Grid item xs={3}>
                  <Grid item>
                    <Stack>
                      <Typography>{""}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Stack alignItems={"flex-end"}>
                      <Typography fontWeight={300} color={grey[600]}>
                        {"Client Requested"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Stack alignItems={"flex-end"}>
                      <Typography fontWeight={300} color={grey[600]}>
                        {"System Recommended"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"flex-end"}>
                    <Typography fontWeight={"bold"} color={grey[600]}>
                      {"TC Disbursement"}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <SummaryItemRow
              title={"Loan Amount"}
              fontColor={currentLoanAmount === proposedLoanAmount ? "" : "red"}
              proposed={currentLoanAmount ? `LKR ${formatCurrency(currentLoanAmount, 2)}` : "-"}
              recommended={proposedLoanAmount ? `LKR ${formatCurrency(proposedLoanAmount, 2)}` : "-"}
              disbursement={disbursementLoanAmount ? `LKR ${formatCurrency(disbursementLoanAmount, 2)}` : "-"}
              reqTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.reqLoanAmount?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.reqLoanAmount?.populated ?? ""}</div>
                </>
              }
              recommendedTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.recommendedLoanAmount?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.recommendedLoanAmount?.populated ?? ""}</div>
                </>
              }
              disbursementTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.disbursementLoanAmount?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.disbursementLoanAmount?.populated ?? ""}</div>
                </>
              }
            />
            <SummaryItemRow
              title={"Interest Rate	"}
              fontColor={proposedInterestRate == recommendInterestRate ? "" : "red"}
              proposed={proposedInterestRate ? `${proposedInterestRate} %` : "-"}
              recommended={recommendInterestRate ? `${recommendInterestRate} %` : "-"}
              disbursement={disbursementInterestRate ? `${disbursementInterestRate} %` : "-"}
              reqTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.reqInterestRate?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.reqInterestRate?.populated ?? ""}</div>
                </>
              }
              recommendedTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.recommendedInterestRate?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.recommendedInterestRate?.populated ?? ""}</div>
                </>
              }
              disbursementTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.disbursementInterestRate?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.disbursementInterestRate?.populated ?? ""}</div>
                </>
              }
            />
            <SummaryItemRow
              title={"Loan Tenor"}
              fontColor={requestedLoanTenor === disburseLoanTenor ? "" : "red"}
              proposed={formatCurrency(requestedLoanTenor)}
              recommended={""}
              disbursement={formatCurrency(disburseLoanTenor)}
              reqTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.reqLoanTenor?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.reqLoanTenor?.populated ?? ""}</div>
                </>
              }
              recommendedTooltipHint={undefined}
              disbursementTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.disbursementLoanTenor?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.disbursementLoanTenor?.populated ?? ""}</div>
                </>
              }
            />
            {salaryProportionFactor !== 0 && <SummaryItemRow
              title={`Salary Propotion	(${(
                salaryProportionFactor * 100
              ).toString()}%)`}
              fontColor={""}
              proposed={formatCurrency(salaryPropotion)}
              recommended={""}
              disbursement={""}
              reqTooltipHint={
                <>
                  <div>Status - {creditSummaryHints?.reqSalaryProportion?.status ?? ""}</div>
                  <div>Populated From - {creditSummaryHints?.reqSalaryProportion?.populated ?? ""}</div>
                </>
              }
              recommendedTooltipHint={undefined}
              disbursementTooltipHint={undefined}
            />}
          </Grid>
        </Grid>
        <Stack direction={"row-reverse"} py={2} gap={2} alignItems={'center'}>
          <ButtonComponent
            startIcon={<CalculateIcon />}
            title={"View TC"}
            variant="contained"
            onClick={() => {
              viewTC();
            }}
            style={{
              maxHeight: "40px",
              marginTop: "auto",
              marginRight: "5px",
            }}
            disabled={false}
            loadingbtn={true}
            loading={isLoading}
          />
          {isLoading ? <CircularProgressComponent size={24} /> : <ButtonComponent
            startIcon={<CalculateIcon />}
            title={"Invoke TC"}
            variant="contained"
            onClick={() => {
              invokeTc();
            }}
            style={{ maxHeight: "40px", marginTop: "auto" }}
            disabled={!selectedScheme || revokedAction}
            loadingbtn={true}
            loading={false}
          />}
        </Stack>
      </>
    );
  };

  const BuildDetailsSection = ({ children }) => {
    return (
      <Grid className={"data-box"} container justifyContent="space-between" alignItems="baseline">
        {children}
      </Grid>
    );
  };

  const keyEntered = ({ event }) => {
    if (event.key) {
      setNoOfDisbursements(noOfDisbursements);
      keyPressDisabled = true;
    }
    return keyPressDisabled;
  };

  const saveCoreBankData = async () => {
    let incomplete = false;
    let loanAmount = 0.0;

    for (const [key, value] of Object.entries(disbursementPreviousData)) {
      const data: any = value;
      if (!data?.disbursementAmount) {
        incomplete = true;
        break;
      }
      loanAmount = loanAmount + parseFloat(data.disbursementAmount.toString());
    }

    if (incomplete) {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Please enter disbursement amount",
      });
      return;
    }

    if (disbursementLoanAmount > loanAmount) {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Sum of disbursement amounts should be equall to disbursement loan amount",
      });
      return;
    }

    const response = await setCoreBankData(applicationId, CORE_BANK_KEYS.DISB_SCHEDULE, disbursementPreviousData);
    if (response?.message.trim() == "Updated" && response?.message.trim() == "Updated") {
      addToaster({
        status: "success",
        title: "Success",
        message: response?.message,
      });
    }
  };

  const CreditSummary = () => {
    controller = new Controller(creditFileData, masterDataDetails, trailCalResult, trailCalParams, applicationId);

    return (
      <>
        {AppConfig.config.displayScheduleDisbursement == 0 ? (
          <ScheduleDisbursements
            disbursementLoanAmount={disbursementLoanAmount}
            applicationId={applicationId}
            disbursementPreviousData={disbursementPreviousData}
            saveCoreBankData={saveCoreBankData}
          ></ScheduleDisbursements>
        ) : (
          <></>
        )}
        <Grid px={1} pt={"15px"}>
          <BuildHeader label={"Loan Summary"} />
        </Grid>
        <Grid container spacing={1} my={2} px={2} justifyContent="start" alignItems="baseline">
          <BuildDetailsSection>
            {controller.loanSummary.map((obj, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <BuildRow data={obj} />
                </Grid>
              );
            })}
          </BuildDetailsSection>
          <Grid className={"data-box"} container justifyContent="space-between" alignItems="baseline">
            {controller.userSummary.map((obj, index) => {
              return (
                <Grid item xs={4} md={4} key={index}>
                  <BuildRow data={obj} />
                </Grid>
              );
            })}
          </Grid>
          <Grid className={"data-box"} container justifyContent="space-between" alignItems="baseline">
            {controller.creditSummary.map((obj, index) => {
              return (
                <Grid item xs={4} md={4} key={index}>
                  <BuildRow data={obj} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  };

  const getCribRecords = async () => {
    let individualId = creditFileData?.formData?.personalData?.individualId;
    try {
      const data = await getCribRecordList(applicationId);
      if (data) {
        let entryId = data.participantEntryMap[individualId];
        let cribAmount = 0;
        if (data.resultCribList[entryId].length > 0) {
          cribAmount = data.resultCribList[entryId][0].cribDeductions;
          setcribDeduction(cribAmount);
        } else {
          setcribDeduction(0);
        }
      } else {
        setcribDeduction(0);
      }
    } catch (error) {
      setcribDeduction(0);
    }
  };

  const LoanSummary = ({ masterData, cribDeduction }) => {
    const loanSummary = {
      incomePayload: creditFileData?.formData?.incomeData,
      expensePayload: creditFileData?.formData?.expenseData,
      lifeInsuranceData: creditFileData?.formData?.lifeInsuranceData || {},
      liabilityData: creditFileData?.formData?.inquiryOfObligationsData || {},
      jbdata: creditFileData?.formData?.jointBorrowerData || {},
      cribDeduction: cribDeduction,
      salaryProportionFactor: new ProductDataProvider(creditDataState).salaryProportionFactor(),
    };
    const creditData = creditFileData?.formData?.creditData;

    return (
      <Summary
        data={loanSummary}
        credit={creditData}
        masterData={masterData}
        productMasterData={productMasterData}
      ></Summary>
    );
  };

  return (
    <Grid container className="inner-component-full-height">
      <div className="full-height">
        <Stack color={"gray"}>
          <DefaultComponent />
          <CreditSummary />
          {isProductOf({}) &&
            <Stack py={2}>
              <MicroAppraisalSummaryComponents applicationId={applicationId} />
            </Stack>
          }
          <LoanSummary masterData={masterDataStore} cribDeduction={cribDeduction} />
        </Stack>
      </div>
    </Grid>
  );
};

export default SummaryLayout;

const MicroAppraisalSummaryComponents = ({ applicationId, }) => {
  const { t: translate } = useTranslation();
  const componentStatus = useHookstate({ isLoading: true, errorStatus: false });
  const componentdata = useHookstate({ profitLossAccountsDataType: "generalized", generalized: {} });
  const { microAppraisalServices } = microCommercialAppraisal;
  const abilityToRepayLoanDataState = useHookstate<any>(abilityToRepayLoanData);
  const comprehensiveProfitAndLoanAccounts = useHookstate(comprehensiveProfitAndLoanAccountsData);
  const generalizedProfitAndLoanAccounts = useHookstate(generalizedProfitAndLoanAccountsData);
  const expanded = useHookstate<Record<string, boolean>>({ ratioAnalysis: true, profitAndLossAccounts: false, balanceSheet: true, abilityToRepayTheProposedLoan: true });

  useEffect(() => {
    getMicroAppraisalSummaryData();

    return () => {
      abilityToRepayLoanDataState.set(JSON.parse(JSON.stringify(abilityToRepayLoanDefaultData)));
      comprehensiveProfitAndLoanAccounts.set(JSON.parse(JSON.stringify(defaultComprehensiveProfitAndLoanAccounts)));
      generalizedProfitAndLoanAccounts.set(getCopy(defaultGeneralizedProfitAndLoanAccounts))
    }
  }, [])

  const getMicroAppraisalSummaryData = async () => {

    try {
      const { getRatioAnalysis, getProfitLossAccountsData, getBalanceSheet } = microAppraisalServices;

      const [ratioData, profitLossAccountsData, balanceSheetData] = await Promise.all([getRatioAnalysis(applicationId), getProfitLossAccountsData(applicationId), getBalanceSheet(applicationId)]);

      if (Object.keys(ratioData).length > 0) {
        componentdata["ratioData"].set(ratioData);
      }

      if (Object.keys(profitLossAccountsData).length > 0) {

        if (profitLossAccountsData.type === 'generalized') {
          const toBeSubmittedData = { data: profitLossAccountsData.data, ...profitLossAccountsData }

          generalizedProfitAndLoanAccounts.set(getCopy({ ...toBeSubmittedData }))
        }

        if (profitLossAccountsData.type === 'comprehensive') {
          comprehensiveProfitAndLoanAccounts.set(getCopy({ ...profitLossAccountsData }))
        }

        componentdata.profitLossAccountsDataType.set(profitLossAccountsData.type);

        abilityToRepayLoanDataState.set({
          selectedYears: profitLossAccountsData.selectedYears,
          data: profitLossAccountsData.totals
        })
      }

      if (balanceSheetData.status === 1 && Object.keys(balanceSheetData.data).length > 0) {
        componentdata["balanceSheetData"].set(balanceSheetData.data);
      }

    } catch (error) {
      componentStatus.errorStatus.set(true);

    } finally {
      componentStatus.isLoading.set(false);
    }
  }

  if (componentStatus.isLoading.get()) return <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />

  if (componentStatus.errorStatus.get()) return <ErrorMessageComponent headMessage={translate("errorMsg.noData")} errorMessage={""} />

  const BuildBalanceSheetComponent = React.memo(() => {
    const balanceSheetDataFormData = componentdata["balanceSheetData"].attach(Downgraded).get();
    let getAllCalculations = balanceSheetDataFormData.totals;

    return <Stack spacing={3}>
      <SummaryHeaderExpander label={"Balance Sheet"} key={"balanceSheet"} currentKey="balanceSheet" expandedKey={expanded} />
      {expanded.balanceSheet.get() ? <SummaryTableBuilder
        headerArray={balanceSheetDataFormData?.selectedYears}
        getAllCalculations={getAllCalculations}
        descriptionList={balanceSheetData}
        label={"Description"}
        appraisalFormData={balanceSheetDataFormData["data"]} /> : <></>}
    </Stack>
  })

  const BuildAbilityToRepayTheProposedLoan = React.memo(() => {
    const creditFileData = creditData.attach(Downgraded).get();

    return <Stack spacing={3}>
      <SummaryHeaderExpander label={"Ability To Repay The Proposed Loan"} key={"abilityToRepayTheProposedLoan"} currentKey="abilityToRepayTheProposedLoan" expandedKey={expanded} />
      {expanded.abilityToRepayTheProposedLoan.get() ? <AbilityToPayLoanAndProposedLoanAmountSummaryLayout creditFileData={creditFileData} /> : <></>}
    </Stack>
  })

  const BuildRatioAnalysisComponent = React.memo(() => {
    const ratioDatasFormData = componentdata["ratioData"].attach(Downgraded).get()

    return <RatioAnalysisContext.Provider value={ratioDatasFormData} >
      <RatioAnalysisVersionTwo />
    </RatioAnalysisContext.Provider>
  })

  const BuildItem = () => {
    return (
      <>
        {componentdata.profitLossAccountsDataType.get() ? <ProfitAndLossSummary typeOfPL={componentdata.profitLossAccountsDataType.get()} doubleTypeProfitLossAccountsData={{ generalized: generalizedProfitAndLoanAccounts, comprehensive: comprehensiveProfitAndLoanAccounts }} expanded={expanded} /> : <></>}
        {componentdata["balanceSheetData"].get() && <BuildBalanceSheetComponent />}
        <BuildAbilityToRepayTheProposedLoan />
        {componentdata["ratioData"].get() && <>
          <SummaryHeaderExpander label={"Ratio Analysis"} key={"ratioAnalysis"} currentKey="ratioAnalysis" expandedKey={expanded} />
          {expanded.ratioAnalysis.get() ? <BuildRatioAnalysisComponent /> : <></>}
        </>}
      </>
    )
  }

  return <Stack spacing={3} px={1}>
    <BuildItem />
  </Stack>
}


const ProfitAndLossSummary = ({ typeOfPL, doubleTypeProfitLossAccountsData, expanded }) => {
  const viewModeType = useHookstate<string | undefined>(viewMode);

  useEffect(() => {
    viewModeType.set("SUMMARY");

    return () => {
      viewModeType.set(undefined);
    }
  }, [typeOfPL])

  const componentMapping = {
    generalized: Generalized,
    comprehensive: ComprehensiveContainer,
  };

  return <Stack spacing={3}>
    <SummaryHeaderExpander label={"Profit and Loss Accounts"} key={"profitAndLossAccounts"} currentKey="profitAndLossAccounts" expandedKey={expanded} />
    <Box paddingX={2} children={React.createElement(componentMapping[typeOfPL], { data: doubleTypeProfitLossAccountsData[typeOfPL].get(), typeOfViewMode: "SUMMARY" })} />
  </Stack>
}
