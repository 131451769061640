import { Stack } from "@mui/material";
import React, { lazy, useEffect } from "react";
import AutoCompleteProxyComponent from "../../../components/InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../components/InputComponents/SelectComponent/SelectComponent";
import {
  applicationSections,
  participantTypes,
} from "../../../configs/constants/contants";
import { select_basic } from "../../../styles";
import { Downgraded, useState } from "@hookstate/core";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { productDetails } from "../../../configs/mainStore";
import { applyChecklist } from "../../../services/apiCalls";
import { toast } from "../../../components/OtherComponents/GlobalToast";

// checklist-configuration-modal

const ChecklistConfigurationModal = (props) => {

  const configurations = {
    productCatalogue: undefined,
    customerType: undefined,
    participantType: undefined,
    checklist: {},
  };

  const productMasterData: any = useState(productDetails);
  const configs: any = useState(configurations);
  const productName = useState([]);
  let products = useState([]);
  let applicationSection = useState([]);

  useEffect(() => {
    (async () => {
      applicationSection.set(applicationSections);

      fetchProducts();
      configs.checklist.set([]);
    })();
  }, []);

  const fetchProducts = () => {
    let _products: any = [];
    const productCatalogData =
      productMasterData?.productCatalogData?.allProductCatalogsWithKeys?.get() ??
      [];
    for (const key of Object.keys(productCatalogData)) {
      const product = productCatalogData[key];
      const type =
        productMasterData?.productTypesMap[
          product.PRODUCT_TYPE
        ]?.TYPE_NAME?.get();
      const sector =
        productMasterData?.productSectorsMap[
          product.PRODUCT_SECTOR
        ]?.TYPE_NAME?.get();
      const scheme =
        productMasterData?.productSchemesMap[
          product.PRODUCT_SCHEME
        ]?.TYPE_NAME?.get();
      let _object = {
        id: key,
        name: `${type} ${sector} ${scheme}`,
      };
      _products.push(_object);
    }
    products.set(_products);
  };

  let { productCatalogue }: any = configs.attach(Downgraded).get();

  const applyProductDetails = async () => {
    const alert = {
      status: "success",
      message: "checklists cloned successfully!",
    };
    try {
      await applyChecklist(
        props.checklistArr,
        props.productCatalogue,
        productCatalogue
      );
      closeGlobalModal();
    } catch (error) {
      alert.status = "error";
      alert.message = "Failed to clone checklists.";
    } finally {
      toast(alert);
    }
  };

  return (
    <Stack
      p={2}
      direction={"column"}
      justifyContent={"stretch"}
      flex={1}
      spacing={6}
      className={"data-box"}
    >
      <AutoCompleteProxyComponent
        options={products.get()}
        fullWidth={true}
        onChange={(e) => {
          configs.productCatalogue.set(e ? e.id : null);
          productName.set(e.name);
        }}
        label={"Product Catalogue"}
        value={configs.productCatalogue.get()}
        cls="select_basic"
        required
      />
      <AutoCompleteProxyComponent
        sx={select_basic}
        label="Customer Type: "
        value={configs.customerType.get()}
        options={[
          { id: 1, name: "Individual" },
          { id: 2, name: "Corporate" },
        ]}
        onChange={(e) => {
          configs.customerType.set(e ? e.id : null);
        }}
      />
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent={"flex-end"}
      ></Stack>
      <Stack direction={"row-reverse"} spacing={2}>
        <ButtonComponent
          variant={"contained"}
          onClick={closeGlobalModal}
          title={"Cancel"}
        />
        <ButtonComponent
          size={"small"}
          variant={"outlined"}
          onClick={applyProductDetails}
          title={"Apply"}
        />
      </Stack>
    </Stack>
  );
};

export default ChecklistConfigurationModal;
