import { Grid, Typography } from "@mui/material";
import { OnDemandValueFormatter } from "../../../../../../services/onDemandValidators";
import DetailItem from "../../DetailItem";
import { securityCategory } from "../../../../../../configs/constants/contants";

export const duration = (obj, yearKey, monthKey) => {
  let year = "";
  let month = "";

  obj[yearKey] = obj[yearKey] || 0;
  obj[monthKey] = obj[monthKey] || 0;

  if (obj[yearKey] instanceof String) {
    obj[yearKey] = parseInt(obj[yearKey]);
  }
  if (obj[monthKey] instanceof String) {
    obj[monthKey] = parseInt(obj[monthKey]);
  }

  if (obj[yearKey] > 0) {
    year = `${obj[yearKey] > 10 ? obj[yearKey] : "0" + obj[yearKey]} Year${obj[yearKey] > 1 ? "s" : ""}`;
  }

  if (obj[monthKey] > 0) {
    month = `${obj[monthKey] > 10 ? obj[monthKey] : "0" + obj[monthKey]} Month${obj[monthKey] > 1 ? "s" : ""}`;
  }

  return `${year} ${month}`;
};

export const renderingKeys = {
  pawning: ["bank", "matureDate", "originalAmount", "outstanding", "annualInterest"],
  overDraft: ["securityCategory", "bank", "limitOfOverdraft", "expiryDate", "outstanding"],
  loanLease: ["amount", "bank", "currentOutstanding", "installmentAmount", "installmentFrequency", "installmentLeft", "liabilityType", "grantedDate", "moreInfoOnRepaymentLoan", "obligationType", "security", "securityType", "statusOfLoan", "valuation"],
  creditCards: ["bank", "typeOfCard", "currentOutstanding", "limit"],
};

export const invokeValueOverriding = (field, masterData, payload) => {
  let attr: string = "";
  const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);
  const typedData2 = (masterType, key) => masterType?.find((t) => t.id === key);

  if (field === "obligationType") {
    attr = typedData("OBLIGATION_TYPE", payload.obligationType)?.name;
  } else if (field === "liabilityType") {
    attr = typedData("LIABILITY_TYPE", payload.liabilityType)?.name;
  } else if (field === "security") {
    attr = typedData2(securityCategory, payload.security)?.name;
  } else if (field === "statusOfLoan") {
    attr = typedData("STATUS_OF_LOAN", payload.statusOfLoan)?.name;
  } else if (field === "installmentFrequency") {
    attr = typedData("LOAN_FREQUENCY", payload.installmentFrequency)?.name;
  } else if (field === "securityCategory") {
    attr = typedData2(securityCategory, payload.securityCategory)?.name;
  } else if (field === "originalAmount") {
    attr = amountsThousandSeperator("originalAmount");
  } else if (field === "outstanding") {
    attr = amountsThousandSeperator("outstanding");
  } else if (field === "limitOfOverdraft") {
    attr = amountsThousandSeperator("limitOfOverdraft");
  } else if (field === "amount") {
    attr = amountsThousandSeperator("amount");
  } else if (field === "currentOutstanding") {
    attr = amountsThousandSeperator("currentOutstanding");
  } else if (field === "installmentAmount") {
    attr = amountsThousandSeperator("installmentAmount");
  } else if (field === "valuation") {
    attr = amountsThousandSeperator("valuation");
  } else if (field === "limit") {
    attr = amountsThousandSeperator("limit");
  }
  return attr;

  function amountsThousandSeperator(field): string {
    return OnDemandValueFormatter.thousandSeparator(!isNaN(parseFloat(payload[field])) ? parseFloat(payload[field]) : "");
  }
};

const labelsMap = {
  bank: "Bank",
  matureDate: "Mature Date",
  originalAmount: "Original Amount",
  outstanding: "Outstanding",
  annualInterest: "Annual Interest",
  limitOfOverdraft: "Limit of Overdraft",
  amount: "Amount",
  currentOutstanding: "Current Outstanding",
  installmentAmount: "Installment Amount",
  installmentFrequency: "Installment Frequency",
  installmentLeft: "Installment Left",
  liabilityType: "Liability Type",
  moreInfoOnRepaymentLoan: "More Info on Repayment Loan",
  obligationType: "Obligation Type",
  security: "Security Category",
  securityType: "Security Type",
  statusOfLoan: "Status of Loan",
  valuation: "Valuation",
  typeOfCard: "Card Type",
  limit: "Limit",
  securityCategory: "Security Category",
  grantedDate: "Granted Date",
  expiryDate: "Expiry Date",
};

export const addItem = (object, key, val = "", obligationType = "", isDateTypeField = false) => {
  if (obligationType != null && obligationType != "") {
    if (obligationType == "pawning") {
      if (labelsMap?.[key] == "annualInterest" || key == "annualInterest") {
        return (
          <Item key={key} label={"Annual Interest Rate"} value={val || object?.[key] || "N/A"}>
            % p.a
          </Item>
        );
      } else {
        return <Item key={key} label={isDateTypeField ? `${labelsMap?.[key] || key} (DD/MM/YYYY)` : labelsMap?.[key] || key} value={val || object?.[key] || "N/A"} />;
      }
    }
  } else {
    return <Item key={key} label={isDateTypeField ? `${labelsMap?.[key] || key} (DD/MM/YYYY)` : labelsMap?.[key] || key} value={val || object?.[key] || "N/A"} />;
  }
};

export const typedData = (masterData, masterType, key) => masterData[masterType]?.get()?.find((t) => t?.id === key);

const Item = ({ label, value, children = "" }) => (
  <Grid item xs={3}>
    {children != null && children != "" ? <DetailItem title={label} value={value} children={children} valueFontClass="font-size-14" titleFontClass="font-size-12" /> : <DetailItem title={label} value={value} valueFontClass="font-size-14" titleFontClass="font-size-12" />}
  </Grid>
);

export const SectionTitle = (props) => {
  return (
    <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
      {props.children}
    </Grid>
  );
};

export const AnnualAmount = (props) => {
  return (
    <Grid container p={1} direction={"row-reverse"} alignItems="baseline" className="border-radius-5 basic-font basic-font-color-bold font-size-16 data-box">
      <Typography variant={"subtitle2"}>Annual Amount: {props.children} </Typography>
    </Grid>
  );
};

export class AmountCalculator {
  payload: any = {};

  calculate = (type, data) => {
    this.payload = data;
    let totalAmount = 0.0;

    if (type === "creditcard") {
      totalAmount = this.calculateCreditCard;
    } else if (type === "loanlease") {
      totalAmount = this.calculateLoanLease;
    } else if (type === "overdraft") {
      totalAmount = this.calculateOverdraft;
    } else {
      totalAmount = this.calculatePawning;
    }

    return OnDemandValueFormatter.thousandSeparator(!isNaN(parseInt(totalAmount.toFixed(2))) ? totalAmount.toFixed(2) : "N/A");
  };

  get calculateLoanLease() {
    //     " ""Number of installments left"" = Y
    // If Y  < 12;
    //      Annual Amount = Y x ""Installment Amount""
    // If Y > 12;
    //      Annual Amount = 12 x  ""Installment Amount"""
    let totalAmount = 0.0;

    let installmentLeft = this.payload.installmentLeft;
    if (this.payload.installmentLeft instanceof String) {
      installmentLeft = parseFloat(this.payload.installmentLeft);
    }

    let installmentAmount = this.payload.installmentAmount;
    if (this.payload.installmentAmount instanceof String) {
      installmentAmount = parseFloat(this.payload.installmentAmount);
    }

    totalAmount = (installmentLeft < 12 ? installmentLeft : 12) * installmentAmount;

    return totalAmount;
  }

  get calculateCreditCard() {
    // Annual Amount = 5% x  "Limit"
    let totalAmount = 0.0;
    let limit = this.payload.limit;

    if (this.payload.limit instanceof String) {
      totalAmount = parseFloat(this.payload.limit);
    }

    totalAmount = limit * 0.05;

    return totalAmount;
  }

  // get calculatePawningOld() {
  //   // Annual Amount = "Amount Outstanding" +  "Annual Interest" 5%
  //   let totalAmount = 0.0;
  //   let currentOutstanding = 0.0;
  //   let limit = 0.0;

  //   if (this.payload.currentOutstanding instanceof String) {
  //     currentOutstanding = parseFloat(this.payload.currentOutstanding);
  //   } else {
  //     currentOutstanding = this.payload.currentOutstanding;
  //   }

  //   if (this.payload.limit instanceof String) {
  //     limit = parseFloat(this.payload.limit);
  //   } else {
  //     limit = this.payload.limit;
  //   }

  //   totalAmount = currentOutstanding + limit;

  //   return totalAmount;
  // }

  get calculatePawning() {
    // Annual Amount = "Amount Outstanding" +  "Annual Interest" 5%
    let totalAmount = 0.0;
    let currentOutstanding = 0.0;
    let annualInterest = 0.0;
    if (this.payload.outstanding !== "") {
      if (this.payload.currentOutstanding instanceof String) {
        currentOutstanding = parseFloat(this.payload.outstanding);
      } else {
        currentOutstanding = parseFloat(this.payload.outstanding);
      }
    }

    if (this.payload.annualInterest !== "") {
      if (this.payload.annualInterest instanceof String) {
        annualInterest = parseFloat(this.payload.annualInterest);
      } else {
        annualInterest = parseFloat(this.payload.annualInterest);
      }
    }
    totalAmount = currentOutstanding + (currentOutstanding / 100) * annualInterest;
    return totalAmount;
  }

  get calculateOverdraft() {
    // Annual Amount = "Outstanding"
    let totalAmount = parseFloat(this.payload?.outstanding?.toString() ?? "0.0");

    return totalAmount;
  }
}
