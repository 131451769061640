import { Box, Tabs, Tab } from "@mui/material";
import React, { FC } from "react";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

interface ScheduleDisbursementsPropTypes {
  textColor: any,
  indicatorColor: any,
  variant: any,
  labelStyle: any,
  tabs: any,
  setSelectedTabLabel: any,
  disbursementScheduleData: any,
  setOnClick: any,
}

const DisbursementVerticalTabComponent: FC<ScheduleDisbursementsPropTypes> = ({
  textColor,
  variant,
  indicatorColor,
  tabs,
  setSelectedTabLabel,
  labelStyle,
  disbursementScheduleData,
  setOnClick,
}) => {
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);

  const filteredTabs = tabs.filter((label, index) => {
    const key = index + 1;
    const item = disbursementScheduleData[key];

    if (item.status === 'Complete' && key !== 1) {
      return true;
    } else if (item.status === 'Pending' && key !== 1) {
      return true;
    }

    return false;
  });

  const handleOnClick = (label: string, index: number) => {
    setSelectedTabIndex(index);
    setOnClick(true);
    setSelectedTabLabel(label);
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            top: "50%",
            right: "20%",
            position: "absolute",
            zIndex: 100,
          }}
        />
      ) : (
        <>
          <Tabs
            orientation="vertical"
            value={selectedTabIndex === -1 ? false : selectedTabIndex}
            textColor={textColor}
            indicatorColor={indicatorColor}
            scrollButtons="auto"
            variant={variant}
          >
            {filteredTabs.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={labelStyle}
                onClick={() => handleOnClick(label, index)}
              />
            ))}
          </Tabs>
        </>
      )}
    </Box>
  );
}

export default DisbursementVerticalTabComponent;