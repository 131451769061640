import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MASTER_DATA_KEYS } from '../../../../../../configs/constants/masterDataKeys';
import { langDictionary } from '../../../../../../configs/languageDictionaryEn';
import { branchDetails } from '../../../../../../configs/mainStore';
import DetailItem from '../../DetailItem';

const BuildApplicantCloseToRdb = ({ personalData, masterDataStore }) => {
    const { t: translate } = useTranslation();

    if (personalData?.isApplicantCloseToRdb !== 1) {
        return <></>;
    }

    if (!personalData?.detailsOfApplicantCloseToRdb) {
        return <></>;
    }

    if (personalData?.detailsOfApplicantCloseToRdb.length === 0) {
        return <></>;
    }

    return <Stack my={2}>
        <Typography color={"gray"} variant='subtitle2'> Details Of The Relation </Typography>
        <Divider></Divider>
        <Stack direction={"column"} flex={1} mt={1}>
            {personalData?.detailsOfApplicantCloseToRdb.map((mainElementObj, index) => {
                return <Stack direction={"row"} flex={1} justifyContent={"space-between"} spacing={1} key={index}>
                    <Stack flex={1}>
                        <DetailItem
                            title={translate(langDictionary?.name)}
                            value={mainElementObj?.rdRName ? mainElementObj?.rdRName : "-"}
                            valueFontClass="font-size-14"
                            titleFontClass="font-size-12"
                        />
                    </Stack>
                    <Stack flex={1}>
                        <DetailItem
                            title={translate(langDictionary?.relationship)}
                            value={
                                mainElementObj?.rdRRelationShip
                                    ? masterDataStore.getMasterDataDescription(
                                        MASTER_DATA_KEYS.RELATION_WITH_MA,
                                        mainElementObj?.rdRRelationShip
                                    )
                                    : "-"
                            }
                            valueFontClass="font-size-14"
                            titleFontClass="font-size-12"
                        />
                    </Stack>
                    <Stack flex={1}>
                        <DetailItem
                            title={translate(langDictionary?.employmentNo)}
                            value={mainElementObj?.rdREmployeeNo ? mainElementObj?.rdREmployeeNo : "-"}
                            valueFontClass="font-size-14"
                            titleFontClass="font-size-12"
                        />
                    </Stack>
                    <Stack flex={1}>
                        <DetailItem
                            title={translate(langDictionary?.branch)}
                            value={
                                mainElementObj?.rdRBranch?.toString() === "-100" ? "Head Office" :
                                    mainElementObj?.rdRBranch ? branchDetails.branchMap[mainElementObj?.rdRBranch].get().name : "-"
                            }
                            valueFontClass="font-size-14"
                            titleFontClass="font-size-12"
                        />
                    </Stack>
                </Stack>;
            })}
        </Stack>
    </Stack>;
}

export default BuildApplicantCloseToRdb
