import moment from "moment";
import { generateUniqueId } from "../../../../../../../../../utility/util";
import { typeOfStock } from "../../../../../CreditInspection/FormBuilder/Tools/Helpers/inspectionConstants";

const operationCostsMap = {
    1: "0",
    2: "(_totalExpenses / 365 ) * _numberOfDays * 0.5",
    3: "(_totalExpenses / 365 ) * _numberOfDays"
}


const workingCapitalRequirementPreInitiation = ({ busineeData, profitAndLossTotals }: any) => {
    const { stock = undefined, debtors = undefined, creditors = undefined, } = busineeData;
    const selectedYears = ["description", "numberOfDays", "rawMaterial", "operatingCost", "total"];

    if (!stock || !debtors || !creditors) return { status: -1, data: {}, selectedYears };

    try {
        if (Array.isArray(stock) && Array.isArray(debtors) && Array.isArray(creditors)) {
            const stockNode = createStocksNode(stock, profitAndLossTotals.netExpenses[moment().year()]);
            const debtorsNode = createDebtorsNode(debtors);
            const creditorsNode = createCreditorNode(creditors);
            const grossWorkingCapital = createGrossWorkingCapital(stockNode, debtorsNode);
            const netWorkingCapitalNode = netWorkingCapital(grossWorkingCapital, creditorsNode);

            return {
                status: 1,
                selectedYears,
                data: {
                    [moment().year()]: {
                        rowId: generateUniqueId(),
                        columns: [...stockNode.row, ...debtorsNode.row, ...grossWorkingCapital.row, ...creditorsNode.row, ...netWorkingCapitalNode.row]
                    }
                }
            };
        };

        const yearArray = Object.keys(stock || debtors || creditors);
        const data = {};

        yearArray.forEach((year) => {
            const stockNode = createStocksNode(stock[year], profitAndLossTotals.netExpenses[year]);
            const debtorsNode = createDebtorsNode(debtors[year]);
            const creditorsNode = createCreditorNode(creditors[year]);
            const grossWorkingCapital = createGrossWorkingCapital(stockNode, debtorsNode);
            const netWorkingCapitalNode = netWorkingCapital(grossWorkingCapital, creditorsNode);

            data[year] = {
                rowId: generateUniqueId(),
                columns: [...stockNode.row, ...debtorsNode.row, ...grossWorkingCapital.row, ...creditorsNode.row, ...netWorkingCapitalNode.row]
            };
        });

        return { status: 1, data, selectedYears };
    } catch (error) {
        console.error(error);
        return { status: -1, data: {}, selectedYears };
    }
};

export default workingCapitalRequirementPreInitiation;

const createStocksNode = (data: any[], netExpenses) => {
    const _totalExpenses = Number(netExpenses || 0);
    let totalRawMaterial = 0;
    let totalOperatingCost = 0;
    let totalofTotals = 0;

    const row = data
        .sort((firstEle, secondEle) => Number(firstEle.description) - Number(secondEle.description))
        .reduce((acc, stockType) => {

            if (!stockType.type) return acc;

            const description = typeOfStock.find((stock) => stock.id === stockType.type)?.name || "Unknown";
            const operationCostCalc = operationCostsMap[stockType.type] !== "0" ?
                operationCostsMap[stockType.type]
                    .replace("_totalExpenses", _totalExpenses)
                    .replace("_numberOfDays", stockType.numberOfDays) : "0";

            const rawMaterial = Number(stockType.amount);
            const operatingCost = Number(eval(operationCostCalc));
            const total = rawMaterial + operatingCost;

            totalRawMaterial += rawMaterial;
            totalOperatingCost += operatingCost;
            totalofTotals += total;

            acc.push({
                dataId: `${stockType.type}-${stockType.dataId}-${description}`,
                description,
                numberOfDays: stockType.numberOfDays,
                rawMaterial,
                operatingCost,
                total,
            });

            return acc;
        }, []);

    return { row, totals: { totalRawMaterial, totalOperatingCost, totalofTotals } };
};

const createDebtorsNode = (data: any) => {
    const rowMap = new Map<string, any>();
    let totalofTotals = 0;

    data.forEach((debtorType) => {
        const description = `Debtors - ${debtorType.description}`;
        const dataId = `${debtorType.description}-${debtorType.dataId}-${description}`;
        const total = Number(debtorType.amount);
        totalofTotals += total;

        if (rowMap.has(dataId)) {
            const existingRow = rowMap.get(dataId);
            existingRow.total += total;
            existingRow.numberOfDays += debtorType.numberOfDays;
        } else {
            rowMap.set(dataId, {
                dataId,
                description,
                numberOfDays: debtorType.numberOfDays,
                rawMaterial: 0,
                operatingCost: 0,
                total,
            });
        }
    });

    const row = Array.from(rowMap.values());
    return {
        row,
        totals: { totalofTotals },
    };
}

const createCreditorNode = (data: any) => {
    const rowMap = new Map<string, any>();
    let totalofTotals = 0;

    data.forEach((creditorType) => {
        const description = `Creditors - ${creditorType.description}`;
        const dataId = `${creditorType.description}-${creditorType.dataId}-${description}`;
        const total = Number(creditorType.amount);
        totalofTotals += total;

        if (rowMap.has(dataId)) {
            const existingRow = rowMap.get(dataId);
            existingRow.total += total;
            existingRow.numberOfDays += creditorType.numberOfDays;
        } else {
            rowMap.set(dataId, {
                dataId,
                description,
                numberOfDays: creditorType.numberOfDays,
                rawMaterial: 0,
                operatingCost: 0,
                total,
            });
        }
    });

    const row = Array.from(rowMap.values());

    return {
        row,
        totals: { totalofTotals },
    };
}

const createGrossWorkingCapital = (stock: any, debtors: any) => {
    return {
        row: [{
            dataId: generateUniqueId(),
            isTotal: true,
            description: "Gross Working Capital Requirement",
            numberOfDays: 0,
            rawMaterial: stock.totals.totalRawMaterial,
            operatingCost: stock.totals.totalOperatingCost,
            total: eval(stock.totals.totalofTotals + debtors.totals.totalofTotals),
        }]
    }
}

const netWorkingCapital = (grossWorkingCapital: any, creditors: any) => {
    return {
        row: [{
            dataId: generateUniqueId(),
            isTotal: true,
            description: "Net Working Capital Requirement",
            numberOfDays: 0,
            rawMaterial: 0,
            operatingCost: 0,
            total: grossWorkingCapital.row[0].total - creditors.totals.totalofTotals,
        }]
    }
}