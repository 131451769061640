import DeleteIcon from '@mui/icons-material/Delete';
import {
  Stack,
  Typography
} from "@mui/material";
import React, { FC } from "react";
import {
  deleteConditionData,
} from "../../../../services/creditFileApiCall";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import {
  closeGlobalModal,
  openGlobalModal,
} from "../../GlobalModal/GlobalModal";
import { Toast } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

interface PreviewRowProps {
  Icon?: any;
  subTxt: string;
  mainTxt?: string;
  color?: any;
  designation?: string;
  secondaryTxt?: string;
  data?: any;
  applicationId?: any;
  setNewDataArray?: any;
  creditFileData?: any;
  actionDelete?: any;
  disableAccordingTo?: boolean;
}

export const IconRowAgreementConditionComponent: FC<PreviewRowProps> = ({
  Icon,
  subTxt = "",
  color,
  secondaryTxt = "",
  designation = "",
  data,
  actionDelete,
  disableAccordingTo = false,
  applicationId,
}) => {
  const [reload, setReload] = React.useState<boolean>(false);

  const confirmDelete = () => {
    openGlobalModal({
      modalSize: "sm",
      title: "Are you sure ?",
      bodyId: "app-section-delete-confirm",
      close: false,
      modalParams: {
        textMessage: `Condition '${subTxt}' will be deleted.`,
        callBackOnAction: () => deleteConfirm(data),
      },
    });
  }

  const deleteConfirm = async (object) => {
    const objectId = object?.id;

    try {
      setReload(true);
      const { status } = await deleteConditionData(objectId);

      if (status === 1) {
        Toast.success(`Condition '${subTxt}' has been removed.`);
        // createApplicationsHistory(applicationId, 'Agreement special condition deleted', null, data, "/products/conditions");
      }
      actionDelete(object);
    } catch (error) {
      Toast.error(`Failed to remove condition '${subTxt}'`);
    } finally {
      setReload(false);
      closeGlobalModal();
    }
  };

  const BuildContent = () => {
    return <Stack direction={"row"} flex={1}>
      <Stack flex={1}>
        {subTxt && <Typography variant="subtitle1">{subTxt}</Typography>}
        {secondaryTxt && <Typography variant="caption" color={"grey"}>{secondaryTxt}</Typography>}
        {designation && <Typography variant="caption" color={"grey"}>{designation}</Typography>}
      </Stack>
      <ButtonComponent
        iconbtn
        size="small"
        children={<DeleteIcon fontSize="small" />}
        onClick={() => confirmDelete()}
        disabled={disableAccordingTo}
      />
    </Stack>
  }

  return <Stack direction="row" spacing={2} alignItems={"start"} justifyContent={"flex-start"} p={2}>
    {Icon && <Stack mt={1}><Icon sx={{
      width: "16px",
      height: "16px",
      marginTop: "5px",
      color: color,
    }} /></Stack>}
    {reload ? <CircularProgressComponent size={24} /> : <BuildContent />}
  </Stack>;
};
