//###################################- DEFAULT VALUES -########################################\\

import {createState} from "@hookstate/core";

const currentCollaterlTabIdState: string = "ins-imm-property";




















//###################################- CUSTOM STATES -########################################\\
export const currentCollaterlTabId = createState(currentCollaterlTabIdState);


