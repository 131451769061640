import { adminPermission } from "./getPrivilageState";

export const getIsDisbursed = (permission: any, disbursed: any) => {
  const privilage = adminPermission(permission);

  if (disbursed === 1) {
    return true;
  } else {
    if (permission) {
      return privilage;
    } else {
      return false;
    }
  }
};
