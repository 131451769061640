import { SelectedSteps } from "./ApplicationFormManageProvider";

export const getBackToNormalStepper = (steps: Record<number, string[]>, sectionKey: string, selectedSteps: Record<number, string[]>) => {
    let stepsFiltered: any = Object.values(steps).reduce((obj: Record<number, string[]>, currItem: string[], index: number) => {

        if (currItem[0] === selectedSteps?.[index]?.[0]) {
            let item = currItem.includes(sectionKey) ? currItem : selectedSteps[index];

            return { ...obj, [index]: item }
        }
        return { ...obj, [index]: currItem }

    }, {} as SelectedSteps);

    return stepsFiltered;
}

export const updateSectionErrorState = (state, status) => {
    if (state.get() && Array.isArray(state.get())) {
        state[0].set(prev => ({ ...prev, sectionErrorStatus: status }))
    } else {
        state.set(prev => ({ ...prev, sectionErrorStatus: status }))
    }
}