import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { applicationHints } from "../../../../../../../../configs/constants/contants";
import { creditData, userDetails, workflowMasterData } from "../../../../../../../../configs/mainStore";
import {
  getAppraisalData,
  getCribRecordList,
  getTrailCalData,
  saveAppraisalData,
} from "../../../../../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import ProductDataProvider from "../../../../../../../../utility/productDataProvider";
import { addToaster } from "../../../../../../GlobalToast";
import { InfoToolTip } from "../../../../../../Info";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import ApplicationHeader from "../../../../Application/ApplicationHeader";
import DetailItem from "../../../../DetailItem";
import InnerSectionHeaderComponent from "../../../../InnerSectionHeaderComponent";
import { disableAccess } from "../../../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { RepayLoanController } from "./RepayLoanController";

const abilityToRepayTheLoan = {
  limitPercentageOfIncome: "",
  limitOnProposedIncome: "",
  currentDeductions: "",
  proposedLoanInstallments: "",
  proposedLoanInterest: "",
  DebtPaymentPercentage: "",
  gracePeriod: "",
};

const params = {
  id: "net_other_income",
  title: "Other Income",
  componentId: "NetOtherIncome",
  headerActionButton: true,
  headerActionButtonTitle: "Add Other Income",
  modalBodyKey: "net-other-income-modal",
};

const AbilityToPayLoan = ({
  data,
  applicationId,
  isMainContactDetails = true,
  mainTabData = { isAppraisal: false },
  isRawVersion,
  productSchemeId,
  innerComponentData,
  tabsToEdit,
}) => {
  const abilityToPayLoanState: any = useState(abilityToRepayTheLoan);
  const netOtherIncomeState: any = useState({ createdBy: "", incomeData: [] });
  const isLoading = useState(false);
  const isSaving = useState(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const { wf_system_users }: any = useState(workflowMasterData);

  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  const incomePayload: any = useState(data.formData.incomeData ? data.formData.incomeData : {});
  const expensePayload: any = useState(data.formData.expenseData ? data.formData.expenseData : {});
  const jbdata: any = useState(data.formData.jbdata ? data.formData.jbdata : {});
  const lifeInsuranceData: any = useState(data.formData.lifeInsuranceData ? data.formData.lifeInsuranceData : {});
  const liabilityData: any = useState(
    data.formData.inquiryOfObligationsData ? data.formData.inquiryOfObligationsData : {}
  );
  const [trailCalResult, setTrailCalResult] = React.useState([]);
  const [loanInterestRate, setLoanInterestRate] = React.useState([]);
  const [maxMonthInstallment, setMaxMonthInstallment] = React.useState("N/A");
  const [debtRepaymentPercentage, setDebtRepaymentPercentage] = React.useState("");
  const creditFileData: any = useState(creditData);
  const [fixedAllowance, setFixedAllowance] = React.useState(0.0);
  const [averageVariableAllowance, setAverageVariableAllowance] = React.useState(0.0);
  const [cribData, setCribData] = React.useState<any>(0.0);
  const [netOtherIncome, setNetOtherIncome] = React.useState(0.0);
  let creditFileDataItem = creditFileData?.formData?.incomeData?.personnelIncome;
  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();
  const [totalObligation, setTotalObligation]: any = React.useState(0);
  const [totalExtraIncome, setTotalExtraIncome]: any = React.useState(0);
  const [netExtraIncome, setNetExtraIncome]: any = React.useState(0);

  const loanSummary = {
    incomePayload: incomePayload,
    expensePayload: expensePayload,
    jbdata: jbdata,
    lifeInsuranceData: lifeInsuranceData,
    liabilityData: liabilityData,
    salaryProportionFactor: new ProductDataProvider(creditFileData).salaryProportionFactorIncomeBased(),
  };

  const addIncomeData = (data) => {
    const prevData = netOtherIncomeState?.incomeData?.attach(Downgraded).get();

    let sortedList: any = [];
    if (prevData) {
      sortedList = [prevData, data.attach(Downgraded).get()];
      sortedList = sortedList.flat();
    } else {
      sortedList.push(data.attach(Downgraded).get());
    }

    netOtherIncomeState.incomeData.set(sortedList);
  };

  const repayLoanController = new RepayLoanController(loanSummary, data);

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const params = {
        applicationId: applicationId,
        section: "direct_obligation_other_income",
      };
      let response: any = await getAppraisalData(params);

      if (response && Object.keys(response).length > 0) {
        netOtherIncomeState.set(response);
      }

      abilityToPayLoanState.set(repayLoanController.abilityRepayLoanData);
      isLoading.set(false);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const data = await getTrailCalData(applicationId);

        if (data) {
          if (data?.trailCalResult) {
            setTrailCalResult(data?.trailCalResult);
          }
          if (data?.trailCalParams) {
            setLoanInterestRate(data?.trailCalParams?.LoanInterestRate);
          }
        }
      } catch (error) {
        setTrailCalResult([]);
      }
    })();
  }, [applicationId]);

  useEffect(() => {
    if (trailCalResult.length > 0) {
      if (
        trailCalResult[1]["termlyInstallment"] != undefined &&
        trailCalResult[1]["termlyInstallment"] != null &&
        trailCalResult[1]["termlyInstallment"] != ""
      ) {
        setMaxMonthInstallment(formatCurrency(trailCalResult[1]["termlyInstallment"]));
        // Get Debt Reypayment Percentage as  % of Exisitng Income
        let debtValue = repayLoanController.calculateDebtRepaymentValue(trailCalResult[1]["termlyInstallment"]);
        setDebtRepaymentPercentage(debtValue);
      } else {
        setMaxMonthInstallment("N/A");
        // Get Debt Reypayment Percentage as  % of Exisitng Income
        let debtValue = repayLoanController.calculateDebtRepaymentValue(0);
        setDebtRepaymentPercentage(debtValue);
      }
    } else {
      setMaxMonthInstallment("N/A");
      // Get Debt Reypayment Percentage as  % of Exisitng Income
      let debtValue = repayLoanController.calculateDebtRepaymentValue(0);
      setDebtRepaymentPercentage(debtValue);
    }
  }, [trailCalResult]);

  useEffect(() => {
    (async function () {
      let cribAmount = await getCribRecords();
      setCribData(cribAmount);
    })();
  }, []);

  useEffect(() => {
    let incomeOtherValues = creditFileDataItem?.attach(Downgraded).get();
    if (incomeOtherValues) {
      incomeOtherValues.map((obj, index) => {
        if (obj) {
          obj?.fixedAllowance ? setFixedAllowance(parseFloat(obj?.fixedAllowance.toString())) : setFixedAllowance(0.0);
          obj?.averageVariableAllowance
            ? setAverageVariableAllowance(parseFloat(obj?.averageVariableAllowance.toString()))
            : setAverageVariableAllowance(0.0);
        } else {
          setFixedAllowance(0.0);
          setAverageVariableAllowance(0.0);
        }
      });

      let netOtherIncome = cribData - (fixedAllowance + averageVariableAllowance);
      setNetOtherIncome(netOtherIncome);
    }
  }, [creditFileDataItem]);

  useEffect(() => {
    let totalObligationAmount = repayLoanController.annualLiability || 0;
    setTotalObligation(totalObligationAmount);
    let totalExtraIncomeAmount = 0;
    let totalExtraIncomeDetails = netOtherIncomeState.attach(Downgraded).get();
    let netExtraIncomeAmount = 0;

    if (totalExtraIncomeDetails.incomeData && totalExtraIncomeDetails.incomeData.length < 0) {
      setTotalExtraIncome(0);
    }

    (totalExtraIncomeDetails.incomeData ?? []).forEach((element) => {
      totalExtraIncomeAmount += element.income;
    });

    netExtraIncomeAmount = totalExtraIncomeAmount - totalObligationAmount;

    setTotalExtraIncome(totalExtraIncomeAmount);
    setNetExtraIncome(netExtraIncomeAmount);
  }, [netOtherIncomeState, totalObligation]);

  const onSaveData = async () => {
    isSaving.set(true);
    const res: any = await saveAppraisalData({
      section: "ability_to_repay",
      applicationId: applicationId,
      data: { ...abilityToPayLoanState?.get(), userId: userId },
    });

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }
    isSaving.set(false);
  };

  const getCribRecords = async () => {
    // CRIB Deductions
    let creditFilePersonalData = creditFileData?.formData?.personalData;
    let formattedPersonalData = creditFilePersonalData.attach(Downgraded).get();
    let individualId = formattedPersonalData?.individualId;
    try {
      const data = await getCribRecordList(applicationId);
      if (data) {
        let entryId = data.participantEntryMap[individualId];
        if (data.resultCribList[entryId].length > 0) {
          let cribAmount = data.resultCribList[entryId][0].cribDeductions;
          return parseFloat(cribAmount.toString());
        } else {
          return 0.0;
        }
      } else {
        return 0.0;
      }
    } catch (error) {
      return 0.0;
    }
  };

  const formattedValue = (value: any) => {
    if (value !== 0 && !value) {
      return "N/A";
    }
    return Number(value)?.toFixed(2);
  };

  // const checkPrivilegeStatus = () => {
  //   let isDisabled = true
  //   if (tabsToEdit.includes(innerComponentData?.id)) {
  //     isDisabled = true
  //   } else {
  //     isDisabled = getIsDisbursed(privilegeKeys.APPRAISAL_TAB_EDIT_APPRAISAL, disbursed) || isLoggedInUserNotTheAssignee
  //   }
  //   return isDisabled
  // }

  const checkPrivilegeStatus = disableAccess({
    isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
  });

  const salaryProportionValue = (new ProductDataProvider(creditDetailsState).salaryProportionFactor() * 100).toString();

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (
        <ApplicationHeader
          formData={data?.formData}
          requestId={data?.requestId}
          applicationId={applicationId}
          title={"Ability to Repay the Proposed Loan"}
          isAppraisal={mainTabData?.isAppraisal}
          isRawVersion={true}
        />
      )}

      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid
            container
            className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}
          >
            <Grid container p={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.grossIncome}>
                  <Grid container>
                    <DetailItem
                      title={`Applicant Gross Income`}
                      value={formatCurrency(abilityToPayLoanState?.limitOnProposedIncome?.get())}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.existingIncome(salaryProportionValue)}>
                  <Grid container>
                    {/*  */}
                    <DetailItem
                      title={`Limit ${(
                        new ProductDataProvider(creditDetailsState).salaryProportionFactor() * 100
                      ).toString()}% on existing income`}
                      value={formattedValue(abilityToPayLoanState?.limitPercentageOfIncome?.get())}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.longTermDeduction}>
                  <Grid container>
                    <DetailItem
                      title={`Long Term Deduction`}
                      value={formatCurrency(abilityToPayLoanState?.currentDeductions?.get())}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.interestRate}>
                  <Grid container>
                    <DetailItem
                      title={`Proposed Loan Interest Rate`}
                      value={loanInterestRate}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.installments}>
                  <Grid container>
                    <DetailItem
                      title={`Proposed Loan Installment`}
                      value={maxMonthInstallment}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.debtRepayment(salaryProportionValue)}>
                  <Grid container>
                    <DetailItem
                      title={`Debt Repayment as  % of Existing Income`}
                      value={debtRepaymentPercentage}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                <InfoToolTip data={applicationHints?.obligations}>
                  <Grid container>
                    <DetailItem
                      title={`Total Obligations`}
                      value={formatCurrency(totalObligation)}
                      valueFontClass="font-size-14"
                      titleFontClass="font-size-12"
                    />
                  </Grid>
                </InfoToolTip>
              </Grid>
              {netExtraIncome > 0 ? (
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3} mb={3}>
                  <InfoToolTip data={applicationHints?.otherIncome}>
                    <Grid container>
                      <DetailItem
                        title={`Net - Other Income`}
                        value={formatCurrency(netExtraIncome)}
                        valueFontClass="font-size-14"
                        titleFontClass="font-size-12"
                      />
                    </Grid>
                  </InfoToolTip>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Stack className="full-width full-height">
              <InnerSectionHeaderComponent
                innerComponentData={params}
                applicationId={applicationId}
                currentApplicationWfData={undefined}
                incomeData={netOtherIncomeState.get()}
                callBackOnAction={addIncomeData}
                disabled={checkPrivilegeStatus}
              />
            </Stack>
            <Grid container className={"full-width full-height"}>
              <Grid container padding={2}>
                {netOtherIncomeState?.incomeData?.get()?.map((entry) => {
                  const description: any = entry?.description ? entry.description : "";
                  const income = entry?.income ? entry.income : "";
                  const createdDate = entry?.createdDate ? entry.createdDate : "";
                  return (
                    <Grid container p={1} spacing={2} className="data-box" key={1}>
                      <Grid container spacing={2} p={1} justifyContent="space-between">
                        <Grid container padding={2} flexDirection={"row"}>
                          <Grid item flex={1}>
                            <Typography variant="body1" color={grey[700]}>
                              {description}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container flexDirection={"row-reverse"} paddingX={2}>
                          <Typography variant="body2" color={grey[500]}>
                            {createdDate}
                          </Typography>
                          <Typography variant="body2" mr={2} color={grey[500]}>
                            {
                              wf_system_users?.get()?.find((user) => user.id == netOtherIncomeState.get().createdBy)
                                ?.fullName
                            }
                          </Typography>
                          <Grid flex={1}></Grid>
                          <Typography variant="body2" color={grey[500]}>
                            {formatCurrency(income)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {isLoading?.get() && <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}
            </Grid>
          </Grid>
        </Grid>
        {isLoading?.get() && <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}
      </Grid>
    </div>
  );
};

export default AbilityToPayLoan;
