export const siDictionary = {
  language: 'භාෂාව',
  back: 'ආපසු',
  published: 'ප්‍රකාශිතයි',
  unpublished: 'ප්‍රකාශනය නොකළ',
  saveChanges: 'වෙනස්කම් සුරකින්න',
  updated: 'යාවත්කාලීන කරන ලදී',
  updateFail: 'යාවත්කාලීන කිරීම අසාර්ථක විය',
  error: 'දෝෂයකි',
  authModule: {
    login: 'ඇතුල් වන්න',
    loginSubTxt: 'ඉදිරියට යාමට පරිශීලක නාමය සහ මුරපදය ඇතුළත් කරන්න',
    username: 'පරිශීලක නාමය',
    password: 'මුරපදය',
    enterUsername: 'පරිශීලක නාමය ඇතුළත් කරන්න',
    enterPassword: 'මුරපදය ඇතුළත් කරන්න',
    continue: 'දිගටම කරගෙන යන්න',
    pleaseFill: 'කරුණාකර අවශ්‍ය ක්ෂේත්‍ර පුරවන්න!',
  },
  smsModule: {
    head: 'SMS සැකිලි',
    createSms: 'SMS සැකිල්ල සාදන්න',
    form: {
      createSmsTitle: 'නව SMS සැකිල්ලක් සාදන්න',
      published: 'ප්‍රකාශිතයි',
      enName: 'ඉංග්රීසි නම',
      enDesc: 'ඉංග්රීසි විස්තරය',
      siName: 'සිංහල නම',
      siDesc: 'සිංහල විස්තරය',
      tmName: 'දෙමළ නම',
      tmDesc: 'දෙමළ විස්තරය',
      createBtn: 'නව සැකිල්ල එක් කරන්න',
      editSmsTitle: 'SMS ආකෘතිය වෙනස් කරන්න',
      editBtn: 'සැකිල්ල වෙනස් කරන්න',
      updated: 'අච්චුව යාවත්කාලීන කරන ලදී',
      newAdded: 'නව සැකිල්ල ඇතුල් කරන ලදී',
    },
    placeholders: 'ස්ථාන දරන්නන්',
    message: 'පණිවුඩය',
    placeholdersIns: 'ඔබ කැමති ස්ථාන දරන්නන් තෝරන්න',
    tmpUpdated: 'අච්චුව යාවත්කාලීන කරන ලදී',
  },
  errorMsg: {
    required: 'අවශ්යයි!',
    requiredAlert: 'අවශ්‍ය ක්ෂේත්‍ර පුරවන්න',
    updateError: 'යාවත්කාලීන දෝෂයකි',
    someError: 'මොකක්හරි වැරැද්දක් වෙලා',
    notFoundTmp: 'අච්චු හැඳුනුම්පත හමු නොවීය',
    noData: 'දත්ත නැත!',
    emptyTmp: 'හිස් සැකිලි ලැයිස්තුව',
  },
};
