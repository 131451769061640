import { Downgraded } from "@hookstate/core";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import {
  getCoreBankData,
  getSCVRecoveryDetails,
  setCoreBankData,
} from "../../../../../services/bankServiceInvorker";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { villageLeaderList } from "../../../../../utility/util";
import AutoCompleteProxyComponent from "../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const VillageLeader = ({ data, applicationId }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [selectedAccount, setSelectedAccount]: any = useState("");
  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData.disbursed;
  const [isLoading, setIsLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [individualId, setIndividualId] = React.useState("");
  const masterDataStore = MasterDataProvider.provideMasterData();
  const villageLeaderName = villageLeaderList;

  useEffect(() => {
    if (creditFileData) {
      setIndividualId(creditFileData?.formData?.personalData?.individualId);
    }
  }, [creditFileData]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (individualId) {
        let cifNo = null;
        // const selectedAccountObj = await getCoreBankData(
        //   applicationId,
        //   CORE_BANK_KEYS.CIF
        // );
        const data = await getSCVRecoveryDetails(
          cifNo,
          "CIF",
          true,
          "",
          applicationId
        );
        if (data) {
          const selectedAccountObj = await getCoreBankData(
            applicationId,
            CORE_BANK_KEYS.VILLAGE_LEADER
          );

          if (selectedAccountObj) {
            setSelectedAccount(selectedAccountObj);
          }
        }
      }
      setIsLoading(false);
    })();
  }, [individualId, refresh]);

  const handleSave = async () => {
    setIsLoading(true);

    const res = await setCoreBankData(applicationId, CORE_BANK_KEYS.VILLAGE_LEADER, selectedAccount);

    if (res?.message) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.message,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Not Updated",
      });
    }

    setIsLoading(false);
  };

  const onChangeAccount = (value) => {
    setSelectedAccount(value);
  };

  if (isLoading) {
    <CircularProgressComponent
      size={30}
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 100,
      }}
    />

  }

  return (
    <>
      <Stack width={"15%"} >
        <Typography variant="subtitle2" color={"gray"} width={"150pt"}> Village Leader</Typography>
      </Stack>
      <Stack width={"25%"} direction={"row"} gap={2}>
        <AutoCompleteProxyComponent
          options={villageLeaderName}
          fullWidth={true}
          onChange={(e) => {
            onChangeAccount(e ? e.id : null);
          }}
          label={"Select Village Leader"}
          value={selectedAccount}
          cls="select_basic"
          disabled={
            getIsDisbursed(null, disbursed) || isLoading ||
            isLoggedInUserNotTheAssignee
          }
          required
        />
        <ButtonComponent
          title={"Save"}
          variant="contained"
          onClick={handleSave}
          style={{
            maxHeight: "40px",
          }}
          disabled={
            isLoggedInUserNotTheAssignee
          }
        />
      </Stack>
      <Stack flex={2}></Stack>
    </>
  );
};

export default VillageLeader;
