import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CachingStorage from "../../../../../../../../services/cacheStorage";
import { insertDetailsOfAnalysis } from "../../../../../../../../services/creditFileApiCall";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";

const ViewMarketAnalysis = (params) => {
    const cacheKey = "market_analysis" + params?.id + params?.applicationId;
    const [loading, setLoading]: any = React.useState(false);
    const [insertObject, setInsertObject] = React.useState(CachingStorage.read(cacheKey)?.data);
    const [createdBy, setCreatedBy] = React.useState();
    const [updatedAt, setUpdatedAt] = React.useState("");

    useEffect(() => {

        if (!insertObject) {
            setLoading(true);
            params?.getItemData().then((item) => {
                setCreatedBy(item?.createdBy)
                setUpdatedAt(item.updatedAt)
                setInsertObject(item.description)
                setLoading(false);

            }).catch((e) => { });
        }

    }, [])

    const saveAnalysis = async () => {
        setLoading(true);
        const saveAnalysis = await insertDetailsOfAnalysis(params.applicationId, params.id, insertObject)
        params?.reloadComponent()

        if (saveAnalysis?.status === 1) {
            addToaster({
                status: 'success',
                title: "Updated successfully",
                message: "Updated successfully",
            });
            closeCondition()
        } else {
            addToaster({
                status: 'error',
                title: 'Error on Saving !',
                message: 'Error on Saving !',
            });
        }

        CachingStorage.clear(cacheKey);
        setLoading(false);
    }

    const closeCondition = async () => {
        closeGlobalModal();
    };

    return (
        <>
            {loading ? (
                <Stack style={{ height: 100 }} alignItems={"center"} width={"100%"} justifyContent={"center"}>
                    {" "}
                    <CircularProgressComponent size={24} />{" "}
                </Stack>
            ) : (
                <Grid item xs={12} md={12} minHeight={"50vh"}>
                    {params?.mode === "edit" || CachingStorage.read(cacheKey)?.data?.length > 0 ? (
                        <TextBoxComponent
                            label={""}
                            value={insertObject}
                            fullWidth={true}
                            onChange={(e) => {
                                setInsertObject(e.target.value);
                                CachingStorage.store(cacheKey, { data: e.target.value });
                            }}
                            multiline
                            rows={15}
                        />
                    ) : (
                        <Typography>
                            <div dangerouslySetInnerHTML={{ __html: insertObject?.replace(/\n/g, '<br />') }}></div>
                        </Typography>
                    )}
                    {CachingStorage.read(cacheKey)?.data?.length > 0 && (
                        <Typography color={"red"}>
                            Unsaved Changes
                            <Typography />
                        </Typography>
                    )}
                </Grid>

            )}
            <Divider></Divider>

            <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
                <Stack direction="row" spacing={5}>
                    {createdBy ? <Typography alignContent={"center"}>
                        Created By : {userName(createdBy)}
                    </Typography> : <></>}

                    {updatedAt ? <Typography alignContent={"center"}>
                        Updated At: {dateFormatWithTimeChange(updatedAt)}
                    </Typography> : <></>}

                </Stack>
                <Stack direction={"row"} spacing={2}>
                    <>
                        <ButtonComponent
                            title="Close"
                            startIcon={<CloseIcon />}
                            variant="contained"
                            onClick={closeCondition}
                            loadingbtn={true}
                            color="info"
                        />
                        {params?.mode === "edit" ?
                            <ButtonComponent
                                title="Save"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={() => {
                                    saveAnalysis()
                                }}
                                loadingbtn={true}
                                color="info"
                            /> : <></>}
                    </>
                </Stack>

            </Stack>
        </>
    )

}

export default ViewMarketAnalysis;