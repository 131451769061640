import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import React, { useMemo } from "react";
import { NumericFormat } from "react-number-format";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../../components/OtherComponents/GlobalToast";

const ConfigChargeModal = (params) => {
  const [charge, setCharge]: any = React.useState({});
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useMemo(() => {
    const chargeObj: any = {
      name: "",
      desc: "",
      maxAmount: 0,
      typeId: "",
      isActive: 1,
    };

    if (params?.type == "update") {
      chargeObj.name = params?.data?.name;
      chargeObj.desc = params?.data?.desc;
      chargeObj.maxAmount = params?.data?.maxAmount;
      chargeObj.typeId = params?.data?.typeId;
      chargeObj.isActive = params?.data?.isActive;
    }
    setCharge(chargeObj);
  }, [params]);

  const saveChargeConfiguration = () => {
    const validationArray: any = [];
    if (charge?.desc == "") {
      validationArray.push("desc");
    }

    if (charge?.maxAmount == "") {
      validationArray.push("maxAmount");
    }

    if (validationArray.length > 0) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Please fill all reuired fields to continue!",
      });
    } else {
      params?.callBackOnAction(charge, params?.dataIndex, params?.type);
    }
  };

  const togglePublishHandler = (value, chargeId) => {
    const data = {
      typeId: chargeId,
      isActive: value ? 1 : 0,
    };
    params?.callBackOnTogglePublish(data);
  };

  return (
    <>
      <Grid container columns={12} pt={2.5}>
        {params?.data?.typeId && (
          <Grid item xs={12} mb={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    {...label}
                    checked={charge.isActive === 1 ? true : false}
                    onChange={(e) => {
                      setCharge((previousState) => {
                        return { ...previousState, isActive: e.target.checked === true ? 1 : 0 };
                      });
                      togglePublishHandler(e.target.checked, charge.typeId);
                    }}
                  />
                }
                label={charge.isActive === 1 ? "Published" : "Unpublished"}
                style={{ justifyContent: "flex-end" }}
              />
            </FormGroup>
          </Grid>
        )}
        <Grid item xs={12} mb={3}>
          <TextBoxComponent
            label={"Description"}
            defaultValue=""
            value={charge.desc}
            fullWidth={true}
            onChange={(e) => {
              setCharge((previousState) => {
                return { ...previousState, desc: e.target.value };
              });
            }}
            multiline={true}
            rows={8}
            required={true}
          />
        </Grid>
        <Grid item xs={12} mb={3}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            thousandSeparator={true}
            label={"Maximum Amount"}
            decimalScale={2}
            allowLeadingZeros={false}
            allowNegative={false}
            required={true}
            fullWidth={true}
            value={charge.maxAmount}
            onValueChange={(e: any) => {
              const { value } = e;
              const floatValue = parseFloat(value);
              setCharge((previousState) => {
                return { ...previousState, maxAmount: floatValue };
              });
            }}
          />
        </Grid>
        <Grid item xs={12} textAlign={"right"}>
          <ButtonComponent startIcon={<CloseIcon />} title="Cancel" variant="outlined" onClick={() => closeGlobalModal()} loadingbtn={true} color="info" style={{ marginRight: "12px" }} />
          <ButtonComponent startIcon={<DoneIcon />} title="Submit" variant="contained" onClick={() => { saveChargeConfiguration(); closeGlobalModal() }} loadingbtn={true} color="info" />
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigChargeModal;
