import { Divider, Grid, Stack } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import isProductOf from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { getUserDesignation, userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import ErrorBoundary from "../../../../../../../ErrorHandleComponent/ErrorBoundary";
import DetailItem, { DetailInlinedItem } from "../../../../DetailItem";


const AnalysisOfLoanApplicantList = (props) => {
  const { loanApplicantList } = props;
  const masterDataStore = MasterDataProvider.provideMasterData();

  return (
    <ErrorBoundary>
      <Stack flex={1}>
        {loanApplicantList
          .slice()
          .reverse()
          .map((entry, i) => {
            const comments: any = entry?.comments ? entry.comments : "";
            const date = entry?.created ? standardDateFormatter(entry.created) : "";
            const isPhysicalVerified = entry?.physicalVerified ? "Yes" : "No";
            const netWorth: any = entry?.netWorth ? formatCurrency(entry.netWorth) : "-";
            const isCompanySignedAnyBusinessWithOther = entry?.isCompanySignedAnyBusinessWithOther ? "Yes" : "No";
            const jbBankRelations = entry?.jbBankRelation ? entry?.jbBankRelation : [];
            const relation = masterDataStore.getMasterData(MASTER_DATA_KEYS?.BANK_RELATION)
            const bankRelationInfo = relation.get().filter(item => item.id === entry.bankRelation)?.[0]?.name;

            return <Stack mt={2}>
              <Stack width={"100%"}>
                <Divider />
              </Stack>
              {jbBankRelations.length > 0 && (
                <Grid container p={1}>
                  {jbBankRelations.map((jbBankRelation, index) => {
                    <Grid item xs={3}>
                      <DetailInlinedItem title={"Bank Relation with Joint Borrower: " + Number(index + 1)} value={jbBankRelation} />
                    </Grid>;
                  })}
                </Grid>
              )}
              {isProductOf() && (
                <Grid container p={1}>
                  <Grid item xs={6}>
                    <DetailInlinedItem title="Company Signed any business agreements with 3rd party" value={isCompanySignedAnyBusinessWithOther} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailItem title="Net Worth" value={netWorth} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                  </Grid>
                </Grid>
              )}
              <Stack mt={2} mb={2}>
                <DetailItem title="Comments" value={comments} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                {entry.userId && <DetailInlinedItem title="By" value={`${userName(entry.userId)} ${entry.userId ? getUserDesignation(entry.userId) : ""}`} />}
                <DetailInlinedItem title="Physical Verification" value={isPhysicalVerified} />
                <DetailInlinedItem title="Date" value={date} />
                <DetailInlinedItem title="Bank Relation" value={bankRelationInfo} />
              </Stack>
            </Stack>;
          })}
      </Stack>
    </ErrorBoundary>
  );
};

export default AnalysisOfLoanApplicantList;
