import { Box, Stack, Grid } from "@mui/material";
import React, { createRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { appSetting, userDetails, workflowMasterData } from "../../../configs/mainStore";
import { DisbursementDocumentUploadComponent } from "../DropzoneComponent/DisbursementDocumentUploadComponent";
import { openGlobalModal } from "../GlobalModal/GlobalModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Downgraded, useState } from "@hookstate/core";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import DeleteIcon from '@mui/icons-material/Delete';
import { getUploadedDisbursementDocuments, viewDisbursementDocumentHisroy } from "../../../services/documentApis";
import { convertDisbursementCount } from "../../../utility/util";
import HistoryIcon from '@mui/icons-material/History';

interface DisbursementDocumentUploadProps {
  disbIndex?: any;
  documentTypes?: any;
  documentArray?: any;
  setDocumentArray?: any;
  setUploadedList?: any;
  applicationId?: any;
  extractAttachedDocuments?: any;
  reloadComponent?: any;
}

export type Ref = any;

export const DisbursementDocumentUpload = forwardRef<Ref, DisbursementDocumentUploadProps>(({ disbIndex, documentTypes, documentArray, setDocumentArray, setUploadedList, applicationId, extractAttachedDocuments, reloadComponent }: DisbursementDocumentUploadProps, docUploadRef) => {

  const initialData = { title: "test", description: "test" };
  const disbursementState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const [refresh, setRefresh] = React.useState(false);
  const initialFileList = {};
  const [fileList, setFileList] = React.useState<any>([]);
  const [submitLoading, setSubmitLoading] = React.useState<any>(false);
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const [documentList, setDocumentList]: any = React.useState([]);
  const appSettings: any = useState(appSetting);
  const convertedDisbIndex = Object.keys(convertDisbursementCount).find(key => convertDisbursementCount[key] === disbIndex);
  let alreadyUploadedData;

  useEffect(() => {
    (async function () {
      if (documentArray.length > 0) {
        let payload = {
          disbIndex: convertedDisbIndex,
          applicationId: applicationId,
        }
        let uploadedDocument = await getUploadedDisbursementDocuments(payload);

        documentArray?.forEach((item) => {
          if (!uploadedDocument.data.data[item.documentId]) {
            initialFileList[item.documentId] = [
              {
                ref: createRef(),
                isMandatory: true,
                allowedFormats: "application/pdf",
                maxFileSize: 4000000,
                documentKey: "",
                fileStatus: "noFile",
                productDocId: "",
                STATUS: '-1',
                DOC_INDEX: 0
              },
            ];
          } else {
            for (const key in uploadedDocument.data.data) {
              if (Object.prototype.hasOwnProperty.call(uploadedDocument.data.data, key)) {
                uploadedDocument.data.data[key].forEach(obj => {
                  obj['ref'] = createRef();
                });
              }
            }
          }
        });
        let completeObject = { ...uploadedDocument.data.data, ...initialFileList };

        setFileList(completeObject);

        let _array: any = [];
        const fetchData = async () => {

          for (const item of documentArray) {
            const tempDoc = documentDetailsList[item.documentId];

            _array.push({
              name: tempDoc[appSettings.get().language]?.name,
              documentId: item.documentId,
              productDocId: item.productDocId,
            });
          }
          setDocumentList(_array);
        };

        fetchData();
      }
    })();
  }, [documentArray])

  useEffect(() => {
    (async function () {
      let payload = {
        disbIndex: convertedDisbIndex,
        applicationId: applicationId,
      }

      let uploadedDocument = await getUploadedDisbursementDocuments(payload);

      const transformedData: any = [];
      const uniqueData = {};
      const documentIds: any = [];
      alreadyUploadedData = uploadedDocument.data.data;

      Object.keys(uploadedDocument.data.data).forEach(key => {
        uploadedDocument.data.data[key].forEach(item => {
          const tempDoc = documentDetailsList[item.DOCUMENT_ID];
          const documentId = parseInt(key);
          const productDocId = item.PRODUCT_DOC_ID;
          const name = tempDoc[appSettings.get().language]?.name;

          const keyCombo = productDocId;

          if (!uniqueData[keyCombo]) {
            transformedData.push({ documentId, productDocId, name });
            documentIds.push({ documentId, productDocId, name });
            uniqueData[keyCombo] = true;
          }
        });
      });

      for (const key in uploadedDocument.data.data) {
        if (Object.prototype.hasOwnProperty.call(uploadedDocument.data.data, key)) {
          uploadedDocument.data.data[key].forEach(obj => {
            obj['ref'] = createRef();
          });
        }
      }
      setDocumentArray([...documentIds]);
      setDocumentList(transformedData);
      let completeObject = { ...fileList, ...uploadedDocument.data.data };

      setFileList(completeObject);
      // setFileList(uploadedDocument.data.data);

    })();
  }, [refresh]);

  extractAttachedDocuments.invoke = () => {
    return documentList;
  }

  const onClickAddBtn = (id) => {

    const nextDocId = fileList[id].length;

    setFileList((prevFileList) => {
      const updatedFileList = {
        ...prevFileList,
        [id]: [
          ...(prevFileList[id] || []),
          {
            STATUS: '-1',
            fileStatus: "noFile",
            ref: createRef(),
            isMandatory: false,
            allowedFormats: "application/pdf",
            maxFileSize: 4000000,
            documentKey: "",
            productDocId: "",
            DOC_INDEX: nextDocId
          },
        ],
      };
      return updatedFileList;
    });
  };

  const onClickeDeleteBtn = (id) => {

    let _documentList = documentList.filter(item => item.id != id);
    let _documentIdArray = documentArray.filter(item => item.documentId != id)
    setDocumentList(_documentList);
    setDocumentArray(_documentIdArray);
  }

  useImperativeHandle(docUploadRef, () => ({

    async normalDocumentUploadFunc() {
      const promises: any = [];
      for (const obj in fileList) {
        fileList[obj].map((item) => {
          promises.push(item.ref.current?.uploadSingleImg());
        });
      }

      return await Promise.all(promises).then(() => {
        return true;
      });
    },
  }));

  const callbackFunction = (uploadDataObject, status) => {

    let documentId = uploadDataObject.documentId;
    let docIndex = uploadDataObject.docIndex;
    if (fileList && fileList[documentId] && fileList[documentId][docIndex]) {
      fileList[documentId][docIndex].fileStatus = status;
    }
    setUploadedList(fileList);
  };

  const viewHistory = async (modalBodyKey, documentId, docName) => {

    const result: any = await viewDisbursementDocumentHisroy({
      applicationId: applicationId,
      documentId: documentId
    });

    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        historyData: result.data,
        documentId: documentId,
        docName: docName
      },
    });
  };

  return (
    <Box>
      {documentList.length > 0 ? documentList.map((obj) =>
        <Grid container spacing={2} mt={1} className="modal-text-field" key={obj.id}>
          <Grid item xs={12} md={12} className="">
            <Grid
              container
              item
              direction="row"
              sm={12}
            >
              <div className="basic-font basic-font-color font-size-16 font-weight-100" style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}>
                Upload {obj.name}
              </div>
              <Stack display={'flex'} justifyContent={'flex-start'} spacing={5} direction={'row'}>
                <AddCircleIcon
                  style={{ color: "gray", fontSize: 25, cursor: "pointer" }}
                  onClick={() => {
                    onClickAddBtn(obj.documentId);
                  }}
                />
                <DeleteIcon style={{ color: "gray", fontSize: 25, cursor: "pointer" }} onClick={() => {
                  onClickeDeleteBtn(obj.documentId);
                }} />
                <HistoryIcon style={{ color: "gray", fontSize: 25, cursor: "pointer" }} onClick={() => {
                  viewHistory("view-disbursement-document-upload-history", obj.documentId, obj.name);
                }} />
              </Stack>
            </Grid>
          </Grid>

          {fileList[obj.documentId]?.map((item, i) => {

            return (
              <DisbursementDocumentUploadComponent
                key={i}
                fileDetails={item}
                uploadDataObject={{
                  applicationId: applicationId,
                  docIndex: obj.DOC_INDEX ? obj.DOC_INDEX : i,
                  documentId: obj.documentId,
                  disbIndex: convertedDisbIndex,
                  productDocId: obj.productDocId,
                  createdBy: loginUser?.userId?.get(),
                }}
                reloadComponent={reloadComponent}
                isPreviewHave={item.STATUS != -1 ? true : false}
                status={item.STATUS}
                callBackFunction={callbackFunction}
              />
            );
          })}
        </Grid>
      ) : "Empty Document List"}
    </Box>
  );
});