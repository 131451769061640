import { Downgraded, useHookstate, useState } from '@hookstate/core';
import React, { useEffect, useMemo } from 'react';
import { CircularProgressComponent } from '../../../../../../../../ProgressComponent/ProgressComponent';
import { comprehensiveProfitAndLoanAccountsData, financialPositionOfTheCompanyFinalObject, viewMode } from '../../../../Stores/MicroAppraisalStore';
import { ComprehensiveController } from '../../Calculations/ComprehensiveController';
import { comprehensiveSetStateFromTheBusinessData } from '../../Helpers/SetStateFromBusinessData/Comprehensive';
import Depreciation from './Depreciation/Depreciation';
import InterestExpenses from './InterestExpenses/InterestExpenses';
import RevenueAndIncome from './RevenueAndIncome/RevenueAndIncome';
const TotalCountsWrapper = React.lazy(() => import("../../../../Helpers/TotalCountsWrapper/TotalCountsWrapper"));
const DeductionsAndCostOfGoods = React.lazy(() => import("./DeductionsAndCostOfGoods/DeductionsAndCostOfGoods"));
const Expenses = React.lazy(() => import("./Expenses/Expenses"));
const Taxes = React.lazy(() => import("./Taxes/Taxes"))

const ComprehensiveContainer = ({ typeOfViewMode = undefined, inspectionBusinessData }) => {
    const formData = useState<any>(comprehensiveProfitAndLoanAccountsData);
    const autoPupulatedKeys: any[] = formData["selectedYears"].attach(Downgraded).get();
    const tots = useState(financialPositionOfTheCompanyFinalObject["totals"]);
    const viewModeType = useHookstate(viewMode);

    useEffect(() => {
        if (typeOfViewMode) {
            viewModeType.set(typeOfViewMode)
        }

        if (inspectionBusinessData) {
            comprehensiveSetStateFromTheBusinessData({ state: formData, busineeData: inspectionBusinessData })
        }

        return () => {
            viewModeType.set(undefined);
        }

    }, [])

    const getAllCalculations = useMemo(() => {
        const downgradedFormData = formData.attach(Downgraded).get();

        const controllerResponse: any = new ComprehensiveController(downgradedFormData.data, autoPupulatedKeys);
        const totals = {
            grossProfit: controllerResponse.getGrossProfitAganist(),
            revenueIncome: controllerResponse.getTotalRevenue(),
            netExpenses: controllerResponse.getTotalExpenses(),
            netProfitBeforeTax: controllerResponse.getNetProfitBeforeTax(),
            netProfitBeforeInterestDepreciation: controllerResponse.getNetProfitBeforeInterestDepreciation(),
            netAfterDepriciation: controllerResponse.getNetAfterDepriciation(),
            netProfitAfterTax: controllerResponse.getNetProfitAfterTax(),
        }
        tots.set(totals);
        return {
            ...totals
        }
    }, [formData["data"].get()]);

    return (
        <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
            <RevenueAndIncome />
            <DeductionsAndCostOfGoods />
            <TotalCountsWrapper key={"grossProfit"} dataObject={getAllCalculations} lables={["grossProfit"]} profitAndLossAccountsTitleArray={autoPupulatedKeys} />
            <Expenses />
            <TotalCountsWrapper key={"netExpenses_netProfitBeforeTax"} dataObject={getAllCalculations} lables={["netExpenses", "netProfitBeforeInterestDepreciation"]} profitAndLossAccountsTitleArray={autoPupulatedKeys} />
            <Depreciation />
            <InterestExpenses />
            <TotalCountsWrapper key={"netAfterDepriciation"} dataObject={getAllCalculations} lables={["netAfterDepriciation"]} profitAndLossAccountsTitleArray={autoPupulatedKeys} />
            <Taxes />
            <TotalCountsWrapper key={"netProfitAfterTax"} dataObject={getAllCalculations} lables={["netProfitAfterTax"]} profitAndLossAccountsTitleArray={autoPupulatedKeys} />

        </React.Suspense>
    )
}

export default ComprehensiveContainer