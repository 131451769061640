import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Chip, Grid, Stack, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxComponent } from "../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { ErrorMessageComponent } from "../../components/OtherComponents/ErrorMessageComponent";
import { InputFieldsStack } from "../../components/OtherComponents/InputFieldsStack/InputFieldsStack";
import { MASTER_DATA_KEYS } from "../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../utility/MasterDataProvider";
import { Downgraded } from "@hookstate/core";

export const PrivilegeSectionView = ({ privilegeTypeList, isLoading, groupPrivilegeDataList, changePrivilegeArr, loggedFrom }) => {
  const masterDataStore = MasterDataProvider.provideMasterData();
  const { t: translate } = useTranslation();

  const [value, setValue] = useState<any>(1);
  const [mainSections, setMainSections] = useState<any>();

  useEffect(() => {
    let tempMainSections = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.PRVG_MAIN_SEC);
    if (tempMainSections && tempMainSections?.length > 0) {
      setMainSections(tempMainSections?.get());
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const selectAllClicked = (privilegeList, subId, groupPrivilegeDataList, value) => {
    changePrivilegeArr({ priObj: privilegeList, value, list: true, groupPrivilegeDataList });
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1", height: "calc(100vh - 300px)", overflowY: "auto" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="-">
              {mainSections?.map((item, i) => {
                return <Tab key={i} label={item?.name} value={item?.id} />;
              })}
            </TabList>
          </Box>
          {mainSections?.map((item, i) => {
            let tempSubSections = masterDataStore?.getDependencyMasterData(MASTER_DATA_KEYS?.PRVG_SUB_SEC, item?.id);

            return (
              <TabPanel value={item?.id}>
                {tempSubSections?.map((subItem, j) => {
                  let tempPrivilegeSection = privilegeTypeList?.[item?.id] && privilegeTypeList?.[item?.id][subItem?.id?.get()] ? privilegeTypeList?.[item?.id][subItem?.id?.get()] : [];

                  return (
                    <>
                      <Grid key={j} container columns={24} mb={2}>
                        <Grid item xs={18} sm={18}>
                          <div className="basic-font align-left">{subItem?.name?.get()}</div>
                        </Grid>
                        {loggedFrom === "UserPrivilegesAndAssignments" ?
                          <Grid item xs={6} sm={6}>
                            <SelectAllCheckBox selectAllClicked={selectAllClicked} isLoading={isLoading} isAllHave={false} tempPrivilegeSection={tempPrivilegeSection} changePrivilegeArr={changePrivilegeArr} groupPrivilegeDataList={groupPrivilegeDataList} />
                          </Grid> : <></>}
                      </Grid>
                      <Grid container columns={24} mb={4}>
                        {Object.keys(tempPrivilegeSection).length > 0 ? (
                          tempPrivilegeSection.map((priObj, i) => {
                            return <RowCheckBox i={i} priObj={priObj} isLoading={isLoading} isPrivilegeHave={groupPrivilegeDataList && groupPrivilegeDataList[priObj?.id] ? true : false} changePrivilegeArr={changePrivilegeArr} />;
                          })
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </>
                  );
                })}
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </>
  );
};

const RowCheckBox = ({ i, priObj, isLoading, isPrivilegeHave, changePrivilegeArr }) => {
  const [isChecked, setIsChecked] = useState(isPrivilegeHave);

  const changeCheckbox = (value) => {
    setIsChecked(value);
    changePrivilegeArr({ priObj, value });
  };

  useEffect(() => {
    setIsChecked(isPrivilegeHave);
  }, [isPrivilegeHave]);

  return (
    <Grid item xs={24} sm={6} key={i} mb={5}>
      <Stack direction={"row"} alignItems={"center"}>
        <CheckBoxComponent
          checked={isChecked}
          onChange={(e) => {
            changeCheckbox(!isChecked);
          }}
          disabled={isLoading}
        />
        <Typography variant="subtitle1">{priObj?.name}</Typography>
      </Stack>
      <Grid mr={1} ml={1}>
        <Typography variant="caption" color={"grey"}>
          {priObj?.desc}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SelectAllCheckBox = ({ selectAllClicked, isLoading, isAllHave, tempPrivilegeSection, changePrivilegeArr, groupPrivilegeDataList }) => {
  const [isChecked, setIsChecked] = useState(isAllHave);

  const changeCheckbox = (tempPrivilegeSection, changePrivilegeArr, groupPrivilegeDataList, value) => {
    setIsChecked(value);
    selectAllClicked(tempPrivilegeSection, changePrivilegeArr, groupPrivilegeDataList, value);
  };

  if (tempPrivilegeSection && tempPrivilegeSection.length == 0) {
    return <></>;
  }

  return (
    <Grid item xs={24} sm={6} m={"auto"} style={{ marginRight: "0px" }}>
      <InputFieldsStack>
        <CheckBoxComponent
          checked={isChecked}
          onChange={(e) => {
            changeCheckbox(tempPrivilegeSection, changePrivilegeArr, groupPrivilegeDataList, !isChecked);
          }}
          disabled={isLoading}
        />
        <div className="basic-font-color font-size-14">Select All</div>
      </InputFieldsStack>
    </Grid>
  );
};
