import { useState } from "@hookstate/core";
import { Paper, Stack } from "@mui/material";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import ServiceCharges from "./ServiceCharges";

export const BankServiceCharges = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
    const value = useState(0);

    const handleChangeIndex = (index: number) => {
        value.set(index);
    };

    // const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

    return (
        <Stack>
            <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData}>
                <ButtonComponent title={"Active"} variant={value.get() === 0 ? "contained" : "outlined"} onClick={() => handleChangeIndex(0)}></ButtonComponent>
                <Stack width="5pt"></Stack>
                <ButtonComponent title={"Inactive"} variant={value.get() === 1 ? "contained" : "outlined"} onClick={() => handleChangeIndex(1)}></ButtonComponent>
                <Stack width="10pt"></Stack>
            </InnerSectionHeaderComponent>
            <Paper sx={{ padding: 2 }}>
                <ServiceCharges
                    data={data}
                    applicationId={applicationId}
                    renderId={value.get() === 1 ? `inActive` : `active`}
                />
            </Paper>
        </Stack>
    );
};
