import { useState } from "@hookstate/core";
import {
    Divider,
    ListItem,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { workflowMasterData } from "../../../../../../configs/mainStore";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const WorkflowStagesSelectionModal = ({ applicationId, callBackOnAction }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const [selectionList, setSelectionList]: any = React.useState([]);
    const { wf_stages: masterStages }: any = useState(workflowMasterData);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async (force = false) => {
        try {
            setIsLoading(true);
            const stages = masterStages.get() || [];
            setSelectionList(stages);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Stack flex={1} alignItems={"center"}>
                <Paper elevation={0}>
                    <CircularProgressComponent size={30} />
                </Paper>
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            <TextBoxComponent placeholder={"Search"} onChange={(e) => setSearchText(e.target.value)}></TextBoxComponent>
            <Divider></Divider>
            <Stack width={"100%"} overflow={"auto"} height={"200px"}>
                {selectionList.filter(item => item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())).map((item: any) => (
                    <ListItem divider key={""}>
                        <Stack
                            key={""}
                            direction="row"
                            width={"100%"}
                            style={{ cursor: "pointer" }}
                            justifyContent={"space-between"}
                            onClick={(e: any) => {
                                callBackOnAction(item.id);
                            }}
                        >

                            <Typography>
                                {item.name}
                            </Typography>
                            <Typography color={"GrayText"} variant={"caption"}>
                                {item.id}
                            </Typography>
                        </Stack>
                    </ListItem>
                ))}
            </Stack>
            <Divider></Divider>
            <Stack direction={"row-reverse"}>
                <ButtonComponent title={"Close"} onClick={closeGlobalModal}></ButtonComponent>
            </Stack>
        </Stack>
    );
};

export default WorkflowStagesSelectionModal;
