import { Downgraded } from "@hookstate/core";
import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { postalCodeDetails } from "../../../../../../../configs/mainStore";
import isProductOf from "../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { LengthItemValidate } from "../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { addItem } from "../Helpers";
import { ExitRemoveItem } from "./Helpers/exitRemoveItem";

export const ShareHolderDetails = ({ business, isMainContactDetails }) => {
  const shareholderDetails = LengthItemValidate.validatedArray(business?.shareholderDetails || []);
  const masterData = MasterDataProvider.provideMasterData();
  const postalCodeState = postalCodeDetails.attach(Downgraded).get();
  const isShareholderAvailable = business?.isShareholderAvailable ?? false;

  const masterDataObjProvider = (mapedshareholderData) => {
    const province = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.PROVINCE, mapedshareholderData?.shareholderD_province);
    const district = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.DISTRICT, mapedshareholderData?.shareholderD_distric);
    const postalCode = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.POSTAL_CODE, postalCodeState[mapedshareholderData?.shareholderD_postalCode]?.id);
    return {
      province,
      district,
      postalCode,
    };
  };

  const BuildShareHolderDetails = ({ shareholderDetails, index = null, masterDataObject }) => {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(shareholderDetails, "Name", shareholderDetails?.shareholderD_name ?? "N/A")}
          {addItem(shareholderDetails, "New NIC", shareholderDetails?.shareholderD_newNic ?? "N/A")}
          {addItem(shareholderDetails, "Old NIC", shareholderDetails?.shareholderD_oldNic ?? "N/A")}
          {addItem(shareholderDetails, "Shareholder Percentage Of Shares", shareholderDetails?.shareholderD_percentageOfShares ?? "N/A")}
          {addItem(shareholderDetails, "Relationship With The Primary Applicant", shareholderDetails?.shareholderD_relationshipWithtThePrimaryApplicant ?? "N/A")}
          {addItem(shareholderDetails, "Address Line 1", shareholderDetails?.shareholderD_addressLine1 ?? "N/A")}
          {addItem(shareholderDetails, "Address Line 2", shareholderDetails?.shareholderD_addressLine2 ?? "N/A")}
          {addItem(shareholderDetails, "Address Line 3", shareholderDetails?.shareholderD_addressLine3 ?? "N/A")}

          {addItem(shareholderDetails, "Address Line 4", shareholderDetails?.shareholderD_addressLine4 ?? "N/A")}
          {addItem(shareholderDetails, "City", shareholderDetails?.shareholderD_city ?? "N/A")}

          {addItem(shareholderDetails, "Province", masterDataObject?.province ?? "N/A")}
          {addItem(shareholderDetails, "District", masterDataObject?.district ?? "N/A")}
          {addItem(shareholderDetails, "Postal Code", masterDataObject?.postalCode ?? "N/A")}
        </Grid>
      </Grid>
    );
  };

  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <>
        {isShareholderAvailable ? (
          <div className="full-width margin-xs-b">
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
              Share Holder Details
            </Grid>

            {shareholderDetails.length > 0 &&
              new ExitRemoveItem(shareholderDetails).removeRemovedItem().map((shareholder, index) => {
                const masterDataObject = masterDataObjProvider(shareholder);
                return <BuildShareHolderDetails index={index} shareholderDetails={shareholder} masterDataObject={masterDataObject} />;
              })}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
