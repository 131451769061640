import { Box, Stack, Grid } from "@mui/material";
import React, { useEffect } from "react";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeBtn } from "../../../../../../styles";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { useState } from "@hookstate/core";
import { OnDemandValueFormatter } from "../../../../../../services/onDemandValidators";

function StandingOrderInputModal(params) {
  const applicationId = params?.applicationId;
  const loanAgreementType = params?.type;
  const letterDocumentData = params?.letterDocumentData;

  const currentType = JSON.parse(localStorage.getItem("securityDocuments") || "{}");

  const [type, setType] = React.useState("");
  let initialData = { [applicationId]: { securityDoc: { [loanAgreementType]: type } } };

  const saveCondition = () => {
    if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
      params.callBackOnAction(type, loanAgreementType);
    }
    closeGlobalModal();
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{params?.modalHeading}</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          {params.inputType === "text" ? (
            <ModalInputComponent
              title={params?.modalInputLabel}
              component={
                <Grid xs={5} item>
                  <TextBoxComponent
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    onInput={OnDemandValueFormatter.alphabetical}
                  />
                </Grid>
              }
              isMandatory={false}
              showErrors={false}
            />
          ) : (
            <ModalInputComponent
              title={params?.modalInputLabel}
              component={
                <Grid xs={5} item>
                  <TextBoxComponent
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    onInput={OnDemandValueFormatter.numeric}
                  />
                </Grid>
              }
              isMandatory={false}
              showErrors={false}
            />
          )}
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={saveCondition} loadingbtn={true} color="info" />
      </Stack>
    </Box>
  );
}

export default StandingOrderInputModal;
