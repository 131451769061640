import { Downgraded, useState } from "@hookstate/core";
import { Box, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { creditData, securityDetails } from "../../../../../../../../configs/mainStore";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { DuplicateKeyReceivedActivateState, InsuranceAvailableActivateState, WarrantyAvailableSection } from "../../SecurityTypeSubComponents/SecurityTypeSubComponent";
import { MachineryEquipmentsMain } from "./MachineryEquipmentsMain";

export const MovableMachineryEquipement = (props) => {
  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const masterDataStore: any = MasterDataProvider.provideMasterData();
  const machineTypeMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MACHINE_TYPE);
  const districtMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT);
  const manufactureCodeMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CODE);
  const conditionMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CONDITIONS);
  const thirdPartyDealerCodeMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.THIRD_PARTY_DEALER_CODE);
  const taxTypeMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE);
  const natureofWarrantyMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.NATURE_OF_WARRANTY);
  const sourceOfPowerMasterData: any = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.SOURCE_POWER);
  const lawyersMasterData: any[] = [
    { id: 1, name: "Lawyer1" },
    { id: 2, name: "Lawyer2" },
  ];

  const [machineTypeData, setMachineTypeData] = React.useState<any>({});
  const [districtData, setDistrictData] = React.useState<any>({});
  const [manufacturerCodeData, setManufacturerCodeData] = React.useState<any>({});
  const [conditionData, setConditionData] = React.useState<any>({});
  const [thirdPartyDealerCodeData, setThirdPartyDealerCodeData] = React.useState<any>({});
  const [taxTypeData, setTaxTypeData] = React.useState<any>({});
  const [natureWarrantyData, setNatureofWarrantyData] = React.useState<any>({});
  const [sourceOfPowerData, setSourceOfPowerData] = React.useState<any>({});
  const [lawyersData, setLawyersData] = React.useState<any>({});
  const securityStateDetails: any = useState(securityDetails);
  const { t: translate } = useTranslation();
  const buttonEvents = securityStateDetails["buttonEvents"]["movableMachinery"];

  const masterDataStoreValues = {
    machineTypeData: machineTypeData,
    districtData: districtData,
    manufacturerCodeData: manufacturerCodeData,
    conditionData: conditionData,
    thirdPartyDealerCodeData: thirdPartyDealerCodeData,
    taxTypeData: taxTypeData,
    natureWarrantyData: natureWarrantyData,
    sourceOfPowerData: sourceOfPowerData,
    lawyersData: lawyersData,
  };
  const { mmLifespanNumberOfYears, mmLifespanNumberOfUnits, mmLifespanDepretiationRate, mmDateCommencementInsurance, mmMovablePropertyMortgageDeedNo, mmLawyerPreparedMortgageDeed, mmDatePreparedMortgageDeed, mmDateRegistrationMortgageDeed, mmOfficeNameRegistrationMortgageDeed, mmFinalRegisteredFolioNumber, mmDateFinalRegisteredFolio } = props?.fields;

  useEffect(() => {
    if (Array.isArray(machineTypeMasterData) && machineTypeMasterData.length > 0) {
      setMachineTypeData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MACHINE_TYPE)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(districtMasterData) && districtMasterData.length > 0) {
      setDistrictData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(manufactureCodeMasterData) && manufactureCodeMasterData.length > 0) {
      setManufacturerCodeData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CODE)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(conditionMasterData) && conditionMasterData.length > 0) {
      setConditionData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CONDITIONS)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(thirdPartyDealerCodeMasterData) && thirdPartyDealerCodeMasterData.length > 0) {
      setThirdPartyDealerCodeData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.THIRD_PARTY_DEALER_CODE)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(taxTypeMasterData) && taxTypeMasterData.length > 0) {
      setTaxTypeData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(natureofWarrantyMasterData) && natureofWarrantyMasterData.length > 0) {
      setNatureofWarrantyData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.NATURE_OF_WARRANTY)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(sourceOfPowerMasterData) && sourceOfPowerMasterData.length > 0) {
      setSourceOfPowerData(masterDataStore?.getMasterData(MASTER_DATA_KEYS?.SOURCE_POWER)?.attach(Downgraded)?.get());
    }

    if (Array.isArray(lawyersMasterData) && lawyersMasterData.length > 0) {
      setLawyersData(lawyersMasterData);
    }
  }, []);

  return (
    <>
      <Stack spacing={1} m={1} className="full-width">
        <Box mt={2} p={2} className="securityWrapper">
          <Fragment>
            {props?.isNotNeedComponentTitle ? (
              <></>
            ) : (
              <InputFieldsStack>
                <Typography variant="subtitle1" pl={2.5}>
                  Movable Machinery Equipment {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                </Typography>
              </InputFieldsStack>
            )}
            <MachineryEquipmentsMain props={props} />
            <DuplicateKeyReceivedActivateState props={props} />
            <InputFieldsStack>
              <InputGrid visibility={mmLifespanNumberOfYears.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmLifespanNumberOfYears.get()}
                  onChange={(e) => {
                    props?.doc?.mmLifespanNumberOfYears.set(e.target.value);
                  }}
                  label={translate("Lifespan Number Of Years")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={mmLifespanNumberOfYears.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmLifespanNumberOfUnits.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmLifespanNumberOfUnits.get()}
                  onChange={(e) => {
                    props?.doc?.mmLifespanNumberOfUnits.set(e.target.value);
                  }}
                  label={translate("Lifespan Number Of Units")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={mmLifespanNumberOfUnits.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmLifespanDepretiationRate.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmLifespanDepretiationRate.get()}
                  onChange={(e) => {
                    props?.doc?.mmLifespanDepretiationRate.set(e.target.value);
                  }}
                  label={translate("Lifespan Depretiation Rate")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={mmLifespanDepretiationRate.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
            <WarrantyAvailableSection props={props} />
            <InsuranceAvailableActivateState props={props} />

            <InputFieldsStack>
              <InputGrid visibility={mmMovablePropertyMortgageDeedNo.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmMovablePropertyMortgageDeedNo.get()}
                  onChange={(e) => {
                    props?.doc?.mmMovablePropertyMortgageDeedNo.set(e.target.value);
                  }}
                  label={translate("Movable Property Mortgage Deed No")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={mmMovablePropertyMortgageDeedNo.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmLawyerPreparedMortgageDeed.visible}>
                <TextBoxComponent label={translate("The lawyer who prepared Movable Property Mortgage Deed")} value={props?.doc?.mmLawyerPreparedMortgageDeed.get()} required={mmLawyerPreparedMortgageDeed.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphabeticalSpaces} onChange={(e) => props?.doc?.mmLawyerPreparedMortgageDeed.set(e.target.value)} />
              </InputGrid>
              <InputGrid visibility={mmDatePreparedMortgageDeed.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.mmDatePreparedMortgageDeed.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.mmDatePreparedMortgageDeed.set(preparedDate);
                    }
                  }}
                  label={translate("Date of prepared and Certified Mortgage Deed (DD/MM/YYYY)")}
                  enableFuture={false}
                  disablePast={false}
                  required={mmDatePreparedMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmDateRegistrationMortgageDeed.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.mmDateRegistrationMortgageDeed.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.mmDateRegistrationMortgageDeed.set(preparedDate);
                    }
                  }}
                  label={translate("Date of Registration of Mortgage Deed (DD/MM/YYYY)")}
                  enableFuture={false}
                  disablePast={false}
                  required={mmDateRegistrationMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmOfficeNameRegistrationMortgageDeed.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmOfficeNameRegistrationMortgageDeed.get()}
                  onChange={(e) => {
                    props?.doc?.mmOfficeNameRegistrationMortgageDeed.set(e.target.value);
                  }}
                  label={translate("Office Name of Registration of Mortgage Deed")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={mmOfficeNameRegistrationMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmFinalRegisteredFolioNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.mmFinalRegisteredFolioNumber.get()}
                  onChange={(e) => {
                    props?.doc?.mmFinalRegisteredFolioNumber.set(e.target.value);
                  }}
                  label={translate("Final Registered Folio Number")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={mmFinalRegisteredFolioNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={mmDateFinalRegisteredFolio.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.mmDateFinalRegisteredFolio.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.mmDateFinalRegisteredFolio.set(preparedDate);
                    }
                  }}
                  label={translate("Date of Final Registered Folio (DD/MM/YYYY)")}
                  enableFuture={false}
                  disablePast={false}
                  required={mmDateFinalRegisteredFolio.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
          </Fragment>
        </Box>
      </Stack>
    </>
  );
};
