import { FC } from "react";
import { Typography } from "@mui/material";
import {
  DialogActionsComponent,
  DialogComponent,
  DialogContentComponent,
  DialogTitleComponent,
} from "./DialogComponent";
import { DialogPropsTypes } from "../../../configs/interfaces";

export const Dialog: FC<DialogPropsTypes> = ({
  open,
  title,
  children,
  actions,
}) => (
  <DialogComponent open={open}>
    <DialogTitleComponent children={<Typography>{title}</Typography>} />
    <DialogContentComponent children={children} />
    <DialogActionsComponent children={actions} />
  </DialogComponent>
);
