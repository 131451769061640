import { Downgraded } from "@hookstate/core";
import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import { endpoints } from "../configs/endpoints";
import { userListStore } from "../configs/stores/userStore";
import Api from "./apiCallsHandler";

const api = Api.getApi();

const getUserListUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/user-list`;
const userLogoutUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/log-out`;

export const getUserDetailsList = async () => {
  try {
    const data = await api.post(getUserListUrl, {});

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUserDetailsById = (userId) => {
  const { userDetailsList }: any = userListStore.attach(Downgraded).get();

  const userList: any = Object.values(userDetailsList);

  if (userList.length > 0) {
    return userList.find((value: any) => value.userId === userId);
  } else {
    return [];
  }
};

export const userLogoutService = async (userId) => {
  try {
    const data = await api.post(userLogoutUrl, userId);

    if (data.status === ERROR_CODES.success) {
      return ERROR_CODES.success;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const changePasswordService = async (payload) => {
  try {
    const data = await api.post(endpoints.changePassword, payload);

    if (data.status === ERROR_CODES.success) {
      return ERROR_CODES.success;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateUserDetailsExtended = async (payload) => {
  try {
    const data = await api.put(endpoints.updateUserExtended, payload);

    if (data.status === ERROR_CODES.success) {
      return ERROR_CODES.success;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUserDetails = async (userId) => {
  try {
    const data = await api.get(endpoints.getUserDetails + userId);
    return data;
  } catch (error) {
    return null;
  }
};

export const switchUserAccount = async (userId) => {
  try {
    const data = await api.post(endpoints.switchUserSession + userId, {});
    return data;
  } catch (error) {
    return null;
  }
};

export const resetUserPassword = async (payload) => {
  try {
    const data = await api.post(endpoints.resetUserPassword, payload);

    if (data.status === ERROR_CODES.success) {
      return ERROR_CODES.success;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const loadSystemUsers = async () => {
  try {
    const data = await api.get(endpoints.loadUsers, {});

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return [];
  } catch (error) {
    return [];
  }
};
