import { Downgraded } from "@hookstate/core";
import { creditData, inspectionFormState } from "../../../../../../../../../configs/mainStore";
import AppConfig from "../../../../../../../../../utility/AppConfig";
import { businessInspection } from "./Forms/BusinessInspection";
import { collateralInspection } from "./Forms/CollateralInspection";
import { cultivationModel } from "./Forms/CultivationModel";
import { inspectionSummery } from "./Forms/InspectionSummery";
import { otherThancultivationModel } from "./Forms/OtherThancultivationModel";
import { primaryApplicantDetails } from "./Forms/PrimaryApplicantDetails";
import { otherQuestionsValidations } from "./Forms/otherQuestions";

export const inspectionValidationMandtoryHandler = (dataKey, state, sectionKey) => {
  const formStore = inspectionFormState.attach(Downgraded).get();
  const { validationMap } = AppConfig.config;
  const creditFileData = creditData.attach(Downgraded).get();
  const isAmountBelowTwoPointFive = creditFileData?.isAmountBelowTwoPointFive;
  const isAmountAboveTwoPointFiveAndEqual = creditFileData?.isAmountAboveTwoPointFiveAndEqual;

  let validationErrorArray = [];
  let structure: any[] = jsonLoopPickerWidgetCart(formStore[dataKey]);
  structure = dataKeyStringNumberSanitizer(structure);

  const validationConfigurations = {
    primaryApplicant: { structure, state, function: primaryApplicantDetails },
    jointBorrower: { structure, state, function: primaryApplicantDetails },
    summery: { structure, state, function: inspectionSummery },
    participation: { structure, state, function: primaryApplicantDetails },
    collateral: { structure, state, function: collateralInspection },
    business: { structure, state, function: businessInspection },
    cultivationModel: { structure, state, function: cultivationModel },
    otherThancultivationModel: { structure, state, function: otherThancultivationModel },
    sustainableEnvironmentalAndSafeguard: { structure, state, function: otherThancultivationModel },
    otherQuestions: { structure, state, function: otherQuestionsValidations }
  };
  validationErrorArray = errorArrayOutLoder(
    validationConfigurations,
    structure,
    state,
    sectionKey,
    validationMap,
    isAmountBelowTwoPointFive,
    isAmountAboveTwoPointFiveAndEqual
  );

  return validationErrorArray;
};

const dataKeyStringNumberSanitizer = (structure) => {
  // eslint-disable-next-line array-callback-return
  structure = structure.filter((field) => {
    if (!parseInt(field?.dataKey.toString())) {
      return field;
    }
  });

  return structure;
};

const errorArrayOutLoder = (
  validationConfigurations,
  structure,
  state,
  sectionKey,
  validationMap,
  isAmountBelowTwoPointFive,
  isAmountAboveTwoPointFiveAndEqual
) => {
  const validateMandatoryFn =
    validationConfigurations[sectionKey]?.function instanceof Function
      ? validationConfigurations[sectionKey].function
      : () => [];

  const errorArray = validateMandatoryFn(
    structure,
    state,
    validationMap,
    isAmountBelowTwoPointFive,
    isAmountAboveTwoPointFiveAndEqual
  );

  return errorArray;
};

export const jsonLoopPickerWidgetCart = (applicationForm) => {
  let intermediateArray: any[] = [];

  if (applicationForm && Object.keys(applicationForm).length > 0 && applicationForm.children.length > 0) {
    applicationForm.children.forEach((nodeJson) => {
      const res: any[] = jsonLoopPickerWidgetCart(nodeJson);
      intermediateArray = [...intermediateArray, ...res];
    });

    return intermediateArray;
  }

  intermediateArray.push(applicationForm);

  return intermediateArray;
};
