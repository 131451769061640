import { Downgraded } from "@hookstate/core";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { defaultDatasets, LineChartDefaultData, options } from "../../../Configs/AbilityToPayLoanAndProposedLoanAmountConfigs/GraphConfigurations";
import { abilityToRepayLoanData } from "../../../Stores/MicroAppraisalStore";

Chart.register(...registerables); //

interface AbilityToPayLoanAndProposedLoanAmountGraphProps {
  data?: any;
}

const AbilityToPayLoanAndProposedLoanAmountGraph: React.FC<AbilityToPayLoanAndProposedLoanAmountGraphProps> = ({ data }: AbilityToPayLoanAndProposedLoanAmountGraphProps) => {
  const abilityToRepayLoanDataState = abilityToRepayLoanData.attach(Downgraded).get();
  const labels: string[] = abilityToRepayLoanDataState["selectedYears"].filter(item => !!item?.isRatioAnalysisCal).map(({ title }) => title);
  const yearDataKeys: number[] = abilityToRepayLoanDataState["selectedYears"].filter(item => !!item?.isRatioAnalysisCal).map(({ dataKey }) => dataKey);
  const lineDefaultData: LineChartDefaultData[] = defaultDatasets;

  let datasets: any[] = lineDefaultData.reduce((dataSetObj: LineChartDefaultData[], curr: LineChartDefaultData, index) => {
    const itemData: Record<number, number> = abilityToRepayLoanDataState["data"]?.[curr.dataKey] || {}

    curr.data = yearDataKeys.reduce((dataArry: number[], yearKey: number) => {
      return [...dataArry, Number(itemData?.[yearKey] || 0)]
    }, [])

    return [...dataSetObj, curr]
  }, [])

  return <Line style={{ minWidth: "50rem", height: "31.25rem" }} options={options} data={{ labels, datasets }} />;
};

export default AbilityToPayLoanAndProposedLoanAmountGraph;
