import { useState } from '@hookstate/core';
import React, { useEffect } from 'react';

import { languageListStore } from '../../configs/stores/languageStore';
import { getLanguageDataList } from '../../services/other';

export const useLoadLanguageData: any = () => {
  const languageData = useState(languageListStore);
  const [loadingLanguageData, setLoadingLanguageData] = React.useState(true);

  const loadData = async () => {
    const data = await getLanguageDataList();

    if (data?.languageList) {
      const { system, communication, login } = data?.languageList;
      const { systemLanguageList, communicationLanguageList, loginLanguageList } = languageData;

      systemLanguageList.set(system);
      communicationLanguageList.set(communication);
      loginLanguageList.set(login);
    }
  };

  useEffect(() => {
    (async function () {
      await loadData();
      setLoadingLanguageData(false);
    })();
  }, []);

  return { loadingLanguageData };
};
