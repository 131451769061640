import { adminPermission } from './getPrivilageState';
import { creditData, userDetails } from '../../configs/mainStore';
import { Downgraded } from '@hookstate/core';

export const isPermitted = (permission?: any) => {
  const privilage = adminPermission(permission);
  let { currentAssignee, disbursed }: any = creditData.attach(Downgraded).get();
  let { userId }: any = userDetails.attach(Downgraded).get();

  if (disbursed === 1) {
    return true;
  } else {
    if (currentAssignee !== userId) {
      return true;
    } else {
      if (0) {
        return privilage;
      } else {
        return false;
      }
    }
  }
};
export const stagewisePermissionCheck = (
  permissionData: any = {},
  workflowStage = '',
  productCatelog = '',
  documentId = '',
  privilegeKey = ''
) => {

  // let stagePermissions: any = permissionData.attach(Downgraded).get();
  let currentProductPermissions: any[] = permissionData[productCatelog][documentId]?.[workflowStage] || [];

  let isDisabled = true;

  if (currentProductPermissions.length < 1) {
    isDisabled = true;
  } else {
    if (!currentProductPermissions.includes(privilegeKey)) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
  }

  return isDisabled;
};
