import { Grid, Stack } from "@mui/material";
import { FC, useState, useEffect, createRef } from "react";
import { applicationDocumentStatus } from "../../../../configs/constants/contants";
import { iconApprovedCircle, iconCircle, iconPendingCircle, iconRejectedCircle } from "../../../../styles";

interface PreviewRowProps {
  Icon?: any;
  subTxt: string;
  mainTxt: string;
  status: number;
}

export const DocumentPreviewRow: FC<PreviewRowProps> = ({ Icon, subTxt, mainTxt, status }) => {
  return (
    <Stack direction="row" spacing={2}>
      {Icon && (
        <Grid item>
          <Icon sx={status === applicationDocumentStatus.rejected ? iconRejectedCircle : status === applicationDocumentStatus.approved ? iconApprovedCircle : iconPendingCircle} />
        </Grid>
      )}
      <Grid container item xs={24} style={{ textAlign: "left" }}>
        <Grid item xs={24}>
          <div className="basic-font basic-font-color opacity-7 font-size-12">{subTxt}</div>
        </Grid>
        <Grid item xs={24}>
          <div className="basic-font basic-font-color-bold font-size-14">{mainTxt}</div>
        </Grid>
      </Grid>
    </Stack>
  );
};

interface PreviewHistoryRowProps {
  subTxtOne: string;
  mainTxtOne: string;
  subTxtTwo: string;
  mainTxtTwo: string;
}

export const DocumentPreviewHistoryRow: FC<PreviewHistoryRowProps> = ({ subTxtOne, mainTxtOne, subTxtTwo, mainTxtTwo }) => {
  return (
    <Grid container item xs={12} sm={24} direction="column" justifyContent="center">
      <Stack direction="row" spacing={2}>
        <Grid container item sm={12} style={{ textAlign: "left" }}>
          <Grid item sm={12}>
            <div className="basic-font basic-font-color opacity-7 font-size-12">{subTxtOne}</div>
          </Grid>
          <Grid item sm={12}>
            <div className="basic-font basic-font-color-bold font-size-14">{mainTxtOne}</div>
          </Grid>
        </Grid>
        <Grid container item sm={12} style={{ textAlign: "left" }}>
          <Grid item xs={12}>
            <div className="basic-font basic-font-color opacity-7 font-size-12">{subTxtTwo}</div>
          </Grid>
          <Grid item xs={12}>
            <div className="basic-font basic-font-color-bold font-size-14">{mainTxtTwo}</div>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};
