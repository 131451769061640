import { Downgraded } from "@hookstate/core";
import SendIcon from "@mui/icons-material/Send";
import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getCoreBankData, setCoreBankData } from "../../../../../services/bankServiceInvorker";
import { select_basic } from "../../../../../styles";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import { createApplicationsHistory } from "../../../../../services/applicationHistoryApi";

const ARMAllocation = ({ applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const [armCode, setArmCode] = React.useState();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const [isSubmit, setIsSubmit] = React.useState(false);

  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData?.disbursed;

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== creditFileData?.currentAssignee;

  useEffect(() => {
    loadARMDetails();
  }, []);

  const loadARMDetails = async () => {
    setIsSubmit(true);
    const selectedARMObj = await getCoreBankData(applicationId, CORE_BANK_KEYS.ARM);
    if (selectedARMObj) {
      const armMasterData = masterDataStore?.getMasterData(MD_KEYS?.ARM_CODE);
      var selectedARMObjId: any = "";

      armMasterData?.get().map((obj) => {
        if (obj.name == selectedARMObj) {
          selectedARMObjId = obj.id;
        }
      });
      setArmCode(selectedARMObjId);
    }
    setIsSubmit(false);
  };

  const onClickedSend = async () => {
    if (armCode !== "") {
      const armMasterData = masterDataStore?.getMasterData(MD_KEYS?.ARM_CODE);
      var selectedARMObjName: any = "";

      armMasterData?.get().map((obj) => {
        if (obj.id == armCode) {
          selectedARMObjName = obj.name;
        }
      });
      setIsSubmit(true);
      const data = await setCoreBankData(applicationId, CORE_BANK_KEYS.ARM, selectedARMObjName);
      if (data?.message) {
        const params = {
          selectedARMObjName: selectedARMObjName,
        };
        createApplicationsHistory(
          applicationId,
          `ARM Allocation - ${getArmCode()}`,
          null,
          params,
          "/bank-service/core-bank-data"
        );
        addToaster({
          status: "success",
          title: "Success",
          message: data?.message,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Not Updated",
        });
      }

      setIsSubmit(false);
    } else {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Select a Schemed",
      });
    }
  };

  const masterData = masterDataStore?.getMasterData(MD_KEYS?.ARM_CODE);
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const getArmCode = () => {
    if (!armCode) return "N/A";
    return masterData.get().filter(data => data.id == armCode)?.[0]?.name || "N/A";
  }

  const Header = (props) => {
    return <div className="mainSectionWrapper full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} disabled={isLoggedInUserNotTheAssignee} />
      <Paper>
        {props.children}
      </Paper>
    </div>;
  }

  if (isSubmit) {
    return <Header>
      <Stack height={"200pt"} justifyContent={"center"} alignItems={"center"}><CircularProgressComponent size={25} /></Stack>
    </Header>;
  }

  return <Header>
    <Stack p={2} spacing={2} width={"25%"} minWidth={"300pt"} pb={4}>
      <Stack direction={"row"} spacing={2}>
        <Typography color={"gray"}>
          Assigned ARM
        </Typography>
        <Typography>
          {getArmCode()}
        </Typography>
      </Stack>

      <Stack direction={"row"}>
        <SelectComponent sx={select_basic} label={"ARM"} value={armCode} values={masterData} onChange={(e) => setArmCode(e.target.value)} defaultPlaceholder={true} disabled={getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee || tabsToEdit.includes(innerComponentData?.id)} />
      </Stack>

      <Stack direction={"row-reverse"}>
        <ButtonComponent startIcon={<SendIcon />} variant="contained" onClick={onClickedSend} style={{}} title={"Assign"} disabled={checkPrivilegeStatus} />
      </Stack>
    </Stack>
  </Header>;
}

export default ARMAllocation;
