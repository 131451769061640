import { Grid } from "@mui/material";
import { addItem, AnnualAmount, invokeValueOverriding, renderingKeys, SectionTitle } from "./Controller";
import { ExitRemoveItem } from "../BusinessDetails/AthamaruProductBusinessDetails/Helpers/exitRemoveItem";

export const Overdraft = ({ data, masterData, title, calculator }) => {
  let payload = data?.overdraft || [];
  payload = new ExitRemoveItem(payload).removeRemovedItem();

  const BuildChild = ({ object }) => {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {renderingKeys["overDraft"]?.map((field) => {
            let isDateTypeField: any = field == "expiryDate" ? true : false;

            return addItem(object, field, invokeValueOverriding(field, masterData, object), "", isDateTypeField);
          })}

          <AnnualAmount>{calculator.calculate("overdraft", object)}</AnnualAmount>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="full-width margin-xs-b">
      {payload.length > 0 ? (
        <>
          <SectionTitle>{title}</SectionTitle>
          {payload
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((object, index) => {
              return <BuildChild key={index} object={object} />;
            })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
