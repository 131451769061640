import { PreDefinedConditionsModal } from "./PredisbursedmentAsPerTheNewReq/PreDefinedConditionsModal";

const PredisbursementModal = (params) => {
  const payload = JSON.parse(JSON.stringify(params || "{}"));

  return (
    <PreDefinedConditionsModal params={params} />
  );
};

export default PredisbursementModal;
