import { Grid } from "@mui/material";
import { DetailInlinedItem } from "../../DetailItem";
import { duration } from "./Components";

export const EmploymentExperience = (props) => {
  const experience = props?.experience;

  return <Grid container m={1} p={1} className="data-box">
    <Grid item xs={3}>
      <DetailInlinedItem
        title="Total Employment Experience "
        value={experience ? duration(experience, "totalYearsOfExperience", "totalMonthsOfExperience") : "N/A"} />
    </Grid>
  </Grid>;
}
