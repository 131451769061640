import { Box, Chip, Grid, ListItem, ListItemText, ListSubheader, Stack, Typography } from "@mui/material";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from '@mui/icons-material/Close';
import { PrivilegeSectionView } from "../../PrivilegeLayout/PrivilegeSectionView";
import React from "react";

const UserPrivilegeModal = (params) => {
    const privilegeTypeList = params.list;
    const loggedFrom = "UserConfigurations"
    const [isLoading, setIsLoading] = React.useState(true);

    const groupPrivilegeDataList:any = params.group;
    const closeCondition = () => {
        closeGlobalModal();
    };
    // let main: any = [];
    // Object.values(privilegeTypeList ?? {})?.map((obj) => { main.push(obj) }
    // )

    // let sub: any = [];
    // Object.values(main ?? {})?.map((obj) => { sub.push(obj) }
    // )

    // sub.forEach(element => {
    //     // console.log(element);
    // });

    return (
        <>
            <div>
                {/* <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                    Users in branch- {params?.group?.name || ""}
                </Typography> */}
                <Stack direction={'column'}>
                <PrivilegeSectionView privilegeTypeList={privilegeTypeList} isLoading={isLoading} groupPrivilegeDataList={groupPrivilegeDataList} changePrivilegeArr={undefined} loggedFrom={loggedFrom}/>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="Close"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={closeCondition}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </>
    );
}

export default UserPrivilegeModal;
