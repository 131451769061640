import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { saveAppraisalData } from "../../../../../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { closeBtn } from "../../../../../../../../styles";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../GlobalToast";


const NetOtherIncomeModal = (params) => {
  const initialData = { description: "", income: "", createdDate: moment().format("DD/MM/YYYY") };
  const incomeState: any = useState(initialData);
  const loginUser = useState(userDetails);

  const saveTask = async () => {
    const errorState = validateSave();
    if (!errorState) {
      let incomeData: any = [];

      if (params?.incomeData) {
        incomeData = [params?.incomeData, incomeState.get()];
      } else {
        incomeData = [incomeState.get()];
      }

      incomeData = incomeData.flat();

      let data = { createdBy: loginUser?.userId?.get(), incomeData };

      const payload = {
        section: "direct_obligation_other_income",
        applicationId: params?.applicationId,
        data: data,
      };

      const res = await saveAppraisalData(payload);

      if (res?.status === responseSuccessCode) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.data?.msg,
        });

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(incomeState);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return incomeState.description.get() === "" || incomeState.income.get() === "";
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack className="basic-font font-size-18 basic-font-color-bold">Add Net Other Income</Stack>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <Stack width={"100%"}>
            <Typography variant="subtitle2" color={"gray"}>
              Description <span className="error">*</span>
            </Typography>
            <TextBoxComponent
              value={incomeState?.description?.get()}
              onChange={(e) => {
                incomeState.description.set(e.target.value);
              }}
              variant="outlined"
              size="small"
              multiline={true}
              // maxRows={4}
              inputProps={{ maxLength: 4000 }}
            />
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          <Stack width={"100%"}>
            <Typography variant="subtitle2" color={"gray"}>
              Income <span className="error">*</span>
            </Typography>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(incomeState?.income?.get())}
              decimalScale={2}
              allowLeadingZeros={false}
              allowNegative={false}
              fullWidth={true}
              onValueChange={(e: any) => {
                incomeState.income.set(e.floatValue);
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveTask}
          // disabled={disableSave()}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default NetOtherIncomeModal;
