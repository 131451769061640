import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

const DepartmentTotal = ({ total, label }) => {
    if (total === "") return null;

    return (
        <Stack marginTop={2}>
            <Typography variant="subtitle2">
                {label}: {total} days
            </Typography>
        </Stack>
    );
};


const DepartmentFlow = ({ items, stageMap, formatDuration, stageColors, selectedIndex, setSelectedIndex, color }) => (
    <Stack direction={"row"} spacing={0.2} style={{ top: "0px", position: "sticky", zIndex: 100, backgroundColor: "white" }}>
        {items
            .filter(item => stageColors?.[item.stage]?.backgroundColor === color)
            .map(obj => (
                <Stack
                    key={obj.ExecSequence}
                    flex={obj.duration}
                    style={{
                        backgroundColor: stageColors?.[obj.stage]?.backgroundColor ?? "red",
                        cursor: "pointer",
                        userSelect: "none",
                        border: obj.ExecSequence === selectedIndex ? 'solid 2px lightBlue' : 'none'
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => setSelectedIndex(obj.ExecSequence)}
                >
                    <Tooltip
                        title={
                            <Stack>
                                <Stack>{stageMap?.[obj.stage] || ""}</Stack>
                                <Stack>{formatDuration(obj.duration)}</Stack>
                            </Stack>
                        }
                        sx={{ ":hover": "background: 'white'" }}
                    >
                        <Stack height={30} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant="caption" fontSize={"6pt"}>
                                {formatDuration(obj.duration)}
                            </Typography>
                        </Stack>
                    </Tooltip>
                </Stack>
            ))}
    </Stack>
);

const UnitFlow = ({ items, stageMap, formatDuration, stageColors }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const departmentsByColor = {
        "#F9F9DD": "Branch",
        "#D6F6D5": "CCU",
        "#C8F0FF": "CAU"
    };

    const calculateTotal = (color) =>
        Number(
            items
                .filter(item => stageColors?.[item.stage]?.backgroundColor === color)
                .reduce((total, item) => total + item.duration, 0) / 24
        ).toFixed(2);

    const totals = {
        "#F9F9DD": calculateTotal("#F9F9DD"),
        "#D6F6D5": calculateTotal("#D6F6D5"),
        "#C8F0FF": calculateTotal("#C8F0FF")
    };

    return (
        <Stack direction={"column"} spacing={2}>
            {Object.keys(departmentsByColor).map(color => (
                <React.Fragment key={color}>
                    <DepartmentTotal total={totals[color]} label={`Total ${departmentsByColor[color]}`} />
                    <DepartmentFlow
                        items={items}
                        stageMap={stageMap}
                        formatDuration={formatDuration}
                        stageColors={stageColors}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        color={color}
                    />
                </React.Fragment>
            ))}
        </Stack>
    );
};

export default UnitFlow;
