import { Downgraded, useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Chip, Grid, Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { creditData, userDetails, workflowMasterData } from '../../../../../../configs/mainStore';
import {
  getPredisbursementChecklistList,
  getTrailCalData,
  predisbursementChecklistItemStatusChange
} from '../../../../../../services/creditFileApiCall';
import { formatCurrency } from '../../../../../../services/currencyFormater';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import { CheckBoxComponent } from '../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent';
import { ErrorMessageComponent } from '../../../../ErrorMessageComponent';
import { addToaster } from '../../../../GlobalToast';
import { CircularProgressComponent } from '../../../../ProgressComponent/ProgressComponent';
import { IconRowComponent } from '../../IconRowComponent';
import InnerSectionHeaderComponent from '../../InnerSectionHeaderComponent';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export const PredisbursementChecklist = ({
  data,
  applicationId,
  innerComponentData,
  productTypeId,
  productSectorId,
  productSchemeId,
}) => {
  const creditFileData: any = useState(creditData);
  const disbursed = creditFileData.disbursed.get();

  const _creditFileData: any = creditData.attach(Downgraded).get();


  const [isLoading, setIsLoading] = React.useState(true);
  const [doneChecklistList, setDoneCheckList] = React.useState<any>();
  const [preChecklistList, setPreCheckList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [isChanged, setIsChanged] = React.useState(false);

  const [trialCalcResult, setTrialCalResult]: any[] = React.useState([]);
  const [trialCalcParams, setTrialCalcParams]: any = React.useState({});


  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const preList = await getPredisbursementChecklistList(applicationId);

      if (preList) {
        setDoneCheckList(preList);
      } else {
        setErrorMessage('Something went Wrong!');
      }

      if (preList) {
        setPreCheckList(preList);
      }

      const trialInvokedData = await getTrailCalData(applicationId);

      let trialCalResult: any = []
      let trailCalParams: any = {}

      if (trialInvokedData) {
        if (trialInvokedData?.trailCalResult != null && trialInvokedData?.trailCalResult != undefined) {
          trialCalResult = trialInvokedData?.trailCalResult;
        }

        if (trialInvokedData?.trailCalParams != null && trialInvokedData?.trailCalParams != undefined) {
          trailCalParams = trialInvokedData?.trailCalParams;
        }
      }

      if (trailCalParams != null && trailCalParams != undefined) {
        if (trailCalParams.applicableRate != null || trailCalParams?.LoanInterestRate != null || trailCalParams?.loanInterestRate != null) {
          let interestRate: any = trailCalParams?.applicableRate ?? trailCalParams?.LoanInterestRate ?? trailCalParams?.loanInterestRate ?? 0.0;
          let regularPayFactor: any = 0.0;

          if (_creditFileData.formData.creditData.regularPaymentFactor != null) {
            regularPayFactor = parseInt(_creditFileData?.formData?.creditData?.regularPaymentFactor);
          } else {
            regularPayFactor = 0.0;
          }
          _creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";
          _creditFileData.formData.creditData.loanAmount = trailCalParams?.LoanAmount ?? trailCalParams?.loanAmount ?? 0.0;
        } else {
          let interestRate: any = parseInt(_creditFileData.formData.creditData.interestRate ?? _creditFileData.formData.creditData.loanInterestRate ?? 0.0);
          let regularPayFactor: any = 0.0;

          if (_creditFileData.formData.creditData.regularPaymentFactor != null) {
            regularPayFactor = parseInt(_creditFileData.formData.creditData.regularPaymentFactor);
          } else {
            regularPayFactor = 0.0;
          }
          _creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";
        }

        if (trailCalParams.LoanTenure != null && trailCalParams.LoanTenure != undefined) {
          let loanTenture: any = trailCalParams.LoanTenure ?? 0;
          _creditFileData.formData.creditData.LoanTenure = loanTenture;
        } else {
          let loanTenture: any = _creditFileData.formData.creditData.loanTerms ?? 0;
          _creditFileData.formData.creditData.LoanTenure = loanTenture;
        }
      } else {
        let interestRate: any = parseInt(_creditFileData.formData.creditData.interestRate ?? _creditFileData.formData.creditData.loanInterestRate ?? 0.0);
        let regularPayFactor: any = 0.0;

        if (_creditFileData.formData.creditData.regularPaymentFactor != null) {
          regularPayFactor = parseInt(_creditFileData.formData.creditData.regularPaymentFactor);
        } else {
          regularPayFactor = 0.0;
        }
        _creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";

        let loanTenture: any = _creditFileData.formData.creditData.loanTerms ?? 0;
        _creditFileData.formData.creditData.LoanTenure = loanTenture;
      }
      if (trialCalResult != null && trialCalResult != undefined) {
        if (trialCalResult.length > 0) {
          if (trialCalResult[1]["termlyInstallment"] != undefined && trialCalResult[1]["termlyInstallment"] != null && trialCalResult[1]["termlyInstallment"] != "") {
            _creditFileData.formData.creditData.maxMonthInstallment = trialCalResult[1]["termlyInstallment"];
          } else {
            _creditFileData.formData.creditData.maxMonthInstallment = 0.0;
          }
        } else {
          _creditFileData.formData.creditData.maxMonthInstallment = 0.0;
        }
      } else {
        _creditFileData.formData.creditData.maxMonthInstallment = 0.0;
      }

      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const statusUpdate = async (itemId, isChecked, setIsChecked, itemObj, setFullName, setDate, wf_system_users) => {
    setIsChecked(!isChecked);
    let completedBy = userId || '';
    const result = await predisbursementChecklistItemStatusChange(
      applicationId,
      itemId,
      isChecked,
      itemObj,
      completedBy
    );

    if (result?.message) {
      const user = wf_system_users?.get()?.find((usr) => usr?.id == completedBy);
      const dateCompleted = new Date();

      setFullName(user?.fullName);
      setDate(`${dateCompleted?.toLocaleString()}`);

      addToaster({
        status: 'success',
        title: 'Updated',
        message: 'Check List Item Updated',
      });
    } else if (result?.error) {
      addToaster({
        status: 'warning',
        title: 'Not Updated',
        message: 'Update Error',
      });
    } else {
      addToaster({
        status: 'error',
        title: 'Error',
        message: 'Something went Wrong!',
      });
    }
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
        disabled={isLoggedInUserNotTheAssignee}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                zIndex: 100,
              }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent headMessage={'Error!'} errorMessage={errorMessage} />
          ) : doneChecklistList?.length > 0 ? (
            <>
              {doneChecklistList?.map((item, i) => {
                return (
                  <TaskChecklistRow
                    itemObj={item}
                    statusUpdate={statusUpdate}
                    key={i}
                    itemDisabled={disbursed || isLoggedInUserNotTheAssignee}
                    preChecklistList={preChecklistList}
                    applicationId={applicationId}
                    creditFileData={creditFileData}
                  />
                );
              })}
            </>
          ) : (
            <ErrorMessageComponent headMessage={'Data Not Available'} errorMessage={''} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

const TaskChecklistRow = ({ itemObj, statusUpdate, preChecklistList, itemDisabled, applicationId, creditFileData }) => {
  const [isChecked, setIsChecked] = React.useState<any>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const { wf_stages, wf_system_users }: any = useState(workflowMasterData);
  const [fullName, setFullName] = React.useState<any>('');
  const [date, setDate] = React.useState<any>('');

  const isExpand = useState(false);

  const onExpand = () => {
    isExpand.set(!isExpand.get());
  };

  const user = wf_system_users?.get()?.find((usr) => usr?.id == itemObj?.COMPLETED_BY);
  const dateCompleted = itemObj?.COMPLETED_DATE ? new Date(itemObj?.COMPLETED_DATE) : '';

  useEffect(() => {
    let returnedStatus = compareWithPreArr(preChecklistList);
    if (returnedStatus) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    let status = itemObj?.STATUS > 0 ? true : false;
    setIsChecked(status);

    setFullName(user?.fullName);
    setDate(`${dateCompleted?.toLocaleString()}`);
  }, []);

  const compareWithPreArr = (arr) => {
    if (arr?.length > 0) {
      let filteredArr = arr?.filter((el) => {
        return el?.MAPPING_ID == itemObj?.ID;
      });
      return filteredArr[0]?.STATUS;
    }
  };

  function buildPrePopulatedText(placeholder) {
    let value = '';

    if (placeholder?.traverse.includes('??')) {
      const segments = placeholder?.traverse.split('??');
      for (let i = 0; i < segments.length; i++) {
        value = eval(`creditFileData.formData.${segments[i].trim()}`)?.get();
        if (value) {
          if ((value as any) instanceof String && value.length > 0) {
            continue;
          }
        } else if ((value as any) !== 0) {
          continue;
        } else if (value == null || value == undefined) {
          continue;
        } else {
          break;
        }
      }
    } else {
      value = eval(`creditFileData.formData.${placeholder?.traverse}`)?.get();
    }

    if (placeholder.format === 'currency') {
      value = formatCurrency(value);
    }

    placeholder.value = value;

    return value || 'N/A';
  }

  function BuildConditionItem({ data }) {
    const placeholderJson = data.PLACEHOLDERS ? JSON.parse(data.PLACEHOLDERS) : null;

    if (!placeholderJson) {
      return <div>{`${data?.DESCRIPTION}`}</div>;
    }

    if (Object.keys(placeholderJson).length === 0) {
      return <div>{`${data?.DESCRIPTION}`}</div>;
    }

    const keys = Object.keys(placeholderJson);
    const segments = (data.DESCRIPTION || '').split(' ');

    const getHintDetails = (hintDetailsStr = '') => {
      let formattedHints: any[] = [];

      if (!hintDetailsStr) {
        return null;
      }

      let hintDetailsArr = JSON.parse(JSON.stringify(hintDetailsStr));
      if (hintDetailsArr.length === 0) {
        return null;
      }

      for (const hint of hintDetailsArr) {
        formattedHints.push(
          <div>
            <b>{hint.key}</b> : {hint.value}
          </div>
        );
      }

      return <div>{formattedHints.map((obj) => obj)}</div>;
    };

    return (
      <div>
        {segments.map((segment) => {
          if (!segment) return '';

          if (!keys.includes(segment)) {
            return <span>{`${segment} `}</span>;
          }

          let placeholder = placeholderJson[segment];

          if (keys.includes(segment)) {
            if (placeholder?.type === 'manual') {
              let label = segment.replaceAll('__', '').toUpperCase();
              let labelText = label.replaceAll('_', ' ');
              return (
                <Chip
                  id={segment}
                  size="small"
                  label={`${placeholder?.value ?? label} `}
                  sx={{ fontSize: '11px', marginRight: '0.5em' }}
                  variant="outlined"
                />
              );
            } else {
              const prePopulatedText = buildPrePopulatedText(placeholder);

              if (placeholder?.hint != null && placeholder?.hint != undefined && placeholder?.hint != '') {
                return (
                  <Tooltip title={<div>{getHintDetails(placeholder?.hint)}</div>} className="align-center">
                    <span>
                      <Chip
                        id={segment}
                        size="small"
                        label={`${prePopulatedText}`}
                        sx={{ fontSize: '11px', marginRight: '0.5em' }}
                      />
                    </span>
                  </Tooltip>
                );
              }

              return (
                <Chip
                  id={segment}
                  size="small"
                  label={`${prePopulatedText}`}
                  sx={{ fontSize: '11px', marginRight: '0.5em' }}
                />
              );
            }
          }

          placeholderJson[segment] = placeholder;

          return <span>{`${segment} `}</span>;
        })}
        {<div style={{ display: 'none' }}>{(data.PLACEHOLDERS = JSON.stringify(placeholderJson))}</div>}
      </div>
    );
  }

  return (
    <Box>
      <Grid
        className="row_list_item"
        style={{
          boxShadow: `0px 0px 10px ${isChecked ? '#39a21a' : '#ff1b1b'}3d`,
        }}
        container
        direction="column"
        p={2}
      >
        <Stack justifyContent={'space-between'} direction={'row'} width={'100%'}>
          <Stack direction={'row'}>
            <CheckBoxComponent
              checked={isChecked}
              disabled={itemDisabled}
              onChange={() => {
                statusUpdate(itemObj?.id, isChecked, setIsChecked, itemObj, setFullName, setDate, wf_system_users);
              }}
            />
            <Stack direction={'column'}>
              <Grid xs={12}>
                <IconRowComponent subTxt={''} mainTxt={itemObj?.TITLE} color={isChecked ? '#39a21a' : '#ff1b1b'} />
              </Grid>
              <Grid xs={12} sx={{ fontSize: '12px !important' }}>
                {/* OLD Version - <IconRowComponent subTxt={""} mainTxt={description} color={isChecked ? "#39a21a" : "#ff1b1b"} /> */}
                <BuildConditionItem data={itemObj} />
              </Grid>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <IconRowComponent
              Icon={isChecked ? DoneIcon : CloseIcon}
              subTxt={''}
              mainTxt={isChecked ? 'Done' : 'Pending'}
              color={isChecked ? '#39a21a' : '#ff1b1b'}
            />
            {fullName && date && (
              <Stack direction="row" spacing={2} alignItems={'center'}>
                <Grid item xs={1} className="align-center">
                  <ButtonComponent
                    iconbtn
                    children={
                      isExpand.get() ? (
                        <KeyboardDoubleArrowUpIcon className="basic-font-color" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="basic-font-color" />
                      )
                    }
                    onClick={onExpand}
                  />
                </Grid>
              </Stack>
            )}
          </Stack>
        </Stack>
        {isExpand.get() && (
          <Grid container className="full-width row_list_item" columns={24} mt={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Actioned By</div>
                <div className={`basic-font basic-font-color font-size-14`}>{fullName}</div>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Actioned Date</div>
                <div className={`basic-font basic-font-color font-size-14`}>{date} </div>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
