import {
  validatelength,
  validatePositiveNumber,
  validatePositiveNumberWithDecimalPlaces,
  validateTextWithCharactersAndSpaces
} from '../../validation-rules/validationRules';

export const lifeInsuranceDetailsValidationOnNext = (changes) => {
  const lifeInsuranceDetailsErrors: any = {};
  for (let i = 0; i < changes?.length; i++) {

    if (changes[i]['removedItem']) { continue }

    const insuranceCompanyVal = changes[i]?.insuranceCompany
      ? insuranceCompanyValidation(`${i + 1} : Insurance Company`, changes[i]?.insuranceCompany)
      : null;

    const insuredAmountVal = changes[i]?.insuredAmount
      ? insuredAmountValidation(`${i + 1} : Insured Amount`, changes[i]?.insuredAmount)
      : null;

    const monthlyPremiumVal = changes[i]?.monthlyPremium
      ? monthlyPremiumValidation(`${i + 1} : Monthly Premium`, changes[i]?.monthlyPremium, 2)
      : null;

    const beneficiaryVal = changes[i]?.beneficiary
      ? beneficiaryValidation(`${i + 1} : Beneficiary`, changes[i]?.beneficiary)
      : null;

    if (insuranceCompanyVal || insuredAmountVal || monthlyPremiumVal || beneficiaryVal) {
      lifeInsuranceDetailsErrors[i] = {
        insuranceCompany: insuranceCompanyVal,
        insuredAmount: insuredAmountVal,
        monthlyPremium: monthlyPremiumVal,
        beneficiary: beneficiaryVal,
      };
    }
  }
  return Object.keys(lifeInsuranceDetailsErrors).length > 0 ? lifeInsuranceDetailsErrors : 1;
};

export const insuranceCompanyValidation = (fieldName, value) => {
  const result: any[] = [];
  // const checkInsuranceCompany = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value.toString());
  // if (checkInsuranceCompany) result.push(checkInsuranceCompany);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const insuredAmountValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkInsuredAmount = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkInsuredAmount) result.push(checkInsuredAmount);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const monthlyPremiumValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkMonthlyPremium = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkMonthlyPremium) result.push(checkMonthlyPremium);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const beneficiaryValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkBeneficiary = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkBeneficiary) result.push(checkBeneficiary);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
