import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { getCoreBankData } from "../../../../../services/bankServiceInvorker";
import { convertNumberToWords } from "../../../../../utility/util";
import { Downgraded, useState } from "@hookstate/core";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import DisbursementVerticalTabComponent from "./DisbursementVerticalTabComponent";
import DisbursementDocumentUploadLayout from "../../../../../layouts/DisbursementDocumentUploadLayout";

const DisbursementDocuments = ({ data, applicationId, tabsToEdit }) => {
  const [selectedTabLabel, setSelectedTabLabel]: any = React.useState<string>('Second Disbursement');
  const [disbursementScheduleData, setDisbursementScheduleData]: any = React.useState<number>();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [onClick, setOnClick] = React.useState(true);
  const [isChanged, setIsChanged]: any = React.useState(false);
  const creditFileData: any = useState(creditData);

  const { productId, applicantType, formData, disbursed }: any = creditFileData.attach(Downgraded).get();
  const individualId = formData?.personalData?.individualId;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  let tabList: any = [];
  let display: any = true;

  const innerComponentData = {
    id: "disbursement_check_list",
    title: selectedTabLabel,
    componentId: "DisbursementCheckList",
    headerActionButton: true,
    headerActionButtonTitle: "Create Checklist",
    documentTypeId: 8,
  };

  useEffect(() => {
    (async function () {
      const disbursementSchedule = await getCoreBankData(
        applicationId,
        CORE_BANK_KEYS.DISB_SCHEDULE
      );

      setDisbursementScheduleData(disbursementSchedule);
    })();
  }, []);

  if (disbursementScheduleData) {
    for (const [key, value] of Object.entries(disbursementScheduleData)) {
      const data: any = key;
      tabList.push(`${convertNumberToWords(data)} Disbursement`)
    }
  }

  const callBackOnAction = () => {
    setIsChanged(!isChanged);
  };

  return (
    <div>
      {
        display ? <Stack direction={'row'}>
          <Stack>
            <DisbursementVerticalTabComponent
              textColor={"primary"}
              variant={"scrollable"}
              indicatorColor={"primary"}
              tabs={tabList}
              setSelectedTabLabel={setSelectedTabLabel}
              setOnClick={setOnClick}
              disbursementScheduleData={disbursementScheduleData}
              labelStyle={{
                textTransform: "none",
                fontSize: "16px",
                fontFamily: "Poppins, Medium",
                borderRight: 1,
                borderColor: "divider",
                alignItems: "flex-start",
              }} />
          </Stack> <Stack flex={1}>
            {
              onClick ? <DisbursementDocumentUploadLayout
                applicationId={applicationId}
                productId={productId}
                applicantType={applicantType}
                documentTypeId={innerComponentData?.documentTypeId}
                documentSectionName={innerComponentData?.title}
                documentComponentId={innerComponentData?.id}
                participantId={individualId}
                disbursed={disbursed}
                isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
                disbIndex={selectedTabLabel}
                />
                : <></>
            }
          </Stack>
        </Stack> : <></>
      }
    </div>
  );

};

export default DisbursementDocuments;
