import { useEffect } from "react";
import { Downgraded, useState } from "@hookstate/core";
import { Grid, Box, Stack, IconButton, CircularProgress } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

// Tab View Required Imports
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { creditData, userDetails } from "../../../../../../../../configs/mainStore";
import { getSCVClientDetails } from "../../../../../../../../services/bankServiceInvorker";
import ErrorBoundary from "../../../../../../../ErrorHandleComponent/ErrorBoundary";
import ScvDetailsContainer from "../../../../../../ApplicationForm/Sections/SingleCustomerViewDetails/ScvDetailsContainer/ScvDetailsContainer";
import InnerSectionHeaderComponent from "../../../../InnerSectionHeaderComponent";

const SCVDetails = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const isLoading = useState(true);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [scvData, setScvData] = React.useState<any>({});
  const [selectedTab, setSelectedTab] = React.useState("PA");
  const jointBorrowerDetails: any[] = creditFileData?.formData?.jointBorrowerData || [];
  const guarantorsDetails: any[] = creditFileData?.formData?.securityData?.guarantors || [];

  useEffect(() => {
    scvDetailsFetchDispatcher();
  }, [creditFileData, selectedTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const scvDetailsFetchDispatcher = async (force = false) => {
    isLoading.set(true);
    let { oldNic, newNic } = creditFileData?.formData?.personalData;

    if (selectedTab !== "PA") {
      jointBorrowerDetails.forEach((jb, index) => {
        let selectedJBTab: any = `JB-${index + 1}`;
        if (selectedTab === selectedJBTab) {
          oldNic = jb?.personalData?.oldNic;
          newNic = jb?.personalData?.newNic;
        }
      });

      guarantorsDetails.forEach((gr, index) => {
        let slectedGRTab: any = `GR-${index + 1}`;
        if (selectedTab === slectedGRTab) {
          oldNic = gr?.personalData?.oldNic;
          newNic = gr?.personalData?.newNic;
        }
      });
    }
    await resposeForByDemandFromComp(oldNic, newNic, force);
    isLoading.set(false);
  };

  const resposeForByDemandFromComp = async (oldNic: any = "", newNic: any = "", force = false) => {
    let nicArray: Array<string> = [];

    if (oldNic) {
      nicArray.push(oldNic);
    }
    if (newNic) {
      nicArray.push(newNic);
    }

    if (nicArray.length > 0) {
      let onDemandResultObj: any = [];
      let validNicArray: Array<string> = [];

      nicArray.forEach((nic, index) => {
        if (nic) {
          onDemandResultObj[index] = getSCVDetails(nic, force, applicationId);
          validNicArray[index] = nic;
        }
      });
      onDemandResultObj = await Promise.all(onDemandResultObj);

      let scvDetailById: any = {};

      onDemandResultObj.forEach((scv, index) => {
        if (scv && Object.keys(scv).length > 0) {
          scvDetailById = {
            ...scvDetailById,
            [validNicArray[index]]: {
              savings: scv.savings,
              FDs: scv.FDs,
              loans: scv.loans,
            },
          };
        }
      });
      setScvData(scvDetailById);
    }
  };

  const getSCVDetails = async (nic, force = false, applicationId) => {
    let data = await getSCVClientDetails(nic, force, applicationId);

    if (data && data?.scvDetails && Object.keys(data?.scvDetails)) {
      return data?.scvDetails;
    }
  };

  const onRefreshData = async (force) => {
    await scvDetailsFetchDispatcher(force);
  };

  const params = {
    scvData,
    isFromCreditFile: true,
  };

  return (
    <>
      <ErrorBoundary>
        <div className="full-width full-height">
          <Stack>
            <InnerSectionHeaderComponent
              innerComponentData={innerComponentData}
              applicationId={applicationId}
              disabled={isLoggedInUserNotTheAssignee}
              children={
                <IconButton
                  size="small"
                  color="primary"
                  children={isLoading.get() ? <CircularProgress size={24} /> : <SyncIcon />}
                  onClick={() => {
                    onRefreshData(true);
                  }}
                  sx={{ right: 20 }}
                />
              }
            />
          </Stack>
          <Grid container className="inner-component-height">
            <Stack spacing={1} m={1} className="full-width">
              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Primary Applicant" value="PA" />
                    {jointBorrowerDetails.length > 0 &&
                      jointBorrowerDetails.map((jb, index) => {
                        return <Tab key={index} label={`Joint Borrower ${index + 1}`} value={`JB-${index + 1}`} />;
                      })}
                    {guarantorsDetails.length > 0 &&
                      guarantorsDetails.map((gr, index) => {
                        return <Tab key={index} label={`Guarantors ${index + 1}`} value={`GR-${index + 1}`} />;
                      })}
                  </TabList>
                </Box>
                <TabPanel value="PA">
                  <ScvDetailsContainer {...params} />
                  {/* <ReportOnBurrowingLayout data={data} applicationId={applicationId} innerComponentData={innerComponentData} tabsToEdit={tabsToEdit} loanData={loanData} onClickedSend={onClickedSend} selectedTab={selectedTab} /> */}
                </TabPanel>
                {jointBorrowerDetails.length > 0 &&
                  jointBorrowerDetails.map((jb, index) => {
                    return (
                      <TabPanel value={`JB-${index + 1}`} key={index}>
                        <ScvDetailsContainer {...params} />
                        {/* <ReportOnBurrowingLayout data={data} applicationId={applicationId} innerComponentData={innerComponentData} tabsToEdit={tabsToEdit} loanData={loanData} onClickedSend={onClickedSend} selectedTab={selectedTab} /> */}
                      </TabPanel>
                    );
                  })}
                {guarantorsDetails.length > 0 &&
                  guarantorsDetails.map((gr, index) => {
                    return (
                      <TabPanel value={`GR-${index + 1}`} key={index}>
                        <ScvDetailsContainer {...params} />
                        {/* <ReportOnBurrowingLayout data={data} applicationId={applicationId} innerComponentData={innerComponentData} tabsToEdit={tabsToEdit} loanData={loanData} onClickedSend={onClickedSend} selectedTab={selectedTab} /> */}
                      </TabPanel>
                    );
                  })}
              </TabContext>
            </Stack>
          </Grid>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default SCVDetails;
