import { useState } from "@hookstate/core";
import EditSMSJobModalLayout from "./EditSMSJobModalLayout";
import { workflowMasterData } from "../../../../../../../../../configs/mainStore";
import { WorkflowStructuralChangeConfig } from "../../../../../../utils";
import { Box } from "@mui/material";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";

const EditSMSJobModalContainer = ({ selectData, isStructuralChange, isEdit }: any) => {
  const { wf_sms_templates, wf_hierarchies, wf_groups, wf_users, wf_system_users }: any = useState(workflowMasterData);

  const smsJobData = useState({
    templatesSMS: [...wf_sms_templates.get()],
    toNumber: {
      hierarchies: [...wf_hierarchies.get()],
      groups: [...wf_groups.get()],
      users: [...wf_system_users.get()],
    },
  });

  const onChange = (e, type) => {
    if (type === "templatesSMS") {
      const matchedData = smsJobData.get()[type].find((job) => job.id === e.target.value);
      const d = { ...matchedData };

      selectData[type].set(d);
    } else {
      const data = e;
      const matchedArray: any = [];

      data.forEach((item) => {
        const matchedData = smsJobData.toNumber.get()[type].find((job) => job.name === item);
        if (matchedData) {
          matchedArray.push({ ...matchedData });
        }
      });
      const { groups, hierarchies, users, ...toNumberPrev } = selectData?.toNumber.get();
      const groupsNew = type === "groups" ? matchedArray : groups.map((item) => ({ ...item }));
      const hierarchiesNew = type === "hierarchies" ? matchedArray : hierarchies.map((item) => ({ ...item }));
      const usersNew = type === "users" ? matchedArray : users.map((item) => ({ ...item }));

      selectData.toNumber.set({
        ...toNumberPrev,
        groups: groupsNew,
        hierarchies: hierarchiesNew,
        users: usersNew,
      });
    }
    // trigger structural change occur only for job change
    if (WorkflowStructuralChangeConfig.nodePermissionChange) {
      isStructuralChange.set(true);
    }
  };
  return <EditSMSJobModalLayout onChange={onChange} smsData={smsJobData} isEdit={isEdit} selectData={selectData} onSave={undefined} />;
};

export const EditSMSJobModalContainerV2 = ({ selectData, isStructuralChange, isEdit, onSave }: any) => {
  const { wf_sms_templates, wf_hierarchies, wf_groups, wf_users, wf_system_users }: any = useState(workflowMasterData);
  const isLoading = useState(false);

  const smsJobData = useState({
    templatesSMS: [...wf_sms_templates.get()],
    toNumber: {
      hierarchies: [...wf_hierarchies.get()],
      groups: [...wf_groups.get()],
      users: [...wf_system_users.get()],
    },
  });

  const onChange = (e, type) => {
    isLoading.set(true);

    if (type === "templatesSMS") {
      const matchedData = smsJobData.get()[type].find((job) => job.id === e.target.value);
      const d = { ...matchedData };

      selectData[type].set(d);
    } else {
      const data = e;
      const matchedArray: any = [];

      data.forEach((item) => {
        const matchedData = smsJobData.toNumber.get()[type].find((job) => job.name === item);
        if (matchedData) {
          matchedArray.push({ ...matchedData });
        }
      });
      const { groups, hierarchies, users, ...toNumberPrev } = selectData?.toNumber.get();
      const groupsNew = type === "groups" ? matchedArray : groups.map((item) => ({ ...item }));
      const hierarchiesNew = type === "hierarchies" ? matchedArray : hierarchies.map((item) => ({ ...item }));
      const usersNew = type === "users" ? matchedArray : users.map((item) => ({ ...item }));

      selectData.toNumber.set({
        ...toNumberPrev,
        groups: groupsNew,
        hierarchies: hierarchiesNew,
        users: usersNew,
      });
    }
    // trigger structural change occur only for job change
    if (WorkflowStructuralChangeConfig.nodePermissionChange) {
      isStructuralChange.set(true);
    }

    isLoading.set(false);
  };

  return (
    <>
      {isLoading?.get() ? (
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Box>
      ) : (
        <EditSMSJobModalLayout onChange={onChange} smsData={smsJobData} isEdit={isEdit} selectData={selectData} onSave={onSave} />)}
    </>
  )
};

export default EditSMSJobModalContainer;
