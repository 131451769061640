import { Box, Divider, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { formatCurrency } from "../../../services/currencyFormater";

const ManageProductDla = (props) => {

    const [payload, setPayload] = React.useState({ id: -1, ...props?.data });
    const [submitting, setSubmitting] = React.useState(false);

    const validateAndSubmit = async () => {
        setSubmitting(true);
        await props.handleSave(payload);
        setSubmitting(false);
        closeGlobalModal();
    }

    const onDelete = async () => {
        setSubmitting(true);
        await props.handleDelete(payload);
        setSubmitting(false);
        closeGlobalModal();
    }

    return <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack display={"flex"} direction={"column"} spacing={1}>
                <Typography>
                    DLA Limit
                </Typography>
                <Typography variant="caption">
                    Delegated Lending Authority
                </Typography>
            </Stack>
        </Stack>
        <Divider></Divider>
        <Stack direction={"row"}>
            <Stack flex={3} spacing={1}>
                <Typography>
                    {"DLA Limit Min"}
                </Typography>
                <Box>
                    <NumericFormat
                        customInput={TextBoxComponent}
                        thousandSeparator={true}
                        value={formatCurrency(payload?.dlaLimitMin)}
                        decimalScale={2}
                        onValueChange={(e) => {
                            const { value } = e;
                            setPayload({ ...payload, dlaLimitMin: Number(value) })
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                        }}
                    />
                </Box>
                <Typography>
                    {"DLA Limit Max"}
                </Typography>
                <Box >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        thousandSeparator={true}
                        value={formatCurrency(payload?.dlaLimitMax)}
                        decimalScale={2}
                        onValueChange={(e) => {
                            const { value } = e;
                            setPayload({ ...payload, dlaLimitMax: Number(value) })
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                        }}
                    />
                </Box>
            </Stack>
            <Stack flex={1}></Stack>
        </Stack>
        <Divider></Divider>
        {!submitting ? <Stack direction={"row"} spacing={2}>
            <Stack flex={1}></Stack>
            <ButtonComponent variant={"contained"} onClick={() => validateAndSubmit()} title={payload?.id === -1 ? "Add" : "Update"}></ButtonComponent>
            {payload?.id === -1 ? <></> : <ButtonComponent variant={"contained"} onClick={() => onDelete()} title={"Delete"}></ButtonComponent>}
            <ButtonComponent variant={"outlined"} onClick={closeGlobalModal} title={"Close"}></ButtonComponent>
        </Stack> : <CircularProgressComponent size={30} />}
    </Stack>
}

export default ManageProductDla;