import { Stack, TableCell } from "@mui/material";
import React, { memo, useState } from "react";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";

interface TableColumnsTextBoxWrapperProps {
  value: any;
  index: string | number;
  title: string;
  data?: any;
  dataKey?: any;
  onchangeHandler?: any;
  dataIndex?: any;
}

const TableColumnsTextBoxWrapperAlphanumericNew: React.FC<TableColumnsTextBoxWrapperProps> = ({ index, value, title, data = {}, dataKey = "", dataIndex, onchangeHandler = () => {} }: TableColumnsTextBoxWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  const [inputValue, setInputValue] = useState(value?.get());

  const handleValueChange = (value) => {
    setInputValue(value);
    onchangeHandler(value, dataKey, dataIndex);
  };

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <TextBoxComponent label={title} value={inputValue} onInput={OnDemandValueFormatter.alphaNumericSpaces} fullWidth={true} onChange={(e) => handleValueChange(e.target.value)} />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default memo(TableColumnsTextBoxWrapperAlphanumericNew);
