import { Grid } from '@mui/material';
import { VARIANT } from '../../../configs/constants/styleValues';

export const InputGrid = ({ visibility, children, variant = VARIANT?.standard, style = {}, nativeProps = {} }) => {
  if (visibility === 1 && variant === VARIANT?.small)
    return (
      <Grid item xs={12} sm={6} md={2} lg={2} xl={2} style={style} {...nativeProps}>
        {children}
      </Grid>
    );

  if (visibility === 1 && variant === VARIANT?.standard)
    return (
      <Grid item {...nativeProps} xs={12} sm={12} md={4} lg={3} xl={3} style={style}>
        {children}
      </Grid>
    );

  if (visibility === 1 && variant === VARIANT?.longGrid)
    return (
      <Grid item {...nativeProps} xs={12} sm={12} md={4} lg={6} xl={6} style={style}>
        {children}
      </Grid>
    );

  if (visibility === 1 && variant === VARIANT?.extraLongGrid)
    return (
      <Grid item {...nativeProps} xs={12} sm={12} md={4} lg={9} xl={9} style={style}>
        {children}
      </Grid>
    );

  if (visibility === 1 && variant === VARIANT?.fullWidth)
    return (
      <Grid item {...nativeProps} xs={12} sm={12} md={12} lg={12} xl={12} style={style}>
        {children}
      </Grid>
    );

  return <></>;
};
