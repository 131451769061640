import { Downgraded, useState } from "@hookstate/core";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React from "react";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../../../../configs/constants/styleValues";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { select_basic } from "../../../../../../../../styles";
import isProductOf from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { getUserDesignation, userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { CheckBoxComponent } from "../../../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../../../../CommunicationService/CMTextInput";
import { addToaster } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { DetailInlinedItem } from "../../../../DetailItem";
import AnalysisOfLoanApplicantList from "./AnalysisOfLoanApplicantList";

export const AnalysisOfLoanApplicantVersionOne = (props) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const {
    formData: { jointBorrowerData },
  } = props;

  const { loanApplicantList = [] } = props.appraisalData;
  const [appraisalData, setAppraisalData]: any = React.useState(props.appraisalData || {});
  const verificationTaken: any = useState(loanApplicantList.filter((item) => item?.physicalVerified)?.[0]?.physicalVerified || false);

  const onSubmit = async () => {
    const errorArray: string[] = [];
    if (!appraisalData?.bankRelation) errorArray.push("You cannot save without Adding Bank Relation or others");

    if (!appraisalData?.comments) errorArray.push("You cannot save without Adding Comments");

    if (isProductOf() && !appraisalData?.netWorth) errorArray.push("Net Worth Cannot Be empty in Athamaru Products");

    if (errorArray.length > 0) {
      return addToaster({
        status: "error",
        title: "Error",
        message: errorArray.join("\n"),
      });
    }

    await props?.changeLoanApplicantData(userId, appraisalData);
  };

  const GetJointBurrowerTypeOfRelationshipWithRDB = () => {
    let intermediateFilterdJbArray = [];

    const onChangeValueSetter = (value, individualId) => {
      let existingAppraisalData: any = { ...appraisalData };
      const _existingJB = appraisalData?.jbBankRelation || appraisalData?.loanApplicantList?.[0]?.jbBankRelation;

      _existingJB[individualId] = value;

      existingAppraisalData.jbBankRelation = _existingJB;
      setAppraisalData(existingAppraisalData);
    };

    try {
      const {
        formData: { jointBorrowerData },
      } = props;

      intermediateFilterdJbArray = jointBorrowerData
        .filter((jbItem) => jbItem && !jbItem["removedItem"])
        .map((jbItem, index) => {
          const {
            personalData: { individualId, oldNic, newNic },
          } = jbItem;

          if (individualId && individualId !== 0) {
            if (oldNic || newNic) {
              return jbItem;
            }
          }
        });
    } catch (error) { }

    if (intermediateFilterdJbArray.length === 0) {
      return <></>;
    }

    return (
      <Grid container columnSpacing={1}>
        {intermediateFilterdJbArray.map((jb) => {
          const {
            personalData: { individualId, oldNic, newNic },
          } = jb;

          const nic = newNic || oldNic;

          const relation = appraisalData?.loanApplicantList?.[0]?.jbBankRelation || {};

          return (
            <Grid item xs={6} key={nic + "item" + individualId} pt={1}>
              <InputGrid visibility={1} variant="fullWidth" key={individualId}>
                <SelectComponent key={`SelectComponent_` + individualId} sx={select_basic} label={"Relationship With RDB: " + nic} value={relation[individualId]} values={masterDataStore.getMasterData(MASTER_DATA_KEYS?.BANK_RELATION)} onChange={(e) => onChangeValueSetter(e.target.value, individualId)} defaultPlaceholder={true} disabled={props?.disabled} />
              </InputGrid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Stack flex={1} p={2}>
      <Stack flex={1} direction={"column"}>
        <Stack flex={1} direction={"column"} style={{ position: "sticky", top: "80px", background: "white", zIndex: 100 }}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              {" "}
              Relationship With RDB (Primary Applicant){" "}
            </Typography>
            <Stack width={"50%"}>
              <InputGrid visibility={1} variant="longGrid">
                <SelectComponent
                  sx={select_basic}
                  label={""}
                  value={appraisalData?.bankRelation}
                  values={masterDataStore.getMasterData(MASTER_DATA_KEYS?.BANK_RELATION)}
                  onChange={(e) => {
                    let existingAppraisalData: any = { ...appraisalData };
                    existingAppraisalData.bankRelation = e.target.value;
                    setAppraisalData(existingAppraisalData);
                  }}
                  defaultPlaceholder={true}
                  disabled={props?.disabled || verificationTaken.get()}
                />
              </InputGrid>
            </Stack>
          </Stack>

          {jointBorrowerData?.[0] && (
            <Stack mt={4} direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
                {" "}
                Relationship with RDB J/B{" "}
              </Typography>
              <Stack width={"50%"}>
                <GetJointBurrowerTypeOfRelationshipWithRDB />
              </Stack>
            </Stack>
          )}

          <Stack direction={"row"} spacing={1} mt={4} alignItems={"center"}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              {" "}
              Physical Verification{" "}
            </Typography>
            <Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <CheckBoxComponent
                  checked={verificationTaken.get()}
                  disabled={props?.disabled || verificationTaken.get()}
                  onChange={(e) => {
                    let existingAppraisalData: any = { ...appraisalData };
                    appraisalData.physicalVerified = e.target.checked === true ? true : false;
                    setAppraisalData(existingAppraisalData);
                  }}
                  required={false}
                />
                <Typography variant="subtitle1" color={"gray"}>
                  {" "}
                  Physical Verification Done for the Relation Contact {props?.formData.contactData?.relationLandNumber ? `(${props?.formData.contactData?.relationLandNumber})` : ""}{" "}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={1} mt={4} alignItems={"flex-start"} mb={2}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              {" "}
              Comment{" "}
            </Typography>
            <Stack width={"50%"}>
              <CMTextInput
                desc={""}
                component={
                  <TextBoxComponent
                    onChange={(e) => {
                      let existingAppraisalData: any = { ...appraisalData };
                      existingAppraisalData.comments = e.target.value;
                      setAppraisalData(existingAppraisalData);
                    }}
                    multiline={true}
                    minRows={4}
                    maxRows={10}
                    value={appraisalData?.comments}
                    disabled={props?.disabled || verificationTaken.get()}
                  />
                }
              />
              <Stack direction={"row"} p={1} justifyContent="space-between">
                {appraisalData.userId && <DetailInlinedItem title="By" value={`${userName(appraisalData.userId)} ${getUserDesignation(appraisalData.userId)}`} />}
                {appraisalData?.created && <DetailInlinedItem title="Date" value={appraisalData?.created ? standardDateFormatter(appraisalData.created) : ""} />}
              </Stack>
            </Stack>
          </Stack>

          <Divider></Divider>

          <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
            <StyledButton styles={props?.isSaving?.get() ? loadingButtonStyle : continueButtonStyle} title={"Save"} onClick={onSubmit} loading={props?.isSaving?.get()} disabled={props?.disabled || verificationTaken.get()} />
          </Stack>
        </Stack>

        <Stack direction={"row"} mt={2}>
          <AnalysisOfLoanApplicantList loanApplicantList={loanApplicantList} renderBankRelationDetails={props.renderBankRelationDetails} />
        </Stack>
      </Stack>
    </Stack>
  );
};
