import { FinancialPositionOfTheCompanyController } from "./CalcluationsInterface";

export class GeneralizedControllerForForecastedCash extends FinancialPositionOfTheCompanyController {
  grossProfit = {};
  totalExpenses = {};
  totalTaxes = {};
  netProfitBeforeTax = {};
  netProfitAfterTax = {};
  totalEquity = {};
  depreciation = {};
  netProfitBeforeInterestDepreciation = {};
  private grossProfitDataKeys: Array<any>;
  private expensesDataKeys: Array<any>;
  private taxesDataKeys: Array<any>;

  constructor(formData, years, grossProfit, expenses, taxes) {
    super();
    this.formData = formData;
    this.years = years.filter((item) => item?.isRatioAnalysisCal);
    this.grossProfitDataKeys = grossProfit;
    this.expensesDataKeys = expenses;
    this.taxesDataKeys = taxes;
  }

  private grossProfitCal(array) {
    let total = {};
    let intermediateArray = this.getIncludedMainKeys();

    intermediateArray.length > 0 &&
      intermediateArray.forEach(({ dataKey }) => {
        for (const { dataKey: key } of array) {
          if (!this.formData[dataKey].hasOwnProperty(key)) {
            continue;
          }
          this.totalRevenue = {
            ...this.totalRevenue,
            [dataKey]: Number(this.formData[dataKey]["revenueIncome"])
          }

          total = {
            ...total,
            [dataKey]:
              (Number((this.formData[dataKey]["revenueIncome"]) ?? 0) -
                Number((this.formData[dataKey]["reductions"]) ?? 0)) || 0,
          };
        }
      });

    return total;
  }

  getTotalGrossProfit() {
    this.grossProfit = this.grossProfitCal(this.grossProfitDataKeys);
    return this.grossProfit;
  }

  getTotalExpenses() {
    this.totalExpenses = this.getTotals(this.expensesDataKeys);
    return this.totalExpenses;
  }

  getTotalExpensesOnly() {
    this.totalExpenses = this.getTotals([{ dataKey: "expenses" }]);
    return this.totalExpenses;
  }

  getTotalNetProfitBeforeTax() {
    this.netProfitBeforeTax = this.deferencesCalculator(this.grossProfit, this.getTotalExpenses());
    return this.netProfitBeforeTax;
  }

  getTotalNetProfitBeforeInterestDepreciation() {
    this.netProfitBeforeInterestDepreciation = this.deferencesCalculator(this.grossProfit, this.getTotalExpensesOnly());
    return this.netProfitBeforeInterestDepreciation;
  }

  getTotalDepreciation() {
    this.depreciation = this.getTotals([{ dataKey: "depreciation" }]);
    return this.depreciation;
  }

  getNetAfterDepriciation() {
    return this.deferencesCalculator(this.getTotalNetProfitBeforeInterestDepreciation(), this.getTotalDepreciation());
  }

  getTotalNetProfitAfterTaxWithDepriciation() {
    this.totalTaxes = this.getTotals(this.taxesDataKeys);
    this.netProfitAfterTax = this.deferencesCalculator(this.getNetAfterDepriciation(), this.totalTaxes);

    return this.netProfitAfterTax;
  }

  getTotalNetProfitAfterTax() {
    this.totalTaxes = this.getTotals(this.taxesDataKeys);
    this.netProfitAfterTax = this.deferencesCalculator(this.getTotalNetProfitBeforeTax(), this.totalTaxes);

    return this.netProfitAfterTax;
  }

  getNetTotalExpenses() {
    return this.deferencesCalculator(this.grossProfit, this.netProfitBeforeTax)
  }
}
