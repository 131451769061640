import { Stack } from "@mui/material";

function CommonWitnessBlock({ index, fields }) {
  return (
    <Stack direction={"row"} flex={1}>
      <Stack mr={2} width={20}>
        {index}.
      </Stack>
      <Stack flex={1}>
        {fields.map((obj) => {
          return (
            <Stack flex={1} direction={"row"} mb={1.25}>
              <Stack width={150} direction={"row"}>
                <Stack flex={1}>{obj}</Stack>
                <Stack width={5}>:</Stack>
              </Stack>
              <Stack style={{ borderBottom: "dotted 2px grey" }} width={"50%"}></Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default CommonWitnessBlock;
