import { Downgraded, useHookstate, useState } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import { useApplicationFormContext } from "../../components/providers/ApplicationFormManageProvider";
import { updateSectionErrorState } from "../../components/providers/ApplicationFormUtils";
import appStrings from "../../configs/constants/appStrings";
import { applicationFormErrorSectionIndexState, applicationFormErrorsState, applicationFormSectionError, applicationFormSectionErrorState, productDetails, selectedApplicationReferenceToEditState, submitLoading, userDetails } from "../../configs/mainStore";
import { saveAppFormDataJson, submitApplication } from "../../services/apiCalls";
import { formSavingAlert } from "../../services/formSavingAlert";
import { getObjectChanges } from "../../services/objectValueChanges";
import { removeNullValues } from "../../services/removeNullValues";
import { updateStateFromUserData } from "../../services/setStateFromTheApplicationFormData";
import { validateMandatoryHandler } from "../helpers/validateMandatoryHandler";

//
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//   :::::: S E R V I C E  R E L A T E D   T O S A V E   A P P L I C A T I O N   F O R M  A N D   S U B M I T   F O R M : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//
// service related to save application form data and submit form

export const useSaveSectionChanges = () => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const { validation, applicationParams: { requestId, formStructure: formSet }, currentPage, navigationsSectionSteps, applicationForm: previousState, formStore: sections }: any = useApplicationFormContext();
  const applicationFormErrors = useState(applicationFormErrorsState);
  const submitLoadingState = useState(submitLoading);
  const { t: translate } = useTranslation();
  const selectedApplicationReferenceId = useHookstate(selectedApplicationReferenceToEditState);
  const selectedApplicationIdToEdit = selectedApplicationReferenceId?.get() || "";
  const productMasterData: any = useState(productDetails);
  const productCatelogKeyData = productMasterData?.productCatalogData?.allProductCatalogsWithKeys?.get();
  const { creditData, personalData } = sections;

  const applicationSubmissionHandler = async ({ formStructure, sectionName, sanitizedCurrFormData, stepNavigationAlertWithUnsavedChanges }) => {
    let { guarantor: guarantorStructure = {}, jb: jointBorrowerStructure = {} } = formSet;
    let props = { formStore: sections, structure: formStructure, guarantorStructure, jointBorrowerStructure, validation, previousData: previousState };
    let responseEr: any = validateMandatoryHandler(props);
    const navigationLength = Object.keys(navigationsSectionSteps).length - 1;
    handleValidationPopUpLight(formStructure, responseEr, { ...sections });
    responseEr = clean(responseEr)

    if (Number(currentPage.get()) === navigationLength) {
      return Object.keys(responseEr).length ? { status: '-1', message: 'Required Mandatory Fields!', errors: responseEr } : { status: 1 }
    }

    const applicationFormDataJSON: any = previousState.attach(Downgraded).get();
    const latestSectionChangesStr = JSON.stringify(sanitizedCurrFormData);
    const oldSectionChangesStr = JSON.stringify(applicationFormDataJSON[sectionName]);

    if ((latestSectionChangesStr !== oldSectionChangesStr) && Object.keys(sanitizedCurrFormData).length) {

      if (!stepNavigationAlertWithUnsavedChanges) return { status: 2, isUnsavedChanges: true };

      let response = await saveJsonData(sectionName, sanitizedCurrFormData, productCatelogKeyData, null, 'U', previousState, requestId, personalData);

      if (response === -2) return { status: response, message: `${sectionName} Mandatory Validation Error!` }

      return { status: 3 }
    }

    if (Object.keys(responseEr).length) {
      return { status: '-1', message: 'Required Mandatory Fields!', errors: responseEr };
    }

    return { status: 3 }
  }

  const saveChanges = async (sectionName, submitType = "", submitMode = "I", formStructure: any = [], validationStatus: any = null, viewModeId = "credit", applicationId = null, stepNavigationAlert = false, stepNavigationAlertWithUnsavedChanges = false, navigateIndex = null, navigateMode = null) => {
    let changes: any = {};

    try {
      const currentDataObj: any = {
        ...JSON.parse(JSON.stringify(previousState.value)),
      };

      sessionStorage.applicationRequestDtl = JSON.stringify(currentDataObj);
    } catch (error) {
    }

    let targetSection = sections?.[sectionName]; // object to be compare ( latest state )
    let sourceObject = previousState?.[sectionName]; // object to be compare with ( old state )

    const targetSectionObj = targetSection?.attach(Downgraded).get() || {};
    const sourceObjectObj = sourceObject?.attach(Downgraded).get() || {};

    if (sectionName === "securityData") {
      let targetSectionLength = targetSectionObj?.guarantors?.length || 0;
      let sourceObjectLength = sourceObjectObj?.guarantors?.length || 0;

      if (sourceObjectLength < targetSectionLength) {
        const latestitem = targetSectionObj.guarantors[targetSectionObj.guarantors.length - 1];

        let valid = true;

        if (!latestitem.personalData.primaryLastName) {
          valid = false;
        } else if (!latestitem.personalData.newNic && !latestitem.personalData.oldNic) {
          valid = false;
        }

        if (valid === true) {
          changes = removeNullValues(getObjectChanges(targetSection, sourceObjectObj, sectionName)) || {};
        }
      } else if (sourceObjectLength === targetSectionLength) {
        changes = removeNullValues(getObjectChanges(targetSection, sourceObject, sectionName)) || {};
      } else {
        changes = targetSection.attach(Downgraded).get() || {};
      }
      // Re-arrange item indexes after the deletion of guaranters(Refer JIRA - 2647)
      if (changes && changes.guarantors && changes?.guarantors.length > 0) {
        changes?.guarantors.filter(g => g != null).map((g, i) => {
          g["itemIndex"] = i;
        });
      }
    } else {
      changes = removeNullValues(getObjectChanges(targetSection, sourceObjectObj, sectionName)) || {};
    }

    if (submitType === "submit") {

      const props = { formStructure, sectionName, stepNavigationAlertWithUnsavedChanges, sanitizedCurrFormData: changes }

      const result = await applicationSubmissionHandler({ ...props });

      if (result.status !== 1) {
        return { ...result }
      }

      //set applicationFormErrors to empty
      applicationFormErrors.set({});
      applicationFormErrorsState.set({});
      applicationFormErrorSectionIndexState.set(0);
      submitLoadingState?.set(true);

      const { type, sector, scheme, applicantType } = creditData.attach(Downgraded).get();

      const response = await submitApplication(requestId, sectionName, Object.keys(changes).length > 0 && changes,
        type, sector, scheme, applicantType, userId, submitMode);

      if (response?.status === 1) {
        formSavingAlert(false, translate("SubmittedSuccessfully") + ` (${selectedApplicationIdToEdit})`);

        return { ...response, status: 3 };
      } else {
        formSavingAlert(true, response?.data);
      }

      return;
    }

    const latestSectionChangesStr = JSON.stringify(changes);
    const oldSectionChangesStr = JSON.stringify(sourceObjectObj);

    if (latestSectionChangesStr !== oldSectionChangesStr) {
      saveJsonData(sectionName, changes, productCatelogKeyData, validationStatus, submitType, previousState, requestId, personalData);
    }
  };

  return { saveChanges };
};

const saveJsonData = async (sectionName, changes, productCatelogKeyData, validationStatus, submitType, previousState, requestId, personalData) => {
  closeGlobalModal()
  let response = await saveAppFormDataJson(requestId, sectionName, changes);

  if (response?.status === 1) {
    const _responseData: any = JSON.parse(response?.data);

    if (_responseData.hasOwnProperty("idTypes") && _responseData?.idTypes?.length === 0) personalData.idTypes.set([]);

    updateStateFromUserData(previousState, sectionName, response?.data);
  } else {
    formSavingAlert(true, response?.data);
  }

  return 1;
};

export const sectionErrorStateReset = () => {
  applicationFormSectionErrorState.set(JSON.parse(JSON.stringify(applicationFormSectionError)));
};

export const handleValidationPopUpLight = (formStructure, responseEr, formStore) => {

  if (Array.isArray(formStructure)) {
    formStructure.forEach(({ key }) => {
      key = appStrings?.mainSectionValidationsKeys?.[key];

      if (responseEr?.hasOwnProperty(key)) {
        let errorArrayLength: number = responseEr[key].length;
        let status = errorArrayLength > 0 ? "error" : "success"
        updateSectionErrorState(formStore?.[key], status)
      }
    })
  }
};

export const clean = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || !obj[propName].length) {
      delete obj[propName];
    }
  }
  return obj;
};
