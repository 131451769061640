import isProductOf, { ProductType } from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { AnalysisOfLoanApplicantVersionOne } from "../../../Retail/Components/AnalysisLoanApplicant/AnalysisOfLoanApplicantVersionOne";
import AnalysisOfLoanRequirement from "../../../Retail/Components/AnalysisOfLoanRequirement/AnalysisOfLoanRequirement";
import AbilityToPayLoanAndProposedLoanAmount from "../../Components/AbilityToPayLoanAndProposedLoanAmount/AbilityToPayLoanAndProposedLoanAmount";
import DirectOtherBankLiabilityAnalysis from "../../Components/DirectOtherBankLiabilityAnalysis/DirectOtherBankLiabilityAnalysis";
import FinancialPositionOfTheCompanyContainer from "../../Components/FinancialPositionOfTheCompany/FinancialPositionOfTheCompanyContainer";
import MarketAnalysis from "../../Components/MarketAnalysis/MarketAnalysis";
import RatioAnalysisContainer from "../../Components/RatioAnalysis/RatioAnalysisContainer";

export const retailconstAppraisalInnerTabs: any[] = ["ability_to_repay", "salary_deduction", "analysis_loan_applicant", "analysis_loan_requirement", "recommended_and_approved_by", "reports_on_previous_rdb_burrowing", "scv_details"];

export const microAppraisalInnerTabs: any[] = ["micro_ability_to_repay", "analysis_loan_applicant", "micro_analysis_loan_requirement", "reports_on_previous_rdb_burrowing", "scv_details", "micro_marketAnalysis", "micro_financialPositionOfTheCompany", "micro_auditedFinancialsDetails", "ratio_analysis", "micro_direct_other_bank_liability_analysis", "recommended_and_approved_by"];

export const chooseAppraisalInnerTabs = (isAmountBelowTwoPointFive) => {
  let microInnerTabs = microAppraisalInnerTabs;

  if (isAmountBelowTwoPointFive) {
    microInnerTabs = microAppraisalInnerTabs.filter((key) => key !== "micro_marketAnalysis");
  }

  return isProductOf({ types: [ProductType.MICRO, ProductType.SME] }) ? microInnerTabs : retailconstAppraisalInnerTabs;
};

export const miroAppraisalComponents = {
  AbilityToPayLoanAndProposedLoanAmount: AbilityToPayLoanAndProposedLoanAmount,
  AddAnalysisLoanApplicantForm: AnalysisOfLoanApplicantVersionOne,
  AnalysisLoanRequirement: AnalysisOfLoanRequirement,
  MarketAnalysis: MarketAnalysis,
  FinancialPositionOfTheCompanyContainer: FinancialPositionOfTheCompanyContainer,
  DirectOtherBankLiabilityAnalysis: DirectOtherBankLiabilityAnalysis,
  RatioAnalysis: RatioAnalysisContainer,
};
