import { Grid, Stack } from "@mui/material";
import { SinglePageBreak } from "../../CommonPageBreaks";
import CommonWitnessBlock from "../../CommonWitnessBlock";

export const PromissioryNoteWitness = ({ data, date, typeCaseClass }) => {
  const jointBorrowDetails = data.formData.jointBorrowerData || [];

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (data.formData.personalData.newNic != null && data.formData.personalData.newNic != "") {
        nic = data.formData.personalData.newNic;
      } else if (data.formData.personalData.oldNic != null && data.formData.personalData.oldNic != "") {
        nic = data.formData.personalData.oldNic;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  const renderJBSignatures = () => {
    return jointBorrowDetails.map((jb, index) => {
      return (
        <>
          <Grid item xs={6} mb={2}>
            <Grid container columns={6}>
              <Stack direction={"row"}>
                <Stack>{parseInt(index + 2)}.</Stack>
                <Stack ml={2}>
                  <Stack>......................................</Stack>
                  <Stack>Signature</Stack>
                  <Stack direction={"row"}>
                    NIC : <Stack width={5}></Stack>
                    <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("JB", jb)}</span>{" "}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>{" "}
          </Grid>
        </>
      );
    });
  };

  return (
    <>
      <Grid container mt={5}>
        <Grid item xs={12}>
          <Stack>
            <Grid container>
              <Grid item xs={6} mb={2}>
                <Grid container columns={6}>
                  <Stack direction={"row"}>
                    <Stack>1.</Stack>
                    <Stack ml={2}>
                      <Stack>......................................</Stack>
                      <Stack>Signature</Stack>
                      <Stack direction={"row"}>
                        NIC : <Stack width={5}></Stack>
                        <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("PA", "")}</span>{" "}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>{" "}
              </Grid>
              {jointBorrowDetails.length > 0 && renderJBSignatures()}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <SinglePageBreak />
      <Grid item container p={0.5} mt={0.5}>
        <Grid item xs={12}>
          <Stack mb={2}>Witnesses :</Stack>
          <Stack>
            <CommonWitnessBlock index={1} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
            <SinglePageBreak />
            <Stack mt={2}></Stack>
            <CommonWitnessBlock index={2} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
          </Stack>
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <Grid item xs={12} pl={0.5}>
          Date : ......................................
        </Grid>
      </Grid>
    </>
  );
};
