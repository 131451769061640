import { Box, InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { StocksUnderBankLockAction, WarehouseDetailsRequiredAction, OwnershipOfWarehousesAction } from "./PledgeSubComponent";
import { InsuranceAvailableActivateState } from "../../SecurityTypeSubComponents/SecurityTypeSubComponent";
import { standardDateFormatter } from "los-util";
import { DatePickerComponent } from "../../../../../../../InputComponents/DatePickerComponent/DatePickerComponent";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { OnDemandLengthValidator, OnDemandValueFormatter, OnDemandValueValidator } from "../../../../../../../../services/onDemandValidators";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import PreInspectionDetails from "./PreInspectionDetails";
import PledgeAgreement from "./PledgeAgreements";
import { NumericFormat } from "react-number-format";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import PostInspectionDetails from "./PostInspectionDetails";

export const Pledge = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore: any = MasterDataProvider.provideMasterData();

  const masterDateValues = {
    pledgeOwnershipMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.OWNERSHIP),
    warehouseMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.WAREHOUSE_RENTED_BANK_CLIENT_NAME),
    pledgeCropTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.CROP_TYPE),
    districtMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT),
    pledgeStatementTemplateMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.STATEMENT_TEMPLATE),
    pledgeStatementFrequencyMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.STATEMENT_FREQUENCY),
  };

  const { pledgeDescription, pledgeValueOfSecurity, pledgeSecuredValue, pledgeForceSaleValue, pledgeOwnership, pledgeOwarehousep, pledgeCropType, pledgeWeightOfPledge, pledgeStatementTemplate, pledgeStatementFreq, pledgeStockStatementValidUpToDate, pledgeToBeSubmittedBeforeGraceDays, pledgeInsuaranceValue, pledgeLoanToValueRatio, pledgeTaxPeriod, pledgeAccumulatedValue, pledgeCumulativeWeight, pledgeInventoryDetailsPricePerUnit, pledgeDateOfInventory, pledgeInventoryNumber } = props?.fields;

  const pledgeOwnershipDropDown = () => {
    return [
      { id: 1, name: "Rented" },
      { id: 2, name: "Owned" },
      { id: 3, name: "Leased by Party" },
    ];
  };
  const warehouseDropDown = () => {
    return [
      { id: 1, name: "Warehouse 01" },
      { id: 2, name: "Warehouse 02" },
      { id: 3, name: "Warehouse 03" },
    ];
  };

  return (
    <Box mt={2} className="securityWrapper">
      <FieldSectionWrapper>
        {props?.isNotNeedComponentTitle ? (
          <></>
        ) : (
          <InputFieldsStack>
            <Typography variant="subtitle1" pl={2.5}>
              Pledge {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
            </Typography>
          </InputFieldsStack>
        )}
        <InputFieldsStack>
          <InputGrid visibility={pledgeValueOfSecurity.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.pledgeValueOfSecurity.get())} onChange={(e) => props?.doc?.pledgeValueOfSecurity.set(e.target.value)} label={translate("Value of the Security")} required={pledgeValueOfSecurity.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
          <InputGrid visibility={pledgeSecuredValue.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.pledgeSecuredValue.get())} onChange={(e) => props?.doc?.pledgeSecuredValue.set(e.target.value)} label={translate("Secured Value")} required={pledgeSecuredValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
          <InputGrid visibility={pledgeDescription.visible}>
            <TextBoxComponent value={props?.doc?.pledgeDescription.get()} onChange={(e) => props?.doc?.pledgeDescription.set(e.target.value)} label={translate("Description")} required={pledgeDescription.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
          <InputGrid visibility={pledgeForceSaleValue.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.pledgeForceSaleValue.get())} onChange={(e) => props?.doc?.pledgeForceSaleValue.set(e.target.value)} label={translate("Force Sale Value")} onInput={OnDemandValueFormatter.numeric} required={pledgeForceSaleValue.mandatory === 1 ? true : false} />
          </InputGrid>
          <InputGrid visibility={pledgeInsuaranceValue.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.pledgeInsuaranceValue.get())} onChange={(e) => props?.doc?.pledgeInsuaranceValue.set(e.target.value)} label={translate("Insuarance Value")} required={pledgeInsuaranceValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
          <InputGrid visibility={pledgeLoanToValueRatio.visible}>
            <TextBoxComponent value={props?.doc?.pledgeLoanToValueRatio.get()} onChange={(e) => props?.doc?.pledgeLoanToValueRatio.set(e.target.value)} label={translate("Loan to Value Ratio")} required={pledgeLoanToValueRatio.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
          <InputGrid visibility={pledgeOwnership.visible}>
            <SelectComponent label={translate("Ownership")} value={props?.doc?.pledgeOwnership.get()} values={masterDateValues.pledgeOwnershipMasterData} required={pledgeOwnership.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.pledgeOwnership.set(e.target.value)} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={pledgeOwarehousep.visible}>
            <TextBoxComponent value={props?.doc?.pledgeOwarehousep.get()} onChange={(e) => props?.doc?.pledgeOwarehousep.set(e.target.value)} label={translate("Warehouse  Rented in Bank's/ Client Name")} required={pledgeOwarehousep.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphabeticalSpaces} />

            {/* <SelectComponent
              label={translate("Warehouse  Rented in Bank's/ Client Name")}
              value={props?.doc?.pledgeOwarehousep.get()}
              values={warehouseDropDown()}
              required={pledgeOwarehousep.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.pledgeOwarehousep.set(e.target.value)
              }
              defaultPlaceholder={true}
            /> */}
          </InputGrid>
          <InputGrid visibility={pledgeCropType.visible}>
            <SelectComponent label={translate("Crop Type")} value={props?.doc?.pledgeCropType.get()} values={masterDateValues.pledgeCropTypeMasterData} required={pledgeCropType.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.pledgeCropType.set(e.target.value)} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={pledgeWeightOfPledge.visible}>
            <TextBoxComponent value={props?.doc?.pledgeWeightOfPledge.get()} onChange={(e) => props?.doc?.pledgeWeightOfPledge.set(e.target.value)} label={translate("Weight Of Pledge (Kg)")} required={pledgeWeightOfPledge.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
          <InputGrid visibility={pledgeTaxPeriod.visible}>
            <TextBoxComponent value={props?.doc?.pledgeTaxPeriod.get()} onChange={(e) => props?.doc?.pledgeTaxPeriod.set(e.target.value)} label={translate("Tax Period")} required={pledgeTaxPeriod.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
        </InputFieldsStack>
        <OwnershipOfWarehousesAction props={props} />
        <WarehouseDetailsRequiredAction props={props} />
        <StocksUnderBankLockAction props={props} />

        <InputFieldsStack>
          <InputGrid visibility={pledgeStatementTemplate.visible}>
            {/* <SelectComponent
              label={translate("Statement Template Code")}
              value={props?.doc?.pledgeStatementTemplate.get()}
              values={masterDateValues.pledgeStatementTemplateMasterData}
              required={pledgeStatementTemplate.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.pledgeStatementTemplate.set(e.target.value)
              }
              defaultPlaceholder={true}
            /> */}

            <TextBoxComponent value={props?.doc?.pledgeStatementTemplate.get()} onChange={(e) => props?.doc?.pledgeStatementTemplate.set(e.target.value)} label={translate("Statement Template Code")} required={pledgeStatementTemplate.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
          <InputGrid visibility={pledgeStatementFreq.visible}>
            <SelectComponent label={translate("Statement Frequency")} value={props?.doc?.pledgeStatementFreq.get()} values={masterDateValues.pledgeStatementFrequencyMasterData} onChange={(e) => props?.doc?.pledgeStatementFreq.set(e.target.value)} required={pledgeStatementFreq.mandatory === 1 ? true : false} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={pledgeStockStatementValidUpToDate.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.pledgeStockStatementValidUpToDate.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.pledgeStockStatementValidUpToDate.set(preparedDate);
                }
              }}
              label={translate("Stock Statement Valid Up To Date (DD/MM/YYYY)")}
              enableFuture={false}
              disablePast={false}
              required={pledgeStockStatementValidUpToDate.mandatory === 1 ? true : false}
            />
          </InputGrid>
          <InputGrid visibility={pledgeToBeSubmittedBeforeGraceDays.visible}>
            <TextBoxComponent value={props?.doc?.pledgeToBeSubmittedBeforeGraceDays.get()} onChange={(e) => props?.doc?.pledgeToBeSubmittedBeforeGraceDays.set(e.target.value)} label={translate("To Be Submitted Before (Grace days)")} required={pledgeToBeSubmittedBeforeGraceDays.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
        </InputFieldsStack>

        <InsuranceAvailableActivateState props={props} />
        <PledgeAgreement props={props} />

        <InputFieldsStack>
          <InputGrid visibility={1} variant={VARIANT?.longGrid}>
            <Box mb={1} mt={1}>
              <FormLabelComponent>Inventory Details</FormLabelComponent>
            </Box>
          </InputGrid>
        </InputFieldsStack>

        <InputFieldsStack>
          <InputGrid visibility={pledgeInventoryNumber.visible}>
            <TextBoxComponent value={props?.doc?.pledgeInventoryNumber.get()} onChange={(e) => props?.doc?.pledgeInventoryNumber.set(e.target.value)} label={translate("Inventory Number")} required={pledgeInventoryNumber.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>

          <InputGrid visibility={pledgeDateOfInventory.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.pledgeDateOfInventory.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.pledgeDateOfInventory.set(preparedDate);
                }
              }}
              label={"Date of Inventory (DD/MM/YYYY)"}
              enableFuture={false}
              disablePast={false}
              required={pledgeDateOfInventory.mandatory === 1 ? true : false}
            />
          </InputGrid>

          <InputGrid visibility={pledgeInventoryDetailsPricePerUnit.visible}>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              label={"Price Per Unit"}
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.pledgeInventoryDetailsPricePerUnit.get())}
              required={pledgeInventoryDetailsPricePerUnit?.mandatory === 1 ? true : false}
              decimalScale={2}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.pledgeInventoryDetailsPricePerUnit.set(value);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
              }}
            />
          </InputGrid>

          <InputGrid visibility={pledgeCumulativeWeight.visible}>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              label={"Cumulative weight"}
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.pledgeCumulativeWeight.get())}
              required={pledgeCumulativeWeight?.mandatory === 1 ? true : false}
              decimalScale={2}
              onValueChange={(e: any) => {
                const { value } = e;

                props?.doc?.pledgeCumulativeWeight.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={pledgeAccumulatedValue.visible}>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              label={"Accumulated value"}
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.pledgeAccumulatedValue.get())}
              required={pledgeAccumulatedValue?.mandatory === 1 ? true : false}
              decimalScale={2}
              onValueChange={(e: any) => {
                const { value } = e;

                props?.doc?.pledgeAccumulatedValue.set(value);
              }}
            />
          </InputGrid>
        </InputFieldsStack>
        <PreInspectionDetails props={props} />
        {/* <PostInspectionDetails props={props} /> */}
      </FieldSectionWrapper>
    </Box>
  );
};
