import { Downgraded, useState } from "@hookstate/core";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect } from "react";
import { userDetails } from "../../../../../configs/mainStore";
import { loadApplicationFrom } from "../../../../../services/applicationFormApis";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import AddressInformation from "../Application/AddressInformation";
import AssetsDetails from "../Application/AssetsDetails";
import BankDetails from "../Application/BankDetails";
import BusinessDetails from "../Application/BusinessDetails";
import ContactDetails from "../Application/ContactDetails";
import EducationalDetails from "../Application/EducationalDetails";
import EmploymentDetails from "../Application/EmploymentDetails";
import ExpensesDetails from "../Application/ExpensesDetails";
import IdentificationDetails from "../Application/IdentificationDetails";
import IncomeDetails from "../Application/IncomeDetails";
import InquiryOfObligations from "../Application/InquiryOfObligations";
import LifeInsuranceDetails from "../Application/LifeInsuranceDetails";
import TaxDetails from "../Application/TaxDetails";

const GuarantorDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isRawVersion }) => {
  const guarantorsArr = data?.formData?.securityData?.guarantors;
  const expandState = useState(false);
  const formSections: any = useState([]);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [value, setValue] = React.useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const type = data?.formData?.creditData?.type;
      const sector = data?.formData?.creditData?.sector;
      const scheme = data?.formData?.creditData?.scheme;
      const applicantType = data?.formData?.creditData?.applicantType;
      let participantType = 2;

      const structure: any = await loadApplicationFrom(type, sector, scheme, applicantType, participantType);
      formSections.set(structure);
    })();
  }, []);

  useEffect(() => {
    if (guarantorsArr?.length > 0) {
      const stateObj = guarantorsArr?.map((jointBorrowerData, i) => ({
        [i]: true,
      }));
      expandState.set(stateObj);
    }
  }, [guarantorsArr]);

  const onClickIcon = (e, index) => {
    const prev = expandState[index].get();
    expandState[index].set(!prev);
  };

  const components: any = {
    "primary-applicant-details": IdentificationDetails,
    "contact-details": ContactDetails,
    "address-details": AddressInformation,
    "education-details": EducationalDetails,
    "employment-details": EmploymentDetails,
    "business-details": BusinessDetails,
    "income-details": IncomeDetails,
    "expenses-details": ExpensesDetails,
    "bank-details": BankDetails,
    "assets-details": AssetsDetails,
    "tax-details": TaxDetails,
    // 'analysis-of-the-loan-requirement': IdentificationDetails,
    // 'risk-conditions-general-facts': IdentificationDetails,
    // 'reports-previous-borrowings': IdentificationDetails,
    "life-insurance-details": LifeInsuranceDetails,
    "inquiry-of-obligations": InquiryOfObligations,
  };

  const sectionIndex = (sectionKey: number) => {
    return "0" + (sectionKey + 1);
  };

  return (
    <>
      {guarantorsArr?.length > 0 ? (
        <div className="full-width full-height">
          <Grid container className="inner-component-height">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {guarantorsArr?.length > 0 &&
                      guarantorsArr?.map((guarantorData, index) => {
                        const name = guarantorData?.personalData?.initialsInFull ? guarantorData?.personalData?.initialsInFull : "";
                        if (guarantorData?.hasOwnProperty("removedItem") && guarantorData?.["removedItem"] === true) return;

                        if (!isRawVersion) return <Tab label={`Guarantor ${sectionIndex(index)} ${name ? " - " + name : ""}`} value={index.toString()} key={index} />;
                      })}
                  </TabList>
                </Box>
                {guarantorsArr?.length > 0 &&
                  guarantorsArr?.map((guarantorData, index) => {
                    return (
                      <TabPanel value={index.toString()} key={index}>
                        {formSections.get()?.map((section: any, i: any) => {
                          const Component = components[section?.key];
                          if (section?.key && section.visible && Component) {
                            return <Component data={{ formData: { ...guarantorData } }} applicationId={applicationId} isMainContactDetails={false} key={i} isGuarantor={true} guarantorJBSection={section?.key} />;
                          }
                        })}
                      </TabPanel>
                    );
                  })}
              </TabContext>
            </Box>
          </Grid>
        </div>
      ) : (
        <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
      )}
    </>
  );
};

export default GuarantorDetails;
