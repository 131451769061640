import React, { createRef, FC, useEffect } from "react";



import { Downgraded, useState } from "@hookstate/core";
import { userDetails } from "../../../configs/mainStore";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import AppConfig from "../../../utility/AppConfig";
import { DocumentUploadSectionWrapper } from "../MainSectionsWrapper/DocumentUploadSectionWrapper";
import { SubSectionsWrapper } from "../SubSectionsWrapper/SubSectionsWrapper";
import { ApplicationDocumentUploadSection } from "./ApplicationDocumentUploadSection";

interface ProductDocumentUploadSubSectionProps {
  sectionObj?: any;
  applicantType?: any;
  applicationId?: any;
  participantId?: any;
  uploadedFiles?: any;
  documentTypes?: any;
  uploadedDocumentTypes?: any;
  documentSectionName?: any;
  mandatoryCount?: any;
  uploadedMandatoryCount?: any;
  documentComponentId?: any;
  personalData?: Object;
  creditFileData?: any;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  refreshComponent?: any;
  reloadComponent?: any;
  isLoggedInUserNotTheAssignee?: any;
  documentCount?: any;
  multipleDisbursementDocument?: any;
}

export const ProductDocumentUploadSubSection: FC<ProductDocumentUploadSubSectionProps> = ({
  applicationId,
  applicantType,
  documentTypes,
  uploadedDocumentTypes,
  documentSectionName,
  mandatoryCount,
  uploadedMandatoryCount,
  participantId,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  reloadComponent,
  isLoggedInUserNotTheAssignee,
  documentCount,
  multipleDisbursementDocument,
}: any) => {
  const userDetailsData = useState(userDetails);
  const { userId }: any = userDetailsData.attach(Downgraded).get();
  const docUploadRef: any = createRef();
  const { documentList }: any = useState(documentUploadData);
  const [sectionTitle, setSectionTitle] = React.useState<any>("");

  const submitBtnClick = async () => {
    const normalDocUploadResult = await docUploadRef?.current?.normalDocumentUploadFunc();
    if (normalDocUploadResult === true) {
      reloadComponent(true);
    }
  };

  useEffect(() => {
    if (personalData) {
      setSectionTitle(
        personalData?.initialsInFull
          ? `${documentSectionName} - ${personalData?.initialsInFull}`
          : documentSectionName
      );
    } else {
      setSectionTitle(documentSectionName);
    }

    if (uploadedDocumentTypes.length) {
      documentList.set(uploadedDocumentTypes);
    }
  });

  useEffect(() => {
    return () => {
      documentList.set([]);
    }
  }, []);

  if (AppConfig.control) {
    disableAccordingToTab = false;
  }

  return (
    <DocumentUploadSectionWrapper
      title={sectionTitle}
      keyCode={1}
      noBack={true}
      loading={false}
      onNext={() => {
        submitBtnClick();
      }}
      totalFiles={documentCount}
      numOfUploadedFiles={uploadedDocumentTypes.length}
      numOfMandatory={mandatoryCount}
      numOfUploadedMandatoryFiles={uploadedMandatoryCount}
      subTxt={
        `Upload ${documentSectionName}`
      }
      documentComponentId={documentComponentId}
      personalData={personalData}
      creditFileData={creditFileData}
      onSyncSuccess={onSyncSuccess}
      onSync={onSync}
      sectionLoading={sectionLoading}
      disableAccordingToTab={disableAccordingToTab}>
      <SubSectionsWrapper title="">
        <ApplicationDocumentUploadSection
          ref={docUploadRef}
          primaryFiles={[]}
          applicantType={applicantType}
          applicationId={applicationId}
          participantId={participantId}
          uploadedBy={userId}
          normalFiles={[]}
          documentTypes={documentTypes}
          uploadedDocumentTypes={uploadedDocumentTypes}
          documentComponentId={documentComponentId}
          disbursed={disbursed}
          disableAccordingToTab={disableAccordingToTab}
          reloadComponent={reloadComponent}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
          creditFileData={creditFileData}
          multipleDisbursementDocument={multipleDisbursementDocument}
        />
      </SubSectionsWrapper>
    </DocumentUploadSectionWrapper>
  );
};
