import { Downgraded, useHookstate } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect } from 'react';
import { createApplicationsHistory } from '../../../../services/applicationHistoryApi';
import { addWorkflowConfigurations, addWorkflowMetaData, getWorkflowDataByWorkflowId } from '../../../../services/workflowApiCalls';
import { ButtonComponent } from '../../../InputComponents/ButtonComponent/ButtonComponent';
import { TextBoxComponent } from '../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

const CloneWorkflowFormContainer = ({ onSave, selectWorkflow, loading }) => {

  if (!selectWorkflow) {
    return <></>;
  }

  const { workflowId, workflowType, descriptions: { EN: { workflowName = "", workflowDescription = "" } = {} } = {} }: any = selectWorkflow.get() || {};

  const cloneData = useHookstate({ description: workflowDescription, name: workflowName });
  const currentWFData = useHookstate({});

  useEffect(() => {
    prepareDataSource();
  }, []);

  const prepareDataSource = async () => {
    try {
      const data = await getWorkflowDataByWorkflowId(workflowId);
      currentWFData.set(data);
    } catch (error) { } finally {
      await clonnableData();
    }
  }


  const buildStep = (wf) => {
    const { stepType, stepIndex, stageId, permission, permissionData, actions, actionArray = [], job } = wf;

    const _actions: any = [];

    for (const actionId of actionArray) {
      const action = actions[actionId];
      _actions.push(action);
    }

    return {
      stepType, stepIndex, stepData: {
        stageId, permission, permissionData,
        permissionList: [],
        actions: _actions
      }
    };
  }

  const clonnableData = async () => {
    const { meta, workflow }: any = currentWFData.attach(Downgraded).get() || {};

    if (!workflow) {
      return;
    }

    if (!meta) {
      return;
    }

    let { data = [],
      metaData = {},
      apiJobMetaData = {}
    }: any = {};

    metaData = meta;

    for (const wf of (workflow || [])) {
      const step = buildStep(wf);
      data.push(step);
    }

    const payload = {
      data,
      workflowId,
      metaData,
      apiJobMetaData
    };

    return payload;
  }

  const onSaveData = async (e, isUpdate) => {
    loading.set(false);
    let res: any = {};
    const workflowData: any = clonnableData();
    const { name, description } = cloneData.attach(Downgraded).get() || {};

    const language = {
      lang: "EN",
      workflowName: name,
      workflowDescription: description,
    };

    const workflow = {
      workflowType: workflowType || 1,
      isActive: true,
      descriptions: [
        ...(["EN", "SI", "TA"].map(l => { return { ...language, lang: l } }))
      ]
    };

    try {
      res = await addWorkflowMetaData(workflow);

      if (res.status === 1) {
        const { workflowId, data, metaData, apiJobMetaData } = workflowData;
        res = await addWorkflowConfigurations(workflowId, data, metaData, apiJobMetaData);
      }

      createApplicationsHistory(0, `created a new workflow from ${workflowId}`, null, { workflowId, name }, "/audit/log/workflow/createworkflow");
    } catch (error) {

    } finally {

    }
    loading.set(false);
  }

  return <Stack>
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Stack>
        <Typography color={"gray"} variant="body1"> {`Clone Workflow`} </Typography>
        <Typography color={"gray"} variant="body2"> {`${workflowName}`} </Typography>
      </Stack>
      <CloseIcon
        style={{ cursor: "pointer" }}
        onClick={() => {
          closeGlobalModal();
        }}
      />
    </Stack>
    <Divider></Divider>

    <Stack height={"300px"} py={2} spacing={2}>
      <Stack>
        <Typography color={"gray"} variant="body2"> {workflowDescription} </Typography>
      </Stack>
      <Stack>
        <Typography color={"gray"} variant="caption">* New Workflow from workflow ({workflowId})</Typography>
        <Stack>
          <Typography color={"gray"} variant="caption">Title</Typography>
          <TextBoxComponent value={cloneData.name.get() || ""} onChange={(e) => cloneData.name.set(e.target.value)}></TextBoxComponent>
        </Stack>
        <Stack mt={2}>
          <Typography color={"gray"} variant="caption">Description</Typography>
          <TextBoxComponent multiline={true} value={cloneData.description.get() || ""} maxRows={4} onChange={(e) => cloneData.description.set(e.target.value)} ></TextBoxComponent>
        </Stack>
      </Stack>
    </Stack>

    <Divider></Divider>
    <Stack direction={"row-reverse"} py={1}>
      <ButtonComponent variant={"contained"} title={"Clone"} onClick={() => { }}></ButtonComponent>
    </Stack>
  </Stack>;
};

export default CloneWorkflowFormContainer;