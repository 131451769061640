import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import TableBodyWrapper from "../../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import {
  TableColumnsTextBoxWrapperRevaamp,
  TableColumnsTextWrapper
} from "../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper";
import { CustomTableHeaderWrapperForBalanceSheet } from "../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import { TableRowWrapper } from "../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableWrapper from "../../../Helpers/TableContainer/TableWrapper/TableWrapper";

interface FinancialPositionOfCompanyGeneralizedTableComponentProps {
  label: string;
  onChange: any;
  defaultObject: any;
  profitAndLossAccountsTitleArray: Array<any>;
  descriptionList: Array<any>;
  isShowGeneralized?: boolean;
  getAllCalculations?: any;
}

const FinancialPositionOfCompanyGeneralizedTableComponent: React.FC<FinancialPositionOfCompanyGeneralizedTableComponentProps> = ({
  label,
  onChange,
  defaultObject,
  profitAndLossAccountsTitleArray,
  descriptionList,
  isShowGeneralized,
  getAllCalculations
}: FinancialPositionOfCompanyGeneralizedTableComponentProps) => {
  const { t: translate } = useTranslation();

  const getAutoPopulatedValue = (year, mainDataKey) => {

    if (!getAllCalculations) return 0;

    else if (!getAllCalculations[mainDataKey]) return 0;

    else if (!getAllCalculations[mainDataKey][year]) return 0;

    else return Number(getAllCalculations[mainDataKey][year]);
  }

  return (
    <Box>
      <TableWrapper>
        <CustomTableHeaderWrapperForBalanceSheet
          firstColTitle={translate(label)}
          dataObject={profitAndLossAccountsTitleArray} />
        <TableBodyWrapper>
          {descriptionList.map((item: any) => {
            return (
              <TableRowWrapper
                key={"TableRowWrapper" + item.title + item.dataKey}
              >
                <TableColumnsTextWrapper
                  key={item.title}
                  index={item.title + item.dataKey}
                >
                  <React.Fragment>{item.title}</React.Fragment>
                </TableColumnsTextWrapper>
                {profitAndLossAccountsTitleArray.map((year) => {
                  if (item.type === "textBox") {
                    return (
                      <TableColumnsTextBoxWrapperRevaamp
                        key={year.dataKey + item.title + item.dataKey}
                        index={year.dataKey}
                        formData={defaultObject}
                        year={year.dataKey}
                        dataKey={item.dataKey}
                        onChange={onChange}
                      />
                    );
                  } else if (item.type === "autoPopulated") {
                    return (
                      <TableColumnsTextWrapper
                        key={year.dataKey + item.title + item.dataKey}
                        index={year.dataKey}
                      >
                        <React.Fragment>{` ${formatCurrency(getAutoPopulatedValue(year.dataKey, item.dataKey))}`}</React.Fragment>
                      </TableColumnsTextWrapper>
                    );
                  }
                  return <></>
                })}
              </TableRowWrapper>
            );
          })}
        </TableBodyWrapper>
      </TableWrapper>
    </Box>
  );
};

export default React.memo(FinancialPositionOfCompanyGeneralizedTableComponent);

