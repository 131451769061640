import { Downgraded } from "@hookstate/core";
import { userDetails } from "../../configs/mainStore";
import { isPrivilegeHave } from "../../services/utilService";

export const adminPermission = (permission: any) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();

  const isPrivilegeNotHave = !isPrivilegeHave(userPrivilege, permission);

  return isPrivilegeNotHave;
};
