import { dateFormatWithDate } from "../../../../../utility/fomatters";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { followUpChecklistStatus } from "../../../../../configs/constants/contants";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { IconRowComponent } from "../IconRowComponent";
import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Divider, Typography } from "@mui/material";
import { Downgraded, useState } from "@hookstate/core";
import {
    creditData,
    workflowMasterData,
} from "../../../../../configs/mainStore";

const DisbursementCheckListRow = ({
    isLoggedInUserNotTheAssignee,
    itemObj,
    statusUpdate,
}) => {
    const [isChecked, setIsChecked] = React.useState<any>(false);

    useEffect(() => {
        itemObj?.status === followUpChecklistStatus.done
            ? setIsChecked(true)
            : setIsChecked(false);
    }, []);

    const InfoRow = () => {
        return (
            <Stack
                direction="row"
                flex={1}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                mt={2}
                justifyContent={"flex-end"}
            >
                <IconRowComponent
                    Icon={isChecked ? DoneIcon : CloseIcon}
                    subTxt={"Status"}
                    mainTxt={isChecked ? "Done" : "Pending"}
                    color={isChecked ? "#39a21a" : "#ff1b1b"}
                />
                <IconRowComponent
                    Icon={InsertInvitationIcon}
                    subTxt={"Created Date"}
                    mainTxt={
                        itemObj?.createdDate
                            ? dateFormatWithDate(itemObj?.createdDate)
                            : "--"
                    }
                    color={isChecked ? "#39a21a" : "#ff1b1b"}
                />
                <IconRowComponent
                    Icon={InsertInvitationIcon}
                    subTxt={"Completed Date"}
                    mainTxt={
                        itemObj?.completedDate
                            ? dateFormatWithDate(itemObj?.completedDate)
                            : "--"
                    }
                    color={isChecked ? "#39a21a" : "#ff1b1b"}
                />
            </Stack>
        );
    };

    const InfoDetailsRow = () => {
        const { wf_system_users }: any = useState(workflowMasterData);
        const renderUser = (userId) => {
            const createdBy = wf_system_users
                ?.get()
                ?.find((usr) => usr?.id == userId);
            return createdBy?.fullName;
        };

        return (
            <Stack
                direction="row"
                flex={1}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={4}
                mt={2}
                justifyContent={"flex-end"}
            >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography color={"grey"} variant={"caption"}>
                        {"Created By:"}
                    </Typography>
                    <Typography variant={"caption"}>
                        {renderUser(itemObj.createdBy)}
                    </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography color={"grey"} variant={"caption"}>
                        {"Created At:"}
                    </Typography>
                    <Typography variant={"caption"}>
                        {itemObj?.createdDate
                            ? dateFormatWithDate(itemObj?.createdDate)
                            : "--"}
                    </Typography>
                </Stack>
                {itemObj?.completedDate && (
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Typography variant={"caption"} color={"grey"}>
                            {"Completed At:"}
                        </Typography>
                        <Typography variant={"caption"}>
                            {itemObj?.completedDate
                                ? dateFormatWithDate(itemObj?.completedDate)
                                : "--"}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        );
    };

    const creditDetailsState: any = useState(creditData);
    const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

    return (
        <Box>
            <Grid
                className="row_list_item"
                style={{
                    boxShadow: `0px 0px 10px ${isChecked ? "#39a21a" : "#ff1b1b"}3d`,
                }}
                container
                direction="column"
                p={2}
            >
                <Grid container direction="row">
                    <Grid container style={{ textAlign: "left" }} alignItems={"center"}>
                        <CheckBoxComponent
                            checked={isChecked}
                            onChange={() => {
                                statusUpdate(itemObj?.id, isChecked, setIsChecked);
                            }}
                            disabled={
                                isLoggedInUserNotTheAssignee
                            }
                        />
                        <Grid flex={1}>
                            <IconRowComponent
                                subTxt={""}
                                mainTxt={itemObj?.itemName}
                                color={isChecked ? "#39a21a" : "#ff1b1b"}
                            />
                        </Grid>

                        <IconRowComponent
                            Icon={isChecked ? DoneIcon : CloseIcon}
                            subTxt={""}
                            mainTxt={isChecked ? "Done" : "Pending"}
                            color={isChecked ? "#39a21a" : "#ff1b1b"}
                        />
                    </Grid>

                    <InfoDetailsRow></InfoDetailsRow>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DisbursementCheckListRow;