import { Box, Stack, Grid } from '@mui/material';
import React from 'react';
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import { closeBtn } from '../../../../../../styles';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';

function LetterConditionStagesModal(params) {
  const applicationId = params?.applicationId;

  const [stages, setStages] = React.useState('');

  const saveCondition = () => {
    if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
      params.callBackOnAction(stages, params?.labelId);
    }
    closeGlobalModal();
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Disbursment Of Facility</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Name"
            component={
              <Grid xs={5} item>
                <TextBoxComponent
                  value={stages}
                  onChange={(e) => {
                    setStages(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            }
            isMandatory={false}
            showErrors={false}
          />
        </Grid>
      </Grid>

      {/*  */}

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveCondition}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
}

export default LetterConditionStagesModal;
