export const errorMessageGenerator = (errorState) => {
  const errorMessage: any[] = [];

  const checkErrorState = (errorState) => {
    const setErrors = (errorState: any) => {
      if (errorState.status.get() === -1) {
        if (!errorMessage.includes(errorState.message.get())) {
          errorMessage.push(errorState.message.get());
        }
      } else if (errorState.status.get() === -2) {
        errorMessage.push(errorState.message.get());
      }
    };

    if (errorState instanceof Array) {
      errorState.forEach((item: any) => {
        setErrors(item);
      });
    } else if (errorState instanceof Object) {
      Object.keys(errorState).forEach((key: any) => {
        checkErrorState(errorState[key]);
      });
    }
  };

  checkErrorState(errorState);
  return errorMessage;
};

export const errorMessageGenerator2 = (errorState) => {
  const errorMessage: any[] = [];

  const checkErrorState = (errorState) => {
    const setErrors = (errorState: any) => {
      if (errorState.status === -1) {
        if (!errorMessage.includes(errorState.message)) {
          errorMessage.push(errorState.message);
        }
      } else if (errorState.status === -2) {
        errorMessage.push(errorState.message);
      }
    };

    if (errorState instanceof Array) {
      errorState.forEach((item: any) => {
        setErrors(item);
      });
    } else if (errorState instanceof Object) {
      Object.keys(errorState).forEach((key: any) => {
        checkErrorState(errorState[key]);
      });
    }
  };

  checkErrorState(errorState);
  return errorMessage;
};

export const errorMessageGenerator3 = (errorState) => {
  const errorMessage: any[] = [];
  if (errorState instanceof Array) {
    errorState.forEach((item: any) => {
      errorMessage.push(item);
    });
  }
  return errorMessage;
};
