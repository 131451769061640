import { useTranslation } from "react-i18next";
import CollateralDetailsBuilder from "./PaperDocDetailsBuilder";

export const IntermediateDetailsSanitizer = ({ collateralData, title }) => {
  const { t: translate } = useTranslation();

  const checkingValidations = () => {
    let conditionResult: boolean = false;
    let intermediateObject: any = {};

    if (collateralData && Object.keys(collateralData).length > 0) {
      Object.keys(collateralData).map((key: any) => {
        if (collateralData[key] && collateralData[key].length > 0) {
          const res = isValidObjectForRender(collateralData[key]);
          if (res.length > 0) {
            conditionResult = true;
            intermediateObject = {
              ...intermediateObject,
              [key]: res,
            };
          }
        }
      });

      return {
        intermediateObject,
        conditionResult,
      };
    } else
      return {
        conditionResult,
      };
  };

  const isValidObjectForRender = (arrayToBeSanitizer: Array<Object>) => {
    const intermediateArray: Array<Object> = [];
    arrayToBeSanitizer
      .filter((obj: any) => obj && Object.keys(obj).length > 0 && obj["removedItem"] !== true)
      .map((object: any) => {
        ["type", "itemIndex", "entryId", "dataId", "inspetionId", "applicationId"].forEach((key: string) => {
          delete object[key];
        });
        if (Object.keys(object).length > 0) {
          intermediateArray.push(object);
        }
      });
    return intermediateArray;
  };

  return <>{checkingValidations() && checkingValidations()?.conditionResult ? <CollateralDetailsBuilder collateralData={checkingValidations()?.intermediateObject} title={translate(title)} /> : <></>}</>;
};

export const collateralDropDownList = {
  pledgeOwnership: "OWNERSHIP",
  pledgeCropType: "CROP_TYPE",
  pledgeDistrict: "DISTRICT",
  pledgeStatementFreq: "STATEMENT_FREQUENCY",
  pledgeCustomerType: "MOVABLE_CUSTOMER_TYPE",
  vhVehicleCatergory: "VEHICLE_CATEGORY",
  vhFuelType: "FUEL_TYPE",
  lpIssuingAuthority: "INSURANCE_COMPANY_TYPE", //
  lpInsuranceAgreementPolicy: "INSURANCE_POLICY_TYPE",
  lpInsuranceDetailsDescription: "INSURANCE_DETAILS_DESCRIPTION", //
  mmDistrict: "DISTRICT",
  mmCondition: "MANUFACTURER_CONDITIONS",
  mmSourceOfPower: "SOURCE_POWER",
  immDistrict: "DISTRICT",
  immProvince: "PROVINCE",
  immCondition: "MANUFACTURER_CONDITIONS",
  immSourceOfPower: "SOURCE_POWER",
  liInsuranceCompanyCode: "INSURANCE_COMPANY_TYPE", //
  propTypeOfMortagage: "TYPE_OF_MORTGAGE",
  propLandMeasurementType: "LAND_MEASUREMENT_TYPE",
  propTypeofRoad: "TYPE_OF_ROAD",
  propDistric: "DISTRICT",
  propProvince: "PROVINCE",
  fdPurposeCode: "LOAN_PURPOSE",
  mmSuppliersDistrict: "DISTRICT",
  immSuppliersDistrict: "DISTRICT",
  vhUseAsset: "USE_OF_ASSET",
};
