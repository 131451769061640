export const userSessionDelete = () => {
  localStorage.removeItem('accessToken');

  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('authenticate');

  sessionStorage.removeItem('session');
  sessionStorage.removeItem('reports');
  sessionStorage.removeItem('stagesOrder');
  sessionStorage.removeItem('currentApplication');
  sessionStorage.removeItem('lastNavigation');
  sessionStorage.removeItem('stagesRoles');

  for (const key in Object.keys(sessionStorage)) {
    try {
      sessionStorage.removeItem(key);
    } catch {
      continue;
    }
  }

  const localStorageKeys = ['appSettings', 'DOC_PATH', 'BASE_PATH', 'version', 'currentApplication'];
  for (const key in Object.keys(localStorage)) {
    if (localStorageKeys.includes(key)) {
      continue;
    }
    try {
      localStorage.removeItem(key);
    } catch {
      continue;
    }
  }

  setTimeout(() => {
    sessionStorage.removeItem('missingPrivilegeAlert');
    window.location.reload();
  }, 1000);
};