import { Divider, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { langDictionary } from "../../../../../configs/languageDictionaryEn";
import { getApplicationScorecardList } from "../../../../../services/creditFileApiCall";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../UserCreditComponents/ErrorMessageComponent";
import AthamaruScoreCardLayout from "./AthamaruScoreCardLayout";
import RetailScoreCardLayout from "./RetailScoreCardLayout";
import ScoreCardMainWrapper from "./ScoreCardMainWrapper";

type ScoreCardResponseViewProps = {
    key?: string;
    label?: string;
    value?: string;
    color?: string;

}
interface ScoreCardResponseProps {
    scoreCardData;
    view: {
        key: string,
        label: string,
        children: ScoreCardResponseViewProps[]
    };
    totalScore: number;
    grade: {
        name: string,
        color: string
    };
    model: string;
}

const ScoreCardLayout = ({ applicationId }) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const [scoreCardData, setScoreCardData] = useState<any>([]);
    const [totalScore, setTotalScore] = useState<any>(null);
    const [grade, setGrade] = useState<any>({});
    const [view, setView] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>(true);
    const [error, setError] = useState<any>("");
    const [isTypeArray, setIsTypeArray] = useState<boolean>(false);
    const [scoreCardResponse, setScoreCardResponse] = useState<ScoreCardResponseProps[]>([] as ScoreCardResponseProps[]);

    useEffect(() => {
        prepareLayout();
    }, []);

    const prepareLayout = async () => {
        try {
            setError("");
            const { data: scoreCardDataResult, status } = await getApplicationScorecardList(applicationId);
            if (!scoreCardDataResult) {
                setIsLoading(false);
                return;
            }

            if (status < 1) {
                setIsLoading(false);
                if (status === -100) {
                    setError("score card product mapping not found.");
                }
                return;
            }

            if (Array.isArray(scoreCardDataResult as ScoreCardResponseProps[])) {
                setIsTypeArray(true);
                setScoreCardResponse(scoreCardDataResult as ScoreCardResponseProps[]);
                return;
            }

            if (Object.keys(scoreCardDataResult).length > 0) {
                const { scoreCardData, view: _view, totalScore, grade } = scoreCardDataResult;
                let array: any = [];

                Object.keys(scoreCardData).map((item) => {
                    array[item] = scoreCardData[item];
                });

                setScoreCardData(array);
                setTotalScore(totalScore);
                setGrade(grade);
                setView(_view);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const ToggelScoreCardLayout = () => {
        if (isTypeArray) {
            return <BuildMultipleScoreCardLayout props={{ scoreCardResponse, isLoading, masterDataStore }} />
        }

        if (!grade.name) {
            return <Stack flex={1} alignItems={"center"} justifyContent={"center"} height={"400px"}>
                <ErrorMessageComponent headMessage={error || "No data available"} errorMessage={""} />
            </Stack>;
        }

        const props = { isLoading, scoreCardData, view, masterDataStore };

        return (
            <ScoreCardMainWrapper grade={grade} totalScore={totalScore}>
                {isProductOf() ? <AthamaruScoreCardLayout {...props} /> : <RetailScoreCardLayout {...props} />}
            </ScoreCardMainWrapper>
        )
    }

    if (isLoading) {
        return <Stack flex={1} alignItems={"center"} justifyContent={"center"} height={"400px"}>
            <CircularProgressComponent
                size={30}
                sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    zIndex: 100,
                }}
            />
        </Stack>;
    }

    return <ToggelScoreCardLayout />;
};

export default ScoreCardLayout;


const BuildMultipleScoreCardLayout = ({ props }) => {
    const [currentSelectedButton, setCurrentSelectedButton] = useState<string>("");
    const dataMap = new Map<string, any>(props.scoreCardResponse.map((scorecard, index) => [scorecard.model, scorecard]));
    const { t: translate } = useTranslation();

    useEffect(() => {
        setCurrentSelectedButton(props.scoreCardResponse[0].model as string);
    }, [])

    return <>
        <Stack direction={"row"} alignItems={"center"} p={2} gap={1} className="data-box">
            {props.scoreCardResponse.length && props.scoreCardResponse.map((scorecard, index) => <Stack flex={1} width={'100%'}>
                <ButtonComponent variant={currentSelectedButton === scorecard.model ? "contained" : "outlined"} onClick={() => setCurrentSelectedButton(scorecard.model as string)} title={translate(langDictionary.scoreCardLayouts?.[scorecard.model] || scorecard.model)} />
            </Stack>)}
        </Stack>
        <Divider />
        {
            Boolean(currentSelectedButton) && dataMap.has(currentSelectedButton) && <BuildSelectedLayout {...{ ...dataMap.get(currentSelectedButton), isLoading: props.isLoading, masterDataStore: props.masterDataStore }} />
        }
    </>
}

const BuildSelectedLayout = ({ isLoading, scoreCardData, view, grade, totalScore, masterDataStore }) => {

    const params = { isLoading, scoreCardData, view, masterDataStore };

    return <ScoreCardMainWrapper grade={grade} totalScore={totalScore}>
        <RetailScoreCardLayout {...params} />
    </ScoreCardMainWrapper>

}