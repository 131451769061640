import { Stack } from "@mui/material";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import QRCode from "react-qr-code";
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

const GenerateQRCode = () => {
  const qrCode = "https://los.xgencredit.com/los/application/?channel=1";
  const componentRef: any = useRef();

  const copy = async () => {
    await navigator.clipboard?.writeText(qrCode);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @media print {
          #Header, #Footer {
            display: none !important;
          }
        }
      `,
  });

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <Stack padding={2} direction={"column"}>
      <style>{getPageMargins()}</style>

      <Stack alignItems={"center"} direction={"column"} spacing={3} ref={componentRef}>
        <QRCode size={300} value={qrCode} />
        <div>{qrCode}</div>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" pt={5}>
        <ButtonComponent startIcon={<ContentCopyIcon />} title="Copy" variant="contained" onClick={copy} loadingbtn={true} color="info" />
        <ButtonComponent
          startIcon={<SaveAltIcon />}
          title="Save"
          variant="contained"
          onClick={() => {
            handlePrint();
          }}
          loadingbtn={true}
          color="info"
        />
        <ButtonComponent title="Close" variant="contained" onClick={() => closeGlobalModal()} loadingbtn={true} color="info" />
      </Stack>
    </Stack>
  );
};

export default GenerateQRCode;
