import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Paper style={{ height: '10px', padding: '0px', margin: '0', color: 'white' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress style={{ height: '10px', backgroundColor: 'white' }} variant="determinate" {...props} />
        </Box>
        {/* <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box> */}
      </Box>
    </Paper>
  );
}
