import { Downgraded, useState } from "@hookstate/core";
import CheckIcon from "@mui/icons-material/CheckCircle";
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from "@mui/icons-material/Sync";
import { Divider, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getCoreBankData, getExtractionMethod, getSCVRecoveryDetails, setCoreBankDataObject } from "../../../../../services/bankServiceInvorker";
import { formatCurrency } from "../../../../../services/currencyFormater";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import LoanRelatedNIC from "../CIF/LoanRelatedNIC";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

const InvestmentAccount = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [selectedAccount, setSelectedAccount]: any = React.useState("");
  const [investmentAmount, setInvestmentAmount]: any = React.useState(0.0);
  const loading: any = useState({
    savedData: true,
    cbsData: true,
    forceRead: false,
    updateDetails: false,
  });
  const editing: any = useState({
    investmentAccount: false,
    standingOrderAmount: false,
  });
  const [refresh, setRefresh] = React.useState(false);
  const [referAccountList, setReferAccountList] = React.useState<any>([]);
  const [individualId, setIndividualId] = React.useState("");
  const [individualIds, setIndividualIds]: any = React.useState([]);
  const creditFileData: any = creditData.attach(Downgraded).get();
  const [coreDataResult, setCoreDataResult]: any = React.useState([]);
  const [selectedNic, setSelectedNic]: any = React.useState("");
  const [selectedCif, setSelectedCif]: any = React.useState("");
  const disbursed = creditFileData.disbursed;
  let actions: any = useState(false);

  useEffect(() => {
    if (data) {
      processData();
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(coreDataResult).length === 0 && refresh === false) {
      return;
    }

    const { choice, INVESTMENT_SEARCH_REF_KEY: nic } = coreDataResult;

    let cbsExtractionMethodValue: any = choice == 1 ? nic : selectedCif;
    let cbsExtractionMethodName: any = choice == 1 ? "NIC" : "CIF";

    loading?.cbsData.set(true);
    getSCVRecoveryDetails(cbsExtractionMethodValue, cbsExtractionMethodName, refresh, "SAVING", applicationId)
      .then((data) => {
        let savings = data?.scvDetails?.savings;
        let tempDropdownList: any = [];
        savings?.map((item) => {
          tempDropdownList.push({
            id: item?.accountNumber,
            name: `${item?.accountNumber} | ${item?.productName}`,
          });
        });

        setReferAccountList(tempDropdownList);
        loading?.cbsData.set(false);
        loading.forceRead.set(false);
        setRefresh(false);
      })
      .catch((err) => {
        setRefresh(false);
      });
  }, [coreDataResult, refresh]);

  const processData = async () => {
    loading.savedData?.set(true);
    let coreResult: any = await Promise.all([getPreferedCBSExtractionMethod(), getCoreBankData(applicationId, [CORE_BANK_KEYS.CIF, CORE_BANK_KEYS.INVESTMENT_ACCOUNT, CORE_BANK_KEYS.INVESTMENT_AMOUNT, CORE_BANK_KEYS.INVESTMENT_SEARCH_REF_KEY].join(","))]);

    const {
      0: choice,
      1: { INVESTMENT_ACCOUNT: investmentAccount = "", INVESTMENT_AMOUNT: investmentAmount, INVESTMENT_SEARCH_REF_KEY: nic, CIF: cifs = {} },
    } = coreResult;
    const cif = cifs[individualId]?.CIF;

    investmentAccount && setSelectedAccount(investmentAccount);
    investmentAmount && setInvestmentAmount(parseFloat(investmentAmount));
    nic && setSelectedNic(nic);
    cif && setSelectedCif(cif);

    if (!investmentAmount) {
      editing.standingOrderAmount.set(true);
    }
    if (!investmentAccount) {
      editing.investmentAccount.set(true);
    }

    let allCifs: any = [];
    let allIndIds: any = individualIds.map(String);
    for (const key of Object.keys(cifs)) {
      if (allIndIds.includes(key)) {
        allCifs.push(cifs[key]);
      }
    }

    coreResult[1] && setCoreDataResult({ choice, ...coreResult[1] });
    loading.savedData?.set(false);
  };

  const getPreferedCBSExtractionMethod = async () => {
    let recoveryMethod: any = "";
    try {
      const response = await getExtractionMethod();
      recoveryMethod = response.investment == "nic" ? 1 : response.investment == "cif" ? 2 : 1;
    } catch (error) {
      recoveryMethod = 2;
    }

    return recoveryMethod;
  };

  const onClickedSend = async () => {
    if (!selectedAccount) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Select an Account",
      });
      return;
    }

    if (!investmentAmount) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Enter the standing order amount",
      });
      return;
    }

    loading.updateDetails.set(true);

    let coreObject = {};
    coreObject[CORE_BANK_KEYS.INVESTMENT_ACCOUNT] = selectedAccount;
    coreObject[CORE_BANK_KEYS.INVESTMENT_AMOUNT] = investmentAmount;
    selectedNic && (coreObject[CORE_BANK_KEYS.INVESTMENT_SEARCH_REF_KEY] = selectedNic);

    const { data } = await setCoreBankDataObject(applicationId, coreObject);
    if (data?.data || data.ID) {
      addToaster({
        status: "success",
        title: "Success",
        message: "Investment Account and Amount updated.",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to update.",
      });
    }

    loading.updateDetails.set(false);

    editing.standingOrderAmount.set(false);
    editing.investmentAccount.set(false);
  };

  const checkPrivilegeStatus = (key = "") => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      if (key != "") {
        isDisabled = getIsDisbursed(privilegeKeys[key], disbursed) || isLoggedInUserNotTheAssignee;
      } else {
        isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
      }
    }
    return isDisabled;
  };

  const onChangeHandler = async () => {
    actions.set(true);
  };

  if (loading.savedData.get()) {
    return (
      <div className="full-width full-height">
        <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} disabled={isLoggedInUserNotTheAssignee} />
        <Stack height={"80%"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgressComponent size={30} />
        </Stack>
      </div>
    );
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} disabled={isLoggedInUserNotTheAssignee} />
      <Stack direction={"column"} mx={2} mt={2} spacing={4}>
        <LoanRelatedNIC applicationId={applicationId} data={{ ...data, ...currentApplicationWfData }} setReferAccountList={setReferAccountList} accountsLoader={loading.forceRead} actions={actions} />
      </Stack>

      <Divider style={{ marginTop: "10pt", marginBottom: "10pt" }}></Divider>

      <Stack direction={{ md: "column", lg: "row" }} mx={2} p={2} mt={2} style={{ background: "white" }} spacing={{ sm: 2, md: 4, lg: 8 }}>
        <Stack spacing={4} width={"280pt"}>
          <Stack direction={"column"} spacing={2}>
            <Typography variant="subtitle2" color={"gray"}>Investment Account Number</Typography>
            <Stack direction={"row"} spacing={2}>
              <ChipsComponent label={<Typography mx={2}>
                {selectedAccount || "N/A"}
              </Typography>}></ChipsComponent>
              <IconButton
                size="small"
                color="primary"
                children={<EditIcon style={{ fontSize: "12pt" }} />}
                onClick={() => {
                  editing.investmentAccount.set(prev => !prev);
                }}
                style={{ display: !editing.investmentAccount.get() ? 'flex' : 'none' }}
              />
            </Stack>
          </Stack>

          <Divider style={{ display: editing.investmentAccount.get() ? 'flex' : 'none' }}></Divider>

          <Stack direction={"row"} alignItems={"center"} spacing={2} mt={2} style={{ display: editing.investmentAccount.get() ? 'flex' : 'none' }}>
            <SelectComponent
              label={"Select Investment Account"}
              defaultPlaceholder="Select Account"
              value={selectedAccount}
              values={referAccountList}
              onChange={(e) => {
                setSelectedAccount(e.target.value);
              }}
              disabled={loading.forceRead.get() || checkPrivilegeStatus()}
            />
            {loading.forceRead.get() || loading.cbsData.get() ? (
              <CircularProgressComponent size={20} />
            ) : (
              <IconButton
                size="small"
                color="primary"
                children={<SyncIcon />}
                onClick={() => {
                  setRefresh(true);
                  loading.forceRead.set(true);
                  onChangeHandler();
                }}
                disabled={checkPrivilegeStatus() === true}
              />
            )}

            <CheckIcon style={{ cursor: "pointer", color: "gray" }} fontSize="small" onClick={() => {
              editing.investmentAccount.set(prev => !prev);
            }} />
          </Stack>
        </Stack>

        <Stack spacing={4}>
          <Stack direction={"column"} spacing={2}>
            <Typography variant="subtitle2" color={"gray"}>Standing Order Amount</Typography>
            <Stack direction={"row"} spacing={2}>
              <ChipsComponent label={<Typography mx={2}>
                {formatCurrency(investmentAmount, 2)}{" "}
              </Typography>}></ChipsComponent>
              <IconButton
                size="small"
                color="primary"
                children={<EditIcon style={{ fontSize: "12pt" }} />}
                onClick={() => {
                  editing.standingOrderAmount.set(prev => !prev);
                }}
                style={{ display: !editing.standingOrderAmount.get() ? 'flex' : 'none' }}
              />
            </Stack>
          </Stack>

          <Divider style={{ display: editing.standingOrderAmount.get() ? 'flex' : 'none' }}></Divider>

          <Stack flex={1} direction={"row"} style={{ display: editing.standingOrderAmount.get() ? 'flex' : 'none' }} alignItems={"center"} spacing={2}>
            <NumericFormat
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={investmentAmount}
              decimalScale={2}
              allowLeadingZeros={false}
              allowNegative={false}
              required={true}
              fullWidth={false}
              onValueChange={(e: any) => {
                const { value } = e;
                setInvestmentAmount(parseFloat(value));
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
              }}
              disabled={checkPrivilegeStatus()}
            />
            <CheckIcon style={{ cursor: "pointer", color: "gray" }} fontSize="small" onClick={() => {
              editing.standingOrderAmount.set(prev => !prev);
            }} />
          </Stack>
        </Stack>
      </Stack>

      <Divider style={{ marginTop: "10pt", marginBottom: "10pt" }}></Divider>

      <Stack direction={"row-reverse"} pt={2} px={2}>
        {loading.updateDetails.get() ? <CircularProgressComponent size={20} /> :
          <ButtonComponent
            title={"Save"}
            variant="contained"
            onClick={onClickedSend}
            style={{
              maxHeight: "40px",
            }}
            disabled={checkPrivilegeStatus("INVESTMENT_ACCOUNT_SUBMIT")}
          />}
      </Stack>
    </div >
  );
};

export default InvestmentAccount;
