import { Downgraded } from "@hookstate/core";
import { openGlobalModal } from "../../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../../GlobalToast";
import { TableRowWrapper } from "../../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableColumnsTextWrapperAnyNew from "../../../../Helpers/inputHelpers/TableColumnsTextWrapperAnyNew";
import TableColumnsTextBoxWrapperNumberNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperNumberNew";
import TableColumnsTextBoxWrapperAlphanumericNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperAlphanumericNew";
import TableColumnsDatePickerWrapperNew from "../../../../Helpers/inputHelpers/TableColumnsDatePickerWrapperNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";

const BankGuaranteeDetailsTableBuildRow = ({ data, callBackOnDelete }) => {
  const dataKeys: any[] = Object.keys(data) || [];

  const dataObj = data.attach(Downgraded).get();
  const onchangeHandler = (value, dataKey, dataIndex) => {
    dataObj[dataIndex][dataKey] = value;
    data?.set(dataObj);
  };

  const itemRowDeleteDispatcher = (key: any, type = "") => {
    if (key) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete the row item!.",
          callBackOnAction: () => callBackOnDelete(key, type),
        },
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty Item Key",
        message: "Item key is required to proceed the deletion",
      });
    }
  };

  return (
    <>
      {dataKeys?.length > 0 &&
        dataKeys?.map((key, index) => {
          const { financialInstitute, amount, issuedDate, timePeriodMonths, issuedCompany } = data[key];

          return (
            <>
              <TableRowWrapper>
                <TableColumnsTextWrapperAnyNew index={index + "financialInstitute"} dataKey={"financialInstitute"} dataIndex={key} onchangeHandler={onchangeHandler}>
                  <>{financialInstitute?.get()}</>
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsTextBoxWrapperNumberNew key={index + "amount"} value={amount} index={index + "amount"} title={""} dataKey={"amount"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsDatePickerWrapperNew key={index + "issuedDate"} value={issuedDate} index={index + "issuedDate"} title={""} dataKey={"issuedDate"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "timePeriodMonths"} value={timePeriodMonths} index={index + "timePeriodMonths"} title={""} dataKey={"timePeriodMonths"} dataIndex={key} onchangeHandler={onchangeHandler} isTypeCurrency={false} />
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "issuedCompany"} value={issuedCompany} index={index + "issuedCompany"} title={""} dataKey={"issuedCompany"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextWrapperAnyNew key={index + "actions"} index={index + "actions"} style={{ textAlign: "right" }}>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => itemRowDeleteDispatcher(key, "guaranteeDetailsBank")}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableColumnsTextWrapperAnyNew>
              </TableRowWrapper>
            </>
          );
        })}
    </>
  );
};

export default BankGuaranteeDetailsTableBuildRow;
