import { Downgraded, useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { getNoteList } from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import NoteItemRow from "./NoteItemRow";

import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";

const ApplicationNote = ({ data, applicationId, currentApplicationWfData, innerComponentData }) => {
  const noteList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getNoteList(applicationId);
        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "noteId"));
          noteList.set(sortedList);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const callBackOnAction = (newNote) => {
    const notes = JSON.parse(JSON.stringify(noteList.get()));
    const sortedList = [...notes, newNote]?.sort((a, b) => compare(b, a, "noteId"));

    noteList.set(sortedList);
  };

  const handleNoteDeleted = (deletedNoteId) => {
    let list: any = noteList.attach(Downgraded).get();
    list = list.filter(note => note.noteId !== deletedNoteId);
    if (list.length > 0) {
      noteList.set(list);
    } else {
      noteList.set([]);
    }
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent modalSize={'lg'} innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={callBackOnAction} disabled={getIsDisbursed(privilegeKeys.NOTE_TAB_CREATE_NOTE, disbursed) || isLoggedInUserNotTheAssignee} />
      <Grid container className="inner-component-height" style={{ textAlign: "left" }}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : noteList && noteList.length > 0 ? (
            noteList.get().map((data, index) => <NoteItemRow data={data} key={data?.noteId || index} onNoteDeleted={handleNoteDeleted} />)
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicationNote;