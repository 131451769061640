import { incomeExpenseFrequency } from "../../../../../../../../utility/util";

export const typeOfStock = [
    {
        id: 1,
        name: "Raw Materials",
    },
    {
        id: 2,
        name: "Work in Progress",
    },
    {
        id: 3,
        name: "Finished Goods",
    },

];

export const periods = [
    {
        id: 1,
        name: "Below 3 Months",
    },
    {
        id: 2,
        name: "3 to 6 Months ",
    },
    {
        id: 3,
        name: "Above 6 Months",
    },
];

export const employeeType = [
    {
        id: 1,
        name: "Managiaral",
    },
    {
        id: 2,
        name: "Supervisory",
    },
    {
        id: 3,
        name: "Skilled",
    },
    {
        id: 4,
        name: "Unskilled",
    },
];

export const sourceOfIncomeForInspectionBusiness = [
    {
        id: 1,
        name: "Salary"
    },
    {
        id: 2,
        name: "Pension"
    },
    {
        id: 3,
        name: "Business Income"
    },

    {
        id: 4,
        name: "Cultivation Income"
    },
]