import React, { useEffect } from "react";
import { Box, Grid, List, ListItem, ListItemButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { userListStore } from "../../../configs/stores/userStore";
import SearchComponent from "../../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";
import { Downgraded, useState } from "@hookstate/core";
import { CheckBoxComponent } from "../../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { getUserGroupList, getUsersByGroupId } from "../../../services/userPrivilegeService";
import { appSetting, workflowMasterData } from "../../../configs/mainStore";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { fetchPrivilegeGroups, savePrivilegeGroupsById } from "../../../services/configurationApis";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { addToaster } from "../../../components/OtherComponents/GlobalToast";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { openGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { createApplicationsHistory } from "../../../services/applicationHistoryApi";

function PrivilegeGroups({ user, willAssignGroups }) {
  const searchText = useState("");
  const userListData = useState(userListStore);
  const appSettings: any = useState(appSetting);
  const filteredPrivilegeGroups: any = useState([]);
  const { userGroupList }: any = useState(userListStore);

  const updating: any = useState(-1);
  const loading: any = useState("");
  const saving: any = useState(false);

  const [activePrivilegeGroupsArr, setactivePrivilegeGroupsArr] = React.useState<any>([]);
  const privilegeGroup: any = userListData.userGroupList.attach(Downgraded).get();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const selectedUserObject: any = useState("");

  const processSearchText = (text) => {
    searchText.set(text);
  };
  let checkedPrivilegeGroups: any = [];

  useEffect(() => {
    filteredPrivilegeGroups.get().forEach((element) => {
      checkedPrivilegeGroups.push(element);
    });
    if (checkedPrivilegeGroups.length > 0) {
      setactivePrivilegeGroupsArr(checkedPrivilegeGroups);
    }
  }, []);

  useEffect(() => {
    fetchPrivilegeGroupsList();
  }, [user]);

  const updateActivePrivilegeGroups = (status, id) => {
    let privilegeGroups: any = [];
    if (activePrivilegeGroupsArr.includes(id)) {
      privilegeGroups = activePrivilegeGroupsArr.filter((p) => parseInt(p) !== id);
    } else {
      privilegeGroups = [...activePrivilegeGroupsArr, id];
    }
    setactivePrivilegeGroupsArr(privilegeGroups);
  };

  const handleSave = async () => {
    if (willAssignGroups) {
      const proceed = await willAssignGroups(activePrivilegeGroupsArr, saving);
      if (proceed === false) {
        return;
      }
    }

    let obj = {
      userId: user,
      groupIds: activePrivilegeGroupsArr,
    };

    if (!user) {
      addToaster({
        status: "error",
        title: "Validation Error",
        message: "User Id not found!",
      });
      return;
    }

    try {
      saving.set(true);
      const res = await savePrivilegeGroupsById(obj);
      if (res?.data.length > 0) {
        addToaster({
          status: "success",
          title: "Updated",
          message: `successfully updated !`,
        });
        
      } else {
        addToaster({
          status: "warning",
          title: "No Changes",
          message: "Do not have changes to update!",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to update permitted branches.",
      });
    } finally {
      saving.set(false);
    }
  };

  const fetchPrivilegeGroupsList = async () => {
    let data;
    setactivePrivilegeGroupsArr([]);

    if (Object.values(userGroupList.get()).length <= 0) {
      data = await getUserGroupList(appSettings.get().language);
    }

    if (data) {
      userGroupList.set(data);
    }

    extractPrivileges(user);
  };

  async function extractPrivileges(index: any) {
    loading.set(true);
    const privilegeGroupData = await fetchPrivilegeGroups(index);

    const privilegeGroupDataResponse = privilegeGroupData?.data ?? [];
    let checkedPrivilegeGroupshIds: any = [];
    if (privilegeGroupDataResponse && privilegeGroupDataResponse.length > 0) {
      privilegeGroupDataResponse.forEach((item) => {
        checkedPrivilegeGroupshIds.push(item.groupId);
      });
    }

    if (checkedPrivilegeGroupshIds.length > 0) {
      setactivePrivilegeGroupsArr(checkedPrivilegeGroupshIds);
    }
    loading.set(false);
  }

  const buildName = (u) => {
    let name = u.name ?? u.name ?? u.name ?? "N/A";
    name = name.replaceAll("undefined", "").replaceAll("null", "");
    if (!name) {
      name = u.id;
    }
    return name;
  };

  const handleListItemClick = async (index) => {
    setSelectedIndex(index);
    try {
      updating.set(index);
      let userListData = await getUsersByGroupId(index);
      let filteredArray = Object.keys(userListData)
        .filter((key) => key !== "0")
        .map((key) => parseInt(key));
      return filteredArray;
    } catch (error) {
    } finally {
      updating.set(-1);
    }
  };
  const viewUsers = async (modalBodyKey, group) => {
    const list = await handleListItemClick(group.id);
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        list,
        group,
      },
    });
  };

  return (
    <>
      <Paper elevation={0}>
        <Box p={1}>
          <Grid container className="align-center" columns={8} pl={1} pr={1}>
            <Grid item xs={8} sm={8} className="align-center">
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack justifyContent="flex-start" direction="row">
                  <Stack direction="column" alignItems="flex-start">
                    <Typography color="gray">Privilage Groups</Typography>
                    <Typography variant="caption" color="gray">
                      {`Allocated Groups (${activePrivilegeGroupsArr.length}/${privilegeGroup.length})`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Box
                    className="relative w-[200px] h-[40px] items-center justify-center basic-font basic-font-color font-size-14 align-left"
                    style={{ marginTop: "15px", marginBottom: "18px" }}
                  ></Box>
                  {saving.get() ? (
                    <CircularProgressComponent size={25} />
                  ) : (
                    <ButtonComponent
                      disabled={false}
                      variant="contained"
                      loadingbtn={false}
                      loading={false}
                      onClick={handleSave}
                      title={"Save"}
                    />
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} marginTop={-2}>
          <div className="relative w-[200px] h-[40px] items-center justify-center">
            <div>
              <Box m={2}>
                <SearchComponent placeholder={undefined} handleChangeSearchText={processSearchText} />
              </Box>
            </div>
          </div>

          {loading.get() ? (
            <Box sx={{ height: "200px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <CircularProgressComponent size={25} />
            </Box>
          ) : (
            <List component="nav">
              <div
                className="basic-font basic-font-color font-size-15 font-weight-100"
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  overflowX: "hidden",
                  overflowY: "scroll",
                  maxHeight: "calc(100vh - 350px)",
                }}
              >
                {privilegeGroup?.length > 0 &&
                  privilegeGroup
                    .filter((p: any) => p.name.toLowerCase().includes(searchText.get()) || p?.id?.toString().includes(searchText.get()))
                    .reduce((objs, element) => {
                      if (activePrivilegeGroupsArr.includes(element.id)) {
                        return [element, ...objs];
                      }
                      return [...objs, element];
                    }, [])
                    .map((p: any, index) => (
                      <ListItem divider key={index}>
                        <ListItemButton
                          selected={selectedIndex === p.id}
                          onClick={(event) => {
                            selectedUserObject.set(buildName(p));
                            handleListItemClick(p.id);
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid item xs={12}>
                                <Box
                                  m={1}
                                  mb={1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    alignSelf="center"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <CheckBoxComponent
                                      checked={activePrivilegeGroupsArr.includes(parseInt(p.id)) ? true : false}
                                      onChange={(e) => updateActivePrivilegeGroups(e.target.checked, parseInt(p.id))}
                                    />
                                    <Grid
                                      item
                                      xs={12}
                                      alignSelf="center"
                                      style={{
                                        display: "flex-start",
                                      }}
                                    >
                                      <Typography variant="body1" fontWeight={"100"}>
                                        {p.name}
                                      </Typography>
                                      <Typography variant="caption">Group Id: {p.id}</Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      {updating.get() === p.id ? (
                                        <CircularProgressComponent size={16} />
                                      ) : (
                                        <Tooltip title="Add User">
                                          <GroupAddIcon
                                            className="icon-basic basic-font-color"
                                            onClick={() => {
                                              viewUsers("user-configeration-modal", p);
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                    ))}
              </div>
            </List>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default PrivilegeGroups;
