import { createState } from '@hookstate/core';

const DocumentUploadData = {
  documentDetailsList: {},
  currentProductDocumentList: {},
  previewedDocument: {},
  documentList: [],
};

export const documentUploadData = createState(DocumentUploadData);
