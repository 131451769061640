import SendIcon from '@mui/icons-material/Send';
import { Box, Grid, Typography } from '@mui/material';
import contentSearch from '../../images/content-search.png';
import { ButtonComponent } from '../InputComponents/ButtonComponent/ButtonComponent';
interface ErrorMessageComponentProps {
  headMessage: any;
  errorMessage: any;
  btnText?: any;
  onClick?: any;
  isLoading?: any;
  showImg?: boolean;
}
export const ErrorMessageComponent = ({
  headMessage,
  errorMessage,
  btnText,
  onClick,
  isLoading,
  showImg = true,
}: ErrorMessageComponentProps) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      p={3}
    >
      <Box mt={5}>{showImg && <img src={contentSearch} width="100px" height="auto" />}</Box>
      <Typography variant='subtitle1' color={'gray'}> {headMessage} </Typography>
      <Typography variant='body2' color={'gray'}> {errorMessage} </Typography>
      {onClick && (
        <Box className="online-app-applicant-new-lead-button-div">
          <ButtonComponent
            startIcon={<SendIcon />}
            // endIcon={<SendIcon />}
            title={btnText ? btnText : 'Click'}
            variant="contained"
            onClick={() => {
              onClick();
            }}
            disabled={isLoading}
            loadingbtn={true}
            loading={isLoading}
          />
        </Box>
      )}
    </Grid>
  );
};
