import { Grid, Stack, Typography } from "@mui/material";

const ViewReferralNoteModal = ({ data }) => {


    return (
        <Grid container maxHeight={"calc(100vh - 200px)"} sx={{ overflowY: "auto" }}>
            <Stack spacing={1} m={1} className="full-width">
                <Typography variant="subtitle1" color={"grey"} sx={{ whiteSpace: "pre-wrap" }}>{data?.note}</Typography>
            </Stack>
        </Grid>
    );
};

export default ViewReferralNoteModal;
