import { sanitizingMandatoryFields, validationHandler, validationSmartHandler } from "../Helpers/ValidationExamingPlot";

export const cultivationModel = (structure, state) => {
  let errorArray: Array<string> = [];
  let titleErrorArray: string[] = [];

  if (Array.isArray(structure)) {
    structure = sanitizingMandatoryFields(structure);

    const res = validationHandler(structure, state, errorArray, titleErrorArray);
    titleErrorArray = res.titleErrorArray;
  }

  return titleErrorArray;
};
