import { Downgraded } from "@hookstate/core";
import { addWorkflowMetaData, updateWorkflowMetaData } from "../../../../services/workflowApiCalls";
import { languages } from "../../../../utility/helpers/creditFileUtility";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { Toast } from "../../GlobalToast";
import CreateWorkflowFormLayout from "./CreateWorkflowFormLayout";

const CreateWorkflowFormContainer = ({ onSave, selectWorkflow, loading }) => {
  const update = !!(selectWorkflow.get ? selectWorkflow?.get()?.id : (selectWorkflow?.id || 0));

  const onSaveData = async (e, isUpdate) => {
    try {
      const { descriptions = {}, isActive = 1, workflowId, workflowType } = selectWorkflow.get ? selectWorkflow.get() : selectWorkflow || {};

      let res;
      loading.set(true);
      if (update) {
        res = await updateWorkflowMetaData({
          isActive: isActive,
          descriptions,
          workflowId,
          workflowType
        });
      } else {
        res = await addWorkflowMetaData({
          isActive: isActive,
          descriptions,
          workflowType
        });
      }

      if (res.status) Toast.success(descriptions?.EN?.workflowName + " workflow created successfully.");

      onSave();

      closeGlobalModal();
    } catch (error) {
      Toast.error(`Failed to create workflow`);
    } finally {
      loading.set(false);
    }
  };

  return <CreateWorkflowFormLayout selectData={selectWorkflow} onSave={onSaveData} />;
};

export default CreateWorkflowFormContainer;

export const CreateWorkflowModal = ({ onSaved, selectWorkflow, loading }) => {
  let data: any = { descriptions: {} };

  let wfData: any = {
    descriptions: {}
  };

  if (selectWorkflow?.get) {
    wfData = selectWorkflow?.attach(Downgraded)?.get();
    const languagesArray: any = languages();

    let obj: any = {};
    languagesArray?.forEach((langData) => {
      const dataObject = wfData?.descriptions?.[langData] ? { ...wfData.descriptions[langData] } : { workflowName: "", workflowDescription: "" };
      obj[langData] = dataObject;
    });

    data = { descriptions: obj, ...wfData };
  } else {
    data.descriptions.EN = { workflowName: "", workflowDescription: "" }
  }

  return <CreateWorkflowFormContainer onSave={onSaved} selectWorkflow={data} loading={loading} ></CreateWorkflowFormContainer>
}
