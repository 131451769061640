import { Downgraded, useState } from "@hookstate/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { RISK_DOCUMENT_ID } from "../../../../configs/constants/contants";
import { userDetails, workflowMasterData } from "../../../../configs/mainStore";
import {
  getSubFlowNextAction,
  subFlowReferralReverts,
  updateSubFlowReferral
} from "../../../../services/creditFileApiCall";
import { getWorkflowStageById } from "../../../../services/workflowServices";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../CommunicationService/CMTextInput";
import { FileUploadComponent } from "../../DropzoneComponent/FileUploadComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";
import CircularProgress from "./CreditInspection/FormBuilder/Tools/Widgets/CircularProgress";

const SubWorkflowChangesValidation = (props) => {
  const { data, isRevert, revertStages, action, callBackOnAction, documentUploadApi } = props
  const { wf_system_users, wf_users, wf_groups, wf_hierarchies }: any = useState(workflowMasterData);
  const [onValidate, setOnValidate] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<any>({
    stage: "",
    comment: "",
  });
  const [selectedGroup, setSelectedGroup] = React.useState("-");
  const [nextWFStage, setNextWFStage] = React.useState<any>({});
  const [dropdownList, setDropdownList] = React.useState([]);
  const [revertStage, setRevertStage] = React.useState();
  const [revertedStages, setRevertedStages] = React.useState<any>([]);

  const [assignedGroups, setAssignedGroups] = React.useState<any>(data?.assignedGroups ?? null);
  const [assignedHierarchy, setAssignedHierarchy] = React.useState<any>(data?.assignedHierarchy ?? null);
  const [assignedUsers, setAssignedUsers] = React.useState<any>(data?.assignedUsers ?? null);
  const [refresh, setRefresh] = React.useState(false);
  const [historySeqId, setHistorySeqId] = React.useState<any>(null);
  const [revertNextWorkflowAction, setRevertNextWorkflowAction] = React.useState<any>(null)

  const [actionWFData, setActionWFData] = React.useState<any>(data?.wfData);

  const _groups = wf_groups.attach(Downgraded).get();

  const docUploadRef: any = createRef();

  const [fileList, setFileList] = React.useState<any>([
    {
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const loginUser = useState(userDetails);

  useEffect(() => {
    setOnValidate(true);

    (async function () {
      const _getSubFlowNextAction: any = await getSubFlowNextAction({
        currentStage: data?.workflowStage,
        subWorkflowId: data?.subWorkflowId,
        action: action
      });

      if (_getSubFlowNextAction?.permission === 2) {
        const selectedGroup = _groups
          .filter((obj) => _getSubFlowNextAction?.permissionData.includes(obj.id))
          .map((item) => item.name)
          .join(", ");

        setSelectedGroup(selectedGroup);
      }

      setNextWFStage(_getSubFlowNextAction);
    })();

    if (isRevert === true) {
      (async function () {
        const params = {
          applicationId: data?.applicationId,
          id: data?.id,
        };
        const { data: revertStages } = await subFlowReferralReverts(params);

        const revertList: any = [];

        for (const item in revertStages) {
          const revertItem = revertStages[item];

          if (data.workflowStage !== revertItem.stageId) {
            const stageData = getWorkflowStageById(revertItem.stageId);

            revertList.push({ id: revertItem.stageId, name: stageData?.name });
          }
        }

        const tempObj = {};
        const uniqueData = revertList.filter((item) => {
          if (!tempObj[item.id]) {
            tempObj[item.id] = true;
            return true;
          }
          return false;
        });

        setDropdownList(uniqueData);
        setRevertedStages(revertStages ?? []);
      })();
    }

    setOnValidate(false);
  }, [isRevert]);

  const onChangeField = (value) => {
    setComment(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ comment: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ comment: "" } });
    }
  };

  const onSubmit = async () => {
    const _actionArray = actionWFData?.actionArray;

    let filteredActions: any = actionWFData?.actions[action];

    const _stage = isRevert ? nextWFStage?.stageId : nextWFStage?.nextStage;
    const _nextWorkflowStep = isRevert ? revertNextWorkflowAction : filteredActions?.nextWorkflowStepId;

    const params = {
      id: data?.id,
      stage: _stage,
      comment: comment,
      applicationId: data?.applicationId,
      permissionGroup: nextWFStage?.permission === 2 ? nextWFStage?.permissionData.join(", ") : "",
      permissionUser: nextWFStage?.permission === 3 ? nextWFStage?.permissionData : "",
      wfid: data?.workflowId,
      nextWorkflowStep: _nextWorkflowStep,
      stepIndex: actionWFData?.stepIndex,
      stepType: actionWFData?.stepType,
      currentStage: actionWFData?.stageId,
      action: action,
      assignedGroups: assignedGroups ?? null,
      assignedHierarchy: assignedHierarchy ?? null,
      assignedUsers: assignedUsers ?? null,
      actionedBy: loginUser?.userId?.get(),
    };

    const _update = await updateSubFlowReferral(params);

    if (_update?.status !== 1) {
      addToaster({
        status: "error",
        title: "Empty",
        message: _update?.data?.message,
      });

      closeGlobalModal();
    }

    const _historySeqId = _update?.data?.id;

    setHistorySeqId(_historySeqId);

    const promises: any = [];

    if (fileList?.length > 0) {
      fileList.map((item: any) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });
    }

    return await Promise.all(promises).then((res) => {
      if (callBackOnAction && callBackOnAction instanceof Function) {
        callBackOnAction(true);
      }

      closeGlobalModal();

      return true;
    });
  };

  const onClickAddBtn = () => {
    let tempFileList = fileList;
    tempFileList.push({
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: false,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  const onChangeStage = async (e) => {
    setRevertStage(e.target.value);

    const selectStageData = revertedStages.find((item) => item.stageId === e.target.value);

    if (selectStageData?.permission === 2) {
      setAssignedGroups(selectStageData.permissionData.join(", "));

      const selectedGroup = _groups
        .filter((obj) => selectStageData?.permissionData.includes(obj.id))
        .map((item) => item.name)
        .join(", ");

      setSelectedGroup(selectedGroup);
    }

    setRevertNextWorkflowAction(selectStageData?.stepIndex);
    setNextWFStage(selectStageData);
    setActionWFData(selectStageData);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-14 basic-font-color-bold">{`Stages Action`}</div>
      </Stack>

      {onValidate ? (
        <Box m={2} display="flex" alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Box ml={2}>Validation in progress for workflow transition....</Box>
        </Box>
      ) : (
        <Grid container spacing={1} mt={1} rowSpacing={1} className="modal-text-field">
          {isRevert && (
            <Grid item xs={12} md={12} className="">
              <SelectComponent
                label={"Revert Stage"}
                value={revertStage}
                values={dropdownList}
                onChange={(e) => {
                  onChangeStage(e);
                }}
                required={isRevert}
              // disabled={isLoading}
              />
              {errorMessage?.stage !== "" && (
                <Box>
                  <span className="error font-size-14">{errorMessage?.stage}</span>
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} className="">
            <CMTextInput
              desc={"Comment*"}
              component={
                <TextBoxComponent
                  onChange={(e) => {
                    onChangeField(e.target.value);
                  }}
                  multiline={true}
                  minRows={6}
                  value={comment}
                // disabled={isLoading}
                />
              }
              error={errorMessage?.comment !== "" ? true : false}
            />
            <Typography>Group - {selectedGroup}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} md={12} className="">
        <Grid
          container
          item
          // spacing={1}
          direction="row"
          sm={12}
        >
          <div
            className="basic-font basic-font-color font-size-16 font-weight-100"
            style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}
          >
            Upload File
          </div>
          <Grid item sm={4}>
            <AddCircleIcon
              style={{ color: "gray", fontSize: 25, cursor: "pointer" }}
              onClick={() => {
                onClickAddBtn();
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {fileList.map((item, i) => {
        return (
          <FileUploadComponent
            fileDetails={item}
            uploadDataObject={{
              applicationId: data?.applicationId,
              docIndex: i,
              documentId: RISK_DOCUMENT_ID,
              title: "",
              desc: comment ?? "",
              workflowId: data?.workflowId,
              stageId: data?.workflowStage,
              subFlowId: data?.id,
              createdBy: loginUser?.userId?.get(),
            }}
            isPreviewHave={false}
            fileUploadApi={documentUploadApi}
            referralSeqId={historySeqId}
          />
        );
      })}

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          //   disabled={isLoading}
          loadingbtn={true}
          //   loading={isLoading}
          color="error"
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          //   disabled={isLoading}
          loadingbtn={true}
          //   loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default SubWorkflowChangesValidation;
