import React, { useEffect, useMemo } from "react";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { langDictionary } from "../../../../../../../../configs/languageDictionaryEn";
import { getBankListWithoutRDB } from "../../../../../../../../utility/helpers/getBankList";

const BankSelect = (props) => {
  const { callBackOnChange, selectedValue, type } = props;
  let bankList: any = getBankListWithoutRDB();
  const [selectedBank, setSelectedBank]: any = React.useState("");
  let overrideBanksList = {};

  Object.values(bankList).filter((bank: any, index) => {
    if (bank["code"] !== "noOtherBankAcc") {
      overrideBanksList = {
        ...overrideBanksList,
        [index]: bank
      }
    }
  })

  useEffect(() => {
    setSelectedBank(selectedValue);
  }, [selectedValue]);

  return (
    <>
      <AutoCompleteProxyComponent
        label={langDictionary?.bankName}
        value={selectedBank}
        fullWidth={true}
        required={false}
        options={overrideBanksList}
        onChange={(e) => {
          callBackOnChange(e?.id, type);
        }}
        cls="select_basic"
      />
    </>
  );
};

export default BankSelect;
