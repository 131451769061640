import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip, Divider, List, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { getProductPredisbursementCategories } from "../../../../../../services/creditFileApiCall";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import { useHookstate } from "@hookstate/core";

function BuildConditionItem({ condition }) {
  if (!condition.PLACEHOLDERS) {
    return <div>{`${condition.DESCRIPTION} `}</div>;
  }

  const keys = Object.keys(JSON.parse(condition.PLACEHOLDERS ?? "{}"));
  const segments = condition?.F_DESC.split(" ");

  return (
    <div>
      {segments.map((obj) => {
        if (!obj) return "";
        let fob = obj.trim().toLowerCase();
        if (keys.includes(fob)) {
          const text = `${fob.replaceAll("__", "").toUpperCase().trim()}`;
          return <Chip size="small" label={text} sx={{ fontSize: "11px", marginRight: "2px" }} />;
        }

        return <span>{`${obj} `}</span>;
      })}
    </div>
  );
}

export default function ApprovingConditionList({ selectedList, applicationId }) {
  const [checkedItems, setCheckedItems] = React.useState<any>([]);
  const loading: any = useHookstate(false);

  const [approvingConditionList, setApprovingConditionList] = React.useState<any>([]);

  useEffect(() => {
    (async function () {
      loading.set(true);

      let categories = await getProductPredisbursementCategories(applicationId);

      loading.set(false);

      categories = setAlreadyAddedItems(categories);

      setApprovingConditionList(categories);
    })();
  }, []);

  const setAlreadyAddedItems = (categories) => {
    const conds = sessionStorage.addedApprovingConditions;
    let items: any[] = [];
    try {
      if (conds) {
        items = JSON.parse(conds);
      }
    } catch (error) {
      items = [];
    } finally {
      if (items.length > 0) {
        const _list = categories.filter((obj: any) => !items.includes(obj.ID));
        return _list;
      } else {
        return categories;
      }
    }
  };

  const selectAll = () => {
    if (checkedItems.length === approvingConditionList?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleToggleAll = async (e) => {
    let items: any = [];

    if (e.target.checked === true) {
      approvingConditionList.forEach((obj) => items.push(obj.ID));
    } else {
      items = [];
    }

    applyConditions(items);
    sendItems(items);
  };

  const handleChange = (condition) => {
    let items = checkedItems;

    if (items.includes(condition.ID)) {
      items = items.filter((obj) => obj != condition.ID);
    } else {
      items.push(condition.ID);
    }

    applyConditions(items);
    sendItems(items);
  };

  const applyConditions = (items) => {
    setCheckedItems(items);
    setApprovingConditionList([...approvingConditionList]);
  };

  const sendItems = (items?: any) => {
    let list = approvingConditionList.filter((obj) => items.includes(obj.ID));
    selectedList(list);
  };

  return (
    <>
      <Stack pl={2} direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <Typography variant="caption" color={"gray"}>
          {`${checkedItems.length}`}/<b>{`${approvingConditionList?.length ?? 0}`}</b>
        </Typography>
        {loading.get() && <CircularProgressComponent size={24} />}
        {
          <FormControlLabel
            label="Select All"
            control={
              <Checkbox
                checked={selectAll()}
                onClick={handleToggleAll}
                inputProps={{
                  "aria-label": "all Items selected",
                }}
              />
            }
          />
        }
      </Stack>
      <Divider />
      <Box height={500} overflow={"auto"}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {approvingConditionList &&
            approvingConditionList.length > 0 &&
            approvingConditionList.map((condition, index) => {
              return (
                <Stack direction={"row"} py={2} key={index}>
                  <Checkbox checked={checkedItems.includes(condition.ID)} onChange={() => handleChange(condition)} />
                  <Stack direction={"column"}>
                    <Typography variant="subtitle2" color={"grey"}>
                      {condition?.TITLE?.replaceAll("_", " ")?.toUpperCase() || ""}
                    </Typography>
                    <Typography variant="subtitle1" color={"darkgray"}>
                      <BuildConditionItem key={index + 1000} condition={condition} />
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </List>
      </Box>
    </>
  );
}
