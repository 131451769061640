import { langDictionary } from '../../../../../../../../../../configs/languageDictionaryEn';
import { validationHandler } from '../Helpers/ValidationExamingPlot';
import { jsonLoopPickerWidgetCart } from '../InspectionValidationHandler';

export const scoreCardQuestions = (structure: Record<string, any[]>, state: Record<string, Record<string, string>>) => {

    if (!Object.keys(structure).length) return [];

    let errorArray: string[] = [];

    try {
        Object.entries(structure).forEach(([key, children], indexId) => {
            let endWidgetsArray = jsonLoopPickerWidgetCart({ children });

            let { titleErrorArray } = validationHandler(endWidgetsArray, state[key] || {}, []);

            titleErrorArray = titleErrorArray.map((title, index) => `${title} field is mandatory`);

            if (titleErrorArray.length) {

                errorArray = [...errorArray, `${!indexId ? "👉" : "\n👉"} ${langDictionary.scoreCardLayouts?.[key] || key}`, ...titleErrorArray];
            }
        })
    } catch (error) {

    } finally {
        return errorArray;
    }
}