import moment from "moment";

export const profitAndLossAccountsTitleArray = [
    {
        title: Number(moment().year()) - 2,
        dataKey: Number(moment().year()) - 2,
        isEdited: 0,
        isRatioAnalysisCal: true,
        isAutoPopulatedColumns: false
    },
    {
        title: Number(moment().year()) - 1,
        dataKey: Number(moment().year()) - 1,
        isEdited: 0,
        isRatioAnalysisCal: true,
        isAutoPopulatedColumns: false
    },
    {
        title: Number(moment().year()),
        dataKey: Number(moment().year()),
        isEdited: 0,
        isRatioAnalysisCal: true,
        isAutoPopulatedColumns: false
    },
]