import { Downgraded, useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { fetchInspectionService } from "../../../../../../../services/inspectionService";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { toast } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspectionOfficerData, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { inspection, inspectionOfficerDetails } from "../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

const InspectionDetails = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const valueSet = useState<any[]>(inspectionOfficerData);
  const { formData } = data;
  const creditData = data;
  const inspetionId = useState<any>(inspetionIdState);
  const isLoading = useState<boolean>(true);

  const createAddressData = () => {
    const { addressData } = formData;
    const { permanentAddress } = addressData;
    const { addressLine1, addressLine2, addressLine3, addressLine4 } = permanentAddress;
    let address = addressLine1 + "," + addressLine2;

    if (addressLine3 && addressLine4) {
      address = address + ", " + addressLine3 + ", " + addressLine4;
    } else if (addressLine3) {
      address = address + ", " + addressLine3;
    } else if (addressLine4) {
      address = address + ", " + addressLine4;
    }

    return {
      address,
    };
  };

  let toBeSubmittedFormDetails: any = {
    borrowerName: creditData?.name,
    newNic: formData?.personalData?.newNic,
    oldNic: formData?.personalData?.oldNic,
    ...createAddressData(),
    mobile: formData?.contactData?.primaryContact,
    applicationId
  };
  const [formDetails, setFormDetails]: any = React.useState(toBeSubmittedFormDetails);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
    isLoading: isLoading.get()
  };


  useEffect(() => {
    getExistingInspectionForm();

    return () => {
      valueSet.set(JSON.parse(JSON.stringify([inspectionOfficerDetails])));
    }
  }, []);


  const getExistingInspectionForm = async () => {
    try {
      let [applicationFormData] = await Promise.all([getFilledPendingInpectionFormData(applicationId), fetchInspectionService({ applicationId })])

      let { ID: inspectionId = null, INSPECTION_DATA: inspectionData = {} } = applicationFormData?.response;

      try {
        inspectionData = JSON.parse(inspectionData);
      } catch (error) { }

      if (inspectionData && Object.keys(inspectionData).length) {
        inspectionFormState.set(inspectionData);

      } else {
        inspectionFormState.set(JSON.parse(JSON.stringify(inspection)));
      }

      let { summery: data = {} } = inspectionData;

      if (data?.inspectionOfficerData && data?.inspectionOfficerData.length > 0) {
        valueSet.set(data?.inspectionOfficerData);
      }

      inspetionId.set(inspectionId);
      setFormDetails((prev) => ({
        ...JSON.parse(JSON.stringify(inspectionFormState?.summery?.get() ?? {})),
        ...prev
      }));

    } catch (error) {
      toast({
        status:
          "error", message: "Something went wrong while fetching data...!"
      })
    } finally {
      isLoading.set(false);
    }
  }

  const onChange = (metaData: any, value: any) => {
    setFormDetails(value);
  };

  const onSubmit = async () => {
    const inspectionOfficerData = valueSet.attach(Downgraded).get();

    const theDataTobeSubmitted = {
      ...formDetails,
      inspectionOfficerData: inspectionOfficerData,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(ApplicationFormKey.inspectionSummery, theDataTobeSubmitted, SectionKey.inspectionSummery);

    if (errorArray && errorArray.length > 0) {

      const preparedErrorMessagges = inspectionMessageMappings(errorArray).join("\n");

      return toast({ status: "error", message: `${preparedErrorMessagges}` });
    }

    const respose = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.inspectionSummery, inspectionFormState, applicationId);

    if (respose.status == 1) {
      return toast({ status: "success", message: "Successfully Submitted Inpection Form" });
    } else {
      return toast({ status: "error", message: "Something went wrong while Submitting Inspection Form..!" });
    }
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.inspectionSummery);
    } catch (error: any) {
      GlobalLogger.log("inspection details", error.message);
    }
  };

  return (
    <ErrorBoundary message={"Data processing error"}>
      <FormBuilder applicationDataObject={formDetails} onChange={onChange} formId={ApplicationFormKey.inspectionSummery} title={translate("InspectionDetails")} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
    </ErrorBoundary>
  );
};

export default InspectionDetails;
