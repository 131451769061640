import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { openGlobalModal } from "../GlobalModal/GlobalModal";
import { cribStatusColors } from "../WorkflowComponents/utils";

interface CribDocumentComponentProps {
  fileDetails: any;
}

export const CribDocumentComponent: FC<CribDocumentComponentProps> = ({ fileDetails }: any) => {
  const previewClicked = async () => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "document-data-preview",
      close: false,
      modalParams: {
        variant: "crib",
        cribDocId: fileDetails?.docId,
      },
    });
  };

  const bgColor = fileDetails?.status ? cribStatusColors?.[fileDetails?.status]?.color : cribStatusColors?.[1]?.color;

  return (
    <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
      <Box
        style={{
          borderRadius: "5px",
          backgroundColor: `${bgColor}3d`,
          margin: "auto",
          border: `1px solid ${bgColor}`,
          textAlign: "center",
        }}>
        <Grid
          style={{ height: "125px", position: "relative" }}
          container
          item
          direction="row"
          sm={12}
          alignItems="center"
          justifyContent="center">
          <Grid item sm={12}>
            <FindInPageIcon
              style={{
                color: bgColor,
                fontSize: 40,
                cursor: "pointer",
              }}
              onClick={previewClicked}
            />
          </Grid>
        </Grid>
      </Box>

      <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>
        CRIB - {fileDetails?.cribKey}
      </h5>
    </Grid>
  );
};
