import URL from "../../../../../../../configs/connection";

export const endpoints = {
    profitLossAccountsData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/profit-loss-accounts`,
    auditProfitLossAccountsData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/audit/financial-position/profit-loss-accounts`,
    saveProfitLossAccountsData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/profit-loss-accounts`,
    balanceSheetData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/balance-sheet`,
    auditBalanceSheetData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/audit/financial-position/balance-sheet`,
    forCastedData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/forecasted-cash-flow`,
    salesAndPurchaseInformations: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/sales-purchase-information`,
    saveForecastedCashFlowData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/forecasted-cash-flow`,
    saveSalesAndPurchaseInformations: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/financial-position/sales-purchase-information`,
    abilitytoproposeloanData: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/abilitytoproposeloan`,
    ratioAnalysis: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/analysis/ratios`,
    loadExistingComments: `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/abilitytoproposeloan/comments`,
    getInspectionFilledPendingDate: `${URL.REACT_APP_BACKEND_HOST}/los/inspections?applicationId=`,
}