import moment from "moment";
import { TRAIL_CAL_TYPE } from "../../../../configs/constants/trailCalculator";

const equatedBalanceCalculation = (
  loanAmount,
  annualInterestRate,
  terms,
  rateDivider,
  paymentDate,
  paymentStartMonth
) => {
  let strCurrentMonthYear = moment(paymentStartMonth).format("YYYY-MM");
  strCurrentMonthYear = `${strCurrentMonthYear}-${paymentDate}`;

  let currentDate = moment(strCurrentMonthYear);
  let payDate;
  let days;

  let totalInterest = (loanAmount * annualInterestRate) / 100;
  let interestRecovery = totalInterest / terms;

  let termlyInstallment = (loanAmount + totalInterest) / terms;
  let capitalRecovery = termlyInstallment - interestRecovery;

  let trailCalculationData: any = [];
  let capitalOutstanding = loanAmount;
  for (let termX = 0; termX < terms; termX++) {
    payDate = moment(currentDate).add(12 / rateDivider, "M");
    days = payDate.diff(currentDate, "days");

    capitalOutstanding = capitalOutstanding - capitalRecovery;

    trailCalculationData.push({
      capitalRecovery: capitalRecovery.toFixed(2),
      termlyInstallment: termlyInstallment.toFixed(2),
      interestRecovery: interestRecovery.toFixed(2),
      capitalOutstanding: capitalOutstanding.toFixed(2),
      paymentDate: payDate,
    });

    currentDate = payDate;
  }

  return trailCalculationData;
};

const invokeSheduleTerminalAdjusment = (trailCalculationData, strCurrentMonthYear, rateDivider, isEBM) => {
  const previousCapitalRecovery = trailCalculationData[0].capitalRecovery;

  const todayDate: any = moment();
  let dayDifference: any = moment(strCurrentMonthYear)
    .add(12 / rateDivider, "M")
    .diff(todayDate, "days");

  if (isEBM) {
    if (
      moment(strCurrentMonthYear)
        .add(12 / rateDivider, "M")
        .isBefore(todayDate, "day")
    ) {
      // console.log(
      //   "=== isBefore ===",
      //   moment(strCurrentMonthYear).add(12 / rateDivider, "M")
      // );
      let selectedDate = moment(strCurrentMonthYear).add(12 / rateDivider, "M");
      let actualCalDate = moment(selectedDate).add(1, "M");

      let dayDifference = actualCalDate.diff(todayDate, "day");

      const interestForDiffDates: any =
        (trailCalculationData[0].interestRecovery * (dayDifference + 1)) /
        moment().daysInMonth();

      trailCalculationData[0].capitalRecovery =
        trailCalculationData[0].termlyInstallment -
        interestForDiffDates.toFixed(2);
      trailCalculationData[0].interestRecovery =
        interestForDiffDates.toFixed(2);

      const paidExtra =
        trailCalculationData[0].capitalRecovery - previousCapitalRecovery;
      const lastCapitalRecovery =
        trailCalculationData[trailCalculationData.length - 1].capitalRecovery -
        paidExtra;

      trailCalculationData[trailCalculationData.length - 1].capitalRecovery =
        lastCapitalRecovery.toFixed(2);
      trailCalculationData[trailCalculationData.length - 1].termlyInstallment =
        Number(lastCapitalRecovery.toFixed(2)) +
        Number(
          trailCalculationData[trailCalculationData.length - 1].interestRecovery
        );

      trailCalculationData[0].capitalRecovery = trailCalculationData[0].capitalRecovery.toFixed(2);
      trailCalculationData[trailCalculationData.length - 1].capitalRecovery = parseFloat(trailCalculationData[trailCalculationData.length - 1].capitalRecovery.toString()).toFixed(2);
    } else {
      if (dayDifference !== 0) {
        const interestForDiffDates: any =
          (trailCalculationData[0].interestRecovery * (dayDifference + 1)) /
          moment().daysInMonth();

        trailCalculationData[0].capitalRecovery =
          trailCalculationData[0].termlyInstallment -
          interestForDiffDates.toFixed(2);
        trailCalculationData[0].interestRecovery =
          interestForDiffDates.toFixed(2);

        const paidExtra =
          trailCalculationData[0].capitalRecovery - previousCapitalRecovery;
        const lastCapitalRecovery =
          trailCalculationData[trailCalculationData.length - 1]
            .capitalRecovery - paidExtra;

        trailCalculationData[trailCalculationData.length - 1].capitalRecovery =
          lastCapitalRecovery.toFixed(2);
        trailCalculationData[
          trailCalculationData.length - 1
        ].termlyInstallment =
          Number(lastCapitalRecovery.toFixed(2)) +
          Number(
            trailCalculationData[trailCalculationData.length - 1]
              .interestRecovery
          );

        trailCalculationData[0].capitalRecovery = trailCalculationData[0].capitalRecovery.toFixed(2);
        trailCalculationData[trailCalculationData.length - 1].capitalRecovery = parseFloat(trailCalculationData[trailCalculationData.length - 1].capitalRecovery.toString()).toFixed(2);
      }
    }
  }

  trailCalculationData[trailCalculationData.length - 1].capitalOutstanding =
    Math.abs(
      trailCalculationData[trailCalculationData.length - 1].capitalOutstanding
    );
}

const reducingBalanceCalculation = (
  loanAmount,
  annualInterestRate,
  terms,
  rateDivider,
  paymentDate,
  paymentStartMonth,
  selectedType
) => {
  let strCurrentMonthYear = moment(paymentStartMonth).format("YYYY-MM");
  strCurrentMonthYear = `${strCurrentMonthYear}-${paymentDate}`;

  let currentDate = moment(strCurrentMonthYear);
  let payDate;
  let days;

  let meta: Object = {};

  if (
    selectedType?.code === "EB_M" &&
    moment(strCurrentMonthYear).add(1, "M").isBefore(moment())
  ) {
    currentDate = moment(strCurrentMonthYear).add(1, "M");
  }

  const i = annualInterestRate / (rateDivider * 100);
  const n = terms;

  const termlyInstallment =
    loanAmount * i * ((1 + i) ** n / ((1 + i) ** n - 1));

  let capitalRecovery = 0,
    interestRecovery,
    capitalOutstanding = loanAmount;
  let trailCalculationData: any = [];

  for (let termX = 0; termX < terms; termX++) {
    payDate = moment(currentDate).add(12 / rateDivider, "M");
    days = payDate.diff(currentDate, "days");

    interestRecovery = capitalOutstanding * i;
    capitalRecovery = termlyInstallment - interestRecovery;

    capitalOutstanding = capitalOutstanding - capitalRecovery;

    trailCalculationData.push({
      capitalRecovery: capitalRecovery.toFixed(2),
      termlyInstallment: termlyInstallment.toFixed(2),
      interestRecovery: interestRecovery.toFixed(2),
      capitalOutstanding: capitalOutstanding.toFixed(2),
      paymentDate: payDate,
    });

    currentDate = payDate;
  }

  try {
    meta["first"] = Object.assign({}, trailCalculationData[0]);
    meta["last"] = Object.assign({}, trailCalculationData[trailCalculationData.length - 1]);
  } catch (error) {

  }

  invokeSheduleTerminalAdjusment(trailCalculationData, strCurrentMonthYear, rateDivider, selectedType?.code === "EB_M");

  return { meta, trailCalculationData };
};

const customizedBalanceCalculation = (
  loanAmount,
  annualInterestRate,
  terms,
  rateDivider,
  paymentDate,
  paymentStartMonth
) => {
  let strCurrentMonthYear = moment(paymentStartMonth).format("YYYY-MM");
  strCurrentMonthYear = `${strCurrentMonthYear}-${paymentDate}`;

  let currentDate = moment(strCurrentMonthYear);
  let payDate;

  let capitalRecovery = loanAmount / terms,
    interestRecovery,
    capitalOutstanding = loanAmount,
    termlyInstallment;
  let trailCalculationData: any = [];
  let days;
  for (let termX = 0; termX < terms; termX++) {
    payDate = moment(currentDate).add(12 / rateDivider, "M");
    days = payDate.diff(currentDate, "days");

    interestRecovery =
      (capitalOutstanding * annualInterestRate * days) / (100 * 365);
    capitalOutstanding = capitalOutstanding - capitalRecovery;

    termlyInstallment = capitalRecovery + interestRecovery;
    trailCalculationData.push({
      capitalRecovery: capitalRecovery.toFixed(2),
      termlyInstallment: termlyInstallment.toFixed(2),
      interestRecovery: interestRecovery.toFixed(2),
      capitalOutstanding: capitalOutstanding.toFixed(2),
      paymentDate: payDate,
    });

    currentDate = payDate;
  }

  return trailCalculationData;
};

export const trailCalculatorResult = (
  repaymentMethodList,
  trailCalType,
  loanAmount,
  annualInterestRate,
  terms,
  paymentData,
  paymentStartMonth
) => {
  const selectedType = repaymentMethodList[trailCalType];
  switch (trailCalType) {
    case TRAIL_CAL_TYPE[0].id:
      return equatedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    case TRAIL_CAL_TYPE[1].id:
      return equatedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    case TRAIL_CAL_TYPE[2].id:
      return equatedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    case TRAIL_CAL_TYPE[3].id:
      return reducingBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth,
        selectedType
      );
    case TRAIL_CAL_TYPE[4].id:
      return reducingBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth,
        selectedType
      );
    case TRAIL_CAL_TYPE[5].id:
      return reducingBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth,
        selectedType
      );
    case TRAIL_CAL_TYPE[6].id:
      return customizedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    case TRAIL_CAL_TYPE[7].id:
      return customizedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    case TRAIL_CAL_TYPE[8].id:
      return customizedBalanceCalculation(
        loanAmount,
        annualInterestRate,
        terms,
        selectedType?.factor,
        paymentData,
        paymentStartMonth
      );
    default:
      return null;
  }
};
