import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { branchDetails, creditData, letterDocumentStructure } from "../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import { numberToWords, trimWithDelimiter } from "../../../../../../../utility/util";
import { SinglePageBreak } from "../../CommonPageBreaks";

export const LoanAgreementInfo = ({ data, installmentData, disburseLoanAmount, letterDocumentData, typeCaseClass }) => {
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const creditFileData: any = creditData.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const personalData = data?.formData?.personalData;
  const jointBorrowerData = data?.formData?.jointBorrowerData || [];
  const guarantorData = data?.formData?.securityData?.guarantors || [];
  const primaryAddress = data?.formData?.addressData?.permanentAddress;
  const loanTerms = data?.formData?.creditData?.loanTerms;
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const [disburseLoanValue, setDisburseLoanValue]: any = React.useState(0.0);
  const [loanAmountInWords, setLoanAmountInWords]: any = React.useState("");

  useEffect(() => {
    loanCreditDetails();
    getCoreBankDataDetails();
  }, []);

  useEffect(() => {
    loanCreditDetails();
  }, [disburseLoanAmount, installmentData]);

  const getCoreBankDataDetails = async () => {
    const { REC_ACC: recoveryAccount = "" } = (data as any).coreData || {};
    setSelectedAccount(recoveryAccount);
  };

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const paAddress = paAddressSegments.join(", ").trim();
    paAddresses.push(paAddress);
    return (
      <>
        <Grid item xs={12}>
          <Stack direction={"row"} className="security-documents-table">
            <Stack width={20}>1.</Stack>
            <Stack pl={1} width={250} direction={"row"} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
              <Stack>Primary Applicant</Stack>
              <Stack>:</Stack>
            </Stack>
            <Grid pl={0.5} item className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
              <Typography className={`security-doc-type-variable security-documents-table ${typeCaseClass}`}>{paAddresses.join("")}</Typography>
            </Grid>
          </Stack>
        </Grid>
      </>
    );
  };

  const JBAdress = ({ jbData }) => {
    if (!jbData || jbData.length === 0) {
      return <></>;
    }
    let addresses: String[] = [];
    for (const jb of jbData) {
      let addressSegments = [jb?.addressData?.permanentAddress?.addressLine1 || "", jb?.addressData?.permanentAddress?.addressLine2 || "", jb?.addressData?.permanentAddress?.addressLine3 || "", jb?.addressData?.permanentAddress?.addressLine4 || ""].filter((obj) => obj);
      let address = addressSegments.join(", ").trim();
      address = trimWithDelimiter(address);
      addresses.push(address);
    }
    return (
      <>
        {addresses.map((item, i) => {
          return (
            <>
              <Grid item xs={12}>
                <Stack direction={"row"}>
                  <Stack width={20}>{i + 2}.</Stack>
                  <Stack pl={1} direction={"row"} width={250} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
                    <Stack>Joint Borrower</Stack>
                    <Stack>:</Stack>
                  </Stack>
                  <Grid item pl={0.5} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                    <Typography className="security-documents-table" key={i}>
                      <span className={`security-doc-type-variable ${typeCaseClass}`}>{`${item}`}</span>
                    </Typography>
                  </Grid>
                </Stack>
              </Grid>
            </>
          );
        })}
      </>
    );
  };


  let loanAgreementObj = {};
  const callBackOnAction = (value, param) => {
    loanAgreementObj = {
      ...letterDocumentData?.loanAgreementObj,
      [param]: value,
    };
    letterDocumentStructureData?.["loanAgreementObj"].set(loanAgreementObj);
  };

  const loanCreditDetails = () => {
    let disburseLoanAmt: any = disburseLoanAmount || data?.formData?.creditData?.loanAmount || 0.0;
    setDisburseLoanValue(disburseLoanAmt);

    let disburseLoanValueWords = numberToWords(disburseLoanAmt);
    setLoanAmountInWords(disburseLoanValueWords);
  };

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = personalData.newNic;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = personalData.oldNic;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  const renderAddressDetails = (type = "", item: any = {}) => {
    let address = "";
    if (type == "") {
      return "";
    }

    item = item?.addressData;
    if (!item) {
      return "";
    }

    if (type == "PA") {
      return ""; // TODO: implement PA address
    } else if (type == "JB") {
      return ""; // TODO: implement PA address
    } else if (type == "GR") {
      let addressSegments = [item.permanentAddress?.addressLine1 || "", item.permanentAddress?.addressLine2 || "", item.permanentAddress?.addressLine3 || "", item.permanentAddress?.addressLine4 || ""].filter((obj) => obj);
      address = addressSegments.join(", ");
      address = trimWithDelimiter(address);
    }

    return address;
  };

  const applicantName = () => {
    return `
    ${personalData?.initialsInFull} ${personalData?.primaryLastName}
    `;
  };

  return (
    <>
      <Grid container>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} className="security-documents-table" colSpan={2}>
                  <Grid item className="security-documents-table" style={{ marginBottom: "2px" }}>
                    Loan No :
                  </Grid>
                </TableCell>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2} className="security-documents-table">
                  <Stack style={{ marginBottom: "2px" }} direction={"row"}>
                    Branch :{" "}
                    <Stack ml={1} className={`security-doc-type-variable ${typeCaseClass}`}>
                      {nearestBranch}
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Stack direction={"row"}>
                    Savings Account No :{" "}
                    <Stack ml={1} className={`security-doc-type-variable ${typeCaseClass}`}>
                      {selectedAccount ?? ""}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell className="security-documents-table" sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Grid item>Secutity File No :</Grid>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "100%", borderColor: "#000000" }} colSpan={4} width={"100%"}>
                  <Grid container columns={12}>
                    <Grid item xs={12} mb={2}>
                      Name of Applicant/s{" "}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={"row"}>
                      <Stack width={20}>1.</Stack>
                      <Grid item pl={1} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                        <span className={`security-doc-type-variable ${typeCaseClass}`}>{applicantName()}</span>
                      </Grid>
                    </Stack>
                  </Grid>

                  {jointBorrowerData &&
                    jointBorrowerData?.map((item, i) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <Stack direction={"row"}>
                              <Stack width={20}>{i + 2}.</Stack>
                              <Grid item pl={1} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                                <span className={`security-doc-type-variable ${typeCaseClass}`}>{`${item?.personalData?.initialsInFull + " " + item?.personalData?.primaryLastName ?? ""} `}</span>
                              </Grid>
                            </Stack>
                          </Grid>
                        </>
                      );
                    })}
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "50%", borderColor: "#000000" }} colSpan={3} width={"100%"}>
                  <Grid container columns={12}>
                    <Grid item xs={12} mb={2}>
                      NIC/Passport/Driving License No/s{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} className="security-documents-table">
                        <Stack width={20}>1.</Stack>
                        <Stack pl={1} width={250} direction={"row"} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
                          <Stack>Primary Applicant</Stack>
                          <Stack>:</Stack>
                        </Stack>
                        <Grid pl={0.5} item className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                          {renderNicDetails("PA")}
                        </Grid>
                      </Stack>
                    </Grid>

                    {jointBorrowerData.length > 0 ? (
                      <>
                        {jointBorrowerData?.map((item, i) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <Stack direction={"row"}>
                                  <Stack width={20}>{i + 2}.</Stack>
                                  <Stack pl={1} direction={"row"} width={250} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
                                    <Stack>Joint Borrower</Stack>
                                    <Stack>:</Stack>
                                  </Stack>
                                  <Grid item pl={0.5} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                                    <span>{renderNicDetails("JB", item)}</span>
                                  </Grid>
                                </Stack>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Stack direction={"row"}>
                            <Stack width={20}>{2}.</Stack>
                            <Stack pl={1} direction={"row"} width={250} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
                              <Stack>Joint Borrower</Stack>
                              <Stack>:</Stack>
                            </Stack>
                            <Grid item pl={0.5} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                              N/A
                            </Grid>
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "100%", borderColor: " #000000" }} colSpan={3}>
                  <Grid container columns={12}>
                    <Grid item xs={12} mb={2}>
                      Address/es{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <PAAdress />
                    </Grid>
                    <Grid item xs={12}>
                      {jointBorrowerData.length > 0 ? (
                        <JBAdress jbData={jointBorrowerData} />
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Stack direction={"row"}>
                              <Stack width={20}>{2}.</Stack>
                              <Stack pl={1} direction={"row"} width={250} style={{ marginBottom: "2px", justifyContent: "space-Between" }}>
                                <Stack>Joint Borrower</Stack>
                                <Stack>:</Stack>
                              </Stack>
                              <Grid item pl={0.5} className="security-documents-table" style={{ marginBottom: "2px", width: "100%" }}>
                                <Typography className="security-documents-table">N/A</Typography>
                              </Grid>
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <SinglePageBreak />
              {guarantorData && (
                <TableRow sx={{ border: 1 }}>
                  <TableCell className="security-documents-table" sx={{ width: "100%", borderColor: " #000000" }} colSpan={3}>
                    <Grid item style={{ marginBottom: "2px" }}>
                      Full Name/s of Guarantor/s, if any :
                    </Grid>
                    {guarantorData?.map((item, i) => {
                      return (
                        <Stack direction={"row"} mt={1}>
                          <Stack width={20}>
                            <Typography className="security-documents-table">{i + 1}.</Typography>
                          </Stack>
                          <Stack pl={0.5}>
                            <Typography className={`security-documents-table ${typeCaseClass}`}>{`${item?.personalData?.initialsInFull} ${item?.personalData?.primaryLastName}`}</Typography>
                            <Typography className={`security-documents-table ${typeCaseClass}`}>{renderNicDetails("GR", item)}</Typography>
                            <Typography className={`security-documents-table ${typeCaseClass}`}>{renderAddressDetails("GR", item)}</Typography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </TableCell>
                </TableRow>
              )}
              <SinglePageBreak />
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "100%", borderColor: " #000000" }} colSpan={3}>
                  <Grid item style={{ marginBottom: "2px" }}>
                    Loan Amount : <span className={`security-doc-type-variable ${typeCaseClass}`}>{loanAmountInWords}</span> (Rs : {formatCurrency(disburseLoanValue, 2)})
                  </Grid>
                </TableCell>
              </TableRow>
              <SinglePageBreak />
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "50%", borderColor: " #000000" }} colSpan={2}>
                  <Grid item style={{ marginBottom: "2px" }}>
                    Number of Installments : <span className={`security-doc-type-variable ${typeCaseClass}`}>{loanTerms}</span>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell className="security-documents-table" sx={{ width: "100%", borderColor: " #000000" }} colSpan={3}>
                  <Stack mb={2} flex={1} direction={"row"}>
                    <Stack mr={1}>Monthly installment :</Stack>
                    <Stack direction={"column"} flex={1}>
                      <Stack direction={"column"} flex={1} mt={3} mb={4} style={{ borderBottom: "grey 2px dotted" }}></Stack>
                      <Stack direction={"column"} flex={1} mb={3} style={{ borderBottom: "grey 2px dotted" }}></Stack>
                      <Stack direction={"row"} flex={1}>
                        <Stack direction={"row"}>(Rs. </Stack>
                        <Stack flex={0.5} style={{ borderBottom: "grey 2px dotted" }}></Stack>)
                      </Stack>
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <SinglePageBreak />
    </>
  );
};
