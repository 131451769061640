import { Box, Grid, Stack } from "@mui/material";
import { MainSectionsWrapper } from "../../../../../../MainSectionsWrapper/MainSectionsWrapper";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { documentStatusColors } from "../../../../../../WorkflowComponents/utils";
import { standardDateFormatter } from "los-util";
import { formattedDate } from "../../../../../../../../utility/fomatters";

const PropertyValuation = (props) => {
  return (
    <>
      <Box pt={2} className="mainSection__heading--bold">{`S${props?.disbIndex} Valuation of Property`}</Box>

      <Stack>
        <InputFieldsStack>
          <InputGrid visibility={1}>
            <TextBoxComponent value={props?.doc?.propertyValuation[props?.disbIndex]?.assetValue?.get()} onChange={(e) => props?.doc?.propertyValuation[props?.disbIndex]?.assetValue?.set(e.target.value)} label={"Asset Value"} />
          </InputGrid>
          <InputGrid visibility={1}>
            <TextBoxComponent value={props?.doc?.propertyValuation[props?.disbIndex]?.valuationOfficer?.get()} onChange={(e) => props?.doc?.propertyValuation[props?.disbIndex]?.valuationOfficer?.set(e.target.value)} label={"Valuation Officer"} />
          </InputGrid>
          <InputGrid visibility={1}>
            <ValidateDatePicker
              label={"Valuation Date (DD/MM/YYYY)"}
              value={formattedDate(props?.doc?.propertyValuation[props?.disbIndex]?.valuationDate?.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.propertyValuation[props?.disbIndex]?.valuationDate?.set(preparedDate);
                }
              }}
            />
          </InputGrid>
        </InputFieldsStack>
      </Stack>

      <Grid item xs={12} sm={2} md={2} p={1}>
        <Box
          style={{
            borderRadius: "5px",
            backgroundColor: "#a2a2a22e",
            margin: "auto",
            border: `1px solid #a2a2a22e`,
            textAlign: "center",
          }}
        >
          <Grid style={{ height: "125px", position: "relative" }} container item direction="row" sm={12} alignItems="center" justifyContent="center">
            <Grid item sm={12}>
              <AddCircleIcon
                style={{
                  color: "#a2a2a2",
                  fontSize: 40,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>Valuation Report</h5>
      </Grid>
    </>
  );
};

export default PropertyValuation;
