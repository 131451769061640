import { addToaster } from "../components/OtherComponents/GlobalToast";
import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";

const createNewTab = `${URL.REACT_APP_BACKEND_HOST}/los/workflow/tabcontrolstage`;
const api = Api.getApi();

/** WORKFLOW MASTER */
export const getAllMasterData = async () => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/master-data`, {});
    return data;
  } catch (error) { }
};

/** WORKFLOW META DATA */
export const getWorkflowMetaData = async () => {
  try {
    const data = await CachingStorage.invoke("workflow-engine/workflow-list", () => api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/workflow-list`, {}));
    return data;
  } catch (error) { }
};

export const addWorkflowMetaData = async (dataObj) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/add-workflow-meta`, {
      ...dataObj,
    });
    return res;
  } catch (error) { }
};

export const updateWorkflowMetaData = async (dataObj) => {
  //(update workflow meta data)
  try {
    const res = await api.put(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/edit-workflow-meta`, {
      ...dataObj,
    });
    return res;
  } catch (error) { }
};

/** APPLICATION REVERT HISTORY */

export const getApplicationRevertHistory = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/revert-history`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const getApplicationWithdrawStages = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/stages/withdraw`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

/** ADD APPLICATION REVERT HISTORY */

export const addApplicationRevertHistory = async (
  applicationId,
  action,
  previousStep,
  currentStep,
  actionBy,
  stageId,
  description,
  isActive
) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/add-revert-history`, {
      applicationId: applicationId,
      action: action,
      previousStep: previousStep,
      currentStep: currentStep,
      actionBy: actionBy,
      stageId: stageId,
      description: description,
      isActive: isActive,
    });
    return data;
  } catch (error) { }
};

/** DELETE APPLICATION REVERT HISTORY */

export const deleteApplicationRevertHistory = async (applicationId, stageId, isActive, activeStatus) => {
  try {
    const { data } = await api.put(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/delete-revert-history`, {
      applicationId: applicationId,
      stageId: stageId,
      isActive: isActive,
      activeStatus: activeStatus,
    });
    return data;
  } catch (error) { }
};

/** UPDATE TAB STAGES */
export const assignTabControlData = async (dataObj) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/update-workflow-stage`, dataObj);
    return res;
  } catch (error) {
    console.log(error);
  }
};

/** GET TAB STAGES */

export const getTabControlData = async (workFlowId) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/workflow-stage?workFlowId=${workFlowId}`
    );
    return data;
  } catch (error) { }
};

/** GET DEFAULT TAB STAGES */
export const getTabControlStages = async (workflowId = null) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/tabs/stages?workflowId=${workflowId}`);
    return data;
  } catch (error) { }
};

/** WORKFLOW DRAW */
export const getWorkflowDataByWorkflowId = async (id) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/workflow`, { id: id });
    return data;
  } catch (error) { }
};

export const addWorkflowConfigurations = async (id, arr, metaData, apiJobMetaData = {}) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/add-workflow`,
      {
        data: arr,
        workflowId: id,
        metaData: metaData,
        apiJobMetaData
      }
    );
    return res;
  } catch (error) { }
};

export const updateWorkflowConfigurations = async (
  id,
  arr,
  metaData,
  apiJobMetaData = {},
  isStructuralChange
) => {
  try {
    const res = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/edit-workflow`,
      {
        data: arr,
        workflowId: id,
        metaData: metaData,
        apiJobMetaData,
        isStructuralChange: isStructuralChange,
      }
    );
    return res;
  } catch (error) { }
};

/** WORKFLOW MAPPING  */
export const getWorkflowMappedData = async () => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/mapped-workflows`, {});
    return data;
  } catch (error) { }
};

export const addWorkflowProductMapping = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/add-workflow-product-mapping`, {
      ...data,
    });
    return res;
  } catch (error) { }
};

export const updateWorkflowProductMapping = async (data) => {
  try {
    const res = await api.put(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/update-workflow-product-mapping`, {
      ...data,
    });
    return res;
  } catch (error) { }
};

export const getWorkflowActionsInStage = async (
  applicationId,
  workflowStage,
  applicantType,
  productType,
  sector,
  scheme
) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/actions/?workflowStage=${workflowStage}&applicantType=${applicantType}&productType=${productType}&sector=${sector}&scheme=${scheme}&applicationId=${applicationId}`,
      {}
    );
    return data;
  } catch (error) { }
};

export const fetchWorkflowActions = async ({ status }) => {
  try {

    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/actions/${status}`
    );

    return data;
  } catch (error) {
    return null;
  }
}

export const fetchWorkflowStages = async ({ status }) => {
  try {

    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/stages/${status}`
    );

    return data;
  } catch (error) {
    return null;
  }
}

export const registerWorkflow = async (payload) => {
  try {
    const { type } = payload;

    delete payload.type;
    const { data } = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/register/${type}`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const unregisterWorkflow = async (payload) => {
  try {
    const { type } = payload;
    delete payload.type;
    const { data } = await api.delete(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/register/${type}`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const updateApplicationWorkflowStage = async (
  applicationId,
  workflowStage,
  action,
  workflowId,
  productId,
  applicantTypeId,
  comment,
  revertedStage
) => {
  try {
    // const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/workflow-change`, {
    //   applicationId,
    //   workflowStage,
    //   action,
    //   workflowId,
    //   productId,
    //   applicantTypeId,
    //   comment,
    //   revertedStage,
    // });

    const res2 = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/tab-validation-wf-process`, {
      applicationId,
      workflowStage,
      action,
      workflowId,
      productId,
      applicantTypeId,
      comment,
      revertedStage,
    });

    // if (res.status === ERROR_CODES.success) {
    //   return { msg: res?.data };
    // } else if (res.status === ERROR_CODES.fail) {
    //   return { error: res?.data };
    // }

    if (res2.status === ERROR_CODES.success) {
      return { msg: res2?.data };
    } else if (res2.status === ERROR_CODES.fail) {
      return { error: res2?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const validateWorkflowStage = async (applicationId, rules) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/validate-on-workflow-next`, {
      applicationId,
      rules,
    });

    return response;

    return null;
  } catch (error) {
    return null;
  }
};

export const updateApprovalLimitToStage = async ({ type, stageId, status }) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/stage/status`, {
      stageId,
      status,
      type,
    });

    return response;
  } catch (error) {
    return null;
  }
};

export const updateWFProductMappingStatus = async (data) => {
  try {
    const response = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/product/status`, {
      data,
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Failed to update publish/unpublish product.");
    }
  } catch (error) {
    return null;
  }
};

export const addNewTab = async (payload: any) => {
  try {
    const data = await api.post(`${createNewTab}`, payload);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const deleteTab = async (workflowId, tabKey) => {
  try {
    const { data } = await api.delete(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/tabcontrolstage?workflowId=${workflowId}&tabKey=${tabKey}`,
      {}
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const revertStageTo = async (applicationId, sequenceId, nextWorkflowStage, section) => {
  try {
    const { data } = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/los/workflow/stage/revert?applicationId=${applicationId}&seqId=${sequenceId}&nextWorkflowStage=${nextWorkflowStage}&section=${section}`,
      {}
    );
    return data;
  } catch (error) { }
};

export const getWorkflowMetaActionData = async () => {
  try {
    const { data, status } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/action-data`, {});

    if (status === 1) {
      return data;
    }

    return [];
  } catch (error) { }
};

export const saveActionData = async (params) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/rule/save`, params);

    return response;
  } catch (error) {
    addToaster({
      status: "error",
      title: "Error",
      message: `Error Saving data`,
    });

    return null;
  }
};

export const getSavedRule = async (params) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/rule/config`, {
      key: params,
    });

    return response;
  } catch (error) {
    addToaster({
      status: "error",
      title: "Error",
      message: `Error Retrieving data`,
    });

    return null;
  }
};
