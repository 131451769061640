import { CircularProgress } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { FC } from 'react';
import { CircularProgressPropsTypes, LinearProgressPropsTypes } from '../../../configs/interfaces';

export const CircularProgressComponent: FC<CircularProgressPropsTypes> = ({
  classes,
  color,
  disableShrink,
  size,
  sx,
  thickness,
  value,
  variant,
}: any) => {
  return (
    <CircularProgress
      classes={classes}
      color={color}
      disableShrink={disableShrink}
      size={size}
      sx={sx}
      thickness={thickness}
      value={value}
      variant={variant}
    />
  );
};

export const LinearProgressComponent: FC<LinearProgressPropsTypes> = ({
  classes,
  color,
  sx,
  value,
  valueBuffer,
  variant,
}: any) => {
  return (
    <LinearProgress classes={classes} color={color} sx={sx} value={value} valueBuffer={valueBuffer} variant={variant} />
  );
};
