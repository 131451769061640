import { Grid, Stack } from "@mui/material";
import { signatureListNumberMapping } from "../../../../../configs/constants/contants";
import { branchDetails } from "../../../../../configs/mainStore";
import { SinglePageBreak } from "./CommonPageBreaks";

function CommonSignatureBlock({ creditFileData, typeCaseClass, renderNicDetails, isThisDayAvailable = false, isDateAvailable = false, isSignAtAvailable = false }) {
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const jointBorrowers = creditFileData.formData.jointBorrowerData || [];
  const primaryApplicantfullName = `${creditFileData?.formData?.personalData?.initialsInFull} ${creditFileData?.formData?.personalData?.primaryLastName}`;

  const BuildSegment = (data) => {
    let mainArrayIndex = data.numbering;

    let rowJBOne = {
      fullName: "",
      nic: "",
    };

    let rowJBTwo = {
      fullName: "",
      nic: "",
    };

    if (data.data.length > 0) {
      rowJBOne.fullName = `${data.data[0].personalData?.initialsInFull} ${data.data[0].personalData?.primaryLastName}`;
      rowJBOne.nic = `${renderNicDetails("JB", data.data[0])}`;
      if (data.data.length > 1) {
        rowJBTwo.fullName = `${data.data[1].personalData?.initialsInFull} ${data.data[1].personalData?.primaryLastName}`;
        rowJBTwo.nic = `${renderNicDetails("JB", data.data[1])}`;
      }
    }

    return (
      <>
        {data.data.length > 0 ? (
          <>
            <Stack flex={"100%"} direction={"row"} mb={2}>
              <Stack width={"100%"} direction={"column"}>
                <Stack width={"100%"} direction={"row"} mb={2} mt={3}>
                  <Stack width={"50%"} direction={"row"}>
                    <Stack width={"8%"}>{signatureListNumberMapping[mainArrayIndex][0] != null ? `${signatureListNumberMapping[mainArrayIndex][0]}.` : ""}</Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Stack width={90}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                          <Stack>Signature</Stack>
                          <Stack>:</Stack>
                        </Stack>
                      </Stack>
                      <Stack pl={1}>.........................................</Stack>
                    </Stack>
                  </Stack>
                  {data.data[1] != null && (
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}>{signatureListNumberMapping[mainArrayIndex][1] != null ? `${signatureListNumberMapping[mainArrayIndex][1]}.` : ""}</Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Stack width={90}>
                          <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack>Signature</Stack>
                            <Stack>:</Stack>
                          </Stack>
                        </Stack>
                        <Stack pl={1}>.........................................</Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                <Stack width={"100%"} direction={"row"} mb={2}>
                  <Stack width={jointBorrowers.length === 0 ? "100%" : "50%"} direction={"row"}>
                    <Stack width={jointBorrowers.length === 0 ? "4%" : "8%"}></Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Stack width={90}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                          <Stack>Name</Stack>
                          <Stack>:</Stack>
                        </Stack>
                      </Stack>
                      <Stack direction={"row"} flex={1} pl={2} pr={2}>
                        <span className={`security-doc-type-variable ${typeCaseClass}`}>{rowJBOne.fullName}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                  {data.data[1] != null && (
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}></Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Stack width={90}>
                          <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack>Name</Stack>
                            <Stack>:</Stack>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} flex={1} pl={2} pr={2}>
                          <span className={`security-doc-type-variable ${typeCaseClass}`}>{rowJBTwo.fullName}</span>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                <Stack width={"100%"} direction={"row"} mb={2}>
                  <Stack width={"50%"} direction={"row"}>
                    <Stack width={"8%"}></Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Stack width={90}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                          <Stack>NIC No</Stack>
                          <Stack>:</Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack direction={"row"} flex={1} pl={2} pr={2}>
                          <span className={`security-doc-type-variable ${typeCaseClass}`}>{rowJBOne.nic}</span>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  {data.data[1] != null && (
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}></Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Stack width={90}>
                          <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack>NIC No</Stack>
                            <Stack>:</Stack>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack direction={"row"} flex={1} pl={2} pr={2}>
                            <span className={`security-doc-type-variable ${typeCaseClass}`}>{rowJBTwo.nic}</span>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                {isThisDayAvailable && (
                  <Stack width={"100%"} direction={"row"} mb={2}>
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}></Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Grid item>
                          This .......... day of ...................... 20.....{" "}
                          <div>
                            at <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranch}</span> Branch
                          </div>
                        </Grid>
                      </Stack>
                    </Stack>
                    {data.data[1] != null && (
                      <Stack width={"50%"} direction={"row"}>
                        <Stack width={"8%"}></Stack>
                        <Stack width={"92%"} direction={"row"}>
                          <Grid item>
                            This .......... day of ...................... 20.....{" "}
                            <div>
                              at <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranch}</span> Branch
                            </div>
                          </Grid>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                )}

                {isDateAvailable && (
                  <Stack width={"100%"} direction={"row"} mb={2}>
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}></Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Stack direction={"row"}>
                          <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                            <Stack>Date</Stack>
                            <Stack>:</Stack>
                          </Stack>
                          <Stack pl={1}>...........................................</Stack>
                        </Stack>
                        <Stack>
                          <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                        </Stack>
                      </Stack>
                    </Stack>

                    {data.data[1] != null && (
                      <Stack width={"50%"} direction={"row"}>
                        <Stack width={"8%"}></Stack>
                        <Stack width={"92%"} direction={"row"}>
                          <Stack direction={"row"}>
                            <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                              <Stack>Date</Stack>
                              <Stack>:</Stack>
                            </Stack>
                            <Stack pl={1}>...........................................</Stack>
                          </Stack>
                          <Stack>
                            <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                )}

                {isSignAtAvailable && (
                  <Stack width={"100%"} direction={"row"} mb={2}>
                    <Stack width={"50%"} direction={"row"}>
                      <Stack width={"8%"}></Stack>
                      <Stack width={"92%"} direction={"row"}>
                        <Stack direction={"row"}>
                          <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                            <Stack>Signed at</Stack>
                            <Stack>:</Stack>
                          </Stack>
                          <Stack pl={1}>...........................................</Stack>
                        </Stack>
                        <Stack>
                          <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    {data.data[1] != null && (
                      <Stack width={"50%"} direction={"row"}>
                        <Stack width={"8%"}></Stack>
                        <Stack width={"92%"} direction={"row"}>
                          <Stack direction={"row"}>
                            <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                              <Stack>Signed at</Stack>
                              <Stack>:</Stack>
                            </Stack>
                            <Stack pl={1}>...........................................</Stack>
                          </Stack>
                          <Stack>
                            <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <SinglePageBreak />
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const segments = {};

  // let list = [creditFileData?.formData, ...jointBorrowers];
  let list: any[] = [...jointBorrowers];

  list.splice(0, 1);

  let keyLength: any = list.length;
  let elementCount: any = 0;

  if (keyLength % 2 == 0) {
    elementCount = keyLength / 2;
  } else {
    elementCount = (keyLength + 1) / 2;
  }

  for (let i = 0; i < elementCount; i++) {
    let newArr: any[] = [];
    newArr = list.splice(0, 2);
    segments[i] = newArr;
  }

  return (
    <>
      <Grid container>
        <Stack flex={"100%"} direction={"row"} mb={2} mt={3}>
          <Stack width={"100%"} direction={"column"}>
            <Stack width={"100%"} direction={"row"} mb={2}>
              <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                <Stack width={"8%"}>1.</Stack>
                <Stack width={"92%"} direction={"row"}>
                  <Stack width={90}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Stack>Signature</Stack>
                      <Stack>:</Stack>
                    </Stack>
                  </Stack>
                  <Stack pl={1}>.........................................</Stack>
                </Stack>
              </Stack>
              {jointBorrowers.length > 0 && (
                <Stack width={"50%"} direction={"row"}>
                  <Stack width={"8%"}>2.</Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Stack width={90}>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack>Signature</Stack>
                        <Stack>:</Stack>
                      </Stack>
                    </Stack>
                    <Stack pl={1}>.........................................</Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>

            <Stack width={"100%"} direction={"row"} mb={2}>
              <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                <Stack width={"8%"}></Stack>
                <Stack width={"92%"} direction={"row"}>
                  <Stack>
                    <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                      <Stack>Name</Stack>
                      <Stack>:</Stack>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack direction={"row"} flex={1} pl={2} pr={2}>
                      <span className={`security-doc-type-variable ${typeCaseClass}`}>{primaryApplicantfullName}</span>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {jointBorrowers.length > 0 && (
                <Stack width={"50%"} direction={"row"}>
                  <Stack width={"8%"}></Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Stack>
                      <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                        <Stack>Name</Stack>
                        <Stack>:</Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack direction={"row"} flex={1} pl={2} pr={2}>
                        <span className={`security-doc-type-variable ${typeCaseClass}`}>{jointBorrowers[0].personalData?.initialsInFull + " " + jointBorrowers[0].personalData?.primaryLastName}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>

            <Stack width={"100%"} direction={"row"} mb={2}>
              <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                <Stack width={"8%"}></Stack>
                <Stack width={"92%"} direction={"row"}>
                  <Stack>
                    <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                      <Stack>NIC No</Stack>
                      <Stack>:</Stack>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack direction={"row"} flex={1} pl={2} pr={2}>
                      <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("PA")}</span>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {jointBorrowers.length > 0 && (
                <Stack width={"50%"} direction={"row"}>
                  <Stack width={"8%"}></Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Stack>
                      <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                        <Stack>NIC No</Stack>
                        <Stack>:</Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack direction={"row"} flex={1} pl={2} pr={2}>
                        <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("JB", jointBorrowers[0])}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>

            {isThisDayAvailable && (
              <Stack width={"100%"} direction={"row"} mb={2}>
                <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                  <Stack width={"8%"}></Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Grid item>
                      This .......... day of ...................... 20.....{" "}
                      <div>
                        at <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranch}</span> Branch
                      </div>
                    </Grid>
                  </Stack>
                </Stack>
                {jointBorrowers.length > 0 && (
                  <Stack width={"50%"} direction={"row"}>
                    <Stack width={"8%"}></Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Grid item>
                        This .......... day of ...................... 20.....{" "}
                        <div>
                          at <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranch}</span> Branch
                        </div>
                      </Grid>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}

            {isDateAvailable && (
              <Stack width={"100%"} direction={"row"} mb={2}>
                <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                  <Stack width={"8%"}></Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Stack direction={"row"}>
                      <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                        <Stack>Date</Stack>
                        <Stack>:</Stack>
                      </Stack>
                      <Stack pl={1}>...........................................</Stack>
                    </Stack>
                    <Stack>
                      <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                    </Stack>
                  </Stack>
                </Stack>
                {jointBorrowers.length > 0 && (
                  <Stack width={"50%"} direction={"row"}>
                    <Stack width={"8%"}></Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Stack direction={"row"}>
                        <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                          <Stack>Date</Stack>
                          <Stack>:</Stack>
                        </Stack>
                        <Stack pl={1}>...........................................</Stack>
                      </Stack>
                      <Stack>
                        <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}

            {isSignAtAvailable && (
              <Stack width={"100%"} direction={"row"} mb={2}>
                <Stack width={jointBorrowers.length > 0 ? "50%" : "100%"} direction={"row"}>
                  <Stack width={"8%"}></Stack>
                  <Stack width={"92%"} direction={"row"}>
                    <Stack direction={"row"}>
                      <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                        <Stack>Signed at</Stack>
                        <Stack>:</Stack>
                      </Stack>
                      <Stack pl={1}>...........................................</Stack>
                    </Stack>
                    <Stack>
                      <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                    </Stack>
                  </Stack>
                </Stack>
                {jointBorrowers.length > 0 && (
                  <Stack width={"50%"} direction={"row"}>
                    <Stack width={"8%"}></Stack>
                    <Stack width={"92%"} direction={"row"}>
                      <Stack direction={"row"}>
                        <Stack width={90} direction={"row"} justifyContent={"space-between"}>
                          <Stack>Signed at</Stack>
                          <Stack>:</Stack>
                        </Stack>
                        <Stack pl={1}>...........................................</Stack>
                      </Stack>
                      <Stack>
                        <Stack direction={"row"} flex={1} pl={2} pr={2}></Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <SinglePageBreak />

        {Object.keys(segments).map((key, index) => {
          return (
            <Stack width={"100%"} direction={"row"} flexWrap={"wrap"}>
              <BuildSegment data={segments[key]} type={"JB"} numbering={index} />
            </Stack>
          );
        })}
      </Grid>
    </>
  );
}

export default CommonSignatureBlock;
