import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack, Typography } from "@mui/material";
import appStrings from "../../../../../configs/constants/appStrings";
import { userDetails } from "../../../../../configs/mainStore";
import { closeBtn } from "../../../../../styles";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

const ConditionModal = (params) => {
  const initialData = { type: "", title: "", description: "" };
  const showErrors = useState(false);
  const conditionState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { productType, sector, scheme } = params;

  const addCondition = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      let productCatelog = "";
      if (productType != "" && sector != "" && scheme != "") {
        productCatelog = `${productType}-${sector}-${scheme}`;
      }

      const getConditionType = () => {
        let conditionTypeOptions = "";
        appStrings.conditionTypeOptions.map((item) => {
          if (item.id === conditionState?.type?.get()) {
            conditionTypeOptions = item.name;
          }

          return conditionTypeOptions;
        });
      };

      const conditonObj = {
        COND_TYPE: conditionState?.type?.get(),
        COND_TYPE_DES: getConditionType(),
        COND_DESCRIPTION: conditionState?.description.get(),
        COND_KEY: "",
        CREATED_BY: loginUser?.userId?.get(),
        DESCRIPTION: conditionState?.description.get(),
        F_DESC: conditionState?.description.get(),
        ID: 0,
        PLACEHOLDERS: "{}",
        PRODUCT_CATALOG: productCatelog,
        PRODUCT_TYPE_ID: productType,
        STATUS: "",
        MAPPING_ID: new Date().getTime(),
        TITLE: conditionState?.title?.get(),
      };
      const dataArr: any[] = [];
      dataArr.push(conditonObj);

      let obj = {
        conditions: dataArr,
        applicationId: params?.applicationId,
        type: "special_offer_condition",
      };

      if (params.addItem) {
        params.addItem(obj);
      } else {
      }

      closeGlobalModal();
    }
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return (
      conditionState.type.get() === null ||
      conditionState.title.get() === "" ||
      conditionState.description.get() === ""
    );
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="basic-font font-size-18 basic-font-color-bold">
          Create Offer Letter Condition
        </div>
      </Stack>
      <Grid container spacing={2} mt={4} className="modal-text-field" px={2}>
        <Stack width={"40%"}>
          <Typography variant="subtitle2" color={"gray"}>
            Condition Type <span className="error">*</span>
          </Typography>
          <SelectComponent
            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            value={conditionState?.type?.get()}
            values={appStrings?.conditionTypeOptions}
            onChange={(e) => {
              resetError();
              conditionState?.type?.set(e.target.value);
            }}
          />
        </Stack>

        <Stack width={"100%"} mt={2}>
          <Typography variant="subtitle2" color={"gray"}>
            Condition Title <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={conditionState?.title?.get()}
            onChange={(e) => {
              resetError();
              conditionState.title.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={1}
          />
        </Stack>

        <Stack width={"100%"} mt={2}>
          <Typography variant="subtitle2" color={"gray"}>
            Condition Description <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={conditionState?.description.get()}
            onChange={(e) => {
              resetError();
              conditionState.description.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={4}
          />
        </Stack>
      </Grid>
      {showErrors.get() && (
        <Typography variant="caption" className="error">
          Title and Description required to proceed
        </Typography>
      )}

      <Stack
        direction="row"
        spacing={2}
        justifyContent="end"
        pt={3}
        mt={2}
        pr={2}
        pb={1}
      >
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Add"
          variant="contained"
          onClick={addCondition}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default ConditionModal;
