import React, { createRef, useEffect } from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Downgraded, useState } from "@hookstate/core";
import { appSetting, userDetails } from "../../../../../configs/mainStore";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import { CRIB_DOCUMENT_ID } from "../../../../../configs/constants/contants";
import { VARIANT } from "../../../../../configs/constants/styleValues";
import { uploadCribFile } from "../../../../../services/creditFileApiCall";
import { closeBtn } from "../../../../../styles";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { compare } from "../../../../../utility/other";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import ValidateDatePicker from "../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FileUploadComponent } from "../../../DropzoneComponent/FileUploadComponent";
import { addToaster } from "../../../GlobalToast";
import { InputGrid } from "../../../InputGrid/InputGrid";
import { cribStatusColors } from "../../../WorkflowComponents/utils";

export const AddCribRecordModal = (params) => {
  const { t: translate } = useTranslation();
  const docUploadRef: any = createRef();
  const appSettings: any = useState(appSetting);
  const masterDataStore = MasterDataProvider.provideMasterData();

  const { userId }: any = userDetails.attach(Downgraded).get();

  const [cribRecordKey, setCribRecordKey] = React.useState("");
  const [receivedDate, setReceivedDate] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>({
    cribRecordKey: "",
    receivedDate: "",
    cribStatus: "",
    arrearsStatus: "",
  });

  const [dropdownList, setDropdownList] = React.useState([]);
  const [arrearsDropdownList, setArrearsDropdownList] = React.useState([]);
  const [cribStatus, setCribStatus] = React.useState("");
  const [arrearsStatus, setArrearsStatus] = React.useState("");
  const [nics, setNics]: any[] = React.useState([]);

  useEffect(() => {
    let values = masterDataStore?.getMasterData("CRIB_STATUS").get();
    if (values && values.length > 0) {
      let data = values.map((obj) => Object.assign({}, obj));
      const sortedList = data.sort((a, b) => compare(a, b, "id"));
      setDropdownList(sortedList);
    }

    let arrearsObj = masterDataStore?.getMasterData("CRIB_RECODE").get();

    if (arrearsObj && arrearsObj.length > 0) {
      let data = arrearsObj.map((obj) => Object.assign({}, obj));
      const arrearsSortedList = data.sort((a, b) => compare(a, b, "id"));
      setArrearsDropdownList(arrearsSortedList);
    }

    if (params.clientMeta?.nics) {
      setNics(params.clientMeta?.nics);
    }
  }, []);

  const applicantType = () => {
    if (params.clientType === 1) {
      return "Primary Applicant";
    } else if (params.clientType === 2) {
      return "Joint Borrower";
    } else if (params.clientType === 3) {
      return "Gurantor";
    } else {
      return null;
    }
  };

  const onSubmit = async () => {
    let isValid = true;

    let tempError = errorMessage;
    if (cribRecordKey === "") {
      tempError = { ...tempError, ...{ cribRecordKey: "Required!" } };
      isValid = false;
    }

    if (receivedDate === "") {
      tempError = { ...tempError, ...{ receivedDate: "Required!" } };
      isValid = false;
    }

    if (cribStatus === "") {
      tempError = { ...tempError, ...{ cribStatus: "Required!" } };
      isValid = false;
    }

    if (arrearsStatus === "") {
      tempError = { ...tempError, ...{ arrearsStatus: "Required!" } };
      isValid = false;
    }

    setErrorMessage(tempError);

    if (isValid) {
      setIsLoading(true);

      const primaryDocUploadResult = await docUploadRef?.current?.uploadSingleImg();

      if (primaryDocUploadResult?.status) {
        addToaster({
          status: "success",
          title: "Updated",
          message: "New CRIB Record Added",
        });

        params?.callBackOnAction();
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: primaryDocUploadResult?.message,
        });
      }

      setIsLoading(false);
    }
  };

  const onChangeCribRecordKey = (value) => {
    setCribRecordKey(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ cribRecordKey: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ cribRecordKey: "" } });
    }
  };

  const onChangeDateField = (value) => {
    setReceivedDate(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ receivedDate: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ receivedDate: "" } });
    }
  };

  const onChangeStatus = (value: any) => {
    setCribStatus(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ cribStatus: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ cribStatus: "" } });
    }
  };

  const onChangeArrears = (value: any) => {
    setArrearsStatus(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ arrearsStatus: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ arrearsStatus: "" } });
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">
          {"Add CRIB Record"} {applicantType() ? `- ${applicantType()}` : ""}
        </div>
      </Stack>

      <Stack direction={"row"} width={"100%"} pt={1.5} gap={1} alignItems={"center"}>
        <Stack flex={1} pt={2}>
          <SelectComponent
            label={"CRIB Record ID"}
            value={cribRecordKey}
            values={nics}
            onChange={(e) => {
              onChangeCribRecordKey(e.target.value);
            }}
            disabled={isLoading}
            required={1}
          />
          {errorMessage?.cribStatus !== "" && (
            <Box pt={0.5}>
              <span className="error font-size-14">{errorMessage?.cribRecordKey}</span>
            </Box>
          )}
        </Stack>

        <Stack flex={1} pt={2}>
          <InputGrid visibility={1} variant={VARIANT.fullWidth}>
            <ValidateDatePicker label={"Date Recieved (DD/MM/YYYY)"} value={receivedDate === "" ? null : receivedDate} onChange={(value) => onChangeDateField(value)} required={1} />
          </InputGrid>
          {errorMessage?.receivedDate !== "" && (
            <Box>
              <span className="error font-size-14">{errorMessage?.receivedDate}</span>
            </Box>
          )}
        </Stack>
      </Stack>

      <Grid container spacing={1} mt={1} rowSpacing={1} className="modal-text-field">
        <Grid item xs={12} md={12} className="">
          {dropdownList?.length < 5 ? (
            <Box style={{ marginTop: 8, marginBottom: 8 }}>
              <div className="basic-font basic-font-color font-size-16 font-weight-100" style={{ marginBottom: 0, marginTop: 0 }}>
                CRIB Status <sup>*</sup>
                <Grid container className="align-center" columns={24}>
                  {dropdownList?.map((item: any) => {
                    let color = cribStatusColors[item?.id]?.color ? cribStatusColors[item?.id]?.color : cribStatusColors["default"]?.color;
                    return (
                      <Grid container item xs={6} sm={6} direction="column" justifyContent="center">
                        <div
                          className={`basic-font font-size-14 fund-release-chip`}
                          style={cribStatus === item?.id ? { color: "white", backgroundColor: color } : { color: color, backgroundColor: `${color}3d` }}
                          onClick={() => {
                            onChangeStatus(item?.id);
                          }}
                        >
                          {item?.name}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Box>
          ) : (
            <SelectComponent
              label={"CRIB Status"}
              value={cribStatus}
              values={dropdownList}
              onChange={(e) => {
                onChangeStatus(e.target.value);
              }}
              disabled={isLoading}
              required={1}
            />
          )}
          {errorMessage?.cribStatus !== "" && (
            <Box>
              <span className="error font-size-14">{errorMessage?.cribStatus}</span>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={12} className="" mb={1.5}>
          <SelectComponent
            label={"Arrears Status"}
            value={arrearsStatus}
            values={arrearsDropdownList}
            onChange={(e) => {
              onChangeArrears(e.target.value);
            }}
            disabled={isLoading}
            required={1}
          />
          {errorMessage?.arrearsStatus !== "" && (
            <Box>
              <span className="error font-size-14">{errorMessage?.arrearsStatus}</span>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={
              <span>
                Upload CRIB File
                <span className="MuiFormLabel-asterisk"> *</span>
              </span>
            }
            component={
              !params?.entryId ?
                <Stack py={2}>
                  <Typography color={"gray"}> Crib Attachment Unavailable. Contact Administrator. </Typography>
                </Stack>
                : <FileUploadComponent
                  fileDetails={{
                    ref: docUploadRef,
                    isMandatory: true,
                    allowedFormats: "application/pdf",
                    maxFileSize: 4000000,
                    documentKey: "",
                  }}
                  uploadDataObject={{
                    applicationParticipantId: params?.entryId,
                    cribRecordKey: cribRecordKey,
                    dateReceived: moment(receivedDate).format("DD-MMM-YY"),
                    uploadedBy: userId,
                    applicationId: params.applicationId,
                    documentId: CRIB_DOCUMENT_ID,
                    cribStatus: cribStatus,
                    arrearsStatus: arrearsStatus,
                    applicantType: applicantType(),
                  }}
                  isPreviewHave={false}
                  fileUploadApi={uploadCribFile}
                />
            }
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};
