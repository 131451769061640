import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { calculateAnnualValue, getDefaultFrequencies, incomeExpenseFrequency, updatedIncomeExpenseFrequency } from "../../../../../../utility/util";
import DetailItem from "../../DetailItem";

const CultivationIncome = ({ cultivationIncome, masterData }) => {
  const { t: translate } = useTranslation();

  const getTotalCultivationIncome = () => {
    let total = 0;
    cultivationIncome?.income?.forEach((cultivation) => {
      const { annualDefaultVal, monthlyDefaultVal } = getDefaultFrequencies(cultivation?.incomeFrequencyCultivation);

      const totalItem = calculateAnnualValue(cultivation?.seasonalIncome, annualDefaultVal * monthlyDefaultVal, 1);
      total += totalItem;
    });
    return total;
  };

  const getTotalCultivationExpense = () => {
    let total = 0;
    cultivationIncome?.expense?.forEach((cultivation) => {
      const { annualDefaultVal, monthlyDefaultVal } = getDefaultFrequencies(cultivation?.expenseFrequencyCultivation);

      const totalItem = calculateAnnualValue(cultivation?.seasonalExpense, annualDefaultVal * monthlyDefaultVal, 1);
      total += totalItem;
    });
    return total;
  };

  const TotalAnnualProfitCultivation = () => {
    let profit: any = getTotalCultivationIncome() - getTotalCultivationExpense();
    return `LKR ${formatCurrency(profit ? profit : 0)}`;
  };

  const getTotalCultivationExpenseData = () => {
    const total = getTotalCultivationExpense();
    return `LKR ${formatCurrency(total ? total : 0)}`;
  };

  const getTotalCultivationIncomeData = () => {
    const total = getTotalCultivationIncome();
    return `LKR ${formatCurrency(total ? total : 0)}`;
  };

  return (
    <div className="full-width margin-xs-b">
      {cultivationIncome ? (
        <>
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            Cultivation Income
          </Grid>
          <Grid container>
            <Grid container p={1} className="data-box" spacing={1}>
              <Grid container item mt={1}>
                <Grid item xs={3}>
                  <DetailItem title="Net Annual Income" value={getTotalCultivationIncomeData()} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem title="Net Annual Expenses" value={getTotalCultivationExpenseData()} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem title="Net Annual Profit" value={TotalAnnualProfitCultivation()} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid container p={1} className="data-box">
                  <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">Income</div>
                  </Grid>

                  {cultivationIncome?.income?.length > 0 &&
                    cultivationIncome?.income
                      ?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)
                      ?.map((incomeData, key) => {
                        const cultivationFrequency: any = updatedIncomeExpenseFrequency?.find((t) => t.id == incomeData?.incomeFrequencyCultivation);
                        const frequencyName: any = cultivationFrequency?.name ? translate(cultivationFrequency.name) : "";

                        const getAnnualFunc = (amount, freq) => {
                          const { annualDefaultVal, monthlyDefaultVal } = getDefaultFrequencies(freq);
                          const val = calculateAnnualValue(amount, annualDefaultVal, monthlyDefaultVal);
                          return `LKR ${formatCurrency(val)}`;
                        };

                        return (
                          <Grid container item spacing={1} p={1} key={key} className="data-box">
                            <Grid item xs={3}>
                              <DetailItem title="Income Type" value={incomeData?.incomeTypeCultivation ? incomeData.incomeTypeCultivation : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem title="Frequency" value={frequencyName ? frequencyName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Seasonal Income" value={incomeData?.seasonalIncome ? `LKR ${formatCurrency(incomeData.seasonalIncome)}` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Annual Income" value={getAnnualFunc(incomeData?.seasonalIncome, incomeData?.incomeFrequencyCultivation)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>

                <Grid container p={1} className="data-box">
                  <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">Expense</div>
                  </Grid>

                  {cultivationIncome?.expense?.length > 0 &&
                    cultivationIncome?.expense
                      ?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)
                      ?.map((expenseData, key) => {
                        const expenseFrequency: any = incomeExpenseFrequency?.find((t) => t.id == expenseData?.expenseFrequencyCultivation);
                        const frequencyName: any = expenseFrequency?.name ? translate(expenseFrequency.name) : "";

                        const getAnnualFunc = (amount, freq) => {
                          const { annualDefaultVal, monthlyDefaultVal } = getDefaultFrequencies(freq);
                          const val = calculateAnnualValue(amount, annualDefaultVal, monthlyDefaultVal);
                          return `LKR ${formatCurrency(val)}`;
                        };

                        return (
                          <Grid container item spacing={1} p={1} key={key} className="data-box">
                            <Grid item xs={3}>
                              <DetailItem title="Expense Type" value={expenseData?.expenseTypeCultivation ? expenseData.expenseTypeCultivation : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Frequency" value={frequencyName ? frequencyName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Seasonal Expense" value={expenseData?.seasonalExpense ? `LKR ${formatCurrency(expenseData.seasonalExpense)}` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem title="Annual Expense" value={getAnnualFunc(expenseData?.seasonalExpense, expenseData?.expenseFrequencyCultivation)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CultivationIncome;
