/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from '@hookstate/core';
import { Paper, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CachingStorage from '../../../../../../../../../../services/cacheStorage';
import { toast } from '../../../../../../../../GlobalToast';
import { CircularProgressComponent } from '../../../../../../../../ProgressComponent/ProgressComponent';
import { endPointKeys, getProfitLossAccountsData, saveProfitLossAccountsData } from '../../../../Api/AppraisalApi';
import { profitAndLossAccountsTitleArray } from '../../../../Configs/AuditedFinancialsDetailsConfigs/AuditedFinancialsDetailsConfigs';
import { expensesDataKeys, grossProfitDataKeys, profitAndLossAccountsDescriptions, taxDataKeys } from '../../../../Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs';
import SubSectionHeader from '../../../../Helpers/SubSectionHeader/SubSectionHeader';
import { filterAutoPopulatedKeys } from '../../../../Utilities/Util';
import { GeneralizedControllerForForecastedCash } from '../../../FinancialPositionOfTheCompany/Calculations/GeneralizedControllerForForecastedCash';
import FinancialPositionOfCompanyGeneralizedTableComponent from '../../../FinancialPositionOfTheCompany/Helpers/FinancialPositionOfCompanyGeneralizedTableComponent';

const ProfitAndLossAccountsType = ({ applicationId }) => {
    const isLoading = useState(false);
    const [localData, setLocalData] = React.useState({});
    const grossProfitData = filterAutoPopulatedKeys(grossProfitDataKeys);
    const expenses = filterAutoPopulatedKeys(expensesDataKeys);
    const taxes = filterAutoPopulatedKeys(taxDataKeys);
    const { t: translate } = useTranslation();
    const [allTableHeaderTitles, setAllTableHeaderTitles] = React.useState([...profitAndLossAccountsTitleArray]);

    useEffect(() => {
        (async function () {
            isLoading.set(true);
            const { data = {}, selectedYears = [] }: any = await getProfitLossAccountsData(applicationId, true);
            if (Object.values(data).length > 0) {
                setLocalData(data);
                setAllTableHeaderTitles(selectedYears);
                isLoading.set(false);
            }
            isLoading.set(false);
        }());

        return () => {
            setLocalData({})
        }

    }, []);

    const getAllCalculations = useMemo(() => {
        const generalizedController = new GeneralizedControllerForForecastedCash(
            localData,
            profitAndLossAccountsTitleArray,
            grossProfitData,
            expenses,
            taxes
        );

        const totals = {
            grossProfit: generalizedController.getTotalGrossProfit(),
            revenueIncome: generalizedController.totalRevenue,
            netProfitBeforeTax: generalizedController.getTotalNetProfitBeforeTax(),
            netProfitAfterTax: generalizedController.getTotalNetProfitAfterTax(),
            netExpenses: generalizedController.getNetTotalExpenses()
        }

        return {
            ...totals
        };
    }, [localData]);

    const onChange = useCallback((value, year, dataKey) => {
        setLocalData((prevFormData) => ({
            ...prevFormData,
            [year]: {
                ...prevFormData[year],
                [dataKey]: value,
            },
        }));
    }, []);

    const onSaveChanges = async () => {

        const dataTobeSave = {
            applicationId,
            profitLossData: {
                data: localData,
                selectedYears: allTableHeaderTitles,
                totals: getAllCalculations,
                isPublish: 0,
            }
        }
        const response = await saveProfitLossAccountsData(dataTobeSave, true);

        if (response.status) {
            CachingStorage.clear(`${applicationId}-${endPointKeys.auditProfitLossAccountsData}`)
            toast({ status: "success", message: `${translate("profitAndLossAccounts")} saved successfully !` });
        }
        else toast({ status: "error", message: `Error on Saving ${translate("profitAndLossAccounts")}!` });
    }

    return <Stack width={"100%"} gap={2} component={Paper} elevation={0}>
        <SubSectionHeader
            isNeedDropdown={false}
            title={translate("profitAndLossAccounts")}
            onSubmit={onSaveChanges}
            dropDownProps={{
                options: [],
                profitAndLossAccounts: "",
            }}
        />

        {isLoading.get() ? <CircularProgressComponent size={30} sx={{ top: '50%', right: '50%', position: 'absolute', zIndex: 100 }} />
            :
            <FinancialPositionOfCompanyGeneralizedTableComponent
                label={"description"}
                onChange={onChange}
                defaultObject={localData}
                profitAndLossAccountsTitleArray={profitAndLossAccountsTitleArray}
                descriptionList={profitAndLossAccountsDescriptions}
                isShowGeneralized={true}
                getAllCalculations={getAllCalculations}
            />
        }
    </Stack>


}

export default ProfitAndLossAccountsType
