import DoneIcon from "@mui/icons-material/Done";
import { Grid, TextField } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";
import { select_basic } from "../../../../../../../../../styles";
import AppConfig from "../../../../../../../../../utility/AppConfig";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";



const GoogleMapFC = ({ metaData, value, onChange }) => {
  const authKey: string = AppConfig.config["googleMapAuthenticateKey"] ?? null;
  const center: any = { lat: 6.927079, lng: 79.861244 };
  const [currentLocation, setCurrentLocation] = React.useState(center);
  const [map, setMap]: any = React.useState(/**@type google.maps.Map */);
  const [autocomplete, setAutocomplete]: any = React.useState(null);
  const originRef: any = useRef();
  const serchedRef: any = useRef();
  const [address, setAddress] = React.useState("");

  useEffect(() => {
    if (value["googleMapLocationData"]) {
      console.log(value["googleMapLocationData"]["dirrections"])
      setCurrentLocation(value["googleMapLocationData"]["dirrections"] ?? center);
      setAddress(value["googleMapLocationData"]["address"] ?? "");
    }

    if (!value["googleMapLocationData"] && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }, [])


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: authKey,
    libraries: ["places"],
  });

  const onLoad = (autocomplete) => setAutocomplete(autocomplete);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {

      const place = autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setCurrentLocation({ lat: latitude, lng: longitude });
      } else {
        console.error("Location not found for the selected place.");
      }
    } else {
      console.error("Autocomplete is not loaded yet!");
    }
  };

  const geocodeLatLng = (latLng: google.maps.LatLngLiteral) => {
    const geocoder: google.maps.Geocoder = new window.google.maps.Geocoder();
    setCurrentLocation(latLng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        setAddress(results[0].formatted_address);
      } else {
        console.error('Geocoder failed due to:', status);
      }
    });
  };

  const submitCurrentLocation = () => {
    map.panTo(currentLocation);

    const googleMapLocationData = {
      dirrections: currentLocation,
      address,
    };

    value = {
      ...value,
      googleMapLocationData
    }

    onChange(metaData, value);

  };

  if (!isLoaded) {
    return <Skeleton animation="wave" />;
  }

  // return (
  //   <Stack position={"relative"} flexDirection={"row"} width={"65vw"} height={"70vh"} justifyContent={"center"} overflow={"auto"}>
  //     <Box position={"absolute"} sx={{ display: "flex", backgroundColor: "Menu", padding: "5px", width: "100%", justifyContent: "center" }} zIndex={1}>
  //       <Stack direction={"row"} gap={2} alignItems={"center"} width={"100%"}>
  //         <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
  //           <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} ref={originRef} >
  //             <TextField value={address} onChange={(e) => setAddress(e.target.value)} id="autocomplete" label="Select For Search an Address" variant="outlined" fullWidth ref={serchedRef} style={select_basic} />
  //           </Autocomplete>
  //         </Stack>
  //         <ButtonComponent title="Save Selection" variant="outlined" onClick={submitCurrentLocation} />
  //       </Stack>
  //     </Box>
  //     <Stack height={"100%"} width={"100%"}>

  //     </Stack>

  //   </Stack>
  // );

  return <Grid container gap={2} padding={2}>
    <Grid item xs={12} md={12} style={{ position: "sticky", top: 0, zIndex: 1000, background: "white" }}>
      <Grid container gap={2} display={'flex'} justifyContent={'space-between'} padding={2}>
        <Grid item xs={8}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} ref={originRef} >
            <TextField value={address} onChange={(e) => setAddress(e.target.value)} id="autocomplete" label="Select or Search an Address" variant="outlined" fullWidth ref={serchedRef} style={select_basic} />
          </Autocomplete>
        </Grid>
        <Grid item xs={3} alignSelf={'center'} justifyItems={"self-end"} >
          <ButtonComponent startIcon={<DoneIcon />} title="Save selected address" variant="outlined" onClick={submitCurrentLocation} sx={{ padding: '8px' }} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={12} style={{ height: "700px", width: "100%" }}>
      <GoogleMap onClick={(e: any) => {
        const lat = e?.latLng?.lat() || undefined;
        const lng = e?.latLng?.lng() || undefined;

        if (lat && lng) {
          const latLngObj = { lat, lng };
          geocodeLatLng(latLngObj)
        }
      }} center={currentLocation} zoom={15} mapContainerStyle={{ width: "100%", height: "100%" }} options={{ zoomControl: true, streetViewControl: false, fullscreenControl: false, mapTypeControl: false }} onLoad={(map: any) => setMap(map)}>
        <Marker position={currentLocation} />
      </GoogleMap>
    </Grid>
  </Grid>


};

export default GoogleMapFC;
