import { useHookstate } from "@hookstate/core";
import { Box, Divider, Grid, InputAdornment, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS, MASTER_DATA_KEYS as MD_KEYS } from "../../../../configs/constants/masterDataKeys";
import { continueButtonStyle, continueButtonStyleInNewLead, loadingButtonStyle, loadingButtonStyleInLead, resetButtonStyle } from "../../../../configs/constants/styleValues";
import { creditDetails, userSettings } from "../../../../configs/mainStore";
import { formatCurrency } from "../../../../services/currencyFormater";
import { OnDemandLengthValidator, OnDemandValueValidator } from "../../../../services/onDemandValidators";
import { repaymentTypeBtnStyles } from "../../../../styles";
import MasterDataProvider from "../../../../utility/MasterDataProvider";
import { formattedLoanTerm } from "../../../../utility/util";
import { StyledButton } from "../../../InputComponents/ButtonComponent/StyledButton";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";

export const Calculator = () => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Paper>
        <Grid container direction="column" p={3}>
          <SelectorButtons />
          <LoanAmount />
          <LoanPayTerms />
          <RepaymentType />
          <Footer />
        </Grid>
      </Paper>
    </Grid>
  );
};

// 1
const SelectorButtons = () => (
  <Grid item>
    <Stack direction="row">
      <StyledButton styles={repaymentTypeBtnStyles(undefined, undefined, undefined)} title="Repayments" onClick={undefined} />
      <StyledButton styles={repaymentTypeBtnStyles(undefined, undefined, undefined)} title="Affordability" onClick={undefined} />
    </Stack>
    <SectionDivider />
  </Grid>
);

//2
const LoanAmount = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Grid item mt={2}>
      <AppInputLabel label="I would like to borrow" />
      <AmountSlider value={value} handleChange={handleChange} startLabel="LKR , 0" endLabel="LKR , 100,000" />
    </Grid>
  );
};

//3
const LoanPayTerms = () => {
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);

  return (
    <Grid item>
      <LoanTerms year={year} yearChange={(e) => setYear(e.target.value())} month={month} monthChange={(e) => setMonth(e.target.value)} />
    </Grid>
  );
};

//4
const RepaymentType = () => {
  return (
    <Grid item mt={4}>
      <Repayments onClick={undefined} repaymentType={undefined} />
    </Grid>
  );
};

//5
const Footer = () => (
  <Grid item mt={3}>
    <ScreenFooterButtons titleOne="reset" onCancel={undefined} titleTwo="continue" onNext={undefined} />
  </Grid>
);

/**
 *  --------------------------- Reusable components ---------------------
 */

export const SideLabels = ({ leftLabel, leftLabelStyle, rightLabel, rightLabelStyle }) => (
  <Box display="flex" alignItems="center" mb={1}>
    <Box className="font-size-14 basic-font-color-bold basic-font" style={{ marginLeft: "5px" }}>
      {leftLabel}
    </Box>
    <Box className={rightLabelStyle ? rightLabelStyle : `font-size-14 basic-font-color-bold basic-font`} style={{ marginLeft: "auto" }}>
      {rightLabel}
    </Box>
  </Box>
);

const SectionDivider = ({ fullWidth = false }) => <Box mt={2}>{fullWidth ? <Divider style={{ width: "100%" }} /> : <Divider style={{ width: "90%" }} />}</Box>;

export const AppInputLabel = ({ label }) => <Box className="calculator_slider--title">{label}</Box>;

export const AmountSlider = ({ value, handleChange, startLabel, endLabel }) => {
  const settings = userSettings();
  let label = `Loan Amount * `;

  if (settings.loanAmountAsCreditLimit) {
    label = `Credit Limit * `;
  }

  const rightLabel = "";
  return (
    <>
      <SideLabels leftLabel={label} rightLabel={rightLabel} leftLabelStyle="calculator_slider--title-dark" rightLabelStyle="font-size-12" />
      <Box>
        <LoanAmountText value={value} handleChange={handleChange} minValue={startLabel} maxValue={endLabel} />
      </Box>
    </>
  );
};

export const LoanTerms = ({ year, yearChange, month, monthChange }) => (
  <>
    <SideLabels leftLabel="Loan Term" leftLabelStyle="calculator_slider--title-dark" rightLabel="" rightLabelStyle="loan-terms-years" />

    <Box display="flex">
      <Box mr={1} style={{ width: "50%" }}>
        <TextBoxComponent label="Years" value={year} onChange={yearChange} type="number" InputProps={{ inputProps: { min: 1, step: 1 } }} style={{ border: "none" }} />
      </Box>
      <Box style={{ width: "50%" }}>
        <TextBoxComponent
          label="Months"
          value={month}
          onChange={monthChange}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 11 } }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    </Box>
  </>
);

export const Repayments = ({ repaymentType, onClick }) => {
  const { t: translate } = useTranslation();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const repaymentTypes = masterDataStore?.getMasterData(MD_KEYS?.REPAYMENT_TYPE);
  return (
    <>
      <Box>
        <Box mb={1} className="font-size-14 basic-font-color-bold basic-font">
          Repayment Type *
        </Box>
        <Box display="flex">
          {repaymentTypes?.map((item, index) => (
            <Box key={index}>
              <StyledButton styles={repaymentTypeBtnStyles(repaymentType === item?.id?.get(), index === 0, index === repaymentTypes?.length - 1)} title={translate(item?.name?.get())} onClick={() => onClick(item?.id?.get())} disabled={item?.id?.get() === 2 || item?.id?.get() === 3 || item?.id?.get() === 4 || item?.id?.get() === 5} />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export const ScreenFooterButtons = ({ titleOne, onCancel, titleTwo, onNext, loading = false, disableSubmit = false }) => (
  <>
    <SectionDivider fullWidth={true} />
    <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
      <StyledButton styles={resetButtonStyle} title={titleOne} onClick={onCancel} />
      <StyledButton styles={loading ? loadingButtonStyle : continueButtonStyle} title={titleTwo} onClick={onNext} loading={loading} disabled={disableSubmit} />
    </Stack>
  </>
);

export const ScreenCreatLeadFooterButton = ({ titleOne, onCancel, titleTwo, onNext, loading = false, disableSubmit = false }) => (
  <>
    <SectionDivider fullWidth={true} />
    <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
      <StyledButton styles={resetButtonStyle} title={titleOne} onClick={onCancel} />
      <StyledButton styles={loading ? loadingButtonStyleInLead : continueButtonStyleInNewLead} title={titleTwo} onClick={onNext} loading={loading} disabled={disableSubmit} />
    </Stack>
  </>
);

export const InterestRateInput = ({ interestRate, interestRateChange, repaymentText, min = 0, max = 100, interestLabel = "Interest Rate" }) => {
  return (
    <Box>
      <NumericFormat
        label={`${interestLabel} ` + `[${min} - ${max}]`}
        customInput={TextBoxComponent}
        variant="outlined"
        required
        thousandSeparator={true}
        value={interestRate}
        decimalScale={2}
        onValueChange={(e) => {
          const { value, formattedValue } = e;
          interestRateChange(value, formattedValue);
        }}
        error={OnDemandValueValidator.proposedRate(interestRate, min, max)}
        onInput={OnDemandLengthValidator.proposedRate}
        InputProps={{
          endAdornment: <InputAdornment position="start">% p.a</InputAdornment>,
          inputProps: { min: 0 },
        }}
        isAllowed={(values) => {
          try {
            if (values.value && parseInt(values.value.toString()) > max) {
              return false;
            }
          } catch (error) {
            return false;
          }
          return true;
        }}
        style={{ height: "23px !important" }}
      />
    </Box>
  );
};

/**
 * ---------------------------- Sub components ---------------------------
 */

export const LoanAmountText = ({ value, handleChange, minValue, maxValue }) => {
  return (
    <NumericFormat
      customInput={TextBoxComponent}
      variant="outlined"
      required
      thousandSeparator={true}
      value={formatCurrency(value)}
      decimalScale={2}
      allowNegative={false}
      error={OnDemandValueValidator.loanAmount(value, minValue, maxValue)}
      onValueChange={(e) => {
        const { value, formattedValue } = e;
        handleChange(value, formattedValue);
      }}
      isAllowed={(values) => {
        if (values.floatValue && values.floatValue > parseFloat(maxValue?.toString() ?? "100000")) {
          return false;
        }
        return true;
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
      }}
      style={{ height: "23px !important" }}
    />
  );
};

export const LoanTermsRevamp = ({ loanTerms, loanTermsChange, min = 0, max = 240, loanFrequncy = 3 }) => {
  const { label, minLoanTenor, maxLoanTenor } = formattedLoanTerm(loanFrequncy, { min, max })

  return (
    <>
      <></>
      <TextBoxComponent
        value={loanTerms}
        label={label}
        required
        onChange={loanTermsChange}
        type="number"
        error={OnDemandValueValidator.loanTenor(loanTerms, minLoanTenor, maxLoanTenor)}
        onInput={(e) => OnDemandLengthValidator.loanTenor(e, maxLoanTenor.toString().length)}
        InputProps={{ inputProps: { min: minLoanTenor, max: maxLoanTenor } }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
    </>
  );
};

export const LoanCycles = ({ loanCycles, loanCyclesChange, range }) => {
  let labelLeft = `Loan Cycles *`;
  let labelRight = range[0] ? `[${range[0]} - ${range[1]}]` : "";

  return (
    <>
      <SideLabels leftLabel={labelLeft} leftLabelStyle="calculator_slider--title-dark" rightLabel={labelRight} rightLabelStyle="font-size-12" />

      <Box display="flex">
        <TextBoxComponent
          value={loanCycles}
          required
          onChange={loanCyclesChange}
          type="number"
          error={OnDemandValueValidator.loanCycles(loanCycles)}
          onInput={OnDemandLengthValidator.loanCycles}
          InputProps={{ inputProps: { min: 1, max: 24 } }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    </>
  );
};

export const GracePeriod = ({ gracePeriod, gracePeriodChange, range }) => {

  return (
    <>
      <Box display="flex">
        <TextBoxComponent
          label="Grace Period [1-12]"
          value={gracePeriod}
          required
          onChange={gracePeriodChange}
          type="number"
          error={OnDemandValueValidator.gracePeriod(gracePeriod)}
          onInput={OnDemandLengthValidator.gracePeriod}
          InputProps={{ inputProps: { min: 1, max: 12 } }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    </>
  );
};

export const LoanFrequncy = ({ loanFrequncy, loanFrequencyChange, required = false }) => {
  const masterDataStore = MasterDataProvider.provideMasterData();

  let filteredValues = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LOAN_FREQUENCY)?.filter((item) => {
    if (item?.id?.get() === 3) return item;
  });

  return (
    <>
      <SelectComponent
        label={required ? "Loan Frequency *" : "Loan Frequency"}
        value={loanFrequncy}
        onChange={loanFrequencyChange}
        values={filteredValues}
      />
    </>
  );
};

const filterableSchemes: any = [21, 41, 62];

export const FundSource = ({ fundSource, fundSourceChange, rquired = false }) => {
  const masterDataStore = MasterDataProvider.provideMasterData();
  const creditData: any = useHookstate(creditDetails);
  const isLimited = filterableSchemes.includes(creditData?.schemeTypes?.id?.get()) ? true : false;
  let filteredValues = isLimited
    ? masterDataStore?.getMasterData(MASTER_DATA_KEYS?.FUND_SOURCE)?.filter((item) => {
      if (item?.id?.get() === 2) return item;
    })
    : masterDataStore?.getMasterData(MASTER_DATA_KEYS?.FUND_SOURCE);

  if (!fundSource && filteredValues.length === 1) {
    fundSourceChange(filteredValues[0]);
  }

  return (
    <>
      <SelectComponent
        label={rquired ? "Fund Source *" : "Fund Source"}
        value={fundSource}
        onChange={fundSourceChange}
        values={filteredValues}
      />
    </>
  );
};
