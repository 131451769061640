import { useHookstate } from "@hookstate/core";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import {
  workflowStateVisibilityState,
} from "../../../../configs/mainStore";
import { assignTabControlData } from "../../../../services/apiCalls";
import { getTabControlData, getTabControlStages } from "../../../../services/workflowApiCalls";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import WorkflowStageControl from "./WorkflowStageControl";

export const AssignTabControlContainer = ({ value }) => {
  const workflowStateVisibilityData: any = useHookstate(
    workflowStateVisibilityState
  );
  const [rawTabStages, setRawTabStages]: any = React.useState([]);
  const [loading, setLoading]: any = React.useState(true);
  const updating: any = useHookstate(false);

  useEffect(() => {
    if (value?.value?.id || value?.id) {
      loadTabStages();
    }
  }, [value]);

  const loadTabStages = async () => {
    setLoading(true);
    const defaultStages = (await getTabControlStages(value?.value?.id || value?.id)) ?? [];
    await bindTabControlData(defaultStages);
  }

  const bindTabControlData = async (list) => {
    try {
      const { id: workflowId } = value.value || value;
      const result = (await getTabControlData(workflowId)) ?? [];

      let stages: Object = {};
      for (const stage of list) {
        stages[stage] = {
          tabKey: stage,
          startStage: undefined,
          stopStage: undefined,
          // freezeStage: undefined,
          workFlowId: workflowId,
        };
      }

      for (const stage of result) {
        stages[stage.TAB_KEY] = {};
        stages[stage.TAB_KEY]["tabKey"] = stage.TAB_KEY;
        stages[stage.TAB_KEY]["startStage"] = stage.START_STAGE;
        stages[stage.TAB_KEY]["freezeStage"] = stage.FREEZE_STAGE;
        stages[stage.TAB_KEY]["stopStage"] = stage.STOP_STAGE;
        stages[stage.TAB_KEY]["workFlowId"] = stage.WORKFLOW_ID;
        // stages[stage.TAB_KEY]["freezeStage"] = stage.FREEZE_STAGE;
      }

      const items = Object.keys(stages).map(key => stages[key]);

      setLoading(false);

      setRawTabStages(items);
    } catch (error) { }
  };

  const onSaveData = async (stages) => {
    if (!value) {
      return;
    }

    let _stages = stages.get().map(stg => {
      if (!stg.startStage || !stg.stopStage) return stg;
      if (stg.freezeStage === "" || stg.freezeStage === undefined) {
        return { ...stg, freezeStage: null };
      }

      return stg;
    });

    try {
      updating.set(true);
      const result = await assignTabControlData(_stages);
      updating.set(false);
      if (result?.status == 1) {
        addToaster({
          status: "success",
          title: "Updated",
          message: "Successfully Updated !",
        });
        workflowStateVisibilityData.set(null);
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went wrong !",
        });
      }
    } catch (error) { }
  };

  const onUpdateChange = async (configs) => {
    if (!configs.workFlowId || !configs.startStage || !configs.stopStage || !configs.tabKey) {
      return;
    }

    try {
      updating.set(true);
      const result = await assignTabControlData(configs);
      updating.set(false);
      if (result?.status == 1) {
        addToaster({
          status: "success",
          title: "Updated",
          message: "Successfully Updated !",
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went wrong !",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeTabData = () => {
    setRawTabStages([]);
  };

  if (loading) {
    return (
      <Stack
        height={"200px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgressComponent size={30} />
      </Stack>
    );
  }

  return (
    <WorkflowStageControl
      onSave={onSaveData}
      loading={loading}
      updating={updating}
      stages={rawTabStages}
      onUpdateChange={onUpdateChange}
      removeTabData={removeTabData}
      workFlow={value}
    />
  );
};
