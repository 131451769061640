import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { securityDocumentReferenceTypes } from "../../../../../configs/constants/contants";

function CommonDocReference({ referenceHandler, referenceType }) {
  return (
    <>
      <SelectComponent label={"Reference Location"} value={referenceType} values={securityDocumentReferenceTypes} onChange={(e) => referenceHandler(e.target.value)} required={false} defaultPlaceholder={true} />
    </>
  );
}

export default CommonDocReference;
