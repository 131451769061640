import { useState } from "@hookstate/core";
import appStrings from "../../../../../../../configs/constants/appStrings";
import { downloadInspectionForm, saveAppFormDataJson } from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import { addToaster } from "../../../../../GlobalToast";
import { SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import CollateralViewController from "./CollateralViewController";
import { currentCollaterlTabId } from "./Helpers/collateralStores";
import { collateralTabRendering, collaterlComponentMapping, requetsDtlKeyMapping } from "./Helpers/configs";
import { creditData } from "../../../../../../../configs/mainStore";

const CollateralInspections = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { formData, requestId } = data;
  const { securityData } = formData;
  const currentId = useState<string>(currentCollaterlTabId);
  const creditFileData: any = useState(creditData);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
  };

  const onSubmit = async (dataItemArray) => {
    const dataKey = requetsDtlKeyMapping[currentId.get()].split("_");
    const intermediateObject = {
      ...securityData,
      [dataKey[0]]: {
        ...securityData[dataKey[0]],
        [dataKey[1]]: dataItemArray,
      }
    };

    // const respose: any = await SaveInspectionChanges(intermediateObject, SectionKey.collateralInspection, inspectionFormState, applicationId);

    const response = await saveAppFormDataJson(requestId, appStrings?.participantSections?.securityData, intermediateObject);

    if (response?.status == 1) {

      creditFileData?.formData?.securityData.set(intermediateObject);

      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.collateralInspection);
    } catch (error: any) {
      GlobalLogger.log("collateral inspection", error.message);
    }
  };

  const props = {
    onSubmit,
    downloadInspectionFormTemplate,
    collateralTabRendering,
    collaterlComponentMapping,
    data,
    applicationId,
    otherParams,
  };

  return <CollateralViewController {...props} />;
};

export default CollateralInspections;
