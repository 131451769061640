import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import moment from "moment";
import { DatePickerProps } from "../../../configs/interfaces";

const ValidateTimePicker: React.FC<DatePickerProps> = ({ value, label, onChange, required, sx }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
      <DesktopTimePicker
        value={value ? moment(value) : null}
        sx={sx}
        label={<span
          dangerouslySetInnerHTML={{
            __html: `${label}${required ? '<span class="MuiFormLabel-asterisk"> *</span>' : ""}`,
          }}
        />}
        onChange={onChange}
        slotProps={{ textField: { size: "small" } }}
        ampm={true}
      />
    </LocalizationProvider>
  );
};

export default ValidateTimePicker;
