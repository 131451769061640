/**
 *
 * @param object object you want to check the type
 * @returns true or falls
 */

export const isObject = (object) =>
  Object.prototype.toString.call(object) === "[object Object]";

export const isArray = (object) => Array.isArray(object);
