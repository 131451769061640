import { validatelength, validatePositiveNumberWithDecimalPlaces, validateTextWithCharactersSpacesAndDots } from "../../validation-rules/validationRules";

export const assetsDetailsValidationOnNext = (changes) => {
  const assetsDetailsErrors: any = {};
  for (let i = 0; i < changes?.length; i++) {
    if (!changes[i].removedItem) {
      const extentOrRegNoOrOtherVal = changes[i]?.extentOrRegNoOrOther ? extentOrRegNoOrOtherValidation(`${i + 1} : Extent or Reg No or Other`, changes[i]?.extentOrRegNoOrOther) : null;

      const mortgageValueVal = changes[i]?.mortgageValue ? mortgageValueValidation(`${i + 1} : Market Value`, changes[i]?.mortgageValue) : null;

      const assetGrossValueVal = changes[i]?.assetGrossValue ? grossValueValidation(`${i + 1} : Force Sales Value`, changes[i]?.assetGrossValue) : null;

      const assetDescriptionVal = changes[i]?.assetDescription ? assetDescriptionValidation(`${i + 1} : Asset Description`, changes[i].assetDescription) : null;

      if (extentOrRegNoOrOtherVal || mortgageValueVal || assetGrossValueVal || assetDescriptionVal) {
        assetsDetailsErrors[i] = {
          extentOrRegNoOrOther: extentOrRegNoOrOtherVal,
          mortgageValue: mortgageValueVal,
          assetGrossValue: assetGrossValueVal,
          assetDescription: assetDescriptionVal,
        };
      }
    }
  }

  return Object.keys(assetsDetailsErrors).length > 0 ? assetsDetailsErrors : 1;
};

export const extentOrRegNoOrOtherValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkExtentRegNo = validateTextWithCharactersSpacesAndDots(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkExtentRegNo) result.push(checkExtentRegNo);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const grossValueValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumberWithDecimalPlaces(fieldName, value, 2);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const mortgageValueValidation = (fieldName, value) => {
  const result: any[] = [];
  const mortgageValue = validatePositiveNumberWithDecimalPlaces(fieldName, value, 2);
  const checkLength = validatelength(fieldName, value);
  if (mortgageValue) result.push(mortgageValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const assetDescriptionValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLength = validatelength(fieldName, value, null, null, 2000);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
