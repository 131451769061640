import { Stack } from '@mui/material';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';
import { closeBtn } from '../../../../../../styles';
import PreDefinedConditionsModalList from './PreDefinedConditionsModalList';

export function PreDefinedConditionsModal({ params }) {
  let checkedList = [];

  const sendFilteredConditions = () => {
    if (checkedList.length > 0) {
      params.callBackOnAction(checkedList);
      closeGlobalModal();
    }
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  return (
    <Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Add Pre-Defined Conditions</div>
      </Stack>

      <Stack flex={1}>
        <PreDefinedConditionsModalList
          selectedList={(items) => {
            checkedList = items;
          }}
          applicationId={params?.applicationId}
        />
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="ADD"
          variant="contained"
          onClick={sendFilteredConditions}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Stack>
  );
}
