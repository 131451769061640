import { useState } from '@hookstate/core';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { masterDataDetails, workflowMasterData } from '../../../../../configs/mainStore';

import { getAppraisalData, getHistoryDataForApplication } from '../../../../../services/creditFileApiCall';
import { standardDateFormatter } from "los-util";
import { RenderingHelper } from '../Application/Employment/Components';
import DetailItem, { DetailInlinedItem } from '../DetailItem';

export const AppraisalSummary = ({ applicationId }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [bankRelation, setBankRelation] = React.useState<any>({});

  const [appraisalSummary, setAppraisalSummary] = React.useState<any>({});
  const recommendedApprovedBy: any = useState([]);

  const { wf_system_users, wf_actions }: any = useState(workflowMasterData);
  const masterData: any = useState(masterDataDetails);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      if (applicationId) {
        const params = {
          applicationId: applicationId,
          section: 'all',
        };
        const data: any = await getAppraisalData(params);

        const data2 = await getHistoryDataForApplication(applicationId);
        if (data2?.length > 0) {
          recommendedApprovedBy.set(data2);
        }

        if (data) {
          setAppraisalSummary(data);

          setBankRelation(
            masterData?.BANK_RELATION?.get()?.find((t) => t.id == data?.analysis_loan_applicant?.bankRelation)
          );
        }
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="full-width padding-m-t">
      <Grid
        container
        p={1}
        className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
        style={{ backgroundColor: '#e6f0fa' }}
      >
        Appraisal
      </Grid>

      <Grid container>
        <div className="full-width" key={1}>
          <Grid container item p={1} spacing={1} className="data-box">
            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
              style={{ backgroundColor: '#e6f0fa' }}
            >
              Ability to Repay the Proposed Loan
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container item p={1} spacing={1} className="data-box">
                  <Grid container p={1} spacing={2} className="data-box">
                    <Grid container p={1}>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Limit Percentage of Proposed Income"
                          value={appraisalSummary?.ability_to_repay?.limitPercentageOfIncome}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Limit on Proposed Income"
                          value={appraisalSummary?.ability_to_repay?.limitOnProposedIncome}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Current Deductions"
                          value={appraisalSummary?.ability_to_repay?.currentDeductions}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Proposed Loan Installment"
                          value={appraisalSummary?.ability_to_repay?.proposedLoanInstallments}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Proposed Loan Interest"
                          value={appraisalSummary?.ability_to_repay?.proposedLoanInterest}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Debt Repayment as a Percentage of Existing Income"
                          value={appraisalSummary?.ability_to_repay?.DebtPaymentPercentage}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Grace Period"
                          value={appraisalSummary?.ability_to_repay?.gracePeriod}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
              style={{ backgroundColor: '#e6f0fa' }}
            >
              Salary Deduction
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container item p={1} spacing={1} className="data-box">
                  <Grid container p={1} spacing={2} className="data-box">
                    <Grid container p={1}>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Monthly Regulation Number"
                          value={appraisalSummary?.salary_deduction?.monthlyRegulatedNumber}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Validity Period From"
                          value={appraisalSummary?.salary_deduction?.validFrom}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Validity Period To"
                          value={appraisalSummary?.salary_deduction?.validTo}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
              style={{ backgroundColor: '#e6f0fa' }}
            >
              Analysis of the Loan Applicant
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container item p={1} spacing={1} className="data-box">
                  <Grid container p={1} spacing={2} className="data-box">
                    <Grid container p={1}>
                      <Grid item xs={4} mb={1}>
                        <DetailItem
                          title="Relation Ship with RDB"
                          value={bankRelation ? bankRelation?.name : '-'}
                          valueFontClass="font-size-14"
                          titleFontClass="font-size-12"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container item p={1} spacing={1} className="data-box">
                  {appraisalSummary?.analysis_loan_applicant?.loanApplicantList?.map((entry) => {
                    const msg: any = entry?.msg ? entry.msg : '';
                    const user = wf_system_users?.get()?.find((user) => user.id == entry.userId);
                    const date = entry?.created ? standardDateFormatter(entry.created) : '';

                    return (
                      <Grid container p={1} spacing={2} className="data-box" key={entry.sequence}>
                        <Grid container spacing={2} p={1} justifyContent="space-between">
                          <Grid item xs={4}>
                            <DetailInlinedItem title="By" value={user?.name} />
                          </Grid>
                          <Grid item xs={4}>
                            <DetailInlinedItem title="Role" value={'-'} />
                          </Grid>
                          <Grid item xs={4}>
                            <DetailInlinedItem title="Date" value={date} />
                          </Grid>
                        </Grid>
                        <Grid container p={1}>
                          <Grid item xs={12}>
                            <DetailItem
                              title="Comments"
                              value={msg}
                              valueFontClass="font-size-14"
                              titleFontClass="font-size-12"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>

            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
              style={{ backgroundColor: '#e6f0fa' }}
            >
              Analysis of the Loan Requirement
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container item p={1} spacing={1} className="data-box">
                  {appraisalSummary?.analysis_loan_requirement?.map((entry) => {
                    const msg: any = entry?.msg ? entry.msg : '';
                    const user = wf_system_users?.get()?.find((user) => user.id == entry.userId);
                    const date = entry?.created ? standardDateFormatter(entry.created) : '';

                    return (
                      <Grid container p={1} spacing={2} className="data-box" key={entry.sequence}>
                        <Grid container spacing={2} p={1} justifyContent="space-between">
                          <Grid item xs={4}>
                            <DetailInlinedItem title="By" value={user?.name} />
                          </Grid>
                          <Grid item xs={4}>
                            <DetailInlinedItem title="Role" value={'-'} />
                          </Grid>
                          <Grid item xs={4}>
                            <DetailInlinedItem title="Date" value={date} />
                          </Grid>
                        </Grid>
                        <Grid container p={1}>
                          <Grid item xs={12}>
                            <DetailItem
                              title="Comments"
                              value={msg}
                              valueFontClass="font-size-14"
                              titleFontClass="font-size-12"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>

            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
              style={{ backgroundColor: '#e6f0fa' }}
            >
              Recommended By /Approved By
            </Grid>

            <Grid container>
              <div className="full-width" key={1}>
                <Grid container>
                  <Grid container className="border-radius-5">
                    {recommendedApprovedBy?.get()?.map((entry) => {
                      const msg: any = entry?.note ? entry.note : '';
                      const user = wf_system_users?.get()?.find((user) => user.id == entry.actionBy);
                      const date = entry?.timeStamp ? standardDateFormatter(entry.timeStamp) : '';
                      const actionItem = wf_actions?.get()?.find((action) => action.id == entry.action);
                      const status =
                        actionItem?.actionKey == 'forward'
                          ? 'Recommended'
                          : actionItem?.actionKey == 'approve'
                          ? 'Approved'
                          : '';
                      return (
                        <Grid container p={1} spacing={2} className="data-box" key={entry.sequence}>
                          <Grid container spacing={2} p={1} justifyContent="space-between">
                            <Grid item xs={4}>
                              <DetailInlinedItem title="By" value={user?.fullName} />
                            </Grid>
                            <Grid item xs={4}>
                              <DetailInlinedItem title="Date" value={date} />
                            </Grid>
                            <Grid item xs={4}>
                              <DetailInlinedItem title="Status" value={status} />
                            </Grid>
                          </Grid>
                          <Grid container p={1}>
                            <Grid item xs={12}>
                              <DetailItem
                                title="Comments"
                                value={msg}
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
