import { Divider, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";

export default (props) => {
    return <Stack direction={'column'}>
        <Stack direction={'column'} style={{ backgroundColor: 'white' }}>
            <Stack justifyContent={'space-between'} direction={'row'}>
                <Typography variant="subtitle1"> Customer Performance (Collection - Overview) </Typography>
                <CloseIcon
                    onClick={() => {
                        closeGlobalModal();
                    }}
                    style={{
                        fontSize: 20,
                        cursor: "pointer"
                    }}
                />
            </Stack>
            <Divider></Divider>
        </Stack>
        <Stack mt={2} height={"calc(100vh - 100px)"}>
            <iframe src="https://los.xgencredit.com/performance-dashboard/#/" className="iframe-styles"></iframe>
        </Stack>
    </Stack >;
}