import { Downgraded, useHookstate } from "@hookstate/core";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { langDictionary } from "../../../../../../configs/languageDictionaryEn";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import AutoCompleteProxyComponent from "../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { toast } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import { agricultureLandIsA } from "../Utilities/Constants";
const AgricultureTaxDetails = React.lazy(() => import("./Sections/AgricultureTaxDetails"))
const AgricultureLandIsA = React.lazy(() => import("./Sections/AgricultureLandIsA"))
const AgricultureAssetValue = React.lazy(() => import("./Sections/AgricultureAssetValue"))

const AgricultureForm = ({ onNext, formData }) => {
    const { t: translate } = useTranslation();
    const form: any = useHookstate(formData);
    const masterData = MasterDataProvider.provideMasterData();
    let now = new Date();
    let maxMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

    useMemo(() => {
        let formData = form.attach(Downgraded).get();

        if (form?.agricultureLandIsA?.get()) {

            Object.keys(agricultureLandIsA).filter((key) => key !== formData?.agricultureLandIsA).forEach((key) => {
                if (formData[agricultureLandIsA[key]]) {
                    delete formData[agricultureLandIsA[key]];
                }
            });

            form.set(formData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form?.agricultureLandIsA?.get()]);

    const onValidationHandler = (): string[] => {
        return []
    }

    const handleSubmit = () => {
        let notify = { status: '', message: '' }

        try {
            let validationResult: string[] = onValidationHandler();

            if (validationResult.length > 0) {
                notify.status = 'error';
                notify.message = validationResult.join('\n');

                return;
            }

            let theForm = {
                ...form.attach(Downgraded).get(),
            }

            onNext(theForm)
        } catch (error) {
            notify.status = 'error';
            notify.message = translate(langDictionary.errorMessages.default);
        } finally {
            notify.status && toast(notify);
            closeGlobalModal();
        }
    }

    const handleClose = () => {
        try {

        } catch (error) {

        } finally {
            closeGlobalModal();
        }
    }

    return (
        <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 3, sm: 2, md: 3 }}
                alignItems={"center"}
            >
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <AutoCompleteProxyComponent
                        options={masterData.getConfigMasterData("typeOfAgriculturing")}
                        fullWidth={true}
                        onChange={(e) => {
                            form?.typeOfAgriculturing?.set(e ? e.id : "");
                        }}
                        label={translate(
                            langDictionary.agricultureSection.typeOfAgriculturing
                        )}
                        value={form?.typeOfAgriculturing?.get() || null}
                    // sx={select_basic_ins}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <AutoCompleteProxyComponent
                        options={masterData.getConfigMasterData("typeOfAgriculturing")}
                        fullWidth={true}
                        onChange={(e) => {
                            form?.subTypeOfAgriculturing?.set(e ? e.id : "");
                        }}
                        label={translate(
                            langDictionary.agricultureSection.subTypeOfAgriculturing
                        )}
                        value={form?.subTypeOfAgriculturing?.get() || null}
                    // sx={select_basic_ins}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <TextBoxComponent
                        label={translate(
                            langDictionary.agricultureSection.manufacturingCountryOfTheMachine
                        )}
                        value={form?.manufacturingCountryOfTheMachine?.get() ?? ""}
                        onInput={OnDemandValueFormatter.alphabeticalSpaces}
                        fullWidth={true}
                        onChange={(e) => {
                            form?.manufacturingCountryOfTheMachine?.set(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.agricultureSection.priceOfTheMachine)}
                        thousandSeparator={true}
                        value={formatCurrency(form?.priceOfTheMachine?.get() || 0)}
                        decimalScale={2}
                        onValueChange={(e: any) => {
                            form?.priceOfTheMachine.set(e.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">LKR</InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <Typography>
                        {translate(
                            langDictionary.agricultureSection.agriculturingExperienceInYears
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.years)}
                        thousandSeparator={true}
                        max={12}
                        value={form?.years?.get() || 0}
                        decimalScale={2}
                        onValueChange={(e: any) => {
                            form?.years.set(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.months)}
                        thousandSeparator={true}
                        max={maxMonth}
                        value={form?.months?.get() || 0}
                        onValueChange={(e: any) => {
                            form?.months.set(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.noOfEmployees)}
                        thousandSeparator={true}
                        value={formatCurrency(form?.noOfEmployees?.get() || "")}
                        decimalScale={2}
                        onValueChange={(e: any) => {
                            form?.noOfEmployees.set(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.agricultureSection.maleEmployees)}
                        thousandSeparator={true}
                        value={formatCurrency(form?.maleEmployees?.get() || "")}
                        decimalScale={2}
                        onValueChange={(e: any) => {
                            form?.maleEmployees.set(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                    <NumericFormat
                        customInput={TextBoxComponent}
                        label={translate(langDictionary.agricultureSection.femaleEmployees)}
                        thousandSeparator={true}
                        value={formatCurrency(form?.femaleEmployees?.get() || "")}
                        decimalScale={2}
                        onValueChange={(e: any) => {
                            form?.femaleEmployees.set(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                        container
                        alignItems={"center"}
                        rowSpacing={2}
                        columnSpacing={{ xs: 3, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={6} md={4} lg={3} gap={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                            <Box>
                                <Typography>
                                    {translate(
                                        langDictionary.agricultureSection
                                            .hasTheAgriculturingObtainedAnyTraining
                                    )}
                                </Typography>
                            </Box>
                            <Box display={"flex"} gap={2}>
                                <Button
                                    variant={`${form?.hasTheAgriculturingObtainedAnyTraining?.get()
                                        ? "contained"
                                        : "outlined"
                                        }`}
                                    onClick={() => {
                                        form?.hasTheAgriculturingObtainedAnyTraining?.set(true);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={`${form?.hasTheAgriculturingObtainedAnyTraining?.get() === false
                                        ? "contained"
                                        : "outlined"
                                        }`}
                                    onClick={() => {
                                        form.set((items) => {
                                            delete items["nameOfTheInstitute"];
                                            return {
                                                ...items,
                                                hasTheAgriculturingObtainedAnyTraining: false
                                            }
                                        })
                                    }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Grid>

                        {form?.hasTheAgriculturingObtainedAnyTraining?.get() && (
                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                <TextBoxComponent
                                    label={translate(
                                        langDictionary.agricultureSection.nameOfTheInstitute
                                    )}
                                    value={form?.nameOfTheInstitute?.get() ?? ""}
                                    onInput={OnDemandValueFormatter.alphabeticalSpaces}
                                    fullWidth={true}
                                    onChange={(e) => {
                                        form?.nameOfTheInstitute?.set(e.target.value);
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={3} gap={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                            <Box>
                                <Typography>
                                    {translate(
                                        langDictionary.agricultureSection
                                            .memberInTheFarmersSociety
                                    )}
                                </Typography>
                            </Box>
                            <Box display={"flex"} gap={2}>
                                <Button
                                    variant={`${form?.memberInTheFarmersSociety?.get()
                                        ? "contained"
                                        : "outlined"
                                        }`}
                                    onClick={() => {
                                        form?.memberInTheFarmersSociety?.set(true);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={`${form?.memberInTheFarmersSociety?.get() === false
                                        ? "contained"
                                        : "outlined"
                                        }`}
                                    onClick={() => {
                                        form.set((items) => {
                                            delete items["nameOfTheMemberOfFarmersSociety"];
                                            delete items["registrationNumberOfTheFarmersSociety"];
                                            return {
                                                ...items,
                                                memberInTheFarmersSociety: false
                                            }
                                        })
                                    }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Grid>
                        {form?.memberInTheFarmersSociety?.get() && (
                            <>
                                <Grid item xs={12} sm={6} md={4} lg={3} >
                                    <TextBoxComponent
                                        label={translate(langDictionary.agricultureSection.nameOfTheMemberOfFarmersSociety)}
                                        value={form?.nameOfTheMemberOfFarmersSociety?.get()}
                                        onInput={OnDemandValueFormatter.alphabeticalSpaces}
                                        fullWidth={true}
                                        onChange={(e) => {
                                            form?.nameOfTheMemberOfFarmersSociety.set(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} >
                                    <TextBoxComponent
                                        label={translate(langDictionary.agricultureSection.registrationNumberOfTheFarmersSociety)}
                                        value={form?.registrationNumberOfTheFarmersSociety?.get()}
                                        onInput={OnDemandValueFormatter.alphabeticalSpaces}
                                        fullWidth={true}
                                        onChange={(e) => {
                                            form?.registrationNumberOfTheFarmersSociety.set(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </>

                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container alignItems={"center"} rowSpacing={1} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={4} lg={3} >
                            <Typography>
                                {translate(
                                    langDictionary.agricultureSection
                                        .agricultureLandIsA
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} gap={2} display={'flex'}>
                            <Button
                                variant={`${form?.agricultureLandIsA?.get() === 1
                                    ? "contained"
                                    : "outlined"
                                    }`}
                                onClick={() => {
                                    form?.agricultureLandIsA?.set(1);
                                }}
                            >
                                {translate(langDictionary.loanAgreement)}
                            </Button>
                            <Button
                                variant={`${form?.agricultureLandIsA?.get() === 2
                                    ? "contained"
                                    : "outlined"
                                    }`}
                                onClick={() => {
                                    form?.agricultureLandIsA?.set(2);
                                }}
                            >
                                {translate(langDictionary.inheritedProperty)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    form?.agricultureLandIsA?.get() &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AgricultureLandIsA form={form} translate={translate} />
                    </Grid>
                }

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AgricultureAssetValue form={form} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container alignItems={"center"} rowSpacing={2.5} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={4} lg={3} gap={2} sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Typography>{translate(langDictionary.taxPayer)}</Typography>
                            <Button
                                variant={`${form?.taxPayer?.get() ? "contained" : "outlined"}`}
                                onClick={() => {
                                    form?.set((items) => {
                                        return {
                                            ...items,
                                            taxPayer: true,
                                            taxDetails: {}
                                        }
                                    });
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                variant={`${form?.taxPayer?.get() === false ? "contained" : "outlined"
                                    }`}
                                onClick={() => {
                                    form.set((items) => {
                                        delete items["taxDetails"]
                                        return {
                                            ...items,
                                            taxPayer: false
                                        }
                                    });
                                }}
                            >
                                No
                            </Button>
                        </Grid>
                        {form?.taxPayer?.get() && <AgricultureTaxDetails form={form} translate={translate} />}
                    </Grid>

                </Grid>
            </Grid>

            <Grid container alignItems={"center"} display={'flex'}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', whiteSpace: 'nowrap' }} gap={5} paddingTop={2} paddingRight={2}>
                    <ButtonComponent startIcon={<CloseIcon />} title="Close" variant="contained" onClick={handleClose} />
                    <ButtonComponent startIcon={<SaveIcon />} title="Save" variant="contained" onClick={handleSubmit} />
                </Grid>
            </Grid>
        </React.Suspense>
    );
};

export default AgricultureForm;
