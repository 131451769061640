import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

function TableHeaderCell(props) {
  return (
    <TableCell align={props.align} width={props.width}>
      <Typography style={{ color: "#747a88", fontSize: 12, opacity: 0.7 }}>{props.value}</Typography>
    </TableCell>
  );
}

function TableDataCell(props) {
  return (
    <TableCell align={props.align} width={props.width}>
      <Typography style={{ color: "#747a88", fontSize: 12 }}>{props.value}</Typography>
    </TableCell>
  );
}

const LoanDetails = ({ details }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableHeaderCell value="Account Number" width="10%" />

            <TableHeaderCell value="Product" width="10%" />

            <TableHeaderCell value="Open Date" width="10%" />

            <TableHeaderCell value="Settled Date" align="right" width="10%" />

            <TableHeaderCell value="Balance" align="right" width="10%" />

            <TableHeaderCell value="Principal Due" align="right" width="10%" />

            <TableHeaderCell value="Principal Due" align="right" width="10%" />

            <TableHeaderCell value="Interest Due" align="right" width="10%" />

            <TableHeaderCell value="Current Rate" align="right" width="10%" />
          </TableRow>
        </TableHead>

        <TableBody>
          {details.map((item: any, i) => (
            <TableRow key={item?.accountNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableDataCell value={item?.accountNumber} width="10%" />

              <TableDataCell value={item?.productName} width="10%" />

              <TableDataCell value={item?.openDate ?? item?.grantedDate} width="10%" />

              <TableDataCell value={item?.settledDate} align="right" width="10%" />

              <TableDataCell value={item?.outstandingBalanace} align="right" width="10%" />

              <TableDataCell value={item?.totalPrincipalDue} align="right" width="10%" />

              <TableDataCell value={item?.interestDue} align="right" width="10%" />

              <TableDataCell value={item?.chargeDue} align="right" width="10%" />

              <TableDataCell value={item?.interestRate} align="right" width="10%" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FDDetails = ({ details }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableHeaderCell value="Account Number" />

            <TableHeaderCell value="Product Name" />

            <TableHeaderCell value="Open Date" align="right" />

            <TableHeaderCell value="Balance" align="right" />

            <TableHeaderCell value="Current Rate FD" align="right" />

            <TableHeaderCell value="Last Renewal Date" align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {details.map((item: any, i) => (
            <TableRow key={item?.accountNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableDataCell value={item?.accountNumber} />

              <TableDataCell value={item?.productName} />

              <TableDataCell value={item?.openDate ?? item?.grantedDate} align="right" />

              <TableDataCell value={item?.balance ?? item?.balanace} align="right" />

              <TableDataCell value={item?.currentRateFd} align="right" />

              <TableDataCell value={item?.lastRenewDate} align="right" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SavingDetails = ({ details }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableHeaderCell value="Account Number" />

            <TableHeaderCell value="Product Name" />

            <TableHeaderCell value="Open Date" align="right" />

            <TableHeaderCell value="Balance" align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {details.map((item: any, i) => (
            <TableRow key={item?.accountNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableDataCell value={item?.accountNumber} />

              <TableDataCell value={item?.productName} />

              <TableDataCell value={item?.openDate ?? item?.grantedDate} align="right" />

              <TableDataCell value={item?.balance} align="right" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function ScvDetailTable(props) {
  const object: any = props.scvData;
  const node: any = props.node;
  let details = [];
  let renderComponent = <></>;
  if (node.toString() === "loans") {
    details = object.loans;
    renderComponent = <LoanDetails details={details} />;
  } else if (node.toString() === "FDs") {
    details = object.FDs;
    renderComponent = <FDDetails details={details} />;
  } else if (node.toString() === "savings") {
    details = object.savings;
    renderComponent = <SavingDetails details={details} />;
  }

  if (!details?.length) {
    renderComponent = <></>;
  }

  return renderComponent;
}
