import { Divider, Stack } from "@mui/material";
import { useRef } from "react";
import { ButtonComponent } from "../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../components/OtherComponents/GlobalModal/GlobalModal";
import { Toast } from "../components/OtherComponents/GlobalToast";

const SuperPrivilegeTrackingModal = ({ }) => {

    const updateStorage = useRef({
        key: "",
        value: ""
    });

    const storeStorage = () => {
        const { key, value } = updateStorage.current;
        if (!key || !value) {
            Toast.error("Key and Value required to proceed.");
            return;
        }
        sessionStorage[key] = value;
        closeGlobalModal();

        if (key === "control") {
            window.location.reload();
        }
    }

    const clearStorage = () => {
        const { key, value } = updateStorage.current;
        if (!key) {
            Toast.error("Key required to proceed.");
            return;
        }
        delete sessionStorage[key];
        closeGlobalModal();

        if (key === "control") {
            window.location.reload();
        }
    }

    return <Stack spacing={1}>
        <Stack direction={"row"}></Stack>
        <Divider />
        <Stack spacing={2}>
            <TextBoxComponent label={"Key"} onChange={(e) => updateStorage.current.key = e.target.value} onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                    clearStorage();
                }
            }}></TextBoxComponent>
            <TextBoxComponent label={"Value"} onChange={(e) => updateStorage.current.value = e.target.value} onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                    storeStorage();
                }
            }}></TextBoxComponent>
        </Stack>
        <Divider />
        <Stack direction={"row-reverse"} spacing={2}>
            <ButtonComponent title={"Update"} onClick={storeStorage} />
            <ButtonComponent title={"Close"} onClick={closeGlobalModal} />
        </Stack>
    </Stack>;
}

export default SuperPrivilegeTrackingModal;