import { Stack, Divider, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ handleClose, open, setOpen, children, title }) {
  return (
    <React.Fragment>
      {/* <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description"  fullWidth >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
      </Dialog> */}


      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} aria-describedby="alert-dialog-slide-description" fullWidth={true} maxWidth={"lg"} style={{ zIndex: 1 }}>
        <Stack direction={"row-reverse"} pt={2} pr={2}>
          <Tooltip title="Close" style={{ cursor: "pointer" }} onClick={handleClose}>
            <CloseIcon color="error" fontSize="medium" />
          </Tooltip>
        </Stack>
        {title && <DialogTitle id="form-dialog-title" color={"GrayText"}>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        {/* <DialogActions>
                    <ButtonComponent title="CLOSE" variant="outlined" onClick={handleClose} loadingbtn={true} color="primary" />
                    <ButtonComponent title="DONE" variant="outlined" onClick={() => {
                        handleSubmit()
                        handleClose()
                    }} loadingbtn={true} color="primary" />
                </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
