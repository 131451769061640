import { Downgraded, useHookstate } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCopy } from "../../../../../../../../../../../utility/util";
import { CircularProgressComponent } from "../../../../../../../../../ProgressComponent/ProgressComponent";
import SubTitleTypeTwo from "../../../../../Helpers/SubTitleTypeTwo/SubTitleTypeTwo";
import { comprehensiveDefaultDataObject, defaultComprehensiveProfitAndLoanAccounts } from "../../../../../Stores/MicroAppraisalDefaultData";
import { comprehensiveProfitAndLoanAccountsData } from "../../../../../Stores/MicroAppraisalStore";
import { decsOfTaxes } from "../../../../../Utilities/Util";
import Comprehensive from "../Comprehensive";

const Depreciation = () => {
    const { t: translate } = useTranslation();
    const comprehesiveData: any = useHookstate(comprehensiveProfitAndLoanAccountsData["data"]);
    const formData = useHookstate<any>(comprehesiveData["depreciation"]);
    const selectedYears = useHookstate<any>(comprehensiveProfitAndLoanAccountsData["selectedYears"]);
    const isLoading = useHookstate(true);

    useEffect(() => {
        if (!comprehesiveData?.depreciation.get()) {
            comprehesiveData?.depreciation.set(getCopy(defaultComprehensiveProfitAndLoanAccounts.data.depreciation))
        }

        isLoading.set(false)
    }, [])

    if (isLoading.get()) return <CircularProgressComponent size={30} />

    return (
        <React.Fragment>
            <SubTitleTypeTwo title={translate("depreciation")} />
            <Comprehensive isNotVisibleAddIcon={true} mainTitle={translate("depreciation")} titleYearArray={selectedYears.attach(Downgraded).get()} formData={formData} defaultDataObject={comprehensiveDefaultDataObject} options={decsOfTaxes} />
        </React.Fragment>
    )
}

export default Depreciation
