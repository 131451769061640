import Api from "./apiCallsHandler";
import { ERROR_CODES } from "../configs/constants/errorCode";
import URL from "../configs/connection";
import { addToaster } from "../components/OtherComponents/GlobalToast";
const api = Api.getApi();

const apiCAUUsers = `${URL.REACT_APP_BACKEND_HOST}/los/cau/users?groupIds=40,1`;
const apiCAUOfficers = `${URL.REACT_APP_BACKEND_HOST}/los/cau/officers`;
const apiCAUUsersByManager = `${URL.REACT_APP_BACKEND_HOST}/los/workflows/next/assignees`;
const caAdminsList = `${URL.REACT_APP_BACKEND_HOST}/los/ca/update/admins`;
const caUpdateInfo = `${URL.REACT_APP_BACKEND_HOST}/los/ca/update/info`;

export const fetchCreditAdminUnit = async () => {
  try {
    const data = await api.get(`${apiCAUUsers}`, {});

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const fetchCreditAdminUnitUsers = async (params) => {
  try {
    // const data = await api.get(`${apiCAUUsersByManager}?applicationId=${params.applicationId}&parentGroup=${params.parentGroup}&childGroup=${params.childGroup}`);
    const data = await api.get(`${apiCAUUsersByManager}?applicationId=${params.applicationId}`,{
      nextWorkflowStepId : params?.nextWorkflowStepId
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const fetchCreditAdminUniOfficersByManagers = async (params) => {
  const obj = {
    userId: params.userId,
    groups: params.groups,
  };
  try {
    const data = await api.get(`${apiCAUOfficers}?managerIds=${obj}`);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const fetchCAAdminList = async () => {
  try {
    const data = await api.get(`${caAdminsList}`);

    if (data?.status === ERROR_CODES.success) {
      return data?.data;
    }
  } catch (error) {
    return null;
  }
};

export const fetchCAUpdateInfo = async (adminItem) => {
  try {
    const data = await api.get(`${caUpdateInfo}`, { adminId: adminItem });

    if (data?.status === ERROR_CODES.success) {
      return data?.data;
    } else {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Error loading admins list!",
      });
    }
  } catch (error) {
    return null;
  }
};
