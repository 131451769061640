import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { formatCurrency } from '../../../../../../../../services/currencyFormater';

const ChangeDetector = ({ value }) => {
    value = parseFloat(value ?? 0.00);
    let changeDetectorClassName: string = 'normal';
    let isPositive = 0;

    if (value > 0) {
        isPositive = 1;
        changeDetectorClassName = 'plus';
        value = "+" + formatCurrency(value.toFixed(2));
    }
    else if (value < 0) {
        isPositive = -1;
        changeDetectorClassName = 'minus';
        value = formatCurrency(value.toFixed(2));
    } else {
        value = "N/A";
    }

    return (
        <div className='chanage-detector'>
            <Stack direction={"row"} alignItems={"center"} className={changeDetectorClassName}>
                <Stack flex={1} className='chanage-detector-icon'>
                    <Box p={0} sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        {isPositive > 0 ? <KeyboardDoubleArrowUpIcon /> : isPositive < 0 ? <KeyboardDoubleArrowDownIcon /> : <DragHandleIcon />}
                    </Box>
                </Stack>
                <Stack alignItems={"center"} flex={2} className='text'>
                    <Typography variant='subtitle1'>{value}</Typography>
                </Stack>
            </Stack>
        </div>
    )
}

export default ChangeDetector
