import React, { useEffect } from "react";

import { Box, Stack } from "@mui/material";
import { getCribRecordList } from "../../../../../services/creditFileApiCall";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

import { Downgraded } from "@hookstate/core";
import { userDetails } from "../../../../../configs/mainStore";

import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

import { useTranslation } from "react-i18next";
import { applicantTypes } from "../../../../../configs/constants/creditFileConfigs";
import { CribRecordRightComponentRevamp } from "./CribRecordingsRightComponentRevamp";

const CribRecords = ({ data, applicationId, innerComponentData, tabsToEdit, currentApplicationWfData }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { t: translate } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isChanged, setIsChanged] = React.useState(false);
  const [tabOptions, setTabOptions] = React.useState<any>();
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    const sideTabList: Array<any> = [];
    (async function () {
      setIsLoading(true);
      // const data = currentApplicationWfData?.cribList || (await getCribRecordList(applicationId));
      const data = await getCribRecordList(applicationId);
      if (data) {
        data?.participantTypeList.map((participantTypeId: any, index) => {
          if (
            data["applicantCribData"] &&
            data["applicantCribData"][
            `${applicantTypes[participantTypeId]}Data`
            ]
          ) {
            sideTabList.push({
              id: participantTypeId,
              title: translate(applicantTypes[participantTypeId]),
              content: renderSideComponent(
                data["applicantCribData"][
                `${applicantTypes[participantTypeId]}Data`
                ],
                participantTypeId,
                data["participantEntryMap"],
                data["resultCribList"],
                reloadPage,
                index
              ),
            });
          }
        });

        setTabOptions(sideTabList);
      } else {
        setErrorMessage("Something went Wrong!");
      }

      setIsLoading(false);
    })();
  }, [isChanged]);

  const reloadPage = () => {
    setIsChanged(!isChanged);
  };

  const renderSideComponent = (
    data,
    participantTypeId,
    participantEntryMap,
    cribList,
    reloadPage,
    index
  ) => {
    return (
      <CribRecordRightComponentRevamp
        key={index}
        data={data}
        participantTypeId={participantTypeId}
        participantEntryMap={participantEntryMap}
        cribList={cribList}
        reloadPage={reloadPage}
        applicationId={applicationId}
        tabsToEdit={tabsToEdit}
        innerComponentData={innerComponentData}
      />
    );
  };

  const ContentContainer = ({ children }) => {
    return <Stack className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        disabled={isLoggedInUserNotTheAssignee}
      />
      {children}
    </Stack>;
  }

  if (isLoading) {
    return <ContentContainer>
      <Stack height={"300px"} justifyContent={"center"} alignContent={"center"}>
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      </Stack>
    </ContentContainer >;
  }

  if (errorMessage) {
    return <ContentContainer>
      <ErrorMessageComponent
        headMessage={"Error!"}
        errorMessage={errorMessage}
      />
    </ContentContainer >;
  }

  return <ContentContainer>
    <Stack spacing={1} m={1} className="full-width">
      <Box sx={{ border: "1px black", m: 1 }}>
        {tabOptions?.map((item) => {
          return item?.content;
        })}
      </Box>
    </Stack>
  </ContentContainer >;
};

export default CribRecords;
