import { ERROR_CODES } from '../configs/constants/errorCode';
import Api from './apiCallsHandler';
import URL from "../configs/connection";
const api = Api.getApi();

const getLanguageDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/language-list`;
const getLoginLanguageDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/login-language-list`;

export const getLanguageDataList = async () => {
  try {
    const data = await api.get(`${getLanguageDataUrl}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getLoginLanguageDataList = async () => {
  try {
    const data = await api.get(`${getLoginLanguageDataUrl}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
