import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../../configs/base64Conversion";
import { getReferSubFlowList, getRiskRecordDocFile } from "../../../../../../services/creditFileApiCall";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../../InnerSectionHeaderComponent";
import ApplicationInsurancePolicyItemRow from "./ApplicationInsurancePolicyItemRow";

import { creditData, userDetails } from "../../../../../../configs/mainStore";
import AppConfig from "../../../../../../utility/AppConfig";
import { getIsDisbursed } from "../../../../../../utility/helpers/getIsDisbursed";

export const previewBtnClicked = async (docId) => {
  const resultFile = await getRiskRecordDocFile(docId);

  if (resultFile?.fileData?.data) {
    const fileBuffer = resultFile?.fileData.data;
    const contentType = resultFile?.contentType;

    const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=500,
         height=500`
    );
  }
};

const ApplicationInsurancePolicy = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const insuranceDepartmentGroupId = AppConfig.config.insuranceDepartmentGroupId;

  const riskList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {

    (async function () {
      const response = await getReferSubFlowList(applicationId, insuranceDepartmentGroupId);

      if (response?.data && response?.data.length > 0) {
        riskList.set(response);
      }
      setIsLoading(false);
    })();
  }, []);

  const callBackOnAction = async () => {
    setIsLoading(true);

    const response = await getReferSubFlowList(applicationId, insuranceDepartmentGroupId);

    if (response?.data && response?.data.length > 0) {
      riskList.set(response);
    }

    setIsLoading(false);

  };

  const callBackOnReferAction = async (data) => {
    setIsLoading(true);

    const response = await getReferSubFlowList(applicationId, insuranceDepartmentGroupId);

    if (response?.data && response?.data.length > 0) {
      riskList.set(response);
    }

    setIsLoading(false);

  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  if (isLoading) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"}>
        <CircularProgressComponent
          size={30}
          sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", zIndex: 100 }}
        />
      </Stack>
    );
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={currentApplicationWfData}
        callBackOnAction={callBackOnAction}
        modalSize="lg"
        disabled={checkPrivilegeStatus()}
        catalogKey={data?.currentProductCatalogKey}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {riskList && riskList.length > 0 ? (
            riskList
              ?.get()
              ?.map((data, index) => (
                <ApplicationInsurancePolicyItemRow
                  data={data}
                  key={data?.id}
                  previewBtnClicked={previewBtnClicked}
                  handleCallBackOnAction={callBackOnReferAction}
                />
              ))
          ) : (
            <ErrorMessageComponent headMessage={"Data Not Available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ApplicationInsurancePolicy;
