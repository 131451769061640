import moment from "moment";
import { formattedDateForValidation } from "../../../utility/fomatters";
import { validateTypedDateWithFutureDateEnabled, validateTypedDateWithPastDateEnabled } from "../../../utility/util";

//filed cannot be empty or null. you must enter a value
export const validateMandatory = (value: any) => {
  if (value === "" || value === null || value === undefined) {
    return { status: -1, message: "Mandatory Fields cannot be empty." };
  } else {
    return null;
  }
};

export const validateTextWithCharactersAndSpaces = (field: string, value: string) => {
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9 ]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters and spaces.`,
      };
  } else {
    return null;
  }
};

export const validateTextWithCharactersSpacesAndDots = (field: string, value: string) => {
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9. ]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces and dots.`,
      };
  } else {
    return null;
  }
};

export const validateAphabeticalExtended = (field: string, value: string) => {
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z,. ']*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces, commas, full-stop, and apostrophes.`,
      };
  } else {
    return null;
  }
};

export const validateTextWithCharactersSpacesAndNumbers = (field: string, value: string) => {
  // value can have only characters, spaces , numbers, apostrophe and brackets
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9 '()]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces, numbers apostrophes, and brackets.`,
      };
  } else {
    return null;
  }
};

export const validateTextWithAlphaNumericField = (field: string, value: string) => {
  // value can have only characters, spaces , numbers, apostrophe and brackets
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces, and numbers.`,
      };
  } else {
    return null;
  }
};

export const validateTextWithAlphaNumericFieldAndDecmal = (field: string, value: string) => {
  // value can have only characters, spaces , numbers, apostrophe and brackets
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9\.]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, numbers.`,
      };
  } else {
    return null;
  }
};

export const validatelength = (field: string, value: string, exactLength: any = null, minLength: any = null, maxLength: any = null) => {
  if (isValueAvailable(value)) {
    if (exactLength) {
      if (value?.length === exactLength) {
        return null;
      } else {
        return {
          status: -1,
          message: `${field} should be ${exactLength} characters long.`,
        };
      }
    } else if (minLength || maxLength) {
      if (minLength && maxLength) {
        return value?.length >= minLength && value?.length <= maxLength
          ? null
          : {
            status: -1,
            message: `${field} should be between ${minLength} and ${maxLength} characters long.`,
          };
      } else if (minLength) {
        return value?.length >= minLength
          ? null
          : {
            status: -1,
            message: `${field} should be at least ${minLength} characters long.`,
          };
      } else if (maxLength) {
        return value?.length <= maxLength
          ? null
          : {
            status: -1,
            message: `${field} should be at most ${maxLength} characters long.`,
          };
      } else return null;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const validateName = (field: string, name: string) => {
  if (isValueAvailable(name)) {
    return name?.match(/^[A-Za-z\s]*$/) ? null : { status: -2, message: field + " must contain only letters and spaces." };
  } else {
    return null;
  }
};

export const validateNic = (nic: string) => {
  if (isValueAvailable(nic)) {
    return String(nic)?.match(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/)
      ? null
      : {
        status: -2,
        message: "NIC must contain 9 digits and a letter or 12 digits.",
      };
  } else {
    return null;
  }
};

export const validateOldNic = (nic: string) => {
  if (isValueAvailable(nic)) {
    return String(nic)?.match(/^([0-9]{9}[x|X|v|V])$/) ? null : { status: -2, message: "Old NIC must contain 9 digits and a letter." };
  } else {
    return null;
  }
};

export const validateNewNic = (nic: string) => {
  if (isValueAvailable(nic)) {
    return String(nic)?.match(/^[0-9]{12}$/) ? null : { status: -2, message: "New NIC must contain 12 numbers." };
  } else {
    return null;
  }
};

export const validateOldNicNumbering = (nic: string) => {
  if (isValueAvailable(nic)) {
    let first2 = String(nic)?.slice(0, 2);
    let next3 = String(nic)?.slice(2, 5);

    let first2Numbers = first2 ? Number(first2) : null;
    let next3Numbers = next3 ? Number(next3) : null;

    if (first2Numbers && first2Numbers >= 0 && first2Numbers <= 99) {
      if (next3Numbers && ((next3Numbers >= 0 && next3Numbers <= 366) || (next3Numbers >= 501 && next3Numbers <= 866))) {
      } else {
        return { status: -2, message: "Please add a valid old NIC number." };
      }
    } else {
      return { status: -2, message: "Please add a valid old NIC number." };
    }
  } else {
    return null;
  }
};

export const validateNewNicNumbering = (nic: string) => {
  if (isValueAvailable(nic)) {
    let first4 = String(nic)?.slice(0, 4);
    let next3 = String(nic)?.slice(4, 7);

    let first4Numbers = first4 ? Number(first4) : null;
    let next3Numbers = next3 ? Number(next3) : null;

    if (first4Numbers && first4Numbers >= 1900 && first4Numbers <= 2010) {
      if (next3Numbers && ((next3Numbers >= 0 && next3Numbers <= 366) || (next3Numbers >= 501 && next3Numbers <= 866))) {
        // return { status: 1, message: 'Success' };
      } else {
        return { status: -2, message: "Please add a valid new NIC number" };
      }
    } else {
      return { status: -2, message: "Please add a valid new NIC number" };
    }
  } else {
    return null;
  }
};

export const validateGenderWithOldNic = (gender: number, nic: string) => {
  if (isValueAvailable(gender) && isValueAvailable(nic)) {
    if (validateOldNic(nic)) {
      return { status: -2, message: "Gender is mismatched with Old NIC." };
    }
    const days = parseInt(nic.substring(2, 3));
    if (days > 500)
      if (gender === 2) {
        return null;
      } else {
        return { status: -2, message: "Gender is mismatched with Old NIC." };
      }
    else {
      if (gender === 1) {
        return null;
      } else {
        return { status: -2, message: "Gender is mismatched with Old NIC." };
      }
    }
  } else {
    return null;
  }
};

export const validateGenderWithNewNic = (gender: number, nic: string) => {
  if (isValueAvailable(gender) && isValueAvailable(nic)) {
    if (validateNewNic(nic)) {
      return { status: -2, message: "You must add a valid new NIC to proceed." };
    }
    const days = parseInt(nic.substring(4, 3));
    if (days > 500)
      if (gender === 2) {
        return null;
      } else {
        return { status: -2, message: "Gender is mismatched with New NIC." };
      }
    else {
      if (gender === 1) {
        return null;
      } else {
        return { status: -2, message: "Gender is mismatched with New NIC." };
      }
    }
  } else {
    return null;
  }
};

const nicDobValidator = (dob: string, nicYear: number, nicDays: number) => {
  if (isValueAvailable(dob) && isValueAvailable(nicYear) && isValueAvailable(nicDays)) {
    const dobYear = new Date(dob).getFullYear();
    const dobMonth = new Date(dob).getMonth() + 1;
    const dobDay = new Date(dob).getDate();

    let nicMonth = 0;
    let nicDay = 0;

    if (nicDays > 335) {
      nicMonth = 12;
      nicDay = nicDays - 335;
    } else if (nicDays > 305) {
      nicMonth = 11;
      nicDay = nicDays - 305;
    } else if (nicDays > 274) {
      nicMonth = 10;
      nicDay = nicDays - 274;
    } else if (nicDays > 244) {
      nicMonth = 9;
      nicDay = nicDays - 244;
    } else if (nicDays > 213) {
      nicMonth = 8;
      nicDay = nicDays - 213;
    } else if (nicDays > 182) {
      nicMonth = 7;
      nicDay = nicDays - 182;
    } else if (nicDays > 152) {
      nicMonth = 6;
      nicDay = nicDays - 152;
    } else if (nicDays > 121) {
      nicMonth = 5;
      nicDay = nicDays - 121;
    } else if (nicDays > 91) {
      nicMonth = 4;
      nicDay = nicDays - 91;
    } else if (nicDays > 60) {
      nicMonth = 3;
      nicDay = nicDays - 60;
    } else if (nicDays < 32) {
      nicMonth = 1;
      nicDay = nicDays;
    } else if (nicDays > 31) {
      nicMonth = 2;
      nicDay = nicDays - 31;
    }

    if (dobYear === nicYear && dobMonth === nicMonth && dobDay === nicDay) {
      return null;
    } else {
      return { status: -2, message: "The date of Birth is mismatched with NIC." };
    }
  } else {
    return null;
  }
};

export const validateDOBWithOldNic = (dob: string, nic: string) => {
  if (isValueAvailable(dob) && isValueAvailable(nic)) {
    if (validateOldNic(nic)) {
      return { status: -2, message: "You must add a Valid Old NIC to proceed." };
    }
    if (validateDate("", dob)) {
      return null;
    }
    const nicYear = parseInt("19" + parseInt(nic.substring(0, 2)));
    const nicDays = parseInt(nic.substring(2, 5));
    if (nicDays < 1 && nicDays > 366) {
      return { status: -2, message: "You must add a Valid Old NIC to proceed." };
    } else {
      return nicDobValidator(dob, nicYear, nicDays);
    }
  } else {
    return null;
  }
};

export const validateDOBWithNewNic = (dob: string, nic: string) => {
  if (isValueAvailable(dob) && isValueAvailable(nic)) {
    if (validateNewNic(nic)) {
      return { status: -2, message: "You must add a Valid New NIC." };
    }
    if (validateDate("", dob)) {
      // return { status: -2, message: 'The valid Date of Birth format is mm/dd/yyyy.' };
      return null;
    }
    const nicYear = parseInt(nic.substring(0, 4));
    const nicDays = parseInt(nic.substring(4, 7));
    if (nicDays < 1 && nicDays > 366) {
      return { status: -2, message: "You must add a Valid New NIC." };
    } else {
      return nicDobValidator(dob, nicYear, nicDays);
    }
  } else {
    return null;
  }
};

//use to check whether the date is in correct format.  in mm/dd/yyyy format
export const validateDate = (fieldName: string, date: string) => {
  if (isValueAvailable(date)) {
    // return date?.match(/^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)
    return date?.match(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/)
      ? null
      : // : { status: -2, message: fieldName + ' must be in mm/dd/yyyy format.' };
      null;
  } else {
    return null;
  }
};

export const nicvalidateDate = (fieldName: string, date: string) => {
  if (isValueAvailable(date)) {
    // return date?.match(/^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)
    return date?.match(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/)
      ? null
      : // : { status: -2, message: fieldName + ' must be in mm/dd/yyyy format.' };
      { status: -2, message: fieldName + " must be in dd/mm/yyyy format." };
  } else {
    return { status: -2, message: fieldName + " must not be Empty." };
  }
};

// use to check whether the user has entered future date
export const validateFutureDate = (fieldName: string, givenDate: string) => {
  if (isValueAvailable(givenDate)) {
    if (validateDate(fieldName, givenDate)) {
      return null;
    }
    const date = new Date(givenDate);
    const today = new Date();
    if (date > today) return null;
    else return { status: -2, message: fieldName + " must be a future date." };
  } else {
    return null;
  }
};

// use to check whether the user has entered past date
export const validatePastDate = (fieldName: string, givenDate: string) => {
  if (isValueAvailable(givenDate)) {
    if (validateDate(fieldName, givenDate)) {
      // return { status: -2, message: `The valid ${fieldName} format is mm/dd/yyyy.` };
      return null;
    }
    const date = formattedDateForValidation(givenDate);
    const today = new Date();
    if (date < today) {
      return null;
    } else return { status: -2, message: fieldName + " must be a past date." };
  } else {
    return null;
  }
};

export const validatePastDateWithToday = (fieldName: string, givenDate: string) => {

  if (isValueAvailable(givenDate)) {
    if (validateDate(fieldName, givenDate)) return null;

    const date = moment(givenDate, "DD/MM/YYYY").toDate();
    const today = new Date();

    return date <= today ? null : { status: -2, message: fieldName + " must be a Past Date." }

  } else return null
}

export const validateFutureDateWithToday = (fieldName: string, givenDate: string) => {
  if (isValueAvailable(givenDate)) {
    if (validateDate(fieldName, givenDate)) return null;

    const date = moment(givenDate, "DD/MM/YYYY").toDate();
    const today = new Date();

    return date > today ? null : { status: -2, message: fieldName + " must be a future Date." }

  } else return null
}


export const validateMinimumDate = (fieldName: string, givenDate: string, minimumDate: string) => {
  if (isValueAvailable(givenDate) && isValueAvailable(minimumDate)) {
    const date = formattedDateForValidation(givenDate);
    const minDate = formattedDateForValidation(minimumDate);
    if (date > minDate) return null;
    else
      return {
        status: -2,
        message: fieldName + " must be a date after the start date.",
      };
  } else {
    return null;
  }
};

// use to check whether input is a number and it equal or greater than 0
export const validatePositiveNumber = (fieldName: string, number: number) => {
  if (isValueAvailable(number)) {
    return number?.toString().match(/^[0-9]*$/) && number >= 0 ? null : { status: -2, message: fieldName + " must be a positive number." };
  } else {
    return null;
  }
};

export const validatePositiveNumberWithDecimalPlaces = (fieldName: string, number: number, decimalPlaces: number) => {
  if (isValueAvailable(number) && isValueAvailable(decimalPlaces)) {
    return number >= 0 && number?.toString().match(`^[0-9]+(\\.[0-9]{1,${decimalPlaces}})?$`)
      ? null
      : {
        status: -2,
        message: fieldName + " must be a positive number with a maximum of " + decimalPlaces + " decimal places.",
      };
  } else {
    return null;
  }
};

export const validatemaximumAmount = (fieldName: string, number: number, maximumAmount: number) => {
  if (isValueAvailable(number) && isValueAvailable(maximumAmount)) {
    return Number(number) <= maximumAmount
      ? null
      : {
        status: -2,
        message: fieldName + " must be less than or equal to " + maximumAmount + ".",
      };
  } else {
    return null;
  }
};

export const validateLimit = (fieldName: string, value: number, limit: any) => {
  if (isValueAvailable(value) && isValueAvailable(limit)) {
    return Number(value) <= limit
      ? null
      : {
        status: -2,
        message: fieldName + " must be less than or equal to " + limit + ".",
      };
  } else {
    return null;
  }
};

export const validateMinimumAmount = (fieldName: string, number: number, minimumAmount: number) => {
  if (isValueAvailable(number) && isValueAvailable(minimumAmount)) {
    return Number(number) >= minimumAmount
      ? null
      : {
        status: -2,
        message: fieldName + " must be higher than or equal to " + minimumAmount + ".",
      };
  } else {
    return null;
  }
};

export const validateEmail = (fieldName: string, email: string) => {
  if (isValueAvailable(email)) {
    return email?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? null : { status: -2, message: fieldName + " is Invalid." };
  } else {
    return null;
  }
};

export const validatePhoneNumber = (fieldName: string, mobile: string) => {
  if (isValueAvailable(mobile)) {
    return mobile?.match(/^(0)[0-9]{9}$/) ? null : { status: -2, message: fieldName + " must contain 10 digits." };
  } else {
    return null;
  }
};

export const validateMobileNumber = (fieldName: string, mobile: string) => {
  if (isValueAvailable(mobile)) {
    return mobile?.match(/^(0)[0-9]*$/) ? null : { status: -2, message: fieldName + " must start with 0." };
  } else {
    return null;
  }
};

export const validateLandLineNumber = (fieldName: string, landLine: string) => {
  if (isValueAvailable(landLine)) {
    return landLine?.match(/^(0)[0-9]*$/) && landLine?.charAt(1) !== "7"
      ? null
      : {
        status: -2,
        message: fieldName + " must start with 0 and the 2nd number cannot be 7.",
      };
  } else {
    return null;
  }
};

// validatePastYear is used to check whether the user has entered a number and it should be a past year and only can have 4 digits
export const validatePastYear = (fieldName: string, year: number) => {
  if (isValueAvailable(year)) {
    const today = new Date();
    const currentYear = today.getFullYear();
    return year?.toString().match(/^[0-9]{4}$/) && year <= currentYear ? null : { status: -2, message: fieldName + " must be a past year." };
  } else {
    return null;
  }
};

export const validateInBetweenAmount = (fieldName: string, number: number, minimumAmount: number, maximumAmount: number) => {
  if (isValueAvailable(number) && isValueAvailable(minimumAmount) && isValueAvailable(maximumAmount)) {
    return Number(number) <= maximumAmount && Number(number) >= minimumAmount
      ? null
      : {
        status: -2,
        message: `${fieldName} should be between ${minimumAmount} and ${maximumAmount}.`,
      };
  } else {
    return null;
  }
};

export const validateAge = (fieldName: string, num: Number) => {
  if (isValueAvailable(num)) {
  }
};

export const validateCitizenship = (status) => {
  if (isValueAvailable(status)) {
    return status == 0 ? { status: -2, message: `Non-Sri Lankan citizens cannot apply for loans.` } : null;
  } else {
    return null;
  }
};

export const validateExtentOrRegNo = (field: string, value: string) => {
  // value can have only characters, spaces , numbers, apostrophe and brackets, backslash, dash
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9 '()/-]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces, numbers, backslashes, apostrophes, dash, and brackets.`,
      };
  } else {
    return null;
  }
};

export const validateNicSearch = (nic) => {
  if (isValueAvailable(nic)) {
    const errors: any = [];

    const isOldNic = /[a-z]/i.test(nic) ? true : false;

    if (isOldNic) {
      errors[errors.length] = validateOldNic(nic);
      errors[errors.length] = validateOldNicNumbering(nic);
    } else {
      errors[errors.length] = validateNewNic(nic);
      errors[errors.length] = validateNewNicNumbering(nic);
    }

    return errors?.filter((error) => error && error);
  } else {
    return null;
  }
};

export const isValueAvailable = (value: any) => {
  if (value === "" || value === null || value === undefined) {
    return false;
  } else {
    return true;
  }
};

export const validatePassword = (field: string, password: string) => {
  if (isValueAvailable(password)) {
    return password?.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/)
      ? null
      : {
        status: -2,
        message: field + " must contain at least one letter, one number, and be at least 8 characters long.",
      };
  } else {
    return null;
  }
};

export const validateTextWithCharactersSpacesAndNumbersCommasFullstop = (field: string, value: string) => {
  if (isValueAvailable(value)) {
    return value.match(/^[a-zA-Z0-9,. ]*$/)
      ? null
      : {
        status: -1,
        message: `${field} should contain only characters, spaces, commas & full-stop.`,
      };
  } else {
    return null;
  }
};

// Used to validate future & pase date in application sections
export const getDateValidationErrorKeys = (dataObj, field, existingErrorArr, prefix, type) => {
  let erros: any = [];

  if (type == "future") {
    let result: any = validateTypedDateWithFutureDateEnabled(dataObj[field], field, prefix);
    if (result) {
      let formattedField = `${prefix}_${field}`;
      if (!existingErrorArr.includes(formattedField)) {
        erros.push(`${prefix}_${field}`);
      }
    }
  } else if (type == "past") {
    let result: any = validateTypedDateWithPastDateEnabled(dataObj[field], field, prefix);
    if (result) {
      let formattedField = `${prefix}_${field}`;
      if (!existingErrorArr.includes(formattedField)) {
        erros.push(`${prefix}_${field}`);
      }
    }
  }

  return erros;
};
