import { Downgraded } from '@hookstate/core';
import { workflowMasterData } from '../configs/mainStore';

export const getWorkflowActionById = (actionId) => {
  const { wf_actions }: any = workflowMasterData.attach(Downgraded).get();

  const actionData = wf_actions.find((action) => action.id == actionId);

  if (actionData) {
    return actionData;
  } else {
    return [];
  }
};

export const getWorkflowStageById = (stageId) => {
  const { wf_stages }: any = workflowMasterData.attach(Downgraded).get();

  const stage = wf_stages.find((stage) => stage.id == stageId);

  if (stage) {
    return stage;
  } else {
    return [];
  }
};
