import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect } from "react";

import { appSetting } from "../../../../../configs/mainStore";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";

import { useState } from "@hookstate/core";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  getInsurancePolicyDocuments
} from "../../../../../services/creditFileApiCall";
import { getUserDetailsById } from "../../../../../services/userService";
import { readStaticDocumentPath } from "../../../../../utility/helpers/creditFileUtility";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const InsurancePolicyDocumentsList = ({ applicationId }) => {
  const appSettings: any = useState(appSetting);

  const [isLoading, setIsLoading] = React.useState(false);
  const [riskList, setRiskList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getInsurancePolicyDocuments(applicationId);

        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "referId"));
          setRiskList(sortedList);
        } else {
          setErrorMessage("No Insurance Policy Documents");
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const previewBtnClicked = async (path) => {
    window.open(
      `${readStaticDocumentPath(path)}`,
      "targetWindow",
      `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=500,
           height=500`
    );
  };

  return (
    <Grid container className="full-width" alignItems="center">
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : errorMessage ? (
        <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />
      ) : (
        <Grid container p={1} className="data-box" style={{ backgroundColor: "#e8ecf1" }} m={2}>
          <Grid
            container
            pt={1}
            pr={1}
            pl={1}
            pb={2}
            className="full-width"
            columns={24}
            alignItems="center">
            <Grid container item sm={24} style={{ textAlign: "left" }}>
              <Grid item sm={24} mb={2}>
                <div className="basic-font basic-font-color opacity-7 font-size-12 mb-2">
                  Insurance Policy Documents
                </div>
              </Grid>
              <Grid item sm={24}>
                <div className="basic-font basic-font-color-bold font-size-14">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className="basic-font font-size-12">
                          <TableCell align="left">
                            <b>#</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Title</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Created</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Created By</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {riskList?.map((col: any, k) => {
                          return (
                            <TableRow
                              key={k}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className="basic-font opacity-7 font-size-12">
                                {k + 1}
                              </TableCell>
                              <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                {col?.title ? col?.title : "-"}
                              </TableCell>
                              <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                {col?.createdDate
                                  ? dateFormatWithTimeChange(col?.createdDate)
                                  : "--"}
                              </TableCell>
                              <TableCell className="basic-font opacity-7 font-size-12">
                                {col?.createdBy
                                  ? getUserDetailsById(col?.createdBy)[appSettings.get().language]
                                    ?.name
                                  : "--"}
                              </TableCell>
                              <TableCell align="right">
                                <PreviewIcon
                                  onClick={() => {
                                    previewBtnClicked(col?.filePath);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#1976d2",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InsurancePolicyDocumentsList;
