import { Downgraded, useState } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import { branchDetails, creditData, letterDocumentStructure, userDetails } from "../../../../../../configs/mainStore";
import { getApplicationOfferLetterData, saveLetterDocumentsData } from "../../../../../../services/creditFileApiCall";
import { standardDateFormatter } from "../../../../../../utility/fomatters";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { DocumentFooter } from "../../../../../InputHelperComponents/DocumentFooterComponent/DocumentFooterComponent";
import { addToaster } from "../../../../GlobalToast";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonDocReference from "../CommonDocReference";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import { LoanAgreementConditions } from "./LoanAgreementComponents/LoanAgreementConditions";
import { LoanAgreementInfo } from "./LoanAgreementComponents/LoanAgreementInfo";
import { LoanAgreementSignatureWitness } from "./LoanAgreementComponents/LoanAgreementSignatureWitness";

interface LoanAgreementPropTypes {
  data?: any;
  applicationId?: any;
  letterDocumentData?: any;
  existingDataArr?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  setDocumentDataUpdated?: any;
  currentApplicationWfData: any;
}

const printValidator = {
  loanAgreementInfo: () => [],
};

export const LoanAgreement: FC<LoanAgreementPropTypes> = ({ data, applicationId, letterDocumentData, existingDataArr, disableAccordingToTab, isLoggedInUserNotTheAssignee = false, setDocumentDataUpdated, currentApplicationWfData }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const componentRef: any = useRef();
  const userData: any = userDetails.attach(Downgraded).get();
  const usedBranchId = userData["userDetails"]["branch"] || "";
  const nearestBranch = branchDetails.branchMap[usedBranchId].get();
  const [conditionList, setConditionsList] = React.useState<any>([]);
  const [legalList, setLegalList] = React.useState<any>([]);
  const [riskList, setRiskList] = React.useState<any>([]);
  const [loanAgreementDataArr, setLoanAgreementDataArr] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const existingLetterDocumentData = letterDocumentStructureData.attach(Downgraded).get();
  const [disburseLoanAmount, setDisburseLoanAmount] = React.useState<any>();
  const [installmentData, setInstallementData] = React.useState<any>();
  const [repaymentDate, setRepaymentDate] = React.useState<any>("");
  const [applicableRate, setApplicableRate] = React.useState<any>();
  const documentId = "loan_agreement";
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();
  const [isHavingTypeVariables, setisHavingTypeVariables]: any = React.useState(false);
  const [referenceType, setReferenceType]: any = React.useState(1);
  const [referenceStyles, setReferenceStyles]: any = React.useState({
    top: {
      display: "none",
    },
    bottom: {
      display: "none",
    },
  });
  const [positionStyleType, setPositionStyleType] = React.useState("page-footer-bottom");

  const permissionToEditSave = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_EDIT_SAVE_CHANGES",
  });

  const permissionToPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    (async function () {
      if (applicationId) {
        const offerLetterData = await getApplicationOfferLetterData(applicationId);

        if (offerLetterData != null) {
          if (offerLetterData.conditionList?.length > 0) {
            setConditionsList(offerLetterData.conditionList);
          }

          if (offerLetterData.legalList?.length > 0) {
            setLegalList(offerLetterData.legalList);
          }

          if (offerLetterData.riskList?.length > 0) {
            setRiskList(offerLetterData.riskList);
          }
        }

        const { trialData: trailObjectData, coreData } = data || {};

        const trailCalResult = trailObjectData?.trailCalResult;

        let firstTermObj = trailCalResult[0];
        let firstTermPayDate = firstTermObj?.paymentDate || "";

        if (firstTermPayDate) {
          if (firstTermPayDate.includes("/")) {
            firstTermPayDate = firstTermPayDate;
          } else {
            firstTermPayDate = standardDateFormatter(firstTermPayDate);
          }
        }

        setRepaymentDate(firstTermPayDate);
        setDisburseLoanAmount(trailObjectData?.trailCalParams?.LoanAmount ?? trailObjectData?.trailCalParams?.loanAmount);

        if (trailCalResult.length > 0) {
          setInstallementData(trailCalResult[1]?.termlyInstallment);
        }

        let trialInterestRate = trailObjectData?.trailCalParams?.LoanInterestRate ?? trailObjectData?.trailCalParams?.loanInterestRate;

        setApplicableRate(trialInterestRate || 0.0);

        let loanAgreementDataObj = existingDataArr.find((obj) => obj?.LETTER_TYPE === "loanAgreement");
        setLoanAgreementDataArr(loanAgreementDataObj);
      }
    })();
  }, []);

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  useEffect(() => {
    if (referenceType == 1) {
      setReferenceStyles((previousState) => {
        return { bottom: { display: "block" }, top: { display: "none" } };
      });
      setPositionStyleType("page-footer-bottom");
    } else if (referenceType == 2) {
      setReferenceStyles((previousState) => {
        return { top: { display: "block" }, bottom: { display: "none" } };
      });
      setPositionStyleType("page-footer-top");
    }
  }, [referenceType]);

  const saveDocumentData = async () => {
    let letterBodyObj = {};

    setIsLoading(true);

    const updatedData = existingLetterDocumentData?.loanAgreementObj;
    const letterBodyData = loanAgreementDataArr?.LETTER_BODY;
    if (letterBodyData) {
      const letterBodyDataObj = JSON.parse(letterBodyData);

      letterBodyObj = {
        ...letterBodyDataObj,
        ...updatedData,
      };
    } else {
      letterBodyObj = {
        ...updatedData,
      };
    }

    const data = {
      APPLICATION_ID: applicationId,
      WORKFLOW_ID: "",
      LETTER_BODY: JSON.stringify(letterBodyObj),
      STATUS: 1,
      CREATED_BY: userData?.userId,
      UPDATED_BY: userData?.userId,
      LETTER_TYPE: "loanAgreement",
    };

    const res = await saveLetterDocumentsData(data);

    if (res?.status === 1) {
      setDocumentDataUpdated((prev) => !prev);
    }

    setIsLoading(false);

    if (res?.status === 1) {
      addToaster({
        status: "success",
        title: "Success",
        message: "Letter Document Data Added",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Letter Document Data Adding Falied",
      });
    }
  };

  const handlePrint = () => {
    let documentErrors: any[] = [];
    let validateStatus = true;

    for (const key of Object.keys(printValidator)) {
      try {
        const errors = printValidator[key]();
        if (errors.length > 0) {
          errors.forEach((error) => {
            documentErrors.push(error.message);
          });
          validateStatus = false;
        }
      } catch (error) {
        continue;
      }
    }

    if (documentErrors.length > 0) {
      let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

      for (const item of documentErrors) {
        formattedMessage.push(`\n\n👉 ${item}`);
      }

      addToaster({
        status: "error",
        title: "Error",
        message: formattedMessage,
      });
      validateStatus = false;
    }

    return validateStatus;
  };

  const proceedPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      #Header, #Footer {
        display: none !important;
      }
    }
  `,
  });

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const referenceHandler = (value) => {
    setReferenceType(value);
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <Grid container className={`inner-component-full-height basic-font`}>
      <style>{getPageMargins()}</style>
      <div className="full-width full-height">
        <SecurityDoceumentsFileDocumentsViewSection data={data} applicationId={applicationId} isSecurityDocument={true} securityDocumentKey={"loan-agreement"} />
        <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef}>
          <div style={referenceStyles.top}>
            <DocumentFooter positionStyleType={positionStyleType} />
          </div>
          <CommonSecurityDocHeader documentName={"Loan Agreement"} textCase={"lower"} />
          <Grid container className="border-radius-5" style={{ paddingRight: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4} className="loan-agreement-date" style={{ paddingLeft: "15px" }}>
              Date :
            </Grid>
          </Grid>
          <span style={{ padding: "35px" }}>
            <LoanAgreementInfo data={creditFileData} installmentData={installmentData} disburseLoanAmount={disburseLoanAmount} letterDocumentData={letterDocumentData} typeCaseClass={typeCaseClass} />
            <LoanAgreementConditions installmentData={installmentData} applicableRate={applicableRate} data={creditFileData} branch={nearestBranch} applicationId={applicationId} letterDocumentData={letterDocumentData} loanAgreementDataArr={loanAgreementDataArr} disburseLoanAmount={disburseLoanAmount} repaymentDate={repaymentDate} typeCaseClass={typeCaseClass} />
            <LoanAgreementSignatureWitness data={creditFileData} nearestBranch={nearestBranch} typeCaseClass={typeCaseClass} />
            <div style={referenceStyles.bottom}>
              <DocumentFooter positionStyleType={positionStyleType} />
            </div>
          </span>
        </Grid>
        <Box m={1}>
          <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={4} pr={1}>
              <CommonDocReference referenceHandler={referenceHandler} referenceType={referenceType} />
            </Grid>
            <Grid item xs={4} pr={1}>
              <SelectComponent label={"Select Typecase"} value={typeCase} values={Object.values(securityDocumentTextCase)} onChange={(e) => setTypeCase(e.target.value)} required={false} defaultPlaceholder={true} />
            </Grid>
            <Grid item xs={4}>
              {isHavingTypeVariables ? (
                <ButtonComponent
                  title={"Save"}
                  variant="contained"
                  onClick={() => {
                    saveDocumentData();
                  }}
                  style={{
                    maxHeight: "40px",
                    marginTop: "auto",
                    marginRight: "10px",
                  }}
                  disabled={permissionToEditSave}
                  loadingbtn={true}
                  loading={isLoading}
                />
              ) : (
                ""
              )}

              <ButtonComponent
                startIcon={<LocalPrintshopIcon />}
                title={"Print PDF"}
                variant="contained"
                onClick={(event) => {
                  handlePrint() && proceedPrint();
                }}
                style={{ maxHeight: "40px", marginTop: "auto" }}
                disabled={permissionToPrint}
                loadingbtn={true}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};
