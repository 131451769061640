import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { formatCurrency } from '../../../../../../services/currencyFormater';
import { ErrorMessageComponent } from '../../../../ErrorMessageComponent';
import { CircularProgressComponent } from '../../../../ProgressComponent/ProgressComponent';
import ScvDetailTable from '../../../../ScvDetailTable';

const ScvDetails = ({ params }) => {
    const [scvData, setScvData] = React.useState<any>({});
    const [nicList, setNicList] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (params) {
            if (params?.scvData) {
                setScvData(params?.scvData);
                setNicList(Object.keys(params?.scvData));
            } else {
                setScvData({});
            }
        }
        setIsLoading(false);
    }, [params]);

    const calculateSCVTotal = (data: [], type = "") => {
        let total: any = 0;
        if (data.length > 0 && type != "") {
            if (type === "loans") {
                data.forEach((element: any) => {
                    total += parseFloat(element?.totalPrincipalDue);
                });
            } else if (type === "FDs") {
                data.forEach((element: any) => {
                    total += parseFloat(element?.balanace);
                });
            } else if (type === "savings") {
                data.forEach((element: any) => {
                    total += parseFloat(element?.balance);
                });
            }
        }

        return total;
    };

    const renderSCVTotalValue = (loanData: any = [], type = "") => {
        let totalAmount: any = 0;

        const object: any = loanData;
        let node: any = type;

        let details: any = [];
        // let renderComponent;
        if (node.toString() === "loans") {
            details = object.loans;
            totalAmount = calculateSCVTotal(details, "loans");
        } else if (node.toString() === "FDs") {
            details = object.FDs;
            totalAmount = calculateSCVTotal(details, "FDs");
        } else if (node.toString() === "savings") {
            details = object.savings;
            totalAmount = calculateSCVTotal(details, "savings");
        }

        return `Rs. ${formatCurrency(totalAmount)}`;
    };

    return (
        <>
            {isLoading ? (
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        position: "relative",
                    }}
                >
                    <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
                </Grid>
            ) : Object.keys(params.scvData).length > 0 ? (
                <Box pb={2}>
                    <Grid container className="full-width" columns={24} alignItems="center">
                        {nicList.map((nic) => (
                            <div className="full-width">
                                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                                    <Typography> {nic.toString().length === 10 ? `Old NIC: ${nic}` : `New NIC: ${nic}`}</Typography>
                                </Grid>
                                <Grid container className="data-box">
                                    <Grid item xs={12} p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                                        Savings Accounts
                                        <div style={{ float: "right" }}>{renderSCVTotalValue(scvData[nic], "savings")}</div>
                                    </Grid>
                                    {isLoading ? (
                                        <Grid
                                            container
                                            alignItems="center"
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px",
                                                position: "relative",
                                            }}
                                        >
                                            <CircularProgressComponent
                                                size={30}
                                                sx={{
                                                    left: "50%",
                                                    position: "absolute",
                                                    zIndex: 100,
                                                }}
                                            />
                                        </Grid>
                                    ) : scvData[nic]?.savings?.length > 0 ? (
                                        <Grid container>
                                            <ScvDetailTable scvData={scvData[nic]} node="savings" />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid container className="data-box">
                                    <Grid item xs={12} p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                                        Fixed Deposits
                                        <div style={{ float: "right" }}>{renderSCVTotalValue(scvData[nic], "FDs")}</div>
                                    </Grid>
                                    {isLoading ? (
                                        <Grid
                                            container
                                            alignItems="center"
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px",
                                                position: "relative",
                                            }}
                                        >
                                            <CircularProgressComponent
                                                size={30}
                                                sx={{
                                                    left: "50%",
                                                    position: "absolute",
                                                    zIndex: 100,
                                                }}
                                            />
                                        </Grid>
                                    ) : scvData[nic]?.FDs?.length > 0 ? (
                                        <Grid container>
                                            <ScvDetailTable scvData={scvData[nic]} node="FDs" />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid container className="data-box">
                                    <Grid item xs={12} p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                                        Loan List
                                        <div style={{ float: "right" }}>{renderSCVTotalValue(scvData[nic], "loans")}</div>
                                    </Grid>
                                    {isLoading ? (
                                        <Grid
                                            container
                                            alignItems="center"
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px",
                                                position: "relative",
                                            }}
                                        >
                                            <CircularProgressComponent
                                                size={30}
                                                sx={{
                                                    left: "50%",
                                                    position: "absolute",
                                                    zIndex: 100,
                                                }}
                                            />
                                        </Grid>
                                    ) : scvData[nic]?.loans?.length > 0 ? (
                                        <Grid container>
                                            <ScvDetailTable scvData={scvData[nic]} node="loans" />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Box>
            ) : (
                <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
            )}
        </>
    );
}

export default ScvDetails