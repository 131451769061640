import { FinancialPositionOfTheCompanyController } from "./CalcluationsInterface";

export class ComprehensiveController extends FinancialPositionOfTheCompanyController {

    private revenues = [];
    private reductions = [];
    private expenses = [];
    private taxes = [];
    finalRevenue = {};
    private finalRevenueAndReductions = {}
    private finalTax = {};
    private interestExpenses = {}
    private depreciation = {}


    constructor(formData, years) {
        super();
        this.revenues = formData.revenues;
        this.reductions = formData.reductions;
        this.years = years.filter((item) => item?.isRatioAnalysisCal);
        this.expenses = formData.expenses;
        this.depreciation = formData?.depreciation || {};
        this.interestExpenses = formData?.interestExpenses || {};
        this.taxes = formData.taxes;
    }

    getGrossProfitAganist() {
        let revenues = this.revenues;
        let reductions = this.reductions
        let years = this.years;

        years.forEach((year) => {
            year = year.dataKey

            if (!(revenues instanceof Array)) {
                return this.grossProfit;
            }

            if (!(reductions instanceof Array)) {
                return this.grossProfit;
            }

            this.totalRevenue = this.calculateTotValues(revenues, year);

            this.finalRevenue = { ...this.finalRevenue, ...this.totalRevenue };

            this.totalReductions = this.calculateTotValues(reductions, year);

            this.grossProfit = {
                ...this.grossProfit,
                [year]: (this.totalRevenue[year] - this.totalReductions[year]) || 0
            }

            this.finalRevenueAndReductions = {
                ...this.finalRevenueAndReductions,
                [year]: {
                    revenueIncome: this.totalRevenue[year],
                    reductions: this.totalReductions[year]
                }
            }
        });

        return this.grossProfit;
    }

    getTotalRevenue() {
        return this.finalRevenue;
    }

    getTotalExpenses() {

        this.years.forEach((year) => {
            year = year.dataKey

            Object.keys(this.expenses).forEach((item) => {

                const response = this.calculateTotValues(this.expenses[item], year);

                this.totalExpensesWise = {
                    ...this.totalExpensesWise,
                    [year]: {
                        ...this.totalExpensesWise[year],
                        [item]: response[year]
                    }
                }

                this.totalExpenses = {
                    ...this.totalExpenses,
                    [year]: (this.totalExpenses[year] ?? 0) + response[year]
                }
            });
        });

        return this.totalExpenses;
    }

    getNetProfitBeforeTax() {

        this.years.forEach((year) => {
            year = year.dataKey

            this.netProfitBeforeTax = {
                ...this.netProfitBeforeTax,
                [year]: this.grossProfit[year] - this.totalExpenses[year]
            }
        });
        return this.netProfitBeforeTax;
    }
    getNetProfitBeforeInterestDepreciation() {
        return this.years.reduce((cal, { dataKey: year }) => {
            return {
                ...cal,
                [year]: this.grossProfit[year] - this.totalExpenses[year]
            }
        }, {})
    }

    getNetAfterDepriciation() {
        const netProfitBeforeInterestDepreciation = this.getNetProfitBeforeInterestDepreciation();
        return this.years.reduce((cal, { dataKey: year }) => {
            const depreciation = Number(this.depreciation?.[0]?.[year] || 0);
            const interestExpenses = Number(this.interestExpenses?.[0]?.[year] || 0);
            return {
                ...cal,
                [year]: Number(netProfitBeforeInterestDepreciation[year] || 0) - (Number(depreciation) + Number(interestExpenses))
            }
        }, {})
    }

    getNetProfitAfterTax() {
        const netAfterDepriciation = this.getNetProfitBeforeInterestDepreciation();

        return this.years.reduce((cal, { dataKey: year }) => {
            const taxes = this.calculateTotValues(this.taxes, year);

            this.finalTax = {
                ...this.finalTax,
                [year]: {
                    taxes: taxes[year]
                }
            }

            return {
                ...cal,
                [year]: Number(netAfterDepriciation[year]) - taxes[year]
            }

        }, {})
    }

    get comprehensiveToGernaralised() {
        this.getGrossProfitAganist();
        this.getTotalExpenses();
        this.getNetProfitBeforeTax();
        this.getNetProfitAfterTax();

        return this.years.reduce((obj, { dataKey }) => {
            return {
                ...obj,
                [dataKey]: {
                    ...this.finalRevenueAndReductions[dataKey],
                    ...this.totalExpensesWise[dataKey],
                    ...this.finalTax[dataKey]
                }
            }
        }, {})
    }
}