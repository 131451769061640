import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { creditData } from "../../../configs/mainStore";
import { getApplicationsFormHistories } from "../../../services/apiCalls";
import { dateFormatWithTimeChange } from "../../../utility/fomatters";
import { userName } from "../../../utility/helpers/creditFileUtility";
import { getCurrentWorkflowStage } from "../../../utility/helpers/getWorkFlow";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { openGlobalModal } from "../GlobalModal/GlobalModal";

const ApplicationsFormHistory = () => {
    const [loading, setLoading] = React.useState(true);
    const [records, setRecords] = React.useState([]);
    const [options, setOptions] = React.useState({ pre: null, post: null });

    useEffect(() => {
        fetchRecords(creditData?.get()?.applicationId?.[0]);
    }, [creditData?.get()?.applicationId, options]);

    const fetchRecords = async (applicationId) => {
        try {
            const result: any = await getApplicationsFormHistories({ applicationId, ...options });
            setRecords(result);
        } catch (error) {
            setRecords([]);
        } finally {
            setLoading(false);
        }
    }

    const openJson = (json, type) => {
        openGlobalModal({
            modalSize: "lg",
            title: "History " + type,
            bodyId: "json-view-component-modal",
            close: false,
            modalParams: {
                jsonData: JSON.parse(json || "{}"),
                options: { mode: "view" },
            },
        });
    }

    return <Stack bgcolor={"white"}>
        <Stack bgcolor={"rgb(230, 240, 250)"} p={2} py={1} style={{ top: "0px", position: "sticky", zIndex: 100 }}>
            <Typography variant="subtitle1" color={"gray"}>Application Form Submission History</Typography>
        </Stack>
        <Stack p={2} border={1} borderRadius={1} borderColor={grey[300]} px={1} style={{ top: "40px", position: "sticky", zIndex: 100, backgroundColor: "white" }} mt={1}>
            <Typography variant="subtitle1" color={"gray"}>Rerieve Log Type</Typography>
            <Stack direction={"row"} spacing={2}>
                <Stack direction={"row"} alignItems={"center"} width={"25%"}>
                    <Typography color={"gray"} variant="body2">Pre Update</Typography>
                    <CheckBoxComponent onChange={(e) => setOptions({ ...options, pre: e.target.checked })} checked={options.pre}></CheckBoxComponent>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} width={"25%"}>
                    <Typography color={"gray"} variant="body2">Post Update</Typography>
                    <CheckBoxComponent onChange={(e) => setOptions({ ...options, post: e.target.checked })} checked={options.post}></CheckBoxComponent>
                </Stack>
            </Stack>
        </Stack>
        <Stack p={2}>
            {records?.map((record: any) => <Stack p={1} direction={"row"} gap={2} flexWrap={"wrap"} borderBottom={1} borderColor={grey[300]} mt={1}>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>History Serial</Typography>
                    <Typography color={"gray"} variant="body2">{record.historyId}</Typography>
                </Stack>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>Insert / Update</Typography>
                    <Typography color={"gray"} variant="body2">{record.insertUpdate === "I" ? "Insert" : "Update"}</Typography>
                </Stack>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>Corporate / Individual</Typography>
                    <Typography color={"gray"} variant="body2">{record.participantType === 1 ? "Individual" : "Corporate"}</Typography>
                </Stack>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>Actioned By</Typography>
                    <Typography color={"gray"} variant="body2">{userName(record.userId)}</Typography>
                </Stack>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>Workflow Stage</Typography>
                    <Typography color={"gray"} variant="body2">{getCurrentWorkflowStage(record.workflowStage)}</Typography>
                </Stack>
                <Stack minWidth={"200px"}>
                    <Typography variant="caption" color={"gray"}>Updated At</Typography>
                    <Typography color={"gray"} variant="body2">{dateFormatWithTimeChange(record.createdDate)}</Typography>
                </Stack>
                <Stack display={!!record?.preJson ? "flex" : "none"} minWidth={"200px"} alignItems={"flex-start"}>
                    <Typography variant="caption" color={"gray"}>Previous Log</Typography>
                    <ButtonComponent size={"small"} variant={"outlined"} onClick={() => { openJson(record?.preJson, "Pre Log - " + record.historyId) }} title={"View"}></ButtonComponent>
                </Stack>
                <Stack display={!!record?.postJson ? "flex" : "none"} minWidth={"200px"} alignItems={"flex-start"}>
                    <Typography variant="caption" color={"gray"}>Post Log</Typography>
                    <ButtonComponent size={"small"} variant={"outlined"} onClick={() => { openJson(record?.postJson, "Post Log - " + record.historyId) }} title={"View"}></ButtonComponent>
                </Stack>
            </Stack>)}
        </Stack>
    </Stack>;
};

export default ApplicationsFormHistory;