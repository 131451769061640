import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Stack, Typography } from '@mui/material';
import { dateFormatWithTimeChange } from '../../../../../utility/fomatters';
import { hasTabAccess, userName } from '../../../../../utility/helpers/creditFileUtility';
import { defaultOptions } from '../../../../../utility/util';
import { IconRowAgreementConditionComponent } from '../IconRowAgreementConditionComponent';

const ConditionItemRow = (params) => {
  const {
    data,
    applicationId,
    setNewDataArray,
    creditFileData,
    actionDelete,
  } = params;
  const color = defaultOptions?.color;
  const createdDate = data.createdDate ? dateFormatWithTimeChange(data.createdDate) : '';

  return <Stack key={data.sequence}>
    <IconRowAgreementConditionComponent
      Icon={RadioButtonCheckedIcon}
      subTxt={data?.title}
      secondaryTxt={data?.description}
      color={color}
      data={data}
      applicationId={applicationId}
      setNewDataArray={setNewDataArray}
      creditFileData={creditFileData}
      actionDelete={actionDelete}
      disableAccordingTo={!hasTabAccess()}
    />
    <Stack direction={'row'} justifyContent={'space-between'} px={6} mb={1}>
      <Typography variant='caption' color="grey">Created by: {userName(data?.createdBy)}</Typography>
      <Typography variant='caption' color="grey">Created At: {createdDate}</Typography>
    </Stack>
  </Stack>;
};

export default ConditionItemRow;
