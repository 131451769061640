import { Downgraded, useHookstate } from "@hookstate/core";
import { Stack, Typography } from "@mui/material";
import SearchComponent from "../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";
import { productDetails } from "../../configs/mainStore";

const CatalogSelectionModal = ({ action }) => {

    const productMasterData: any = useHookstate(productDetails);
    const { definitionMap: _catalogMap }: any = productMasterData.attach(Downgraded).get() || {};

    const searchText = useHookstate("");

    const catalogs = Object.keys(_catalogMap?.catalogDefinitionMap || {} || {}).reduce((acc: any, key) => {
        const catalog: any = _catalogMap?.catalogDefinitionMap?.[key] || "N/A";
        acc.push({ id: key, name: catalog });
        return acc;
    }, []);

    return <Stack direction={"column"} >
        <Stack pr={2}>
            <SearchComponent placeholder={"Search Workflow Stage"} handleChangeSearchText={(text) => {
                searchText.set(text);
            }}></SearchComponent>
        </Stack>
        <Stack style={{ height: "100%", overflowY: "auto", }} pr={2}>
            {catalogs.filter((item) => item.name.toLowerCase().includes(searchText.get().toLowerCase())).map((obj, index) => <Stack key={index} direction={"row"} alignItems={"center"} justifyContent={"space-between"} flex={1} py={1} onClick={() => {
                action(obj);
            }} style={{ cursor: "pointer" }}>
                <Stack direction={"row"} flex={1}>
                    <Typography color={"gray"}>{obj.name}</Typography>
                </Stack>
                <Typography color={"gray"} variant="caption">{obj.id}</Typography>
            </Stack>)}
        </Stack>
    </Stack>;
}

export default CatalogSelectionModal;