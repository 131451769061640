import { Divider, Stack, Typography } from "@mui/material"
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import React, { Suspense, lazy } from "react";
import Loading from "react-loading";
import { camelCaseToWords } from "../../../utility/util";
import { NumericFormat } from "react-number-format";
import { TextBoxComponent } from "../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { toast } from "../../../components/OtherComponents/GlobalToast";
import { updateProductProperty } from "../../../services/productApis";

export default (props) => {
    const CloseIcon = lazy(() => import("@mui/icons-material/Close"));
    const [loading, setLoading] = React.useState(false);

    if (!props.field) {
        return <></>;
    }

    const { key, value } = props.field;
    const formattedKey = camelCaseToWords(key);

    const updateProductDetails = async () => {
        let payload = { message: formattedKey + " updated successfully.", status: "success" };

        try {
            setLoading(true);
            props.setUpdating(props.field.key);
            const data = await updateProductProperty({ ...props });
            props.callback(data);
        } catch (error) {
            payload.status = "error";
            payload.message = "Failed to update product details.";
        } finally {
            toast(payload);
            setLoading(false);
            props.setUpdating("");
        }
    }

    return (
        <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="subtitle2">
                    {formattedKey + " update"}
                </Typography>
                <Suspense fallback={<Loading></Loading>}>
                    <CloseIcon
                        onClick={closeGlobalModal}
                    />
                </Suspense>
            </Stack>
            <Divider></Divider>
            <Stack py={4}>
                <NumericFormat
                    customInput={TextBoxComponent}
                    variant="outlined"
                    thousandSeparator={true}
                    label={formattedKey}
                    value={value}
                    decimalScale={2}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    required={true}
                    fullWidth={true}
                    onValueChange={(e: any) => {
                        props.field.value = e.value;
                    }}
                    isAllowed={(values) => {
                        try {
                            if (values.value && parseInt(values.value.toString()) > 999999999) {
                                return false;
                            }
                        } catch (error) {
                            return false;
                        }
                        return true;
                    }}
                />
            </Stack>
            {!loading ? <Stack direction={"row-reverse"} spacing={2}>
                <ButtonComponent size={"small"} variant={"outlined"} onClick={updateProductDetails} title={"Update"}></ButtonComponent>
                <ButtonComponent variant={"contained"} onClick={closeGlobalModal} title={"Cancel"}></ButtonComponent>
            </Stack> : <Stack direction={"row-reverse"} spacing={2}><CircularProgressComponent size={25} /></Stack>}
        </Stack>
    );
}