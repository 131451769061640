import { Grid, Stack } from "@mui/material";

const DetailItem = ({ title, value, valueFontClass, titleFontClass, children = "", isDateInput = false }) => {
  return (
    <Stack>
      <div className={`basic-font basic-font-color opacity-7 ${titleFontClass}`}>{isDateInput ? `${title} (DD/MM/YYYY)` : title}</div>
      <div className={`basic-font basic-font-color ${valueFontClass}`}>
        {value} {children != null && children != "" ? children : ""}
      </div>
    </Stack>
  );
};

export const DetailInlinedItem = ({ title, value, valueFontClass = null, titleFontClass = null }) => {
  return (
    <Grid item>
      <Stack>
        <span>
          <span className={`basic-font basic-font-color ${valueFontClass || "font-size-14"}`}>
            <span className="opacity-7">{title}</span> {title ? ":" : ""} <span>{value}</span>{" "}
          </span>
        </span>
      </Stack>
    </Grid>
  );
};

export default DetailItem;
