export const analysisLoanRequirementConfigs = [
  { key: " description", title: "Description", mergeCell: false, children: [] },
  {
    key: "existingEquality",
    title: "Exsisting - Equity",
  },
  {
    key: "existingBank",
    title: "Exsisting - Bank",
  },
  {
    key: "proposedEquity",
    title: "Proposed - Equity",
  },
  {
    key: "proposedBank",
    title: "Proposed - Bank",
  },
  {
    key: "totalCost",
    title: "Total Cost",
  },
];
