import { Box, Grid } from '@mui/material';
import React from 'react'

const ModalInputComponent = ({ title, component, isMandatory, showErrors}) => {
    return (
        <>
            <Grid container item xs={12}>
                <Grid item xs={6} md={3} className="align-center">
                    <div className="align-left font-size-14 basic-font basic-font-color">{title}  {isMandatory && <span className="error">*</span>}</div>
                </Grid>
                <Grid item xs={6} md={9}>
                    {component}
                </Grid>
            </Grid>

            {showErrors && (
                <Grid container item xs={12}>
                    <Grid item xs={6} md={3}></Grid>
                    <Grid container item xs={6} md={9}>
                        <div className="error font-size-14">Required !</div>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default ModalInputComponent