import { useHookstate } from "@hookstate/core";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import EditIcon from '@mui/icons-material/Edit';
import ForwardIcon from "@mui/icons-material/Forward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CORE_BANK_KEYS } from "../../../../../../configs/constants/contants";
import { privilegeKeys } from "../../../../../../configs/constants/privilegeKeys";
import { getClientsDetails, getCoreBankData, getSCVRecoveryDetails, setCoreBankData } from "../../../../../../services/bankServiceInvorker";
import { Access, jointApplication, loadCustomerNICs } from "../../../../../../utility/helpers/creditFileUtility";
import AutoCompleteProxyComponent from "../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { ChipsComponent } from "../../../../../InputComponents/ChipsComponent/ChipsComponent";
import { closeGlobalModal, openGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { Toast } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../../InnerSectionHeaderComponent";

const RecoveryAccountLayoutV2 = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  // const [selectedRecoveryAccount, setSelectedRecoveryAccount] = useState<any>("");

  const payload: any = useHookstate({
    nic: {},
    cif: {},
    account: {},
    selectedAccount: "",
  });

  const loaders = useHookstate({
    selectedAccount: true,
    cif: true,
    accounts: false,
    update: false,
  });

  const layout = useHookstate({
    forceCIF: false,
    forceSaving: false,
    editable: false,
    customerType: true,
    viewNIC: false,
    viewCIF: true,
  });

  const [cifList, setCIFList] = useState([]);
  const [nicList, setNICList] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const { primary, jb } = loadCustomerNICs();
  const usePAOnly = true;

  useEffect(() => {
    loadNICs();

    loadCIFList();

    getCoreBankData(applicationId, CORE_BANK_KEYS.REC_ACC).then((selectedAccountObj) => {
      if (selectedAccountObj instanceof Object || !selectedAccountObj) {
        layout.editable.set(true);
        loaders.selectedAccount.set(false);
        return;
      }

      payload.selectedAccount.set(selectedAccountObj);
      loaders.selectedAccount.set(false);
    });
  }, []);

  const loadNICs = () => {
    const listPA = [primary].reduce((acc, item) => {
      for (const _nic of [item.newNic, item.oldNic]) {
        if (_nic) {
          acc[_nic] = { id: _nic, name: `${_nic} (Primary Applicant)`, details: item };
        }
      }
      return acc;
    }, {});
    let list = { ...listPA };

    if (!usePAOnly) {
      const listJB = [...jb].reduce((acc, item) => {
        for (const _nic of [item.newNic, item.oldNic]) {
          if (_nic) {
            acc[_nic] = { id: _nic, name: `${_nic} (Joint Applicant)`, details: item };
          }
        }
        return acc;
      }, {});
      list = { ...list, ...listJB };
    }
    setNICList(Object.values(list));
  }

  const loadCIFFromNIC = async (force = false) => {
    if (!payload?.nic?.get()?.id) {
      return;
    }
    loaders.cif.set(true);
    getClientsDetails(payload?.nic?.get()?.id, force).then((res) => {
      const content: any = {};
      for (const [nic, data] of Object.entries(res || {})) {
        const { IndividualProfiles, JoinedProfiles }: any = data;
        for (const profile of [...IndividualProfiles, ...JoinedProfiles]) {
          if (layout.customerType.get() && jointApplication() && profile.customerType !== "J") {
            continue;
          }
          content[profile.CIF] = { id: profile.CIF, name: `(${profile.CIF}) ${profile.initialInFull.trim()}`, details: profile };
        }
      }
      setAccounts([]);
      setCIFList(Object.values(content));
      loaders.cif.set(false);
    });
  }

  const loadCIFList = async (force = false) => {
    loaders.cif.set(true);
    const nics = (usePAOnly ? [primary] : [primary, ...jb]).reduce((acc, item: any) => acc = [...acc, item?.newNic || "", item?.oldNic || ""], []).filter(item => item);
    getClientsDetails(nics.join(","), force).then((res) => {
      const content: any = {};
      for (const [nic, data] of Object.entries(res || {})) {
        const { IndividualProfiles, JoinedProfiles }: any = data;
        for (const profile of [...IndividualProfiles, ...JoinedProfiles]) {
          if (layout.customerType.get() && !jointApplication() && profile.customerType === "J") {
            continue;
          }
          content[profile.CIF] = { id: profile.CIF, name: `(${profile.CIF}) ${profile.initialInFull.trim()}`, details: profile };
        }
      }
      setCIFList(Object.values(content));
      loaders.cif.set(false);
    });
  }

  const loadAccounts = async (force = false) => {
    let cif = payload.cif?.get()?.id;
    let nic = payload.nic?.get()?.id;

    if (!layout.viewCIF.get()) {
      cif = null;
    }

    if (!layout.viewNIC.get()) {
      nic = null;
    }

    if (cif) {
      loaders.accounts.set(true);
      getSCVRecoveryDetails(cif, "CIF", force, "SAVING", applicationId).then((res) => {
        const content: any = {};
        for (const saving of res?.scvDetails?.savings || []) {
          const { accountNumber, productName }: any = saving;
          content[accountNumber] = { id: accountNumber, name: `(${accountNumber}) ${productName}`, details: saving };
        }
        setAccounts(Object.values(content));
        loaders.accounts.set(false);
      });
    }

    if (nic) {
      loaders.accounts.set(true);
      getSCVRecoveryDetails(nic, "NIC", force, "SAVING", applicationId).then((res) => {
        const content: any = {};
        for (const saving of res?.scvDetails?.savings || []) {
          const { accountNumber, productName }: any = saving;
          content[accountNumber] = { id: accountNumber, name: `(${accountNumber}) ${productName}`, details: saving };
        }
        setAccounts(Object.values(content));
        loaders.accounts.set(false);
      });
    }
  }


  const onClickedSend = async (accId) => {
    let { id: account } = payload.account.get() || {};

    if (accId && accId?.type !== "click") {
      account = accId;
    }

    if (!account) {
      Toast.error("Select an account to proceed");
      return;
    }

    try {
      loaders.update.set(true);
      const { message } = await setCoreBankData(applicationId, CORE_BANK_KEYS.REC_ACC, account);
      if (message) {
        payload.selectedAccount.set(account);
        Toast.success(`Recovery account ${account} updated successfully`, "Success");
        layout.editable.set(false);
        return;
      }

      throw new Error("Failed to proceed with updating recovery account. Please try again later");
    } catch (error: any) {
      Toast.error(error.message);
    } finally {
      loaders.update.set(false);
    }
  };

  const ContentPane = ({ children }) => {
    return <Stack>
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} disabled={!Access.privilege()} />
      <Stack>
        {children}
      </Stack>
    </Stack>;
  }

  const editRecoveryAccount = () => {
    layout.editable.set(!layout.editable.get());
  }

  const addCustomNumber = () => {
    openGlobalModal({
      modalSize: "xs",
      title: "Add Custom Entry",
      bodyId: "custom-entry",
      close: false,
      modalParams: {
        label: "Recovery Account Number",
        value: payload.selectedAccount.get(),
        callBackOnAction: async (value) => {
          payload.selectedAccount.set(value);
          closeGlobalModal();
          onClickedSend(value);
        },
      },
    });
  }

  const isDisabled = () => {
    return !Access.tab(innerComponentData?.id) || !Access.privilege();
  }

  const extractCutomerType = (type) => {
    switch (type) {
      case "J":
        return "Joint";
      case "I":
        return "Individual";
      case "C":
        return "Corporate";
    }
    return "Individual";
  }

  const selectionEnabled = () => {
    if (Access.privilege(privilegeKeys.SYS_CONTROL_TROUBLESHOORT)) {
      return false;
    }

    if (isDisabled()) {
      return true;
    }

    return loaders.accounts.get();
  }

  return <ContentPane>
    <Paper elevation={1}>
      <Stack p={2} pb={4} px={4} width={{ sm: "100%", lg: "80%" }} spacing={3}>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack p={2} pl={0} spacing={2}>
            <Typography variant="subtitle2" color={"gray"}>Selected Recovery Account</Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              {payload.selectedAccount.get() && <ChipsComponent label={<Typography>{payload.selectedAccount.get()}</Typography>} onClick={() => Access.privilege(privilegeKeys.SYS_CONTROL_TROUBLESHOORT) ? addCustomNumber() : null}></ChipsComponent>}
              {loaders.selectedAccount.get() ? <CircularProgressComponent size={24} /> :
                <EditIcon color={'primary'} sx={{ cursor: "pointer", fontSize: "12pt" }} onClick={() => editRecoveryAccount()} />}
              {Access.privilege(privilegeKeys.SYS_CONTROL_TROUBLESHOORT) && <AdminPanelSettings color={layout.customerType.get() ? 'primary' : 'success'} sx={{ cursor: "pointer", fontSize: "12pt" }} onClick={() => {
                layout.customerType.set(false);
                loadCIFList(true);
              }} />}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"column"}>
          {layout.editable.get() && <Divider></Divider>}
          {layout.editable.get() && <Stack direction={"row"} spacing={10} alignItems={"center"} mt={2}>
            <Typography variant="subtitle2" color={"gray"}>Account Search Method</Typography>
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="caption" color={"gray"}>CIF</Typography>
                <CheckBoxComponent checked={layout.viewCIF.get()} onChange={(e) => {
                  layout.viewCIF.set(e.target.checked);
                  !e.target.checked && payload.cif.set({});
                }} />
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="caption" color={"gray"}>NIC</Typography>
                <CheckBoxComponent checked={layout.viewNIC.get()} onChange={(e) => {
                  layout.viewNIC.set(e.target.checked);
                  !e.target.checked && payload.nic.set({});
                }} />
              </Stack>
            </Stack>
          </Stack>}
          <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
          {layout.editable.get() && <Stack direction={"row"} spacing={1} mt={2} alignItems={"flex-start"}>
            {layout.viewNIC.get() && <Stack width={"350px"}>
              <Stack direction={"row"}>
                <Stack flex={1}>
                  <AutoCompleteProxyComponent
                    label={"NIC Selection"}
                    value={payload?.nic?.get()?.id || ''}
                    options={nicList}
                    onChange={(e) => {
                      payload.nic.set(e);
                      if (layout.viewCIF.get()) {
                        payload.cif.set({});
                        loadCIFFromNIC(false);
                      } else {
                        loadAccounts();
                      }
                    }}
                    disabled={loaders?.cif?.get() || isDisabled()}
                  />
                </Stack>
                {
                  loaders.selectedAccount.get() ?
                    <Stack direction={"row-reverse"} width={"85px"}><CircularProgressComponent size={24} /></Stack> :
                    <Stack direction={"row"} spacing={1}>
                      <ButtonComponent
                        iconbtn
                        children={<RefreshIcon className="basic-font-color" />}
                        onClick={() => {
                          loadCIFFromNIC(true);
                        }}
                        disabled={loaders?.cif?.get() || isDisabled()}
                      />
                    </Stack>
                }
              </Stack>
              <Stack mt={2}>
                {payload.nic.get()?.id && <Typography variant="caption" color={"gray"}>
                  {payload.nic.get()?.id || ""}
                </Typography>}
                {payload.nic.get()?.details?.name && <Typography variant="caption" color={"gray"}>
                  {payload.nic.get()?.details?.name || ""}
                </Typography>}
                {payload.nic.get()?.details?.individualId && <Typography variant="caption" color={"gray"}>
                  {payload.nic.get()?.details?.individualId} (LOS Customer ID)
                </Typography>}
              </Stack>
            </Stack>}
            {layout.viewCIF.get() && <Stack width={"350px"}>
              <AutoCompleteProxyComponent
                label={"CIF Selection"}
                value={payload?.cif?.get()?.id || ''}
                options={cifList}
                onChange={(e) => {
                  payload.cif.set(e);
                  loadAccounts();
                }}
                disabled={loaders.cif.get() || isDisabled()}
              />
              <Stack mt={2}>
                {payload.cif.get()?.id && <Typography variant="caption" color={"gray"}>
                  {payload.cif.get()?.id || ""}
                </Typography>}
                {payload.cif.get()?.details?.initialInFull && <Typography variant="caption" color={"gray"}>
                  {payload.cif.get()?.details?.initialInFull || ""}
                </Typography>}
                {payload.cif.get()?.details?.NIC && <Typography variant="caption" color={"gray"}>
                  {payload.cif.get()?.details?.NIC} (NIC)
                </Typography>}
                {payload.cif.get()?.details?.customerType && <Typography variant="caption" color={"gray"}>
                  {extractCutomerType(payload.cif.get()?.details?.customerType.toUpperCase())} (Customer Type)
                </Typography>}
              </Stack>
            </Stack>}
            {
              loaders.selectedAccount.get() ?
                <Stack direction={"row-reverse"} width={"85px"}><CircularProgressComponent size={24} /></Stack> :
                <Stack direction={"row"} spacing={1} display={!layout.viewCIF.get() ? "none" : "flex"}>
                  <ButtonComponent
                    iconbtn
                    children={<RefreshIcon className="basic-font-color" />}
                    onClick={() => {
                      loadCIFList(true);
                    }}
                    disabled={isDisabled()}
                  />
                </Stack>
            }
            <Stack width={"350px"}>
              <AutoCompleteProxyComponent
                label={"Select Account"}
                value={payload.account.get()?.id || ''}
                options={accounts}
                onChange={(e) => {
                  payload.account.set(e);
                }}
                disabled={selectionEnabled()}
              />
              <Stack mt={2}>
                {payload.account.get()?.id && <Typography variant="caption" color={"gray"}>
                  {payload.account.get()?.id}
                </Typography>}
                {payload.account.get()?.details?.openDate && <Typography variant="caption" color={"gray"}>
                  {payload.account.get()?.details?.openDate} (Open Date)
                </Typography>}
                {payload.account.get()?.details?.balance && <Typography variant="caption" color={"gray"}>
                  {payload.account.get()?.details?.balance} (Balance)
                </Typography>}
              </Stack>
            </Stack>
            {
              loaders.accounts.get() ?
                <Stack direction={"row-reverse"} width={"85px"}><CircularProgressComponent size={24} /></Stack> :
                <Stack direction={"row"} spacing={1}>
                  <ButtonComponent
                    iconbtn
                    children={<RefreshIcon className="basic-font-color" />}
                    onClick={() => {
                      loadAccounts(true);
                    }}
                    disabled={isDisabled()}
                  />
                </Stack>
            }
            <Stack flex={1} alignItems={"center"} pt={0.5}>
              <ButtonComponent size={"small"} startIcon={loaders.update.get() ? <CircularProgressComponent size={12} /> : <ForwardIcon />} variant="contained" onClick={onClickedSend} style={{}} title={"Submit"} disabled={loaders.update.get() || isDisabled()} />
            </Stack>
          </Stack>}
        </Stack>
      </Stack>
    </Paper>
  </ContentPane >;
};

export default RecoveryAccountLayoutV2;
