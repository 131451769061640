import { Downgraded, useState } from '@hookstate/core';
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createNewForm, updateForm } from '../../../services/apiCalls';
import { ButtonComponent } from '../../InputComponents/ButtonComponent/ButtonComponent';
import { SwitchComponent } from '../../InputComponents/SwitchComponent/SwitchComponent';
import { TextBoxComponent } from '../../InputComponents/TextBoxComponent/TextBoxComponent';
import { CMTextInput } from '../CommunicationService/CMTextInput';
import { closeGlobalModal } from '../GlobalModal/GlobalModal';
import { Toast } from '../GlobalToast';
import { CircularProgressComponent } from '../ProgressComponent/ProgressComponent';

//
// ──────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: N E W   A P P L I C A T I O N   F O R M   C R E A T O R : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────
//

export const NewApplicationForm = ({ form, callback }) => {
  const loading = useState(false);
  const { t: translate } = useTranslation();

  form = useState(form?.id?.get() ? { ...form.attach(Downgraded).get() } : { name: "", description: "", id: null, status: 1 });

  const validate = () => {
    if (!form?.name?.get()?.length) {
      Toast.error("Name can not be empty.");
      return false;
    }


    if (!form?.description?.get()?.length) {
      Toast.error("Description can not be empty.");
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    try {
      if (!validate()) {
        return;
      }

      loading?.set(true);
      const payload: any = {
        name: form?.name.get(),
        description: form?.description.get(),
        status: form?.status.get(),
      };

      if (form?.id.get()) {
        payload.id = form?.id.get();
      }

      let response;
      if (!payload.id) {
        response = await createNewForm(payload);
      } else {
        const { id, ...data } = payload;
        response = await updateForm({ ...data }, id);
      }

      if (response?.status) {
        Toast.success("Application form saved successfully.");
        callback(response.data);
        return;
      }

      throw new Error("Failed to save application form");
    } catch (error) {
      Toast.success("Application form saved successfully.");
    } finally {
      loading?.set(false);
    }
  };

  return (
    <Stack flex={1}>
      <Stack p={2}>
        <Stack direction={"row"}>
          <Typography flex={1} variant='h6' color={"gray"}>{(form?.id?.get() || false) ? translate('editApplicationTitle') : translate('createApplicationTitle')}</Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              closeGlobalModal();
            }}
          />
        </Stack>

        <Divider />

        <Stack mt={2}>
          <CMTextInput
            desc={translate('smsModule.form.published')}
            component={
              <SwitchComponent
                commonStatus={true}
                checked={form?.status.get() == 1 ? true : false}
                onChange={() => form?.status?.set(form?.status?.get() === 1 ? 0 : 1)}
              />
            }
          />
        </Stack>

        <Stack>
          <CMTextInput
            desc={`Name`}
            component={
              <TextBoxComponent
                onChange={(e) => form?.name?.set(e.target.value)}
                multiline={true}
                value={form?.name.get()}
              />
            }
          />
          <CMTextInput
            desc={`Description`}
            component={
              <TextBoxComponent
                onChange={(e) => form?.description?.set(e.target.value)}
                multiline={true}
                value={form?.description.get()}
              />
            }
          />
        </Stack>

        <Stack direction={"row-reverse"} mt={4}>
          {loading.get()
            ? <CircularProgressComponent size={30} />
            : <ButtonComponent
              title={(form?.id?.get() || false) ? "Update" : "Create"}
              variant="contained"
              onClick={handleSubmit}
            />}
        </Stack>
      </Stack>
    </Stack >
  );
};
