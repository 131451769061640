import { useHookstate } from "@hookstate/core";
import { getCopy } from "../../../../../../../../../../utility/util";
import { typeOfStock } from "../../../../../FormBuilder/Tools/Helpers/inspectionConstants";
import { businessDetailsState } from "../../../../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import YearlyWorkingCapitalBuilder from "../YearlyWorkingCapitalBuilder";
import { WorkingCapital } from "./WorkingCapital";

const StockLayout = ({ otherParams }) => {
    const businessDetails: any = useHookstate(businessDetailsState);
    const data = businessDetails;

    if (!data?.stock) return <></>

    if (Array.isArray(data?.stock)) {
        return <WorkingCapital data={data?.stock} otherParams={{ ...otherParams, "typeOfStock": getCopy(typeOfStock) }} />
    }

    return <YearlyWorkingCapitalBuilder id={`stock`} data={data?.stock} otherParams={{ ...otherParams, "typeOfStock": getCopy(typeOfStock) }} Component={WorkingCapital} />;
}

export default
    StockLayout;