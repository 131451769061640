import { Downgraded, useState } from '@hookstate/core';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { privilegeKeys } from '../../../../../configs/constants/privilegeKeys';
import { userDetails } from '../../../../../configs/mainStore';
import { getDisbursementAPIStatusData, resetDisbursementAPIStatusData } from '../../../../../services/bankServiceInvorker';
import { hasPrivilege } from '../../../../../utility/helpers/creditFileUtility';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { closeGlobalModal, openGlobalModal } from '../../../GlobalModal/GlobalModal';
import { Toast } from '../../../GlobalToast';
import { CircularProgressComponent } from '../../../ProgressComponent/ProgressComponent';
import InnerSectionHeaderComponent from '../InnerSectionHeaderComponent';
import APIItem from './APIItem';

const DisbursementAPIStatus = ({ data, applicationId, currentApplicationWfData, innerComponentData }) => {
    const isLoading = useState(true);
    const [resetting, setResetting] = React.useState(false);
    const requestData = useState([]);

    const { userId }: any = userDetails.attach(Downgraded).get();
    const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

    useEffect(() => {
        prepareDataSource();
    }, []);

    const restartRequestQueue = async () => {
        openGlobalModal({
            modalSize: "sm",
            title: "Are you sure ?",
            bodyId: "app-section-delete-confirm",
            close: false,
            modalParams: {
                textMessage: "All the disbursement tracking logs will be deleted.",
                callBackOnAction: () => restartRequestQueueProceed(),
            },
        });
    }

    const restartRequestQueueProceed = async () => {
        try {
            setResetting(true);
            const { data } = await resetDisbursementAPIStatusData(applicationId);
            if (data) {
                Toast.success("Disbursement request queue reset successfully");
                prepareDataSource();
            }
        } catch (error) {
            Toast.error("Failed to reset disbursement request queue");
        } finally {
            setResetting(false);
            closeGlobalModal();
            requestData.set([]);
        }
    }

    const prepareDataSource = async () => {
        isLoading.set(true);
        const records = await getDisbursementAPIStatusData(applicationId);
        if (records?.length > 0) {
            requestData.set(records);
        }
        isLoading.set(false);
    }

    const privilegeToReset = hasPrivilege({ key: privilegeKeys.LOAN_DISBURSEMENT_RESET });

    const Actions = () => {
        if (!privilegeToReset) {
            return <></>;
        }

        return resetting ? <CircularProgressComponent size={24} /> : <ButtonComponent title={"Reset"} onClick={restartRequestQueue}></ButtonComponent>;
    }

    return (
        <div className="mainSectionWrapper full-width full-height">
            <InnerSectionHeaderComponent
                innerComponentData={innerComponentData}
                applicationId={applicationId}
                currentApplicationWfData={currentApplicationWfData}
                disabled={isLoggedInUserNotTheAssignee}
            >
                <Actions></Actions>
            </InnerSectionHeaderComponent>
            <Grid container className="inner-component-height padding-sm">
                {isLoading?.get() && (
                    <CircularProgressComponent
                        size={30}
                        sx={{ marginTop: '20px', marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto', zIndex: 100 }}
                    />
                )}
                <Grid container>
                    <Grid container className="border-radius-5 sub-header-height">
                        {requestData.length > 0 && requestData?.get()?.map((apiData, index) => {
                            return (
                                <APIItem data={apiData} key={index} />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default DisbursementAPIStatus