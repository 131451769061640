import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { businessSourceIncome } from "../../../../../../configs/constants/contants";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import isProductOf from "../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import {
  calculateAnnualValue,
  incomeExpenseFrequency,
} from "../../../../../../utility/util";
import DetailItem from "../../DetailItem";

const BusinessIncome = ({
  businessIncome,
  masterData,
  isMainContactDetails,
}) => {
  const { t: translate } = useTranslation();

  const getTotalBusinessIncome = () => {
    let total = 0;
    businessIncome?.income?.forEach((business) => {
      const totalItem = calculateAnnualValue(
        business?.incomeBusiness,
        business?.incomeFrequencyForMonthBusiness,
        business?.incomeFrequencyForYearBusiness
      );
      total += totalItem;
    });
    return total;
  };

  const getTotalBusinessExpense = () => {
    let total = 0;
    businessIncome?.expense?.forEach((personnel) => {
      const totalItem = calculateAnnualValue(
        personnel?.expenseBusiness,
        personnel?.expenseFrequencyForYearBusiness,
        personnel?.expenseFrequencyForMonthBusiness
      );
      total += totalItem;
    });
    return total;
  };

  const TotalAnnualProfitBusiness = () => {
    let profit: any = getTotalBusinessIncome() - getTotalBusinessExpense();
    return `LKR ${formatCurrency(profit ? profit : 0)}`;
  };

  const getTotalBusinessExpenseData = () => {
    const total = getTotalBusinessExpense();
    return `LKR ${formatCurrency(total ? total : 0)}`;
  };

  const getTotalBusinessIncomeData = () => {
    const total = getTotalBusinessIncome();
    return `LKR ${formatCurrency(total ? total : 0)}`;
  };

  const getSourceOfIncomeBusiness = (sourceOfIncomeBusinessId) => {
    for (const item of businessSourceIncome) {
      if (item.id == sourceOfIncomeBusinessId) {
        return item.name;
      }
    }
    return "-";
  };

  return (
    <div className="full-width margin-xs-b">
      {businessIncome ? (
        <>
          <Grid
            container
            p={1}
            className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
            style={{ backgroundColor: "#e6f0fa" }}
          >
            Business Income
          </Grid>
          <Grid container>
            <Grid container p={1} className="data-box" spacing={1}>
              <Grid container item mt={1}>
                <Grid item xs={3}>
                  <DetailItem
                    title="Net Annual Income"
                    value={getTotalBusinessIncomeData()}
                    valueFontClass="font-size-14"
                    titleFontClass="font-size-12"
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem
                    title="Net Annual Expenses"
                    value={getTotalBusinessExpenseData()}
                    valueFontClass="font-size-14"
                    titleFontClass="font-size-12"
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailItem
                    title="Net Annual Profit"
                    value={TotalAnnualProfitBusiness()}
                    valueFontClass="font-size-14"
                    titleFontClass="font-size-12"
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid container p={1} className="data-box">
                  <Grid
                    container
                    p={1}
                    style={{ backgroundColor: "#e6f0fa" }}
                    className="border-radius-5"
                  >
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">
                      Income
                    </div>
                  </Grid>
                  {businessIncome?.income?.length > 0 &&
                    businessIncome?.income?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)?.map((incomeData, key) => {
                        const incomeFrequency: any =
                          incomeExpenseFrequency?.find(
                            (t) => t.id == incomeData?.incomeFrequencyBusiness
                          );
                        const frequencyName: any = incomeFrequency?.name
                          ? translate(incomeFrequency.name)
                          : "";

                        const getAnnualFunc = (amount, year, month) => {
                          const val = calculateAnnualValue(
                            amount,
                            year,
                            month
                          );
                          return `LKR ${formatCurrency(val)}`;
                        };

                        const sourceOfIncomeBusiness = isProductOf(
                          { primaryApplicant: isMainContactDetails }
                        )
                          ? getSourceOfIncomeBusiness(
                            incomeData?.sourceOfIncomeBusiness
                          )
                          : incomeData?.sourceOfIncomeBusiness;
                        return (
                          <Grid
                            container
                            item
                            spacing={1}
                            p={1}
                            key={key}
                            className="data-box"
                          >
                            <Grid item xs={3}>
                              <DetailItem
                                title="Source of Income"
                                value={
                                  sourceOfIncomeBusiness
                                    ? sourceOfIncomeBusiness
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem
                                title="Frequency"
                                value={frequencyName ? frequencyName : "-"}
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Occurrences for Month"
                                value={
                                  incomeData?.incomeFrequencyForMonthBusiness
                                    ? incomeData.incomeFrequencyForMonthBusiness
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Frequency for Annual"
                                value={
                                  incomeData?.incomeFrequencyForYearBusiness
                                    ? incomeData.incomeFrequencyForYearBusiness
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Income"
                                value={
                                  incomeData?.incomeBusiness
                                    ? `LKR ${formatCurrency(
                                      incomeData.incomeBusiness
                                    )}`
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Annual Income"
                                value={getAnnualFunc(
                                  incomeData?.incomeBusiness,
                                  incomeData?.incomeFrequencyForYearBusiness,
                                  incomeData?.incomeFrequencyForMonthBusiness
                                )}
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>

                <Grid container p={1} className="data-box">
                  <Grid
                    container
                    p={1}
                    style={{ backgroundColor: "#e6f0fa" }}
                    className="border-radius-5"
                  >
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">
                      Expense
                    </div>
                  </Grid>
                  {businessIncome?.expense?.length > 0 &&
                    businessIncome?.expense
                      ?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)
                      ?.map((expenseData, key) => {
                        const expenseFrequency: any =
                          incomeExpenseFrequency?.find(
                            (t) =>
                              t.id == expenseData?.expenseFrequencyBusiness
                          );
                        const frequencyName: any = expenseFrequency?.name
                          ? translate(expenseFrequency.name)
                          : "";
                        const expenseTypeBusiness =
                          masterData?.BUSINESS_EXPENSES?.get()?.find(
                            (t) => t.id == expenseData?.expenseTypeBusiness
                          );

                        const getAnnualFunc = (amount, year, month) => {
                          const val = calculateAnnualValue(
                            amount,
                            year,
                            month
                          );
                          return `LKR ${formatCurrency(val)}`;
                        };

                        return (
                          <Grid
                            container
                            item
                            spacing={1}
                            p={1}
                            key={key}
                            className="data-box"
                          >
                            <Grid item xs={3}>
                              <DetailItem
                                title="Expense Type"
                                value={
                                  expenseTypeBusiness?.name
                                    ? expenseTypeBusiness.name
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <DetailItem
                                title="Frequency"
                                value={frequencyName ? frequencyName : "-"}
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Occurrences for Month"
                                value={
                                  expenseData?.expenseFrequencyForMonthBusiness
                                    ? expenseData.expenseFrequencyForMonthBusiness
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Frequency for Annual"
                                value={
                                  expenseData?.expenseFrequencyForYearBusiness
                                    ? expenseData.expenseFrequencyForYearBusiness
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Expense"
                                value={
                                  expenseData?.expenseBusiness
                                    ? `LKR ${formatCurrency(
                                      expenseData.expenseBusiness
                                    )}`
                                    : "-"
                                }
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <DetailItem
                                title="Annual Expense"
                                value={getAnnualFunc(
                                  expenseData?.expenseBusiness,
                                  expenseData?.expenseFrequencyForYearBusiness,
                                  expenseData?.expenseFrequencyForMonthBusiness
                                )}
                                valueFontClass="font-size-14"
                                titleFontClass="font-size-12"
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BusinessIncome;
