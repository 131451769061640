import React from "react";
import TableWrapper from "../../../Helpers/TableContainer/TableWrapper/TableWrapper";
import { TableHeaderWrapperCustom } from "../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import TableBodyWrapper from "../../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { Paper, Stack } from "@mui/material";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";
import { loanDetailsCribDetailConfigs } from "../../../Configs/DirectOtherBankLiabilityAnalysis/DirectOtherBankLiabilityAnalysisConfigs";
import LoanDetailsCribTableBuildRow from "./BuildRowTableComponents/LoanDetailsCribTableBuildRow";

const LoanDetailsCrib = ({ creditData, applicationId, loanDetailsCribData, callBackOnDelete }) => {
  let titleArray: any[] = [];
  let titleStringArr: any[] = [];

  if (loanDetailsCribDetailConfigs.length > 0) {
    loanDetailsCribDetailConfigs.forEach((obj) => {
      titleStringArr.push(obj?.title);
    });
  }

  titleArray = titleStringArr;

  if (titleArray.length === 0) {
    return <></>;
  }

  return (
    <Stack component={Paper} elevation={0} width={"100%"} height={"100%"}>
      <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
        <TableWrapper>
          <TableHeaderWrapperCustom headerArray={titleArray} />
          <TableBodyWrapper>
            <LoanDetailsCribTableBuildRow data={loanDetailsCribData} callBackOnDelete={callBackOnDelete()} />
          </TableBodyWrapper>
        </TableWrapper>
      </React.Suspense>
    </Stack>
  );
};

export default LoanDetailsCrib;
