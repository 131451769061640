import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { formatCurrency } from "../../../../../../services/currencyFormater";

function GeneralConditions({ data, applicableRate, coreBankData, typeCaseClass }) {
  const [annualInterestRate, setAnnualInterestRate]: any = React.useState(0.0);
  const { recoveryAccount, standingOrderAmount, investmentAccount } = coreBankData;

  useEffect(() => {
    loanCreditDetails();
  }, [applicableRate]);

  const loanCreditDetails = () => {
    let disburseInterestRate = applicableRate || parseFloat(data?.formData?.creditData?.interestRate) || 0.0;
    let interestRebate = parseFloat(data?.formData?.creditData?.regularPaymentFactor) || 0.0;
    let annualInterestRate = disburseInterestRate + interestRebate;
    setAnnualInterestRate(annualInterestRate);
  };

  return (
    <Grid container columns={12} pl={1} pr={1}>
      <Grid item xs={12}>
        <h4 style={{ textDecoration: "underline" }}>GENERAL CONDITIONS</h4>
      </Grid>
      <Grid item xs={12} className="offer-letter-condition-container">
        <ol>
          <li>The Bank reserves itself the right to amend the rate of interest/commission indicated above depending on market conditions without prior notice.</li>
          <li>Credit facilities granted by the Bank should not be utilized for any other purpose/s other than the purpose/s for which the said facilities are granted.</li>
          <li>In the event of breach of the terms and conditions set out above or default of the facility, the Bank will be entitled to act any time to terminate the above credit facility and receive payments of all claims on such facility.</li>
          <li>In the event of early settlement of the facility, the bank shall at its sole discretion be entitled to charge an early settlement fee on the capital outstanding of the facility.</li>
          <li>
            The facility will be disbursed to a savings account No, <span className={`security-doc-type-variable ${typeCaseClass}`}>{recoveryAccount ? recoveryAccount : "N/A"}</span> maintained at the bank by you. In the event of a takeover of an existing facility from an institution payment will be made directly to such institution.
          </li>
          <li>Disbursement of all approved facilities would be subject to the terms and conditions stated in this letter of offer and completion of all security documentation.</li>
          <li>All government, levies and taxes payable on credit facilities from time to time may be debited to your account when they become due.</li>
          <li>Apart from the above mentioned interest and commission rates, the bank charges applicable to facilities and ancillary services provided in the normal course of banking business will also be debited to your account.</li>
          <li>
            A standing order will be executed debiting your savings account number <span className={`security-doc-type-variable ${typeCaseClass}`}>{recoveryAccount ? recoveryAccount : "N/A"}</span> with Rs. <span className={`security-doc-type-variable ${typeCaseClass}`}>{standingOrderAmount > 0 ? formatCurrency(standingOrderAmount, 2) : "N/A"}</span> and to be credited to your investment account number <span className={`security-doc-type-variable ${typeCaseClass}`}>{investmentAccount ? investmentAccount : "N/A"}</span>. monthly on your advice until the facility is settled in full.
          </li>
          <li>In the event of changing the contact details such as residential telephone number, mobile phone numbers, address, email etc. given to the bank by you at the time of applying the facility, such changes should be immediately informed to the bank.</li>
          <li>The bank reserves the right to follow up or inspect your location/project during the working hours of the bank, with or without prior notice.</li>
          <li>
            Interest rate is <span className={`security-doc-type-variable ${typeCaseClass}`}>{annualInterestRate ? annualInterestRate : "N/A"}</span>% p.a., floating, payable monthly subject to review from time to time depending on the market rate without prior notice. . As such repayment period of the loan will be either extended or reduced while the monthly installment remains unchanged throughout the loan tenor.
          </li>
          <li>Loan granted with a grace period will attract interest from the date of disbursement irrespective of the grace period and the interest payable on amount disbursed should be serviced monthly during the grace period. Monthly installment together with interest should be paid on the due date every month after the grace period.</li>
          <li>In confirmation of your acceptance of this offer please sign and return the enclosed duplicate of this letter. Please note that disbursement of proceeds under the facility/ facilities is conditional upon the submission of relevant agreement and or security documentation been duly signed and delivered to the Bank.</li>
        </ol>
      </Grid>
    </Grid>
  );
}

export default GeneralConditions;
