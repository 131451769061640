import { getUserGroupsForReports } from "../services/applicationApis";
import CachingStorage from "../services/cacheStorage";

export default class AppConfig {
  static historyStoreChoice = 0;
  public constructor() { }

  static invokers = {
    legal: () => { },
    risk: () => { },
    audit: () => { },
    insuarance: () => { },
    actions: (data) => { },
  };

  public static async sync() {
    sessionStorage.removeItem("reports");
    try {
      const data = await getUserGroupsForReports();
      if (data) {
        sessionStorage.setItem("reports", JSON.stringify(data));
        return data;
      }
      return "";
    } catch (err) {
      return "";
    }
  }

  public static async productCatalog() {
    const productCatalog = await AppConfig.sync();
    sessionStorage.setItem("reports", JSON.stringify(productCatalog));
    if (productCatalog && productCatalog?.athamaruCatalog) {
      return await productCatalog?.athamaruCatalog;
    }
  }

  public static get config(): any {
    let object: any = {};

    if (CachingStorage.read(CachingStorage.Keys.REPORTS)) {
      object = CachingStorage.read(CachingStorage.Keys.REPORTS);

    } else if (sessionStorage.getItem("reports")) {
      object = JSON.parse(sessionStorage.getItem("reports") ?? "{}");
      if (Object.keys(object).length === 0) {
        delete sessionStorage.reports;
        AppConfig.sync();
      }
    } else {
      AppConfig.sync();
      object = JSON.parse(sessionStorage.getItem("reports") ?? "{}");

    }

    return object;
  }

  public static get collateralConfig(): any {
    let result = {};

    for (const key in AppConfig.config.collateral) {
      const value = AppConfig.config.collateral[key];
      result[key] = {
        id: value.id,
        status: value.status,
        name: value.name,
      };
      if (value.childs) {
        value.childs.forEach(child => {
          const childKey = `${key}.${child.id}`;
          result[childKey] = {
            id: child.id,
            status: child.status,
            name: child.name,
          };
        });
      }
    }
    return result;
  }

  public static get control(): Boolean {
    return !!sessionStorage.getItem("control");
  }

  public static hasSupercontrol(userId): Boolean {
    return AppConfig.config?.systemAdmins?.includes(userId);
  }

  public static get historyPage(): string {
    let page = "manage-applications";
    if (this.historyStoreChoice === 1) {
      page = CachingStorage.read("lastNavigation");
    } else {
      page = sessionStorage.getItem("lastNavigation") || "";
    }
    page = window.atob(page);
    return page;
  }

  public static set historyPage(value) {
    let page = value;
    page = window.btoa(page);
    if (this.historyStoreChoice === 1) {
      CachingStorage.store("lastNavigation", page);
    } else {
      sessionStorage.setItem("lastNavigation", page);
    }
  }

  public static clearCache(key) {
    sessionStorage.removeItem(key);
  }

  public static get preference(): any {
    let data: string | Number | Object | null = {};
    try {
      let data = localStorage.getItem("userPreferences");
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
    } catch (error) {
      data = null;
    } finally {
      return data;
    }
  }

  public static set preference(object) {
    try {
      const pref = AppConfig.preference;
      const result = JSON.parse({ ...pref, ...object });
      localStorage.setItem("userPreferences", result);
    } catch (error) {
      console.log(error);
    }
  }
}
