import { Grid } from "@mui/material";
import { addItem } from "./Helpers";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";

export const BusinessDetailsAsset = ({ business, masterData, isMainContactDetails }) => {
  const assets = business?.assets || [];
  const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);

  const BuildAssetDetails = ({ asset }) => {
    const assetsType = typedData("ASSET_TYPE", asset?.assetType);

    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(asset, "assetType", assetsType?.name ?? "N/A")}
          {addItem(asset, "assetValue", asset?.assetValue ?? "N/A")}
          {/* {addItem(asset, "entryId")} */}
          {/* {addItem(asset, "itemIndex")} */}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="full-width margin-xs-b">
      <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
        Business Asset Details
      </Grid>
      {assets.length > 0 ? (
        <>
          {assets
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((asset, index) => {
              return <BuildAssetDetails key={index} asset={asset} />;
            })}
        </>
      ) : (
        <ErrorMessageComponent headMessage={""} errorMessage={"No Data Available"} />
      )}
    </div>
  );
};
