import { Chip, Tooltip } from "@mui/material";
import AppConfig from "../../../../../utility/AppConfig";

export const CollateralDetails = ({ collateral = "", label = "" }) => {
  if (!collateral) {
    return null;
  }

  const collateralDataObj = AppConfig.config.collateral || {};
  const data: any = JSON.parse(collateral);
  let collateralKeys: any[] = Object.keys(data) || [];
  const collateralNames: any[] = [];

  if (collateralKeys.length) {
    collateralKeys.forEach((key) => {
      if (collateralDataObj[key]?.childs != null && collateralDataObj[key].childs.length > 0) {
        collateralDataObj[key].childs.forEach((obj) => {
          if (data[key].includes(obj?.id)) {
            if (!collateralNames.includes(obj?.name)) {
              collateralNames.push(obj?.name);
            }
          }
        });
      } else {
        collateralNames.push(collateralDataObj[key]?.name);
      }
    });
  }

  const getCollateralItems = (collateralDataArr: any[] = []) => {
    let formattedCollateral: any[] = [];

    if (collateralDataArr.length < 1) {
      return null;
    }

    collateralDataArr.forEach((item) => {
      formattedCollateral.push(item);
    });

    return <div>{formattedCollateral.map((element) => element).join(", ")}</div>;
  };

  return (
    <>
      <Tooltip title={<div>{getCollateralItems(collateralNames || [])}</div>} className="align-center">
        <Chip label={label !== "" ? label : "Yes"} style={{ background: "#70cddb" }}></Chip>
      </Tooltip>
    </>
  );
};
