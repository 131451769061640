import { Downgraded, useHookstate } from "@hookstate/core";
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Paper, Stack, Switch, Typography, alpha, styled } from "@mui/material";
import React from "react";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import { userDetails } from "../../../../configs/mainStore";
import { isPrivilegeHave } from "../../../../services/utilService";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SwitchComponent } from "../../../InputComponents/SwitchComponent/SwitchComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

const CreateWorkflowFormLayout = ({ selectData: data, onSave }) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();
  const [errors, setErrors]: any = React.useState({ name: null, desc: null });
  const selectData: any = data.get ? data : useHookstate(data);
  const isUpdate = !!selectData?.get()?.id;
  const workflowName = data?.descriptions?.EN?.workflowName || "";
  const GreenSwitch = styled(Switch)(({ theme }: any) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#00AB71",
      "&:hover": {
        backgroundColor: alpha("#00AB71", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00AB71",
    },
  }));

  const valid = () => {
    const { workflowName, workflowDescription } = selectData?.descriptions?.get()?.["EN"] || {};

    if (workflowName && workflowDescription) {
      setErrors({ name: null, desc: null });
      return true;
    }

    let name = !workflowName ? "Name can not be empty." : "";
    let desc = !workflowDescription ? "Description can not be empty." : "";

    setErrors({ name, desc });
    return false;
  }

  return <Paper elevation={0}>
    <Stack p={1}>
      <Stack direction="column">
        <Stack direction={"row"} mb={1} justifyContent={"space-between"}>
          <Typography color={"gray"} variant="body1">
            {isUpdate ? `Update Workflow` : "Create Workflow"}
          </Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              closeGlobalModal();
            }}
          />
        </Stack>

        <Typography color={"gray"} variant="caption">
          {isUpdate && workflowName ? `(${workflowName})` : ''}
        </Typography>
      </Stack>
      <Divider></Divider>
      <Stack mt={2}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} my={2} mb={4} border={1} borderRadius={2} p={1} borderColor={'lightgray'}>
          <Stack direction="row" alignItems="center">
            <SwitchComponent
              commonStatus={true}
              checked={selectData.isActive.get() ? true : false}
              onChange={(e) => {
                selectData.isActive.set(!selectData.isActive.get());
              }}
            />
            <Typography color={"gray"} variant="subtitle2">
              {selectData.isActive.get() ? "Published" : "Unpublished"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <GreenSwitch
              checked={selectData.workflowType.get() ? true : false}
              onChange={(e) => {
                selectData.workflowType.set(!selectData.workflowType.get());
              }}
            />
            <Typography color={"gray"} variant="subtitle2">
              {selectData.workflowType.get() ? "Main Workflow" : "Sub Workflow"}
            </Typography>
          </Stack>
        </Stack>

        <Stack mb={2} spacing={2} direction={{ xs: "column" }}>
          <Stack>
            <Typography variant="subtitle2" color={"gray"}> Name </Typography>
            <TextBoxComponent error={!!errors?.name} value={selectData?.descriptions?.get()?.["EN"]?.workflowName || ""} onChange={(e: any) => {
              selectData.descriptions["EN"]?.workflowName?.set(e.target.value);
              setErrors({ ...errors, name: "" });
            }} multiline={true} />
            {errors?.name && <Typography variant="caption" color={"red"}> {errors.name} </Typography>}
          </Stack>
          <Stack>
            <Typography variant="subtitle2" color={"gray"}> Description </Typography>
            <TextBoxComponent error={!!errors?.desc} value={selectData?.descriptions?.get()?.["EN"]?.workflowDescription || ""} onChange={(e: any) => {
              selectData.descriptions["EN"]?.workflowDescription?.set(e.target.value);
              setErrors({ ...errors, desc: "" });
            }} multiline={true} sx={{ fontSize: 16 }} />
            {errors?.desc && <Typography variant="caption" color={"red"}> {errors.desc} </Typography>}
          </Stack>
        </Stack>
        <Divider></Divider>
        <Stack direction={"row-reverse"} mt={2}>
          <ButtonComponent title={isUpdate ? "Update" : "Create"} variant="contained" onClick={(e) => {
            if (!valid()) {
              return;
            }

            onSave(e, isUpdate);
          }} disabled={!isPrivilegeHave(userPrivilege, privilegeKeys.CERATE_NEW_WF_BUTTON)} />
        </Stack>
      </Stack>
    </Stack>
  </Paper>;
};

export default CreateWorkflowFormLayout;
