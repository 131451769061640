import ApplicationHeader from "../OtherComponents/CreditFile/Components/Application/ApplicationHeader";
import { ErrorMessageComponent } from "../OtherComponents/ErrorMessageComponent";

const MandatoryFilterComponent = ({ children, items = [], mandatory = [], options, errorMessage = '' }: { children: any; items?: any[]; mandatory?: any[]; options: any, errorMessage?: string }) => {
  let data: any = items instanceof Array ? items : [items];
  let validAssets: any = [];

  for (const item of data) {
    if (!item) {
      continue;
    }

    let validAsset = true;
    let keys = Object.keys(item);

    for (const mandatoryItem of mandatory as string[]) {
      if (!keys.includes(mandatoryItem)) {
        validAsset = false;
        break;
      }
    }

    for (const key of keys) {
      if (["entryId", "itemIndex", "sectionErrorStatus"].includes(key)) {
        continue;
      }

      const isMandatory = (mandatory as any).includes(key);

      if (isMandatory && item.hasOwnProperty(key) && item[key] !== 0 && !item[key]) {
        validAsset = false;
        break;
      } else if (isMandatory && !!item[key]) {
        validAsset = true;
      }
    }

    if (validAsset === true) {
      validAssets.push(item);
    }
  }

  data = validAssets;

  if (data.length === 0) {
    return (
      <div className={` mainSectionWrapper ${options?.isMainContactDetails ? "full-width full-height" : "full-width"}`}>
        {options?.isMainContactDetails && (!options?.isRawVersion || options?.printMode) && <ApplicationHeader formData={options?.formData} requestId={options?.requestId} applicationId={options?.applicationId} title={options?.title} isAppraisal={options?.mainTabData?.isAppraisal} isRawVersion={options?.isRawVersion} disableAccordingToTab={options?.disableAccordingToTab} />}
        <ErrorMessageComponent headMessage={""} errorMessage={errorMessage ? errorMessage : 'No Data Available!'} showImg={false} />
      </div>
    );
  }

  return <>{children}</>;
};

export default MandatoryFilterComponent;
