import { useTranslation } from "react-i18next";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import FormBuilder from "../../FormBuilder";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { useState } from "@hookstate/core";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import React, { useEffect } from "react";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { printFormTemplate } from "../../../../../../../utility/util";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { addToaster } from "../../../../../GlobalToast";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";

const OtherQuestions = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  let toBeSubmittedFormDetails: any = {};
  const [formDetails, setFormDetails]: any = React.useState(toBeSubmittedFormDetails);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const { formData } = data;
  const inspetionId = useState<any>(inspetionIdState);
  const isLoading = useState<boolean>(false);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
  };

  useEffect(() => {
    getExistingInspectionForm();
  }, []);

  const getExistingInspectionForm = async () => {
    isLoading.set(true);
    let responseData = {};

    const { response: data, status }: any = await getFilledPendingInpectionFormData(applicationId);

    if (Object.values(data).length === 0) {
      responseData = {};
    } else {
      inspetionId.set(data["ID"] ?? null);

      if (data["INSPECTION_DATA"] && Object.values(data["INSPECTION_DATA"]).length > 0) {
        const res = JSON.parse(data["INSPECTION_DATA"]);
        if (res["otherQuestions"] && Object.values(res["otherQuestions"]).length > 0) {
          responseData = res["otherQuestions"];
        } else {
          responseData = {};
        }
      } else {
        responseData = {};
      }
    }

    setFormDetails(responseData);
    inspectionFormState.otherQuestions.set(responseData);
    isLoading.set(false);
  };

  const onChange = (metaData, value) => setFormDetails(value);

  const onSubmit = async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(ApplicationFormKey.otherQuestions, theDataTobeSubmitted, SectionKey.otherQuestions);

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = inspectionMessageMappings(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${preparedErrorMessagges}`,
      });
    }

    const respose: any = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.otherQuestions, inspectionFormState, applicationId);

    if (respose.status == 1) {
      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  const downloadInspectionFormTemplate = () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.otherQuestions);
    } catch (error: any) {
      GlobalLogger.log("Other Questions Inspection", error.message);
    }
  };

  return (
    <ErrorBoundary>
      <FormBuilder applicationDataObject={formDetails} onChange={onChange} formId={ApplicationFormKey.otherQuestions} title={translate("otherQuestion")} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
    </ErrorBoundary>
  );
};

export default OtherQuestions;
