import { createState } from "@hookstate/core";

const masterData = {
  wf_actions: [],
  wf_actions_all: [],
  wf_jobs: [],
  wf_stepTypes: [],
  wf_stages: [],
  wf_list: [],
  // wf_not_mapped_list : [],
  wf_hierarchies: [],
  wf_groups: [],
  wf_users: [],
  wf_system_users: [],
  wf_system_users_map: {},
  wf_email_templates: [],
  wf_sms_templates: [],
  wf_ui_components: [],
  wf_api_jobs: [],
  wf_data_status: false,
  wf_api_jobs_meta: {},
  wf_conditions: [],
  wf_stageParentMap: [],
  wf_parent_stages: [],
  wf_departments: [],
  wf_parentWorkflowStageMap: [],
  stageDepartmentMap: [],
  wf_stageAssignList: [],
};

const updatedData = {
  wf_tracked_stages: [],
  wf_tracked_actions: {},
  wf_track_structural_change: false,
};

const mappedData = {
  wf_mapping_list: [],
};

const filteredDataList = {
  filtered_data: [],
};

const workflowActionModalData = {
  workflowActionData: {},
  isChanged: false,
};

export const workflowMasterData = createState(masterData);
export const workflowUpdatedData = createState(updatedData);
export const workflowMappedData = createState(mappedData);
export const workflowFilteredData = createState(filteredDataList);

export const workflowActionModal = createState(workflowActionModalData);
