import { Downgraded, useState } from "@hookstate/core";
import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { microChequeReturnsDetails, creditData, userDetails, workflowMasterData, microLoanDetailsCribDetails, microCurrentAccountDetails, microBankGuaranteeDetails } from "../../../../../../../../configs/mainStore";
import { getAnalysisLoanRequirementCommentsData, getAnalysisOfLoanRequirementDetails, getAppraisalData, getBankGuaranteeDetails, getChequeReturnsDetails, getCurrentAccountDetails, getLoanCribDetails, saveAppraisalData, saveBankGuaranteeDetails, saveChequeReturnsDetails, saveCurrentAccountDetails, saveLoanCribDetails, saveLoanRequirementCommentData } from "../../../../../../../../services/creditFileApiCall";
import { standardDateFormatter } from "los-util";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { addToaster } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import ApplicationHeader from "../../../../Application/ApplicationHeader";
import DetailItem, { DetailInlinedItem } from "../../../../DetailItem";
// import { AddLoanRequirementForm } from "./AddLoanRequirementForm";
import { getIsDisbursed } from "../../../../../../../../utility/helpers/getIsDisbursed";
import { privilegeKeys } from "../../../../../../../../configs/constants/privilegeKeys";
import { disableAccess } from "../../../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
// import AnalysisLoanRequirement from "./Micro/Components/AnalysisOfLoanRequirement/AnalysisLoanRequirement";
import { closeGlobalModal, openGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { select_basic } from "../../../../../../../../styles";
import { loanRequirementContributions } from "../../../../../../../../configs/constants/contants";
import BankSelect from "../../Helpers/BankSelect/BankSelect";
import ChequeReturnDetails from "./subComponents/ChequeReturnDetails";
import LoanDetailsCrib from "./subComponents/LoanDetailsCrib";
import CurrentAccountDetails from "./subComponents/CurrentAccountDetails";
import BankGuaranteeDetails from "./subComponents/BankGuaranteeDetails";
import { getBankListWithoutRDB } from "../../../../../../../../utility/helpers/getBankList";
import DoneIcon from "@mui/icons-material/Done";
import { isPermitted } from "../../../../../../../../utility/helpers/getIshavePermission";
// const AddLoanRequirementForm = React.lazy(() => import("./AddLoanRequirementForm.tsx"))

const DirectOtherBankLiabilityAnalysis = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion, innerComponentData, tabsToEdit }) => {
  let isCommercial = true;
  const [loanRequirementList, setLoanRequirementList] = React.useState<any>([]);
  const [isChanged, setIsChanged] = React.useState(false);
  const isLoading = useState(false);
  const isSaving = useState(false);
  const { wf_system_users, wf_actions }: any = useState(workflowMasterData);
  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get() ?? null;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  //New Commercial Variables
  const chequeReturnDetails = useState(microChequeReturnsDetails);
  const loanDetailsCribDetails = useState(microLoanDetailsCribDetails);
  const currentAccountDetails = useState(microCurrentAccountDetails);
  const bankGuaranteeDetails = useState(microBankGuaranteeDetails);

  const [chequeReturnBank, setChequeReturnBank]: any = React.useState("");
  const [loanCribBank, setLoanCribBank]: any = React.useState("");
  const [accountDetailsBank, setAccountDetailsBank]: any = React.useState("");
  const [guaranteeDetailsBank, setGuaranteeDetailsBank]: any = React.useState("");
  const [isChequeReturnsLoading, setIsChequeReturnsLoading] = React.useState(false);
  const [isLoanDetailsCribLoading, setIsLoanDetailsCribLoading] = React.useState(false);
  const [isCurrentAccountsLoading, setIsCurrentAccountsLoading] = React.useState(false);
  const [isBankGuaranteeLoading, setIsBankGuaranteeLoading] = React.useState(false);

  const bankChangeHandler = (value, type) => {
    if (type == "chequeReturn") {
      setChequeReturnBank(value);
    } else if (type == "loanCribBank") {
      setLoanCribBank(value);
    } else if (type == "accountDetailsBank") {
      setAccountDetailsBank(value);
    } else if (type == "guaranteeDetailsBank") {
      setGuaranteeDetailsBank(value);
    }
  };

  const getSelectedBank = (bankId) => {
    const bankList: any = getBankListWithoutRDB();
    const objectKeys = Object.keys(bankList || {});
    let bank = {};
    objectKeys.forEach((key) => {
      try {
        if (bankList[key] != undefined && bankList[key] != null && bankList[key]?.id == bankId) {
          bank = bankList[key];
        }
      } catch (error) {
        bank = {};
      }
    });

    return bank;
  };

  const addNewRequirementHandler = (type: any = "") => {
    if (type == "chequeReturn") {
      if (!chequeReturnBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(chequeReturnBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          bank: selectedBank.name ?? "",
          reason: "",
          numberOfCheques: 0,
          amount: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        chequeReturnDetails.set(existingRowItems);
      }
    } else if (type == "loanCribBank") {
      if (!loanCribBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(loanCribBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          business: "",
          nameoOfInstitution: selectedBank.name ?? "",
          type: 1,
          grantedAmount: 0,
          securities: "",
          reasonOfDelay: "",
          installment: 0,
          outstanding: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        loanDetailsCribDetails.set(existingRowItems);
      }
    } else if (type == "accountDetailsBank") {
      if (!accountDetailsBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(accountDetailsBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          bank: selectedBank.name ?? "",
          accountNumber: "",
          debit: 0,
          credit: 0,
          balanceOfMonthEnd: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        currentAccountDetails.set(existingRowItems);
      }
    } else if (type == "guaranteeDetailsBank") {
      if (!guaranteeDetailsBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(guaranteeDetailsBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          financialInstitute: selectedBank.name ?? "",
          amount: 0,
          issuedDate: "",
          timePeriodMonths: 0,
          issuedCompany: "",
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        bankGuaranteeDetails.set(existingRowItems);
      }
    }
  };

  const getChequeReturnsData = async () => {
    setIsChequeReturnsLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getChequeReturnsDetails(params);
      chequeReturnDetails?.set(response ?? {});
      setIsChequeReturnsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveChequeReturnsData = async (actionType = "") => {
    setIsChequeReturnsLoading(true);
    let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        chequeReturnsData: {},
      };

      if (actionType == "submit") {
        if (dataKeys.length < 1) {
          addToaster({
            status: "error",
            title: "Empty Cheque Returns!",
            message: `Please add cheque returns before saving!`,
          });
          setIsChequeReturnsLoading(false);
          return;
        } else {
          dataKeys.map((key) => {
            obj.chequeReturnsData = existingRowItems;
          });
        }
      } else {
        dataKeys.map((key) => {
          obj.chequeReturnsData = existingRowItems;
        });
      }

      let response = await saveChequeReturnsDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.chequeReturns);

        chequeReturnDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Cheque Returns Data Saved successfully !",
        });
      }
      setIsChequeReturnsLoading(false);
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    }
  };

  const getLoanDetailsCribData = async () => {
    setIsLoanDetailsCribLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getLoanCribDetails(params);
      loanDetailsCribDetails?.set(response ?? {});
      setIsLoanDetailsCribLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveLoanDetailsCribData = async (actionType = "") => {
    setIsLoanDetailsCribLoading(true);
    let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        cribDetailsData: {},
      };

      if (actionType == "submit") {
        if (dataKeys.length < 1) {
          addToaster({
            status: "error",
            title: "Empty Loan CRIB Details!",
            message: `Please add crib details before saving!`,
          });
          setIsLoanDetailsCribLoading(false);
          return;
        } else {
          dataKeys.map((key) => {
            obj.cribDetailsData = existingRowItems;
          });
        }
      } else if (actionType == "delete") {
        dataKeys.map((key) => {
          obj.cribDetailsData = existingRowItems;
        });
      }

      let response = await saveLoanCribDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.cribDetails);

        loanDetailsCribDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Loan Details related to Business / Project from CRIB Data Saved successfully !",
        });
      }
      setIsLoanDetailsCribLoading(false);
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    }
  };

  const getCurrentAccountsData = async () => {
    setIsCurrentAccountsLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getCurrentAccountDetails(params);
      currentAccountDetails?.set(response ?? {});
      setIsCurrentAccountsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveCurrentAccountsData = async (actionType = "") => {
    setIsCurrentAccountsLoading(true);
    let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        currentAccountsData: {},
      };

      if (actionType == "submit") {
        if (dataKeys.length < 1) {
          addToaster({
            status: "error",
            title: "Empty Current Account Details!",
            message: `Please add current account details before saving!`,
          });
          setIsCurrentAccountsLoading(false);
          return;
        } else {
          dataKeys.map((key) => {
            obj.currentAccountsData = existingRowItems;
          });
        }
      } else {
        dataKeys.map((key) => {
          obj.currentAccountsData = existingRowItems;
        });
      }

      let response = await saveCurrentAccountDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.currentAccounts);

        currentAccountDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Current Account Details Data Saved successfully !",
        });
      }
      setIsCurrentAccountsLoading(false);
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    }
  };

  const getBankGuaranteeData = async () => {
    setIsBankGuaranteeLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getBankGuaranteeDetails(params);
      bankGuaranteeDetails?.set(response ?? {});
      setIsBankGuaranteeLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveBankGuaranteeData = async (actionType = "") => {
    setIsBankGuaranteeLoading(true);
    let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        bankGuaranteesData: {},
      };

      if (actionType == "submit") {
        if (dataKeys.length < 1) {
          addToaster({
            status: "error",
            title: "Empty Bank Guarantee Details!",
            message: `Please add bank guarantee details before saving!`,
          });
          setIsBankGuaranteeLoading(false);
          return;
        } else {
          dataKeys.map((key) => {
            obj.bankGuaranteesData = existingRowItems;
          });
        }
      } else {
        dataKeys.map((key) => {
          obj.bankGuaranteesData = existingRowItems;
        });
      }

      let response = await saveBankGuaranteeDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.bankGuarantees);

        bankGuaranteeDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Bank Guarantee Details Data Saved successfully !",
        });
      }
      setIsBankGuaranteeLoading(false);
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    }
  };

  const onDeleteRequirementItemRow = useCallback(
    (key: any, type = "") => {
      closeGlobalModal();
      if (type == "chequeReturn") {
        let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        chequeReturnDetails.set(newRowItems);
        saveChequeReturnsData("delete");
      } else if (type == "loanCribBank") {
        let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        loanDetailsCribDetails.set(newRowItems);
        saveLoanDetailsCribData("delete");
      } else if (type == "accountDetailsBank") {
        let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        currentAccountDetails.set(newRowItems);
        saveCurrentAccountsData("delete");
      } else if (type == "guaranteeDetailsBank") {
        let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        bankGuaranteeDetails.set(newRowItems);
        saveBankGuaranteeData("delete");
      }
    },
    [chequeReturnDetails, loanDetailsCribDetails, currentAccountDetails, bankGuaranteeDetails]
  );

  const changeRequirementData = async (items, userId) => {
    let tempObj = { ...items, userId, created: new Date() };
    await onSaveData([tempObj]);
    // setIsChanged(!isChanged);
  };

  const onSaveData = async (tempReqList) => {
    isSaving.set(true);
    const res: any = await saveAppraisalData({
      section: "analysis_loan_requirement",
      applicationId: applicationId,
      data: tempReqList,
    });

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }
    isSaving.set(false);
  };

  useEffect(() => {}, [isChanged, loanRequirementList?.length]);

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const params = {
        applicationId: applicationId,
        section: "analysis_loan_requirement",
      };
      const data: any = await getAppraisalData(params);

      if (data && Object.keys(data).length > 0) {
        setLoanRequirementList(data);
      }
      isLoading.set(false);
    })();

    getChequeReturnsData();
    getLoanDetailsCribData();
    getCurrentAccountsData();
    getBankGuaranteeData();
  }, []);

  const checkPrivilegeStatus = disableAccess({
    isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
  });

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Direct Other Bank Liability Analysis"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={true} />}

      {/* Check Returns */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Cheque Returns
        </Grid>
        {isChequeReturnsLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={chequeReturnBank} type={"chequeReturn"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn children={<AddCircleOutlineIcon />} onClick={() => addNewRequirementHandler("chequeReturn")} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <ChequeReturnDetails creditData={data} applicationId={applicationId} chequeReturnData={chequeReturnDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveChequeReturnsData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>

      {/* Loan Details related to Business / Project from CRIB */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Loan Details related to Business / Project from CRIB
        </Grid>
        {isLoanDetailsCribLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={loanCribBank} type={"loanCribBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn children={<AddCircleOutlineIcon />} onClick={() => addNewRequirementHandler("loanCribBank")} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <LoanDetailsCrib creditData={data} applicationId={applicationId} loanDetailsCribData={loanDetailsCribDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveLoanDetailsCribData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>
      {/* Current Account Details */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Current Account Details
        </Grid>
        {isCurrentAccountsLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={accountDetailsBank} type={"accountDetailsBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn children={<AddCircleOutlineIcon />} onClick={() => addNewRequirementHandler("accountDetailsBank")} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <CurrentAccountDetails creditData={data} applicationId={applicationId} currentAccountData={currentAccountDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent startIcon={<DoneIcon />} disabled={isPermitted()} title="SUBMIT" variant="contained" onClick={() => saveCurrentAccountsData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>

      {/* Bank Guarantee Details */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Bank Guarantee Details
        </Grid>
        {isBankGuaranteeLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={guaranteeDetailsBank} type={"guaranteeDetailsBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn children={<AddCircleOutlineIcon />} onClick={() => addNewRequirementHandler("guaranteeDetailsBank")} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <BankGuaranteeDetails creditData={data} applicationId={applicationId} bankGuaranteeData={bankGuaranteeDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveBankGuaranteeData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default DirectOtherBankLiabilityAnalysis;
