import React, { Fragment } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { standardDateFormatter } from "los-util";
import { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Downgraded, useState } from "@hookstate/core";
import { creditData } from "../../../../../../../../configs/mainStore";
import { getIsDisbursed } from "../../../../../../../../utility/helpers/getIsDisbursed";
import { DatePickerComponent } from "../../../../../../../InputComponents/DatePickerComponent/DatePickerComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";

export const Shares = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore = MasterDataProvider.provideMasterData();

  const { shareValueOfSecurity, shareSecuredValue, shareDescription, shareCertificateNo, shareIssuingDate, shareNumberOfShares, shareNominalValueOfShare, shareMarketValueOfShare } = props?.fields;

  return (
    <div className="full-width full-height">
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    Tresury BondShares {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={shareValueOfSecurity?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.shareValueOfSecurity?.get())} onChange={(e) => props?.doc?.shareValueOfSecurity?.set(e.target.value)} label={translate("Value of the Security")} onInput={OnDemandValueFormatter.numeric} required={shareValueOfSecurity?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={shareSecuredValue?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.shareSecuredValue?.get())} onChange={(e) => props?.doc?.shareSecuredValue?.set(e.target.value)} label={translate("Secured Value")} onInput={OnDemandValueFormatter.numeric} required={shareSecuredValue?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={shareDescription?.visible}>
                  <TextBoxComponent value={props?.doc?.shareDescription?.get()} onChange={(e) => props?.doc?.shareDescription?.set(e.target.value)} label={translate("Description")} onInput={OnDemandValueFormatter.alphaNumeric} required={shareDescription?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={shareCertificateNo?.visible}>
                  <TextBoxComponent value={props?.doc?.shareCertificateNo?.get()} onChange={(e) => props?.doc?.shareCertificateNo?.set(e.target.value)} label={translate("Certificate Number")} onInput={OnDemandValueFormatter.alphaNumeric} required={shareCertificateNo?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={shareIssuingDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.shareIssuingDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.shareIssuingDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Issuing Date (DD/MM/YYYY)")}
                    enableFuture={false}
                    disablePast={false}
                    required={shareIssuingDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={shareNumberOfShares?.visible}>
                  <TextBoxComponent value={props?.doc?.shareNumberOfShares?.get()} onChange={(e) => props?.doc?.shareNumberOfShares?.set(e.target.value)} label={translate("Number of shares ")} onInput={OnDemandValueFormatter.numeric} required={shareNumberOfShares?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={shareNominalValueOfShare?.visible}>
                  <TextBoxComponent value={props?.doc?.shareNominalValueOfShare?.get()} onChange={(e) => props?.doc?.shareNominalValueOfShare?.set(e.target.value)} label={translate("Nominal Value Of a Share")} onInput={OnDemandValueFormatter.numeric} required={shareNominalValueOfShare?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={shareMarketValueOfShare?.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.shareMarketValueOfShare?.get())}
                    //value={item?.shareMarketValueOfShare}
                    onChange={(e) => props?.doc?.shareMarketValueOfShare?.set(e.target.value)}
                    label={translate("Market Value of a Share")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={shareMarketValueOfShare?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
