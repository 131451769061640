import { useState } from '@hookstate/core';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PersonIcon from '@mui/icons-material/Person';
import PreviewIcon from '@mui/icons-material/Preview';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Grid, Stack } from '@mui/material';
import { workflowMasterData } from '../../../../../configs/mainStore';
import { dateFormatWithTimeChange } from '../../../../../utility/fomatters';
import { defaultOptions } from '../../../../../utility/util';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { IconRowComponent } from '../IconRowComponent';

const InsurancePolicyDocumentItemRow = ({ data, previewBtnClicked }) => {
  const { wf_system_users }: any = useState(workflowMasterData);

  const isExpand = useState(false);
  const color = defaultOptions?.color;
  const createdDate = data.createdDate ? dateFormatWithTimeChange(data.createdDate) : '';
  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == data?.createdBy);

  return (
    <Grid
      container
      alignItems="center"
      className="row_list_item"
      p={2}
      key={data.sequence}
      style={{
        backgroundColor: `${color}3d`,
      }}
    >
      <Grid container className="full-width" alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <IconRowComponent Icon={RadioButtonCheckedIcon} subTxt="Title" mainTxt={data?.title} color={color} />
        </Grid>
        <Grid item xs={3}>
          <IconRowComponent Icon={PersonIcon} subTxt="Created By" mainTxt={createdBy?.fullName} color={color} />
        </Grid>
        <Grid item xs={4}>
          <IconRowComponent Icon={InsertInvitationIcon} subTxt="Created Date" mainTxt={createdDate} color={color} />
        </Grid>
        <Grid item xs={1} className="align-center">
          <ButtonComponent
            iconbtn
            children={<PreviewIcon className="basic-font-color" />}
            onClick={() => previewBtnClicked(data.filePath)}
          />
        </Grid>
        <Grid item xs={1} className="align-center">
          <ButtonComponent
            iconbtn
            children={
              isExpand.get() ? (
                <KeyboardDoubleArrowUpIcon className="basic-font-color" />
              ) : (
                <KeyboardDoubleArrowDownIcon className="basic-font-color" />
              )
            }
            onClick={() => isExpand.set(!isExpand.get())}
          />
        </Grid>
      </Grid>

      {isExpand.get() && (
        <Grid container className="full-width row_list_item" mt={1}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Risk Description</div>
              <div className={`basic-font basic-font-color font-size-14`}>{data?.description} </div>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InsurancePolicyDocumentItemRow;
