import { messageMappings } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/messageMapping";
import { addElementsToArray, deleteElementsFromArray } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/securityDetails";

export const collateralValidationErrorArray = (mainKey: string, subKey: string, state: any) => {
  let errorArray: Array<any> = [];

  if (subKey === "lifeInsurance") {
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }

      if (obj["actionWhetherInsurancePolicySampledThirdParty"] === true || obj["actionWhetherInsurancePolicySampledThirdParty"] === false) {
        let arry = [];
        if (obj["actionWhetherInsurancePolicySampledThirdParty"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
    });
  }

  if (subKey === "fdAgainstLoans") {
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }

      if (obj["actionAutoInstallmentRecoveryRequired"] === true || obj["actionAutoInstallmentRecoveryRequired"] === false) {
        let arry = ["fdLoanAutoRecoveryAccountNumber", "fdAccountName"];
        if (obj["actionAutoInstallmentRecoveryRequired"] === true) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionIsCash"] === true || obj["actionIsCash"] === false) {
        let arry = ["fdAmountByCash"];
        if (obj["actionIsCash"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionIsBankTransfer"] === true || obj["actionIsBankTransfer"] === false) {
        let arry = ["fdamountByTransfer", "fdAccountNumberSavings"];
        if (obj["actionIsBankTransfer"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
    });
  }

  if (subKey === "pledge") {
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }

      if (obj["actionOwnershipOfWarehouses"] === true || obj["actionOwnershipOfWarehouses"] === false) {
        let arry = ["pledgeCustomerType", "pledgeRegistrationNumber"];
        if (obj["actionOwnershipOfWarehouses"] === false) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionInsuranceAvailable"] === true || obj["actionInsuranceAvailable"] === false) {
        let arry = ["insuranceCompanyName", "insurancePolicyNumber", "insuredAmount", "policyExpiryDate"];
        if (obj["actionInsuranceAvailable"] === true) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionWarehouseDetailsRequired"] === true || obj["actionWarehouseDetailsRequired"] === false) {
        let arry = ["pledgeWarehouseAddress", "pledgeCity", "pledgeDistrict", "pledgeConditionOfTheWarehouse", "pledgeApproximateDistanceFromTheBranch"];
        if (obj["actionWarehouseDetailsRequired"] === true) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionStocksUnderBankLock"] === true || obj["actionStocksUnderBankLock"] === false) {
        let arry = ["pledgeKeyNumber"];
        if (obj["actionStocksUnderBankLock"] === true) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
    });
  }

  if (subKey === "immovableMachineryEquipement" || subKey === "machineryEquipment" || subKey === "vehicle") {
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }
      if (obj["actionInsuranceAvailable"] === true || obj["actionInsuranceAvailable"] === false) {
        let arry = ["insuranceCompanyName", "insurancePolicyNumber", "insuredAmount", "policyExpiryDate"];
        if (obj["actionInsuranceAvailable"] === true) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionWarrantyAvailable"] === true || obj["actionWarrantyAvailable"] === false) {
        let arry = ["natureWarranty", "dealersName", "warrantyStartDate", "warrantyEndDate"];
        if (obj["actionWarrantyAvailable"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
      if (obj["actionDuplicateKeyReceived"] === true || obj["actionDuplicateKeyReceived"] === false) {
        let arry = subKey === "vehicle" ? ["duplicateKeyNumber"] : ["duplicateKeyNumber"];
        if (obj["actionDuplicateKeyReceived"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
    });
  }

  if (subKey === "property") {
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }

      if (obj["actionInsuranceAvailable"] === true || obj["actionInsuranceAvailable"] === false) {
        let arry = ["insuranceCompanyName", "insurancePolicyNumber", "policyExpiryDate"];
        if (obj["actionInsuranceAvailable"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionTitleInsurance"] === true || obj["actionTitleInsurance"] === false) {
        let arry = ["titleInsuranceCompanyName", "titleInsurancePolicyNumber", "titleInsuredAmount"];
        if (obj["actionTitleInsurance"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }

      if (obj["actionLegalOpinionObtained"] === true || obj["actionLegalOpinionObtained"] === false) {
        let arry = ["propObtainedDate"];
        if (obj["actionLegalOpinionObtained"]) {
          errorArray = addElementsToArray(errorArray, obj, arry);
        } else {
          errorArray = deleteElementsFromArray(errorArray, arry);
        }
      }
    });
  }

  if (subKey === "warehouseReceipt") {
    let arry = ["whrNic", "whrNicOld"];
    state[mainKey][subKey].forEach((obj: any) => {
      if (obj["removedItem"]) {
        return;
      }
      let intMedArray = addElementsToArray(errorArray, obj, arry);
      if (intMedArray.length == 2) {
        errorArray.push("whrNic|whrNicOld");
        errorArray = errorArray.filter((key) => !arry.includes(key));
      } else {
        errorArray = errorArray.filter((key) => !arry.includes(key));
      }
    });
  }

  return messageMappings(errorArray);
};
