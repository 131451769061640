import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { innerSecurityNavigationConfig } from "../../../../configs/constants/creditFileConfigs";
import SecurityVerticalTabComponent from "../../TabComponent/SecurityVerticalTabComponent";

const SecurityDocuments = ({ applicationId, innerComponentData, tabsToEdit, data, currentApplicationWfData }) => {
  const [tabList, setTabList] = React.useState({});
  const [innerTabValue, setInnerTabValue] = React.useState("sec-promissiory-note");

  useEffect(() => {
    const innerSecurityNavigationArray = Object.values(innerSecurityNavigationConfig).map((value) => {
      return {
        id: value.id,
        title: value.title,
        content: value.componentId,
      };
    });

    let filteredInnerSecurityNavigationArray: any[] = [];

    if (data.formData.jointBorrowerData == null || data.formData.jointBorrowerData == undefined || data.formData.jointBorrowerData.length === 0) {
      filteredInnerSecurityNavigationArray = innerSecurityNavigationArray.filter((nav) => {
        return nav.id != "sec-letter-set-off-jb";
      });
    } else {
      filteredInnerSecurityNavigationArray = innerSecurityNavigationArray;
    }

    if (data.formData.securityData?.guarantors == null || data.formData.securityData?.guarantors == undefined || data.formData.securityData?.guarantors.length === 0) {
      filteredInnerSecurityNavigationArray = filteredInnerSecurityNavigationArray.filter((nav) => {
        return nav.id != "sec-guarantee-bond";
      });
    } else {
      filteredInnerSecurityNavigationArray = filteredInnerSecurityNavigationArray;
    }

    setTabList(filteredInnerSecurityNavigationArray);
  }, []);

  const handleInnerTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setInnerTabValue(newValue);
  };

  return (
    <Grid container style={{ backgroundColor: "#FFF" }}>
      {Object.keys(tabList).length > 0 && (
        <SecurityVerticalTabComponent
          tabOptions={tabList}
          onChange={handleInnerTabChange}
          value={innerTabValue}
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          sx={{ minWidth: "300px" }}
          showContent={true}
          // maxHeight={{ maxHeight: '50vh' }}
          labelStyle={{
            textTransform: "none",
            fontSize: "16px",
            fontFamily: "Poppins, Medium",
            borderRight: 1,
            borderColor: "divider",
            alignItems: "flex-start",
          }}
          applicationId={applicationId}
          disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
          currentApplicationWfData={currentApplicationWfData}
        // disabled={isLoggedInUserNotTheAssignee}
        />
      )}
    </Grid>
  );
};

export default SecurityDocuments;
