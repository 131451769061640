import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import {
  creditCardAnnualAmount,
  overDraftAnnualAmount,
  pawningAnnualAmount,
  summaryCalculationSettings,
} from "../../../../../../../../utility/util";
import { JBController } from "../../../../../../ApplicationForm/Sections/LoanSummary/JBController";

export class RepayLoanController {
  dataPayload: any;
  incomePayload: any;
  expensePayload: any;
  creditDetails: any;
  basicSalary: any;
  fixedAllowance: any;
  averageVariableAllowance: any;
  lastThreeAverageDeduction: any;
  interestRate: any;
  masterDataStore: any;
  lifeInsuranceData: any;
  liabilityData: any;
  proposedLoanInstallment: any;
  jbController: JBController;

  constructor(loanSummary, data) {
    this.dataPayload = loanSummary;
    try {
      this.creditDetails = data?.formData.creditData;
    } catch (error) {
      this.creditDetails = {};
    }

    try {
      let sum = 0;
      data?.formData.incomeData.personnelIncome.forEach((obj) => {
        sum += parseInt(obj.basicSalary);
      });
      this.basicSalary = sum;
    } catch (error) {
      this.basicSalary = 0;
    }

    try {
      let sum = 0;
      data?.formData.incomeData.personnelIncome.forEach((obj) => {
        sum += parseInt(obj.averageVariableAllowance || "0");
      });
      this.averageVariableAllowance = sum;
    } catch (error) {
      this.averageVariableAllowance = 0;
    }

    try {
      let sum = 0;
      data?.formData.incomeData.personnelIncome.forEach((obj) => {
        if (obj.fixedAllowance != null && obj.fixedAllowance != undefined && obj.fixedAllowance != "") {
          sum += parseInt(obj.fixedAllowance);
        }
      });
      this.fixedAllowance = sum;
    } catch (error) {
      this.fixedAllowance = 0;
    }

    try {
      let sum = 0;
      data?.formData.incomeData.personnelIncome.forEach((obj) => {
        sum += parseInt(obj.lastThreeAverageDeduction || "0");
      });
      this.lastThreeAverageDeduction = sum;
    } catch (error) {
      this.lastThreeAverageDeduction = 0;
    }

    try {
      this.interestRate = parseInt(data?.formData.creditData.interestRate);
    } catch (error) {
      this.interestRate = {};
    }

    // lastThreeAverageDeduction
    ["lifeInsuranceData", "liabilityData", "incomePayload", "expensePayload"].forEach((key) => {
      this[key] = (this.dataPayload && this.dataPayload[key]) || {};
    });

    this.jbController = new JBController(loanSummary);
  }

  // Ability Repay Loan Data Getter
  get abilityRepayLoanData(): any {
    const abilityToRepayTheLoan: any = {
      limitPercentageOfIncome: "",
      limitOnProposedIncome: "",
      currentDeductions: "",
      proposedLoanInstallments: "",
      proposedLoanInterest: "",
      DebtPaymentPercentage: "",
      gracePeriod: "",
    };

    abilityToRepayTheLoan.limitOnProposedIncome = this.calculateApplicantGrossIncome(
      this.basicSalary,
      this.averageVariableAllowance,
      this.fixedAllowance
    );
    abilityToRepayTheLoan.proposedLoanInstallments = "";
    abilityToRepayTheLoan.proposedLoanInterest = this.interestRate;
    abilityToRepayTheLoan.currentDeductions = this.lastThreeAverageDeduction;
    abilityToRepayTheLoan.limitPercentageOfIncome =
      this.calculateApplicantGrossIncome(this.basicSalary, this.averageVariableAllowance, this.fixedAllowance) *
      this.dataPayload?.salaryProportionFactor;
    abilityToRepayTheLoan.DebtPaymentPercentage = "";
    return abilityToRepayTheLoan;
  }

  // Debt Repayment as  % of Exisitng Income
  calculateDebtRepaymentValue = (trailCalValue) => {
    this.proposedLoanInstallment = trailCalValue;
    let limitPercentageOfIncome =
      this.calculateApplicantGrossIncome(this.basicSalary, this.averageVariableAllowance, this.fixedAllowance) *
      this.dataPayload?.salaryProportionFactor;
    try {
      let debtValue = this.calculateDebtRepaymentPercentageOfIncome(
        this.lastThreeAverageDeduction,
        this.proposedLoanInstallment,
        limitPercentageOfIncome
      );
      return debtValue;
    } catch (error) {
      return "";
    }
  };

  // Calculate Gross Income
  calculateApplicantGrossIncome = (basicSalary, averageVariableAllowance, fixedAllowance) => {
    return basicSalary + averageVariableAllowance + fixedAllowance;
  };

  // Calculate Debt Repayment Percentage of Income
  calculateDebtRepaymentPercentageOfIncome = (
    lastThreeAverageDeduction,
    proposedLoanInstallment,
    limitPercentageOfIncome
  ) => {
    if (limitPercentageOfIncome > 0) {
      return (
        ((parseInt(lastThreeAverageDeduction) + parseInt(proposedLoanInstallment)) / limitPercentageOfIncome) *
        100
      ).toFixed(2);
    } else {
      return "N/A";
    }
  };

  // Income And Expenses Details - INCOME
  get salaryProportionValidIdsArr(): any[] {
    const masterDataStoreInstance = MasterDataProvider.provideMasterData();
    const incomeHolders = masterDataStoreInstance?.getMasterData(MD_KEYS?.INCOME_HOLDER);
    const salaryProportionValidIds: any = [];

    incomeHolders?.get().map((obj) => {
      if (obj.name == "Primary Applicant") {
        salaryProportionValidIds.push(obj.id);
      }
    });
    return salaryProportionValidIds;
  }

  get annualSalary(): number {
    let total = 0.0;

    if (!this.incomePayload || !this.incomePayload.personnelIncome) {
      return total;
    }

    // Main Applicant salary details
    // personnelIncome -> averageVariableAllowance, basicSalary, fixedAllowance, lastAverageDeduction, lastThreeAverageDeduction

    let personnelIncome = [];
    try {
      personnelIncome = this.incomePayload["personnelIncome"].get();
    } catch (error) {
      personnelIncome = this.incomePayload["personnelIncome"];
    }

    let segments: any = [];

    try {
      segments = personnelIncome.map(this.calculateAnnualSalary);
    } catch (error) {
      segments = segments;
    }

    if (segments.length > 0) {
      let sum = segments.reduce((sum, value) => sum + value);
      total = sum * 12;
    }

    // JB's details
    return total + this.jbController.annualSalary;
  }

  calculateAnnualSalary = (obj: any) => {
    try {
      const averageAllowance = parseFloat((obj.averageVariableAllowance || "0").toString());
      const lastThreeDeduction = parseFloat((obj.lastThreeAverageDeduction || "0").toString());

      return (
        parseFloat((obj.basicSalary || "0").toString()) +
        parseFloat((obj.fixedAllowance || "0").toString()) +
        averageAllowance -
        lastThreeDeduction
      );
    } catch (error) {
      return 0.0;
    }
  };

  calculateSalary = (obj: any) => {
    const salaryProportionValidIdsList = this.salaryProportionValidIdsArr;
    try {
      if (salaryProportionValidIdsList.length >= 1) {
        try {
          if (salaryProportionValidIdsList.includes(obj.incomeHolder)) {
            const averageAllowance = parseFloat((obj.averageVariableAllowance || "0").toString());
            const lastThreeDeduction = parseFloat((obj.lastThreeAverageDeduction || "0").toString());

            let salaryBeforeLastThreeAverageDeduction =
              parseFloat(obj.basicSalary.toString()) +
              parseFloat((obj.fixedAllowance || "0").toString()) +
              averageAllowance;
            let salaryPropotion = salaryBeforeLastThreeAverageDeduction * this.dataPayload?.salaryProportionFactor;
            return salaryPropotion - lastThreeDeduction; // Long Term Deduction replaced with lastThreeAverageDeduction, LOS 316
          } else {
            return 0.0;
          }
        } catch (error) {
          return 0.0;
        }
      } else {
        return 0.0;
      }
    } catch (error) {
      return 0.0;
    }
  };

  getSalary = (incomeData) => {
    let segments = incomeData?.get().map(this.calculateSalary);
    let sum = segments.reduce((sum, value) => sum + value);
    return sum;
  };

  get annualBusiness(): number {
    let total = 0.0;

    if (!this.incomePayload || !this.incomePayload.businessIncome) {
      return total;
    }

    // Main Applicant business details
    // businessIncome.income
    total =
      (total +
        this.extractApplicantIncomeValue("businessIncome", "income", "incomeBusiness") -
        this.extractApplicantIncomeValue("businessIncome", "expense", "expenseBusiness")) *
      12;

    // JB's salary details
    return total + this.jbController.annualBusiness;
  }

  private extractApplicantIncomeValue(node1, node2, field) {
    try {
      let incomeData: any = [];

      try {
        incomeData = this.incomePayload[node1].get();
      } catch (error) {
        incomeData = this.incomePayload[node1] || {};
      }

      let incomes: any = [];

      try {
        incomes = incomeData[node2].map((obj) => parseFloat(obj[field] || "0"));
      } catch (error) {
        incomes = incomes;
      }

      let sum =
        incomes.length === 0
          ? 0
          : incomes.reduce((total, num) => {
              return total + num;
            });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  get annualCultivation(): number {
    let total = 0.0;
    // Main Applicant salary details

    if (!this.incomePayload || !this.incomePayload.cultivationIncome) {
      return total;
    }

    total =
      (total +
        this.extractApplicantIncomeValue("cultivationIncome", "income", "seasonalIncome") -
        this.extractApplicantIncomeValue("cultivationIncome", "expense", "seasonalExpense")) *
      12;

    // JB's details
    return total + this.jbController.annualCultivation;
  }

  get annualTotalIncome(): number {
    let total = 0.0;
    for (const salary of [this.annualSalary, this.annualBusiness, this.annualCultivation]) {
      total = total + parseFloat(salary.toString());
    }
    return total;
  }

  // Income And Expenses Details - EXPENSES

  get annualOther(): number {
    let total = 0.0;

    // Main Applicant salary details
    if (!this.expensePayload || !this.expensePayload.expenses) {
      return total;
    }

    let expenses: any = [];
    try {
      expenses = this.expensePayload.expenses.get();
    } catch (error) {
      expenses = this.expensePayload.expenses || {};
    }

    let expenseTotal = 0.0;
    try {
      expenseTotal = expenses
        .map((obj) => {
          const expAmount = parseFloat((obj.expenseAmount || "0.0").toString());
          const monthly = parseFloat((obj.frequencyForAnnual || "1.0").toString());
          const annually = parseFloat((obj.frequencyForMonth || "1.0").toString());

          return expAmount * monthly * annually;
        })
        .reduce((sum, value) => sum + value);
    } catch (error) {}

    let lifeInsuranceData: any[] = [];
    try {
      lifeInsuranceData = this.lifeInsuranceData.get();
    } catch (error) {
      lifeInsuranceData = this.lifeInsuranceData || [];
    }

    try {
      let premium = 0;

      try {
        premium = lifeInsuranceData
          .map((obj) => parseFloat((obj?.monthlyPremium || "0")?.toString()))
          .reduce((sum, value) => sum + value);
      } catch (error) {
        premium = premium;
      }

      const premiumAnnual = premium * 12;

      total = total + expenseTotal + premiumAnnual;
    } catch (error) {
      total = total;
    }

    // JB's details
    return total + this.jbController.annualOther;
  }

  get annualLiability(): number {
    let total = 0.0;

    // Main Applicant salary details
    if (!this.expensePayload || !this.expensePayload.expenses) {
      return total;
    }

    try {
      let liabilities: any = [];
      try {
        liabilities = this.liabilityData.loanLease.get();
      } catch (error) {
        liabilities = this.liabilityData.loanLease || [];
      }

      let leaseTotalAmounts: any = [];

      try {
        leaseTotalAmounts = liabilities.map((obj) => {
          if (obj.obligationType == 1) {
            const amountLeft = parseFloat((obj.installmentLeft || "0.0").toString());
            const installment = parseFloat((obj.installmentAmount || "0.0").toString());

            // return loanLeaseAnnualAmount(amountLeft, installment);

            return installment;
          } else {
            return 0.0;
          }
        });
      } catch (error) {}

      const leaseTotalAmount =
        leaseTotalAmounts.length > 0 ? leaseTotalAmounts.reduce((sum, value) => sum + value) : 0.0;

      try {
        liabilities = this.liabilityData.pawning.get();
      } catch (error) {
        liabilities = this.liabilityData.pawning || [];
      }

      let pawningTotalAmounts: any = [];

      try {
        pawningTotalAmounts = liabilities.map((obj) => {
          const outstanding = parseFloat((obj.outstanding || "0.0").toString());
          const insterest = parseFloat((obj.annualInterest || "0.0").toString());

          return pawningAnnualAmount(outstanding, insterest);
        });
      } catch (error) {}

      const pawningTotalAmount =
        pawningTotalAmounts.length > 0 ? pawningTotalAmounts.reduce((sum, value) => sum + value) : 0.0;

      const creditCardTotalAmount = this.extractApplicantLiabilityValue("creditCard", "limit", creditCardAnnualAmount);
      const overDraftTotalAmount = this.extractApplicantLiabilityValue(
        "overdraft",
        "outstanding",
        overDraftAnnualAmount
      );

      const _creditLoanAmount = creditCardTotalAmount;
      const _overDraftAmount = overDraftTotalAmount;
      const _pawningAmount = pawningTotalAmount;

      total = total + _creditLoanAmount + leaseTotalAmount + _overDraftAmount + _pawningAmount;
    } catch (error) {
      total = total;
    }

    // JB's details
    if (summaryCalculationSettings?.addJB === 1) {
      if (this.jbController.annualLiability) {
        total += this.jbController.annualLiability;
      }
    }

    return total;
  }

  private extractApplicantLiabilityValue(node, field, evaluator: any = null) {
    try {
      let liabilityData: any = [];

      try {
        liabilityData = this.liabilityData[node].get();
      } catch (error) {
        liabilityData = this.liabilityData[node] || {};
      }

      let liabilities: any = [];

      try {
        liabilities = liabilityData.map((obj) =>
          !evaluator ? parseFloat(obj[field] || "0") : evaluator(parseFloat(obj[field] || "0"))
        );
      } catch (error) {
        liabilities = liabilities;
      }

      let sum =
        liabilities.length === 0
          ? 0
          : liabilities.reduce((total, num) => {
              return total + num;
            });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  get annualTotalExpenses(): number {
    return this.annualOther + this.annualLiability;
  }

  // Income And Expenses Summary - INCOME
  get summaryAnnualIncome(): number {
    return this.annualTotalIncome;
  }

  get summaryAnnualExpenses(): number {
    return this.annualTotalExpenses;
  }

  get summaryAnnualNetIncome(): number {
    return parseFloat(this.summaryAnnualIncome.toString()) - parseFloat(this.summaryAnnualExpenses.toString());
  }

  get summaryMonthNetIncome(): number {
    return this.summaryAnnualNetIncome / 12.0;
  }

  // Income And Expenses Summary - EXPENSES
  get summaryMaxInstallments(): number {
    return this.summaryMonthNetIncome * this.dataPayload?.salaryProportionFactor;
  }

  get summaryMaxLoanAmount(): number {
    let term = this.creditDetails?.loanTerms;
    if (term instanceof String) {
      term = parseFloat(term.toString());
    }
    return this.summaryMaxInstallments * (term || 0.0);
  }

  get summaryGrossLoanRequirement(): number {
    // main applicant
    let approvedLoanAmount = this.creditDetails?.loanAmount || 0.0;

    return approvedLoanAmount;
  }

  get summaryRecommendedLoanAmount(): number {
    let maxAmount = 7500000;
    let approvedLoanAmount = this.creditDetails?.loanAmount || 0.0;
    approvedLoanAmount = approvedLoanAmount > maxAmount ? maxAmount : approvedLoanAmount;
    return approvedLoanAmount;
  }

  get summaryLoanTerm(): number {
    let term = this.creditDetails?.loanTerms || 0;
    if (term instanceof String) {
      term = parseFloat(term.toString());
    }
    return term;
  }

  get summaryInstallement(): number {
    return 0.0;
  }

  get salaryPropotion(): number {
    const salaryPayload = this.incomePayload?.personnelIncome;
    try {
      if (salaryPayload.length < 1) {
        return 0;
      } else {
        try {
          let salary = this.getSalary(salaryPayload);
          return salary;
        } catch (error) {
          return 0;
        }
      }
    } catch (error) {
      return 0;
    }
  }

  get incomeDetails(): Array<Object> {
    return [
      { key: "Salaried Annual Income", value: this.annualSalary },
      { key: "Business Annual Income", value: this.annualBusiness },
      { key: "Cultivation Annual Income", value: this.annualCultivation },
      { key: "Total Annual Income", value: this.annualTotalIncome },
    ];
  }

  get expenseDetails(): Array<Object> {
    return [
      { key: "Living Expenses", value: this.annualOther },
      { key: "Liability Services Expenses", value: this.annualLiability },
      { key: "", value: "" },
      { key: "Total Annual Expenses", value: this.annualTotalExpenses },
    ];
  }

  get incomeAndExpenseSummary(): Array<Object> {
    return [
      { key: "Annual Income", value: this.summaryAnnualIncome },
      { key: "Annual Expenses", value: this.summaryAnnualExpenses },
      { key: "Annual Net Income", value: this.summaryAnnualNetIncome },
      { key: "Month Net Income", value: this.summaryMonthNetIncome },
    ];
  }

  get loanSummary(): Array<Object> {
    // let frequency = this.creditDetails -> loan frequency
    return [
      { key: "Salary Proportion", value: this.salaryPropotion },
      { key: "Feasible Loan Installment", value: this.summaryMaxInstallments },
      { key: "Feasible Loan Amount", value: this.summaryMaxLoanAmount },
      {
        key: "Gross Loan Requirement",
        value: this.summaryGrossLoanRequirement,
      },
      {
        key: "Recommended Loan Amount",
        value: this.summaryRecommendedLoanAmount,
      },
      { key: "Loan Tenor", value: this.summaryLoanTerm, round: true },
      // { key: "Installment", value: this.summaryInstallement },
    ];
  }
}

export const typedData = (masterData, masterType, key) => masterData[masterType]?.find((t) => t?.id === key);
