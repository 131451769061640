import { Typography, Stack } from "@mui/material";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from '@mui/icons-material/Close';
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import { SelectComponent } from "../../components/InputComponents/SelectComponent/SelectComponent";
import { select_basic } from "../../styles";
import { CheckBoxComponent } from "../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import React, { useState } from "react";

const channelRole = [
    { id: 1, name: 'Standard' },
    { id: 2, name: 'Manager' },
]

const ChannelManagementAddUserModal = (object) => {
    const [selectedTasks, setSelectedTasks] = useState(object.payload);
    const [selectedRole, setSelectedRole] = useState('Standard');

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleTaskChange = (task) => {
        setSelectedTasks((prevSelectedTasks) => ({
            ...prevSelectedTasks,
            [task]: prevSelectedTasks[task] === 1 ? 0 : 1,
        }));
    };

    const handleSubmit = () => {
        const selectedValues = {
            role: selectedRole,
            initiate: selectedTasks.initiate,
            assist: selectedTasks.assist,
            postSupport: selectedTasks.postSupport,
        };

        object.submitted(selectedValues, object.userId);
    };

    return (
        <div>
            <div>
                <Stack direction={'row'} justifyContent={"space-between"} pb={2}>
                    <Typography variant="subtitle1" color={"gray"} mb={2}>
                        Select the Role
                    </Typography>
                    <CloseIcon fontSize='large' onClick={()=> object.cancel(object.userId)} style={{cursor: 'pointer'}}/>
                </Stack>
                <Stack spacing={1} mb={2}>
                    <SelectComponent
                        sx={select_basic}
                        label="Select the channel role"
                        value={selectedRole}
                        values={channelRole}
                        required
                        onChange={handleRoleChange}
                        defaultPlaceholder={true}
                    />
                </Stack>
            </div>
            <div>
                <Typography variant="subtitle1" color={"gray"} mb={1}>
                    Select the channel task
                </Typography>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <CheckBoxComponent
                            onChange={() => handleTaskChange('initiate')}
                            checked={selectedTasks.initiate === 1}
                        />
                        <Typography variant="body1"> Initiate</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                        <CheckBoxComponent
                            onChange={() => handleTaskChange('assist')}
                            checked={selectedTasks.assist === 1}
                        />
                        <Typography variant="body1"> Assist</Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                        <CheckBoxComponent
                            onChange={() => handleTaskChange('postSupport')}
                            checked={selectedTasks.postSupport === 1}
                        />
                        <Typography variant="body1"> Post Support</Typography>
                    </Stack>
                </Stack>
            </div>
            <div>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="Submit"
                        variant="contained"
                        onClick={handleSubmit}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </div>
    );
}


export default ChannelManagementAddUserModal;
