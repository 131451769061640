import { Downgraded } from "@hookstate/core";
import { Grid } from "@mui/material";
import { branchCodeMap } from "los-util";
import React, { useEffect, useState } from "react";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../configs/constants/styleValues";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../services/currencyFormater";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { StyledButton } from "../../../../InputComponents/ButtonComponent/StyledButton";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { IconRowComponent } from "../IconRowComponent";

const FormDataSection = ({ masterDataStore, accountNumber, isLoading, purposeV = "", moreInfoV = "", onInputChange, isLoggedInUserNotTheAssignee, onClickedSend, checkPrivilegeStatus, selectedData }) => {
  const [purpose, setPurpose] = useState(purposeV);
  const [moreInfo, setMoreInfo] = useState(moreInfoV);
  const [isEditing, setIsEditing] = React.useState(true);
  const [purposeName, setPurposeName]: any = React.useState("");

  useEffect(() => {
    if (purpose || moreInfo) {
      if (purpose) {
        let selectedPurpose: any = {};
        selectedPurpose = masterDataStore
          ?.getMasterData(MASTER_DATA_KEYS?.LOAN_PURPOSE)
          ?.get()
          ?.find((t) => t.id == purpose);
        setPurposeName(selectedPurpose?.name ?? "");
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, []);

  const onInputValueChange = (param, accountNumber, value, selectedData) => {
    if (param === "purpose") {
      setPurpose(value);
    } else if (param === "moreInfo") {
      setMoreInfo(value);
    }

    onInputChange(param, accountNumber, value, selectedData);
  };

  const saveDispatcher = () => {
    onClickedSend();
  };

  const previlegeDispatcher = () => {
    return checkPrivilegeStatus();
  };

  const editHandler = () => {
    setIsEditing(true);
  };

  return (
    <>
      {!isEditing ? (
        <>
          <Grid container columns={24} pt={2} mt={2} style={{ borderTop: "1px solid #9e9e9e" }}>
            <Grid item xs={20}>
              <Grid container columns={22}>
                <Grid item xs={6}>
                  <IconRowComponent subTxt="Purpose" mainTxt={purposeName ? purposeName : "N/A"} color={"#7fb4de"} />
                </Grid>
                <Grid item xs={16}>
                  <IconRowComponent subTxt="Loan Informations" mainTxt={moreInfo ? moreInfo : "N/A"} color={"#7fb4de"} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <StyledButton styles={isLoading ? loadingButtonStyle : continueButtonStyle} title={"Edit"} onClick={() => editHandler()} loading={isLoading} disabled={previlegeDispatcher()} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container className="full-width" columns={24} alignItems="center" spacing={2}>
            <Grid item xs={12} mt={5}>
              <SelectComponent label="Purpose of Loan" value={purpose} onChange={(e) => onInputValueChange("purpose", accountNumber, e.target.value, selectedData)} values={masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LOAN_PURPOSE)} defaultPlaceholder={true} disabled={isLoggedInUserNotTheAssignee} />
            </Grid>
          </Grid>
          <Grid container className="full-width" columns={24} alignItems="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <CMTextInput
                desc={"More Information on Repayment Loans"}
                component={
                  <TextBoxComponent
                    onChange={(e) => {
                      onInputValueChange("moreInfo", accountNumber, e.target.value, selectedData);
                    }}
                    multiline={true}
                    minRows={3}
                    value={moreInfo}
                    disabled={isLoading || isLoggedInUserNotTheAssignee}
                  />
                }
              // error={errorMessage?.item !== '' ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <StyledButton styles={isLoading ? loadingButtonStyle : continueButtonStyle} title={"Save"} onClick={() => saveDispatcher()} loading={isLoading} disabled={checkPrivilegeStatus()} />
          </Grid>
        </>
      )}
    </>
  );
};

const ReportOnBurrowingLayout = ({ data, applicationId, innerComponentData, tabsToEdit, loanData, onClickedSend, selectedTab, setSubmitSelectedData }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const [isLoading, setIsLoading] = useState(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const { disbursed }: any = creditFileData ?? null;

  const onInputChange = (param, accountNumber, value, selectedData) => {
    let tempSelectData = selectedData;

    if (tempSelectData[accountNumber]) {
      tempSelectData[accountNumber] = {
        ...tempSelectData[accountNumber],
        ...{ [param]: value },
      };
    } else {
      tempSelectData[accountNumber] = { [param]: value };
    }

    setSubmitSelectedData(tempSelectData);
  };

  function debtHandlingCapacity(principalGranted = 0, outstandingBalanace = 0) {
    if (principalGranted !== 0 && outstandingBalanace !== 0) {
      let prevSuccessServicedDebtAmount = principalGranted - outstandingBalanace;
      let DebtHandlingCapacity = principalGranted / prevSuccessServicedDebtAmount;
      return DebtHandlingCapacity.toString();
    } else {
      return "N/A";
    }
  }

  const appraisalBranchNameFinder = (branchCode) => {
    if (branchCode) {
      for (const branchKey in branchCodeMap) {
        if (branchCodeMap[branchKey] == branchCode) {
          return branchKey;
        } else {
          continue;
        }
      }
    } else {
      return "N/A";
    }
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoading || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  const getTotalValue = (loanData: any = []) => {
    let totalAmount: any = 0;

    if (loanData.length > 0) {
      loanData.forEach((element) => {
        totalAmount += element?.principalGranted;
      });
    }
    return `Rs. ${formatCurrency(totalAmount)}`;
  };

  return (
    <>
      {loanData.map((obj, index) => {
        if (Object.keys(obj).length > 0) {
          return (
            <React.Fragment key={index}>
              <Grid container style={{ backgroundColor: "#e6f0fa" }} className="align-center" mb={0.75}>
                <Grid item xs={12}>
                  <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">
                    Ongoing Loans - {obj.nic}
                    <div style={{ float: "right" }}>{getTotalValue(obj?.ongloingLoans)}</div>
                  </div>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid container alignItems="center" sx={{ paddingTop: "20px", paddingBottom: "20px", position: "relative" }}>
                  <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
                </Grid>
              ) : obj.ongloingLoans && obj.ongloingLoans.length > 0 ? (
                <>
                  {obj.ongloingLoans.map((loanItem, i) => {
                    const { accountNumber, branchCode, productCode, productName, grantedDate, disburseDate, assetCode, interestRate, outstandingBalanace, securityType, interestDue, totalPrincipalDue, totalnterestDue, chargeDue, settledDate, closureDate, principalGranted } = loanItem;
                    let tempSelectData = obj?.selectedData?.[accountNumber];
                    const branchName = appraisalBranchNameFinder(branchCode);

                    return (
                      <Grid
                        container
                        alignItems="center"
                        className="row_list_item"
                        p={2}
                        style={{
                          backgroundColor: `#7fb4de3d`,
                        }}
                      >
                        <Grid key={i} container className="full-width" columns={24} alignItems="center" spacing={2}>
                          <Grid item xs={18}>
                            <IconRowComponent subTxt="Product Name" mainTxt={productName ? productName : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Account Number" mainTxt={accountNumber ? accountNumber : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Branch Name" mainTxt={branchName ? branchName : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Branch Code" mainTxt={branchCode ? branchCode.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Product code" mainTxt={productCode ? productCode.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Granted Date" mainTxt={grantedDate ? grantedDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Disburse Date" mainTxt={disburseDate ? disburseDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Asset Code" mainTxt={assetCode ? assetCode : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Interest Rate" mainTxt={interestRate ? interestRate.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Outstanding Balance" mainTxt={outstandingBalanace ? outstandingBalanace.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Security Type" mainTxt={securityType ? securityType.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Interest Due" mainTxt={interestDue ? interestDue : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Total Principal Due" mainTxt={totalPrincipalDue ? totalPrincipalDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Total Interest Due" mainTxt={totalnterestDue ? totalnterestDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Charges Due" mainTxt={chargeDue ? chargeDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Settled Date" mainTxt={settledDate ? settledDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Closure Date" mainTxt={closureDate ? closureDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Loan Amount" mainTxt={principalGranted ? principalGranted.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={18}>
                            <IconRowComponent subTxt="Debt Handling Capacity" mainTxt={debtHandlingCapacity(principalGranted, outstandingBalanace)} color={"#7fb4de"} />
                          </Grid>
                        </Grid>
                        {<FormDataSection masterDataStore={masterDataStore} accountNumber={accountNumber} isLoading={isLoading} purposeV={tempSelectData?.purpose ? tempSelectData?.purpose : ""} moreInfoV={tempSelectData?.moreInfo ? tempSelectData?.moreInfo : ""} onInputChange={onInputChange} isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee} onClickedSend={onClickedSend} checkPrivilegeStatus={checkPrivilegeStatus} selectedData={obj.selectedData} />}
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {/* ------------------------------------------------------------------------ */}

              <Grid container style={{ backgroundColor: "#e6f0fa" }} className="align-center" mb={0.75}>
                <Grid item xs={12}>
                  <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">
                    Settled Loans - {obj.nic}
                    <div style={{ float: "right" }}>{getTotalValue(obj?.settiledLoans)}</div>
                  </div>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid container alignItems="center" sx={{ paddingTop: "20px", paddingBottom: "20px", position: "relative" }}>
                  <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
                </Grid>
              ) : obj.settiledLoans && obj.settiledLoans.length > 0 ? (
                <>
                  {obj.settiledLoans.map((loanItem, i) => {
                    const { accountNumber, branchCode, branchName, productCode, productName, grantedDate, disburseDate, assetCode, interestRate, outstandingBalanace, securityType, interestDue, totalPrincipalDue, totalnterestDue, chargeDue, settledDate, closureDate, principalGranted } = loanItem;
                    let tempSelectData = obj?.selectedData?.[accountNumber];

                    return (
                      <Grid
                        container
                        alignItems="center"
                        className="row_list_item"
                        p={2}
                        style={{
                          backgroundColor: `#7fb4de3d`,
                        }}
                      >
                        <Grid key={i} container className="full-width" columns={24} alignItems="center" spacing={2}>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Account Number" mainTxt={accountNumber ? accountNumber : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={18}>
                            <IconRowComponent subTxt="Product Name" mainTxt={productName ? productName : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={18}>
                            <IconRowComponent subTxt="Branch Name" mainTxt={branchName ? branchName : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Branch Code" mainTxt={branchCode ? branchCode.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Product code" mainTxt={productCode ? productCode.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Granted Date" mainTxt={grantedDate ? grantedDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Disburse Date" mainTxt={disburseDate ? disburseDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Asset Code" mainTxt={assetCode ? assetCode : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Interest Rate" mainTxt={interestRate ? interestRate.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Outstanding Balance" mainTxt={outstandingBalanace ? outstandingBalanace.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Security Type" mainTxt={securityType ? securityType.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Interest Due" mainTxt={interestDue ? interestDue : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Total Principal Due" mainTxt={totalPrincipalDue ? totalPrincipalDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Total Interest Due" mainTxt={totalnterestDue ? totalnterestDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Charges Due" mainTxt={chargeDue ? chargeDue.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Settled Date" mainTxt={settledDate ? settledDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Closure Date" mainTxt={closureDate ? closureDate : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={6}>
                            <IconRowComponent subTxt="Loan Amount" mainTxt={principalGranted ? principalGranted.toString() : "N/A"} color={"#7fb4de"} />
                          </Grid>
                          <Grid item xs={18}>
                            <IconRowComponent subTxt="Debt Handling Capacity" mainTxt={debtHandlingCapacity(principalGranted ? principalGranted : 0, outstandingBalanace ? outstandingBalanace : 0)} color={"#7fb4de"} />
                          </Grid>
                        </Grid>

                        <FormDataSection masterDataStore={masterDataStore} accountNumber={accountNumber} isLoading={isLoading} purposeV={tempSelectData?.purpose ? tempSelectData?.purpose : ""} moreInfoV={tempSelectData?.moreInfo ? tempSelectData?.moreInfo : ""} onInputChange={onInputChange} isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee} onClickedSend={onClickedSend} checkPrivilegeStatus={checkPrivilegeStatus} selectedData={obj.selectedData} />
                      </Grid>
                    );
                  })}

                  <Grid container justifyContent="flex-end">
                    <StyledButton styles={isLoading ? loadingButtonStyle : continueButtonStyle} title={"Save"} onClick={onClickedSend} loading={isLoading} disabled={checkPrivilegeStatus()} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        }
        return null; // Return null for objects with no keys
      })}
    </>
  );
};

export default ReportOnBurrowingLayout;
