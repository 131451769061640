import { Downgraded, useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { creditData, userDetails } from "../../../../../../configs/mainStore";
import { CollateralDocumentsSubSection } from "./CollateralDocumentsSubSection";

const CollateralDocuments = ({ data, applicationId, innerComponentData }) => {
  const creditFileData: any = useState(creditData);
  const { formData }: any = creditFileData.attach(Downgraded).get();

  const jointBorrowerArr: any = [];

  if (formData?.jointBorrowerData?.length > 0) {
    formData?.jointBorrowerData.forEach((borrower) => {
      jointBorrowerArr.push(borrower.personalData);
    });
  }

  const combinedData = [formData?.personalData, ...jointBorrowerArr];
  const individualId = formData?.personalData?.individualId;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height" >

        <CollateralDocumentsSubSection
          applicationId={applicationId}
          documentTypeIds={innerComponentData?.documentTypeIds}
          documentSectionName={innerComponentData?.title}
          documentComponentId={innerComponentData?.id}
          participantId={individualId}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
          personalData={combinedData}
          creditFileData={creditFileData}
          participantType={innerComponentData.participantType}
          applicantType={innerComponentData.applicantType}
        />
      </div>
    </Grid>
  );
};

export default CollateralDocuments;
