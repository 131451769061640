import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { disSelectButtonStyles, nextBtnStyles } from "../../../../../../../../configs/constants/styleValues";
import { langDictionary } from "../../../../../../../../configs/languageDictionaryEn";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";

const ToggleLabel = ({ value, onChange, metaData }) => {
  const { t: translate } = useTranslation();
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);
  let currentInput = valueInput || value[metaData?.dataKey];

  return (
    <Stack direction={"row"} alignItems={"center"} flex={2} px={1.5}>
      <Stack flex={1} direction={'row'}>
        <Typography color={"gray"}>
          {metaData?.title}
        </Typography>
        {metaData?.render?.required === true && (
          <span style={{ color: "red" }}> *</span>
        )}
      </Stack>
      <Stack direction="row" spacing={1} flex={1} px={1.5}>
        <StyledButton
          styles={currentInput === 1 ? nextBtnStyles : disSelectButtonStyles}
          title={translate(langDictionary?.yes)}
          onClick={() => {
            setValue(1);
            value[metaData?.dataKey] = 1;
            onChange(metaData, value);
          }}
        />
        <StyledButton
          styles={currentInput === 0 ? nextBtnStyles : disSelectButtonStyles}
          title={translate(langDictionary?.no)}
          onClick={() => {
            setValue(0);
            value[metaData?.dataKey] = 0;
            onChange(metaData, value);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ToggleLabel;
