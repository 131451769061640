import CloseIcon from '@mui/icons-material/Close';
import { Chip, Stack, Typography } from "@mui/material";
import React, { useEffect, } from "react";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { userName } from "../../../utility/helpers/creditFileUtility";

const UserConfigerationModal = (params) => {
    const [names, setNames] = React.useState<any>([]);

    useEffect(() => {
        if (params.selectedGroupId !== undefined) {
            const nameValues = params.selectedGroupId.filter(name => name !== null);
            setNames(nameValues);
        }
    }, [params.selectedGroupId]);

    params?.names?.set(names)

    const closeCondition = () => {
        closeGlobalModal();
    };

    return (
        <>
            <div>
                <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                    Users in group- {params?.group?.name || ""}
                </Typography>
                <Stack direction={'row'} sx={{ pr: 2 }}>
                    <Typography variant="caption" color={"gray"}>
                        {Array.isArray(params.list) && params.list.map((id) => (
                            <Chip key={id} label={`${userName(id)} (${id})`} sx={{ m: '4px', p: 2 }} size="small" className="font-size-12 basic-font-color" />
                        ))}

                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="Close"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={closeCondition}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </>
    );
}

export default UserConfigerationModal;
