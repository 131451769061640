import { useHookstate } from "@hookstate/core"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import moment from "moment"
import React, { useCallback } from 'react'
import { formatCurrency } from '../../../../../../../../../../services/currencyFormater'
import { deleteMultipleInputItem } from '../../../../../../../../../../services/multipleInputDelete'
import { generateUniqueId } from "../../../../../../../../../../utility/util"
import { ButtonComponent } from '../../../../../../../../../InputComponents/ButtonComponent/ButtonComponent'
import TableBodyWrapper from '../../../../Helpers/TableContainer/TableBody/TableBodyWrapper'
import { TableColumnTextBoxWrapperTypeString, TableColumnsElementWrapper, TableColumnsTextWrapper, TableTextBoxWrapperRevaamp } from '../../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper'
import { CustomTableHeaderWrapper } from '../../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper'
import { TableRowWrapper } from '../../../../Helpers/TableContainer/TableRow/TableRowWrapper'
import TableWrapper from '../../../../Helpers/TableContainer/TableWrapper/TableWrapper'
import { viewMode } from "../../../../Stores/MicroAppraisalStore"

type TitleYearArray = { dataKey: string, isEdited: number, title: string }

interface ComprehensiveProps {
    mainTitle: string;
    titleYearArray: TitleYearArray[];
    formData: any;
    defaultDataObject: any;
    options: any;
    isNotVisibleAddIcon?: boolean;
}

const Comprehensive = ({ mainTitle, titleYearArray, formData, defaultDataObject, options, isNotVisibleAddIcon }: ComprehensiveProps) => {
    const viewModeType = useHookstate(viewMode);

    const isSummaryViewMode = (viewModeType.get() === "SUMMARY");

    const onNewRowAdding = () => formData.merge([JSON.parse(JSON.stringify({ ...defaultDataObject, dataId: generateUniqueId() }))]);

    const calculateTotValues = useCallback((key: string) => {

        if (key === "variableRate") return "";

        let tot = 0;
        formData.filter(item => item && !item["removedItem"].get()).forEach((item: any, index) => {

            if (item[key].get()) {
                tot = tot + Number(item[key].get() ?? 0);
            }
        });

        return formatCurrency(tot);
    }, [formData.get()]);

    const getDescriptions = (value) => {
        return options.find((item: { id: number, name: string }) => item.id == value).name;
    }

    return (
        <Stack width={"100%"} key={mainTitle}>
            <Stack direction={"row"} alignItems={"center"} gap={2} style={{ backgroundColor: `${isSummaryViewMode ? "" : "#90caf9"}` }} px={1}>
                <Typography variant="subtitle1" color="#555555" fontWeight="bold" children={mainTitle} />
                {isSummaryViewMode || isNotVisibleAddIcon ? <></> :
                    <IconButton sx={{ verticalAlign: "middle", cursor: "pointer" }} onClick={onNewRowAdding}>
                        <AddIcon />
                    </IconButton>
                }
            </Stack>

            <TableWrapper>
                <CustomTableHeaderWrapper dataObject={titleYearArray} />
                <TableBodyWrapper>
                    {
                        formData.get() && formData.filter(item => !item["removedItem"].get()).map((item: any, index) => {

                            const onChange = (value, yearDataKey) => {
                                let nextYear = Number(moment().year()) + 1;

                                if (yearDataKey === "variableRate") {
                                    let regex = /^(100|[0-9]?[0-9])$/;
                                    value = regex.test(value.toString()) ? value : item?.[yearDataKey]?.get() || 0;
                                }

                                let object = { [yearDataKey]: value };

                                if ((yearDataKey == "variableRate" && value != item?.[yearDataKey]?.get()) || yearDataKey == nextYear) {
                                    let followingYear = Number(moment().year()) + 2;
                                    let valueOfFollowingYear = 0;
                                    let variableRateValue = (yearDataKey == "variableRate") ? Number(value) : Number(item?.["variableRate"]?.get() || 0);
                                    let nextYearValue = (yearDataKey == nextYear) ? Number(value) : Number(item?.[nextYear]?.get() || 0)

                                    valueOfFollowingYear = Math.floor(nextYearValue + (nextYearValue * variableRateValue) / 100);

                                    object[followingYear] = valueOfFollowingYear;
                                }

                                item.set(prev => ({ ...prev, ...object }))
                            }

                            return <TableRowWrapper key={item?.dataId.get()}>

                                {isSummaryViewMode ?
                                    <TableColumnsTextWrapper index={"description" + "TableColumnsTextBoxWrapper"} children={item.description.get()} />
                                    :
                                    <TableColumnTextBoxWrapperTypeString value={(item.description)} index={"TableColumnsSelectBoxWrapperRevaamp" + "description" + index} title={""} />
                                }

                                {Object.values(titleYearArray).map((year: TitleYearArray, ind: number) => {

                                    if (year?.["isAutoPopulatedColumns"] || isSummaryViewMode) {
                                        return <TableColumnsTextWrapper index={year + "TableColumnsTextBoxWrapper"} children={formatCurrency(item[year["dataKey"]].get())} tableCell={{ borderBottom: '1px solid #e0e0e0' }} />
                                    }

                                    return <TableTextBoxWrapperRevaamp key={"TableColumnsTextBoxWrapper_" + year["dataKey"] + `${item?.dataId.get()}`} onChange={(value) => onChange(value, year["dataKey"])} value={item[year["dataKey"]]} index={"TableColumnsTextBoxWrapper_" + year + "_" + ind + `${item?.dataId.get() * 2}`} title={''} />
                                })}

                                {isSummaryViewMode || isNotVisibleAddIcon ? <></> : <TableColumnsElementWrapper index={"index" + "delete" + index}>
                                    <Tooltip title={"Delete"}>
                                        <Box>
                                            <ButtonComponent iconbtn children={<DeleteIcon />} onClick={() => deleteMultipleInputItem(item)} />
                                        </Box>
                                    </Tooltip>
                                </TableColumnsElementWrapper>}
                            </TableRowWrapper>
                        })
                    }
                    <TableRowWrapper>
                        <TableColumnsTextWrapper index={mainTitle + "TableColumnsTextBoxWrapper"} >
                            <React.Fragment>{`Total ${mainTitle}`}</React.Fragment>
                        </TableColumnsTextWrapper>

                        {Object.values(titleYearArray).map((year: any, index) => {
                            return <TableColumnsTextWrapper index={year + "TableColumnsTextBoxWrapper"} >
                                <React.Fragment>
                                    {` ${calculateTotValues(year.dataKey)}`}
                                </React.Fragment>
                            </TableColumnsTextWrapper>
                        })}
                    </TableRowWrapper>
                </TableBodyWrapper>
            </TableWrapper>
        </Stack>

    )
}

export default Comprehensive