import { Stack } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/en-gb";
import { DatePickerProps, DatePickerViews } from "../../../configs/interfaces";

/*
____________________________________________________________________________________________________________
______________________USE MOMENT FOR EVERY THAT YOU WANT OVERRIDE ANY FUNCTIONALITIES______________________
____________________________________________________________________________________________________________*/

const ValidateDatePicker: React.FC<DatePickerProps> = ({
  autoFocus,
  className,
  dayOfWeekFormatter,
  defaultCalendarMonth,
  defaultValue,
  disabled,
  enableFuture,
  disablePast,
  format,
  // formatDensity,
  inputRef,
  label,
  loading,
  localeText,
  maxDate,
  minDate,
  monthsPerRow,
  onAccept,
  onChange,
  onClose,
  onError,
  onMonthChange,
  onOpen,
  onSelectedSectionsChange,
  onViewChange,
  onYearChange,
  open,
  openTo,
  // orientation,
  reduceAnimations,
  referenceDate,
  renderLoading,
  // selectedSections,
  showDaysOutsideCurrentMonth,
  slotProps,
  slots,
  sx,
  value,
  required,
  views
}) => {
  const deafultDateFormat: string = "DD/MM/YYYY";
  const defaultViews: DatePickerViews[] = ["year", "month", "day"];

  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
        <DesktopDatePicker
          autoFocus={autoFocus}
          className={className}
          dayOfWeekFormatter={dayOfWeekFormatter}
          defaultValue={defaultValue}
          disabled={disabled}
          disableFuture={!enableFuture}
          disablePast={disablePast}
          format={deafultDateFormat}
          value={value ? moment(value) : null}
          // formatDensity={formatDensity}
          inputRef={inputRef}
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: `${label}${required ? '<span class="MuiFormLabel-asterisk"> *</span>' : ""}`,
              }}
            />
          }
          loading={loading}
          localeText={localeText}
          maxDate={maxDate}
          minDate={minDate}
          // monthsPerRow={monthsPerRow}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          onError={onError}
          onMonthChange={onMonthChange}
          onOpen={onOpen}
          onSelectedSectionsChange={onSelectedSectionsChange}
          onViewChange={onViewChange}
          onYearChange={onYearChange}
          open={open}
          // orientation={orientation}
          reduceAnimations={reduceAnimations}
          referenceDate={referenceDate}
          renderLoading={renderLoading}
          // selectedSections={selectedSections}
          showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
          slotProps={{ textField: { size: "small" } }}
          // openTo="month"
          views={views && Array.isArray(views) ? views : defaultViews}
          slots={slots}
          sx={sx}
        // timezone={timezone}
        // value={value}
        // view={view}
        // views={views}
        // yearsPerRow={yearsPerRow}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default ValidateDatePicker;
