import { Box, Stack, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import { closeBtn } from '../../../../../../styles';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';
import { useState } from '@hookstate/core';

function LetterThankYouModal(params) {
  const applicationId = params?.applicationId;

  const currentLocalStorage = JSON.parse(localStorage.getItem('offerLetter') || '{}');

  const [managerName, setManagerName] = React.useState('');
  const [managerServiceNumber, setManagerServiceNumber] = React.useState('');
  let initialData = { [applicationId]: { managerName: managerName, managerServiceNumber: managerServiceNumber } };

  const saveCondition = () => {
    if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
      params.callBackOnAction(managerName, managerServiceNumber, params?.nameid, params?.serviceNum);
    }

    closeGlobalModal();
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  let inputComponent;
  let labelName;
  if (params?.type === 'name') {
    labelName = 'Name';
    inputComponent = (
      <TextBoxComponent
        value={managerName}
        onChange={(e) => {
          setManagerName(e.target.value);
        }}
        variant="outlined"
        size="small"
      />
    );
  } else if (params?.type === 'service-number') {
    labelName = 'Service Number';
    inputComponent = (
      <TextBoxComponent
        value={managerServiceNumber}
        onChange={(e) => {
          setManagerServiceNumber(e.target.value);
        }}
        variant="outlined"
        size="small"
      />
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Disbursment Of Facility</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title={labelName}
            component={
              <Grid xs={5} item>
                {inputComponent}
              </Grid>
            }
            isMandatory={false}
            showErrors={false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveCondition}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
}

export default LetterThankYouModal;
