import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Box, Divider, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { INPUT_GROUP } from "../../../configs/constants/styleValues";
import { deleteMultipleInputItem } from "../../../services/multipleInputDelete";
import { inputGroup } from "../../../styles";
import { generateUniqueId } from "../../../utility/util";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { FormLabelComponent } from "../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import AlertDialogBox from "../../OtherComponents/AlertComponent/AlertComponent";

export const MultipleInputWrapper = ({ label, sourceArray, children, defaultDataObject, mandatoryKeys, actionReset, removeComponent = false, showControllers = true, addButtonInvisible = false, isValidateNewFieldAdding = false, growable = true, specialOption = false, showControlsAfterDelete = false }: any) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [showControllersSub, setShowControllersSub] = React.useState(true);

  useEffect(() => {
    setShowControllers();
  }, []);

  const onValuesReset = () => {
    setOpenModal(true);
  };

  const elementKeys: any = sourceArray?.length > 0 ? Object.keys(sourceArray[0]) : null;

  const enableAddNewItem =
    sourceArray?.length > 0 ?
      sourceArray?.every((element) => {
        let enable = true;

        if (!Object.keys(element).includes("removedItem")) {
          for (let key = 0; key < elementKeys?.length; key++) {
            if (mandatoryKeys.includes(elementKeys[key])) {
              if (element[elementKeys[key]]?.get()?.length === 0 || element[elementKeys[key]]?.get() === null) {
                enable = false;
                break;
              }
            }
          }
        }

        return enable;
      }) : true;

  const onNewFieldAdding = () => {
    if (!addButtonInvisible) {
      sourceArray.merge([{ ...defaultDataObject, dataId: generateUniqueId() }]);
    }
  };

  const setShowControllers = () => {

    if (sourceArray?.length === 0) return false;
    let isAllItemRemoved = sourceArray?.every((item) => {
      return JSON.parse(JSON.stringify(item.value)).hasOwnProperty("removedItem");
    });

    // Below added to show controls buttons after JB deletion
    if (showControlsAfterDelete) {
      isAllItemRemoved = false
    }

    setShowControllersSub(!isAllItemRemoved ? true : false);
    return !isAllItemRemoved ? true : false;
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleOk = () => {
    if (label === "Other ID Types") {
      defaultDataObject.removedItem = true;
    }
    sourceArray?.set([JSON.parse(JSON.stringify(defaultDataObject))]);
    if (actionReset) {
      actionReset();
    }
    setOpenModal(false);
  };

  const BuildAddButton = () => {

    if (enableAddNewItem && !addButtonInvisible) {
      return <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={onNewFieldAdding} disabled={isValidateNewFieldAdding || !growable ? true : false} />
    }
    else if (label === "Other ID Types" && !isValidateNewFieldAdding) {
      return <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={onNewFieldAdding} />;
    }

    else if (specialOption) {
      return <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={onNewFieldAdding} />
    }
    else return <></>
  }

  return (
    <>
      {showControllers && (
        <Box mb={2} display="flex" alignItems="center">
          <Box>
            <FormLabelComponent>{label}</FormLabelComponent>
          </Box>
          <Box ml={3}>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
              <BuildAddButton />
              <ButtonComponent iconbtn children={<RotateLeftIcon />} onClick={onValuesReset} />
            </Stack>
          </Box>
        </Box>
      )}
      {children}
      <AlertDialogBox open={openModal} handleClose={handleCancel} handleOk={handleOk} mainTitle={""} subTitle={"If you select OK, you will be lost every thing that you entered."} description={"Select OK to continue."} />
    </>
  );
};

export const MultipleInput = ({ selectedItem, children, index, enableDeleteOnFirstItem = true, deleteFunction }: any) => {
  try {
    if (selectedItem?.removedItem?.get() === true) {
      return <></>;
    }
  } catch (error) {
    if (selectedItem?.removedItem === true) {
      return <></>;
    }
  }

  const handleDeleteFunction = () => {
    deleteFunction(index, selectedItem);
  };

  return (
    <Box display="flex" alignItems="right" sx={inputGroup} p={INPUT_GROUP.padding} m={INPUT_GROUP.margin} mt={INPUT_GROUP.marginTop} mb={1}>
      <Box style={{ width: "100%" }}>{children}</Box>
      <Box>
        <ButtonComponent iconbtn children={<DeleteIcon />} onClick={handleDeleteFunction} />
      </Box>
    </Box>
  );
};

export function DeleteButton({ selectedItem, index, enableDeleteOnFirstItem = true }) {
  if (selectedItem?.removedItem?.get() === true) {
    return <> </>;
  }

  return (
    <Box>
      {(index !== 0 || enableDeleteOnFirstItem === true) && (
        <ButtonComponent
          iconbtn
          children={<DeleteIcon />}
          onClick={() => {
            deleteMultipleInputItem(selectedItem);
          }}
        />
      )}
    </Box>
  );
}

export const OnDemandDeleteBtn = ({ onDeleteHandler }) => {
  return <Box>
    <ButtonComponent
      iconbtn
      children={<DeleteIcon />}
      onClick={onDeleteHandler}
    />
  </Box>
}
