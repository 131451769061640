import { useState } from '@hookstate/core';
import React from 'react'
import { salesandPurchaseInformationData } from '../../../../../../Stores/MicroAppraisalStore';
import AutoGeneratedTable from '../../../../../../Helpers/TableContainer/AutoGeneratedTable/AutoGeneratedTable';
import { defaultSalesandPurchaseInformationForState } from '../../../../../../Stores/MicroAppraisalDefaultData';
import { useTranslation } from 'react-i18next';
import { salesAndPurchaseInformationContainer } from '../../../../../../Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs';

const SalesInformation = () => {
    const salesandPurchaseInformation = useState(salesandPurchaseInformationData);
    const { t: translate } = useTranslation();

    const props = {
        formData: salesandPurchaseInformation["salesandInformation"],
        defaultDataObject: defaultSalesandPurchaseInformationForState,
        mainTitle: translate("salesInformation"),
        tableRawData: salesAndPurchaseInformationContainer
    }

    return (
        <AutoGeneratedTable {...props} />

    )
}

export default SalesInformation