import Api from "../../../../../../../services/apiCallsHandler";
import CachingStorage from "../../../../../../../services/cacheStorage";
import { endpoints } from "./AppraisalEndpoints";

const api = Api.getApi();
const applicationIdErrorMessage: string = "Application Id Not Found";
const somthingwentWrong: string = "Error Encountered..!";
const applicationIdQueryParamVar = "?applicationId=";

export const endPointKeys = {
  profitLossAccountsData: "profitLossAccountsData",
  auditProfitLossAccountsData: "auditProfitLossAccountsData"
}

//_____________________________________________________________________________________________________________________________________________________
//___________________________________________________________________GET APIS__________________________________________________________________________

export const getProfitLossAccountsData = async (applicationId, isTypeAudit = false) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }
    let endpoint = isTypeAudit ? endpoints.auditProfitLossAccountsData : endpoints.profitLossAccountsData;

    let keyOfEndPoint = isTypeAudit ? endPointKeys.auditProfitLossAccountsData : endPointKeys.profitLossAccountsData;

    const response: any = await CachingStorage.invoke(`${applicationId}-${keyOfEndPoint}`, () => api.get(`${endpoint}${applicationIdQueryParamVar}${applicationId}`));

    return response;

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: somthingwentWrong
    }
  }
};

export const getBalanceSheet = async (applicationId, isTypeAudit = false) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }
    let endpoint = isTypeAudit ? endpoints.auditBalanceSheetData : endpoints.balanceSheetData;

    const response = await api.get(`${endpoint}${applicationIdQueryParamVar}${applicationId}`);

    return {
      status: response.status,
      data: response.data,
    };

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: somthingwentWrong
    }
  }
};

export const getForcastedData = async (applicationId) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }
    const response = await api.get(`${endpoints.forCastedData}${applicationIdQueryParamVar}${applicationId}`);

    return {
      status: response.status,
      data: response.data,
    };

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: somthingwentWrong
    }
  }
};

export const getSalesAndPurchaseInformations = async (applicationId) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }
    const response = await api.get(`${endpoints.salesAndPurchaseInformations}${applicationIdQueryParamVar}${applicationId}`);

    return {
      status: response.status,
      data: response.data,
    };

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: somthingwentWrong
    }
  }
};

export const getAbilitytoproposeloanData = async (applicationId) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }
    const response = await api.get(`${endpoints.abilitytoproposeloanData}${applicationIdQueryParamVar}${applicationId}`);

    return {
      status: response.status,
      data: response.data,
    };

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: somthingwentWrong
    }
  }
};

export const getRatioAnalysis = async (applicationId) => {
  try {
    if (!applicationId) {
      return {
        status: -1,
        data: {},
        error: applicationIdErrorMessage
      };
    }

    const { data } = await api.get(`${endpoints.ratioAnalysis}${applicationIdQueryParamVar}${applicationId}&reports=${["managementFinances", "auditedFinances"]}`);
    // const { data } = dummy
    return data;
  } catch (error) { }
};

export const getExistingComments = async (applicationId) => {
  try {
    const { status, data = [] } = await api.get(endpoints.loadExistingComments, { applicationId });
    if (status === 1) {
      return data;
    }

  } catch (error) {
    return {
      status: -1,
      data: {},
      error: applicationIdErrorMessage
    };
  }
}

export const getInspectionBusinesData = async (applicationId) => {
  let { INSPECTION_DATA: inspectionData = {} }: any = await CachingStorage.invoke(`${applicationId}-appraisal-inpections-data`, () => api.get(`${endpoints.getInspectionFilledPendingDate}${applicationId}`));

  if (!inspectionData) {
    CachingStorage.clear(`${applicationId}-appraisal-inpections-data`);
    return {}
  }

  try {
    inspectionData = JSON.parse(inspectionData);
  } catch (error) { }

  let { business = {} } = inspectionData;

  return (typeof business === "object" && Object.keys(business).length) ? business : undefined;
}


//_____________________________________________________________________________________________________________________________________________________
//___________________________________________________________________POST APIS__________________________________________________________________________


export const saveProfitLossAccountsData = async (toBeSaveData, isTypeAudit = false) => {
  try {
    let endpoint = isTypeAudit ? endpoints.auditProfitLossAccountsData : endpoints.profitLossAccountsData;

    const data = await api.post(`${endpoint}`, { ...toBeSaveData });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const saveBalanceSheetData = async (toBeSaveData, isTypeAudit = false) => {

  try {
    let endpoint = isTypeAudit ? endpoints.auditBalanceSheetData : endpoints.balanceSheetData;

    const data = await api.post(`${endpoint}`, { ...toBeSaveData });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const saveForecastedCashFlowData = async (toBeSaveData) => {
  try {
    const data = await api.post(`${endpoints.forCastedData}`, { ...toBeSaveData });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const saveSalesAndPurchaseInformations = async (toBeSaveData) => {
  try {
    const data = await api.post(`${endpoints.salesAndPurchaseInformations}`, { ...toBeSaveData });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const saveAbilitytoproposeloanData = async (toBeSaveData) => {
  try {
    const data = await api.post(`${endpoints.abilitytoproposeloanData}`, { ...toBeSaveData });
    return data;
  } catch (error) {
    console.error(error);
  }
};