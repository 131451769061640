import { standardDateFormatter } from "los-util";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

export const MachineryEquipmentsMain = (props) => {
  const { t: translate } = useTranslation();

  const { mmValueOfSecurity, mmSecuredValue, mmDescription, mmMachineType, mmForceSaleValue, mmChasisNumber, mmEngineNumber, mmHPOrLienNotedOn, mmMakeHpOrCc, mmAddress, mmDistrict, mmCity, mmManufacturerName, mmManufactureCode, mmYearOfManufacture, mmRegistrationNumber, mmExpiryDate, mmPurchasedYear, mmCondition, mmSourceOfPower, mmSupplierOrDealerName, mmSupplierAddress, mmSuppliersDistrict, mmSuppliersCity, mmThirdPartyDealerCode, mmInvoiceNumber, mmInvoiceDate, mmInvoicePurchaseValue, mmTaxType, mmTaxNumber, mmTaxAmount, mmLifespanNumberOfYears, mmLifespanNumberOfUnits, mmLifespanDepretiationRate, mmMarketValue, mmCountryOfManufacture, mmUsedPeriodAfterPurchased, mmModel, mmCapacity } = props?.props?.fields;

  const masterDataStore: any = MasterDataProvider.provideMasterData();
  const MasterDateValueState = {
    mmMachineTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MACHINE_TYPE),
    districtMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT),
    manufactureCodeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CODE),
    conditionMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CONDITIONS),
    mmThirdPartyDealerCodeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.THIRD_PARTY_DEALER_CODE),
    mmTaxTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE),
    mmSourceOfPowerMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.SOURCE_POWER),
  };
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={mmValueOfSecurity.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.props?.doc?.mmValueOfSecurity.get())}
            onChange={(e) => {
              props?.props?.doc?.mmValueOfSecurity.set(e.target.value);
            }}
            label={translate("Value of the Security")}
            onInput={OnDemandValueFormatter.numeric}
            required={mmValueOfSecurity.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmSecuredValue.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.props?.doc?.mmSecuredValue.get())}
            onChange={(e) => {
              props?.props?.doc?.mmSecuredValue.set(e.target.value);
            }}
            label={translate("Secured Value")}
            onInput={OnDemandValueFormatter.numeric}
            required={mmSecuredValue.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmDescription.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmDescription.get()}
            onChange={(e) => {
              props?.props?.doc?.mmDescription.set(e.target.value);
            }}
            label={translate("Description")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmDescription.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmMarketValue.visible}>
          <TextBoxComponent value={formatCurrency(props?.props?.doc?.mmMarketValue.get())} onChange={(e) => props?.props?.doc?.mmMarketValue.set(e.target.value)} label={translate("Market Value")} required={mmMarketValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
        </InputGrid>
        <InputGrid visibility={mmForceSaleValue.visible}>
          <TextBoxComponent value={formatCurrency(props?.props?.doc?.mmForceSaleValue.get())} onChange={(e) => props?.props?.doc?.mmForceSaleValue.set(e.target.value)} label={translate("Force Sale Value")} onInput={OnDemandValueFormatter.numeric} required={mmForceSaleValue.mandatory === 1 ? true : false} />
        </InputGrid>
      </InputFieldsStack>

      <InputFieldsStack>
        <InputGrid visibility={mmMachineType.visible}>
          <TextBoxComponent label={translate("Machine Type")} value={props?.props?.doc?.mmMachineType.get()} required={mmMachineType.mandatory === 1 ? true : false} onChange={(e) => props?.props?.doc?.mmMachineType.set(e.target.value)} />
        </InputGrid>
        <InputGrid visibility={mmChasisNumber.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmChasisNumber.get()}
            onChange={(e) => {
              props?.props?.doc?.mmChasisNumber.set(e.target.value);
            }}
            label={translate("Chasis Number")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmChasisNumber.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmEngineNumber.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmEngineNumber.get()}
            onChange={(e) => {
              props?.props?.doc?.mmEngineNumber.set(e.target.value);
            }}
            label={translate("Engine Number")}
            onInput={OnDemandValueFormatter.alphaNumeric}
            required={mmEngineNumber.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmModel.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmModel.get()}
            onChange={(e) => {
              props?.props?.doc?.mmModel.set(e.target.value);
            }}
            label={"Model"}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmModel.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmCapacity.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmCapacity.get()}
            onChange={(e) => {
              props?.props?.doc?.mmCapacity.set(e.target.value);
            }}
            label={"Capacity"}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmCapacity.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmHPOrLienNotedOn.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.props?.doc?.mmHPOrLienNotedOn.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.props?.doc?.mmHPOrLienNotedOn.set(preparedDate);
              }
            }}
            label={translate("HP/Lien Noted On (DD/MM/YYYY)")}
            enableFuture={true}
            disablePast={false}
            required={mmHPOrLienNotedOn.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmMakeHpOrCc.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmMakeHpOrCc.get()}
            onChange={(e) => {
              props?.props?.doc?.mmMakeHpOrCc.set(e.target.value);
            }}
            label={translate("Make & HP/CC")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmMakeHpOrCc.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmAddress.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmAddress.get()}
            onChange={(e) => {
              props?.props?.doc?.mmAddress.set(e.target.value);
            }}
            label={translate("Address")}
            onInput={OnDemandValueFormatter.any}
            required={mmAddress.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmDistrict.visible}>
          <SelectComponent
            label={translate("District")}
            value={props?.props?.doc?.mmDistrict.get()}
            values={MasterDateValueState.districtMasterData}
            required={mmDistrict.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmDistrict.set(e.target.value)}
            defaultPlaceholder={true}
          />
        </InputGrid>
        <InputGrid visibility={mmCity.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmCity.get()}
            onChange={(e) => {
              props?.props?.doc?.mmCity.set(e.target.value);
            }}
            label={translate("City")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={mmCity.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmCountryOfManufacture.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmCountryOfManufacture.get()}
            onChange={(e) => {
              props?.props?.doc?.mmCountryOfManufacture.set(e.target.value);
            }}
            label={translate("Country of Manufacture")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={mmCountryOfManufacture.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmUsedPeriodAfterPurchased.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmUsedPeriodAfterPurchased.get()}
            onChange={(e) => {
              props?.props?.doc?.mmUsedPeriodAfterPurchased.set(e.target.value);
            }}
            label={translate("Used Period After Purchased")}
            required={mmUsedPeriodAfterPurchased.mandatory === 1 ? true : false}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
          />
        </InputGrid>
        <InputGrid visibility={mmManufacturerName.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmManufacturerName.get()}
            onChange={(e) => {
              props?.props?.doc?.mmManufacturerName.set(e.target.value);
            }}
            label={translate("Manufacturer Name")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={mmManufacturerName.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmManufactureCode.visible}>
          <TextBoxComponent label={translate("Manufacturer Code")} value={props?.props?.doc?.mmManufactureCode.get()} values={MasterDateValueState.manufactureCodeMasterData} required={mmManufactureCode.mandatory === 1 ? true : false} onChange={(e) => props?.props?.doc?.mmManufactureCode.set(e.target.value)} />
        </InputGrid>
        <InputGrid visibility={mmYearOfManufacture.visible}>
          <NumericFormat
            label={"Year Of Manufacture"}
            customInput={TextBoxComponent}
            variant="outlined"
            thousandSeparator={false}
            value={props?.props?.doc?.mmYearOfManufacture.get()}
            decimalScale={2}
            required={mmYearOfManufacture.mandatory === 1 ? true : false}
            fullWidth={true}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.props?.doc?.mmYearOfManufacture.set(value);
            }}
            onInput={OnDemandValueFormatter.currentYearFormat}
          />
        </InputGrid>
        <InputGrid visibility={mmRegistrationNumber.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            thousandSeparator={true}
            value={props?.props?.doc?.mmRegistrationNumber.get()}
            decimalScale={2}
            required={mmRegistrationNumber.mandatory === 1 ? true : false}
            fullWidth={true}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.props?.doc?.mmRegistrationNumber.set(value);
            }}
            label={translate("Registration Number")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
          />
        </InputGrid>
        <InputGrid visibility={mmExpiryDate.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.props?.doc?.mmExpiryDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.props?.doc?.mmExpiryDate.set(preparedDate);
              }
            }}
            label={translate("Expiry Date (DD/MM/YYYY)")}
            enableFuture={true}
            disablePast={false}
            required={mmExpiryDate.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmPurchasedYear.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmPurchasedYear.get()}
            onChange={(e) => {
              props?.props?.doc?.mmPurchasedYear.set(e.target.value);
            }}
            label={translate("Purchased Year")}
            onInput={OnDemandValueFormatter.numeric}
            required={mmPurchasedYear.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmCondition.visible}>
          <SelectComponent
            label={translate("Condition")}
            value={props?.props?.doc?.mmCondition.get()}
            values={MasterDateValueState.conditionMasterData}
            required={mmCondition.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmCondition.set(e.target.value)}
            defaultPlaceholder={true}
          />
        </InputGrid>
        <InputGrid visibility={mmSourceOfPower.visible}>
          <SelectComponent
            label={translate("Source Of Power")}
            value={props?.props?.doc?.mmSourceOfPower.get()}
            values={MasterDateValueState.mmSourceOfPowerMasterData}
            required={mmSourceOfPower.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmSourceOfPower.set(e.target.value)}
            defaultPlaceholder={true}
          />
        </InputGrid>
      </InputFieldsStack>

      <InputFieldsStack>
        <InputGrid visibility={mmSupplierOrDealerName.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmSupplierOrDealerName.get()}
            onChange={(e) => {
              props?.props?.doc?.mmSupplierOrDealerName.set(e.target.value);
            }}
            label={translate("Supplier/Dealer Name")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmSupplierOrDealerName.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmSupplierAddress.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmSupplierAddress.get()}
            onChange={(e) => {
              props?.props?.doc?.mmSupplierAddress.set(e.target.value);
            }}
            label={translate("Supplier Address")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={mmSupplierAddress.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmSuppliersDistrict.visible}>
          <SelectComponent
            label={translate("District")}
            value={props?.props?.doc?.mmSuppliersDistrict.get()}
            values={MasterDateValueState.districtMasterData}
            required={mmSuppliersDistrict.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmSuppliersDistrict.set(e.target.value)}
            defaultPlaceholder={true}
          />
        </InputGrid>
        <InputGrid visibility={mmSuppliersCity.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmSuppliersCity.get()}
            onChange={(e) => {
              props?.props?.doc?.mmSuppliersCity.set(e.target.value);
            }}
            label={translate("City")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={mmSuppliersCity.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmThirdPartyDealerCode.visible}>
          <TextBoxComponent
            label={translate("Third Party Delaer Code")}
            value={props?.props?.doc?.mmThirdPartyDealerCode.get()}
            values={MasterDateValueState.mmThirdPartyDealerCodeMasterData}
            required={mmThirdPartyDealerCode.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmThirdPartyDealerCode.set(e.target.value)}
          />
        </InputGrid>
      </InputFieldsStack>

      <InputFieldsStack>
        <InputGrid visibility={mmInvoiceNumber.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmInvoiceNumber.get()}
            onChange={(e) => {
              props?.props?.doc?.mmInvoiceNumber.set(e.target.value);
            }}
            label={translate("Invoice Number")}
            onInput={OnDemandValueFormatter.alphaNumeric}
            required={mmInvoiceNumber.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmInvoiceDate.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.props?.doc?.mmInvoiceDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.props?.doc?.mmInvoiceDate.set(preparedDate);
              }
            }}
            label={translate("Invoice Date (DD/MM/YYYY)")}
            enableFuture={false}
            disablePast={false}
            required={mmInvoiceDate.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmInvoicePurchaseValue.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.props?.doc?.mmInvoicePurchaseValue.get())}
            onChange={(e) => {
              props?.props?.doc?.mmInvoicePurchaseValue.set(e.target.value);
            }}
            label={translate("Invoice Purchase Value")}
            onInput={OnDemandValueFormatter.numeric}
            required={mmInvoicePurchaseValue.mandatory === 1 ? true : false}
          />
        </InputGrid>
      </InputFieldsStack>

      <InputFieldsStack>
        <InputGrid visibility={mmTaxType.visible}>
          <TextBoxComponent
            label={translate("Tax Type")}
            value={props?.props?.doc?.mmTaxType.get()}
            required={mmTaxType.mandatory === 1 ? true : false}
            onChange={(e) => props?.props?.doc?.mmTaxType.set(e.target.value)}
          />
        </InputGrid>
        <InputGrid visibility={mmTaxNumber.visible}>
          <TextBoxComponent
            value={props?.props?.doc?.mmTaxNumber.get()}
            onChange={(e) => {
              props?.props?.doc?.mmTaxNumber.set(e.target.value);
            }}
            label={translate("Tax Number")}
            onInput={OnDemandValueFormatter.alphaNumeric}
            required={mmTaxNumber.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={mmTaxAmount.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.props?.doc?.mmTaxAmount.get())}
            onChange={(e) => {
              props?.props?.doc?.mmTaxAmount.set(e.target.value);
            }}
            label={translate("Tax Amount")}
            onInput={OnDemandValueFormatter.numeric}
            required={mmTaxAmount.mandatory === 1 ? true : false}
          />
        </InputGrid>
      </InputFieldsStack>
    </>
  );
};
