import { messageMappings } from "./messageMapping";
import { validatedFieldKey, validateWithStructure } from "./structureValidation";

export const addressDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let finalKeyArr: string[] = validateWithStructure(structure, state) || [];

  if (!state?.isCurrentAddressSame?.get()) {
    if (!state?.currentAddressData?.currentAddressLine1?.get()) {
      finalKeyArr[finalKeyArr.length] = "currentAddressLine1";
    }
    if (!state?.currentAddressData?.currentAddressLine2?.get()) {
      finalKeyArr[finalKeyArr.length] = "currentAddressLine2";
    }
  }

  if (state?.isMailingAddressSame?.get() === 0) {
    if (!state?.mailingAddressData?.mailAddressLine1?.get()) {
      finalKeyArr[finalKeyArr.length] = "mailAddressLine1";
    }
    if (!state?.mailingAddressData?.mailAddressLine2?.get()) {
      finalKeyArr[finalKeyArr.length] = "mailAddressLine2";
    }
  }

  const currentResidenceTimePeriod = structure.content.find(({ key }) => key === "other-address-info")?.fields?.currentResidenceTimePeriod || undefined;

  if (currentResidenceTimePeriod && currentResidenceTimePeriod.mandatory) {
    if (!(finalKeyArr.includes("currentResidenceYears") || finalKeyArr.includes("currentResidenceMonths") || finalKeyArr.includes("currentResidenceTimePeriod"))) {
      let retuedCurrentResidenceYears = validatedFieldKey(state?.currentResidenceYears?.get(), "currentResidenceYears");
      let retuedCurrentResidenceMonths = validatedFieldKey(state?.currentResidenceMonths?.get(), "currentResidenceMonths");
      finalKeyArr = (retuedCurrentResidenceYears && retuedCurrentResidenceMonths) ? [...finalKeyArr, "currentResidenceTimePeriod"] : finalKeyArr;
    }
  }

  const preparedErrorMessages = messageMappings(finalKeyArr);
  return preparedErrorMessages;
};
