import { useState } from "@hookstate/core";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { updatePredisbursmentData } from "../../../../../services/creditFileApiCall";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { responseSuccessCode } from "../../../../../utility/other";
import { defaultOptions } from "../../../../../utility/util";
import { addToaster } from "../../../GlobalToast";
import { IconRowConditionComponent } from "../IconRowConditionComponent";

const PredisbursementItemRow = (params) => {
  const { data, disbursed, applicationId, currentApplicationWfData, innerComponentData, setNewDataArray, creditFileData, actionDelete, disableAccordingTo, trialCalcResult, trialCalcParams, recommendedInterestRate, regularPaymentFactor } = params;

  const { wf_system_users }: any = useState(workflowMasterData);

  const isExpand = useState(false);
  const color = defaultOptions?.color;
  const createdDate = data.createdDate ? dateFormatWithTimeChange(data.createdDate) : "";
  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == data?.createdBy);

  const [isApproved, setIsApproved] = React.useState<any>(0);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const approvePD = async (e: any) => {
    setIsLoading(true);
    setIsApproved(e.target.checked);

    const approveState = e.target.checked;

    const stateData = {
      approveState: approveState,
      id: data?.id,
    };

    const res = await updatePredisbursmentData(stateData);

    setIsLoading(false);

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }
  };

  useEffect(() => {
    setIsApproved(data?.approveState);
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      className="row_list_item"
      p={2}
      key={data.sequence}
      style={{
        backgroundColor: `${color}3d`,
      }}
    >
      <Grid container className="full-width" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <IconRowConditionComponent Icon={RadioButtonCheckedIcon} subTxt={data?.TITLE} mainTxt={data?.DESCRIPTION} color={color} data={data} applicationId={applicationId} setNewDataArray={setNewDataArray} creditFileData={creditFileData} actionDelete={actionDelete} disableAccordingTo={disableAccordingTo} trialCalcResult={trialCalcResult} trialCalcParams={trialCalcParams} conditionType={"approvingCondition"} recommendedInterestRate={recommendedInterestRate}
            regularPaymentFactor={regularPaymentFactor} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PredisbursementItemRow;
