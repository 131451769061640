import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";
import ScvDetails from "./ScvDetails";


const ScvDetailsContainer = (params) => {
  const { t: translate } = useTranslation();

  if (Object.keys(params.scvData).length === 0) {
    return <ErrorMessageComponent headMessage={translate("errorMsg.noData")} errorMessage={""} />
  }

  return <Stack p={2}> <ScvDetails params={params} /></Stack>
};
export default ScvDetailsContainer;
