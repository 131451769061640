import { Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import CachingStorage from "../../../../../../../../services/cacheStorage";
import { getDetailsOfAnalysis } from "../../../../../../../../services/creditFileApiCall";
import { openGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../../../../InnerSectionHeaderComponent";
import { disableAccess } from "../../../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import MarketAnalysisItemRowComponent from "./MarketAnalysisItemRowComponent";

const MarketAnalysis = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
    const [marketAnalysisColumns, setMarketAnalysisColumns] = React.useState([]);
    const [loading, setLoading]: any = React.useState(false);
    const checkPrivilegeStatus = disableAccess({
        isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
    });

    useEffect(() => {
        getDocumentsListData()
    }, [])

    const getDocumentsListData = async () => {
        setLoading(true);
        const marketAnalysis = await getDetailsOfAnalysis(applicationId);
        setMarketAnalysisColumns(marketAnalysis)
        setLoading(false);
    };

    const viewAnalysis = async (id, mode, title) => {

        const getItemData = async () => {

            try {
                const detail = await getDetailsOfAnalysis(applicationId, id);
                const item = detail?.[0]
                return { description: item?.DATA, createdBy: item?.CREATED_BY, updatedAt: item?.UPDATED_DATE ? item?.UPDATED_DATE : item?.CREATED_DATE }
            } catch (error) {

            } finally {
            }
        }

        let getDraft = () => {
            const cacheKey = "market_analysis" + id + applicationId;

            return CachingStorage.read(cacheKey)?.data?.length > 0 ? " (Draft)" : "";
        };

        openGlobalModal({
            modalSize: "xl",
            title: title + getDraft(),
            bodyId: "view-market-analysis",
            close: false,
            modalParams: {
                id,
                getItemData,
                mode,
                applicationId,
                reloadComponent: getDocumentsListData,
            },
        });
    }

    return (
        <>
            {
                loading ? (
                    <Stack style={{ height: 100 }} alignItems={"center"} width={"100%"} justifyContent={"center"}>
                        {" "}
                        <CircularProgressComponent size={24} />{" "}
                    </Stack>
                ) : (
                    <div className="full-width">
                        <InnerSectionHeaderComponent
                            innerComponentData={innerComponentData}
                            applicationId={applicationId}
                            currentApplicationWfData={currentApplicationWfData}
                        />
                        {marketAnalysisColumns?.map((item: any, index) => (
                            <Paper sx={{ mt: 2, mx: 2 }} elevation={1} key={index}>
                                <MarketAnalysisItemRowComponent
                                    title={item.title}
                                    viewAnalysis={viewAnalysis}
                                    columnKey={item.key}
                                    description={item.description}
                                    disableStatus={checkPrivilegeStatus}
                                />
                            </Paper>
                        ))}
                    </div>
                )
            }
        </>
    )
}

export default MarketAnalysis;