import { Divider, Paper, Stack } from "@mui/material";
import { FC } from "react";

import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { getUserDetailsById } from "../../../../services/userService";
import { dateFormatWithTimeChange } from "../../../../utility/fomatters";
import { DocumentPreviewRow } from "./DocumentPreviewRow";

import { readStaticDocumentPath } from "../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";

export const DocumentSubflowPreviewComponent: FC = (params?: any) => {
  const { filePath } = params?.item || {};

  const previewClicked = (blobUrl) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const viewDocumentFile = () => {
    previewClicked(readStaticDocumentPath(filePath));
  };

  return (
    <Paper elevation={0}>
      <Stack pt={2} spacing={2} height={"calc(100vh - 200px)"}>
        <Stack flex={1}><iframe onContextMenu={(e) => e.preventDefault()} style={{ border: "none" }} src={readStaticDocumentPath(filePath)} height={"100%"} width={"100%"}></iframe></Stack>

        <Divider />

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <ButtonComponent
              title={"Preview Document"}
              variant="contained"
              onClick={() => {
                viewDocumentFile();
              }}
            />
          </Stack>

          <Stack direction={"row"}>
            <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={params?.item?.actionBy ? getUserDetailsById(params?.item?.actionBy)["EN"]?.name : "--"} status={params?.item?.action} />
            <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={params?.item?.timestamp ? dateFormatWithTimeChange(params?.item?.timestamp) : "--"} status={params?.item?.action} />
          </Stack>
        </Stack>

        <Divider />

      </Stack>
    </Paper>
  );
};
