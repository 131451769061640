import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export default function ApparaisalTabularComponent(props) {

  const {
    currentHorizintalTabKey,
    allTabComponents,
    onChange,
    formData: data,
    isAmountBelowTwoPointFive,
    title
  } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentHorizintalTabKey.get()}
          aria-label="appraisal tabs"
          indicatorColor="secondary"
          onChange={onChange}
        >
          {Object.keys(title).map((key) => {

            if (isAmountBelowTwoPointFive && ["2", "3"].includes(key)) {
              return <></>
            }
            else {
              return <Tab
                key={title[key]}
                label={title[key]}
                {...a11yProps(key)}
              />
            }
          }
          )}
        </Tabs>
      </Box>

      {Object.keys(allTabComponents).map((key: string) => (
        <AppraisalTabPanel
          key={"AppraisalTabPanel" + allTabComponents[key].toString()}
          value={currentHorizintalTabKey.get()}
          index={key}
        >
          {React.createElement(allTabComponents[currentHorizintalTabKey.get()], { ...props, data })}
        </AppraisalTabPanel>
      ))}
    </Box>
  );
}

function a11yProps(index: string) {
  return {
    id: `appraisal-tab-${index}`,
    "aria-controls": `appraisal-tabpanel-${index}`,
  };
}

function AppraisalTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`appraisal-tabpanel-${index}`}
      aria-labelledby={`appraisal-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
