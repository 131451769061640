import { v4 as uuidv4 } from 'uuid';
import hash from 'object-hash';

export const generateUuid = (): string => uuidv4();

export const responseSuccessCode = 1;
export const notCompleteSuccessCode = 2;
export const responseFailCode = -1;
export const responseErrorCode = -2;

export const compare = (a, b, property) => {
  if (a[property] < b[property]) {
    return -1;
  }
  if (a[property] > b[property]) {
    return 1;
  }
  return 0;
};

export const hashValue = (input) => {
  return hash(input, { algorithm: 'md5' });
};

export const trimString = (value, length = 20) => {
  return value?.length > length ? `${value.slice(0, length)}...` : value;
};
