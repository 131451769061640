import { endWidgets, thirdPartyComponants } from "./widgetsList";

export const searchAndVerifyWidget = (WidgetKeyMember: string) => {

  if (!WidgetKeyMember) {
    return endWidgets["textArea"];
  } else if (thirdPartyComponants.hasOwnProperty(WidgetKeyMember)) {
    return thirdPartyComponants[WidgetKeyMember];
  } else if (!endWidgets.hasOwnProperty(WidgetKeyMember)) {
    return endWidgets["textArea"];
  }

  return endWidgets[WidgetKeyMember];
};
