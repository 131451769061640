import React, { Fragment } from "react";
import { Box, Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { standardDateFormatter } from "los-util";
import { insuaranceCompany } from "../../../../../../../../configs/constants/contants";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { WhetherInsurancePolicySampledThirdPartyAction } from "./LifeInsuranceSubComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { ValidateWithSecuredValue } from "../../../../../../CreditFile/Components/Collateral/controllers/Controllers";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";

export const LifeInsurance = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore = MasterDataProvider.provideMasterData();

  const { liValueOfSecurity, liSecuredValue, liDescription, liIssuingAuthority, liRegistrationDate, liPolicyNumber, liDocumentNumber, liNoOfDocuments, liFaceValue, liMarketValue, liDueDate, liSurrendableValue, liInsuranceCompanyCode, liDateCommencementInsurance, liDateInsurancePolicyCertified, liDateConfirmationLetterInsurance, liDateConfirmationLetterAgeAccepted } = props?.fields;

  const componentName: string = `Life Insurance
  ${props.index > 0 ? "(" + (parseInt(props.index) + 1) + ")" : ""}`;

  return (
    <div className="full-width full-height">
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} p={2} className="securityWrapper">
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    {componentName}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={liValueOfSecurity?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.liValueOfSecurity?.get())} onChange={(e) => props?.doc?.liValueOfSecurity?.set(e.target.value)} label={translate("Value of the Security")} onInput={OnDemandValueFormatter.numeric} required={liValueOfSecurity?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={liSecuredValue?.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.liSecuredValue?.get())}
                    onChange={(e) => {
                      props?.doc?.liSecuredValue?.set(e.target.value);
                      props?.lifeInsuranceHandledValidationKeys(`lifeInsurance_${parseInt(props.index) + 1}`, "liSecuredValue", props?.doc?.liSecuredValue?.get());
                    }}
                    label={translate("Secured Value")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={liSecuredValue?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liDescription?.visible}>
                  <TextBoxComponent value={props?.doc?.liDescription?.get()} onChange={(e) => props?.doc?.liDescription?.set(e.target.value)} label={translate("Description")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={liDescription?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={liInsuranceCompanyCode?.visible}>
                  {props?.doc?.liInsuranceCompanyCode.get() && props?.doc?.liInsuranceCompanyCode.get().toString.length > 7 ? (
                    <TextBoxComponent
                      value={props?.doc?.liInsuranceCompanyCode.get()}
                      onChange={(e) => {
                        props?.doc?.liInsuranceCompanyCode.set(e.target.value);
                      }}
                      label={translate("Issuing Authority")}
                      onInput={OnDemandValueFormatter.alphaNumericSpaces}
                      required={liInsuranceCompanyCode?.mandatory === 1 ? true : false}
                    />
                  ) : (
                    <SelectComponent
                      label={translate("Issuing Authority")}
                      value={props?.doc?.liInsuranceCompanyCode.get()}
                      values={masterDataStore.getMasterData(MASTER_DATA_KEYS?.INSURANCE_COMPANY_TYPE)}
                      required={liInsuranceCompanyCode?.mandatory === 1 ? true : false}
                      onChange={(e) => {
                        props?.doc?.liInsuranceCompanyCode.set(e.target.value);
                      }}
                      defaultPlaceholder={true}
                    />
                  )}
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={liRegistrationDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liRegistrationDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liRegistrationDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Registration Date (DD/MM/YYYY)")}
                    enableFuture={false}
                    disablePast={false}
                    required={liRegistrationDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liPolicyNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.liPolicyNumber?.get()} onChange={(e) => props?.doc?.liPolicyNumber?.set(e.target.value)} label={translate("Policy Number")} onInput={OnDemandValueFormatter.alphaNumeric} required={liPolicyNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={liDocumentNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.liDocumentNumber?.get()} onChange={(e) => props?.doc?.liDocumentNumber?.set(e.target.value)} label={translate("Document Number")} onInput={OnDemandValueFormatter.alphaNumeric} required={liDocumentNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={liNoOfDocuments?.visible}>
                  <TextBoxComponent value={props?.doc?.liNoOfDocuments?.get()} onChange={(e) => props?.doc?.liNoOfDocuments?.set(e.target.value)} label={translate("No of Documents")} onInput={OnDemandValueFormatter.numeric} required={liNoOfDocuments?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={liFaceValue?.visible}>
                  <NumericFormat
                    label={translate("Face Value")}
                    customInput={TextBoxComponent}
                    variant="outlined"
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.liFaceValue?.get())}
                    decimalScale={2}
                    required={liFaceValue?.mandatory === 1 ? true : false}
                    fullWidth={true}
                    error={ValidateWithSecuredValue(props?.doc?.liFaceValue?.get(), props?.doc?.liSecuredValue?.get())}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.liFaceValue?.set(value);
                      props?.lifeInsuranceHandledValidationKeys(`lifeInsurance_${parseInt(props.index) + 1}`, "liFaceValue", props?.doc?.liFaceValue?.get());
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={liMarketValue?.visible}>
                  <NumericFormat
                    label={translate("Market Value")}
                    customInput={TextBoxComponent}
                    variant="outlined"
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.liMarketValue?.get())}
                    decimalScale={2}
                    required={liMarketValue?.mandatory === 1 ? true : false}
                    fullWidth={true}
                    error={ValidateWithSecuredValue(props?.doc?.liMarketValue?.get(), props?.doc?.liSecuredValue?.get())}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.liMarketValue?.set(value);
                      props?.lifeInsuranceHandledValidationKeys(`lifeInsurance_${parseInt(props.index) + 1}`, "liMarketValue", props?.doc?.liMarketValue?.get());
                    }}
                  />
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={liDueDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liDueDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liDueDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Due Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={true}
                    required={liDueDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liSurrendableValue?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.liSurrendableValue?.get())} onChange={(e) => props?.doc?.liSurrendableValue?.set(e.target.value)} label={translate("Surrendable Value")} required={liSurrendableValue?.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
                </InputGrid>
                <InputGrid visibility={liDateCommencementInsurance?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liDateCommencementInsurance?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liDateCommencementInsurance?.set(preparedDate);
                      }
                    }}
                    label={translate("Date Commencement Insurance")}
                    enableFuture={true}
                    disablePast={false}
                    required={liDateCommencementInsurance?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liDateInsurancePolicyCertified?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liDateInsurancePolicyCertified?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liDateInsurancePolicyCertified?.set(preparedDate);
                      }
                    }}
                    label={translate("The date on which the Insurance policy transportation value was certified (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={liDateInsurancePolicyCertified?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liDateConfirmationLetterInsurance?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liDateConfirmationLetterInsurance?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liDateConfirmationLetterInsurance?.set(preparedDate);
                      }
                    }}
                    label={translate("Date of Confirmation letter to sample the insurance (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={liDateConfirmationLetterInsurance?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={liDateConfirmationLetterAgeAccepted?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.liDateConfirmationLetterAgeAccepted?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.liDateConfirmationLetterAgeAccepted?.set(preparedDate);
                      }
                    }}
                    label={translate("Date of Confirmation letter that the age of the insured has been accepted by the insurance company (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={liDateConfirmationLetterAgeAccepted?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <WhetherInsurancePolicySampledThirdPartyAction props={props} />
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
