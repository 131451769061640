import { Grid } from "@mui/material";
import { addItem, AnnualAmount, invokeValueOverriding, renderingKeys, SectionTitle } from "./Controller";
import { ExitRemoveItem } from "../BusinessDetails/AthamaruProductBusinessDetails/Helpers/exitRemoveItem";

export const CreditCards = ({ data, masterData, title, calculator }) => {
  let payload = data?.creditCard || [];
  payload = new ExitRemoveItem(payload).removeRemovedItem();

  const BuildChild = ({ object }) => {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {renderingKeys["creditCards"]?.map((field) => {
            return addItem(object, field, invokeValueOverriding(field, masterData, object));
          })}

          <AnnualAmount>{calculator.calculate("creditcard", object)} (5% of the Limit)</AnnualAmount>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="full-width margin-xs-b">
      {payload.length > 0 ? (
        <>
          <SectionTitle>{title}</SectionTitle>
          {payload
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((object, index) => {
              return <BuildChild key={index} object={object} />;
            })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
