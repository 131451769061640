import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import CachingStorage from '../../../../../../services/cacheStorage'
import { OnDemandValueFormatter } from '../../../../../../services/onDemandValidators'
import { closeBtn } from '../../../../../../styles'
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent'
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent'
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent'
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal'

function LetterDepositPercentageModal(params) {
    const applicationId = params?.applicationId;
    const field = params?.name;

    const currentLocalStorage = CachingStorage.read('offerLetter') || {};
    const [depositPercentage, setDepositPercentage] = React.useState('');
    let initialData = { [applicationId]: { depositPercentage: depositPercentage } }

    useEffect(() => {
        if (currentLocalStorage) {
            if (Object.keys(currentLocalStorage).includes(applicationId.toString())) {
                setDepositPercentage(currentLocalStorage[applicationId.toString()].depositPercentage)
            }
        }
    }, [])

    const saveCondition = () => {
        if (currentLocalStorage) {
            if (Object.keys(currentLocalStorage).includes(applicationId.toString())) {
                currentLocalStorage[applicationId.toString()].depositPercentage = depositPercentage;
            }
            CachingStorage.store('offerLetter', { ...initialData, ...currentLocalStorage });
        } else {
            CachingStorage.store('offerLetter', initialData);
        }
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
            params.callBackOnAction()
        }
        closeGlobalModal()
    }

    const closeCondition = () => {
        closeGlobalModal()
    }

    return (
        <Box>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <div className="basic-font font-size-18 basic-font-color-bold">Deposit Percentage</div>
            </Stack>

            <Grid container spacing={2} mt={1} className="modal-text-field">
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="Percentage"
                        component={<Grid xs={5} item>
                            <TextBoxComponent
                                value={depositPercentage}
                                onChange={(e) => {
                                    setDepositPercentage(e.target.value)
                                }}
                                onInput={OnDemandValueFormatter.alphaNumeric}
                                variant="outlined"
                                size="small"

                            />
                        </Grid>} isMandatory={false} showErrors={false} />

                </Grid>
            </Grid>

            {/*  */}

            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
                <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={saveCondition} loadingbtn={true} color="info" />
            </Stack>
        </Box>
    )
}

export default LetterDepositPercentageModal