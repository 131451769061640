import { useState } from "@hookstate/core";
import { Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { workflowMasterData } from "../../../../../../../../configs/mainStore";
import { getHistoryDataForApplication } from "../../../../../../../../services/creditFileApiCall";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ChipsComponent } from "../../../../../../../InputComponents/ChipsComponent/ChipsComponent";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";

const RecommendedApprovedBy = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion }) => {
  const recommendedApprovedBy: any = useState([]);
  const isLoading = useState(false);
  const { wf_system_users, wf_actions }: any = useState(workflowMasterData);

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const data = await getHistoryDataForApplication(applicationId);
      if (data?.length > 0) {
        recommendedApprovedBy.set(data);
      }
      isLoading.set(false);
    })();
  }, []);

  const Header = () => {
    return <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 100, top: "0px" }} justifyContent={"center"}>
      <Typography className="basic-font basic-font-color-bold">Recommended By /Approved By</Typography>
    </Stack>;
  }

  const BuildListView = () => {
    const length = recommendedApprovedBy?.get()?.length;
    const [showContent, setShowContent] = React.useState<number>(-1);

    return <Stack>
      <Header />
      <Stack p={1}>
        {recommendedApprovedBy?.get()?.map((entry, index) => {
          const msg: any = entry?.note ? entry.note : "";
          const user = wf_system_users?.get()?.find((user) => user.id == entry.actionBy);
          const date = entry?.timeStamp ? entry.timeStamp : "";
          const actionItem = wf_actions?.get()?.find((action) => action.id == entry.action);
          const status = actionItem?.actionKey == "forward" ? "Recommended" : actionItem?.actionKey == "approve" ? "Approved" : "";
          const color = status == "Recommended" ? "info" : "success";

          return <Paper sx={{ mb: 2 }} key={index}>
            <Stack p={2}>
              <Stack flex={1} direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={1} direction={"row"} flex={1}>
                  <Typography variant="body1" color={"grey"}>
                    {length - index}.
                  </Typography>
                  <Stack flex={1}>
                    <Typography variant="body1" color={grey[700]} style={{ whiteSpace: "pre-line" }}>
                      {showContent === index ? (msg || "N/A") : (msg || "N/A").slice(0, 100)}
                    </Typography>
                    <Stack direction={"row-reverse"}>
                      {(msg || "N/A").length > 100 && <ButtonComponent title={showContent === index ? "less..." : "more..."} onClick={() => showContent > -1 && showContent === index ? setShowContent(-1) : setShowContent(index)}></ButtonComponent>}
                    </Stack>
                  </Stack>
                </Stack>

                {status &&
                  <ChipsComponent color={color} label={<Typography variant="caption">
                    {status.toUpperCase()}
                  </Typography>} />
                }
              </Stack>
              <Stack spacing={1} direction={"row"} mt={1}>
                <Typography variant="caption" color={"gray"}>
                  {user?.fullName}
                </Typography>
                <Typography variant="caption">
                  on
                </Typography>
                <Typography variant="caption" color={"gray"}>
                  {date ? dateFormatWithTimeChange(date) : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        })}
      </Stack>
    </Stack>;
  };

  if (isLoading.get()) {
    return <Stack style={{ backgroundColor: "#fff", height: "calc(100vh - 120pt)", overflowY: "hidden" }}>
      <Header />
      <Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <CircularProgressComponent size={30} sx={{ left: "50%", top: "50%" }} />
      </Stack>
    </Stack>;
  }

  return <BuildListView />;
};

export default RecommendedApprovedBy;
