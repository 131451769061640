import { Downgraded } from "@hookstate/core";
import { creditDetails, singleCustomerViewDetails } from "../../../configs/mainStore";
import { applyProductLevelFieldMeta } from "../../AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { messageMappings } from "./messageMapping";
import { validateWithStructure } from "./structureValidation";

export const singleCustomerViewDetailsValidator = (structure, state, isGuarantor = false, isJointBorrower = false, isSecuritySectionNext?) => {
  let singleCustomerViewData = singleCustomerViewDetails.attach(Downgraded).get();
  let { type, sector, scheme } = creditDetails.attach(Downgraded).get();
  const currentProductCatalog = [type, sector, scheme].join('-');
  let finalKeyArr: any = [];
  let errorArray = validateWithStructure(structure, state);
  const dataKeyAvailability = applyProductLevelFieldMeta({ primaryApplicant: !(isGuarantor || isJointBorrower), dataKey: "dorinDoraAccount", productCatalog: currentProductCatalog });

  if (singleCustomerViewData?.dorinDoraAccount === "" || singleCustomerViewData?.dorinDoraAccount === "" || singleCustomerViewData?.dorinDoraAccount === null || singleCustomerViewData?.dorinDoraAccount === undefined) {
    errorArray = dataKeyAvailability ? [...errorArray, "dorinDoraAccount"] : errorArray;
  }

  if (errorArray?.length > 0) {
    finalKeyArr = errorArray;
  }

  const preparedErrorMessages = messageMappings(finalKeyArr);

  return preparedErrorMessages;
};
