import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import {
  masterDataDetails
} from "../../../../../configs/mainStore";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";
import { formatCurrency } from "../../../../../services/currencyFormater";
import MandatoryFilterComponent from "../../../../DataValidationComponents/MandatoryFilterComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import AppConfig from "../../../../../utility/AppConfig";

const LifeInsuranceDetails = ({
  data,
  applicationId,
  mainTabData = { isAppraisal: false },
  isMainContactDetails = true,
  isRawVersion = false,
  printMode = false,
  innerComponentData,
  tabsToEdit,
}) => {
  const masterData: any = useState(masterDataDetails);
  const insuranceData: any = data?.formData?.lifeInsuranceData;

  return (
    <div
      className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"
        }`}
    >
      {isMainContactDetails && (!isRawVersion || printMode) && (
        <ApplicationHeader
          formData={data?.formData}
          requestId={data?.requestId}
          applicationId={applicationId}
          title={"Life Insurance Details"}
          isAppraisal={mainTabData?.isAppraisal}
          isRawVersion={isRawVersion}
          disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
        />
      )}
      {!isMainContactDetails && (
        <Grid
          container
          p={1}
          className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
          style={{ backgroundColor: "#e6f0fa" }}
        >
          Life Insurance Details
        </Grid>
      )}
      {insuranceData?.[0]?.insuranceCompany !== AppConfig.config.noInsurance ? (
        <Grid
          container
          className={
            isMainContactDetails
              ? "inner-component-height padding-sm"
              : "margin-xs-t"
          }
        >
          <Grid container>
            <Grid
              container
              className={`border-radius-5 ${isMainContactDetails
                ? isRawVersion
                  ? ""
                  : "sub-header-height"
                : ""
                }`}
            >
              <MandatoryFilterComponent
                items={insuranceData}
                mandatory={
                  [
                    "insuranceCompany",
                    "insuredAmount",
                    "monthlyPremium",
                    "beneficiary",
                  ] as never[]
                }
                options={{ title: "Life Insurance Details" }}
              >
                <Grid container>
                  {insuranceData
                      ?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)?.map((insuranceDataItem, index) => {
                        const sourceOfIncome =
                          masterData?.INSURANCE_COMPANY?.get()?.find(
                            (t) => t.id == insuranceDataItem?.insuranceCompany
                          );
                        return (
                          <Grid container m={1} className="data-box" key={index}>
                            <Grid container item p={1} spacing={1}>
                              <Grid item xs={3}>
                                <DetailItem
                                  title="Insurance Company"
                                  value={
                                    sourceOfIncome?.name
                                      ? sourceOfIncome.name
                                      : "-"
                                  }
                                  valueFontClass="font-size-14"
                                  titleFontClass="font-size-12"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem
                                  title="Insured Amount"
                                  value={
                                    insuranceDataItem?.insuredAmount
                                      ? `LKR ${formatCurrency(
                                        insuranceDataItem.insuredAmount
                                      )}`
                                      : "-"
                                  }
                                  valueFontClass="font-size-14"
                                  titleFontClass="font-size-12"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem
                                  title="Monthly Premium"
                                  value={
                                    insuranceDataItem?.monthlyPremium
                                      ? insuranceDataItem.monthlyPremium
                                      : "-"
                                  }
                                  valueFontClass="font-size-14"
                                  titleFontClass="font-size-12"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem
                                  title="Beneficiary"
                                  value={
                                    insuranceDataItem?.beneficiary
                                      ? insuranceDataItem.beneficiary
                                      : "-"
                                  }
                                  valueFontClass="font-size-14"
                                  titleFontClass="font-size-12"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                </Grid>
              </MandatoryFilterComponent>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <ErrorMessageComponent headMessage={""} errorMessage={"No Life Insurance"} showImg={false} />
      )}
    </div>
  );
};

export default LifeInsuranceDetails;
