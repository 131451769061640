import { Stack, Typography } from "@mui/material";
import React from "react";

const Lable = ({ value, onChange, metaData }) => {
  return (
    <Stack flex={1}>
      <Typography>{metaData?.title}</Typography>
    </Stack>
  );
};

export default Lable;
