import { Box, FormGroup, FormControlLabel, Checkbox, InputAdornment } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { useTranslation } from "react-i18next";
import { selectedBtn, deSelectedBtn } from "../../../../../../../configs/constants/styleValues";
import { OnDemandValueFormatter, OnDemandValueValidator } from "../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../utility/fomatters";
import { StyledButton } from "../../../../../../InputComponents/ButtonComponent/StyledButton";
import { SelectComponent } from "../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FormLabelComponent } from "../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../InputGrid/InputGrid";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { NumericFormat, PatternFormat } from "react-number-format";
import ValidateDatePicker from "../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";

const masterDataStore: any = MasterDataProvider.provideMasterData();

const MasterDateValueState = {
  natureofWarrantyMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.NATURE_OF_WARRANTY),
};
/**------------------------------------WARRANTY AVAILABLE------------------------------------- */
export const WarrantyAvailableSection = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Warranty Available ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWarrantyAvailable.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionWarrantyAvailable.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWarrantyAvailable.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionWarrantyAvailable.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionWarrantyAvailable.get() && <WarrantyAvailableInputsState props={props} />}
    </>
  );
};

export const WarrantyAvailableInputsState = ({ props }) => {
  const { t: translate } = useTranslation();
  const { natureWarranty, dealersName, dealersContactNumber, warrantyStartDate, warrantyEndDate } = props?.fields;

  const natureOfWarrentyDropdown = () => [
    { id: 1, name: "Nature Of Warrenty 1" },
    { id: 2, name: "Nature Of Warrenty 2" },
    { id: 3, name: "Nature Of Warrenty 3" },
  ];

  return (
    <InputFieldsStack>
      <InputGrid visibility={natureWarranty.visible}>
        {/* <SelectComponent
          label={translate("Nature Of Warrenty")}
          value={props?.doc?.natureWarranty.get()}
          values={natureOfWarrentyDropdown()}
          required={
            props?.doc?.actionWarrantyAvailable.get() === true ? true : false
          }
          onChange={(e) => props?.doc?.natureWarranty.set(e.target.value)}
          defaultPlaceholder={true}
        /> */}

        <TextBoxComponent label={translate("Nature Of Warrenty")} value={props?.doc?.natureWarranty.get()} required={props?.doc?.actionWarrantyAvailable.get() === true ? true : false} onChange={(e) => props?.doc?.natureWarranty.set(e.target.value)} />
      </InputGrid>
      <InputGrid visibility={dealersName.visible}>
        <TextBoxComponent
          value={props?.doc?.dealersName.get()}
          onChange={(e) => {
            props?.doc?.dealersName.set(e.target.value);
          }}
          label={translate("Dealers Name")}
          onInput={OnDemandValueFormatter.alphabeticalSpaces}
          required={props?.doc?.actionWarrantyAvailable.get() === true ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={dealersContactNumber.visible}>
        {/* <TextBoxComponent
          value={props?.doc?.dealersContactNumber.get()}
          onChange={(e) => {
            props?.doc?.dealersContactNumber.set(e.target.value);
          }}
          label={translate("Dealers Contact Number")}
          onInput={OnDemandValueFormatter.numeric}
          required={dealersContactNumber.mandatory === 1 ? true : false}
        /> */}
        <PatternFormat
          format="(###) ### ####"
          // key={contactNoKey}
          defaultValue={props?.doc?.dealersContactNumber.get()}
          label={translate("Dealers Contact Number")}
          fullWidth={true}
          allowEmptyFormatting={false}
          mask="_"
          customInput={TextBoxComponent}
          error={OnDemandValueValidator.phoneNumber(props?.doc?.dealersContactNumber.get())}
          required={dealersContactNumber.mandatory === 1 ? true : false}
          onValueChange={(values, _) => {
            props?.doc?.dealersContactNumber.set(values.value);
          }}
          isAllowed={(values) => {
            if (values.value && values.value.toString().length > 10) {
              return false;
            }
            return true;
          }}
        />
      </InputGrid>
      <InputGrid visibility={warrantyStartDate.visible}>
        <ValidateDatePicker
          value={formattedDate(props?.doc?.warrantyStartDate.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.warrantyStartDate.set(preparedDate);
            }
          }}
          label={translate("Warranty Start Date (DD/MM/YYYY)")}
          enableFuture={true}
          disablePast={false}
          required={props?.doc?.actionWarrantyAvailable.get() === true ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={warrantyEndDate.visible}>
        <ValidateDatePicker
          value={formattedDate(props?.doc?.warrantyEndDate.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.warrantyEndDate.set(preparedDate);
            }
          }}
          label={translate("Warranty End Date (DD/MM/YYYY)")}
          enableFuture={true}
          disablePast={false}
          required={props?.doc?.actionWarrantyAvailable.get() === true ? true : false}
        />
      </InputGrid>
    </InputFieldsStack>
  );
};
/**-----------------------------------INSURANCE AVAILBLE-------------------------------------- */

export const InsuranceAvailableActivateState = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Insurance Available ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionInsuranceAvailable.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionInsuranceAvailable.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionInsuranceAvailable.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionInsuranceAvailable.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionInsuranceAvailable.get() && <InsuranceAvailableInputsState props={props} />}
    </>
  );
};

export const InsuranceAvailableInputsState = ({ props }) => {
  const { t: translate } = useTranslation();
  const { insuranceCompanyName, insurancePolicyNumber, insuredAmount, policyExpiryDate, policyNextRenewalDate, insurancePrimium, benificiaryName, dateCommencementInsurance } = props?.fields;

  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={insuranceCompanyName.visible}>
          <TextBoxComponent
            value={props?.doc?.insuranceCompanyName.get()}
            onChange={(e) => {
              props?.doc?.insuranceCompanyName.set(e.target.value);
            }}
            label={translate("Insurance Company Name")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={props?.doc?.actionInsuranceAvailable.get() === true ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={insurancePolicyNumber.visible}>
          <TextBoxComponent
            value={props?.doc?.insurancePolicyNumber.get()}
            //value={props?.doc?.issuingAuthority}
            onChange={(e) => {
              props?.doc?.insurancePolicyNumber.set(e.target.value);
            }}
            label={translate("Insurance Policy Number")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={props?.doc?.actionInsuranceAvailable.get() === true ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={insuredAmount.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.doc?.insuredAmount.get())}
            onChange={(e) => {
              props?.doc?.insuredAmount.set(e.target.value);
            }}
            label={translate("Insured Amount")}
            onInput={OnDemandValueFormatter.numeric}
            required={props?.doc?.actionInsuranceAvailable.get() === true ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={policyExpiryDate.visible}>
          {/* <ValidateDatePicker
            value={formattedDate(props?.doc?.policyExpiryDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (
                date &&
                preparedDate !== null &&
                preparedDate !== "Invalid Date"
              ) {
                props?.doc?.policyExpiryDate.set(preparedDate);
              }
            }}
            label={translate("Policy Expiry Date")}
            enableFuture={true}
            disablePast={false}
            required={
              props?.doc?.actionInsuranceAvailable.get() === true ? true : false
            }
          /> */}

          <ValidateDatePicker
            value={formattedDate(props?.doc?.policyExpiryDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.doc?.policyExpiryDate.set(preparedDate);
              }
            }}
            label={translate("Policy Expiry Date (DD/MM/YYYY)")}
            enableFuture={true}
            disablePast={false}
            required={props?.doc?.actionInsuranceAvailable.get() === true ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={policyNextRenewalDate.visible}>
          {/* <ValidateDatePicker
            value={formattedDate(props?.doc?.policyNextRenewalDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (
                date &&
                preparedDate !== null &&
                preparedDate !== "Invalid Date"
              ) {
                props?.doc?.policyNextRenewalDate.set(preparedDate);
              }
            }}
            label={translate("Policy Next Renewal Date")}
            enableFuture={true}
            disablePast={false}
            required={policyNextRenewalDate.mandatory === 1 ? true : false}
          /> */}

          <ValidateDatePicker
            value={formattedDate(props?.doc?.policyNextRenewalDate.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.doc?.policyNextRenewalDate.set(preparedDate);
              }
            }}
            label={translate("Policy Next Renewal Date (DD/MM/YYYY)")}
            enableFuture={true}
            disablePast={false}
            required={policyNextRenewalDate.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={insurancePrimium.visible}>
          <TextBoxComponent
            value={formatCurrency(props?.doc?.insurancePrimium.get())}
            onChange={(e) => {
              props?.doc?.insurancePrimium.set(e.target.value);
            }}
            label={translate("Insurance Primium")}
            onInput={OnDemandValueFormatter.numeric}
            required={insurancePrimium.mandatory === 1 ? true : false}
          />
        </InputGrid>
      </InputFieldsStack>
      <InputFieldsStack>
        <InputGrid visibility={benificiaryName.visible}>
          <TextBoxComponent
            value={props?.doc?.benificiaryName.get()}
            onChange={(e) => {
              props?.doc?.benificiaryName.set(e.target.value);
            }}
            label={translate("Benificiary Name")}
            onInput={OnDemandValueFormatter.alphabeticalSpaces}
            required={benificiaryName.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={dateCommencementInsurance.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.doc?.dateCommencementInsurance.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.doc?.dateCommencementInsurance.set(preparedDate);
              }
            }}
            label={translate("Date of commencement of insurance (DD/MM/YYYY)")}
            enableFuture={true}
            disablePast={false}
            required={dateCommencementInsurance.mandatory === 1 ? true : false}
          />
        </InputGrid>
      </InputFieldsStack>
      <InputFieldsStack>
        <InputGrid visibility={benificiaryName.visible}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={props?.doc?.coveredPolicy.get()} required={false} color="primary" onChange={(e) => props?.doc?.coveredPolicy.set(!props?.doc?.coveredPolicy.get())} />} label={<FormLabelComponent>Covered Policy</FormLabelComponent>} />
          </FormGroup>
        </InputGrid>
      </InputFieldsStack>
    </>
  );
};

/**-----------------------------------DUPLICATE KEY RECEIVED-------------------------------------- */
export const DuplicateKeyReceivedActivateState = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponent>Duplicate Key Received ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionDuplicateKeyReceived.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionDuplicateKeyReceived.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionDuplicateKeyReceived.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionDuplicateKeyReceived.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
        {props?.doc?.actionDuplicateKeyReceived.get() && <DuplicateKeyReceivedInputsState props={props} />}
      </InputFieldsStack>
    </>
  );
};

export const DuplicateKeyReceivedInputsState = ({ props }) => {
  const { t: translate } = useTranslation();
  const { duplicateKeyNumber, lifespanNumberOfYears, lifespanNumberOfUnits, lifespanDepretiationRate } = props?.fields;
  return (
    <>
      <InputGrid visibility={duplicateKeyNumber.visible}>
        <TextBoxComponent
          value={props?.doc?.duplicateKeyNumber.get()}
          onChange={(e) => {
            props?.doc?.duplicateKeyNumber.set(e.target.value);
          }}
          label={translate("Duplicate Key Number")}
          onInput={OnDemandValueFormatter.alphaNumericSpaces}
          required={props?.doc?.actionDuplicateKeyReceived.get() === true ? true : false}
        />
      </InputGrid>
    </>
  );
};

/*---------------------------------------------------- PRE INSPECTION DETAILS--------------------------------------------*/

const PreInspectionDetailsComponent = ({ props }) => {
  const { nameOfTheOfficer, dateOPfInspection, pricePerUnit, qulityOfUnitTested, totalValueChecked, StockQualityDetails } = props?.fields;

  return (
    <InputFieldsStack>
      <InputGrid visibility={nameOfTheOfficer.visible}>
        <TextBoxComponent
          value={props?.doc?.nameOfTheOfficer.get()}
          onChange={(e) => {
            props?.doc?.nameOfTheOfficer.set(e.target.value);
          }}
          label={"Name of the Bank officer"}
          onInput={OnDemandValueFormatter.alphaNumericSpaces}
          required={nameOfTheOfficer.mandatory === 1 ? true : false}
        />
      </InputGrid>

      <InputGrid visibility={dateOPfInspection.visible}>
        <ValidateDatePicker
          value={formattedDate(props?.doc?.dateOPfInspection.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.dateOPfInspection.set(preparedDate);
            }
          }}
          label={"Date of the Inspection (DD/MM/YYYY)"}
          enableFuture={true}
          disablePast={false}
          required={dateOPfInspection.mandatory === 1 ? true : false}
        />
      </InputGrid>

      <InputGrid visibility={pricePerUnit.visible}>
        <NumericFormat
          customInput={TextBoxComponent}
          variant="outlined"
          // isNumericString={true}
          label={"Price Per Unit"}
          thousandSeparator={true}
          value={formatCurrency(props?.doc?.pricePerUnit.get())}
          decimalScale={2}
          onValueChange={(e: any) => {
            if (e) {
              props?.doc?.pricePerUnit.set(e.value);
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
          }}
          required={pricePerUnit.mandatory === 1 ? true : false}
          // isAllowed={withValueLimit}
        />
      </InputGrid>
      <InputGrid visibility={qulityOfUnitTested.visible}>
        <NumericFormat
          customInput={TextBoxComponent}
          variant="outlined"
          // isNumericString={true}
          label={"Quantity of units tested"}
          thousandSeparator={true}
          value={props?.doc?.qulityOfUnitTested.get()}
          required={qulityOfUnitTested.mandatory === 1 ? true : false}
          decimalScale={2}
          onValueChange={(e: any) => {
            if (e) {
              props?.doc?.qulityOfUnitTested.set(e.value);
            }
          }}
          // isAllowed={withValueLimit}
        />
      </InputGrid>
      <InputGrid visibility={totalValueChecked.visible}>
        <NumericFormat
          customInput={TextBoxComponent}
          variant="outlined"
          // isNumericString={true}
          label={"Total value checked"}
          thousandSeparator={true}
          value={props?.doc?.totalValueChecked.get()}
          required={totalValueChecked.mandatory === 1 ? true : false}
          decimalScale={2}
          onValueChange={(e: any) => {
            if (e) {
              props?.doc?.totalValueChecked.set(e.value);
            }
          }}
        />
      </InputGrid>

      <InputGrid visibility={StockQualityDetails.visible}>
        <TextBoxComponent
          value={props?.doc?.StockQualityDetails.get()}
          onChange={(e) => {
            props?.doc?.StockQualityDetails.set(e.target.value);
          }}
          label={"Name of the Bank officer"}
          onInput={OnDemandValueFormatter.alphaNumericSpaces}
          required={StockQualityDetails.mandatory === 1 ? true : false}
        />
      </InputGrid>
    </InputFieldsStack>
  );
};
