import { Breakpoint, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack } from "@mui/material";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";

interface ModalWrapperProps {
    openModal: boolean;
    setOpenModal: (boolean) => void;
    handleSubmit: () => void;
    dialogTitle?: string;
    dialogContentText?: string;
    children?: JSX.Element;
    maxWidth?: Breakpoint;
}


const ModalWrapper: React.FC<ModalWrapperProps> = ({ openModal, setOpenModal, handleSubmit, dialogTitle, dialogContentText, maxWidth, children }: ModalWrapperProps) => {

    const handleClose = () => setOpenModal(false);

    return (
        <div>
            <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={maxWidth}>
                {/* <Stack direction={"row-reverse"} pt={2} pr={2}>
                    <Tooltip title="Close" style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon color="error" fontSize="medium" />
                    </Tooltip>
                </Stack> */}
                <DialogTitle id="form-dialog-title" color={"GrayText"}>{dialogTitle}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack width={"100%"}>
                        <DialogContentText>
                            {dialogContentText}
                        </DialogContentText>
                    </Stack>
                    {children}
                </DialogContent>
                <DialogActions>
                    <ButtonComponent title="CLOSE" variant="outlined" onClick={handleClose} loadingbtn={true} color="primary" />
                    <ButtonComponent title="DONE" variant="contained" onClick={() => {
                        handleSubmit()
                        handleClose()
                    }} loadingbtn={true} color="primary" />
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalWrapper
