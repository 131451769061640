import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function PriorLeadListComponent({ docList, previewBtnClicked }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    const { targetValue } = e.currentTarget.dataset;
    previewBtnClicked(targetValue);
    setAnchorEl(null);
  };

  return (
    <>
      {docList?.length > 0 ? <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {docList &&
            docList?.map((item: any) => {
              return (
                <MenuItem onClick={handleClose} data-target-value={item?.ID}>
                  Document {item?.DOC_INDEX + 1}
                </MenuItem>
              );
            })}
        </Menu>
      </div> : <></>}
    </>
  );
}
