import styled from "@emotion/styled";
import { createState, Downgraded, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { generateUuid } from "../../utility/other";
import { ChipsComponent } from "../InputComponents/ChipsComponent/ChipsComponent";

const TOAST_TIME_TO_LIVE = 5000;
const MAX_SHOWING_TOAST_COUNT = 5;

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
`;

interface ToastsProps {
  id: string;
  severity: any;
  title: string;
  message: any;
  manualClosing?: Boolean;
  meta?: any
}

export const toastNotification = createState<ToastsProps[]>([]);

export class Toast {
  static error(message, title = "") {
    addToaster({ status: "error", message, title });
  }

  static success(message: string, title = "") {
    addToaster({ status: "success", message, title });
  }

  static warning(message, title = "") {
    addToaster({ status: "warning", message, title });
  }
}

export const toast = ({ status = "success", message, manualClosing = false }) => {
  const toastList: ToastsProps[] = toastNotification.attach(Downgraded).get();

  manualClosing === true && (toastList.length = 0);

  // Check for user preferences to set close type
  if (localStorage.getItem("userPreferences") != null && localStorage.getItem("userPreferences") != "") {
    const userPreferences: any = JSON.parse(localStorage.getItem("userPreferences") || "");
    if (userPreferences) {
      if (userPreferences.notification == 1) {
        manualClosing = false;
      } else if (userPreferences.notification == 2) {
        manualClosing = true;
      }
    }
  }

  toastList.unshift({
    id: generateUuid(),
    severity: status,
    title: status === "error" ? "Error" : "Success",
    message: message,
    manualClosing: manualClosing,
  });

  toastNotification.set(toastList);
};

export const addToaster = ({ status, title, message, manualClosing = false }) => {
  const toastList: ToastsProps[] = toastNotification.attach(Downgraded).get();

  manualClosing === true && (toastList.length = 0);

  // Check for user preferences to set close type
  if (localStorage.getItem("userPreferences") != null && localStorage.getItem("userPreferences") != "") {
    const userPreferences: any = JSON.parse(localStorage.getItem("userPreferences") || "");
    if (userPreferences) {
      if (userPreferences.notification == 1) {
        manualClosing = false;
      } else if (userPreferences.notification == 2) {
        manualClosing = true;
      }
    }
  }

  toastList.unshift({
    id: generateUuid(),
    severity: status,
    title: title,
    message: message,
    manualClosing: manualClosing,
  });

  toastNotification.set(toastList);
};

export const addWfTransferToaster = ({ status, title, message, manualClosing = false, applicationId }) => {
  const toastList: ToastsProps[] = toastNotification.attach(Downgraded).get();

  manualClosing === true && (toastList.length = 0);

  // Check for user preferences to set close type
  if (localStorage.getItem("userPreferences") != null && localStorage.getItem("userPreferences") != "") {
    const userPreferences: any = JSON.parse(localStorage.getItem("userPreferences") || "");
    if (userPreferences) {
      if (userPreferences.notification == 1) {
        manualClosing = false;
      } else if (userPreferences.notification == 2) {
        manualClosing = true;
      }
    }
  }

  toastList.unshift({
    id: generateUuid(),
    severity: status,
    title: title,
    message: message,
    manualClosing: manualClosing,
    meta: applicationId
  });

  toastNotification.set(toastList);
};

const GlobalToast: React.FC = () => {
  const toastList = useState<ToastsProps[]>(toastNotification);

  const dismissedToasts = useRef<string[]>([]);

  useEffect(() => {
    const newToasts = toastNotification
      .attach(Downgraded)
      .get()
      .map((t) => t)
      .filter(({ id }) => !dismissedToasts.current.includes(id));

    newToasts.forEach((t) => {
      t.manualClosing === false &&
        setTimeout(() => {
          removeToaster(t.id);
        }, TOAST_TIME_TO_LIVE);

      dismissedToasts.current.push(t.id);
    });
  }, [toastList.get()]);

  const closeToaster = (tId) => {
    removeToaster(tId);
  };

  const removeToaster = (tId) => {
    const myArr = toastNotification.get().filter(function (item) {
      return item.id != tId;
    });

    toastNotification.set(myArr);
    toastList.set(myArr);

    const index = dismissedToasts.current.indexOf(tId);

    dismissedToasts.current.splice(index, 1);
  };

  let applicationId;
  const { meta } = toastList?.[0]?.get() || {};
  if (meta) {
    applicationId = meta;
  }

  const navigteToFile = (applicationId) => {
    if (applicationId) {
      sessionStorage.currentApplication = applicationId;
    } else {
      delete sessionStorage.currentApplication;
    }
    window.location.reload();
  }

  return (
    <Container style={{ zIndex: 9999 }}>
      {toastList
        .get()
        .slice(0, MAX_SHOWING_TOAST_COUNT)
        .map((toast) => (
          <Stack
            key={toast.id}
            sx={{
              width: "100%",
              minWidth: "350px",
              maxWidth: "350px",
              position: "relative",
            }}
            spacing={2}
          >
            <Alert style={{ margin: "2px", whiteSpace: "pre-line", maxHeight: "50vh", overflow: "auto", scrollBehavior: "smooth" }} variant="filled" severity={toast?.severity}>
              <AlertTitle>{toast.title}</AlertTitle>

              {Array.isArray(toast.message) && toast.message.length > 0
                ? toast.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
                : toast.message}

              <CloseIcon
                onClick={() => {
                  closeToaster(toast.id);
                }}
                style={{
                  color: "#ffffff50",
                  fontSize: 20,
                  cursor: "pointer",
                  textAlign: "right",
                  position: "absolute",
                  top: 5,
                  right: 5,
                }}
              />

              {applicationId && <Stack direction={"row-reverse"} px={1} pt={2}> <ChipsComponent size={"small"} variant={"primary"} label={"View"} onClick={() => navigteToFile(applicationId)}></ChipsComponent> </Stack>}
            </Alert>
          </Stack>
        ))}
    </Container>
  );
};

export default GlobalToast;
