import { Box } from "@mui/material";
import { INPUT_GROUP } from "../../../configs/constants/styleValues";
import { inputGroup } from "../../../styles";

export const InputGroup = ({
  children,
  title = "",
  style = {},
  padding = INPUT_GROUP.padding,
  margin = INPUT_GROUP.margin,
  marginTop = INPUT_GROUP.marginTop,
}) => {
  return (
    <Box
      sx={Object.keys(style).length > 0 ? style : inputGroup}
      p={padding}
      m={margin}
      mt={marginTop}
    >
      {/* //title */}
      {title.length > 0 && (
        <Box marginBottom={1}>
          <small className="input-label-basic">{title}</small>
        </Box>
      )}

      {/* //content */}
      {children}
    </Box>
  );
};
