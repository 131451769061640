import { State } from "@hookstate/core";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useMemo } from "react";
import { TabPanelProps } from "../../../../../../configs/interfaces";
import { LengthItemValidate } from "../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";

const MultipleObjectArrayHandler = ({ data, titleMapping, currentId, componentMapping, applicationId, onSubmit, dataItemArray, otherParams }) => {
  // @ts-ignore
  const dataObjectArray: State<Array<any>> = data;

  const props = useMemo(() => {
    return {
      ItemArray: LengthItemValidate.validatedArray(dataObjectArray),
      dataItemArray,
      currentId: currentId.get(),
      titleMapping,
      applicationId,
      componentMapping,
      onSubmit,
      otherParams,
    };
  }, [currentId.get()]);

  if (!dataObjectArray || !dataObjectArray.length) {
    return <></>;
  }

  return (
    <React.Fragment>
      <BuildContent {...props} />
    </React.Fragment>
  );
};

export default MultipleObjectArrayHandler;

const BuildContent = ({ ItemArray, dataItemArray, titleMapping, currentId, componentMapping, applicationId, onSubmit, otherParams }) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    return setValue(0);
  }, [currentId]);

  const handleSubmit = (dataObject: any, dataObjectIndex: number) => {
    dataItemArray[dataObjectIndex] = dataObject;
    onSubmit(dataItemArray);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          textTransform: "none",
          fontSize: "16px",
          fontFamily: "Poppins, Medium",
          borderRight: 1,
          borderColor: "divider",
          alignItems: "flex-start",
        }}
      >
        {ItemArray.map((item, index) => (
          <Tab key={`${index}_Tab_${currentId}`} label={index === 0 ? titleMapping[currentId] : titleMapping[currentId] + " - " + (index + 1)} {...a11yProps(index, currentId)} />
        ))}
      </Tabs>

      {ItemArray.map((item: any, index: number) => {
        const props = {
          title: index === 0 ? titleMapping[currentId] : titleMapping[currentId] + " " + (index + 1),
          data: item,
          inspectionPropertyDetails: dataItemArray[index],
          index,
          handleSubmit,
          key: `${index}_createElement_${currentId}`,
          currentId,
          applicationId,
          otherParams,
        };

        let Component: any = componentMapping[currentId];
        return (
          <CustomTabPanel key={`${index}_CustomTabPanel_${currentId}`} value={value} index={index} currentId={currentId} otherParams={otherParams}>
            <Component {...props} />
          </CustomTabPanel>
        );
      })}
    </React.Fragment>
  );
};

function a11yProps(index: number, currentId: string) {
  return {
    id: `${currentId}-tab-${index}`,
    "aria-controls": `${currentId}-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, currentId, otherParams, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`${currentId}-tab-${index}`} aria-labelledby={`${currentId}-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
