import { Divider, Stack, Typography } from "@mui/material";

const Header = () => {
  return (
    <Stack direction={"column"} width={"100%"}>
      <Typography> Manual Inspection Allocation </Typography>
      <Typography variant="caption" color={"gray"}>
        Manually allocate inspection officers which has permitted branches
      </Typography>
      <Divider></Divider>
    </Stack>
  );
};

export default Header;
