import { Downgraded } from "@hookstate/core";
import { bankDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/bank-details/bankDetailsValidationOnNext";
import { otherValidationHandler } from "../../CustomHooks/useValidateSubmit";
import { messageMappings } from "./messageMapping";
import { validateWithStructure } from "./structureValidation";

export const bankDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  state = state.attach(Downgraded).get();
  let errorArray: string[] = [];

  if (!state.length) {
    return ["Bank Details Section cannot be empty!"]
  }

  for (const item of state) {

    if (item?.bankName === "No Other Bank Accounts") continue;

    errorArray = [...errorArray, ...validateWithStructure(structure, item)]
  }

  if (errorArray.length) return messageMappings(errorArray);

  let resultOtherErrors = bankDetailsValidationOnNext(state);

  if (resultOtherErrors !== 1) {
    resultOtherErrors = otherValidationHandler(Object.values(resultOtherErrors));
  } else {
    resultOtherErrors = [];
  }

  return resultOtherErrors;
};
