import { useEffect } from "react";
import { getUserUpdatedPrivilege } from "../../services/apiCalls";
import Api from "../../services/apiCallsHandler";

export const useSessionRefresh = (cb) => {
    useEffect(() => {
        (async () => {
            try {
                const result = await getUserUpdatedPrivilege();

                if (!result && result.status === -1) {
                    return;
                }

                let sessionStr = sessionStorage.getItem('session');
                if (!sessionStr) {
                    cb();
                    return;
                }
                let session = JSON.parse(sessionStr);
                session.data.permissionObj = result.data.permissionObj;

                sessionStorage.setItem('session', JSON.stringify(session));
                cb();
            } catch (error) {
                cb();
            }
        })();
    }, []);
};

export const sessionRefresh = async () => {
    try {
        const { accessToken } = sessionStorage;

        if (!accessToken) {
            return null;
        }

        Api.createApi(accessToken, null);
        const { data, status } = await getUserUpdatedPrivilege();

        if (status === -1) {
            return null;
        }

        let sessionStr = sessionStorage.getItem('session');
        if (!sessionStr) {
            return null;
        }
        let session = JSON.parse(sessionStr);

        session.data = { ...data };

        sessionStorage.setItem('session', JSON.stringify(session));

        return session;
    } catch (error) {
        return null;
    }
};
