import { LengthItemValidate } from '../../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn';
import { clean, handleValidationPopUpLight } from '../../CustomHooks/useSaveSectionChanges';

export const otherParticipantSectionValidator = ({ participantStructure, participantSection, state, convertToParticipantState, handleAllStructure, formStore, previousData }) => {
  let errorArray: any = [];
  let isGuarantor: boolean = participantSection === 'security-details';
  let isJointBorrower: boolean = participantSection === 'joint-borrower-details';

  LengthItemValidate.validatedArray(state)?.forEach((otherParticipantItem, index) => {
    // get the 'validatorData' like data for participant state (JB or G)
    const participantValidatorData = convertToParticipantState(otherParticipantItem);
    let props = { structure: participantStructure, validatorData: participantValidatorData, formStore, isGuarantor, isJointBorrower, participantState: otherParticipantItem, previousData };
    let responseEr = handleAllStructure(props);

    handleValidationPopUpLight(participantStructure, responseEr, otherParticipantItem);

    errorArray[errorArray.length] = responseEr;

    let sectionErrorStatus = 'pending';
    responseEr = clean(responseEr);
    sectionErrorStatus = Object.keys(responseEr).length ? 'error' : 'success';

    otherParticipantItem.set(prev => ({ ...prev, sectionErrorStatus }));
  });

  return errorArray;
};
