import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { select_basic_ins } from "../../../../../../../../styles";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";

const SelectComponentsBuilder = ({ value, onChange, metaData, isFromTbCell = false }) => {
    const [valueInput, setValue] = useState(value[metaData?.dataKey]);
    let options: Array<any> = [];
    const masterDataStore = MasterDataProvider.provideMasterData();

    if (metaData?.inputOptions?.customOptions.length > 0) {
        metaData?.inputOptions?.customOptions.forEach((item: string, index: number) => {
            let tem: any = {
                id: index + 1, name: item,
            };
            return options.push(tem);
        });
    } else if (metaData?.inputOptions?.source === "MASTER") {
        options = masterDataStore?.getMasterData(MASTER_DATA_KEYS[metaData?.inputOptions?.category]);
    }

    return (
        <Box px={1} py={1} width={metaData?.render?.fullLengthSelect ? "100%" : "350px"}>
            {metaData?.render?.fullLengthSelect ? <Typography variant={"subtitle2"}>{metaData?.title}</Typography> : <></>}
            <SelectComponent
                isNotShowMainLabel={metaData?.render?.fullLengthSelect}
                formControlVariant={metaData?.render?.fullLengthSelect ? "standard" : "outlined"}
                values={options}
                defaultPlaceholder={true}
                sx={metaData?.render?.fullLengthSelect ? { fontSize: "14px", color: 'gray' } : select_basic_ins}
                required={metaData?.render?.required ?? false}
                value={valueInput || ''}
                disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
                onChange={(e) => {
                    delete value[metaData?.dataKey];
                    value = {
                        ...value, [metaData?.dataKey]: e.target.value,
                    };

                    onChange(metaData, value);
                    setValue(e.target.value);
                }}
                label={metaData?.title}
            />
        </Box>
    );
};

export default SelectComponentsBuilder;
