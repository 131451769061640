import { Downgraded } from "@hookstate/core";
import { personalDetails } from "../../../configs/mainStore";
import { taxDetailsObject } from "../../../configs/stores/applicationForm";
import { messageMappings } from "./messageMapping";
import { handleArray, validateWithStructure } from "./structureValidation";

export const taxDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState) => {
  const personalState = personalDetails?.attach(Downgraded).get();

  let participantData: any = {};

  if (isGuarantor || isJointBorrower) {
    participantData = participantState?.attach(Downgraded).get();
  } else {
    participantData = {};
  }

  let finalKeyArr = [];

  if (isJointBorrower || isGuarantor) {
    if (participantData) {
      if (participantData?.personalData?.taxPayer == 1) {
        let errorArray = validateWithStructure(structure, state);
        if (errorArray?.length > 0) {
          finalKeyArr = errorArray;
        }

        let errArrInArrays = handleArray(structure, state?.taxDetails, [taxDetailsObject]);

        if (errArrInArrays?.length > 0) {
          finalKeyArr = errorArray.concat(errArrInArrays);
        }
      }
    }
  } else {
    if (personalState?.taxPayer == 1) {
      let errorArray = validateWithStructure(structure, state);
      if (errorArray?.length > 0) {
        finalKeyArr = errorArray;
      }

      let errArrInArrays = handleArray(structure, state?.taxDetails, [taxDetailsObject]);

      if (errArrInArrays?.length > 0) {
        finalKeyArr = errorArray.concat(errArrInArrays);
      }
    }
  }

  const preparedErrorMessages = messageMappings(finalKeyArr);

  return preparedErrorMessages;
};
