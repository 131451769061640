import { Box, Stack, Typography } from '@mui/material';
import ARMAllocation from '../../ARMAllocation/ARMAllocation';

const ARMCodeMapping = ({ dataCollection }) => {
  return (
    <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle1">ARM Allocation</Typography>
      <Box height={200} width={550}>
        {/* TODO: bind the application ID */}
        <ARMAllocation
          applicationId={3421}
          currentApplicationWfData={1}
          innerComponentData={{}}
          tabsToEdit
        ></ARMAllocation>
      </Box>
    </Stack>
  );
};

export default ARMCodeMapping;
