/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from '@hookstate/core'
import { Paper, Stack } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../../../../GlobalToast'
import { CircularProgressComponent } from '../../../../../../../../ProgressComponent/ProgressComponent'
import { getBalanceSheet, saveBalanceSheetData } from '../../../../Api/AppraisalApi'
import { assetDataKeys, balanceSheetData, liabilityDataKeys } from '../../../../Configs/AuditedFinanceConfigs/AuditedFinanceConfigs'
import { profitAndLossAccountsTitleArray } from '../../../../Configs/AuditedFinancialsDetailsConfigs/AuditedFinancialsDetailsConfigs'
import SubSectionHeader from '../../../../Helpers/SubSectionHeader/SubSectionHeader'
import { filterAutoPopulatedKeys } from '../../../../Utilities/Util'
import { GeneralizedController } from '../../../FinancialPositionOfTheCompany/Calculations/GeneralizedController'
import FinancialPositionOfCompanyGeneralizedTableComponent from '../../../FinancialPositionOfTheCompany/Helpers/FinancialPositionOfCompanyGeneralizedTableComponent'

const BalanceSheet = ({ applicationId }) => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = React.useState({});
  const assets = filterAutoPopulatedKeys(assetDataKeys);
  const liabilities = filterAutoPopulatedKeys(liabilityDataKeys);
  const isLoading = useState(false);
  const [allTableHeaderTitles, setAllTableHeaderTitles] = React.useState([...profitAndLossAccountsTitleArray]);

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const response: any = await getBalanceSheet(applicationId, true);
      if (response?.status === 1) {

        if (Object.values(response.data).length > 0) {
          setFormData(response.data.data);
          setAllTableHeaderTitles(response.data.selectedYears);
          isLoading.set(false);
        }

        isLoading.set(false);
      }
      isLoading.set(false);
    }());

    return () => {
      setFormData({});
    }

  }, []);

  const onChange = useCallback((value, year, dataKey) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [year]: {
        ...prevFormData[year],
        [dataKey]: value,
      },
    }));
  }, []);

  const getAllCalculations = useMemo(() => {
    const generalizedController = new GeneralizedController(
      formData,
      profitAndLossAccountsTitleArray,
      assets,
      liabilities
    );

    return {
      totalAssets: generalizedController.getTotalAssets(),
      totalLiabilities: generalizedController.getTotalLiabilities(),
      equity: generalizedController.getEquity(),
    };
  }, [formData]);

  const onSaveForm = async () => {

    const selectedYears = allTableHeaderTitles;
    const dataTobeSave = {
      applicationId,
      balanceSheetData: {
        data: { ...JSON.parse(JSON.stringify(formData)) },
        totals: getAllCalculations,
        selectedYears,
        isPublish: 0,
      },
    };

    const response = await saveBalanceSheetData(dataTobeSave, true);

    if (response.status === 1) toast({ status: "success", message: `${translate("balanceSheet")} saved successfully !` });

    else toast({ status: "error", message: `Error on Saving ${translate("balanceSheet")}!` });
  };

  return (
    <Stack width={"100%"} gap={2} component={Paper} elevation={0}>
      <SubSectionHeader
        isNeedDropdown={false}
        title={"Balance Sheet"}
        onSubmit={onSaveForm}
        dropDownProps={{
          options: [],
          profitAndLossAccounts: "",
        }}
      />

      {isLoading.get() ?
        <CircularProgressComponent size={30} sx={{ top: '50%', right: '50%', position: 'absolute', zIndex: 100 }} />
        :
        <>
          <FinancialPositionOfCompanyGeneralizedTableComponent
            label={"details"}
            onChange={onChange}
            defaultObject={formData}
            profitAndLossAccountsTitleArray={allTableHeaderTitles}
            descriptionList={balanceSheetData}
            getAllCalculations={getAllCalculations}
          />
        </>
      }

    </Stack>
  )
}

export default BalanceSheet
