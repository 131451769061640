import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { getClientDetailsV2 } from "../../../../../../services/bankServiceInvorker";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { toast } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const GroupSelectionModal = ({ cif, applicationId, handleListItemClick }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectionList, setSelectionList] = React.useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (force = false) => {
    try {
      setIsLoading(true);
      const { IndividualProfiles = [] } = await getClientDetailsV2(
        cif,
        force,
        applicationId
      );
      if (IndividualProfiles.length === 0) {
        toast({
          status: "error",
          message: `No data found for CIF: ${cif}`,
        });
        handleListItemClick("");
      }

      setSelectionList(IndividualProfiles);
    } catch (error) {
      toast({ status: "error", message: "Error while fetching data" });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Stack flex={1} alignItems={"center"} justifyContent={'center'} alignContent={"center"}>
        <Typography>Result is getting ready...</Typography>
        <CircularProgressComponent size={30} />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
        <Stack>
          <Typography variant="h6">Group Selection List</Typography>
        </Stack>
        <Stack
          onClick={() => {
            closeGlobalModal();
          }}
        >
          <Typography
            variant="h6"
            style={{ cursor: "pointer", color: "black" }}
          >
            ❌
          </Typography>
        </Stack>
      </Stack>
      <Divider></Divider>
      <List component="nav">
        <div
          className="basic-font basic-font-color font-size-15 font-weight-100 align-center"
          style={{
            marginBottom: 0,
            marginTop: 0,
            overflowX: "hidden",
            overflowY: "hidden",
            maxHeight: "calc(100vh - 390px)",
          }}
        >
          {selectionList.map((item: any) => (
            <ListItem divider key={`${item?.CIF}-${item?.NIC}`}>
              <ListItemButton
                onClick={(e: any) => handleListItemClick(item)}>
                <Stack width={"100%"}>
                  <Stack>
                    <Typography>
                      👉 {item?.initialInFull}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'} px={3.5}>
                    <Typography>CIF: {item?.CIF}</Typography>
                    <Typography>NIC: {item?.NIC}</Typography>
                  </Stack>
                </Stack>

              </ListItemButton>
            </ListItem>
          ))}
        </div>
      </List>
    </Stack>
  );
};

export default GroupSelectionModal;
