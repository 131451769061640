import moment from "moment";
import { generateUniqueId, getCopy } from "../../../../../../../utility/util";

export interface GernaralizedComprehensiveTitleArray { title: number, dataKey: number | string, isEdited: number, isRatioAnalysisCal: boolean; isAutoPopulatedColumns: boolean }

const comprehensiveDefaultObject = {
  description: ""
};

export const profitAndLossAccountsTitleArray: GernaralizedComprehensiveTitleArray[] = [
  {
    title: Number(moment().year()) - 1,
    dataKey: Number(moment().year()) - 1,
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },

  {
    title: Number(moment().year()),
    dataKey: Number(moment().year()),
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },
];

export const forCastedTitleArray = [
  {
    title: Number(moment().year()) + 1,
    dataKey: Number(moment().year()) + 1,
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },

  {
    title: Number(moment().year()) + 2,
    dataKey: Number(moment().year()) + 2,
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },

  {
    title: Number(moment().year() + 3),
    dataKey: Number(moment().year() + 3),
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },
];

export const balanceSheetTitleArray = [{
  title: Number(moment().year()) + 1,
  dataKey: Number(moment().year()) + 1,
  isEdited: 0,
  isRatioAnalysisCal: true,
  isAutoPopulatedColumns: false
},

{
  title: Number(moment().year()) + 2,
  dataKey: Number(moment().year()) + 2,
  isEdited: 0,
  isRatioAnalysisCal: true,
  isAutoPopulatedColumns: false
},
];

export const gernaralizedComprehensiveTitleArray: GernaralizedComprehensiveTitleArray[] = [
  ...getCopy(profitAndLossAccountsTitleArray),
  {
    title: Number(moment().year()) + 1,
    dataKey: Number(moment().year()) + 1,
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: false
  },

  {
    title: "Variable Rate",
    dataKey: "variableRate",
    isEdited: 0,
    isRatioAnalysisCal: false,
    isAutoPopulatedColumns: false
  },

  {
    title: Number(moment().year()) + 2,
    dataKey: Number(moment().year()) + 2,
    isEdited: 0,
    isRatioAnalysisCal: true,
    isAutoPopulatedColumns: true
  },

]

export const comprehensiveDefaultDataObject = JSON.parse(JSON.stringify(comprehensiveDefaultObject));

const defaultComprehensiveExpenses = {
  bankingAndFinanceExpenses: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
  employeeAndLabourExpenses: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
  generalBusinessExpenses: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
  professionalServiceExpense: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
  vehicleExpenses: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
};

export const defaultComprehensiveProfitAndLoanAccounts = {
  totals: {},
  data: {
    revenues: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
    reductions: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
    expenses: JSON.parse(JSON.stringify(defaultComprehensiveExpenses)),
    depreciation: [getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() })],
    interestExpenses: [getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() })],
    taxes: [JSON.parse(JSON.stringify({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId(), }))],
  },
  selectedYears: JSON.parse(JSON.stringify(gernaralizedComprehensiveTitleArray)),
};

export const comprehensivePandL = {
  revenuesDefault: getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() }),
  reductionsDefault: getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() }),
  expensesDefauls: getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() }),
  depreciationDefault: [getCopy({ ...comprehensiveDefaultDataObject, dataId: generateUniqueId() })],
}

export const defaultGeneralizedProfitAndLoanAccounts = {
  data: {},
  selectedYears: getCopy([...gernaralizedComprehensiveTitleArray]),
  totals: {},
};

export const FinancialPositionOfTheCompanyFinalObject = {
  doesTheApplicantHasEnoughFinancialInformation: "",
  data: {},
  type: "",
  totals: {},
  selectedYears: JSON.parse(JSON.stringify([...profitAndLossAccountsTitleArray, ...balanceSheetTitleArray])),
};

export const comprehensiveProfitAndLoanAccountsState = JSON.parse(JSON.stringify(defaultComprehensiveProfitAndLoanAccounts));
export const generalizedProfitAndLoanAccountsState = JSON.parse(JSON.stringify(defaultGeneralizedProfitAndLoanAccounts));
export const profitAndLossAccountsTypeKey = "generalized";
export const FinancialPositionOfTheCompanyFinalObjectState = JSON.parse(JSON.stringify(FinancialPositionOfTheCompanyFinalObject));

const abilityToRepayLoanDefault = {
  [Number(moment().year()) - 2]: 0,
  [Number(moment().year()) - 1]: 0,
  [Number(moment().year())]: 0,
  proposed: 0

}

export const abilityToRepayLoanDefaultData = {
  selectedYears: JSON.parse(JSON.stringify([...profitAndLossAccountsTitleArray, ...balanceSheetTitleArray])),
  data: {
    grossProfit: JSON.parse(JSON.stringify(abilityToRepayLoanDefault)),
    revenueIncome: JSON.parse(JSON.stringify(abilityToRepayLoanDefault)),
    netProfitBeforeTax: JSON.parse(JSON.stringify(abilityToRepayLoanDefault)),
    netProfitAfterTax: JSON.parse(JSON.stringify(abilityToRepayLoanDefault)),
    netExpenses: JSON.parse(JSON.stringify(abilityToRepayLoanDefault)),
  }
};

export const abilityToRepayLoanDefaultDataState = JSON.parse(JSON.stringify(abilityToRepayLoanDefaultData));

const defaultSalesandPurchaseInformation = {
  key: Math.random(),
  month: "",
  cashBasis: "",
  creditBasis: "",
  total: ""
}

export const defaultSalesandPurchaseInformationForState = JSON.parse(JSON.stringify(defaultSalesandPurchaseInformation));

const salesInformationForState = {
  totals: {},
  salesandInformation: [JSON.parse(JSON.stringify(defaultSalesandPurchaseInformationForState))],
}

const purchaseInformationForState = {
  totals: {},
  purchaseInformation: [JSON.parse(JSON.stringify(defaultSalesandPurchaseInformationForState))],
}

export const salesandPurchaseInformationState = JSON.parse(JSON.stringify(salesInformationForState));
export const purchaseInformationState = JSON.parse(JSON.stringify(purchaseInformationForState));
export const viewModeState: string | undefined = undefined;