import { Stack } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { AddEditUserGroupComponent } from "../../../components/OtherComponents/Users/AddEditUserGroupComponent";
import CloseIcon from "@mui/icons-material/Close";
import { UserGroupPrivilegeViewEdit } from "../UserGroupPrivilegeViewEdit";

const PrivilegeAssignmentModal = (params) => {
    const mode = params?.mode;

    const BuildComponent = () => {
        if (mode === "create" || mode === "edit") {
            return <AddEditUserGroupComponent
                userGroupListChanged={params?.userGroupListChanged}
                editGroupItem={params?.groupId}
                reloadComponent={params?.reloadComponent}
            />
        } else {
            return <UserGroupPrivilegeViewEdit
                selectedUserGroupItem={params?.groupId}
                setPage={null}
            />
        }
        return <></>
    }


    return (
        <>
            <BuildComponent />
            <Stack direction={"row-reverse"} mt={5} flex={1}>
                <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="contained"
                    onClick={() => {
                        closeGlobalModal();
                        params?.reloadComponent();
                    }}
                    loadingbtn={true}
                />
            </Stack>
        </>
    )
}

export default PrivilegeAssignmentModal;