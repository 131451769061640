import React from "react";
import { useTranslation } from "react-i18next";
import { TableColumnsTextWrapper } from "../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper";
import { TableRowWrapperRevaamp } from "../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import BuildTableColumns from "./BuildTableColumnsRevaamp";

const BuildTableRowRevaamp = ({ descriptionItem, index, abilityToRepayLoanDataState }) => {
  const { t: translate } = useTranslation();

  if (descriptionItem.type !== "autoPopulated" && descriptionItem.dataKey !== "revenueIncome") {
    return <React.Fragment key={"React.Fragment" + index + descriptionItem.type + descriptionItem.dataKey}></React.Fragment>
  }

  return (
    <TableRowWrapperRevaamp key={"TableRowWrapperRevaamp" + index + descriptionItem.dataKey} index={"TableRowWrapperRevaamp" + index + index + descriptionItem.dataKey} >
      <TableColumnsTextWrapper index={"TableColumnsTextWrapper" + index + descriptionItem.dataKey + descriptionItem.title}>
        {translate(descriptionItem.title)}
      </TableColumnsTextWrapper>
      {
        abilityToRepayLoanDataState["selectedYears"].get().filter(item => !!item?.isRatioAnalysisCal).map((headerTitle) => {
          const props = { headerTitle, descriptionItem, index, abilityToRepayLoanDataState }

          return <BuildTableColumns key={"BuildTableColumns" + index + index + descriptionItem.dataKey}  {...props} />
        })
      }
    </TableRowWrapperRevaamp>
  )
}

export default React.memo(BuildTableRowRevaamp);

