import { useState } from '@hookstate/core';
import { Grid } from '@mui/material';
import { masterDataDetails } from '../../../../../../configs/mainStore';
import { RenderingHelper } from './Components';

export const ContactDetails = ({ contactDetails, index, nohead = false, isBusinessDetails = false }) => {
  const masterData: any = useState(masterDataDetails);
  const emailTypeData = masterData?.EMAIL_TYPE_EMPLOYMENT?.get();
  const typedData = (masterType) => {
    if (masterType) {
      if (masterType === 1) {
        return { name: 'General' };
      } else if (masterType === 2) {
        return { name: 'Direct' };
      } else return '';
    } else return '';
  };

  const contactType = typedData(contactDetails?.empContactType);
  const contactName = contactDetails?.empContactName ? contactDetails?.empContactName : '';
  const contactNumber = contactDetails?.empContactNumber ? contactDetails?.empContactNumber : '';
  const email = contactDetails?.empEmailAddress ? contactDetails?.empEmailAddress : '';
  const emailType = contactDetails?.empEmailType ? emailTypeData?.filter(e => e?.id === contactDetails?.empEmailType)[0]?.name : '';

  return (
    <>
      <Grid container p={1} spacing={1} className="data-box">
        {nohead ? (
          <></>
        ) : (
          <Grid className="full-width margin-xs-b ">
            <Grid
              container
              p={1}
              className="border-radius-5 basic-font basic-font-color-bold font-size-16"
              style={{ backgroundColor: "#e6f0fa" }}
            >
              Business Contacts
            </Grid>
          </Grid>
        )}
        {isBusinessDetails ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <div className="basic-font basic-font-color-bold font-size-16 align-left">
              Employment Contacts
            </div>
          </Grid>
        )}
        <Grid item xs={6}>
          {RenderingHelper.addItem(
            contactDetails,
            "empContactName",
            `${contactName ? contactName + " - " : ""}${contactNumber ? contactNumber + " - " : ""
            }${contactType ? "(" + contactType.name + ")" : ""}`
          )}
        </Grid>
        <Grid item xs={6}>
          {RenderingHelper.addItem(
            contactDetails,
            "empEmailAddress",
            `${email} ${emailType ? "(" + emailType + ")" : ""}`
          )}
        </Grid>
      </Grid>
    </>
  );
};
