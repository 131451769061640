import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorMessageComponent } from "../../../../ErrorMessageComponent";
import { DetailInlinedItem } from "../../DetailItem";
import { addItem } from "./Helpers";

export const BusinessDetailsTax = ({ business, masterData, isMainContactDetails }) => {
  const taxDetails = business?.taxData?.taxDetails || [];
  const { t: translate } = useTranslation();

  const BuildTaxDetails = ({ tax, index }) => {
    let taxYear = "";
    try {
      if (tax?.yearOfAssessment) {
        taxYear = tax.yearOfAssessment.split("/")[2];
      }
    } catch (error) {
      taxYear = index + 1;
    }

    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(tax, "estimatedIncome", tax?.estimatedIncome ?? "N/A")}
          {addItem(tax, "indexNumber", tax?.indexNumber ?? "N/A")}
          {addItem(tax, "office", tax?.office ?? "N/A")}
          {addItem(tax, "statutoryIncome", tax?.statutoryIncome ?? "N/A")}
          {addItem(tax, "taxFileNumber", tax?.taxFileNumber ?? "N/A")}
          {addItem(tax, "taxPaid", tax?.taxPaid ?? "N/A")}
          {addItem(tax, "valuePayable", tax?.valuePayable ?? "N/A")}
          {addItem(tax, "valueSubjectsToTax", tax?.valueSubjectsToTax ?? "N/A")}
          {addItem(tax, "yearOfAssessment", tax?.yearOfAssessment ?? "N/A")}
        </Grid>
      </Grid>
    );
  };


  return (
    <div className="full-width margin-xs-b">
      <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
        <Grid item xs={6} className="align-left">
          Business Tax Details
        </Grid>
        <Grid item className="align-right">
          <DetailInlinedItem title={translate("individualTaxNo")} value={business?.taxData?.taxIdentificationNumber || "N/A"} />
        </Grid>
      </Grid>
      {taxDetails.length > 0 ? (
        <>
          {taxDetails
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((tax, index) => {
              return <BuildTaxDetails key={index} tax={tax} index={index} />;
            })}
        </>
      ) : (
        <ErrorMessageComponent headMessage={""} errorMessage={"No Data Available"} />
      )}
    </div>
  );
};
