// import { useState } from "@hookstate/core";
import { useEffect } from 'react';
import appStrings from '../../configs/constants/appStrings';
import { defaultAppSettings } from '../../configs/constants/defaultAppSettings';
// import { appSetting } from "../../configs/mainStore";

/**
 * Application settings initializer custom hook
 */

export const useSetAppSettings = (appSettings) => {
  // const app_settings = useState(appSetting);

  useEffect(() => {
    const setDefaultSettings = async () => {
      //checking for user defined settings in local storage
      const userSettings = await localStorage.getItem(appStrings?.localStorage?.appSettings);

      //if detect any user defined object set it , else set default application settings from
      //default app settings object
      let tempUserSettings;
      if (userSettings) {
        tempUserSettings = JSON.parse(userSettings);
      }
      await appSettings.set(tempUserSettings || defaultAppSettings);
      // console.log(tempUserSettings, defaultAppSettings);
    };

    setDefaultSettings();
  }, []);
};
