import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import ProductDocumentUploadLayout from '../../../../../layouts/ProductDocumentUploadLayout';
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { InspectionDocumentUploadSubSection } from "../../../ProductDocumentUploadComponent/InspectionDocumentUploadSubSection";

const InspectionDocumentUpload = ({
  data,
  applicationId,
  innerComponentData,
  currentApplicationWfData,
  tabsToEdit,
}) => {
  const creditFileData: any = useState(creditData);
  const { formData }: any = creditFileData
    .attach(Downgraded)
    .get();

  const jointBorrowerArr: any = [];

  if (formData?.jointBorrowerData?.length > 0) {
    formData?.jointBorrowerData.forEach(borrower => {
      jointBorrowerArr.push(borrower.personalData);
    });
  }

  const combinedData = [formData?.personalData, ...jointBorrowerArr];
  const individualId = formData?.personalData?.individualId;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  
  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height">
        <InspectionDocumentUploadSubSection
          applicationId={applicationId}
          documentTypeId={innerComponentData?.documentTypeId}
          documentSectionName={innerComponentData?.title}
          documentComponentId={innerComponentData?.id}
          participantId={individualId}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
          personalData={combinedData}
        />
      </div>
    </Grid>
  );
};

export default InspectionDocumentUpload;