import {
  validatelength,
  validatePositiveNumber,
  validatePositiveNumberWithDecimalPlaces,
  validateTextWithCharactersSpacesAndNumbers
} from '../../validation-rules/validationRules';

export const incomeDetailsValidationOnNext = (changes) => {

  const personnelIncomeErrors: any = {};
  let tempPersonnelIncome = changes?.personnelIncome;
  for (let i = 0; i < tempPersonnelIncome?.length; i++) {
    if (tempPersonnelIncome[i] && !(tempPersonnelIncome[i].removedItem)) {
      const basicSalaryVal = tempPersonnelIncome[i]?.basicSalary
        ? basicSalaryValidation(`${i + 1} : Basic Salary`, tempPersonnelIncome[i]?.basicSalary, 2)
        : null;

      const averageVariableAllowanceVal = tempPersonnelIncome[i]?.averageVariableAllowance
        ? averageVariableAllowanceValidation(
          `${i + 1} : Average Variable Allowance`,
          tempPersonnelIncome[i]?.averageVariableAllowance,
          2
        )
        : null;

      const fixedAllowanceVal = tempPersonnelIncome[i]?.fixedAllowance
        ? fixedAllowanceValidation(`${i + 1} : Fixed Allowance`, tempPersonnelIncome[i]?.fixedAllowance, 2)
        : null;

      const lastThreeAverageDeductionVal = tempPersonnelIncome[i]?.lastThreeAverageDeduction
        ? lastThreeAverageDeductionValidation(
          `${i + 1} : Last Three Average Deduction`,
          tempPersonnelIncome[i]?.lastThreeAverageDeduction,
          2
        )
        : null;

      if (basicSalaryVal || averageVariableAllowanceVal || fixedAllowanceVal || lastThreeAverageDeductionVal) {
        personnelIncomeErrors[i] = {
          basicSalary: basicSalaryVal,
          averageVariableAllowance: averageVariableAllowanceVal,
          fixedAllowance: fixedAllowanceVal,
          lastThreeAverageDeduction: lastThreeAverageDeductionVal,
        };
      }
    }
  }

  let tempBusinessIncomeExpense = changes?.businessIncome?.expense;

  const businessIncomeExpenseErrors: any = {};
  for (let i = 0; i < tempBusinessIncomeExpense?.length; i++) {
    if (!(tempBusinessIncomeExpense[i].removedItem)) {
      const expenseBusinessVal = tempBusinessIncomeExpense[i]?.expenseBusiness
        ? expenseBusinessValidation(`${i + 1} : Expense`, tempBusinessIncomeExpense[i]?.expenseBusiness, 2)
        : null;

      if (expenseBusinessVal) {
        businessIncomeExpenseErrors[i] = {
          expenseBusiness: expenseBusinessVal,
        };
      }
    }
  }

  let tempBusinessIncome = changes?.businessIncome?.income;
  const businessIncomeErrors: any = {};
  for (let i = 0; i < tempBusinessIncome?.length; i++) {
    if (!(tempBusinessIncome[i].removedItem)) {
      const sourceOfIncomeBusinessVal = tempBusinessIncome[i]?.sourceOfIncomeBusiness
        ? sourceOfIncomeBusinessValidation(`${i + 1} : Source Of Income`, tempBusinessIncome[i]?.sourceOfIncomeBusiness)
        : null;

      const incomeBusinessVal = tempBusinessIncome[i]?.incomeBusiness
        ? incomeBusinessValidation(`${i + 1} : Income`, tempBusinessIncome[i]?.incomeBusiness, 2)
        : null;

      if (sourceOfIncomeBusinessVal || incomeBusinessVal) {
        businessIncomeErrors[i] = {
          sourceOfIncomeBusiness: sourceOfIncomeBusinessVal,
          incomeBusiness: incomeBusinessVal,
        };
      }
    }
  }

  let tempCultivationIncome = changes?.cultivationIncome?.income;

  const businessCultivationIncomeErrors: any = {};
  for (let i = 0; i < tempCultivationIncome?.length; i++) {
    if (businessCultivationIncomeErrors[i] && !(businessCultivationIncomeErrors[i].removedItem)) {
      const incomeTypeCultivationVal = tempCultivationIncome[i]?.incomeTypeCultivation
        ? incomeTypeCultivationValidation(
          `${i + 1} : Cultivation Income Type`,
          tempCultivationIncome[i]?.incomeTypeCultivation
        )
        : null;

      const incomeFrequencyCultivationVal = tempCultivationIncome[i]?.incomeFrequencyCultivation
        ? incomeFrequencyCultivationValidation(
          `${i + 1} : Cultivation Income Frequency`,
          tempCultivationIncome[i]?.incomeFrequencyCultivation
        )
        : null;

      const seasonalIncomeVal = tempCultivationIncome[i]?.seasonalIncome
        ? seasonalIncomeValidation(`${i + 1} : Cultivation seasonal Income`, tempCultivationIncome[i]?.seasonalIncome, 2)
        : null;

      if (incomeTypeCultivationVal || incomeFrequencyCultivationVal || seasonalIncomeVal) {
        businessCultivationIncomeErrors[i] = {
          incomeTypeCultivation: incomeTypeCultivationVal,
          incomeFrequencyCultivation: incomeFrequencyCultivationVal,
          seasonalIncome: seasonalIncomeVal,
        };
      }
    }
  }

  let tempCultivationExpense = changes?.cultivationIncome?.expense;
  const businessCultivationExpenseErrors: any = {};
  for (let i = 0; i < tempCultivationExpense?.length; i++) {
    if (!(tempCultivationExpense[i].removedItem)) {
      const expenseTypeCultivationVal = tempCultivationExpense[i]?.expenseTypeCultivation
        ? expenseTypeCultivationValidation(
          `${i + 1} : Cultivation Expense Type`,
          tempCultivationExpense[i]?.expenseTypeCultivation
        )
        : null;

      const expenseFrequencyCultivationVal = tempCultivationExpense[i]?.expenseFrequencyCultivation
        ? expenseFrequencyCultivationValidation(
          `${i + 1} : Cultivation Expense Frequency`,
          tempCultivationExpense[i]?.expenseFrequencyCultivation
        )
        : null;

      const seasonalExpenseVal = tempCultivationExpense[i]?.seasonalExpense
        ? seasonalExpenseValidation(
          `${i + 1} : Cultivation seasonal Expense`,
          tempCultivationExpense[i]?.seasonalExpense,
          2
        )
        : null;

      if (expenseTypeCultivationVal || expenseFrequencyCultivationVal || seasonalExpenseVal) {
        businessCultivationExpenseErrors[i] = {
          expenseTypeCultivation: expenseTypeCultivationVal,
          expenseFrequencyCultivation: expenseFrequencyCultivationVal,
          seasonalExpense: seasonalExpenseVal,
        };
      }
    }
  }

  const personnelIncomeErrorsVal = Object.keys(personnelIncomeErrors).length > 0 ? personnelIncomeErrors : null
  const businessIncomeExpenseErrorsVal = Object.keys(businessIncomeExpenseErrors).length > 0 ? businessIncomeExpenseErrors : null
  const businessIncomeErrorsVal = Object.keys(businessIncomeErrors).length > 0 ? businessIncomeErrors : null
  const businessCultivationIncomeErrorsVal = Object.keys(businessCultivationIncomeErrors).length > 0 ? businessCultivationIncomeErrors : null
  const businessCultivationExpenseErrorsVal = Object.keys(businessCultivationExpenseErrors).length > 0 ? businessCultivationExpenseErrors : null

  let businessIncomeErrorsObj: any = {};
  if (
    personnelIncomeErrorsVal ||
    businessIncomeExpenseErrorsVal ||
    businessIncomeErrorsVal ||
    businessCultivationIncomeErrorsVal ||
    businessCultivationExpenseErrorsVal
  ) {
    businessIncomeErrorsObj = {
      personnelIncomeErrors: personnelIncomeErrorsVal,
      businessIncomeExpenseErrors: businessIncomeExpenseErrorsVal,
      businessIncomeErrors: businessIncomeErrorsVal,
      businessCultivationIncomeErrors: businessCultivationIncomeErrorsVal,
      businessCultivationExpenseErrors: businessCultivationExpenseErrorsVal,
    };
  }

  // console.log('***', businessIncomeErrorsObj);
  return Object.keys(businessIncomeErrorsObj).length > 0 ? businessIncomeErrorsObj : 1;
};

export const basicSalaryValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkBasicSalary = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkBasicSalary) result.push(checkBasicSalary);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const averageVariableAllowanceValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkAverageVariableAllowance = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkAverageVariableAllowance) result.push(checkAverageVariableAllowance);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const fixedAllowanceValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkFixedAllowance = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkFixedAllowance) result.push(checkFixedAllowance);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const lastThreeAverageDeductionValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const expenseBusinessValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);

  return result.length > 0 ? result : null;
};

export const sourceOfIncomeBusinessValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const incomeBusinessValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const incomeTypeCultivationValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const incomeFrequencyCultivationValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const seasonalIncomeValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const expenseTypeCultivationValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const expenseFrequencyCultivationValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const seasonalExpenseValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkLastThreeAverageDeduction = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkLastThreeAverageDeduction) result.push(checkLastThreeAverageDeduction);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
