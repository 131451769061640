import ReportIcon from "@mui/icons-material/Report"
import SendIcon from "@mui/icons-material/Send"
import { Box, Grid } from "@mui/material"
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent"

// Typo
import Typography from "@mui/material/Typography"
interface ErrorMessageComponentProps {
  headMessage: any
  errorMessage: any
  btnText?: any
  onClick?: any
  isLoading?: any
  showImg?: boolean
}
export const ErrorMessageComponent = ({ headMessage, errorMessage, btnText, onClick, isLoading, showImg = true }: ErrorMessageComponentProps) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      p={3}
    >
      <Box mt={5}>
        <ReportIcon color={"disabled"} fontSize={"large"} />
      </Box>
      <Box className="basic-font-color-bold basic-opacity bold font-size-20" mt={2}>
        <Typography variant="h6">{headMessage ? headMessage : ""}</Typography>
      </Box>
      <Box className="basic-font-color font-size-14 basic-font basic-opacity">{errorMessage}</Box>
      {onClick && (
        <Box className="online-app-applicant-new-lead-button-div">
          <ButtonComponent
            startIcon={<SendIcon />}
            title={btnText ? btnText : "Click"}
            variant="contained"
            onClick={() => {
              onClick()
            }}
            disabled={isLoading}
            loadingbtn={true}
            loading={isLoading}
          />
        </Box>
      )}
    </Grid>
  )
}
