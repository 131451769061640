import { useHookstate } from '@hookstate/core';
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useEffect } from 'react';
import { fetchCreditAdminUnit, fetchCreditAdminUnitUsers } from '../../../../../../services/cauService';
import { getApplicationData } from '../../../../../../services/creditFileApiCall';
import Content from '../../../../ManualAllocation/Content'
import Header from '../../../../ManualAllocation/Header'

const useResolveApplication = (wfdata, cb) => {
    useEffect(() => {
        if (wfdata && wfdata.applicationId) {
            getApplicationData(wfdata.applicationId).then((response) => {
                // console.log(response);
            });
        }
    }, []);
}

export const useResolveCreditAdminUsers = (cb) => {
    useEffect(() => {
        fetchCreditAdminUnit().then((response) => {
            cb(response);
        });
    }, []);
}

export const useResolveCreditAdminOfficers = (data, cb) => {
    useEffect(() => {
        fetchCreditAdminUnitUsers(data).then((response) => {
            cb(response);
        });
    }, []);
}

const ManualAllocation = ({ dataCollection, wfData, nextWorkflowStepId }) => {

    const loading: any = useHookstate(true);
    const groups: any = useHookstate([]);
    const users: any = useHookstate([]);
    let payload: any = {
        group: null,
        user: null,
    }

    // useResolveApplication(wfData, () => { });

    // useResolveCreditAdminUsers((result) => {
    //     let flist: any = Object.values(result.groups).reduce((val: any, result: any) => [...result, ...val]);
    //     flist = flist.map((obj) => { return { ...obj, selected: false } });
    //     groups.set(flist)

    //     groupUserMap.set(result.userMap);
    // });

    useResolveCreditAdminOfficers({
        applicationId: wfData.applicationId,
        nextWorkflowStepId,
    }, (result) => {
        loading.set(false);
        users.set(result);
    });

    const userDidSelect = (user) => {
        payload.user = user;
        dataCollection.set({ ...dataCollection.get(), newAssignee: user.id });
    }

    const groupDidSelect = (group) => {
        payload.group = group;
    }

    const actions = {
        userDidSelect,
        groupDidSelect
    };

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <Header></Header>

            <Content data={{ groups, selection: payload, users, loading }} actions={actions}> </Content>
        </Stack >
    )
}

export default ManualAllocation