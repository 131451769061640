import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode"
import Api from "./apiCallsHandler"

const api = Api.getApi()

const trailCalOtherChargesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/trail-cal-other-charges-list?languageId=`
const appllicationChargesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/other-charges-by-application?applicationId=`
const appllicationChargesSetUrl = `${URL.REACT_APP_BACKEND_HOST}/los/update-other-charges-list`
const appllicationChargesDeleteUrl = `${URL.REACT_APP_BACKEND_HOST}/los/charges`

export const getCalOtherCharges = async (languageId) => {
  try {
    const data = await api.get(`${trailCalOtherChargesUrl}${languageId}`, [])

    if (data.status === ERROR_CODES.success) {
      return data?.data
    }

    return null
  } catch (error) {
    return null
  }
}

export const getApplicationCharges = async (applicationId) => {
  try {
    const data = await api.get(`${appllicationChargesUrl}${applicationId}`, [])

    if (data.status === ERROR_CODES.success) {
      return data?.data
    }

    return null
  } catch (error) {
    return null
  }
}

export const setApplicationCharges = async (dataObject) => {
  try {
    const data = await api.post(`${appllicationChargesSetUrl}`, dataObject)

    if (data.status === ERROR_CODES.success) {
      return data?.data
    }

    return null
  } catch (error) {
    return null
  }
}

export const deleteApplicationCharges = async (applicationId, chargeIds) => {
  try {
    const data = await api.delete(`${appllicationChargesDeleteUrl}`, { applicationId, chargeId: chargeIds })

    if (data.status === ERROR_CODES.success) {
      return data?.data
    }

    return null
  } catch (error) {
    return null
  }
}
