// icon
import SearchIcon from "@mui/icons-material/Search";

// add for search input
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
}));

function SearchComponent({ placeholder, handleChangeSearchText, suffix = null }) {
  const onChangeSeachText = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeSearchText(event.target.value);
  };

  return (
    <Search style={{ width: "100%", border: "1px solid rgba(0, 0, 0, 0.12)", marginRight: 0, marginLeft: 0, display: "flex", alignItems: "center" }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase style={{ width: "100%" }} placeholder={placeholder} inputProps={{ "aria-label": "search" }} endAdornment={suffix} onChange={onChangeSeachText} />
    </Search>
  );
}

export function SearchSuffixComponent({ placeholder, handleChangeSearchText, right, triggerSearch = null }: any) {
  const onChangeSeachText = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeSearchText(event.target.value);
  };

  return (
    <Search style={{ width: "100%", border: "1px solid rgba(0, 0, 0, 0.12)", marginRight: 0, marginLeft: 0, display: "flex", alignItems: "center" }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase style={{ width: "100%" }} placeholder={placeholder} inputProps={{ "aria-label": "search" }} onChange={onChangeSeachText} onKeyDown={(evt) => {
        const { key } = evt;
        if (key == "Enter" && triggerSearch) {
          triggerSearch();
        }
      }} />
      {right}
    </Search>
  );
}

export default SearchComponent;
