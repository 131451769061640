import { Downgraded, useState } from "@hookstate/core"
import { Grid, Stack } from "@mui/material"
import React from "react"
import { useEffect } from "react"
import { creditData, userDetails } from "../../../../configs/mainStore"
import ProductDocumentUploadLayout from "../../../../layouts/ProductDocumentUploadLayout"

const BorrowersBasicDocument = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const creditFileData: any = useState(creditData)
  const { productId, applicantType, formData, disbursed }: any = creditFileData.attach(Downgraded).get()

  const individualId = formData?.personalData?.individualId
  const { userId }: any = userDetails.attach(Downgraded).get()
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee

  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height">
        <ProductDocumentUploadLayout applicationId={applicationId} productId={productId} applicantType={applicantType} documentTypeId={innerComponentData?.documentTypeId} documentSectionName={innerComponentData?.title} documentComponentId={innerComponentData?.id} participantId={individualId} disbursed={disbursed} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee} participantType={innerComponentData?.participantType} />
      </div>
    </Grid>
  )
}

export default BorrowersBasicDocument
