import { Grid, Stack } from "@mui/material";
import { trimWithDelimiter } from "../../../../../../utility/util";
import { SinglePageBreak } from "../CommonPageBreaks";
import CommonWitnessBlock from "../CommonWitnessBlock";

const listMap = ["(a)", "(b)", "(c)", "(d)", "(e)"];

export const GuranteeSign: any = ({ item, branch, typeCaseClass, renderTitles }) => {
  return (
    <>
      <SinglePageBreak />
      <div className="securitydoc-sign-container">
        <div className="securitydoc-sign-column-l">
          <div className="securitydoc-sign above-name-sign">Signed/Sealed by the above named </div>
          <div className={`securitydoc-sign security-doc-type-variable ${typeCaseClass}`}>
            {`${item?.personalData?.initialsInFull} ${item?.personalData?.primaryLastName}`}
          </div>
          <div className="securitydoc-sign">
            <div>
              This .............. day of .................... 20 ...... at{" "}
              <span className={`security-doc-type-variable ${typeCaseClass}`} style={{ marginLeft: "7px", marginRight: "7px" }}>
                {" "}
                {branch}{" "}
              </span>{" "}
              Branch
            </div>
          </div>
        </div>
        <div className="securitydoc-sign-column-r"></div>
        <div className="securitydoc-sign-column-witnesses">
          <Grid item xs={12}>
            <Stack mb={1}>in the presence of</Stack>
            <Stack>
              <CommonWitnessBlock index={1} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
              <SinglePageBreak />
              <Stack mt={2}></Stack>
              <CommonWitnessBlock index={2} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
            </Stack>
          </Grid>
        </div>
      </div>
    </>
  );
};

export const GuranteeNameAdress: any = ({ item, index, typeCaseClass }) => {
  let address = "";
  address = [item?.addressData?.permanentAddress?.addressLine1 ?? "", item?.addressData?.permanentAddress?.addressLine2 ?? "", item?.addressData?.permanentAddress?.addressLine3 ?? "", item?.addressData?.permanentAddress?.addressLine4 ?? ""].filter((obj) => obj).join(", ");
  address = trimWithDelimiter(address);

  return (
    <div className="securitydoc-sign-container">
      <div className="securitydoc-sign-column-l">
        <div className="gurantee-bond-name-address">
          <div className="left-col">{listMap[index]}</div>
          <div className="right-col">
            <div>
              <span className={`name security-doc-type-variable ${typeCaseClass}`}>{`${item?.personalData?.initialsInFull} ${item?.personalData?.primaryLastName}`}</span>
              {"  "}
              Of <span className={`security-doc-type-variable ${typeCaseClass}`}>{address}</span>
            </div>
            <div className="address"></div>
          </div>
        </div>
      </div>
      <div className="securitydoc-sign-column-r"></div>
    </div>
  );
};
