/**
 * -- Default application settings object .
 *
 * -- You have to define all the default settings of the application inside of
 *    defaultAppSettings object .
 *
 * -- Since application not detecting any user customized settings object from local storage
 *    this defaultAppSettings object will be use to settings configurations.
 */

export const defaultAppSettings = {
  language: "EN",
  theme: "LIGHT",
};
