import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DoneIcon from "@mui/icons-material/Done";
import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { isTabActionPrivilege } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isNotPermittedDocs";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";

interface InspectionHeaderProps {
  title?: string;
  onSubmit?: any;
  onDownload?: any;
  children: any;
  otherParams?: any;
}

const InspectionHeader: React.FC<InspectionHeaderProps> = ({ title, children, onSubmit, onDownload, otherParams = {} }: InspectionHeaderProps) => {
  const freezeTabs: any[] = otherParams?.tabsToEdit || [];
  let disableAccordingToTab: any = false;

  if (freezeTabs.length > 0) {
    disableAccordingToTab = freezeTabs.includes(otherParams?.innerComponentData?.id);
  }

  return (
    <Stack
      style={{
        backgroundColor: `#7fb4de3d`,
        margin: "2px",
        borderRadius: "5px",
      }}
    >
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack>
          <Typography className="basic-font basic-font-color-bold font-size-16 padding-m align-left">{title ?? ""}</Typography>
        </Stack>
        <Stack direction={"row"} px={1} gap={1}>
          <ButtonComponent startIcon={<ArrowCircleDownIcon />} title="Print Form" variant="contained" onClick={onDownload} loadingbtn={true} color="info" disabled={isTabActionPrivilege(disableAccordingToTab)} />
          <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={onSubmit} loadingbtn={true} color="info" disabled={isTabActionPrivilege(disableAccordingToTab)} />
        </Stack>
      </Stack>
      <Paper>{children}</Paper>
    </Stack>
  );
};

export default InspectionHeader;
