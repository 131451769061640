import AddCircleIcon from "@mui/icons-material/AddCircle";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, Grid } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import Dropzone from "react-dropzone";

import { useState } from "@hookstate/core";
import { FILE_UPLOAD_COMPONENT_STATUS } from "../../../configs/constants/documentUploadKeys";
import { appSetting } from "../../../configs/mainStore";
import { documentKeys } from "../../../configs/revokeDocumentKeys";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import {
  uploadProductDocumentFile
} from "../../../services/documentApis";
import AppConfig from "../../../utility/AppConfig";
import { unclassifiedDisableAccess } from "../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { openGlobalModal } from "../GlobalModal/GlobalModal";
import { addToaster } from "../GlobalToast";
import { LinearProgressWithLabel } from "../LinearWithValueLabel/LinearWithValueLabel";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { documentStatusColors } from "../WorkflowComponents/utils";
interface SingleImageUploadComponentProps {
  fileDetails: any;
  uploadDataObject: any;
  isPreviewHave?: boolean;
  status?: any;
  permissionToUploadDocs?: Boolean;
  documentComponentId?: any;
  reloadComponent?: any;
}

export type Ref = any;

export const SingleImageUploadComponent = forwardRef<Ref, SingleImageUploadComponentProps>(
  (
    {
      fileDetails,
      uploadDataObject,
      isPreviewHave,
      status,
      permissionToUploadDocs,
      documentComponentId,
      reloadComponent = false,
    }: SingleImageUploadComponentProps,
    ref: any
  ) => {
    const appSettings: any = useState(appSetting);
    const currentRef = fileDetails.ref;

    const [imgStatus, setImgStatus] = React.useState(FILE_UPLOAD_COMPONENT_STATUS.noFile);
    const [file, setFile] = React.useState<any>();
    const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isRequiredFilled, setIsRequiredFilled] = React.useState(true);
    const [previewLoading, setPreviewLoading] = React.useState(false);
    const [closeClicked, setCloseClicked] = React.useState(false);
    const [statusColor, setStatusColor] = React.useState<any>("");

    const { previewedDocument }: any = useState(documentUploadData);

    const extractPrivileges = () => {
      if (AppConfig.control) {
        return false;
      }

      if (documentKeys.includes(fileDetails?.documentKey)) {
        return unclassifiedDisableAccess();
      }
      return permissionToUploadDocs;
    }

    const isRevokePrivilages = extractPrivileges();

    const submitImgChange = () => {
      setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);
      setFile(null);

      setStatusColor(
        documentStatusColors[status]?.color
          ? documentStatusColors[status]?.color
          : documentStatusColors["default"]?.color
      );
    };

    useEffect(() => {
      if (isPreviewHave && !closeClicked) {
        submitImgChange();
      }
    });

    useEffect(() => {
      removeSelectedImg();
    }, [fileDetails]);

    useImperativeHandle(currentRef, () => ({
      uploadSingleImg() {
        return uploadDocumentData().then((res) => {
          return res;
        });
      },
    }));

    const onDrop = useCallback((acceptedFiles) => {
      setFileUploadProgress(0);
      if (acceptedFiles.length === 1) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );

        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.pending);
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    }, []);

    const removeSelectedImg = () => {
      setFile(null);
      setFileUploadProgress(0);

      if (isPreviewHave) {
        submitImgChange();
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    };

    const previewClicked = async () => {
      setPreviewLoading(true);

      previewedDocument.set(uploadDataObject);

      openGlobalModal({
        modalSize: "xl",
        title: "",
        bodyId: "document-data-preview",
        close: false,
        modalParams: {
          documentComponentId: documentComponentId,
          isRevokePrivilages: isRevokePrivilages,
          callBackOnAction: () => handleCallBackAction(),
          fileDetails: fileDetails,
        },
      });

      setPreviewLoading(false);
    };

    const handleCallBackAction = () => {
      reloadComponent(true);
    };

    const errorMessage = (fileRejections: any) => {
      if (fileRejections.length === 1) {
        fileRejections[0].errors.map((item) => {
          addToaster({
            status: "error",
            title: "File Error",
            message: item?.message,
          });
        });
      }
    };

    const uploadDocumentData = () => {
      setIsUploading(true);

      if (file) {
        return uploadProductDocumentFile(uploadDataObject, file, setFileUploadProgress).then((data) => {
          if (data) {
            setIsUploading(false);
            submitImgChange();
          } else {
            if (fileDetails.isMandatory) {
              setIsRequiredFilled(false);
            }

            setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
            setFile(null);
          }
          setIsUploading(false);
          return true;
        });
      } else {
        if (isPreviewHave) {
          submitImgChange();
        } else {
          if (fileDetails.isMandatory) {
            setIsRequiredFilled(false);
          }

          setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
          setFile(null);
        }
        setIsUploading(false);
        return Promise.resolve(true);
      }
    };

    const renderPlaceholders = () => {
      if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile) {
        return (
          <Dropzone
            accept={fileDetails?.allowedFormats}
            maxFiles={1}
            multiple={false}
            maxSize={fileDetails?.maxFileSize}
            onDropRejected={(fileRejections) => errorMessage(fileRejections)}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            disabled={Boolean(isRevokePrivilages)}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} className={`${isRevokePrivilages ? "disabled" : ""}`}>
                <Grid
                  style={{ height: "125px" }}
                  container
                  item
                  // spacing={1}
                  direction="row"
                  sm={12}
                  alignItems="center"
                  justifyContent="center"
                  className={isRevokePrivilages == true ? "disabled" : ""}
                >
                  <Grid item sm={12}>
                    <input {...getInputProps()} />
                    <AddCircleIcon
                      style={{
                        color: "#a2a2a2",
                        fontSize: 40,
                        cursor: `${isRevokePrivilages ? "" : "pointer"}`,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Dropzone>
        );
      } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending) {
        return (
          <>
            {isUploading ? (
              <Grid
                style={{
                  backgroundColor: "#dde9f3",
                  borderRadius: "5px",

                  height: "125px",
                  border: "1px solid #4386be99",
                }}
                container
                item
                direction="row"
                sm={12}
                alignItems="end"
                justifyContent="center"
              >
                <Grid item sm={11} sx={{ mb: "10px" }}>
                  <LinearProgressWithLabel value={fileUploadProgress} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                style={{
                  backgroundColor: "#dde9f3ed",
                  borderRadius: "5px",

                  height: "125px",
                  border: "1px solid #4386be99",
                }}
                container
                item
                direction="row"
                sm={12}
                alignItems="start"
                justifyContent="center"
              >
                <Grid item sm={12} style={{ textAlign: "end" }}>
                  <DisabledByDefaultIcon
                    onClick={() => {
                      removeSelectedImg();
                    }}
                    style={{
                      color: "#4386be",
                      fontSize: 30,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted) {
        return (
          <Grid
            style={{ height: "125px", position: "relative" }}
            container
            item
            direction="row"
            sm={12}
            alignItems="center"
            justifyContent="center"
          >
            <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
              <Dropzone
                accept={fileDetails?.allowedFormats}
                maxFiles={1}
                multiple={false}
                maxSize={fileDetails?.maxFileSize}
                onDropRejected={(fileRejections) => errorMessage(fileRejections)}
                onDrop={(acceptedFiles) => {
                  setCloseClicked(true);
                  onDrop(acceptedFiles);
                }}
                disabled={Boolean(isRevokePrivilages)}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} className={`${isRevokePrivilages ? "privilege-disable" : ""}`}>
                    {status !== 1 && (
                      <section>
                        <div>
                          <input {...getInputProps()} />
                          <FileUploadIcon
                            style={{
                              color: statusColor,
                              fontSize: 30,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </section>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
            <Grid item sm={12}>
              {previewLoading ? (
                <CircularProgressComponent size={50} />
              ) : (
                <FindInPageIcon
                  style={{
                    color: statusColor,
                    fontSize: 40,
                    cursor: "pointer",
                  }}
                  onClick={previewClicked}
                />
              )}
            </Grid>
          </Grid>
        );
      }
    };

    return (
      <>
        <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
          <Box
            style={
              imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile
                ? {
                  borderRadius: "5px",
                  backgroundColor: "#a2a2a22e",
                  margin: "auto",
                  // border: `${isRequiredFilled ? '1px solid #4386be99' : '3px solid red'}`,
                  border: `1px solid #a2a2a22e`,
                  textAlign: "center",
                }
                : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending
                  ? {
                    borderRadius: "5px",
                    margin: "auto",
                    backgroundImage: `url(${file?.preview})`,
                    backgroundSize: "cover",
                  }
                  : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted
                    ? {
                      borderRadius: "5px",
                      backgroundColor: `${statusColor}3d`,
                      margin: "auto",
                      border: `1px solid ${statusColor}`,
                      textAlign: "center",
                    }
                    : {}
            }
          >
            {renderPlaceholders()}
          </Box>

          <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>
            {fileDetails[appSettings.get().language]
              ? fileDetails[appSettings.get().language]?.name
              : fileDetails?.documentKey}
            {fileDetails.isMandatory ? <span style={{ color: "red" }}>*</span> : ""}
          </h5>
        </Grid>
      </>
    );
  }
);
