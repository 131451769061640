import { Box, Grid, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { closeBtn } from "../../../styles";

const ConfirmMultipleAssignment = (params) => {
  const closeCondition = () => {
    closeGlobalModal();
  };

  const updateConfirm = () => {
    params.callBackOnAction(params);
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={100}>
              {params.textMessage}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
            <ButtonComponent
              title="Cancel"
              startIcon={<CloseIcon />}
              variant="contained"
              onClick={closeCondition}
              loadingbtn={true}
              style={closeBtn}
            />
            <Box ml={0.5} mr={0.5}></Box>
            <ButtonComponent
              startIcon={<DoneIcon />}
              title="Yes"
              variant="contained"
              onClick={() => updateConfirm()}
              loadingbtn={true}
              color="info"
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ConfirmMultipleAssignment;
