import { Typography, Stack } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";

const StagePrivilegeSaveConfirmation = (params) => {

    const updateConfirm = () => {
        params.callBackOnAction(params);
    };

    return (
        <>
            {params?.mode === "save" ?
                <div>
                    <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                        Confirguarations Change Confirmation
                    </Typography>
                    <Stack direction={'row'} sx={{ p: 1 }} >
                        <Typography variant="subtitle2" color={"gray"}>
                            {`${params?.selectedId} configuration will be assigned to following selections.`}
                        </Typography>
                    </Stack>
                    {params?.sectionNames?.map((item, index) => {
                        return (
                            <Typography key={index} color={"gray"} variant={"caption"} fontWeight={100} component="div">
                                {`\n👉 ${item}`}
                            </Typography>
                        );
                    })}
                </div> :
                <div>
                    <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                        Confirguarations Delete Confirmation
                    </Typography>
                    <Stack direction={'row'} sx={{ p: 1 }} >
                        <Typography variant="subtitle2" color={"gray"}>
                            {`${params?.selectedId} configuration will be deleted.`}
                        </Typography>
                    </Stack>
                </div>}
            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="contained"
                    onClick={() => closeGlobalModal()}
                    loadingbtn={true}
                    color="info"
                />
                <ButtonComponent
                    title="Confirm"
                    startIcon={<SendIcon />}
                    variant="contained"
                    onClick={() => {
                        updateConfirm();
                        closeGlobalModal();
                    }}
                    loadingbtn={true}
                    color="info"
                />
            </Stack>
        </>
    )
}

export default StagePrivilegeSaveConfirmation;