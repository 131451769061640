import { Grid } from "@mui/material";

function Signatures({}) {
  return (
    <Grid container columns={24} pt={10}>
      <Grid item xs={12} pr={0.5}>
        <Grid container columns={24}>
          <Grid item xs={24}>
            <div style={{ marginBottom: "5px" }}>.............................................</div>
            <div>Signature</div>
          </Grid>
          <Grid item xs={24}>
            <h4>Loan Officer</h4>
          </Grid>
          <Grid item xs={24}>
            <Grid container columns={24}>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Name</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Service No.</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Date</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} pl={0.5}>
        <Grid container columns={24}>
          <Grid item xs={24}>
            <div style={{ marginBottom: "5px" }}>.............................................</div>
            <div>Signature and Branch Rubber Stamp</div>
          </Grid>
          <Grid item xs={24}>
            <h4>Branch Manager</h4>
          </Grid>
          <Grid item xs={24}>
            <Grid container columns={24}>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Name</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Service No.</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
              <Grid item xs={8} className="signature-verification-signature-label">
                <span>Date</span>
                <span>:</span>
              </Grid>
              <Grid item xs={16} pl={1}>
                ....................................................
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Signatures;
