import { useState } from "@hookstate/core";
import { Paper, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { addToaster } from "../../../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../../../ProgressComponent/ProgressComponent";
import { getForcastedData, saveForecastedCashFlowData } from "../../../../Api/AppraisalApi";
import {
  expensesDataKeys,
  grossProfitDataKeys,
  profitAndLossAccountsDescriptions,
  taxDataKeys,
} from "../../../../Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs";
import SubSectionHeader from "../../../../Helpers/SubSectionHeader/SubSectionHeader";
import { forCastedTitleArray } from "../../../../Stores/MicroAppraisalDefaultData";
import { filterAutoPopulatedKeys } from "../../../../Utilities/Util";
import { GeneralizedControllerForForecastedCash } from "../../Calculations/GeneralizedControllerForForecastedCash";
import FinancialPositionOfCompanyGeneralizedTableComponent from "../../Helpers/FinancialPositionOfCompanyGeneralizedTableComponent";

const ForecastedCashFlow = ({ applicationId }) => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = React.useState({});
  const grossProfitData = filterAutoPopulatedKeys(grossProfitDataKeys);
  const expenses = filterAutoPopulatedKeys(expensesDataKeys);
  const taxes = filterAutoPopulatedKeys(taxDataKeys);
  const isLoading = useState(false);

  useEffect(() => {
    getForCastedData(applicationId);

    return () => {
      setFormData({})
    }
  }, []);

  const getAllCalculations = useMemo(() => {
    const generalizedController = new GeneralizedControllerForForecastedCash(
      formData,
      forCastedTitleArray,
      grossProfitData,
      expenses,
      taxes
    );

    return {
      grossProfit: generalizedController.getTotalGrossProfit(),
      netProfitBeforeTax: generalizedController.getTotalNetProfitBeforeTax(),
      netProfitAfterTax: generalizedController.getTotalNetProfitAfterTax(),
    };
  }, [formData]);

  const onChange = (value, year, dataKey) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [year]: {
        ...prevFormData[year],
        [dataKey]: value,
      },
    }));
  };

  const onSaveForm = async () => {
    const dataTobeSave = {
      applicationId,
      forecastedCashFlowData: {
        data: { ...formData },
        totals: getAllCalculations,
        selectedYears: [...forCastedTitleArray],
        isPublish: 0,
      }
    }
    const response = await saveForecastedCashFlowData(dataTobeSave);

    if (response.status === 1) {

      addToaster({
        status: "success",
        title: "Saved!",
        message: `${translate("forecastedCashFlow")} saved successfully !`,
      });

    } else {

      addToaster({
        status: "error",
        title: "Error",
        message: `Error on Saving ${translate(
          "forecastedCashFlow"
        )} try again shortly!`,
      });
    }
  };

  const getForCastedData = async (applicationId) => {
    isLoading.set(true);

    const { data }: any = await getForcastedData(applicationId);

    if (Object.values(data).length === 0) {
      isLoading.set(false);
      return;
    }

    setFormData(data.data)

    isLoading.set(false);
  };


  return (
    <Stack width={"100%"} gap={2} component={Paper} elevation={0}>
      <SubSectionHeader
        isNeedDropdown={false}
        title={"Forecasted Cash Flow"}
        onSubmit={onSaveForm}
        dropDownProps={{
          options: [],
          profitAndLossAccounts: "",
        }}
      />

      {isLoading.get() ? <CircularProgressComponent size={30} sx={{ top: '50%', right: '50%', position: 'absolute', zIndex: 100 }} />

        :

        <FinancialPositionOfCompanyGeneralizedTableComponent
          label={"description"}
          onChange={onChange}
          defaultObject={formData}
          profitAndLossAccountsTitleArray={forCastedTitleArray}
          descriptionList={profitAndLossAccountsDescriptions}
          getAllCalculations={getAllCalculations}
        />
      }
    </Stack>
  );
};

export default ForecastedCashFlow;
