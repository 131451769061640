import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { MASTER_DATA_KEYS } from '../../../../../../configs/constants/masterDataKeys';
import { formatCurrency } from '../../../../../../services/currencyFormater';
import { closeBtn, select_basic } from '../../../../../../styles';
import MasterDataProvider from '../../../../../../utility/MasterDataProvider';
import AutoCompleteProxyComponent from '../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';

function AdditionalConditionsParameterModal(params) {
  const applicationId = params?.applicationId;
  const type = params?.type;
  const placeholder = params?.placeholder;
  const masterDataStore = MasterDataProvider.provideMasterData();

  const currentType = JSON.parse(localStorage.getItem('approvingConditions') || '{}');

  const [value, setValue] = React.useState('');
  const [selectValue, setSelectValue] = React.useState("")
  let initialData = { [applicationId]: { [type]: value } };

  const saveCondition = () => {
    if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
      params.callBackOnAction(value, type);
    }
    closeGlobalModal();
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  const withValueLimit = () => true;

  function additionalConditionInput(placeholder) {
    if (placeholder?.format === 'currency') {
      return (
        <NumericFormat
          customInput={TextBoxComponent}
          variant="outlined"
          thousandSeparator={true}
          value={formatCurrency(value)}
          decimalScale={2}
          onValueChange={(e: any) => {
            const { value } = e;
            setValue(value);
          }}
          isAllowed={withValueLimit}
          required={true}
        />
      );
    }

    if (placeholder?.format === 'dropdown') {
      const options = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.[placeholder?.masterDataKey]);

      if (options.length > 0) {
        return (
          <AutoCompleteProxyComponent
            value={selectValue}
            fullWidth={true}
            required={true}
            options={options}
            onChange={(e) => { setValue(e?.name); setSelectValue(e.id) }}
            sx={select_basic}
          />
        )
      }

      return (
        <TextBoxComponent
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          variant="outlined"
          size="small"
        />
      );
    }
    return (
      <TextBoxComponent
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        variant="outlined"
        size="small"
      />
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{`Add ${params?.modalInputLabel}`}</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title={params?.modalInputLabel}
            component={
              <Grid xs={5} item>
                {additionalConditionInput(placeholder)}
              </Grid>
            }
            isMandatory={false}
            showErrors={false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveCondition}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
}

export default AdditionalConditionsParameterModal;
