import { FC, useEffect, useState } from "react";

import { Box } from "@mui/material";

import { Downgraded } from "@hookstate/core";
import { creditData } from "../../../configs/mainStore";
import { getProductApplicationType } from "../../../services/documentUploadService";
import { ErrorMessageComponent } from "../ErrorMessageComponent";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { ProductDocumentUploadSubSection } from "./ProductDocumentUploadSubSection";

interface Props {
  applicationId: any;
  productId: any;
  applicantType: any;
  documentTypeId?: any;
  documentSectionName?: any;
  documentComponentId?: any;
  participantId?: any;
  personalData?: Object;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  reloadComponent?: any;
  isSecurityDocumentUpload?: any;
  currentWorkflowStageId?: any;
  participantType?: any;
}

export const ProductDocumentUploadComponent: FC<Props> = ({
  applicationId,
  productId,
  applicantType,
  documentTypeId,
  documentSectionName,
  documentComponentId,
  participantId,
  personalData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  reloadComponent,
  isLoggedInUserNotTheAssignee,
  participantType,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [documentTypes, setDocumentTypes] = useState<any>([]);
  const [uploadedDocumentTypes, setuploadedDocumentTypes] = useState<any>([]);
  const [mandatoryCount, setMandatoryCount] = useState<any>("");
  const [uploadedMandatoryCount, setUploadedMandatoryCount] = useState<any>("");

  const creditFileData: any = creditData.attach(Downgraded).get();
  const keyString = `${creditFileData?.formData?.creditData?.type}-${creditFileData?.formData?.creditData?.sector}-${creditFileData?.formData?.creditData?.scheme}`;

  useEffect(() => {
    setErrorMessage("");
    (async () => {
      loadDocuments();
    })();
  }, [productId, applicantType, documentTypeId]);

  const loadDocuments = async () => {
    let resFileData = await getProductApplicationType(
      applicationId,
      documentTypeId,
      participantId,
      keyString,
      participantType
    );
    let mandatoryCount = 0;
    let uploadedMandatoryCount = 0;
    if (resFileData && Object.keys(resFileData).length > 0) {
      setIsLoading(false);
      setuploadedDocumentTypes(resFileData?.selectedApplicationDocuments);
      setDocumentTypes(
        resFileData?.selectedApplicationDocuments.some((item) => item.documentId === 31)
          ? resFileData?.selectedDocumentTypes
          : resFileData?.selectedDocumentTypes.filter((obj) => obj.documentId !== 31)
      );

      for (let doc of resFileData?.selectedDocumentTypes) {
        if (doc.isMandatory === 1) {
          mandatoryCount++;
        }
      }

      const filteredUploadedDocuments = resFileData?.selectedDocumentTypes.filter((obj1) =>
        resFileData?.selectedApplicationDocuments.some((obj2) => obj1.documentId === obj2.documentId)
      );

      for (let uploadedDocs of filteredUploadedDocuments) {
        if (uploadedDocs.isMandatory === 1) {
          uploadedMandatoryCount++;
        }
      }

      setMandatoryCount(mandatoryCount);
      setUploadedMandatoryCount(uploadedMandatoryCount);
    } else {
      setErrorMessage("Something went Wrong!");
    }
  }

  const reloadContent = () => {
    loadDocuments();
    reloadComponent(true);
  }

  return (
    <Box sx={{ textAlign: "center" }}>
      {isLoading ? (
        errorMessage ? (
          <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
        ) : (
          <CircularProgressComponent
            size={30}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 100,
            }}
          />
        )
      ) : (
        <ProductDocumentUploadSubSection
          applicationId={applicationId}
          applicantType={applicantType}
          documentTypes={documentTypes}
          uploadedDocumentTypes={uploadedDocumentTypes}
          documentSectionName={documentSectionName}
          mandatoryCount={mandatoryCount}
          uploadedMandatoryCount={uploadedMandatoryCount}
          participantId={participantId}
          documentComponentId={documentComponentId}
          personalData={personalData}
          creditFileData={creditFileData}
          onSyncSuccess={onSyncSuccess}
          onSync={onSync}
          sectionLoading={sectionLoading}
          disbursed={disbursed}
          disableAccordingToTab={disableAccordingToTab}
          reloadComponent={reloadContent}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
        />
      )}
    </Box>
  );
};
