import { SxProps, TableRow, Theme } from "@mui/material";

export const TableRowWrapper = ({ children, style }: { children: any; style?: SxProps<Theme> }) => {
  return <TableRow sx={style}>{children}</TableRow>;
};


export const TableRowWrapperRevaamp = ({ children, index }) => {
  return <TableRow key={index}>{children}</TableRow>;
};


