import { Downgraded } from '@hookstate/core';
import { branchDetails } from '../../configs/mainStore';

export const getBankList = () => {
  const institutionState: any = branchDetails.attach(Downgraded).get();
  const bankList = institutionState?.bankList;
  let bankListMap = {}
  if (bankList?.length > 0) {
    bankList.map((bank, i) => {
      let id = bank?.INSTITUTION_ID;
      bankListMap[id] = {
        id: bank?.INSITITUTION_NAME,
        name: bank?.INSITITUTION_NAME,
        code: bank?.INSTITUTION_CODE,
      };
    });
    return bankListMap;
  }
};

export const getBankListWithoutRDB = () => {
  let bankList = getBankList() || {};

  if (bankList[4]) {
    delete bankList[4];
  }

  return bankList;
};

export const listOfBanks = (isNeedFilter = true) => {

  let bankList = getBankListWithoutRDB() || {};

  if (!isNeedFilter) {
    bankList = getBankList() || {}
  }

  if (bankList[1]) {
    delete bankList[1];
  }

  return bankList;
}

