import { Grid, Typography } from "@mui/material";
import React from "react";
import { memo } from "react";
import ShareholderSelect from "./ShareholderSelect";

const BuildOtherParticipantItems = ({ options, typePrefix, title, selectedPreShareholderList, callBackOnShareholderSelect }) => {
  if (options.length === 0) return <></>;

  const props = { options, typePrefix, selectedPreShareholderList, callBackOnShareholderSelect };

  return (
    <React.Fragment>
      {options.length > 0 && (
        <Grid item xs={4} mb={1.25} pl={1} pr={1}>
          <Grid container xs={12}>
            <Grid item xs={12} mb={0.5}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ShareholderSelect {...props} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default memo(BuildOtherParticipantItems);
