import { Downgraded } from "@hookstate/core";
import { ignoredKeys } from "../configs/appFormStateIgnoreKeys";
import appStrings from "../configs/constants/appStrings";
import { isArray, isObject } from "../utility/helpers/getObjectTypes";

//
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: S E R V I C E   R E L A T E D   T O   G E T   O B J E C T   C H A N G E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//

/**
 * Parameters
 * @param targetObject your new state
 * @param sourceObject your source state [ previous state ]
 * @param sectionName main section name that you wants to compare
 * @returns changes related to new state compared to old state
 */

export const getObjectChanges = (targetObject: any, sourceObject: any, sectionName = null, pureObjects = false) => {
  let newState = targetObject || {};
  let oldState = sourceObject || {};

  if (!pureObjects) {
    newState = getDowngradedState(targetObject);
    oldState = getDowngradedState(sourceObject)
  }

  //----------------------- Handler functions -----------------

  if (isObject(newState)) {
    return handleObjects(newState, oldState, sectionName);
  }

  if (isArray(newState)) {
    return handleArrays(newState, oldState, sectionName);
  }
};

//------------------------- Task functions ----------------------

/**
 *
 * @param targetObject new object
 * @param sourceObject old object
 * @returns changes
 */
const handleObjects = (targetObject: any, sourceObject: any, sectionName = null, insertCorporateId = false) => {
  const changes: any = {};

  //keys of new object
  const keys = Object.keys(targetObject);

  keys.forEach((key) => {
    if (ignoredKeys.includes(key)) return;

    //if old object contains selected key then ->  update changes
    if (sourceObject?.hasOwnProperty(key)) {
      //if selected key contains an array -> {key : [] }
      if (isArray(targetObject[key])) {
        //updated new array

        const insertCorporateId = key === "currentEmployment" || key === "previousEmployment" || key === "currentBusiness";

        const newArray = handleArrays(targetObject[key], sourceObject[key], sectionName, insertCorporateId);

        if (newArray.length > 0) {
          //adding updated new array
          let nonEmptyKeys: any[] = [];
          if (newArray.length > 0) {
            newArray.forEach((element) => {
              if (element !== "" && element !== null && element != undefined) {
                nonEmptyKeys.push(element);
              }
            });
          }

          changes[key] = nonEmptyKeys;
        }
      }
      //if selected key contains an object -> {key : {} }
      else if (isObject(targetObject[key])) {
        //updated new object

        let newObject = handleObjects(targetObject[key], sourceObject[key], sectionName, insertCorporateId);

        if (Object.keys(newObject).length > 0) {
          //adding updated new object

          newObject = addJsonUpdaterHelperKeys(key, newObject);

          changes[key] = { ...newObject };
        }
      }
      //if selected key contains pure key value pare -> {key : 'value' }
      else {
        if (sourceObject[key] !== targetObject[key]) {
          let souceDataValue = "";

          if (key === "newNicExp" || key === "oldNicExp") {
            let dateObj = new Date(targetObject[key]);
            souceDataValue = dateObj.toString();
          } else {
            souceDataValue = sourceObject?.[key]?.toString();
          }

          if (souceDataValue?.length > 0 && (targetObject[key]?.length === 0 || targetObject[key] === null || targetObject[key] == "")) {
            // ! do not edit - this key adding for identifying removed items in changed object
            changes[key] = "empty-data";
            return;
          }
          if (targetObject[key] !== "" && targetObject[key] !== null && targetObject[key] != undefined) {
            changes[key] = targetObject[key];
          } else if (targetObject[key] == "") {
            changes[key] = "empty-data";
          }
        }
      }
    }
    //if old object not contains selected key then -> insert changes
    else {
      if (isArray(targetObject[key])) {
        const insertCorporateId = key === "currentEmployment" || key === "previousEmployment" || key === "currentBusiness";
        const newArray = handleArrays(targetObject[key], [], sectionName, insertCorporateId);

        if (newArray.length > 0) {
          changes[key] = newArray;
          return;
        }
      } else if (isObject(targetObject[key])) {
        //created new object
        let newObject = handleObjects(targetObject[key], {}, sectionName, insertCorporateId);

        if (Object.keys(newObject).length > 0) {
          //adding created new object

          // *entry id is required by the DB procedures for every main section and not for personal data section
          // *entry id will ne added
          // ! do not edit - this keys added for BE and DB purposes
          if ((key === "permanentAddress" || key === "mailingAddressData" || key === "address") && !newObject.hasOwnProperty("entryId")) {
            newObject["entryId"] = "0";
          }

          changes[key] = { ...newObject };
        }
        return;
      } else {
        if (targetObject[key] !== "" && targetObject[key] !== null && targetObject[key] != undefined) {
          changes[key] = targetObject[key];
        } else if (targetObject[key] == "") {
          changes[key] = "empty-data";
        }
      }
    }
  });

  if (sectionName === appStrings?.mainSectionKeys?.creditData) {
    return prepareCreditDataChanges(changes);
  }

  return changes;
};

/**
 *
 * @param targetArray new array
 * @param sourceArray old array
 * @returns changes
 */
const handleArrays = (targetArray: any[], sourceArray: any[], sectionName = null, insertCorporateId = false) => {
  //changes array
  const changedArray: any = [];

  targetArray = Array.isArray(targetArray) ? targetArray.filter((item) => item && !item?.['removedItem']) : targetArray;
  sourceArray = Array.isArray(sourceArray) ? sourceArray?.filter((item) => item && !item?.['removedItem']) : sourceArray;


  targetArray.forEach((item: any, index: any) => {
    //if selected index is an object
    if (isObject(item)) {
      //updated object
      const newObj = handleObjects(item, sourceArray?.[index], sectionName);

      //if updated object have any changes update
      if (Object.keys(newObj).length > 0) {
        //make if sure all the keys are not containing null or "" in updated object
        if (!isEmptyObject(newObj)) {
          // "itemIndex" is appending for backend service purpose
          newObj["itemIndex"] = index;

          // ! do not edit - this keys added for BE and DB purposes
          if (!newObj.hasOwnProperty("entryId")) {
            newObj["entryId"] = "0";
          }

          // ! do not edit - this keys added for BE and DB purposes
          if (sectionName === "employedData" || sectionName === "businessData" || insertCorporateId) {
            if (!newObj.hasOwnProperty("corporateId")) {
              newObj["corporateId"] = "0";
            }
          }
        }

        changedArray[changedArray.length] = newObj;
      }
    }
    //if selected index is an array
    else if (isArray(item)) {
      //updated array
      const newArray = handleArrays(item, sourceArray[index], sectionName);

      //adding updated array into changes array
      changedArray[changedArray.length] = newArray;
    }
    //if selected index is a scalar value
    else {
      if (item !== sourceArray[index]) {
        changedArray[changedArray.length] = { item, itemIndex: index };
      }
    }
  });

  const changes = changedArray;

  return changes;
};

//----------------------- Helper Functions ----------------------

/**
 *
 * @param object object to be checked
 * @returns if all the keys of object contains either null or ""
 */
const isEmptyObject = (object) => (isObject(object) && Object.values(object).every((value) => value === null || value === "")) || false;

// * this function added specific new generated key and a value to objects for BE and DB purposes
const addJsonUpdaterHelperKeys = (targetKey, dataSet) => {
  if (!targetKey || !dataSet) return false;
  if ((targetKey === "permanentAddress" || targetKey === "mailingAddressData" || targetKey === "previousResidenceAddressData " || targetKey === "address ") && !dataSet.hasOwnProperty("entryId")) {
    dataSet["entryId"] = "0";
  }

  return dataSet;
};

const prepareCreditDataChanges = (creditDataChanges) => {
  const targetKeysToModify = ["productTypes", "sectorTypes", "schemeTypes", "applicantTypes"];

  const keyMap = {
    productTypes: "type",
    sectorTypes: "sector",
    schemeTypes: "scheme",
    applicantTypes: "applicantType",
  };
  const preparedChangeSet = JSON.parse(JSON.stringify(creditDataChanges));

  targetKeysToModify?.forEach((key) => {
    if (creditDataChanges.hasOwnProperty(key)) {
      const id = creditDataChanges?.[key]?.["id"];
      if (id) {
        preparedChangeSet[keyMap[key]] = id;
        delete preparedChangeSet[key];
      }
    }
  });

  return preparedChangeSet;
};


const getDowngradedState = (object) => {
  try {
    object = object.attach(Downgraded).get()
  } catch (error) { }
  return object
}
