import { Downgraded, useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { CORE_BANK_KEYS } from "../../../configs/constants/contants";
import { TabPropTypes } from "../../../configs/interfaces";
import { creditData, letterDocumentStructure, userDetails } from "../../../configs/mainStore";
import { getCoreBankData } from "../../../services/bankServiceInvorker";
import { getLetterDocumentsData, getTrailCalData } from "../../../services/creditFileApiCall";
import OfferLatterLayoutRevamp from "../CreditFile/Components/OfferLatterLayoutRevamp";
import { GuaranteeBond } from "../CreditFile/Components/SecurityDocuments/GuaranteeBond/GuaranteeBond";
import LetterofSetOffJB from "../CreditFile/Components/SecurityDocuments/LetterofSetOffJB/LetterofSetOffJB";
import { LetterofSetOffPA } from "../CreditFile/Components/SecurityDocuments/LetterofSetOffPA/LetterofSetOffPA";
import { LoanAgreement } from "../CreditFile/Components/SecurityDocuments/LoanAgreement/LoanAgreement";
import { LoanReceipt } from "../CreditFile/Components/SecurityDocuments/LoanReceipt/LoanReceipt";
import { PromissoryNote } from "../CreditFile/Components/SecurityDocuments/PromissoryNote/PromissoryNote";
import { SignatureVerification } from "../CreditFile/Components/SecurityDocuments/SignatureVerification/SignatureVerification";
import { StandingInstructions } from "../CreditFile/Components/SecurityDocuments/StandingInstructions/StandingInstructions";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";

interface SecurityVerticalTabComponentProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

const sectionMapping = {};

export default function SecurityVerticalTabComponent({ children, classes, disabled, disableFocusRipple, disableRipple, icon, iconPosition, label, value, wrapped, sx, orientation, tabOptions, onChange, textColor, indicatorColor, variant, labelStyle, maxHeight, showContent, applicationId, disableAccordingToTab = false, currentApplicationWfData }: TabPropTypes) {
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const expanded: any = useState({ currentExpandSection: 0, section: 0, participant: 0, key: 0 });
  const { userId }: any = userDetails.attach(Downgraded).get();
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const existingLetterDocumentData = letterDocumentStructureData.attach(Downgraded).get();
  const creditFileData: any = creditData.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== creditFileData?.currentAssignee;
  const [documentDataUpdated, setDocumentDataUpdated]: any = React.useState(false);
  const dataSource: any = useState({});

  React.useEffect(() => {
    prepareDataSource();
  }, [documentDataUpdated]);

  const prepareDataSource = async () => {
    const [existingDocumentData, coreData, trialData] = await Promise.all([
      getLetterDocumentsData(applicationId),
      getCoreBankData(applicationId, [CORE_BANK_KEYS.REC_ACC, CORE_BANK_KEYS.INVESTMENT_ACCOUNT, CORE_BANK_KEYS.INVESTMENT_AMOUNT].join(",")),
      getTrailCalData(applicationId)
    ]);

    creditFileData.coreData = coreData;
    creditFileData.trialData = trialData;

    dataSource.set({
      data: { ...creditFileData, ...currentApplicationWfData, trialData, coreData },
      applicationId: applicationId,
      coreData, trialData,
      existingDataArr: existingDocumentData,
      setDocumentDataUpdated: setDocumentDataUpdated,
      letterDocumentData: existingLetterDocumentData,
      disableAccordingToTab: disableAccordingToTab,
      isLoggedInUserNotTheAssignee: isLoggedInUserNotTheAssignee,
      currentApplicationWfData: currentApplicationWfData
    });

    setIsLoading(false);
  }

  const tabComponentsMap = {
    "offer-letter": OfferLatterLayoutRevamp,
    "sec-promissiory-note": PromissoryNote,
    "sec-loan-agreement": LoanAgreement,
    "sec-loan-receipt": LoanReceipt,
    "sec-guarantee-bond": GuaranteeBond,
    "sec-letter-set-off-pa": LetterofSetOffPA,
    "sec-letter-set-off-jb": LetterofSetOffJB,
    "sec-standing-instructions": StandingInstructions,
    "signature-verification": SignatureVerification,
  };

  if (isLoading) {
    return <Stack alignItems={"center"} justifyContent={"center"} height={"300px"} width={"100%"}>
      <CircularProgressComponent size={30} />
    </Stack>;
  }

  return <Box sx={{ flexGrow: 1, display: "flex" }}>
    <Tabs orientation="vertical" value={value} onChange={onChange} textColor={textColor} indicatorColor={indicatorColor} sx={sx} scrollButtons="auto" variant={variant}>
      {tabOptions?.length > 0 &&
        tabOptions?.map((item, key) => {
          return (
            <Tab
              disabled={disabled}
              disableFocusRipple={disableFocusRipple}
              disableRipple={disableRipple}
              icon={icon}
              iconPosition={iconPosition}
              key={item?.id}
              value={item?.id}
              label={item?.title}
              sx={labelStyle}
              onClick={() => {
                expanded?.set({
                  participant: null,
                  section: 0,
                  key: item?.id,
                });
              }}
            />
          );
        })}
    </Tabs>
    {showContent &&
      tabOptions.map((item, key) => {
        return (
          <TabPanel index={item.id} value={value} key={key}>
            {React.createElement(tabComponentsMap[item.id], dataSource.get())}
          </TabPanel>
        );
      })}
  </Box>;
}

export function TabPanel(props: SecurityVerticalTabComponentProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} style={{ width: "100%" }}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
