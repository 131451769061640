import { Box, Grid, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeBtn } from "../../../../styles";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";

const ChargeDeleteConfirm = (params) => {
  const closeCondition = () => {
    closeGlobalModal();
  };
  const deleteConfirm = () => {
    params.callBackOnAction(params);
  };
  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={100}>
              {params.textMessage ? params.textMessage : "Charge item will be deleted."}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
            <ButtonComponent title="Cancel" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
            <Box ml={0.5} mr={0.5}></Box>
            <ButtonComponent startIcon={<DoneIcon />} title="Yes" variant="contained" onClick={() => deleteConfirm()} loadingbtn={true} color="info" />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ChargeDeleteConfirm;
