import {Stack, TextField, Typography} from "@mui/material";

const BurrowerNameNicComposite = ({value, metaData}) => {

    const NicBuilder = () => {
        if (value.oldNic && value.newNic) {
            return <Stack>
                <Typography variant={'subtitle2'}>NIC:</Typography>
                <Typography variant={'subtitle1'}>{`${value.newNic} / ${value.oldNic}`}</Typography>
            </Stack>

        } else if (value.newNic) {
            return <Stack>
                <Typography variant={'subtitle2'}>NEW NIC:</Typography>
                <Typography variant={'subtitle1'}>{value.newNic}</Typography>
            </Stack>
        } else if (value.oldNic) {
            return <Stack>
                <Typography variant={'subtitle2'}>OLD NIC:</Typography>
                <Typography variant={'subtitle1'}>{value.oldNic}</Typography>
            </Stack>
        }
        return <Stack>
            <Typography variant={'subtitle2'}>NIC:</Typography>
            <Typography variant={'subtitle1'}>-</Typography>
        </Stack>
    }

    return <Stack width={"100%"} px={1} gap={1} color={"gray"}>
        <Stack width={"100%"} flex={6} direction={"row"}>
            <Stack flex={3}>
                <Typography variant={'subtitle2'}>Name:</Typography>
                <Typography variant={'subtitle1'}>{value.borrowerName ?? "-"}</Typography>
            </Stack>
            <Stack flex={2}>
                <NicBuilder/>
            </Stack>
            <Stack flex={1}>
                <Typography variant={'subtitle2'}>Contact No:</Typography>
                <Typography variant={'subtitle1'}>{value.mobile ?? "-"}</Typography>
            </Stack>

        </Stack>
        <Stack width={"100%"} flex={12} flexWrap={"wrap"} direction={"row"} gap={1}>
           <Stack flex={4}>
               <Typography variant={'subtitle2'}>Address:</Typography>
               <Typography variant={'subtitle1'}>{value.address ?? "-"}</Typography>
           </Stack>
            <Stack flex={5}></Stack>
        </Stack>

    </Stack>
}


export default BurrowerNameNicComposite;