import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { openGlobalModal } from "../GlobalModal/GlobalModal";
import { documentStatusColors } from "../WorkflowComponents/utils";

interface PriorApprovalDocumentComponentProps {
  fileDetails: any;
}

export const PriorApprovalDocumentComponent: FC<PriorApprovalDocumentComponentProps> = ({ fileDetails }: any) => {
  const previewClicked = async () => {
    fileDetails.EN = {
      name: "Prior Approval Document",
      desc: "Document index " + (fileDetails?.DOC_INDEX + 1),
    };
    openGlobalModal({
      modalSize: "xl",
      title: "",
      bodyId: "document-data-preview",
      close: false,
      modalParams: {
        variant: "prior",
        priorApprovalDocId: fileDetails?.ID,
        fileDetails: fileDetails,
      },
    });
  };

  return (
    <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
      <Box
        style={{
          borderRadius: "5px",
          backgroundColor: `${documentStatusColors[1]?.color}3d`,
          margin: "auto",
          border: `1px solid ${documentStatusColors[1]?.color}`,
          textAlign: "center",
        }}>
        <Grid
          style={{ height: "125px", position: "relative" }}
          container
          item
          direction="row"
          sm={12}
          alignItems="center"
          justifyContent="center">
          <Grid item sm={12}>
            <FindInPageIcon
              style={{
                color: documentStatusColors[1]?.color,
                fontSize: 40,
                cursor: "pointer",
              }}
              onClick={previewClicked}
            />
          </Grid>
        </Grid>
      </Box>

      <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>
        Prior Approval - {fileDetails?.DOC_INDEX + 1}
      </h5>
    </Grid>
  );
};
