import { Downgraded } from "@hookstate/core";
import { primaryIdentificationDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/primary-applicant-details/primaryIdentificationDetailsValidationOnNext";
import { otherValidationHandler } from "../../CustomHooks/useValidateSubmit";
import { validateTypedDateWithFutureDateEnabled } from "../../util";
import { messageMappings } from "./messageMapping";
import { identifyEmptyKeys, validateWithStructure } from "./structureValidation";

export const primaryDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?, formStore?, previousData?) => {
  let { personalData } = formStore;
  let finalKeyArr: any = [];
  personalData = personalData.attach(Downgraded).get();
  let errorArray = validateWithStructure(structure, state);
  let preparedErrorMessages: string[] = []
  const futureDateRelatedFieldsToValidate: any = ["dob"];

  finalKeyArr = [...errorArray, ...finalKeyArr];

  if (isGuarantor || isJointBorrower) {

    if (state?.oldNicExp?.get() != null && state?.oldNicExp?.get() != undefined && state?.oldNicExp?.get() != "") {
      futureDateRelatedFieldsToValidate.push("oldNicExp");
    }

    if (state?.newNicExp?.get() != null && state?.newNicExp?.get() != undefined && state?.newNicExp?.get() != "") {
      futureDateRelatedFieldsToValidate.push("newNicExp");
    }
  } else {

    if (personalData?.oldNicExp != null && personalData?.oldNicExp != undefined && personalData?.oldNicExp != "") {
      futureDateRelatedFieldsToValidate.push("oldNicExp");
    }

    if (personalData?.newNicExp != null && personalData?.newNicExp != undefined && personalData?.newNicExp != "") {
      futureDateRelatedFieldsToValidate.push("newNicExp");
    }
  }

  // ID Type Selective validations
  if (personalData?.idTypes?.length > 0) {
    personalData?.idTypes?.forEach((obj) => {
      if (obj.hasOwnProperty("removedItem") && obj?.removedItem === true) {
        return;
      }

      if (obj?.idType !== null && obj?.idType !== "") {
        if (obj?.idDetails === null || obj?.idDetails === "") {
          finalKeyArr.push("idDetails");
        }

        if (obj?.idExp === null || obj?.idExp === "") {
          finalKeyArr.push("idExp");
        }
      }
    });
  }

  // Date Fields Validations
  futureDateRelatedFieldsToValidate.map((field) => {
    if (isGuarantor || isJointBorrower) {
      let result = validateTypedDateWithFutureDateEnabled(state[field]?.get());
      if (result) {
        if (!finalKeyArr.includes(field)) {
          finalKeyArr.push(`futureDate_${field}`);
        }
      }
    } else {
      let result = validateTypedDateWithFutureDateEnabled(personalData[field]);
      if (result) {
        if (!finalKeyArr.includes(field)) {
          finalKeyArr.push(`futureDate_${field}`);
        }
      }
    }
  });

  if (state?.oldNic?.get() !== "" && state?.oldNicExp?.get() == null) {
    finalKeyArr[finalKeyArr.length] = "emptyOldNicIssuedDate";
  }

  if (state?.newNic?.get() !== "" && state?.newNicExp?.get() == null) {
    finalKeyArr[finalKeyArr.length] = "emptyNewNicIssuedDate";
  }

  let nicValidationResult = validatedNic({ formStore: previousData, givenNewNic: state?.newNic?.get(), givenOldNic: state?.oldNic?.get(), dataKeyArray: ["emptyNics"], isGuarantor, isJointBorrower })
  preparedErrorMessages = [...preparedErrorMessages, ...nicValidationResult]

  if (state?.oldNic?.get() && state?.newNic?.get()) {
    finalKeyArr.includes("emptyOldNicIssuedDate");
    finalKeyArr = finalKeyArr.filter((key) => key !== "emptyOldNicIssuedDate");
  }

  if (state?.legalWarrantAgainstCustomer?.get() === 0) {
    const index: any = finalKeyArr?.indexOf("specify");
    if (index > -1) {
      // only splice array when item is found
      finalKeyArr.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  if (state?.sriLankanCitizenship?.get() !== 1) {
    finalKeyArr = finalKeyArr?.filter((key) => key !== "race" && key !== "preferredCommunicationLanguage" && key !== "sriLankanCitizenshipStatus");
  }

  if (state?.civilState?.get() == 2) {
    if (state?.spouseNewNic?.get() || state?.spouseOldNic?.get()) {
      finalKeyArr = finalKeyArr?.filter((key) => key !== "spouseOldOrNewNic");
    } else {
      finalKeyArr.push('spouseOldOrNewNic')
    }
    finalKeyArr = finalKeyArr?.filter((key) => key !== "spouseOldNic" && key !== "spouseNewNic");

    if (isGuarantor || isJointBorrower) {
      finalKeyArr = finalKeyArr?.filter((key) => key !== "spouseOldOrNewNic");
    }
  } else if (state?.civilState?.get() != 2) {
    finalKeyArr = finalKeyArr?.filter((key) => key !== "spouseFullName" && key !== "spouseOldNic" && key !== "spouseNewNic" && key !== "empStatus" && key !== "spouse_occupation");
  }

  if (state?.taxPayer.get() == 1) {
    if (!state?.individualTaxNo.get()) {
      finalKeyArr[finalKeyArr.length] = "individualTaxNo";
    }
  }

  if (state?.isApplicantCloseToRdb.get() == 1) {
    const errorArrayRes = identifyEmptyKeys(state?.detailsOfApplicantCloseToRdb?.get());

    if (errorArrayRes && errorArrayRes.length > 0) {
      finalKeyArr = [...finalKeyArr, ...errorArrayRes];
    }
  }

  if (state?.sriLankanCitizenshipStatus.get() == 2) {
    finalKeyArr = finalKeyArr?.filter((key) => key !== "race");
  }

  if (state?.relationshipToMainApplicantCode.get()) {
    // Temporary fix for the release. Remove the code and handle this from application form
    finalKeyArr = finalKeyArr?.filter((key) => key !== "relationshipToMainApplicant");
  }

  // Spouse Occupation Selective Validation
  if (state?.civilState?.get() == 2) {
    if (state?.empStatus.get() == 1) {
      if (state?.occupation.get() == null || state?.occupation.get() == "") {
        if (!finalKeyArr.includes("spouse_occupation")) {
          finalKeyArr[finalKeyArr?.length] = "spouse_occupation";
        }
      }
    } else {
      if (finalKeyArr.includes("spouse_occupation")) {
        finalKeyArr = finalKeyArr?.filter((key) => key !== "spouse_occupation");
      }
    }
  }

  let primaryApplicantState = state.attach(Downgraded).get()
  preparedErrorMessages = [...preparedErrorMessages, ...messageMappings(finalKeyArr)];
  let otheErrorsResponse = primaryIdentificationDetailsValidationOnNext(primaryApplicantState);

  if (otheErrorsResponse !== 1) {
    let responseArray: string[] = otherValidationHandler(otheErrorsResponse);
    preparedErrorMessages = [...preparedErrorMessages, ...responseArray];
  }

  return preparedErrorMessages;
};


const validatedNic = ({ formStore, givenNewNic, givenOldNic, dataKeyArray, isJointBorrower, isGuarantor }) => {
  let preparedErrorMessages: string[] = [];
  let nicArray: string[] = [];
  let { personalData = {}, jointBorrowerData = [], securityData: { guarantors = [] } } = formStore;

  if (!(givenNewNic || givenOldNic)) {
    return messageMappings(dataKeyArray);
  }

  try {
    personalData = personalData.attach(Downgraded).get();
    jointBorrowerData = jointBorrowerData?.attach(Downgraded).get() || [];
    guarantors = guarantors.attach(Downgraded).get() || [];
  } catch (error) { }

  let result: string[] = []

  if (isJointBorrower || isGuarantor) {
    result = createNicArray(personalData);
  }

  if (!isJointBorrower) {
    jointBorrowerData.forEach(({ personalData: { oldNic = null, newNic = null } }) => {
      let result = createNicArray({ oldNic, newNic });
      nicArray = [...nicArray, ...result];
    });
  }

  if (!isGuarantor) {
    guarantors.forEach(({ personalData: { oldNic = null, newNic = null } }) => {
      let result = createNicArray({ oldNic, newNic });
      nicArray = [...nicArray, ...result];
    });
  }

  nicArray = [...result, ...nicArray];

  if (givenOldNic && nicArray.includes(givenOldNic)) {
    preparedErrorMessages[preparedErrorMessages.length] = `NIC cannot be same as the  Main or Other Participants's Old NIC`;
  }

  if (givenNewNic && nicArray.includes(givenNewNic)) {
    preparedErrorMessages[preparedErrorMessages.length] = `NIC cannot be same as the Main or Other Participants's New NIC`;
  }

  return preparedErrorMessages;
}

const createNicArray = (personalData) => {
  let nicArray: string[] = [];

  if (personalData?.oldNic) nicArray.push(personalData.oldNic);

  if (personalData?.newNic) nicArray.push(personalData.newNic);

  return nicArray;
}


