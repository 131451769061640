export const checkReturnDetailConfigs = [
  {
    key: "bankName",
    title: "Bank Name/ Institution Name",
  },
  {
    key: "reason",
    title: "Reason",
  },
  {
    key: "numberOfCheques",
    title: "Number of Cheques",
  },
  {
    key: "amount",
    title: "Amount",
  },
];

export const loanDetailsCribDetailConfigs = [
  {
    key: "business",
    title: "Applicants/ Business",
  },
  {
    key: "nameoOfInstitution",
    title: "Name Of Institution",
  },
  {
    key: "type",
    title: "Type",
  },
  {
    key: "grantedAmount",
    title: "Granted Amount",
  },
  {
    key: "securities",
    title: "Securities",
  },
  {
    key: "reasonOfDelay",
    title: "Reason Of Delay",
  },
  {
    key: "installment",
    title: "Installment",
  },
  {
    key: "outstanding",
    title: "Outstanding",
  },
];

export const currentAccountDetailConfigs = [
  {
    key: "bank",
    title: "Bank",
  },
  {
    key: "accountNumber",
    title: "Account Number",
  },
  {
    key: "debit",
    title: "Debit",
  },
  {
    key: "credit",
    title: "Credit",
  },
  {
    key: "balanceOfMonthEnd",
    title: "Balance Of Month End",
  },
];

export const bankGuaranteeDetailsConfigs = [
  {
    key: "financialInstitute",
    title: "Financial Institute",
  },
  {
    key: "amount",
    title: "Amount",
  },
  {
    key: "issuedDate",
    title: "Issued Date",
  },
  {
    key: "timePeriodMonths",
    title: "time Period Months",
  },
  {
    key: "issuedCompany",
    title: "Issued Company",
  },
];
