import { Downgraded, useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { downloadInspectionForm } from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { addToaster } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import { InspectionProps } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";

const JBInspections = ({ data, applicationId, inspectionPropertyDetails, handleSubmit, index, otherParams }) => {
  const { t: translate } = useTranslation();
  const jbData = data;
  const createAddressData = () => {
    const { addressData } = jbData;
    const { permanentAddress } = addressData;
    const { addressLine1, addressLine2, addressLine3, addressLine4 } = permanentAddress;
    let address = addressLine1 + "," + addressLine2;

    if (addressLine3 && addressLine4) {
      address = address + ", " + addressLine3 + ", " + addressLine4;
    } else if (addressLine3) {
      address = address + ", " + addressLine3;
    } else if (addressLine4) {
      address = address + ", " + addressLine4;
    }

    return {
      address,
    };
  };
  const [formDetails, setFormDetails]: any = React.useState({
    borrowerName: jbData?.personalData?.initialsInFull + " " + jbData?.personalData?.primaryLastName,
    newNic: jbData?.personalData?.newNic,
    oldNic: jbData?.personalData?.oldNic,
    ...createAddressData(),
    mobile: jbData?.contactData?.primaryContact,
    individualId: jbData?.personalData?.individualId,
    applicationId,
    ...inspectionPropertyDetails ?? {}
  });
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const inspetionId = useState<any>(inspetionIdState);
  const { formData } = data;



  useEffect(() => {
    const jbExistingData = inspectionFormState.jointBorrower.attach(Downgraded).get();

    if (jbExistingData && jbExistingData[jbData?.personalData?.individualId]) {
      setFormDetails((prev) => ({ ...jbExistingData[jbData?.personalData?.individualId], ...prev }));
    } else {
      setFormDetails({
        ...formDetails,
        inspetionId: inspetionId.get(),

      });
    }
  }, []);

  const onChange = (metaData: any, value: any) => {
    setFormDetails(value);
  };

  const onSubmit = async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(ApplicationFormKey.jbInspection, theDataTobeSubmitted, SectionKey.jbInspection);

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = inspectionMessageMappings(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${preparedErrorMessagges}`,
      });
    }

    handleSubmit(theDataTobeSubmitted, index);
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.jbInspection);
    } catch (error: any) {
      GlobalLogger.log("Joint Borrower Inspection", error.message);
    }
  };

  return (
    <ErrorBoundary>
      <FormBuilder applicationDataObject={formDetails} onChange={onChange} formId={ApplicationFormKey.jbInspection} title={"Joint Borrower Inspections"} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
    </ErrorBoundary>
  );
};

export default JBInspections;
