import React from "react";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import { TableColumnsTextWrapper } from "../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper";

const BuildTableColumns = ({ headerTitle, descriptionItem, index, abilityToRepayLoanDataState }) => {
  let value = 0;

  try {
    value = abilityToRepayLoanDataState["data"][descriptionItem["dataKey"]][headerTitle["dataKey"]].get();
  } catch (e) { }

  return (
    <TableColumnsTextWrapper key={"TableColumnsTextWrapper" + "element" + headerTitle["dataKey"]} index={index + headerTitle["dataKey"]} style={{ textAlign: "right" }}>
      <React.Fragment key={"Fragment" + headerTitle["dataKey"]}>{` ${formatCurrency(value ?? 0) || 0.00}`}</React.Fragment>
    </TableColumnsTextWrapper>
  );
};

export default React.memo(BuildTableColumns);
