import { Downgraded } from "@hookstate/core";
import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { postalCodeDetails } from "../../../../../../../configs/mainStore";
import isProductOf from "../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { LengthItemValidate } from "../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { addItem } from "../Helpers";
import { ExitRemoveItem } from "./Helpers/exitRemoveItem";

export const BusinessDetailsIndividual = ({ business, isMainContactDetails }) => {
  const individualData = LengthItemValidate.validatedArray(business?.individualDetails || []);
  const masterData = MasterDataProvider.provideMasterData();
  const postalCodeState = postalCodeDetails.attach(Downgraded).get();

  const masterDataObjProvider = (mapedIndividualData) => {
    const province = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.PROVINCE, mapedIndividualData?.individualD_province);
    const district = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.DISTRICT, mapedIndividualData?.individualD_distric);
    const postalCode = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.POSTAL_CODE, postalCodeState[mapedIndividualData?.individualD_postalCode]?.id);
    return {
      province,
      district,
      postalCode,
    };
  };
  const BuildIndividualDataDetails = ({ individialsData, index = null, masterDataObject }) => {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(individialsData, "Name", individialsData?.individualD_name ?? "N/A")}
          {addItem(individialsData, "New NIC", individialsData?.individualD_newNic ?? "N/A")}
          {addItem(individialsData, "Old NIC", individialsData?.individualD_oldNic ?? "N/A")}
          {addItem(individialsData, "Relationship Witht The Primary Applicant", individialsData?.individualD_relationshipWithtThePrimaryApplicant ?? "N/A")}
          {addItem(individialsData, "Address Line 1", individialsData?.individualD_addressLine1 ?? "N/A")}
          {addItem(individialsData, "Address Line 2", individialsData?.individualD_addressLine2 ?? "N/A")}
          {addItem(individialsData, "Address Line 3", individialsData?.individualD_addressLine3 ?? "N/A")}

          {addItem(individialsData, "Address Line 4", individialsData?.individualD_addressLine4 ?? "N/A")}
          {addItem(individialsData, "City", individialsData?.individualD_city ?? "N/A")}

          {addItem(individialsData, "Province", masterDataObject?.province ?? "N/A")}
          {addItem(individialsData, "District", masterDataObject?.district ?? "N/A")}
          {addItem(individialsData, "Postal Code", masterDataObject?.postalCode ?? "N/A")}
        </Grid>
      </Grid>
    );
  };

  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <div className="full-width margin-xs-b">
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
          {"Individual (Owner) Details"}
        </Grid>

        {individualData.length > 0 &&
          new ExitRemoveItem(individualData).removeRemovedItem().map((individialsData, index) => {
            const masterDataObject = masterDataObjProvider(individialsData);
            return <BuildIndividualDataDetails index={index} individialsData={individialsData} masterDataObject={masterDataObject} />;
          })}
      </div>
    );
  } else {
    return <></>;
  }
};
