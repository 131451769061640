import { State, useHookstate } from "@hookstate/core";
import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { creditData } from "../../../../../../../configs/mainStore";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { getSystemMasterConfigs } from "../../../../../../../services/applicationApis";
import { getScorecardTemplates } from "../../../../../../../services/configurationApis";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { getCopy, printFormTemplate } from "../../../../../../../utility/util";
import { Toast } from "../../../../../GlobalToast";
import InspectionHeader from "../../Components/Header";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { scoreCardQuestionDefault } from "../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { scoreCardQuestions } from "../../FormBuilder/Tools/Helpers/Validations/Forms/ScoreCardQuestions";

export type ScoreCardQuestionsProviderProps = {
    isLoading: State<boolean>;
    initializeScoreCardQuestionLayout: () => Promise<void>;
    otherParams: Record<string, any>;
    scoreCardLayout: Record<string, any[]>;
    setCurrentSelectedButton: React.Dispatch<React.SetStateAction<string>>;
    currentSelectedButton: string;
    onChangeValues: (metaData: any, val: any) => void;
    formDetails: Record<string, string>;
    clearAllSubcriptions: () => void;
    errorStatus: boolean;
    layoutMap: Map<string, ScoreCardLayoutResponse>;
}

export type ScoreCardLayoutResponse = {
    catalog: string;
    customerType: number;
    description: string;
    key: string;
    name: string;
    questionIds: number[];
}

export interface DefaultScoreCardConfigResponse {
    children: DefaultScoreCardConfigResponse[] | [];
    _id: string;
    type: string;
    title?: string;
    subtitle?: string;
    dataKeyMappingId?: string;
    inputOptions?: {
        customOptions?: string[] | [];
        masterDataApiId?: number;
        source?: string;
        category?: string;
    }
    path?: string;
    lastModified: Record<string, string>;
    dataKey: string;
}

export const ScoreCardQuestionsProviderContext = createContext<ScoreCardQuestionsProviderProps | undefined>(undefined);

export const CreditInspectionScoreCardQuestionProvider: React.FC<any> = ({ data, applicationId, innerComponentData, tabsToEdit, children }) => {
    const { t: translate } = useTranslation();
    const [scoreCardLayout, setScoreCardLayout] = React.useState<Record<string, any[]>>({} as Record<string, any[]>);
    const inspectionFormState: any = useHookstate<InspectionProps>(inspectionForm);
    const inspetionId = useHookstate<any>(inspetionIdState);
    const [formDetails, setFormDetails]: any = React.useState(scoreCardQuestionDefault);
    const { formData } = data;
    const creditFileData: any = useHookstate(creditData);
    const { currentProductCatalogKey } = creditFileData.get();
    const isLoading = useHookstate<boolean>(true);
    const [currentSelectedButton, setCurrentSelectedButton] = React.useState<string>("" as string);
    const inspectionFormStore = useHookstate<any>(inspectionFormState);
    const [errorStatus, setErrorStatus] = React.useState<boolean>(false);
    const [layoutMap, setLayoutMap] = React.useState<Map<string, ScoreCardLayoutResponse>>(new Map<string, ScoreCardLayoutResponse>());

    const otherParams: any = {
        formData,
        applicationId,
        innerComponentData,
        tabsToEdit,
    };

    const getExistingInspectionForm = async () => {
        try {
            const { response }: { data: Object, status: number } | any = await getFilledPendingInpectionFormData(applicationId);

            if (!response) {
                throw new Error("No response from API");
            }

            const { ID: inspectionId = null, INSPECTION_DATA: inspectionDataRaw = '{}' } = response;

            // Attempt to parse INSPECTION_DATA, fallback to empty object if parsing fails
            let inspectionData: any = {};
            try {
                inspectionData = JSON.parse(inspectionDataRaw);
            } catch (error) {
                console.error("Error parsing INSPECTION_DATA:", error);
            }

            const { scoreCard: data = {} } = inspectionData;

            // Update state values
            inspectionFormState.scoreCard.set(data);
            inspetionId.set(inspectionId);

            // Set form details if data exists
            if (Object.keys(data).length > 0) {
                setFormDetails(data);
            }
        } catch (error) {
            console.error("Error fetching inspection form:", error);
            Toast.error("Something went wrong while fetching data...!", "Error");
        } finally {
            isLoading.set(false);
        }
    };

    const sanitizedFormStructure = (questionIds = [] as number[], defaultQuestions = [] as DefaultScoreCardConfigResponse[]): DefaultScoreCardConfigResponse[] => {
        const questionIdsSet = new Set(questionIds);

        return defaultQuestions.map((question: DefaultScoreCardConfigResponse) => {
            const { children = [] } = question;

            if (children.length) {
                question.children = sanitizedFormStructure(questionIds, children).length ? sanitizedFormStructure(questionIds, children) : children;
                return question;
            }
            if (questionIdsSet.has(Number(question.dataKeyMappingId))) {
                return question;
            }
            return undefined;
        }).filter((question): question is DefaultScoreCardConfigResponse => question !== undefined);
    }

    const initializeScoreCardQuestionLayout = async () => {
        let scoreCardFinalObject = {} as Record<string, any[]>;

        try {
            const [scoreCardLayouts, defaultScoreCard]: [ScoreCardLayoutResponse[] | undefined, DefaultScoreCardConfigResponse, void] = await Promise.all([
                getScorecardTemplates({ catalog: currentProductCatalogKey }), getSystemMasterConfigs({ key: "default-score-card-questions-form" }), getExistingInspectionForm()
            ]);

            if (!scoreCardLayouts || !scoreCardLayouts.length) {
                scoreCardFinalObject["defaultScoreCard"] = defaultScoreCard.children;
                throw new Error(`Please configure the score card questions for this product -  ${currentProductCatalogKey}`);
            }

            const scoreCardLayoutsMap = new Map<string, ScoreCardLayoutResponse>(scoreCardLayouts.map((layout: ScoreCardLayoutResponse) => [layout.key, layout]));

            setLayoutMap(scoreCardLayoutsMap);

            scoreCardLayouts.forEach((layout: ScoreCardLayoutResponse) => {
                const defaultScoreCardCopy = getCopy(defaultScoreCard);
                scoreCardFinalObject[layout.key] = sanitizedFormStructure(layout.questionIds, defaultScoreCardCopy.children);
            });

        } catch (error) {
            setErrorStatus(true)
        } finally {
            setCurrentSelectedButton(Object.keys(scoreCardFinalObject)[0] as string);
            setScoreCardLayout(scoreCardFinalObject);
            isLoading.set(false);
        }
    }

    const downloadInspectionFormTemplate = async () => {
        try {
            printFormTemplate(downloadInspectionForm, SectionKey.scoreCardQuestion);
        } catch (error: any) {
            GlobalLogger.log("inspection form", error.message);
        }
    };

    const onSubmit = async () => {
        const theDataTobeSubmitted = {
            ...formDetails,
        };

        let errorArray = scoreCardQuestionValidations();

        if (errorArray && errorArray.length > 0) {
            const preparedErrorMessagges = errorArray.join("\n");
            return Toast.error(`${preparedErrorMessagges}`, "Mandatory Validation Error");
        }

        const respose: any = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.scoreCardQuestion, inspectionFormState, applicationId);

        if (respose.status == 1) {
            return Toast.success("Successfully Submitted Inpection Form", "SuccessFull");
        } else {
            return Toast.error("Something went wrong while Submitting Inspection Form..!", "Error");
        }
    };

    const onChangeValues = (metaData, val) => {
        setFormDetails(prev => ({
            ...prev,
            [currentSelectedButton]: val,
        }));
    }

    const clearAllSubcriptions = () => {
        inspectionFormStore.set(prev => {
            delete prev?.[ApplicationFormKey.cultivationModel];

            return prev;
        })
    }

    const scoreCardQuestionValidations = (): string[] => {
        return scoreCardQuestions(scoreCardLayout, formDetails);
    }

    return (
        <ScoreCardQuestionsProviderContext.Provider value={{
            isLoading,
            initializeScoreCardQuestionLayout,
            otherParams,
            scoreCardLayout,
            currentSelectedButton,
            setCurrentSelectedButton,
            onChangeValues,
            formDetails,
            clearAllSubcriptions,
            errorStatus,
            layoutMap,
        }}>
            <InspectionHeader children={children} title={"Inspection Scorecard Questions"} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
        </ScoreCardQuestionsProviderContext.Provider>
    )
}

export const useScoreCardQuestions = (): ScoreCardQuestionsProviderProps => {
    const context: ScoreCardQuestionsProviderProps | undefined = useContext(ScoreCardQuestionsProviderContext);

    if (!context) {
        throw new Error('useScoreCardQuestions must be used within a ScoreCardContecx');
    }

    return context;
}