import { Downgraded, useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import SubTitleTypeTwo from '../../../../../Helpers/SubTitleTypeTwo/SubTitleTypeTwo';
import { comprehensiveDefaultDataObject } from '../../../../../Stores/MicroAppraisalDefaultData';
import { comprehensiveProfitAndLoanAccountsData } from '../../../../../Stores/MicroAppraisalStore';
import { revenueAndIncome } from '../../../../../Utilities/Util';
import Comprehensive from '../Comprehensive';

const RevenueAndIncome = () => {
    const { t: translate } = useTranslation();
    const formData = useState(comprehensiveProfitAndLoanAccountsData["data"]["revenues"]);
    const selectedYears = useState<any>(comprehensiveProfitAndLoanAccountsData["selectedYears"]);

    return (
        <>
            <SubTitleTypeTwo title={translate("revenueIncome")} />
            <Comprehensive mainTitle={translate("revenueIncome")} titleYearArray={selectedYears.attach(Downgraded).get()} formData={formData} defaultDataObject={comprehensiveDefaultDataObject} options={revenueAndIncome} />

        </>
    )
}

export default RevenueAndIncome