import { Box, Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { documentStatusColors } from "../WorkflowComponents/utils";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { openGlobalModal } from "../GlobalModal/GlobalModal";

interface InspectionDocumentComponentProps {
  fileDetails: any;
  onNext: any;
}

export const InspectionDocumentComponent: FC<InspectionDocumentComponentProps> = ({ fileDetails, onNext }: any) => {
  const [previewFile, setPreviewFile]: any = useState({});
  const [onNextDisable, setOnNextDisable]: any = useState(false);

  const getNextDoc = () => {
    const currentFile = previewFile;

    const file = onNext.find(obj => obj.doc_index === currentFile.doc_index + 1);

    if (file) {
      setPreviewFile(file)
    } else {
      setOnNextDisable(true);
    }
  }

  const previewClicked = async () => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "inspection-document-data-preview",
      close: true,
      modalParams: {
        fileDetails: previewFile,
        onNext: getNextDoc,
        onNextDisable
      },
    });
  };

  useEffect(() => {
    if (Object.keys(previewFile)?.length > 0) {
      previewClicked()
    }
  }, [previewFile])

  return (
    <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
      <Box
        style={{
          borderRadius: "5px",
          backgroundColor: `${documentStatusColors[fileDetails?.status]?.color}3d`,
          margin: "auto",
          border: `1px solid ${documentStatusColors[fileDetails?.status]?.color}`,
          textAlign: "center",
        }}>
        <Grid
          style={{ height: "125px", position: "relative" }}
          container
          item
          direction="row"
          sm={12}
          alignItems="center"
          justifyContent="center">
          <Grid item sm={12}>
            <FindInPageIcon
              style={{
                color: documentStatusColors[fileDetails?.status]?.color,
                fontSize: 40,
                cursor: "pointer",
              }}
              onClick={() => {
                setPreviewFile(fileDetails);
                previewClicked
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>
        Inspection - {fileDetails?.doc_index + 1}
      </h5>
    </Grid>
  );
};
