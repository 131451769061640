import { Paper, Stack } from "@mui/material";
import React from "react";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { analysisLoanRequirementConfigs } from "../../Configs/AnalysisLoanRequirementConfigs/AnalysisLoanRequirementConfigs";
import TableBodyWrapper from "../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { TableHeaderWrapperCustom } from "../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import TableWrapper from "../../Helpers/TableContainer/TableWrapper/TableWrapper";
import AnalysisOfLoanRequirementTableBuildRow from "./subComponents/AnalysisOfLoanRequirementTableBuildRow";

const AnalysisLoanRequirement = ({ creditData, applicationId, assignLoanRequirementList, callBackOnDelete, disableStatus }) => {
  let titleArray: any[] = analysisLoanRequirementConfigs.length > 0 ? analysisLoanRequirementConfigs.map(({ title }) => title) : [];

  if (titleArray.length === 0) return <></>

  return (
    <Stack component={Paper} elevation={0} width={"100%"} height={"100%"}>
      <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
        <TableWrapper>
          <TableHeaderWrapperCustom headerArray={titleArray} />
          <TableBodyWrapper>
            <AnalysisOfLoanRequirementTableBuildRow data={assignLoanRequirementList} callBackOnDelete={callBackOnDelete()} disableStatus={disableStatus} />
          </TableBodyWrapper>
        </TableWrapper>
      </React.Suspense>
    </Stack>
  );
};

export default AnalysisLoanRequirement;
