import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { getTaskList } from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import TaskItemRow from "./TaskItemRow";

import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import {
  creditData,
  userDetails,
  workflowMasterData,
} from "../../../../../configs/mainStore";
import {
  getTaskAssigneeRequest,
  saveTaskAssignee,
} from "../../../../../services/applicationApis";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import {
  closeGlobalModal,
  openGlobalModal,
} from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";

const ApplicationTasks = ({
  data,
  applicationId,
  currentApplicationWfData,
  innerComponentData,
}) => {
  const taskList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeTasks, setActiveTasks]: any = React.useState([]);
  const [updatetaskList, setUpdateTaskList]: any = React.useState([]);
  const { wf_stages, wf_system_users }: any = useState(workflowMasterData);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      if (applicationId) {
        const data = await getTaskList(applicationId);
        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "taskId"));
          taskList.set(sortedList);
        }
      }

      setIsLoading(false);
    })();
  }, [updatetaskList]);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  const tempData = taskList?.get()?.map((data) => data);

  const user = wf_system_users?.get()?.find((usr) => usr?.id === tempData.id);

  const callBackOnAction = (newTask) => {
    const tasks = JSON.parse(JSON.stringify(taskList.get()));
    const sortedList = [...tasks, newTask]?.sort((a, b) =>
      compare(b, a, "taskId")
    );

    taskList.set(sortedList);
  };

  const callBackOnActionEdit = (updatedData) => {
    const tasks = JSON.parse(JSON.stringify(taskList.get()));

    let newList: any = [];
    tasks.forEach((task, index) => {
      if (task.taskId === updatedData.taskId) {
        newList[index] = { ...task, ...updatedData };
      } else {
        newList[index] = task;
      }
    });

    taskList.set([...newList]);
  };
  const viewAssignee = async (modalBodyKey, applicantId) => {
    const { data: assigneeLisData } = await getTaskAssigneeRequest(applicantId);

    let taskData: any = [];
    let taskNames = {};

    assigneeLisData.forEach((item) => {
      taskData[item.groupId] = taskData[item.groupId] || [];
      taskData[item.groupId].push(item);
      taskNames[item.groupId] = item.groupName;
    });

    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        list: taskData,
        groupNames: taskNames,
        handleSaveAssignee: handleSaveAssignee,
      },
    });
  };

  const handleSaveAssignee = async (selectedAssignee) => {
    let alertPayload = {
      status: "error",
      title: "Failed",
      message: "Failed to save change assignee",
    };

    try {
      const dataObj = {
        taskIds: activeTasks,
        userId: selectedAssignee,
      };

      const response = await saveTaskAssignee(dataObj);
      if (response.status === 1) {
        alertPayload.status = "success";
        alertPayload.title = "Success";
        alertPayload.message = "Task assignee Successfully Added";

        const changedAssigneeTaskList = response.data;
        setUpdateTaskList(changedAssigneeTaskList);
      }
    } catch (error: any) {
      alertPayload.message = error.message;
    } finally {
      addToaster(alertPayload);
      closeGlobalModal();
    }
  };

  const selectAllRowClicked = (e) => {
    if (e.target.checked) {
      let tasks: any = [];
      taskList.get().forEach((element: any) => {
        if (element.taskStatus === 0) {
          tasks.push(element.taskId);
        }
      });
      setActiveTasks(tasks);
    } else {
      setActiveTasks([]);
    }
  };

  const updateActiveTasks = (status, taskId) => {
    let subTasks: any = [];
    if (activeTasks.includes(taskId)) {
      subTasks = activeTasks.filter((t) => parseInt(t) !== taskId);
    } else {
      subTasks = [...activeTasks, taskId];
    }
    setActiveTasks(subTasks);
  };


  return (
    <div
      style={{
        overflowX: "hidden",
      }}
    >
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={currentApplicationWfData}
        callBackOnAction={callBackOnAction}
        disabled={
          getIsDisbursed(privilegeKeys.TASKS_TAB_CREATE_TASK, disbursed) ||
          isLoggedInUserNotTheAssignee
        }
      />

      {userId === data?.currentAssignee ? (
        <Grid
          container
          p={1}
          mt={1}
          className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
          style={{ backgroundColor: "#e6f0fa" }}
          justifyContent="space-between"
        >
          <Grid item>
            <CheckBoxComponent onChange={selectAllRowClicked} />
            Select Tasks
          </Grid>
          <Grid item>
            <ButtonComponent
              onClick={() => {
                viewAssignee("task-assignee-modal", applicationId);
              }}
              title={"Change Assignee"}
              variant={"contained"}
              disabled={activeTasks.length === 0}
            />
          </Grid>
        </Grid>
      ) : <></>}

      <Stack flex={1} spacing={1} m={1} className="full-width">
        {isLoading ? (
          <CircularProgressComponent
            size={30}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 100,
            }}
          />
        ) : taskList && taskList.length > 0 ? (
          taskList
            ?.get()
            ?.map((data) => (
              <TaskItemRow
                data={data}
                applicationId={applicationId}
                key={data.taskId}
                callBackOnActionEdit={callBackOnActionEdit}
                activeTasks={activeTasks}
                updateActiveTasks={updateActiveTasks}
              />
            ))
        ) : (
          <ErrorMessageComponent
            headMessage={"No data available"}
            errorMessage={""}
          />
        )}
      </Stack>
    </div>
  );
};

export default ApplicationTasks;
