import { Downgraded } from "@hookstate/core";
import React, { useMemo } from "react";
import { openGlobalModal } from "../../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../../GlobalToast";
import { TableRowWrapper } from "../../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableColumnsTextWrapperAnyNew from "../../../../Helpers/inputHelpers/TableColumnsTextWrapperAnyNew";
import TableColumnsTextBoxWrapperAlphanumericNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperAlphanumericNew";
import TableColumnsTextBoxWrapperNumberNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperNumberNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";

const ChequeReturnsTableBuildRow = ({ data, callBackOnDelete }) => {
  const dataKeys: any[] = Object.keys(data) || [];
  const [totalNumberOfCheques, setTotalNumberOfCheques]: any = React.useState(0);
  const [totalAmount, setTotalAmount]: any = React.useState(0);

  useMemo(() => {
    let _numberOfCheques = 0;
    let _amount = 0;

    dataKeys?.map((key, index) => {
      const { numberOfCheques, amount } = data[key];
      _numberOfCheques += parseFloat(numberOfCheques?.get());
      _amount += parseFloat(amount?.get());
    });

    setTotalNumberOfCheques(_numberOfCheques);
    setTotalAmount(_amount);
  }, [data]);

  const dataObj = data.attach(Downgraded).get();
  const onchangeHandler = (value, dataKey, dataIndex) => {
    dataObj[dataIndex][dataKey] = value;
    data?.set(dataObj);
  };

  const itemRowDeleteDispatcher = (key: any, type = "") => {
    if (key) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete the row item!.",
          callBackOnAction: () => callBackOnDelete(key, type),
        },
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty Item Key",
        message: "Item key is required to proceed the deletion",
      });
    }
  };

  return (
    <>
      {dataKeys?.length > 0 &&
        dataKeys?.map((key, index) => {
          const { bank, reason, numberOfCheques, amount } = data[key];

          return (
            <>
              <TableRowWrapper>
                <TableColumnsTextWrapperAnyNew index={index + "bank"} dataKey={"bank"} dataIndex={key} onchangeHandler={onchangeHandler}>
                  <>{bank?.get()}</>
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "reason"} value={reason} index={index + "reason"} title={""} dataKey={"reason"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "numberOfCheques"} value={numberOfCheques} index={index + "numberOfCheques"} title={""} dataKey={"numberOfCheques"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "amount"} value={amount} index={index + "amount"} title={""} dataKey={"amount"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextWrapperAnyNew key={index + "actions"} index={index + "actions"} style={{ textAlign: "right" }}>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => itemRowDeleteDispatcher(key, "chequeReturn")}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableColumnsTextWrapperAnyNew>
              </TableRowWrapper>
            </>
          );
        })}

      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"total_label"}>
          <>Total</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty1"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalNumberOfCheques"}>{formatCurrency(totalNumberOfCheques)}</TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalAmount"}>{formatCurrency(totalAmount)}</TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
    </>
  );
};

export default ChequeReturnsTableBuildRow;
