import React, { createRef, useEffect } from "react";

import { Downgraded, useState } from "@hookstate/core";
import { userDetails } from "../../../../../../configs/mainStore";
import { SubSectionsWrapper } from "../../../../SubSectionsWrapper/SubSectionsWrapper";
import { ErrorMessageComponent } from "../../UserCreditComponents/ErrorMessageComponent";
import { DocumentUploadSectionWrapper } from "../../../../MainSectionsWrapper/DocumentUploadSectionWrapper";
import { ApplicationDocumentUploadSection } from "../../../../ProductDocumentUploadComponent/ApplicationDocumentUploadSection";
import { getProductApplicationType } from "../../../../../../services/documentUploadService";
import AppConfig from "../../../../../../utility/AppConfig";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import { Stack } from "@mui/material";

export const CollateralDocumentsSubSection = ({
  participantId,
  applicationId,
  documentSectionName,
  documentTypeIds,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disableAccordingToTab = false,
  isLoggedInUserNotTheAssignee,
  participantType,
  applicantType,
}: any) => {
  creditFileData = creditFileData.attach(Downgraded).get();

  const productCatalog = `${creditFileData?.formData?.creditData?.type}-${creditFileData?.formData?.creditData?.sector}-${creditFileData?.formData?.creditData?.scheme}`;
  const userDetailsData = useState(userDetails);
  const { userId }: any = userDetailsData.attach(Downgraded).get();
  const [documentTypes, setDocumentTypes] = React.useState<any>({});
  const [primaryFiles, setPrimaryFiles] = React.useState<any>([]);
  const [normalFiles, setNormalFiles] = React.useState<any>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [uploadedMandatoryCount, setUploadedMandatoryCount] =
    React.useState<any>("");

  useEffect(() => {
    buildDocuments();
  }, []);

  const buildDocuments = async () => {
    const _documentTypes = {};
    let executions: any = [];
    for (const documentTypeId of documentTypeIds) {
      documentTypes[documentTypeId] = [];
      executions.push(
        getProductApplicationType(
          applicationId,
          documentTypeId,
          participantId,
          productCatalog,
          documentTypeId
        )
      );
    }
    // selectedApplicationDocuments

    const resFileData: any = await Promise.all(executions);
    setIsLoading(false);
    const keys = Object.keys(documentTypes);
    resFileData.forEach((element, index) => {
      _documentTypes[keys[index]] = {
        documents: element.selectedDocumentTypes ?? [],
        total: element.selectedDocumentTypes.length,
        selectedDocuments: element.selectedApplicationDocuments ?? [],
        required: element.selectedDocumentTypes.filter(
          (obj) => obj.isMandatory === 1
        ).length,
        name: AppConfig.config.documentTypes[keys[index]],
        ref: createRef(),
      };
    });

    setDocumentTypes(_documentTypes);
  };

  // const reloadComponent = async (element?: any) => {
  //   setIsLoading(true);
  //   let data: any;

  //   if (Object.values(documentDetailsList.get()).length <= 0) {
  //     data = await getDocumentDetailsList();
  //   } else {
  //     setDocumentDetailItemList(documentDetailsList.get());
  //     setIsLoading(false);
  //   }
  // };

  const submitBtnClick = async () => {
    const refs = Object.values(documentTypes).map((doc: any) => doc.ref);

    const result = await Promise.all(
      refs.map((ref) => ref.current?.normalDocumentUploadFunc())
    );

    if (result) {
      // reloadComponent(true);
      buildDocuments();
    }
  };

  if (isLoading) {
    return (
      <Stack direction={"row"} justifyContent={"center"}>
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            zIndex: 100,
          }}
        />
      </Stack>
    );
  }

  if (errorMessage.length > 0) {
    return (
      <ErrorMessageComponent
        headMessage={"Empty"}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <Stack>
      {Object.keys(documentTypes).map((documentId, i) => {
        const document = documentTypes[documentId];
        return (
          <DocumentUploadSectionWrapper
            title={document.name}
            keyCode={1}
            noBack={true}
            loading={isUploading}
            onNext={submitBtnClick}
            totalFiles={document.total}
            numOfUploadedFiles={document?.selectedDocuments?.length ?? []}
            numOfMandatory={document.required}
            numOfUploadedMandatoryFiles={uploadedMandatoryCount}
            subTxt={`Upload ${document.name}`}
            documentComponentId={documentComponentId}
            personalData={personalData}
            creditFileData={creditFileData}
            onSyncSuccess={onSyncSuccess}
            onSync={onSync}
            sectionLoading={sectionLoading}
            disableAccordingToTab={disableAccordingToTab}
            documentTypes={document}
            documentTypeId={documentId}
          >
            <SubSectionsWrapper title="">
              <ApplicationDocumentUploadSection
                ref={document.ref}
                primaryFiles={primaryFiles}
                applicantType={applicantType}
                applicationId={applicationId}
                participantId={participantId}
                uploadedBy={userId}
                normalFiles={normalFiles}
                documentTypes={document.documents}
                uploadedDocumentTypes={document.selectedDocuments}
                documentComponentId={documentComponentId}
                disableAccordingToTab={disableAccordingToTab}
                isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
              />
            </SubSectionsWrapper>
          </DocumentUploadSectionWrapper>
        );
      })}
    </Stack>
  );
};
