import { Downgraded, createState, useState } from "@hookstate/core";
import { Button, Dialog, DialogActions, DialogTitle, Stack } from "@mui/material";
import React, { FC, Suspense } from "react";
import Loading from "react-loading";
import ErrorBoundary from "../../ErrorHandleComponent/ErrorBoundary";
import { modalComponentMapping } from "./modalMapping";

export interface globalModelPropsTypes {
  open: boolean;
  modalSize: any;
  title: any;
  handleClose?: any;
  bodyId?: any;
  close?: any;
  modalParams?: any;
  save?: any;
  actions?: JSX.Element;
}

const globalModalData = createState<globalModelPropsTypes>({
  open: false,
  title: "",
  modalSize: "lg",
  bodyId: null,
  close: true,
  modalParams: {},
  save: true,
  actions: undefined,
});

export const openGlobalModal = ({ modalSize, title = "", bodyId, close = false, modalParams, save = false, actions = <></> }) => {
  const modalObject: globalModelPropsTypes = globalModalData.attach(Downgraded).get();
  globalModalData.set({ ...modalObject, ...{ open: true, modalSize, title, bodyId, close, modalParams, save, actions } });
};

export const closeGlobalModal = () => {
  const modalObject: globalModelPropsTypes = globalModalData.attach(Downgraded).get();
  globalModalData.set({ ...modalObject, ...{ open: false, modalParams: {} }, bodyId: null });
};

export const GlobalModal: FC = () => {
  const storeModalData = useState(globalModalData);
  const modalObject: globalModelPropsTypes = storeModalData.attach(Downgraded).get();

  if (!modalObject?.bodyId) {
    return <></>;
  }

  const component = modalComponentMapping?.[modalObject?.bodyId] || "";

  return <ErrorBoundary>
    <Dialog fullWidth={true} maxWidth={modalObject?.modalSize} open={modalObject?.open}>
      {modalObject?.title && <DialogTitle style={{ top: '0px', position: 'sticky', zIndex: 1 }}>{modalObject?.title}</DialogTitle>}
      {component ? <Suspense fallback={<Loading />}><Stack py={1} px={3}>{React.createElement(component, modalObject?.modalParams)}</Stack></Suspense> : <Stack>No Component found.</Stack>}
      <DialogActions>
        {modalObject?.actions && <Suspense fallback={<Loading />}>{modalObject?.actions}</Suspense>}
        {modalObject?.close && (
          <Button
            onClick={() => {
              closeGlobalModal();
            }}
          >
            Close
          </Button>
        )}
        {modalObject?.save && (
          <Button title={"Add"}
            onClick={() =>
              modalObject?.modalParams?.callBackOnSave()
            }
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </ErrorBoundary>;
};
