import { useState } from "@hookstate/core";
import { Box } from "@mui/material";
import { workflowMasterData } from "../../../../../../../../../configs/mainStore";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";
import { WorkflowStructuralChangeConfig } from "../../../../../../utils";
import EditEmailJobModalLayout from "./EditEmailJobModalLayout";

const EditEmailJobModalContainer = ({ selectData, isStructuralChange, isEdit, onSave }: any) => {
  const { wf_email_templates, wf_hierarchies, wf_groups, wf_system_users }: any =
    useState(workflowMasterData);
  const userMasterObject = {
    hierarchies: [...wf_hierarchies.get()],
    groups: [...wf_groups.get()],
    users: [...wf_system_users.get()],
  };
  const emailJobData = useState({
    templatesEmail: [...wf_email_templates.get()],
    toAddress: userMasterObject,
    ccAddress: userMasterObject,
    bccAddress: userMasterObject,
  });
  const isLoading = useState(false);

  const onChange = (e, type, id) => {
    isLoading?.set(true);
    if (type === "templatesEmail") {
      const matchedData = emailJobData[type].get().find((job) => job.id === e.target.value);
      const d = { ...matchedData };

      selectData[type].set(d);
    } else {
      const data = e;
      const matchedArray: any = [];
      const dataKey = id;

      data.forEach((item) => {
        const matchedData = emailJobData[dataKey].get()[type].find((job) => job.name === item);
        if (matchedData) {
          matchedArray.push({ ...matchedData });
        }
      });
      const { groups, hierarchies, users, ...prevOther } = selectData[dataKey]?.get();
      const groupsNew = type === "groups" ? matchedArray : groups.map((item) => ({ ...item }));
      const hierarchiesNew =
        type === "hierarchies" ? matchedArray : hierarchies.map((item) => ({ ...item }));
      const usersNew = type === "users" ? matchedArray : users.map((item) => ({ ...item }));

      selectData[dataKey].set({
        ...prevOther,
        groups: groupsNew,
        hierarchies: hierarchiesNew,
        users: usersNew,
      });
    }

    // trigger structural change occur only for job change
    if (WorkflowStructuralChangeConfig.nodePermissionChange) {
      isStructuralChange.set(true);
    }
    isLoading?.set(false);

  };

  return (
    <>
      {isLoading?.get() ? (
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Box>
      ) : (
        <EditEmailJobModalLayout onChange={onChange} emailData={emailJobData} isEdit={isEdit} selectData={selectData} onSave={onSave} />
      )}
    </>
  )
};

export default EditEmailJobModalContainer;
