import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { userDetails } from "../../../../../configs/mainStore";
import {
  getReferSubFlowList
} from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import ReferItemRow from "./ReferItemRow";

import { getUserGroupsForReports } from "../../../../../services/applicationApis";
import { fetchPrivilegeGroups } from "../../../../../services/configurationApis";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";

const ApplicationRefer = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const referList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOfficerGroup, setOfficerGroup] = React.useState(false);
  const [officerGroupId, setOfficerGroupId] = React.useState<any>(null);
  const [referalGroupIDs, setReferalGroupIDs] = React.useState({});
  const userDetailsData: any = userDetails.attach(Downgraded).get();
  const [actionUpdate, setActionUpdate]: any = React.useState(false);

  // allowed to create referral iff,
  // 1). logged in user is the application assignee or
  // 2). if the user is one of the referee of unclosed referral

  useEffect(() => {
    (async function () {
      const referalIDList = await getUserGroupsForReports();

      setReferalGroupIDs(referalIDList);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const response = await getReferSubFlowList(applicationId);
        if (response && response?.length > 0) {
          referList.set(response);
        }
      }
      setIsLoading(false);
    })();
  }, [actionUpdate]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const getGroups = await fetchPrivilegeGroups(userDetailsData?.userId);

      if (getGroups?.status === 1) {
        const groupIds = getGroups?.data.map((item) => item.groupId);

        const cauOfficerId = await getUserGroupsForReports();

        const incGroupId = groupIds.includes(Number(cauOfficerId?.cauOfficer));

        if (incGroupId === true) {
          setOfficerGroup(true);
          setOfficerGroupId(Number(cauOfficerId?.cauOfficer));
        } else {
          setOfficerGroup(false);
          setOfficerGroupId(null);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const callBackOnAction = (newRefer) => {
    const refers = JSON.parse(JSON.stringify(referList.get()));
    const sortedList = [...refers, newRefer]?.sort((a, b) => compare(b, a, "referId"));

    referList.set(sortedList);
  };

  const callBackOnActionEdit = (updatedData) => {
    const refers = JSON.parse(JSON.stringify(referList.get()));

    let newList: any = [];
    refers &&
      refers.length > 0 &&
      refers?.forEach((refer, index) => {
        if (refer.referId === parseInt(updatedData.referId)) {
          newList[index] = { ...refer, ...updatedData };
        } else {
          newList[index] = refer;
        }
      });

    setActionUpdate((prev) => {
      !prev;
    });

    referList.set([...newList]);
  };

  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={currentApplicationWfData}
        callBackOnAction={callBackOnAction}
        isOfficerGroup={isOfficerGroup}
        isLoading={isLoading}
        officerGroupId={officerGroupId}
        disabled={checkPrivilegeStatus}
        catalogKey={data?.currentProductCatalogKey}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : referList && referList.length > 0 ? (
            referList?.get()?.map((data) => {
              data.applicationId = applicationId;
              return (
                <ReferItemRow
                  data={data}
                  key={data?.referId}
                  applicationId={applicationId}
                  currentApplicationWfData={currentApplicationWfData}
                  callBackOnActionEdit={callBackOnActionEdit}
                  list={referList.attach(Downgraded).get()}
                  isOfficerGroup={isOfficerGroup}
                  referalGroupIDs={referalGroupIDs}
                />
              );
            })
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ApplicationRefer;
