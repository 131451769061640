// TableColumnsSelectBoxWrapperNew

import { Stack, TableCell } from "@mui/material";
import React, { useState } from "react";
import { memo } from "react";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";

interface TableColumnsSelectBoxWrapperProps {
  value: any;
  index: string | number;
  title: string;
  data?: any;
  style?: any;
  dataKey?: any;
  onchangeHandler?: any;
  dataIndex?: any;
}

const TableColumnsSelectBoxWrapperNew: React.FC<TableColumnsSelectBoxWrapperProps> = ({ index, value, title, data = {}, dataKey = "", dataIndex, onchangeHandler = () => {} }: TableColumnsSelectBoxWrapperProps) => {
  const masterDataStore = MasterDataProvider.provideMasterData();
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  const [inputValue, setInputValue] = useState(value?.get());

  const handleValueChange = (value) => {
    setInputValue(value);
    onchangeHandler(value, dataKey, dataIndex);
  };

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <SelectComponent label={"Select Type"} value={inputValue} values={masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LIABILITY_TYPE)} onChange={(e) => handleValueChange(e.target.value)} required={false} defaultPlaceholder={true} />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default memo(TableColumnsSelectBoxWrapperNew);
