export const loadStructure = () => [
  {
    id: "001",
    key: "primary-applicant-details",
    checked: true,
    content: [
      {
        id: "01",
        key: "personal-details",
        checked: true,
        fields: [
          { id: 1, key: "Language Select", checked: true, mandatory: true },
          { id: 2, key: "Title", checked: true, mandatory: true },
          { id: 3, key: "Old Nic", checked: true, mandatory: true },
          { id: 4, key: "New Nic", checked: true, mandatory: true },
          { id: 5, key: "First Name", checked: true, mandatory: true },
          { id: 6, key: "Middle Name", checked: true, mandatory: true },
          { id: 7, key: "Last Name", checked: true, mandatory: true },
          { id: 8, key: "Gender", checked: true, mandatory: true },
        ],
      },
      {
        id: "02",
        key: "residential-details",
        checked: true,
        fields: [
          { id: 9, key: "Address", checked: true, mandatory: true },
          { id: 10, key: "Province", checked: true, mandatory: true },
          { id: 11, key: "District", checked: true, mandatory: true },
          { id: 12, key: "City", checked: true, mandatory: true },
        ],
      },
    ],
  },
  {
    id: "002",
    key: "contact-details",
    checked: true,
    content: [
      {
        id: "01",
        key: "primary-contact-details",
        checked: true,
        fields: [
          { id: 13, key: "Contact Details", checked: true, mandatory: true },
        ],
      },
      {
        id: "02",
        key: "social-contact",
        checked: true,
      },
    ],
  },
  {
    id: "003",
    key: "additional-details",
    checked: true,
    content: [
      {
        id: "01",
        key: "income-details",
        checked: true,
        fields: [
          { id: 14, key: "Employed", checked: true, mandatory: true },
          { id: 15, key: "Salary", checked: true, mandatory: true },
        ],
      },
    ],
  },
  {
    id: "004",
    key: "document-details",
    checked: true,
    content: [
      {
        id: "01",
        key: "Income Details",
        checked: true,
        fields: [
          { id: 14, key: "Employed", checked: true, mandatory: true },
          { id: 15, key: "Salary", checked: true, mandatory: true },
        ],
      },
    ],
  },
];

export const loadStructureNew = () => [
  {
    id: 1,
    key: "primary-applicant-details",
    visible: 1,
    content: [
      {
        id: 1,
        key: "personal-details",
        visible: 1,
        fields: {
          "old-nic": {
            id: 21,
            key: "old-nic",
            visible: 1,
            mandatory: 1,
          },
          "new-nic": {
            id: 22,
            key: "new-nic",
            visible: 1,
            mandatory: 1,
          },
          "first-name": {
            id: 4,
            key: "first-name",
            visible: 1,
            mandatory: 1,
          },
          "middle-name": {
            id: 5,
            key: "middle-name",
            visible: 1,
            mandatory: 1,
          },
          "last-name": {
            id: 6,
            key: "last-name",
            visible: 1,
            mandatory: 1,
          },
          gender: {
            id: 7,
            key: "gender",
            visible: 1,
            mandatory: 1,
          },
          title: {
            id: 15,
            key: "title",
            visible: 1,
            mandatory: 1,
          },
          "language-select": {
            id: 41,
            key: "language-select",
            visible: 1,
            mandatory: 1,
          },
        },
      },
      {
        id: 2,
        key: "residential-details",
        visible: 1,
        fields: {
          address: {
            id: 8,
            key: "address",
            visible: 1,
            mandatory: 1,
          },
          province: {
            id: 9,
            key: "province",
            visible: 1,
            mandatory: 1,
          },
          district: {
            id: 10,
            key: "district",
            visible: 1,
            mandatory: 1,
          },
          city: {
            id: 11,
            key: "city",
            visible: 1,
            mandatory: 1,
          },
        },
      },
    ],
  },
  {
    id: 2,
    key: "contact-details",
    visible: 1,
    content: [
      {
        id: 3,
        key: "primary-contact-details",
        visible: 1,
        fields: {
          "mobile-contact": {
            id: 12,
            key: "mobile-contact",
            visible: 1,
            mandatory: 1,
          },
        },
      },
      {
        id: 4,
        key: "social-contact",
        visible: 1,
        fields: {
          email: {
            id: 47,
            key: "email",
            visible: 1,
            mandatory: 1,
          },
          "whats-app": {
            id: 42,
            key: "whats-app",
            visible: 1,
            mandatory: 1,
          },
        },
      },
    ],
  },
  {
    id: 3,
    key: "additional-details",
    visible: 1,
    content: [
      {
        id: 5,
        key: "income-details",
        visible: 1,
        fields: {
          employed: {
            id: 13,
            key: "employed",
            visible: 1,
            mandatory: 1,
          },
          salary: {
            id: 14,
            key: "salary",
            visible: 1,
            mandatory: 1,
          },
        },
      },
    ],
  },
  {
    id: 4,
    key: "document-details",
    visible: 1,
    content: [
      {
        id: 7,
        key: 'nic-details',
        visible: 1,
        fields: {
          "nic-front": {
            id: 16,
            key: 'nic-front',
            visible: 1,
            mandatory: 1,
            token: ''
          },
          "nic-back": {
            id: 17,
            key: 'nic-back',
            visible: 1,
            mandatory: 1,
            token: ''
          }
        }
      }
    ]
  }
];
