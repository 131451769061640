import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
const api = Api.getApi();

const getBackendMasterDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/master-data?typeKey=`;
const getMasterDataMetaUrl = `${URL.REACT_APP_BACKEND_HOST}/los/master-meta-data`;
const editMasterDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/master-data?typeId=`;
const addMasterDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/master-data?typeId=`;
const addMasterDataTypeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/create/master-data/type`;

export const getBackendMasterDataList = async (typeKey: string) => {
  try {
    const data = await api.get(`${getBackendMasterDataUrl}${typeKey}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data?.items;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getMasterDataMetaList = async () => {
  try {
    const data = await api.get(getMasterDataMetaUrl, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data?.items;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addMasterDataMeta = async (data) => {
  try {
    const data = await api.get(getMasterDataMetaUrl, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data?.items;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const editMasterDataList = async (typeId: string, typeKey: string, obj: any) => {
  try {
    const data = await api.put(`${editMasterDataUrl}${typeId}`, { typeKey: typeKey, data: obj });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addMasterDataList = async (typeId: string, typeKey: string, obj: any) => {
  try {
    const data = await api.post(`${addMasterDataUrl}${typeId}`, { typeKey: typeKey, data: obj });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const saveMasterDataType = async (params) => {
  try {
    const data = await api.post(`${addMasterDataTypeUrl}`, params);

    return data;
  } catch (error) {
    return null;
  }
};

export const fetchEmployersData = async (name) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/master-data/employer?name=${name}`);
    return data;
  } catch (error) {
    return null;
  }
};