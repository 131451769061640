import { useState } from "@hookstate/core";
import { Box, Grid, Typography } from "@mui/material";
import { masterDataDetails } from "../../../../../configs/mainStore";
import AppConfig from "../../../../../utility/AppConfig";
import MandatoryFilterComponent from "../../../../DataValidationComponents/MandatoryFilterComponent";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import GridContentItem from "../UserCreditComponents/GridContentItem";
import ApplicationHeader from "./ApplicationHeader";

const AddressInformation = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const addressFullData: any = data?.formData?.addressData;

  const mailAddressData = {
    addressLine1: addressFullData?.mailingAddressData?.mailAddressLine1,
    addressLine2: addressFullData?.mailingAddressData?.mailAddressLine2,
    addressLine3: addressFullData?.mailingAddressData?.mailAddressLine3,
    addressLine4: addressFullData?.mailingAddressData?.mailAddressLine4,
    city: addressFullData?.mailingAddressData?.mailCity,
    province: addressFullData?.mailingAddressData?.mailProvince,
    district: addressFullData?.mailingAddressData?.mailDistrict,
    gnDivision: addressFullData?.mailingAddressData?.mailGnDivision,
    dsDivision: addressFullData?.mailingAddressData?.mailDsDivision,
    postalCode: addressFullData?.mailingAddressData?.mailPostalCode,
  };

  const currentAddressData = {
    addressLine1: addressFullData?.currentAddressData?.currentAddressLine1,
    addressLine2: addressFullData?.currentAddressData?.currentAddressLine2,
    addressLine3: addressFullData?.currentAddressData?.currentAddressLine3,
    addressLine4: addressFullData?.currentAddressData?.currentAddressLine4,
    city: addressFullData?.currentAddressData?.currentCity,
    province: addressFullData?.currentAddressData?.currentProvince,
    district: addressFullData?.currentAddressData?.currentDistrict,
    gnDivision: addressFullData?.currentAddressData?.currentGnDivision,
    dsDivision: addressFullData?.currentAddressData?.currentDsDivision,
    postalCode: addressFullData?.currentAddressData?.currentPostalCode,
  };

  const previousAddressData = {
    addressLine1: addressFullData?.previousResidenceAddressData?.previousAddressLine1,
    addressLine2: addressFullData?.previousResidenceAddressData?.previousAddressLine2,
    addressLine3: addressFullData?.previousResidenceAddressData?.previousAddressLine3,
    addressLine4: addressFullData?.previousResidenceAddressData?.previousAddressLine4,
    city: addressFullData?.previousResidenceAddressData?.previousCity,
    province: addressFullData?.previousResidenceAddressData?.previousProvince,
    district: addressFullData?.previousResidenceAddressData?.previousDistrict,
    gnDivision: addressFullData?.previousResidenceAddressData?.previousGnDivision,
    dsDivision: addressFullData?.previousResidenceAddressData?.previousDsDivision,
    postalCode: addressFullData?.previousResidenceAddressData?.previousPostalCode,
  };

  const resStatus = masterData?.RESIDENTIAL_STATUS?.get()?.find((t) => t.id == addressFullData?.residentialState);
  const otherResidentialState = addressFullData?.otherResidentialState;

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Address Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Address Details
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />

            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Address Info
            </Grid>

            <Grid container>
              <Grid container p={1} className="data-box" spacing={1}>
                <AddressViewComponent title="" addressData={addressFullData?.permanentAddress} viewType="tab" />
                <Grid container item spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <DetailItem title="Mailing Address same as Permanent Address" value={addressFullData?.isMailingAddressSame == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                  </Grid>
                </Grid>
                {!addressFullData?.isMailingAddressSame && <AddressViewComponent title="Mailing Address" addressData={mailAddressData} viewType="tab" />}
                <Grid container item spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <DetailItem title="Current Address same as Permanent Address" value={addressFullData?.isCurrentAddressSame == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                  </Grid>
                </Grid>
                {!addressFullData?.isCurrentAddressSame && <AddressViewComponent title="Current Address" addressData={currentAddressData} viewType="tab" />}
              </Grid>
              {!isGuarantor && (
                <>
                  <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">Other Address Info</div>
                  </Grid>
                  <Grid item container p={1} className="data-box" spacing={1}>
                    <Grid container p={1} spacing={1}>
                      <Grid item xs={3}>
                        <DetailItem title="Residential Status" value={resStatus?.name ? resStatus?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                      </Grid>
                      {resStatus?.id === AppConfig.config.residentialStatusId && (
                        <Grid item xs={6}>
                          <DetailItem title="Other Residential Status" value={otherResidentialState ? otherResidentialState : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <DetailItem title="Years at Current Residence" value={`${addressFullData?.currentResidenceYears ? addressFullData?.currentResidenceYears : 0} Years ${addressFullData?.currentResidenceMonths ? addressFullData?.currentResidenceMonths : 0} Months`} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                      </Grid>
                    </Grid>
                    {(addressFullData?.currentResidenceYears * 12 + addressFullData?.currentResidenceMonths < 6) && isMainContactDetails &&
                      <AddressViewComponent title="Previous Residence Address" addressData={previousAddressData} viewType="tab" />}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const AddressViewComponent = ({ addressData, title, viewType = "" }) => {
  const masterData: any = useState(masterDataDetails);

  const province = masterData?.PROVINCE?.get()?.find((t) => t.id == addressData?.province);
  const district = masterData?.DISTRICT?.get()?.find((t) => t.id == addressData?.district);
  return viewType == "tab" ? (
    <>
      <MandatoryFilterComponent items={addressData} mandatory={["addressLine1", "addressLine2"] as never[]} options={{ title: "Address Details" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left">
            {title}
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Address Line 1" value={addressData?.addressLine1 ? addressData?.addressLine1 : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Address Line 2" value={addressData?.addressLine2 ? addressData?.addressLine2 : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Address Line 3" value={addressData?.addressLine3 ? addressData?.addressLine3 : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Address Line 4" value={addressData?.addressLine4 ? addressData?.addressLine4 : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Nearest City" value={addressData?.city ? addressData?.city : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Province" value={province?.name ? province?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="District" value={district?.name ? district?.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Postal Code" value={addressData?.postalCode ? addressData?.postalCode : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
        </Grid>
      </MandatoryFilterComponent>
    </>
  ) : viewType == "vertical" ? (
    <>
      <MandatoryFilterComponent items={addressData} mandatory={["addressLine1", "addressLine2"] as never[]} options={{ title: "Address Details" }}>
        <Grid container item spacing={1} pb={2}>
          <Grid item xs={12} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left">
            <Typography variant="body2">
              <Box sx={{ fontWeight: "light" }}>{title}</Box>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Address Line 1" value={addressData?.addressLine1 ? addressData?.addressLine1 : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Address Line 2" value={addressData?.addressLine2 ? addressData?.addressLine2 : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Address Line 3" value={addressData?.addressLine3 ? addressData?.addressLine3 : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Address Line 4" value={addressData?.addressLine4 ? addressData?.addressLine4 : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="City" value={addressData?.city ? addressData?.city : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Province" value={province?.name ? province?.name : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="District" value={district?.name ? district?.name : "N/A"} alignType={"left"} />
          </Grid>
          <Grid item xs={3}>
            <GridContentItem title="Postal Code" value={addressData?.postalCode ? addressData?.postalCode : "N/A"} alignType={"left"} />
          </Grid>
        </Grid>
      </MandatoryFilterComponent>
    </>
  ) : (
    <></>
  );
};

export default AddressInformation;
