import { Box, Stack, Grid, Typography } from "@mui/material";
import { closeBtn } from "../../../styles";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const DifferApplicationsConfirmationModal = (params) => {
    const closeCondition = () => {
      closeGlobalModal();
    };
    const differConfirm = () => {
      params.callBackOnAction(params);
    };
    return (
      <Box>
        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <Grid container columns={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight={100}>
                Selected applications will be differed!
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
              <ButtonComponent title="Cancel" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
              <Box ml={0.5} mr={0.5}></Box>
              <ButtonComponent startIcon={<DoneIcon />} title="Yes" variant="contained" onClick={() => differConfirm()} loadingbtn={true} color="info" />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    );
  };
  
  export default DifferApplicationsConfirmationModal;