import { useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  downloadInspectionForm,
  getFilledPendingInpectionFormData,
} from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { addToaster } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import {
  InspectionProps,
  SaveInspectionChanges,
} from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import {
  ApplicationFormKey,
  SectionKey,
} from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import {
  inspectionForm,
  inspetionIdState,
} from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";

const SustainableEnvironmentalAndSafeguard = ({
  data,
  applicationId,
  innerComponentData,
  tabsToEdit,
}) => {
  const { t: translate } = useTranslation();
  let toBeSubmittedFormDetails: any = {};
  const [formDetails, setFormDetails]: any = React.useState(
    toBeSubmittedFormDetails
  );
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const inspetionId = useState<any>(inspetionIdState);
  const { formData } = data;
  const isLoading = useState<boolean>(false);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
  };

  useEffect(() => {
    getExistingInspectionForm();

    return () => {
      inspectionFormState.otherQuestions.set({});
    };
  }, []);

  const getExistingInspectionForm = async () => {
    isLoading.set(true);
    let responseData = {};

    const preDataInitializer = (response: any = {}) => {
      const getAgeRangeCount = (dataObject) => {
        const ageRanges = {
          betweenSixteenAndTwentyFive: 0,
          betweenTwentySixAndThirtyFive: 0,
          betweenThirtySixAndFortyFive: 0,
          aboveFourtyFive: 0,
          belowSixteen: 0,
          ...dataObject,
        };
        return {
          betweenAgeTwoSixToAgeThreeFive: Boolean(ageRanges.betweenTwentySixAndThirtyFive),
          betweenAgeThreeSixAgeFourFive: Boolean(ageRanges.betweenThirtySixAndFortyFive),
          aboveFourFive: Boolean(ageRanges.aboveFourtyFive),
          belowAgeSixteen: Boolean(ageRanges.belowSixteen),
          betweenAgeSixteenToAgeTwoFive: Boolean(ageRanges.betweenSixteenAndTwentyFive),
        };
      };

      const calculateTotalCount = (ageRanges) => {
        return Object.values(ageRanges).reduce((total: number, count) => total + Number(count || 0), 0);
      };

      try {
        if (!response?.business || !Object.values(response.business).length) {
          throw new Error('Not a Valid Response');
        }

        const { employementDetails: { extractAgeRange: { noOfFemale = {}, noOfMale = {} } } } = response.business;
        const ageRanges = ["belowSixteen", "betweenSixteenAndTwentyFive", "betweenTwentySixAndThirtyFive", "betweenThirtySixAndFortyFive", "aboveFourtyFive"];

        const extractAgeRange = ageRanges.reduce((acc, range) => {
          acc[range] = Number(noOfFemale[range] || 0) + Number(noOfMale[range] || 0);
          return acc;
        }, {});

        const returenedToBeData = {
          ...getAgeRangeCount(extractAgeRange),
          noOfFemaleCount: calculateTotalCount(noOfFemale),
          noOfMaleCount: calculateTotalCount(noOfMale),
        };

        return returenedToBeData;
      } catch (error) {
        throw new Error('Something went wrong: ' + error);
      }
    };

    try {
      const { response: data, status }: any = await getFilledPendingInpectionFormData(applicationId);

      if (Object.values(data).length === 0) {
        responseData = {};
      } else {
        inspetionId.set(data.ID ?? null);

        if (data.INSPECTION_DATA && Object.values(data.INSPECTION_DATA).length > 0) {
          const res = JSON.parse(data.INSPECTION_DATA);
          responseData = res.sustainableEnvironmentalAndSafeguard && Object.values(res.sustainableEnvironmentalAndSafeguard).length > 0
            ? res.sustainableEnvironmentalAndSafeguard
            : preDataInitializer(res) ?? {};
        } else {
          responseData = {};
        }
      }

      setFormDetails(responseData);
      inspectionFormState.sustainableEnvironmentalAndSafeguard.set(responseData);
    } catch (error: any) {
      GlobalLogger.log("getExistingInspectionForm", error.message);
    } finally {
      isLoading.set(false);
    }
  };

  const onChange = (metaData: any, value: any) => {
    setFormDetails(value);
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(
        downloadInspectionForm,
        SectionKey.sustainableEnvironmentalAndSafeguard
      );
    } catch (error: any) {
      GlobalLogger.log("inspection form", error.message);
    }
  };

  const onSubmit = async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(
      ApplicationFormKey.sustainableEnvironmentalAndSafeguard,
      theDataTobeSubmitted,
      SectionKey.sustainableEnvironmentalAndSafeguard
    );

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges =
        inspectionMessageMappings(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${preparedErrorMessagges}`,
      });
    }

    const respose: any = await SaveInspectionChanges(
      theDataTobeSubmitted,
      SectionKey.sustainableEnvironmentalAndSafeguard,
      inspectionFormState,
      applicationId
    );

    if (respose.status == 1) {
      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  const params = {
    onChange,
    onSubmit,
    otherParams,
    applicationDataObject: formDetails,
    formId: ApplicationFormKey.sustainableEnvironmentalAndSafeguard,
    title: translate("sustainableEnvironmentalAndSafeguard"),
    onDownload: downloadInspectionFormTemplate,
  };

  return (
    <ErrorBoundary>
      <FormBuilder {...params} />
    </ErrorBoundary>
  );
};

export default SustainableEnvironmentalAndSafeguard;
