import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { FormHelperText } from "@mui/material";
import { FC } from "react";
import { multiSelectPropsTypes } from "../../../configs/interfaces";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const MultipleSelect: FC<multiSelectPropsTypes> = ({ sx, size, values, value, onChange, type, label }: any) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value, type);
  };
  return (
    <FormControl sx={sx}>
      {label && <div className="basic-font font-size-12 basic-font-color opacity-7 align-left">{label}</div>}
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        size={size}
        value={value}
        onChange={handleChange}
        // input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" className="font-size-12 basic-font-color" />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {values.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={value?.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
