import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
const api = Api.getApi();

const userGroupListUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/user-group-list?languageId=`;
const privilegeTypeUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/privilege-list?languageId=`;
const privilegeByUserGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/privilege-by-user-group?groupId=`;
const updatePrivilegeByUserGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/privilege-by-user-group`;
const userGroupByIdUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/user-group-by-id?groupId=`;
const addNewUserGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/add-new-user-group`;
const editUserGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/edit-user-group`;
const getUsersByGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/users-by-user-group?groupId=`;
const updateUsersGroupUrl = `${URL.REACT_APP_BACKEND_HOST}/auth/users-by-user-group`;
const addNewUserPrivilege = `${URL.REACT_APP_BACKEND_HOST}/auth/privileges/type`;
const getUsersByBranchUrl = `${URL.REACT_APP_BACKEND_HOST}/los/users/branch?branchId=`;

export const getUserGroupList = async (languageId, configs = false) => {
  try {
    let url = `${userGroupListUrl}${languageId}`;
    if (configs) {
      url = url + `&configs=true`;
    }
    const data = await api.get(url, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPrivilegeTypeList = async (languageId) => {
  try {
    const data = await api.get(`${privilegeTypeUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPrivilegeByUserGroup = async (groupId) => {
  try {
    const data = await api.get(`${privilegeByUserGroupUrl}${groupId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updatePrivilegeByUserGroup = async (groupId: any, editedKeys: any) => {
  try {
    const data = await api.patch(`${updatePrivilegeByUserGroupUrl}`, { groupId, editedKeys });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addNewUserGroupApi = async (isActive: any, groupData: any) => {
  try {
    const data = await api.post(`${addNewUserGroupUrl}`, {
      isActive: isActive ? 1 : 0,
      groupData,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUserGroupById = async (groupId) => {
  try {
    const data = await api.get(`${userGroupByIdUrl}${groupId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const editUserGroupApi = async (groupId: any, isActive: any, groupData: any) => {
  try {
    const data = await api.patch(`${editUserGroupUrl}`, { groupId, isActive: isActive ? 1 : 0, groupData });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUsersByGroupId = async (groupId, branchId?, applicationId?) => {
  try {
    if (!groupId && !branchId) {
      return null;
    }

    if (groupId instanceof Array && groupId.length === 0) {
      return null;
    }

    let _branchId = branchId ? branchId : null;
    const data = await api.get(`${getUsersByGroupUrl}${groupId}&branchId=${_branchId}&applicationId=${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUsersByBranchId = async (branchId = null) => {
  try {
    const data = await api.get(`${getUsersByBranchUrl}${branchId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateUsersByGroup = async (groupId: any, editedKeys: any) => {
  try {
    const data = await api.patch(`${updateUsersGroupUrl}`, { groupId, editedKeys });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createNewUserPrivilege = async (payload: any) => {
  try {
    const data = await api.post(`${addNewUserPrivilege}`, { ...payload });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
