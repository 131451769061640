import { InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import appStrings from "../../../configs/constants/appStrings";
import { SelectPropsTypes } from "../../../configs/interfaces";
import { FormControlComponent } from "../../InputHelperComponents/FormControlComponent/FormControlComponent";
import { CircularProgressComponent } from "../../OtherComponents/ProgressComponent/ProgressComponent";

export const SelectComponent: FC<SelectPropsTypes> = ({ onBlur, isNotShowMainLabel, formControlVariant, endAdornment, autoWidth, children, classes, error, defaultOpen, defaultValue, displayEmpty, IconComponent, id, input, inputProps, label, labelId, MenuProps, multiple, native, onChange, onClose, onOpen, open, renderValue, SelectDisplayProps, sx, value, variant, values, className, size, formSX, disabled, required, InputProps, defaultPlaceholder }: any) => {
  if (values instanceof Array === false) {
    return <CircularProgressComponent size={16} />;
  }

  return (
    <FormControlComponent variant={formControlVariant} size={size ? size : appStrings?.common?.small} sx={formSX} error={error} fullWidth={true}>
      {!isNotShowMainLabel ? <InputLabel id="demo-simple-select-autowidth-label">
        {label}
        {required ? <span className="MuiFormLabel-asterisk"> *</span> : ""}
      </InputLabel> : <></>}
      <Select
        labelId="demo-simple-select-autowidth-label"
        id={id}
        autoWidth={autoWidth}
        // children={children}
        classes={classes}
        error={error}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        displayEmpty={displayEmpty}
        IconComponent={IconComponent}
        // id={id}
        input={input}
        onBlur={onBlur}
        required={required}
        inputProps={inputProps}
        label={label}
        MenuProps={MenuProps}
        multiple={multiple}
        native={native}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        renderValue={renderValue}
        SelectDisplayProps={SelectDisplayProps}
        style={sx}
        value={value || ''}
        endAdornment={endAdornment}
        variant={variant}
        className={className ? className : "select_basic align-left"}
        size={size}
        disabled={disabled}
      >
        {/* {defaultPlaceholder ? <MenuItem value="null">Please Select ...</MenuItem> : <></>} */}
        {defaultPlaceholder && <MenuItem value="">Please Select ...</MenuItem>}
        {values?.length > 0 &&
          values?.map((item: any) => {
            if (item?.id?.get && item?.name?.get) {
              return (
                <MenuItem value={item.id.get()} key={item.id.get()}>
                  {item?.name.get()}
                </MenuItem>
              );
            } else if (item?.id && item?.name) {
              return (
                <MenuItem value={item?.id} key={item?.id}>
                  {item?.name}
                </MenuItem>
              );
            }
          })}
      </Select>
    </FormControlComponent>
  );
};
