import { CSSObject, Stack, TableCell, TableHead, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TableColumnsElementWrapper, TableColumnsTextWrapper } from "../TableColumn/TableColumnsWrapper";
import { TableRowWrapper } from "../TableRow/TableRowWrapper";

interface TableHeaderWrapperProps {
  headerArray: Array<string>;
}

interface CustomTableHeaderWrapperForBalanceSheetProps {
  firstColTitle: string,
  dataObject: any,
  style?: CSSObject
}

export const TableHeaderWrapper = ({ headerArray, shouldDelete = false }) => {
  const { t: translate } = useTranslation();

  if (headerArray) {
    return (
      <TableHead>
        <TableRowWrapper>
          {headerArray.map((header: any) => (
            <TableColumnsTextWrapper key={header.title} index={header.title + " TableColumnsTextWrapper"} style={header.style}>
              {translate(header.title)}
            </TableColumnsTextWrapper>
          ))}
          <TableColumnsTextWrapper index={"delete"}>
            <></>
          </TableColumnsTextWrapper>
        </TableRowWrapper>
      </TableHead>
    );
  }

  return <></>;
};
export const TableHeaderWrapperCustom = ({ headerArray, style }: { headerArray: string[]; style?: CSSObject }) => {
  const { t: translate } = useTranslation();

  if (headerArray) {
    return (
      <TableHead>
        <TableRowWrapper>
          {headerArray.map((title: string, index) => (
            <TableColumnsTextWrapper key={title} index={title + " TableColumnsTextWrapper"} style={!index ? {} : style}>
              <Typography color="#555555" fontWeight="bold">
                {translate(title)}
              </Typography>
            </TableColumnsTextWrapper>
          ))}
        </TableRowWrapper>
      </TableHead>
    );
  }
  return <></>;
};

export const TableHeaderCell = ({ headerArray, style }: { headerArray: string[]; style?: CSSObject }) => {
  const { t: translate } = useTranslation();

  if (headerArray) {
    return (
      <TableHead>
        <TableRowWrapper>
          {headerArray.map((title: string, index) => (
            <TableCell key={title + " TableColumnsTextWrapper"}>
              <Typography color="#555555" fontWeight="bold">
                {translate(title)}
              </Typography>
            </TableCell>
          ))}
        </TableRowWrapper>
      </TableHead>
    );
  }
  return <></>;
};

export const CustomTableHeaderWrapper = ({ dataObject }) => {
  const { t: translate } = useTranslation();

  if (dataObject) {
    return (
      <TableHead>
        <TableRowWrapper>
          <TableColumnsTextWrapper index={"description"}>
            <Typography variant="subtitle1" color="#555555" fontWeight="bold">
              {translate("description")}
            </Typography>
          </TableColumnsTextWrapper>

          {Object.values(dataObject).map((item: any) => {
            return (
              <TableColumnsTextWrapper key={item.title} index={item.title + " TableColumnsTextWrapper"}>
                <Typography variant="subtitle1" color="#555555" fontWeight="bold">
                  {translate(item.title)}
                </Typography>
              </TableColumnsTextWrapper>
            );
          })}

          <TableColumnsTextWrapper index={"delete"}>
            <></>
          </TableColumnsTextWrapper>
        </TableRowWrapper>
      </TableHead>
    );
  }

  return <></>;
};

export const CustomTableHeaderWrapperForBalanceSheet: React.FC<CustomTableHeaderWrapperForBalanceSheetProps> = ({ firstColTitle, dataObject, style }: CustomTableHeaderWrapperForBalanceSheetProps) => {
  const { t: translate } = useTranslation();

  const handleEdit = () => { };

  if (dataObject) {
    return (
      <TableHead>
        <TableRowWrapper>
          <TableColumnsTextWrapper index={firstColTitle} style={style}>{translate(firstColTitle)}</TableColumnsTextWrapper>

          {Object.values(dataObject).map((item: any) => {
            return (
              <TableColumnsElementWrapper key={item.title} index={item.title + " TableColumnsTextWrapper"}>
                <Stack direction={"row"} alignItems={"center"} gap={1} sx={style}>
                  <Typography variant="subtitle1" color="#555555" fontWeight="bold">
                    {translate(item.title)}
                  </Typography>
                </Stack>
              </TableColumnsElementWrapper>
            );
          })}
        </TableRowWrapper>
      </TableHead>
    );
  }

  return <></>;
};

export const TableHeaderWrapperRevaamp = ({ dataObject }) => {
  const { t: translate } = useTranslation();

  if (dataObject) {
    return (
      <TableHead>
        <TableRowWrapper>
          <TableColumnsTextWrapper style={{ width: "150px" }} index={"description_01_"} children={<></>} />
          {Object.values(dataObject).map((item: any) => {
            return (
              <TableColumnsTextWrapper style={{ width: "200px" }} key={item.title} index={item.title + " TableColumnsTextWrapper"}>
                <Typography variant="subtitle1" color="#555555" fontWeight="bold">
                  {translate(item.title)}
                </Typography>
              </TableColumnsTextWrapper>
            );
          })}
          <TableColumnsTextWrapper index={"delete"} children={""} />
        </TableRowWrapper>
      </TableHead>
    );
  }

  return <></>;
};
