import { Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import ValidateTimePicker from "../../../../../../../InputComponents/ValidateTimePicker/ValidateTimePicker";

function TimePickerBuilder({ value, onChange, metaData }) {
  const [valueInput, setValue] = React.useState(value[metaData?.dataKey]);

  useEffect(() => {
    if (!value[metaData?.dataKey]) {
      setValue(moment());
      value[metaData?.dataKey] = moment()
      onChange(metaData, value);
    }
  }, [])

  return (
    <Stack px={1} pt={1}>
      <ValidateTimePicker
        label={metaData.title}
        sx={{ width: "340px" }}
        value={moment(valueInput ?? null)}
        required={metaData?.render?.required ?? false}
        onChange={(date) => {
          date = date ? date._d.toString() : null;
          setValue(date);
          value[metaData?.dataKey] = date
          onChange(metaData, value);
        }}
      />
    </Stack>
  );
}

export default TimePickerBuilder;
