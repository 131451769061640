import { useEffect, useState } from "react";
import { productDetails } from "../../../configs/mainStore";
import { Downgraded, useHookstate } from "@hookstate/core";
import { Divider, Stack, Typography } from "@mui/material";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { ComponentMapper } from "./ComponentMapper";

export default (props) => {
    const [loading, setLoading] = useState(true);
    const [values, setValues]: any = useState({ types: [], sectors: [] });
    const [selections, setSelections]: any = useState([]);
    const store: any = useHookstate(productDetails);

    let types: any = store?.productTypesMap?.attach(Downgraded)?.get() || [];
    let sectors: any = store?.productSectorsMap?.attach(Downgraded)?.get() || [];

    useEffect(() => {
        loadMappingData();
    }, []);

    const loadMappingData = async () => {
        try {
            const idmap = {};
            const prev = props.storedConfigs.map((item) => {
                const obj = {
                    name: [types?.[item.PRODUCT_TYPE_ID]?.TYPE_NAME, sectors?.[item.PRODUCT_SECTOR_ID]?.TYPE_NAME].join(" "),
                    desc: [item.PRODUCT_TYPE_ID, item.PRODUCT_SECTOR_ID].join("."),
                    // id: item.ID,
                    id: [item.PRODUCT_TYPE_ID, item.PRODUCT_SECTOR_ID].join("."),
                };
                idmap[obj.desc] = item.ID;
                return obj;
            });

            const current = props.currentConfigs.sectors.map((item) => {
                const id = [props.currentConfigs.type, item].join(".");

                if (idmap[id]) {
                    return null;
                }
                return {
                    name: [types?.[props.currentConfigs.type]?.TYPE_NAME, sectors?.[item]?.TYPE_NAME].join(" "),
                    id
                };
            }).filter(item => item);

            const sels: any = [];
            const preItemIds = prev.map(item => item.desc);
            for (const item of current) {
                const { id } = item;
                if (preItemIds.includes(id)) {
                    sels.push(idmap[id]);
                }
            }
            setSelections(sels);
            setValues({ prev, current });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleSelection = async (status, data) => {
        let values: any = [];
        if (selections.includes(data.id)) {
            values = selections.filter(item => item != data.id);
        } else {
            values = [data.id, ...selections];
        }

        setSelections(values);
    }

    const handleUnpublish = async () => {
        await props.unpublish(selections);
        closeGlobalModal();
    }

    const handleConfirm = async () => {
        await props.confirm(values.current);
        closeGlobalModal();
    }

    const PreviousMapping = () => {
        return <Stack flex={1}>
            <Stack direction={"row"}>
                <Typography>
                    Previous Mapping
                </Typography>
            </Stack>
            <Divider />
            <Stack>
                <ComponentMapper title={"mappings currently used in system"} items={values.prev} loading={loading} selections={selections} handleSelection={handleSelection} height={"calc(100vh - 450px)"} />;
            </Stack>
        </Stack>;
    }

    const CurrentMapping = () => {
        return <Stack flex={1}>
            <Stack direction={"row"}>
                <Typography>
                    Current Mapping
                </Typography>
            </Stack>
            <Divider />
            <Stack>
                <ComponentMapper title={"new mappings to be added"} items={values.current} loading={loading} height={"calc(100vh - 450px)"} />;
                <Stack direction={"row-reverse"}>
                    <ButtonComponent size={"small"} variant={"outlined"} title={"Confirm"} onClick={handleConfirm}></ButtonComponent>
                </Stack>
            </Stack>
        </Stack>;
    }

    if (loading) {
        return <></>;
    }

    return <Stack>
        <Stack direction={"row"} flex={1} spacing={2}>
            <PreviousMapping />
            <Stack borderRight={"solid 1px lightGray"}></Stack>
            <CurrentMapping />
        </Stack>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}></Divider>
        <Stack direction={"row-reverse"}>
            <ButtonComponent size={"small"} variant={"outlined"} title={"Close"} onClick={() => { closeGlobalModal() }}></ButtonComponent>
        </Stack>
    </Stack>;
};
