import React, {useState} from "react";
import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";

const CheckBoxBuilder = ({value, onChange, metaData}) => {
    const [checked, setChecked] = useState<string | boolean>(value[metaData?.dataKey]);


    return (
        <React.Fragment key={metaData.dataKey}>
                <Stack px={1} pt={1}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={checked === true}
                            label={metaData.title}
                            onClick={(e: any) => {
                                setChecked(e.target.checked);
                                value[metaData.dataKey] = e.target.checked;
                                onChange(metaData, value);
                            }}
                        />
                    </FormGroup>
                </Stack>
        </React.Fragment>
    )
}

export default  CheckBoxBuilder;