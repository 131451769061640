import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";
const api = Api.getApi();

const salesSupportCalOtherChargesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/trail-cal-other-charges-list?languageId=`;
const appllicationChargesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/other-charges-by-application?applicationId=`;
const appllicationChargesSetUrl = `${URL.REACT_APP_BACKEND_HOST}/los/update-other-charges-list`;
const chargeTypeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/charges/types`;
const chargeStatus = `${URL.REACT_APP_BACKEND_HOST}/los/charges/status`;
const createChargeTemplateMappingUrl = `${URL.REACT_APP_BACKEND_HOST}/los/charges/templates/mappings`;
const chargeTemplatesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/charges/templates`;
const productChargesTemplatesUrl = `${URL.REACT_APP_BACKEND_HOST}/los/products/charges/templates`;
const applicationProductCharges = `${URL.REACT_APP_BACKEND_HOST}/los/products/charges?catalog=`;

export const getCalOtherCharges = async (languageId) => {
  try {
    const res = (await CachingStorage.invoke("chargeList", () => api.get(`${salesSupportCalOtherChargesUrl}${languageId}`, []))) ??
      {}

    return res;
  } catch (error) {
    return null;
  }
};

export const getApplicationCharges = async (applicationId) => {
  try {
    const data = await api.get(`${appllicationChargesUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const setApplicationCharges = async (dataObject) => {
  try {
    const data = await api.post(`${appllicationChargesSetUrl}`, dataObject);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateChargeStatus = async (dataObject) => {
  try {
    const data = await api.post(`${chargeStatus}`, dataObject);

    if (data.status === ERROR_CODES.success) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateAddedChargeItem = async (payload: any) => {
  try {
    const res = await api.post(`${chargeTypeUrl}`, {
      ...payload,
    });

    if (res.status === ERROR_CODES.success) {
      return res;
    } else return null;
  } catch (error) {
    return null;
  }
};

export const createChargeTemplate = async (dataObject) => {
  try {
    const data = await api.post(`${chargeTemplatesUrl}`, dataObject);

    if (data.status === 1) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createChargeTemplateMapping = async (dataObject) => {
  try {
    const data = await api.post(`${createChargeTemplateMappingUrl}`, dataObject);

    if (data.status === 1) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createProductChargeMapping = async (dataObject) => {
  try {
    const data = await api.post(`${productChargesTemplatesUrl}`, dataObject);

    if (data.status === 1) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getChargeTemplates = async () => {
  try {
    const data = await api.get(`${chargeTemplatesUrl}`, []);

    if (data.status === 1) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductChargeTemplates = async (id) => {
  try {
    const data = await api.get(`${productChargesTemplatesUrl}?id=${id}`, []);

    if (data.status === 1) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getApplicationProductCatalog = async (catalog) => {
  try {
    const data = await api.get(`${applicationProductCharges}${catalog}`, []);

    if (data.status === 1) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
