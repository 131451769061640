import { Box, Grid, Stack } from "@mui/material";
import { FC } from "react";

interface StandingInstructionsHeaderPropType {
  data?: Object;
  typeCaseClass?: any;
}

export const StandingInstructionsHeader: FC<StandingInstructionsHeaderPropType> = ({ data, typeCaseClass }) => {
  const payload: any = data;
  const primaryAddress = payload?.formData?.addressData?.permanentAddress;

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const paAddress = paAddressSegments.join(", ");
    paAddresses.push(paAddress);
    return (
      <>
        <Grid className={`security-doc-type-variable ${typeCaseClass}`}>
          {paAddressSegments.map((item, i) => {
            return <div key={i}>{item}</div>;
          })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container columns={12}>
        <Grid xs={4} style={{ marginLeft: "auto" }}>
          <Stack width={"100%"}>
            <Box style={{ padding: "10px" }}>
              <Stack className={`security-doc-type-variable ${typeCaseClass}`}>
                {payload.formData.personalData.initials} {payload.formData.personalData.primaryLastName}
              </Stack>
              <Stack mb={1}>
                <PAAdress />
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
