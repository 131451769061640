import { Downgraded, useState } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import {
  branchDetails,
  creditData,
  masterDataDetails
} from "../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import {
  disableAccess
} from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { numberToWords, trimWithDelimiter } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { DocumentFooter } from "../../../../../InputHelperComponents/DocumentFooterComponent/DocumentFooterComponent";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonContentAlignType from "../CommonContentAlignType";
import CommonDocReference from "../CommonDocReference";
import { SinglePageBreak } from "../CommonPageBreaks";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import GuranteeCell from "./GuranteeCell";
import { GuranteeNameAdress, GuranteeSign } from "./GuranteeSign";

interface GuaranteeBondPropTypes {
  applicationId?: any;
  data?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  currentApplicationWfData?: any;
  disburseLoanAmount?: any;
}

export const GuaranteeBond: FC<GuaranteeBondPropTypes> = ({
  applicationId,
  data,
}) => {
  const componentRef: any = useRef();
  const creditFileData: any = creditData.attach(Downgraded).get();
  const guarantorData = creditFileData?.formData?.securityData?.guarantors || [];
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const jointBorrowerData = data?.formData?.jointBorrowerData || [];
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const personalData = creditFileData?.formData?.personalData;
  const primaryAddress = creditFileData?.formData?.addressData?.permanentAddress;
  const [disburseLoanValue, setDisburseLoanValue]: any = React.useState(0.0);
  const [loanAmountInWords, setLoanAmountInWords]: any = React.useState("");
  const documentId = securityDocumentPermissions.guaranteeBond || "guarantee_bond";
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();
  const masterData: any = useState(masterDataDetails);
  const [referenceType, setReferenceType]: any = React.useState(1);
  const [referenceStyles, setReferenceStyles]: any = React.useState({
    top: {
      display: "none",
    },
    bottom: {
      display: "none",
    },
  });
  const [positionStyleType, setPositionStyleType] = React.useState("page-footer-bottom");
  const [contentAlignType, setContentAlignType]: any = React.useState(1);

  const permissionToPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    const { trialData: trailObjectData }: any = data;
    let { LoanAmount, loanAmount } = trailObjectData?.trailCalParams;
    loanAmount = LoanAmount || loanAmount || creditFileData.formData.creditData.loanAmount || 0.0;

    setDisburseLoanValue(loanAmount);
    setLoanAmountInWords(numberToWords(loanAmount));
  }, []);

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  useEffect(() => {
    if (referenceType == 1) {
      setReferenceStyles((previousState) => {
        return { bottom: { display: "block" }, top: { display: "none" } };
      });
      setPositionStyleType("page-footer-bottom");
    } else if (referenceType == 2) {
      setReferenceStyles((previousState) => {
        return { top: { display: "block" }, bottom: { display: "none" } };
      });
      setPositionStyleType("page-footer-top");
    }
  }, [referenceType]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [
      primaryAddress?.addressLine1 || "",
      primaryAddress?.addressLine2 || "",
      primaryAddress?.addressLine3 || "",
      primaryAddress?.addressLine4 || "",
    ].filter((obj) => obj);
    const paAddress = paAddressSegments.join(", ").trim();
    paAddresses.push(paAddress);
    return (
      <>
        <span className={`security-doc-type-variable ${typeCaseClass}`}>
          {paAddresses.join("")}
        </span>
      </>
    );
  };

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase].typeCaseClass);
  };

  const renderTitles = (primaryTitle) => {
    if (primaryTitle == "") {
      return null;
    }

    const title: any = masterData?.TITLE?.get()?.find((t) => t.id == primaryTitle);
    return `${title?.name}.`;
  };

  const GurantorSignatures = ({ }) => {
    return (
      <>
        {guarantorData.length > 0 &&
          guarantorData?.map((item, i) => {
            if (i === 0) {
              return (
                <>
                  <GuranteeSign
                    key={i}
                    item={item}
                    branch={nearestBranch}
                    typeCaseClass={typeCaseClass}
                    renderTitles={renderTitles}
                  />
                </>
              );
            } else if (i % 3 == 0) {
              return (
                <>
                  <GuranteeSign
                    key={i}
                    item={item}
                    branch={nearestBranch}
                    typeCaseClass={typeCaseClass}
                    renderTitles={renderTitles}
                  />
                </>
              );
            } else {
              return (
                <GuranteeSign
                  key={i}
                  item={item}
                  branch={nearestBranch}
                  typeCaseClass={typeCaseClass}
                  renderTitles={renderTitles}
                />
              );
            }
          })}
      </>
    );
  };

  const referenceHandler = (value) => {
    setReferenceType(value);
  };

  const textAlignHandler = (value) => {
    setContentAlignType(value);
  };

  const renderJointBorrowerDetails = () => {
    if (jointBorrowerData.length < 1) {
      return null;
    }

    return (
      <>
        {jointBorrowerData.map((jb, index) => {
          const initialsInFull = jb?.personalData?.initialsInFull;
          const jbLastName = jb?.personalData?.primaryLastName;

          if (index === jointBorrowerData.length - 1) {
            return (
              <>
                <JbDetailsProvider jb={jb} />
              </>
            );
          }
          return (
            <>
              <JbDetailsProvider jb={jb} />,
            </>
          );
        })}
      </>
    );
  };

  const JbDetailsProvider = ({ jb }) => {
    const initialsInFull = jb?.personalData?.initialsInFull;
    const jbLastName = jb?.personalData?.primaryLastName;
    return (
      <>
        <span className={`security-doc-type-variable ${typeCaseClass}`}>
          {" "}
          {`${initialsInFull} ${jbLastName}`}
        </span>{" "}
        of{" "}
        <span>
          <JBAdress jb={jb} />
        </span>
      </>
    );
  };

  const JBAdress = ({ jb }) => {
    let addresses: String[] = [];

    let addressSegments = [
      jb?.addressData?.permanentAddress?.addressLine1 || "",
      jb?.addressData?.permanentAddress?.addressLine2 || "",
      jb?.addressData?.permanentAddress?.addressLine3 || "",
      jb?.addressData?.permanentAddress?.addressLine4 || "",
    ].filter((obj) => obj);
    let address = addressSegments.join(", ").trim();
    address = trimWithDelimiter(address);
    addresses.push(address);

    return (
      <>
        {addresses.map((item, i) => {
          return (
            <>
              <span className={`security-doc-type-variable ${typeCaseClass}`}>{`${item}`}</span>
            </>
          );
        })}
      </>
    );
  };

  const getPageMargins = () => {
    return `@page { margin: ${"6mm"} ${"10mm"}  ${"15mm"} ${"10mm"} !important; }`;
  };

  return (
    <Grid container className="inner-component-full-height basic-font">
      <style>{getPageMargins()}</style>
      <div className="full-width full-height">
        <SecurityDoceumentsFileDocumentsViewSection
          data={data}
          applicationId={applicationId}
          isSecurityDocument={true}
          securityDocumentKey={"guarantee-bond"}
        />
        <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef}>
          <div style={referenceStyles.top}>
            <DocumentFooter positionStyleType={positionStyleType} />
          </div>
          <CommonSecurityDocHeader documentName={"Guarantee Bond"} textCase={"lower"} />
          <Grid container columns={12} mb={2.5}>
            <Grid item xs={12} mb={1.5}>
              <Typography variant="body1">
                To : <span className="securitydoc_upperCaseText">Pradeshiya Sanwardhana Bank</span>
              </Typography>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">1.</span>For the purpose of this guarantee, it is
                    hereby agreed that:-
                    <br />
                    <div style={{ paddingTop: "15px", textAlign: "left" }}>
                      (i) the term "debtor" shall mean{" "}
                      <span
                        className={`security-doc-type-variable ${typeCaseClass}`}>{`${personalData?.initialsInFull} ${personalData?.primaryLastName}`}</span>{" "}
                      of <PAAdress />{" "}
                      <span style={{ marginLeft: "-4px" }}>
                        {jointBorrowerData.length > 0 ? "," : ""}
                      </span>{" "}
                      {renderJointBorrowerDetails()}
                    </div>
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    Interpretation (*Here insert Name/s and address/es of the borrower/s)
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (ii) if it shall so happen that the name of the debtor inserted in sub-clause
                    (i) shall be that of a firm or of a limited company or other corporation or of a
                    society or of any committee or association or other unincorporated body any of
                    the provisions of this guarantee which shall be primarily and literally
                    applicable to the case of a single and individual debtor only shall be construed
                    and take effect so as to give the Bank hereunder a guarantee, for the moneys
                    herein mentioned owing by such firm and every member thereof or by such limited
                    company or corporation or by such society or committee or association or other
                    unincorporated body as identical or analogous as may be with or to that which
                    would have been given for the moneys owing by a single individual if the debtor
                    had been a single individual.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Debtor incorporated Or unincorporated body</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (iii) if it shall so happen that the name of the debtor inserted in sub-clause
                    (i) shall be that of a firm this guarantee shall he deemed to be a continuing
                    guarantee for all moneys herein mentioned owing by the firm whether under its
                    present or any other name or by the persons or person carrying on business in
                    the name of or in succession to the firm or by any one or more of such persons
                    although by death retirement or admission of partners or other causes the
                    constitution of the firm may have been in part or wholly varied and this
                    guarantee shall extend to all moneys herein mentioned owing by any company one
                    of whose principal objects is to takeover the business of the firm however named
                    or constituted.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Changes in Debtor Firm</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (iv) if it shall so happen that the name of the debtor inserted in sub-clause
                    (i) shall be that of a limited company or other corporation this guarantee shall
                    be deemed to be a continuing guarantee for all moneys herein mentioned owing by
                    the company or other corporation notwithstanding any change in its name style or
                    constitution whether upon or by absorption amalgamation reconstruction or
                    otherwise and this guarantee shall extend to all moneys herein mentioned owing
                    by any company corporation or concern resulting from such change.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Changes in Debtor Company</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (v) if it shall so happen that the name of the debtor inserted in sub-clause (i)
                    shall be that of a society or committee or association or other unincorporated
                    body, this guarantee shall be deemed to be a continuing guarantee for all moneys
                    herein mentioned owing by the society committee association or unincorporated
                    body notwithstanding any change in its name style or constitution Or in the
                    constitution of its members and this guarantee shall extend to all moneys herein
                    mentioned owing by any society committee association or unincorporated body
                    resulting from such change.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Changes in unincorporated Debtor</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (vi) if it shall so happen that the name of the debtor inserted in sub clause
                    (i) shall be that of a limited company or other corporation or of a society or
                    committee or association or other unincorporated body, any reference in this
                    guarantee to insolvency shall be deemed to be a reference to winding-up or other
                    analogous proceeding and the moneys herein mentioned shall be deemed to include
                    any money owing in respect of debentures or debenture stock of the limited
                    company or other corporation held by or on behalf of the Bank.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Winding-up of Debtor bodied</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell style={{ outline: "1px solid red" }}>
                    <div className="cell-inner-container">
                      <div className="left-col">(vii)</div>
                      <div className="right-col">
                        <div className="list-item">
                          <div className="list-item-number">(a)</div>
                          <div className="list-item-content">
                            the expression “the Bank” shall mean the Pradeshiya Sanwardhna Bank duly
                            incorporated and having its Registered Office in Kelaniya and its
                            successors and assigns.
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="list-item-number">(b)</div>
                          <div className="list-item-content">
                            the word “Person” shall, where the context so requires or admit includes
                            a firm, company or corporation.
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="list-item-number">(c)</div>
                          <div className="list-item-content">
                            the singular shall include the plural or vice versa.
                          </div>
                        </div>
                        <div className="list-item">
                          <div className="list-item-number">(d)</div>
                          <div className="list-item-content">
                            the expression ‘this Guarantee’ shall be construed as including each
                            separate or independent stipulation or agreement herein contained.
                          </div>
                        </div>
                      </div>
                    </div>
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell></GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (viii) the expression "moneys herein mentioned" shall mean all and every the
                    sums and sum of money whether in Sri Lanka Rupees or the currency of any other
                    State (if any) which may now be and which shall at any time and front time to
                    time presently actually or in future or contingently become due or owing and
                    remain unpaid to the Bank anywhere whether In or outside Sri Lanka by the
                    debtor, whether solely or jointly or jointly and severally with any other
                    person, firm, company, corporation, society or unincorporated body, whether upon
                    loan account or byway of advances, whether upon notes or bills discounted or
                    paid credits made or opened to or by or for the accommodation or at the request
                    of the debtor, or upon any guarantee or indemnity given by the debtor to the
                    Bank or given by the Bank for or ,at the request of the debtor or upon any
                    transaction of any kind whatsoever between the debtor and the Bank including
                    every renewal or extension of any of the foregoing kinds of transaction whether
                    any such renewal or extension be made or effected with or without my/our consent
                    or notice to me/us and including all liabilities of the debtor to the Bank
                    arising or incurred presently or contingently during the three calendar months'
                    period of notice hereinafter referred to TOGETHER with all interest and
                    government levy or any tax on capital borrowed or owing, or interest thereon,
                    discounts, handling charges, commitment charges, bankers' charges and expenses
                    of every description in accordance with the Bank's usual course of business and
                    all legal and other costs, charges and expenses (whether taxable or not)
                    occasioned by or incidental to this or any other security held by or offered to
                    the Bank as security for the same indebtedness or by or to the enforcement of
                    any such security on a full indemnity basis.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right ">
                  <GuranteeCell>Moneys herein mentioned</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (ix) any moneys herein mentioned shall for the purpose of this guarantee be
                    deemed to be owing notwithstanding any legal or other limitations (whether under
                    the law of prescription or limitations or otherwise) any incapacity of or
                    limitation upon the debtor or any person acting or purporting to act on behalf
                    or in the name of the debtor with respect to borrowing power of the Bank or any
                    person acting or purporting to act on behalf or in the name of the Bank with
                    respect to lending or any defect or insufficiency in the borrowing powers of the
                    debtor or any such person aforesaid or in the lending powers of the Bank or any
                    such person aforesaid or in the exercise thereof respectively, which might be a
                    defense as between the debtor and the Bank; and this guarantee shall extend to
                    and include all moneys herein mentioned lent paid disbursed or advanced by the
                    Bank in any way for or on account of or apparently for the purposes of the
                    debtor, or arising from any arrangement or transaction whatsoever made with or
                    purporting to be made on behalf of the debtor at the request of or by honoring
                    cheques, drafts, bills or notes of or Signed by or by carrying out the requests
                    or directions of the debtor or any person being or appearing to be or acting or
                    purporting to act as a partner, director, manager, officer or office- bearer( as
                    the case may be) of the debtor or a person authorized by the debtor,
                    notwithstanding any defect or irregularity in his appointment and
                    notwithstanding any want of authority on the part of such partner, director,
                    manager, officer, office- bearer or person to bind or to act on behalf or in the
                    name of the debtor, Nor shall this guarantee be or become in anyway prejudiced
                    affected or unenforceable either wholly or in part by reason of any act, matter
                    or circumstance concerning the debtor or any other person or concerning the
                    account or conduct of any transaction of or with the debtor or any other person,
                    whether such fact, matter or circumstance be known to or at any time come to the
                    knowledge of the Bank or not, and whether or not the same be disclosed by the
                    Bank to me/us or any of us.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    Liability independent of existence of principal
                    <br />
                    <br />
                    <br />
                    <Stack direction={"row"} mt={20}>
                      No duty on Bank to disclose information to guarantors
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    (x) if this guarantee shall be given by more than one person, the liability of
                    such person and all covenants on their part herein contained shall in all
                    respects be and be deemed to he joint and several and separate and every
                    agreement obligation and understanding on their part and every right power or
                    privilege derived hereunder by the Bank shall be construed accordingly.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Joint and several rights and obligations</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SinglePageBreak />
          <Grid container columns={12} mb={2.5}>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">2.</span>IN CONSIDERATION of the Bank at my/our
                    request agreeing not to require Immediate payment of such of the moneys herein
                    mentioned as may be now due and/or in consideration of any moneys herein
                    mentioned which the Bank may hereafter advance or pay or which may hereafter
                    become due and/or in consideration of the Bank otherwise giving credit or
                    affording banking facilities to the debtor for as long as Bank may think
                    fit,I/we the undersigned
                    <br />
                    <br />
                    <SinglePageBreak />
                    {guarantorData.length > 0 &&
                      guarantorData?.map((item, i) => {
                        return (
                          <>
                            <GuranteeNameAdress
                              key={i}
                              item={item}
                              index={i}
                              typeCaseClass={typeCaseClass}
                            />
                            <SinglePageBreak />
                          </>
                        );
                      })}
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_textrow">
                  <GuranteeCell>Guarantee</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    hereby agree to pay to the Bank in
                    <span
                      className={`security-doc-type-variable ${typeCaseClass}`}
                      style={{ marginLeft: "7px", marginRight: "7px" }}>
                      {nearestBranch}
                    </span>
                    Branch
                    <SinglePageBreak />
                    <br />
                    <br />
                    or at such other place as the Bank may from time to time direct moneys herein
                    mentioned ten days after demand in writing is made on me/us PROVIDED ALWAYS that
                    the total liability ultimately enforceable against me/us under this guarantee
                    shall not exceed the sum of{" "}
                    <span className={`security-doc-type-variable ${typeCaseClass}`}>
                      {loanAmountInWords}
                    </span>{" "}
                    Rs : {formatCurrency(disburseLoanValue, 2)}
                    <br />
                    <br />
                    and all interest thereon computed from the date or respective dates on which the
                    moneys herein mentioned were lent or advanced by the Bank to the debtor or
                    became due or payable by the debtor to the Bank whether demanded from the debtor
                    or not at such rate or rates as may be fixed or determined from time to time by
                    the General Manager of the Bank or other officer duly authorized in that behalf
                    from time to time and all such further sums by way of Government Levy or any tax
                    on capital borrowed or owing or interest thereon,handling charges, commitment
                    charges, Banker’s charges and expenses as shall accrue all in accordance with
                    Bank’s usual course of business and also all legal costs, charges and expenses
                    incurred by the Bank. I/we hereby agree that payment shall be in the currency in
                    which the moneys obligations or liabilities of the debtor were due owing or
                    incurred. If I am/we are compelled by law to make any deduction or withholding
                    I/we hereby undertake that I/we will promptly pay to the Bank such additional
                    amount as will result in the net amount received by the Bank equal to the full
                    amount which would have been receivable had there been no deduction or
                    withholding. Any additional amount so paid shall not be treated as interest but
                    as agreed compensation .if and to the extent I/we fail to pay the moneys herein
                    mentioned on demand the Bank may in its absolute discretion without notice to
                    me/us or any of us purchase at any time thereafter so much of a currency as the
                    Bank considers necessary or desirable to cover the obligations and liabilities
                    of the debtor in such currency hereby guaranteed at the then prevailing spot
                    rate of exchange of the Bank (as conclusively determined by the Bank) for
                    purchasing such currency with Sri Lanka Rupees and I hereby agree/We hereby
                    jointly agree and severally agree to indemnify the Bank against the full Sri
                    Lanka Rupee price (including all cost. charges and Expenses) paid by the Bank.
                    All money received or held by the Bank from the debtor or under this guarantee
                    may from time to time be converted into such other currency as the Bank
                    considers necessary or desirable to cover the obligations and liabilities of the
                    debtor in that currency at the then prevailing spot rate of exchange of the Bank
                    (as conclusively determined by the Bank) for Purchasing the currency to be
                    acquired with existing currency. A demand in writing shall be deemed to have
                    been duly given to me/us or my/our respective legal personal representatives by
                    sending the same by post addressed to me/us at the address/es herein written or
                    at my/our last known address/es and shall be effectual notwithstanding and
                    change of residence or death and pending probate/s of my/our Will/Wills or
                    Grant/s of administration in respect of my/our estate/s and such demand shall be
                    deemed to be received by me/us or our legal personal representatives and shall
                    be effectual as if I/we were still living twenty four hours after the posting
                    thereof and in proving such service it shall be sufficient to prove that the
                    letters/s containing the demand was/were properly addressed and put into a Post
                    office. A demand for payment or any notice hereunder may be made by telex or
                    cable addressed to me/us and such telex or cable shall be deemed to have been
                    served at the time of dispatch and shall notwithstanding that I/we may be dead
                    be effectual as if I/we were still living.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} mt={2} pt={5}>
                      (*Here state place of payment)
                    </Stack>
                    <br />
                    <br />
                    <Stack direction={"row"} mt={2} pt={3}>
                      (* Here insert type of currency and amount in words and Figures)
                    </Stack>
                    <br />
                    <br />
                    <Stack direction={"row"} mt={25} pt={28}>
                      Procedure for demand
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">3.</span>This guarantee shall not be considered as
                    satisfied by any intermediate payment or satisfaction of the whole or any part
                    of the moneys herein mentioned but shall be a continuing security and shall
                    extend to cover any sum of money which shall for the time being and from time to
                    time constitute the ultimate balance due or be deemed to be due for the purpose
                    of this guarantee by the debtor to the Bank in respect of the moneys herein
                    mentioned notwithstanding my insanity or death or other incapacity or the
                    insanity death or other incapacity of any of us and including any loans advances
                    accommodation or banking facilities granted by the Bank to the debtor during the
                    three calendar months period next hereinafter referred to and all Government
                    Levy or any other tax as aforesaid and interest and bank charges on and in
                    connection with such further loans advances accommodation or banking facility up
                    to the limit aforesaid.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Continuing Security</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">4.</span>This guarantee shall be binding as
                    continuing security on me/us and on my /our executors or administrators and
                    my/our legal representatives until the expiration of three calendar months after
                    I/we or in case of my/our dying or becoming under disability my/our executors or
                    administrators or my/our legal representatives shall have given to the Bank
                    notice in writing to discontinue and determine the same and shall have paid to
                    you the moneys herein mentioned up to the limit of liability and all interest
                    thereon and all tax and other charges as specified in paragraph 2 above and due
                    hereunder at the expiration of such notice. And in the case of this being a
                    joint and several guarantee he same shall remain in force and binding as a
                    continuing security as against (a) each of us and the estate or estates of each
                    of us notwithstanding the death or disability of us/or either or any of us until
                    the expiration of three calendar months after the Bank shall have received
                    'notice in writing to discontinue and determine the same, from or from the
                    survivors or survivor of us and the executors or administrators or the legal
                    representatives of such of us may have died or become under disability and until
                    the Bank shall have received Payment of the moneys herein mentioned up to the
                    limit of liability and all interest thereon and all tax and other charges as
                    specified in paragraph 2 above and due hereunder at the expiration of such
                    notice, (b) each of us and the estate or estates of each of us until the
                    expiration of three calendar months after the Bank shall have received such
                    notice as aforesaid from either or any of us or either or any of our afore
                    written and until the Bank shall have received payment of the moneys herein
                    mentioned up to the limit of liability and all interest thereon and all tax and
                    other charges as specified in paragraph 2 above and due hereunder at the
                    expiration of such notice and (c) such of us as shall not have given such notice
                    as aforesaid and as against the estate or respective estates of such of us as
                    shall not have given such notice as aforesaid notwithstanding that it may have
                    ceased to be binding on any other or others of us by reason of any such notice
                    as aforesaid having been given to discontinue and determine this guarantee.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} pt={3}>
                      Notice of termination of guarantee
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">5.</span>In the event if this guarantee ceasing
                    from any cause whatsoever to be binding on me/us or my/our executors or
                    administrators or my/our legal representatives (or as the case may be on me/us
                    and each or any of us and the executors or administrators or the legal
                    representative or me /us and of each or of any of us), the Bank shall be at
                    liberty without thereby affecting its rights hereunder to open a fresh account
                    or to continue any then existing account with the debtor and no moneys paid from
                    time to time into any such account by or on behalf of the debtor and
                    subsequently drawn out by the debtor shall on settlement of any claim in respect
                    of this guarantee be appropriated towards or have the effect of payment of any
                    part of the moneys herein mentioned due from the debtor at the time of this
                    guarantee ceasing to be so binding or of the interest thereon unless the person
                    paying in such moneys shall at that time in writing direct the Bank specially to
                    appropriate the same to that purpose and in any event my/our and each of our
                    liability and the liability of my/our and each of our afore written for the
                    moneys herein mentioned as on the date of this guarantee ceasing to operate as
                    aforesaid shall be effective and shall remain notwithstanding and unaffected by
                    any mere subsequent payment into or out of the account by or on behalf of the
                    debtor.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} pt={3}>
                      Opening of fresh accounts optional
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">6.</span>(i) l/We and each of us hereby agree that
                    any statement, extract, writing or other document showing the moneys herein
                    mentioned owing by the debtor to the Bank and made out of the books, records or
                    documents of the Bank and signed and certified by the General Manager or
                    Accountant of the Bank or by any other person who may be specially appointed for
                    that purpose by the Bank or by the General Manager thereof shall be and shall be
                    deemed sufficient at law and conclusive proof against me/us and my/our executors
                    or administrators or my/our legal representatives (or, as the case may be
                    against us and each of us and our and each of our executors or administrators or
                    legal representatives) of the contents thereof without any other documents or
                    vouchers to support the same and the same shall further be deemed to be
                    conclusive proof against me/us and each of us and my/our privy or privies of the
                    fact that the amounts set out therein are due by the debtor to the Bank.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} pt={4}>
                      Evidence of indebtedness
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    {" "}
                    &nbsp; (ii) Any admission or acknowledgment in writing by the debtor or by any
                    person being or appearing to be or acting or purporting to act as a partner,
                    director, manager, office or office-bearer (as the case may be) of the debtor or
                    by a person expressly or impliedly authorized by the debtor of the amount of the
                    moneys herein mentioned owing by the debtor to the Bank and any judgement
                    obtained by the Bank against the debtor in respect thereof shall be binding and
                    conclusive on and against me/us and my/our executors or administrators or my/our
                    legal representatives (or as the case may be) on and against me/us and each of
                    us 'and my/our and each of my/our executors or administrators or legal
                    representative/s in all courts of law and elsewhere.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} pt={10}>
                      Admissions
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">7.</span>The Bank is at liberty (without the
                    consent of or notice to me/us or any of us or my/our any of or our executor/s
                    administrator/s or legal representative/s) without thereby affecting its rights
                    hereunder, at any time and from time to time, to enter into a new, renew or
                    extend any obligation liability or transaction of any kind of or with the
                    debtor, to substitute in place thereof any new or alternative obligation,
                    liability or transaction, to add to vary or alter the terms and conditions
                    thereof or of any new or alternative obligation, liability or transaction
                    respectively to determine enlarge or vary any credit to the debtor, to vary any
                    credit to the debtor , to vary exchange abstain from perfecting or discharge or
                    release, in whole or in part, any guarantee or security now or already received
                    taken or held or to be received taken or held by the Bank from or on behalf or
                    on account of the debtor or me/us or any of us or any other person in respect of
                    or for or on account of the moneys herein mentioned or any part thereof, to
                    renew bills or promissory notes in any manner and to compound with, give time
                    for payment or other indulgence to, accept compositions from and make any other
                    arrangements with the debtor or me or my afore written (or as the case may be,
                    with us or any or either if us or our or any of our afore written) or any person
                    liable for the moneys herein mentioned or liable on any bill not guarantee or
                    security received taken or held or to be received taken or held by the Bank. If
                    this guarantee shall be executed or given by more than one person then the Bank
                    is also at liberty (without the consent of or notice to any of us or our or any
                    of our executor/s administrators or legal representative/s) to discharge or
                    release any of us from the obligations of this guarantee or any action or
                    actions that may be commenced or any decree or decrees which may he entered upon
                    this guarantee without thereby prejudicing or affecting any of the rights and/or
                    remedies of the Bank against the other or others of us under this guarantee or
                    any action or actions that may have been commenced and/ or any decree or decrees
                    that may have been entered against the remaining guarantor or guarantors. The
                    undersigned guarantor/guarantors agrees/ agree to be bound by this guarantee
                    notwithstanding that any other or others of the guarantor/guarantors who were
                    intended to sign and execute or to be bound may not do so or be effectually
                    bound and notwithstanding that this guarantee may be determined or become
                    invalid or unenforceable against any one or more of such persons whether or not
                    the deficiency is known to the Bank.
                  </GuranteeCell>{" "}
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"} pt={6}>
                      Indulgence
                    </Stack>
                  </GuranteeCell>
                </Grid>{" "}
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">8.</span>Any dividend composition any payment the
                    Bank may receive from the debtor or me/us or any of us or from any other person,
                    or from the estate of the debtor or my/our estate or estates or the estate of
                    any other person, whether in or in consequence of liquidation, bankruptcy,
                    liquidation by arrangement or composition with creditors or otherwise howsoever
                    shall be taken and applied as payments in gross, without any right on my part or
                    on the part of my afore written (or as the case may be on our part or on the
                    part of any of us or that of our or any of our afore written) to stand in the
                    place of the Bank in respect of or to claim the benefit of any such dividend,
                    composition or payment or any security held by the Bank until the Bank shall
                    have received the full amount of all the moneys herein mentioned, and shall not
                    prejudice the Bank's right to recover from me or my afore written (or as the
                    case may be from us and each of us and our and each of our afore written) to the
                    full extent of this guarantee.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Payment on account</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">9.</span>Although my/our ultimate liability
                    hereunder cannot exceed the limit hereinbefore mentioned, and all interest
                    thereon and all tax and other charges as specified in paragraph 2 above, yet
                    this present guarantee shall be construed and take effect as a guarantee of the
                    whole and every part of the moneys herein mentioned and accordingly I am/we are
                    and each of us is not to be entitled as against the Bank to any right of proof
                    in the insolvency of the debtor or other right of a surety discharging his
                    liability in respect of the principal debt, unless and until the whole of the
                    moneys herein mentioned including interest and charges as aforesaid shall have
                    first been completely discharged and satisfied. And further, for the purpose of
                    enabling the Bank to sue the debtor to prove against the estate of the debtor
                    for the whole of the moneys herein mentioned or to preserve intact the liability
                    of the debtor or of another person, the Bank may at any time and from time to
                    time place and keep for such time as it may think prudent any moneys received
                    recovered or realized hereunder to and at a separate or suspense account to
                    my/our credit or to the credit of any of us or to the credit of such other
                    person or transaction, if any, as they shall think fit, without any intermediate
                    obligation on the part of the Bank to apply the same or any part thereof in or
                    towards the discharge of the moneys herein mentioned or any intermediate right
                    on my/our part to sue the debtor or prove against his estate in competition with
                    or so as to diminish any dividend or other advantage, that would or might come
                    to the Bank or to treat the liability of the debtor as diminished.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    Whole of moneys to be guaranteed
                    <br />
                    <br />
                    <Stack direction={"row"} pt={3}>
                      No proof in competition with the Bank
                    </Stack>
                    <br />
                    <br />
                    <Stack direction={"row"} pt={20}>
                      Suspense account
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">10.</span>No assurance or security given to the
                    Bank which may be avoided, and no payment made to the Bank which may be
                    recoverable, under any law for the time being in force relating to bankruptcy or
                    insolvency or to limited liability companies or corporations and no release,
                    settlement /or discharge which may have been given or made on the faith of any
                    such assurance security or payment shall prejudice or affect the Bank's right of
                    recovery from me/us and each of us to the full extern of this guarantee.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Avoidable security or payment</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">11.</span>I/we and each of us do hereby warrant to
                    the Bank that I/we or any of us have/has not taken from the debtor in respect of
                    the liability hereby undertaken by me/us and each of us, and I/we and each of us
                    hereby undertake not to demand or accept or to negotiate assign charge or
                    otherwise dispose of any moneys obligations or liabilities now or hereafter due
                    or owing to me/us or any of us from the debtor or any of us or any promissory
                    note, bill of exchange, guarantee, indemnity, mortgage, charge or other security
                    for the same or to take any step to enforce any right against the debtor or any
                    of us or to claim any set-off or counter claim against the same, or to take from
                    the debtor either directly or indirectly without the previous written consent of
                    the Bank any sum of money or promissory note, bill of exchange, mortgage, charge
                    or other security, whether merely personal or involving a charge on any property
                    whatsoever of the debtor, whereby I/we or any of us or any person claiming
                    through me/us or any of us by endorsement assignment or otherwise would or might
                    on the bankruptcy or insolvency of the debtor and to the prejudice of the Bank
                    increase the proof in such bankruptcy or insolvency or diminish the property
                    distributable amongst the creditors of the debtor. And as regards any such
                    security as aforesaid which I / we or any of us may have taken or may take or if
                    I/we shall otherwise act in breach of this clause anything so received and any
                    benefit derived directly or indirectly by me/us or any of us therefrom or
                    thereby shall be held in trust for the Bank as a continuing security for my/our
                    liability to the Bank hereunder for the fulfillment of my/our and each of our
                    obligations hereunder and shall be forthwith deposited with the Bank for that
                    purpose by me or such of us who may, have taken or may take the same or the
                    amount of my/our liability under this guarantee shall be increased by an amount
                    equivalent to that which the dividend payable to the Bank on the bankruptcy or
                    insolvency of the debtor is thereby reduced.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    No counter- Security without the Bank's consent
                    <br />
                    <br />
                    <Stack pt={35}>Or to be held in trust for the Bank</Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">12.</span>The liability of me/us and each of us
                    hereunder shall not be affected by any failure on the part of the Bank to take
                    any security or by any invalidity or insufficiency of any security taken, or by
                    any existing or future agreement by the Bank as to the application of any
                    advances made or to be made to the debtor or in the event of this guarantee
                    proving not to be binding on me/us or any of us for any reason whatsoever. And
                    this guarantee shall be in addition to and shall not in any way be prejudiced or
                    affected by any collateral or other security now or hereafter held by the Bank
                    for-all or any part of the moneys herein mentioned, nor shall such collateral or
                    other security or any lien to which the Bank may be otherwise entitled or
                    the-liability of any person not party hereto for all or any part of the moneys
                    herein mentioned be in any wise prejudiced or affected by this present
                    guarantee.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Failure to Take security</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">13.</span>The Bank shall so long as any moneys
                    remain owing hereunder have a lien therefore on all stocks, shares, securities
                    and all movable property belonging to me/us (or as the case may be to each or
                    any of us) from time to time held by the Bank, whether for safe custody or
                    otherwise and on all moneys now or hereafter standing to the credit of me/us (or
                    as the case may be of each or any of us) with the Bank whether on any account
                    whatsoever.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Lien</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">14.</span> In addition to any general lien or
                    similar right which the Bank as bankers may be entitled to by law, the Bank may
                    at any time and without notice to me/us combine or consolidate all or any of
                    my/our accounts including foreign currency accounts with any of my/our
                    liabilities hereunder to the Bank and set off transfer realize apply or
                    appropriate any sum or sums standing to the credit of any one or more of such
                    accounts in or towards satisfaction of the moneys herein mentioned, whether in
                    Sri Lanka Currency or any foreign currency and I/we do hereby irrevocably and
                    for valuable consideration authorize the Bank at any time and from time to time
                    and whenever it may deem expedient and without any notice whatsoever to me/us to
                    debit any savings or fixed deposit account (whether matured or not) including
                    foreign currency accounts in my/our name/s/or kept or maintained by me/us with
                    the amount of the moneys herein mentioned or any part or parts thereof
                    regardless of the fact that as a result of consequence thereof any orders for
                    payment of money drawn by me/us upon the Bank whether on or before or after the
                    date of such combination, consolidation, set-off, transfer, .debit, realization
                    application or appropriation may be returned or dishonored by the Bank and where
                    such combination, consolidation, set-off, or transfer• requires or involves the
                    conversion of one currency into another such conversion shall be calculated at
                    the then prevailing spot rate of exchange of the Bank (as conclusively
                    determined by the Bank) for purchasing the currency in which the moneys,
                    indebtedness or liabilities were due or owing or to become due or owing or
                    incurred with the existing currency so converted.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    Set-off
                    <br />
                    <br />
                    <Stack direction={"row"} pt={18}>
                      Authority <br /> to debit
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">15.</span>Nothing herein contained shall be
                    construed to mean or be deemed to imply that there is any obligation on the Bank
                    to act or continue to act upon this writing for the benefit of the debtor or at
                    any time to afford the debtor any banking or credit facility or at any time to
                    enter into any transaction with or at the request or for the benefit of the
                    debtor and the Bank shall in its sole and absolute discretion refuse to so act
                    or continue to act upon this writing as aforesaid or refuse to afford the debtor
                    any banking or credit facility or refuse to enter into any transaction with or
                    for the debtor.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>No obligation on Bank</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">16.</span>I / we hereby declare that there are no
                    legal or arbitration proceedings so far as is known or ought to be or deemed to
                    be known by me/us and each or any of us threatened or likely before and Court,
                    Arbitrator or Tribunal or judicial or quasi-judicial body or officer or person
                    in Sri Lanka or outside which would materially and adversely affect my/our
                    financial condition business or operations.
                    <br />
                    <div className="gurantee-bond-sub-text">
                      (N.B. All executants to affix signatures etc. at the foot of this page)
                    </div>
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>
                    <Stack direction={"row"}>
                      No pending <br /> litigation
                    </Stack>
                  </GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">17.</span>I/We and each of us specifically agree
                    that the Bank shall be at liberty either in one action to sue the debtor and
                    me/us and each or any of us jointly and severally or to proceed in the first
                    instance against me/us and each or any of us only, and further that I/ we and
                    each of us hereby renounce the right to claim that the debtor should be excused
                    or proceeded against by action in the first instance, and (where this guarantee
                    is given by more than one person) the right to claim that the Bank should divide
                    its claim against us and bring actions against us, each for his portion pro
                    rata, and the right to claim in any action brought against all of us that Bank
                    should only recover from each of us a pro rata share of the amount claimed in
                    that action, and all other rights, privileges and benefits whatsoever nothing
                    excepted to which sureties are of may be entitled at law or equity IT BEING
                    AGREED that I/we and each of us am/are and is liable in all respects hereunder
                    not merely as surety or sureties or guarantor or guarantors but as sole or
                    principal debtor or where this guarantee is signed or executed by more than one
                    person as sole or principal debtors severally and separately and jointly and
                    severally, to the extent aforementioned, including the liability to be sued,
                    before recourse is had against the debtor, or without any recourse whatsoever
                    being had to the debtor for any reason or cause whatsoever and in the absolute
                    discretion of the Bank.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Renunciation of all privileges</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">18.</span>Each of the provisions of this document
                    is severable and distinct from the others and if at any time one or more of such
                    provisions is or becomes invalid, illegal or unenforceable, the validity,
                    legality and enforce ability of the remaining provisions hereof shall not in
                    anyway be affected or impaired thereby.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Severable provisions</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">19.</span>As separate and independent stipulation
                    I/we and each of us jointly severally or separately hereby agree and undertake
                    that the moneys herein mentioned or such part thereof which may not be
                    recoverable from me/us or any of us on the footing of the instrument above
                    written whether by reason of any disability or incapacity on or of the debtor or
                    any other fact or circumstance whatsoever and whether known to the Bank or not
                    or by reason of the operation of any rule of law or equity relating to sureties
                    or guarantors shall nevertheless be recoverable from me/us and each of us
                    jointly and severally or separately as sole or principal debtor or debtors in
                    respect thereof and shall be payable by me/us and each of us jointly and
                    severally or separately to the Bank at the place hereinbefore mentioned in
                    paragraph 2 above ten days after demand in writing as aforesaid and for the
                    purpose of the Bank enforcing the liability created by this clause, the Bank
                    shall be entitled to exercise and adopt all or any of the rights powers
                    privileges and benefits conferred on the Bank by the other provisions contained
                    in this instrument.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Independent liability</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">20.</span>All representations and statements made
                    to the Bank or its managers or officers or agents by me/us or my/our agents,
                    employees or officers, or purporting to be on my/our behalf or by the debtor or
                    his agents, employees or officers or purporting to be on his behalf whether in
                    writing or otherwise are hereby warranted true and correct and intended to be
                    acted upon by the Bank and shall form the basis of the connact/s arising
                    hereunder.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Representation</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">21.</span>I/we and each of us hereby agree that so
                    long as the moneys herein mentioned or any part thereof is owing by the debtor
                    to the Bank or has not already been paid to the Bank by the debtor or by me/us
                    the liability of me/us and each of us to pay the same shall subsist and the
                    moneys herein mentioned shall be recoverable from me/us and be my/our and each
                    of our liability jointly and severally or separately notwithstanding anything to
                    the contrary herein or in any rule of law or equity or the Prescription
                    Ordinance or any other statute contained and I / we hereby further agree that
                    I/we or any of us shall not plead the Prescription Ordinance or any of its
                    provisions or any rule of statute or other law as a bar to the Bank suing me/us
                    or any of us for the recovery of the moneys herein mentioned or any part
                    thereof.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Exclusion of Prescription</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <SinglePageBreak />
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">22.</span>This guarantee shall be in addition to
                    and not in substitution for any other guarantee for the debtor given by me/us to
                    the Bank and shall be in addition to any securities you may now or hereafter
                    hold from me/us or any of us or any other person Or the debtor.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>To be additional</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">23.</span>This document and the contract
                    constituted or evidenced hereby and its contents shall be governed by and
                    interpreted Fireted accordance with the laws of Sri Lanka.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Governing Law</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">24.</span>The marginal notes are for convenience
                    only and have no legal effect.
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Marginal notes</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="securitydoc_textrow">
              <Grid container columns={12}>
                <Grid
                  item
                  xs={9}
                  className={`securitydoc_contentspace-left ${contentAlignType == 1 ? "justify-main-content-column" : ""
                    }`}>
                  <GuranteeCell>
                    <span className="list-marker">25.</span>The paper upon which this guarantee is
                    printed is and at all times shall remain the property of the Bank.
                    <br />
                    <br />
                    <GurantorSignatures />
                  </GuranteeCell>
                </Grid>
                <Grid item xs={3} className="securitydoc_contentspace-right">
                  <GuranteeCell>Property in paper</GuranteeCell>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={referenceStyles.bottom}>
            <DocumentFooter positionStyleType={positionStyleType} />
          </div>
        </Grid>
        <Box m={1}>
          <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={3} pr={1}>
              <CommonContentAlignType
                alignType={contentAlignType}
                textAlignHandler={textAlignHandler}
              />
            </Grid>
            <Grid item xs={3} pr={1}>
              <CommonDocReference
                referenceHandler={referenceHandler}
                referenceType={referenceType}
              />
            </Grid>
            <Grid item xs={3} pr={1}>
              <SelectComponent
                label={"Select Typecase"}
                value={typeCase}
                values={Object.values(securityDocumentTextCase)}
                onChange={(e) => setTypeCase(e.target.value)}
                required={false}
                defaultPlaceholder={true}
              />
            </Grid>
            <Grid item xs={3}>
              <ButtonComponent
                startIcon={<LocalPrintshopIcon />}
                title={"Print PDF"}
                variant="contained"
                onClick={() => {
                  handlePrint();
                }}
                style={{ maxHeight: "40px", marginTop: "auto" }}
                disabled={permissionToPrint}
                loadingbtn={true}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};
