import CloseIcon from '@mui/icons-material/Close';
import { ListSubheader, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { userName } from "../../../utility/helpers/creditFileUtility";

const UserConfigerationBranchModal = (params) => {
    const groupNameMapping = params.groupNames;

    const closeCondition = () => {
        closeGlobalModal();
    };

    return (
        <>
            <div>
                <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                    Users represent in {params?.group?.name || ""} branch
                </Typography>
                <Stack direction={'column'}>
                    {Object.keys(params?.list ?? {})?.map((key, index) => (
                        <div>
                            <ListSubheader sx={{ backgroundColor: "lightgray" }}>{groupNameMapping[key]}</ListSubheader>
                            {params.list[key].map((item) => (
                                <Stack direction={'row'} py={2} key={`item-${userName(item.userId)}-${userName(item.userId)}`}>
                                    <Stack flex={1}>
                                        {userName(item.userId).trim() ? `${userName(item.userId)} (${item.employeeId})` : `N/A (${item.employeeId})`}
                                    </Stack>
                                    <Stack> {item.userId} </Stack>
                                </Stack>
                            ))}
                        </div>
                    ))}
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="Close"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={closeCondition}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </>
    );
}

export default UserConfigerationBranchModal;
