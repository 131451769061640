import { Downgraded } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import { openGlobalModal } from "../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../GlobalToast";
import { TableRowWrapper } from "../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableColumnsTextBoxWrapperNumberNew from "../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperNumberNew";
import TableColumnsTextWrapperAnyNew from "../../../Helpers/inputHelpers/TableColumnsTextWrapperAnyNew";

const AnalysisOfLoanRequirementTableBuildRow = ({ data, callBackOnDelete, disableStatus }) => {
  const dataKeys: any[] = Object.keys(data) || [];
  const [calculatedData, setCalculatedData]: any = React.useState({
    totalExistingEquity: 0,
    totalExistingBank: 0,
    totalProposedEquity: 0,
    totalProposedBank: 0,
    calculatedTotalCost: 0,
    existingEquityContribution: 0,
    existingBankContribution: 0,
    proposedEquityContribution: 0,
    proposedBankContribution: 0,
    calculatedTotalContribution: 0,
    totalProposedBankContribution: 0,
    totalProposedEquityContribution: 0,
  });

  const alertMessage: any = {
    status: "",
    title: "",
    message: "",
  };

  useMemo(() => {
    const dataObj = data.attach(Downgraded).get();
    const costDataObj = {
      totalExistingEquity: 0,
      totalExistingBank: 0,
      totalProposedEquity: 0,
      totalProposedBank: 0,
      calculatedTotalCost: 0,
      existingEquityContribution: 0,
      existingBankContribution: 0,
      proposedEquityContribution: 0,
      proposedBankContribution: 0,
      calculatedTotalContribution: 0,
      totalProposedBankContribution: 0,
      totalProposedEquityContribution: 0,
    };

    dataKeys?.map((key, index) => {
      const { existingEquality, existingBank, proposedEquality, proposedBank, totalCost } = dataObj[key]?.data;
      costDataObj.totalExistingEquity += parseFloat(existingEquality);
      costDataObj.totalExistingBank += parseFloat(existingBank);
      costDataObj.totalProposedEquity += parseFloat(proposedEquality);
      costDataObj.totalProposedBank += parseFloat(proposedBank);
      costDataObj.calculatedTotalCost =
        costDataObj.totalExistingEquity +
        costDataObj.totalExistingBank +
        costDataObj.totalProposedEquity +
        costDataObj.totalProposedBank;
    });

    if (costDataObj.calculatedTotalCost > 0) {
      costDataObj.existingEquityContribution = parseFloat(
        ((costDataObj.totalExistingEquity / costDataObj.calculatedTotalCost) * 100).toFixed(2)
      );
      costDataObj.existingBankContribution = parseFloat(
        ((costDataObj.totalExistingBank / costDataObj.calculatedTotalCost) * 100).toFixed(2)
      );
      costDataObj.proposedEquityContribution = parseFloat(
        ((costDataObj.totalProposedEquity / costDataObj.calculatedTotalCost) * 100).toFixed(2)
      );
      costDataObj.proposedBankContribution = parseFloat(
        ((costDataObj.totalProposedBank / costDataObj.calculatedTotalCost) * 100).toFixed(2)
      );
      costDataObj.calculatedTotalContribution = parseFloat(
        (
          costDataObj.existingEquityContribution +
          costDataObj.existingBankContribution +
          costDataObj.proposedEquityContribution +
          costDataObj.proposedBankContribution
        ).toFixed(2)
      );

      costDataObj.totalProposedBankContribution = parseFloat(
        (
          (costDataObj.totalProposedBank / (costDataObj.totalProposedEquity + costDataObj.totalProposedBank)) *
          100
        ).toFixed(2)
      );

      costDataObj.totalProposedEquityContribution = parseFloat(
        (
          (costDataObj.totalProposedEquity / (costDataObj.totalProposedEquity + costDataObj.totalProposedBank)) *
          100
        ).toFixed(2)
      );
    }
    setCalculatedData(costDataObj);
  }, [data]);

  const renderTotalRowConst = (existingEquality, existingBank, proposedEquality, proposedBank) => {
    let rowCost = 0;
    rowCost += parseFloat(existingEquality);
    rowCost += parseFloat(existingBank);
    rowCost += parseFloat(proposedEquality);
    rowCost += parseFloat(proposedBank);

    return rowCost;
  };

  const itemRowDeleteDispatcher = (key: any) => {
    if (key) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete the row item!.",
          callBackOnAction: () => callBackOnDelete(key),
        },
      });
    } else {
      alertMessage.status = "error";
      alertMessage.title = "Empty Item Key";
      alertMessage.message = "Item key is required to proceed the deletion";
      addToaster(alertMessage);
    }
  };

  const dataObj = data.attach(Downgraded).get();

  const onchangeHandler = (value, dataKey, dataIndex) => {
    dataObj[dataIndex].data[dataKey] = value;

    dataKeys?.map((key, index) => {
      const { existingEquality, existingBank, proposedEquality, proposedBank, totalCost } = dataObj[key]?.data;
      let singleRoeCostValue = renderTotalRowConst(existingEquality, existingBank, proposedEquality, proposedBank);
      dataObj[dataIndex].data["totalCost"] = singleRoeCostValue;
      dataObj[dataIndex].data["existingEqualityContribution"] = parseFloat(
        ((existingEquality / singleRoeCostValue) * 100).toFixed(2)
      );
      dataObj[dataIndex].data["existingBankContribution"] = parseFloat(
        ((existingBank / singleRoeCostValue) * 100).toFixed(2)
      );
      dataObj[dataIndex].data["proposedEqualityContribution"] = parseFloat(
        ((proposedEquality / singleRoeCostValue) * 100).toFixed(2)
      );
      dataObj[dataIndex].data["proposedBankContribution"] = parseFloat(
        ((proposedBank / singleRoeCostValue) * 100).toFixed(2)
      );
    });

    data?.set(dataObj);
  };

  return (
    <>
      {dataKeys?.length > 0 &&
        dataKeys?.map((key, index) => {
          const { description, existingEquality, existingBank, proposedEquality, proposedBank, totalCost } =
            data[key]?.data;

          return (
            <>
              <TableRowWrapper>
                <TableColumnsTextWrapperAnyNew
                  index={index + "description"}
                  dataKey={"description"}
                  dataIndex={key}
                  onchangeHandler={onchangeHandler}
                >
                  <>{description?.get()}</>
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsTextBoxWrapperNumberNew
                  key={index + "existingEquality"}
                  value={existingEquality}
                  index={index + "existingEquality"}
                  title={""}
                  dataKey={"existingEquality"}
                  dataIndex={key}
                  onchangeHandler={onchangeHandler}
                />
                <TableColumnsTextBoxWrapperNumberNew
                  key={index + "existingBank"}
                  value={existingBank}
                  index={index + "existingBank"}
                  title={""}
                  dataKey={"existingBank"}
                  dataIndex={key}
                  onchangeHandler={onchangeHandler}
                />
                <TableColumnsTextBoxWrapperNumberNew
                  key={index + "proposedEquality"}
                  value={proposedEquality}
                  index={index + "proposedEquality"}
                  title={""}
                  dataKey={"proposedEquality"}
                  dataIndex={key}
                  onchangeHandler={onchangeHandler}
                />
                <TableColumnsTextBoxWrapperNumberNew
                  key={index + "proposedBank"}
                  value={proposedBank}
                  index={index + "proposedBank"}
                  title={""}
                  dataKey={"proposedBank"}
                  dataIndex={key}
                  onchangeHandler={onchangeHandler}
                />
                <TableColumnsTextWrapperAnyNew
                  key={index + "totalCost"}
                  index={index + "totalCost"}
                  style={{ textAlign: "right" }}
                >
                  {formatCurrency(
                    renderTotalRowConst(
                      existingEquality?.get(),
                      existingBank?.get(),
                      proposedEquality?.get(),
                      proposedBank?.get()
                    )
                  )}
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsTextWrapperAnyNew
                  key={index + "actions"}
                  index={index + "actions"}
                >
                  <Box paddingX={4}>
                    <Tooltip title="Delete">
                      <IconButton aria-label="delete" onClick={() => itemRowDeleteDispatcher(key)} disabled={disableStatus}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableColumnsTextWrapperAnyNew>
              </TableRowWrapper>
            </>
          );
        })}
      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"total_label"}>
          <>Total</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalExistingEquity"} style={{ textAlign: "right" }}>
          {formatCurrency(calculatedData?.totalExistingEquity)}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalExistingBank"} style={{ textAlign: "right" }}>
          {formatCurrency(calculatedData?.totalExistingBank)}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalProposedEquity"} style={{ textAlign: "right" }}>
          {formatCurrency(calculatedData?.totalProposedEquity)}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_totalProposedBank"} style={{ textAlign: "right" }}>
          {formatCurrency(calculatedData?.totalProposedBank)}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_calculatedTotalCost"} style={{ textAlign: "right" }}>
          {formatCurrency(calculatedData?.calculatedTotalCost)}
        </TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"contribution_label"}>
          <>Contribution (%)</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"contribution_existingEquityContribution"} style={{ textAlign: "right" }}>
          {calculatedData?.existingEquityContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"contribution_existingBankContribution"} style={{ textAlign: "right" }}>
          {calculatedData?.existingBankContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"contribution_proposedEquityContribution"} style={{ textAlign: "right" }}>
          {calculatedData?.proposedEquityContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"contribution_proposedBankContribution"} style={{ textAlign: "right" }}>
          {calculatedData?.proposedBankContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"contribution_calculatedTotalContribution"}
          style={{ textAlign: "right" }}
        >
          {calculatedData?.calculatedTotalContribution}
        </TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"proposed_contribution_label"}>
          <>Proposed Contribution (%)</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"proposed_contribution_existingEquityContribution"}
          style={{ textAlign: "right" }}
        ></TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"proposed_contribution_existingBankContribution"}
          style={{ textAlign: "right" }}
        ></TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"proposed_contribution_proposedEquityContribution"}
          style={{ textAlign: "right" }}
        >
          {calculatedData?.totalProposedEquityContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"proposed_contribution_proposedBankContribution"}
          style={{ textAlign: "right" }}
        >
          {calculatedData.totalProposedBankContribution}
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew
          index={"proposed_contribution_calculatedTotalContribution"}
          style={{ textAlign: "right" }}
        ></TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
    </>
  );
};

export default AnalysisOfLoanRequirementTableBuildRow;
