import { Downgraded, useHookstate } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
    floatingWidgetState,
    productDetails,
    workflowMasterData
} from "../../../../configs/mainStore";
import FloatingWidget from "../../../../FloatingWidget";
import AddWorkflowStageModal from "../../../../layouts/Preferences/AddWorkflowStageModal";
import CatalogSelectionModal from "../../../../layouts/Preferences/CatalogSelectionModal";
import { getProductStageOrder, updateStageOrderConfig } from "../../../../services/configurationApis";
import { closeBtn } from "../../../../styles";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { Toast } from "../../GlobalToast";

const WorkflowStageOrderConfig = ({ payload }) => {
    const { wf_stages: workflowStages }: any = useHookstate(workflowMasterData);
    let filteredWFStages: any = [];
    const [configs, setConfigs]: any = React.useState({ catalog: null });
    const [stageOrder, setStageOrder]: any = React.useState([]);
    const [floatMode, setFloatMode] = React.useState(0);

    const _floatingWidgetState: any = useHookstate(floatingWidgetState);

    const stageMap = (workflowStages.get() || {}).reduce((acc: any, stage) => {
        const { name, description, id } = stage;
        const item = { id, name, description };
        acc[id] = item;
        return acc;
    }, {});

    const productMasterData: any = useHookstate(productDetails);
    const { definitionMap: _catalogMap }: any = productMasterData.attach(Downgraded).get() || {};

    useEffect(() => {
        if (configs.catalog) {
            loadOrderedStages();
        } else if (payload.product.id) {
            setConfigs({ catalog: payload.product.id.replaceAll(".", "-") });
        }
    }, [configs.catalog]);

    const loadOrderedStages = async () => {
        let response = await getProductStageOrder(); // using cacheStorage for caching

        if (!(response instanceof Object)) {
            response = JSON.parse(response);
        }

        const catalogBasedData = response?.[configs.catalog] || [];

        const list = catalogBasedData.reduce((acc, item) => {
            if (stageMap[item]) {
                acc.push(stageMap[item]);
            }
            return acc;
        }, []);

        setStageOrder(list);
    };

    const configStages = async () => {
        const payload = {};
        const key = configs.catalog.toString();
        if (stageOrder.length === 0) {
            Toast.error("Please order the stages and check again");
            return;
        }
        payload[key] = stageOrder.map((stage) => stage.id);

        const res = await updateStageOrderConfig(payload);

        if (res?.status === 1) Toast.success("Stage order configured successfully.");
        else Toast.error("Stage Update Failed");
    };

    const updateStageOrder = async () => {
        const payload = {};
        const key = configs.scheme.get().toString();
        payload[key] = stageOrder;
        const res = await updateStageOrderConfig(payload);

        if (res?.status === 1) {
            Toast.success(res?.data?.message);
        } else {
            Toast.error("Stage Update Failed");
        }
    };

    function handleOnDragEnd(result) {
        const { source: { index: sourceIndex = null } = {}, destination: { index: destinationIndex = null } = {} } = result;
        if (destinationIndex === null || sourceIndex === null) return;

        const items: any = stageOrder;

        const [movedItem] = items.splice(sourceIndex, 1);
        items.splice(destinationIndex, 0, movedItem);

        setStageOrder(items);
    }

    const closeCondition = (item) => {
        const updatedStages = stageOrder.filter((p) => p != item);
        setStageOrder(updatedStages);
    };

    const proceedClone = async (item) => {
        let response = await getProductStageOrder(); // using cacheStorage for caching

        if (!(response instanceof Object)) {
            response = JSON.parse(response);
        }

        const catalogBasedData = response?.[item.id] || [];

        const list = catalogBasedData.reduce((acc, item) => {
            if (stageMap[item]) {
                acc.push(stageMap[item]);
            }
            return acc;
        }, []);

        setStageOrder(list);

        const payload = {};
        const key = configs.catalog.toString();
        if (catalogBasedData.length === 0) {
            Toast.error("Please order the stages and check again");
            return;
        }
        payload[key] = catalogBasedData;

        const res = await updateStageOrderConfig(payload);

        if (res?.status === 1) Toast.success(`Stage order clone configured from ${item.name} successfully.`);
        else Toast.error("Stage Update Failed");
    };

    const copyStagesFrom = () => {
        setFloatMode(1);
        _floatingWidgetState.set({
            ..._floatingWidgetState.get(),
            show: true,
            mode: "copystages",
            payload: stageOrder,
        });
    }

    const addNewStages = () => {
        setFloatMode(0);
        _floatingWidgetState.set({
            ..._floatingWidgetState.get(),
            show: true,
            mode: "addnewstage",
            payload: stageOrder,
        });
    }

    const catalogs = Object.keys(_catalogMap?.catalogDefinitionMap || {}).reduce((acc: any, key) => {
        const catalog: any = _catalogMap?.catalogDefinitionMap?.[key] || "N/A";
        acc.push({ id: key, name: catalog });
        return acc;
    }, []);

    return <Stack>
        <FloatingWidget title={`${floatMode === 0 ? 'Add Workflow Stages' : 'Clone Stage Order From'} (${stageOrder.length})`} width={"400px"}>
            {floatMode === 0 && <AddWorkflowStageModal action={(stage) => {
                setStageOrder([...stageOrder, stageMap[stage]]);
            }} actionRemove={(removeStage) => {
                const filteredList = stageOrder.filter((stage) => stage.id != removeStage);
                setStageOrder(filteredList);
            }} currentStages={stageOrder.map(e => e.id)} />}
            {floatMode === 1 && <CatalogSelectionModal action={(catalog) => {
                proceedClone(catalog);
            }} />}
        </FloatingWidget>

        <Stack style={{ position: "sticky", top: "1px", zIndex: 100, background: "white" }}>
            <Stack direction={"row"} pt={2}>
                <Stack direction={"row"} flex={1}>
                    <Stack direction="column" alignItems="flex-start" flex={1}>
                        <Typography color={"gray"}> Configure Workflow Stage Order </Typography>
                        <Typography variant="caption" color={"gray"}> Rearranging the stage order to {catalogs.find(e => e.id === configs.catalog)?.name || ""}({configs.catalog}) </Typography>
                    </Stack>
                    <Stack flex={1} mr={2} direction={"row-reverse"} display={configs.catalog ? 'flex' : 'none'}>
                        <ButtonComponent title={"Add New Stage"} onClick={addNewStages}></ButtonComponent>
                        <ButtonComponent title={"Copy Order From"} onClick={copyStagesFrom}></ButtonComponent>
                    </Stack>
                    {!payload && <CloseIcon
                        onClick={() => {
                            closeGlobalModal();
                        }}
                        style={{
                            fontSize: 24,
                            cursor: "pointer"
                        }}
                    />}
                </Stack>
            </Stack>
        </Stack>

        <Stack direction={"column"} flex={1} border={1} borderColor={"lightGray"} borderRadius={1} mt={1}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                    {(provided) => (
                        <Stack
                            p={1}
                            className="characters"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            height={"calc(100vh - 400px)"}
                            style={{ overflow: "auto" }}
                        >
                            <Stack display={!stageOrder.length ? "flex" : "none"} alignItems={"center"} justifyContent={"center"} height={"calc(100vh - 400px)"} >
                                <Typography variant="subtitle1">No Stage Order found to {catalogs.find(e => e.id === configs.catalog)?.name || ""} </Typography>
                            </Stack>
                            <Stack display={stageOrder.length ? "flex" : "none"} >
                                {stageOrder.map((obj, index) => {
                                    return (
                                        <Draggable
                                            key={obj.id}
                                            draggableId={obj.id.toString()}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Stack
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    borderBottom={1}
                                                    mb={1}
                                                    backgroundColor={"white"}
                                                    borderColor={"lightGray"}
                                                    py={1}
                                                >
                                                    <Stack direction={"row"} spacing={1}>
                                                        <Stack alignItems={"center"} justifyContent={"center"} style={{ width: "40px", height: "30px", borderRadius: "15px", background: "#f2f2f2" }}>
                                                            <Typography>{index + 1}</Typography>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            width={"100%"}
                                                            justifyContent={
                                                                "space-between"
                                                            }
                                                        >
                                                            <Stack direction={"column"}>
                                                                <Typography variant="subtitle1">{obj.name}</Typography>
                                                                <Typography variant="caption" color={"gray"}>{obj.description}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack
                                                            alignContent={"baseline"}
                                                            justifyContent={"center"}
                                                            direction={"row"}
                                                        >
                                                            <ButtonComponent
                                                                startIcon={<CloseIcon />}
                                                                variant="contained"
                                                                onClick={() =>
                                                                    closeCondition(obj)
                                                                }
                                                                style={closeBtn}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </Stack>
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={2} mt={4}>
            <Stack flex={1} direction={"row"} spacing={2}>
                <Stack flex={1}>
                    <AutoCompleteProxyComponent
                        options={catalogs}
                        disabled={!!payload.product.id}
                        fullWidth={true}
                        onChange={(e) => {
                            setConfigs({ ...configs, catalog: e.id });
                        }}
                        label={"Scheme " + (payload?.product?.id?.replaceAll(".", "-") || "")}
                        value={configs.catalog || ''}
                        cls="select_basic"
                        required
                    />
                </Stack>
                <Stack flex={1}>&nbsp;</Stack>
            </Stack>
            <Stack mt={1} width={"200px"}>
                {filteredWFStages.length > 0 ? (
                    <ButtonComponent
                        onClick={() => {
                            updateStageOrder();
                        }}
                        variant="contained"
                        title={"Update"}
                    >

                    </ButtonComponent>
                ) : (
                    <ButtonComponent
                        onClick={() => {
                            configStages();
                        }}
                        variant="contained"
                        title={"Update Order"}
                    >
                    </ButtonComponent>
                )}
            </Stack>
        </Stack>
    </Stack >;
};
export default WorkflowStageOrderConfig