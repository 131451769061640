import { Grid, Stack } from "@mui/material"
import React from "react"

const ScoreCardRow = ({ title, subTxt, score }) => {
  return (
    <Grid container p={1} className="data-box">
      <Grid container item p={1}>
        <Grid item xs={5}>
          <Grid item>
            <Stack>
              <div className={`basic-font basic-font-color`}>{title}</div>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid item>
            <Stack>
              <div className={`basic-font basic-font-color`}>{subTxt}</div>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid item>
            <Stack>
              <div className={`basic-font align-right`}>{score}</div>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScoreCardRow
