import { State, useHookstate } from "@hookstate/core";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { MASTER_DATA_KEYS as MD_KEYS } from '../../../../../../../../../../configs/constants/masterDataKeys';
import { formatCurrency, formatCurrencyWithThousandSeperators } from "../../../../../../../../../../services/currencyFormater";
import { Formatter } from "../../../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../../../utility/MasterDataProvider";
import { CircularProgressComponent } from "../../../../../../../../ProgressComponent/ProgressComponent";
import { inspectionBusinessLiabilityExpensesCategories, inspectionBusinessLiabilityExpensesType } from "../../../../../../CreditInspection/Helpers/utility";
import TableBodyWrapper from "../../../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { BalanceSheetTableTextBox, TableColumnsTextWrapper } from "../../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper";
import { CustomTableHeaderWrapperForBalanceSheet } from "../../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import { TableRowWrapper } from "../../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableWrapper from "../../../../Helpers/TableContainer/TableWrapper/TableWrapper";
import { GernaralizedComprehensiveTitleArray } from "../../../../Stores/MicroAppraisalDefaultData";
import { AssetOutPutDataObject, DataOfChildren, DataOfYears } from "../../Helpers/SetStateFromBusinessData/BalanceSheet";

const BalanceSheetBuilder = ({ formData, headerTitles, calCulateData }: { formData: State<Record<string, AssetOutPutDataObject>>, headerTitles: GernaralizedComprehensiveTitleArray[], calCulateData: { assetData: DataOfYears; liabilityData: DataOfYears, assetDataPartedCal: Record<number, DataOfYears> } }) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const titleMap = { assetData: "Total Assets", liabilityData: "Total Liabilities ", equity: "Total Equity" };
    const subTitleMap = { 1: 'Current Asset', 2: 'Fixed Asset' };
    const [currentNonCurrentList, setCurrentNonCurrentList] = useState({});
    const isLoading = useHookstate(true);

    useEffect(() => {
        balanceSheetViewPreBuilder()
    }, [])

    const selectedTypeData = {
        assetData: { masterDataMap: MD_KEYS.ASSETS_CATEGORY, subMasterDataMap: MD_KEYS.ASSET_TYPE },
        liabilityData: { masterDataMap: inspectionBusinessLiabilityExpensesCategories },
    }

    const getMasterDataDescription = (key: string, dataType: string): string => {
        const getDesc = masterDataStore.getMasterDataDescription;

        let [category, type]: string[] = key.split(".");

        if (dataType === 'assetData') {
            category = masterDataStore.getMasterDataDescription(selectedTypeData[dataType].masterDataMap, category);
            type = getDesc(selectedTypeData[dataType].subMasterDataMap, type);
        }

        if (dataType === 'liabilityData') {
            category = inspectionBusinessLiabilityExpensesCategories.find(({ id }) => category === id.toString())?.name || "";
            type = inspectionBusinessLiabilityExpensesType.find(({ id }) => type === id.toString())?.name || "";
        }
        return type ? `${category} - ${type}` : `${category}`
    }

    const onChangeHanlder = (value: string, state: State<any>, dataKey: number | string) => {
        let nextYear = Number(moment().year()) + 1;

        if (dataKey === "variableRate") {
            value = Formatter.PRECENTAGE_NEGATIVE_VERSIONS.test(value.toString()) ? value : state?.[dataKey].get() || 0;
        }

        let dataObject = { ...state.value, [dataKey]: value };

        if ((dataKey === "variableRate" && value != state?.[dataKey].get()) || dataKey == nextYear) {
            let followingYear = Number(moment().year()) + 2;

            let valueOfFollowingYear = 0;
            let variableRateValue: number = Number(dataObject?.["variableRate"] || 0)
            let nextYearValue: number = Number(dataObject?.[nextYear] || 0);

            valueOfFollowingYear = eval(`${nextYearValue + (nextYearValue * variableRateValue) / 100}`);

            dataObject[followingYear] = valueOfFollowingYear;
        }

        state.set(prev => ({ ...prev, ...dataObject }))
    }

    const balanceSheetViewPreBuilder = () => {
        try {
            const { assetData: { data } } = formData;
            setCurrentNonCurrentList(Object.entries(data).reduce((accData: Record<number, State<Record<string, AssetOutPutDataObject>>>, [key, curr], index) => {
                const currType = key.split(".")[2];
                return { ...accData, [currType]: { ...(accData?.[currType] || {}), [key]: curr } }
            }, {}))

        } catch (error) {
        } finally {
            isLoading.set(false)
        }
    }

    const BuildLiabilityData = () => {
        const props = { currItem: formData.liabilityData, headerTitles, getMasterDataDescription, onChangeHanlder, stringKey: "liabilityData", titleMap, calCulateData };
        return <BuildTableRows {...props} />
    }

    const BuildAssetData: FC<{ currentNonCurrentList: Record<number, State<AssetOutPutDataObject>> }> = ({ currentNonCurrentList }: { currentNonCurrentList: Record<number, State<AssetOutPutDataObject>> }) => {

        if (!Object.keys(currentNonCurrentList).length) return <></>;

        return <>
            {
                Object.entries(currentNonCurrentList).map(([currntNonCurrntKey, data]: [string, State<AssetOutPutDataObject>], indexId) => {
                    return <>
                        <TableRow key={"TableRow-asset" + currntNonCurrntKey + "TableRow"} sx={{ backgroundColor: "rgb(17 114 206 / 10%)" }}>
                            <TableCell sx={{ border: "none" }}>
                                <Typography color={"gray"} px={3} children={subTitleMap[currntNonCurrntKey]} />
                            </TableCell>
                            {headerTitles.map(() => <TableCell sx={{ border: "none" }}></TableCell>)}
                        </TableRow>
                        <BuildTableRows key={"TableRow-asset - 01" + currntNonCurrntKey + "BuildTableRows"} showCalculations={false} currItem={data} headerTitles={headerTitles} getMasterDataDescription={getMasterDataDescription} onChangeHanlder={onChangeHanlder} stringKey={"assetData"} titleMap={titleMap} calCulateData={calCulateData} />
                        <BuildTotalRow key={"TableRow-asset" + currntNonCurrntKey + "main-BuildTableRows"} dataId={'id'} headerTitles={headerTitles} descTitle={`Total ${subTitleMap[currntNonCurrntKey]}`} value={calCulateData?.assetDataPartedCal?.[currntNonCurrntKey] || {}} />
                    </>
                })
            }
            <BuildTotalRow dataId={'id'} headerTitles={headerTitles} descTitle={titleMap.assetData} value={calCulateData.assetData} />

        </>
    }

    return (
        <Box>
            <TableWrapper>
                <CustomTableHeaderWrapperForBalanceSheet
                    firstColTitle={"Description"}
                    dataObject={headerTitles} />
                <TableBodyWrapper>
                    {isLoading.get() ? <CircularProgressComponent size={30} /> : <>
                        <BuildAssetData currentNonCurrentList={currentNonCurrentList} />
                        <BuildLiabilityData />
                        <BuildTotalRow dataId={`equity-BuildTotalRow`} headerTitles={headerTitles} descTitle={titleMap.equity} value={calCulateData?.["equity"] || 0} /></>}
                </TableBodyWrapper >
            </TableWrapper>
        </Box>
    )
}

export default BalanceSheetBuilder

const BuildTotalRow = ({ dataId: key, headerTitles, descTitle, value }: { dataId: string; headerTitles: GernaralizedComprehensiveTitleArray[]; descTitle: string; value: any }) => {

    return <TableRowWrapper key={`${key}-TableRowWrapper-total`}>
        <TableColumnsTextWrapper key={`${key}-TableColumnsTextWrapper-total-description-cell`} index={`${key}-TableColumnsTextWrapper-total-description-cell-index`} children={`${descTitle}`} />
        {
            headerTitles.map(({ title, dataKey }) => {
                return <TableColumnsTextWrapper key={`${key}-${dataKey}-${title}-TableColumnsTextWrapper-total-cell`} index={`${key}-${dataKey}-TableColumnsTextWrapper-total-cell-index`} children={formatCurrency(value?.[dataKey] || 0)} />
            })
        }
    </TableRowWrapper>
}

interface BuildTotalRowProp {
    currItem: State<AssetOutPutDataObject>;
    headerTitles: GernaralizedComprehensiveTitleArray[];
    getMasterDataDescription: (key: string, dataType: string) => string;
    onChangeHanlder: (value: string, state: State<any>, dataKey: number | string) => void;
    stringKey: string;
    titleMap: Record<string, string>;
    calCulateData: { assetData: DataOfYears; liabilityData: DataOfYears }
    showCalculations?: boolean
}

const BuildTableRows: FC<BuildTotalRowProp> = ({ currItem, headerTitles, getMasterDataDescription, onChangeHanlder, stringKey: key, titleMap, calCulateData, showCalculations = true }: BuildTotalRowProp) => {

    return <>
        {
            Object.entries(currItem?.data ? currItem?.data : currItem).map(([alphKey, alphaCurrItem]: [string, State<DataOfChildren>]) => {
                return <TableRowWrapper key={`${key}-${alphKey}-TableRowWrapper`}>
                    <TableColumnsTextWrapper key={`${key}-${alphKey}-TableColumnsTextWrapper`} index={`${key}-${alphKey}-TableColumnsTextWrapper-index`} children={getMasterDataDescription(alphKey, key)} />
                    {
                        headerTitles.map(({ title, dataKey }) => {

                            if (dataKey === Number(moment().year()) + 2) {
                                return <TableColumnsTextWrapper key={`${key}-${alphKey}-TableColumnsTextWrapper`} index={`${key}-${alphKey}-TableColumnsTextWrapper-index`} children={formatCurrencyWithThousandSeperators(alphaCurrItem.data?.[dataKey].value || 0)} />
                            }
                            return <BalanceSheetTableTextBox isPercentage={dataKey === "variableRate"} onChange={(value) => onChangeHanlder(value, alphaCurrItem.data, dataKey)} value={alphaCurrItem.data?.[dataKey]} index={`${key}-${alphKey}-${dataKey}-TableColumnsTextBoxWrapper`} title={""} />
                        })
                    }
                </TableRowWrapper>
            })
        }
        {showCalculations ? <BuildTotalRow dataId={`${key}-BuildTotalRow-${currItem?.dataId?.get() || `${key}`}`} headerTitles={headerTitles} descTitle={titleMap?.[key]} value={calCulateData?.[key]} /> : <></>
        }    </>
}
