import { Grid } from "@mui/material";
import { formatCurrency } from "../../../../../../services/currencyFormater";

function Header({ branch, payload, typeCaseClass }) {
  const { loanAmount, recoveryAccount, scheme = "N/A" } = payload || {};

  return (
    <Grid container columns={24} xs={24}>
      <Grid item xs={6} mb={0.25} className="signature-verification-header-label">
        <span>Branch</span>
        <span>:</span>
      </Grid>
      <Grid item xs={18} pl={1} className={`security-doc-type-variable ${typeCaseClass}`}>
        {branch}
      </Grid>
      <Grid item xs={6} mb={0.25} className="signature-verification-header-label">
        <span>Loan Scheme</span>
        <span>:</span>
      </Grid>
      <Grid item xs={18} pl={1} className={`security-doc-type-variable ${typeCaseClass}`}>
        {scheme}
      </Grid>
      <Grid item xs={6} mb={0.25} className="signature-verification-header-label">
        <span>Loan Amount</span>
        <span>:</span>
      </Grid>
      <Grid item xs={18} pl={1} className={`security-doc-type-variable ${typeCaseClass}`}>
        Rs. {formatCurrency(loanAmount, 2)}
      </Grid>
      <Grid item xs={6} mb={0.25} className="signature-verification-header-label">
        <span>Savings A/C No.</span>
        <span>:</span>
      </Grid>
      <Grid item xs={18} pl={1} className={`security-doc-type-variable ${typeCaseClass}`}>
        {recoveryAccount}
      </Grid>
    </Grid>
  );
}

export default Header;
