import { useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { toast } from "../../../../../GlobalToast";
import VerticalTabComponent from "../../../../../TabComponent/VerticalTabComponent";
import { InspectionProps } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import MultipleObjectArrayHandler from "../../Helpers/MultipleObjectArrayHandler";
import { filterSubKeyFromArray } from "../../Helpers/inspectionTabOrderRestructuredFn";
import { currentCollaterlTabId } from "./Helpers/collateralStores";
import { collateralTitle } from "./Helpers/configs";

export default function CollateralViewController({ data, collateralTabRendering, collaterlComponentMapping, applicationId, onSubmit, otherParams }): JSX.Element {
  const currentId = useState<string>(currentCollaterlTabId);
  const { formData } = data;
  const inspetionId = useState<any>(inspetionIdState);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const isLoading = useState<boolean>(true);
  const [correntFormData, setCorrentFormData] = React.useState({});

  useEffect(() => {
    getExistingInspectionForm();
  }, []);


  const getExistingInspectionForm = async () => {
    isLoading.set(true);

    try {
      const { response }: { data: Object, status: number } | any = await getFilledPendingInpectionFormData(applicationId);

      let { ID: inspectionId = null, INSPECTION_DATA: inspectionData = {} } = response;

      try {
        inspectionData = JSON.parse(inspectionData);
      } catch (error) { }

      let { collateral: data = {} } = inspectionData;

      inspectionFormState.collateral.set(data);
      inspetionId.set(inspectionId);

      if (Object.values(data).length > 0) {
        setCorrentFormData(data);
      }


    } catch (error) {
      toast({
        status:
          "error", message: "Something went wrong while fetching data...!"
      })
    } finally {
      isLoading.set(false);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    currentId.set(newValue.toString());
  };

  const response = React.useMemo(() => {
    const res = filterSubKeyFromArray(formData?.securityData, collateralTabRendering);

    const props = {
      data: res.securityObject[currentId.get()],
      dataItemArray: correntFormData[currentId.get()] ?? [],
      currentId: currentId,
      titleMapping: collateralTitle,
      componentMapping: collaterlComponentMapping,
      applicationId,
      onSubmit,
      otherParams: { ...otherParams, isLoading: isLoading.get() },
    };
    return {
      ...res,
      props,
    };
  }, [currentId.get(), formData?.securityData, isLoading.get()]);

  collateralTabRendering = response.tabArray;

  return (
    <Stack direction={"row"} width={"100%"} sx={{ flexGrow: 1, bgcolor: "background.paper", maxHeight: "calc(100% - 150px)" }} flex={5}>
      <Stack flex={1} sx={{ maxHeight: "calc(100vh - 150px)" }}>
        <VerticalTabComponent
          tabOptions={collateralTabRendering}
          onChange={handleChange}
          value={currentId.get()}
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          labelStyle={{
            textTransform: "none",
            fontSize: "16px",
            width: "320px",
            fontFamily: "Poppins, Medium",
            alignItems: "flex-start",
          }}
        />
      </Stack>
      <Stack flex={4}>
        <ErrorBoundary>
          <MultipleObjectArrayHandler {...response.props} />
        </ErrorBoundary>
      </Stack>
    </Stack>
  );
}
