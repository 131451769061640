import moment from "moment";
import { generateUniqueId } from "../../../../../../../../../../utility/util";

export type AssetCurrentItem = { assertType: string; assetCategory: string };
export type DataOfYears = Record<number, number>
export interface DataOfChildren { data: DataOfYears, dataId: string };
export type CreateObjectOutPut = Record<string, DataOfChildren> | {};
export interface AssetOutPutDataObject { total: DataOfYears; data: CreateObjectOutPut, dataId: string };

export const balanceSheetSetStateFromTheBusinessData = ({ busineeData }) => {
    const { totaleBusinessAssertOtherDetails, businessLiabilityExpenses } = busineeData;

    let assetDataObject: CreateObjectOutPut | AssetOutPutDataObject = createAssetDataObject({ data: totaleBusinessAssertOtherDetails });
    let calOfTotals = calTotals(assetDataObject);
    assetDataObject = { total: calOfTotals, data: assetDataObject, dataId: generateUniqueId() };

    let liabilityDataObject: CreateObjectOutPut | AssetOutPutDataObject = createLiabilityDataObject({ data: businessLiabilityExpenses });
    calOfTotals = calTotals(liabilityDataObject);
    liabilityDataObject = { total: calOfTotals, data: liabilityDataObject, dataId: generateUniqueId() };

    return { assetData: assetDataObject, liabilityData: liabilityDataObject }
}

export const createAssetDataObject = ({ data }): CreateObjectOutPut => {
    return Object.entries(data).reduce((descObject: CreateObjectOutPut, [currentItemKey, currentItemValue]: any) => {
        currentItemValue.forEach((item) => {
            let { assertType = "", assetCategory = "", assertTimeFrame = "" }: { assertType: string; assetCategory: string, assertTimeFrame: string } = item;

            if ((assertType === "" || assetCategory === "")) return;

            let key: string = `${assetCategory}.${assertType}.${assertTimeFrame}`;
            let prev: DataOfChildren = descObject?.[key] || {};

            descObject = buildObject(descObject, key, currentItemKey, item?.marketValue, prev)
        });

        return descObject;
    }, []);
}

const createLiabilityDataObject = ({ data }): CreateObjectOutPut => {
    return Object.entries(data).reduce((descObject: CreateObjectOutPut, [currentItemKey, currentItemValue]: any) => {
        currentItemValue.forEach((item) => {
            let { type, liabilityType }: { type: 1 | 2; liabilityType: string } = item;

            if (!liabilityType) return;

            let key: string = `${type}.${liabilityType}`;
            let prev: DataOfChildren = descObject?.[key] || {};

            descObject = buildObject(descObject, key, currentItemKey, item?.annualLiabilityExpense, prev)
        });

        return descObject;
    }, []);
}

const buildObject = (object: CreateObjectOutPut, itemkey: string, yearKey: number, value: number, previousData?: DataOfChildren): CreateObjectOutPut => {
    let prevData: number = Number(previousData?.data?.[yearKey] || 0);
    let nextYear = moment().year() + 1;
    let afterNextYearData: number = Boolean((nextYear == yearKey)) ? prevData + Number(value || 0) : 0;

    return {
        ...object,
        [itemkey]: {
            data: { ...previousData?.["data"] || {}, [yearKey]: prevData + Number(value || 0), [nextYear + 1]: afterNextYearData },
        }
    }
}

export const calTotals = (data: CreateObjectOutPut) => {
    return Object.entries(data).reduce((object: Record<string, DataOfYears>, [key, currData]: [string | number, any]) => {

        if (typeof currData && Object.keys(currData).length) return { ...object, ...calTotals(currData) };

        return { ...object, [key]: Number(object[key] || 0) + Number(currData) }
    }, {})
}