import { Downgraded } from '@hookstate/core';
import {
  validatelength,
  validatePastDateWithToday,
  validatePositiveNumber,
  validatePositiveNumberWithDecimalPlaces,
  validateTextWithCharactersAndSpaces,
  validateTextWithCharactersSpacesAndNumbers
} from '../../validation-rules/validationRules';

export const bankDetailsValidationOnNext = (changes) => {

  try {
    changes = changes.attach(Downgraded).get()
  } catch (error) { }

  const bankDetailsErrors: any = {};
  for (let i = 0; i < changes?.length; i++) {
    if (!changes[i].removedItem) {
      const bankNameVal = changes[i]?.bankName
        ? bankNameValidation(`Bank Name`, changes[i]?.bankName)
        : null;

      const branchVal = changes[i]?.branch ? branchValidation(`Branch`, changes[i]?.branch) : null;

      const accountNameval = changes[i]?.accountName
        ? accountNameValidation('Account Name', changes[i]?.accountName)
        : null;

      const accountNoVal = changes[i]?.accountNo
        ? accountNoValidation(`Account Number`, changes[i]?.accountNo)
        : null;

      const maximumBalanceVal = changes[i]?.maximumBalance
        ? maximumBalanceValidation(`Maximum Balance`, changes[i]?.maximumBalance, 2)
        : null;

      const asAtDateVal = changes[i]?.asAtDate
        ? asAtDateValidation(`As At Date`, changes[i]?.asAtDate)
        : null;

      const averageDepositLastSixMonthsVal = changes[i]?.averageDepositLastSixMonths
        ? averageDepositLastSixMonthsValidation(
          `Average Deposit Last Six Months`,
          changes[i]?.averageDepositLastSixMonths
        )
        : null;

      const averageWithdrawalLastSixMonthsVal = changes[i]?.averageWithdrawalLastSixMonths
        ? averageWithdrawalLastSixMonthsValidation(
          `Average Withdrawal Last Six Months`,
          changes[i]?.averageWithdrawalLastSixMonths
        )
        : null;

      const accountOpenDateVal = changes[i]?.accountOpenDate
        ? accountOpenDateValidation(
          `Account Open Date`,
          changes[i]?.accountOpenDate
        )
        : null;

      if (
        bankNameVal ||
        branchVal ||
        asAtDateVal ||
        accountNameval ||
        accountNoVal ||
        maximumBalanceVal ||
        averageDepositLastSixMonthsVal ||
        averageWithdrawalLastSixMonthsVal ||
        accountOpenDateVal
      ) {
        bankDetailsErrors[i] = {
          bankName: bankNameVal,
          branch: branchVal,
          accountName: accountNameval,
          accountNo: accountNoVal,
          maximumBalance: maximumBalanceVal,
          averageDepositLastSixMonths: averageDepositLastSixMonthsVal,
          averageWithdrawalLastSixMonths: averageWithdrawalLastSixMonthsVal,
          asAtDate: asAtDateVal,
          accountOpenDate: accountOpenDateVal
        };
      }
    }
  }

  return Object.keys(bankDetailsErrors).length > 0 ? bankDetailsErrors : 1;
};

export const bankNameValidation = (fieldName, value) => {
  const result: any[] = [];
  // const checkBankName = validateAphabeticalExtended(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  // if (checkBankName) result.push(checkBankName);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const branchValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkExtentRegNo = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkExtentRegNo) result.push(checkExtentRegNo);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const accountNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkExtentRegNo = validateTextWithCharactersAndSpaces(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkExtentRegNo) result.push(checkExtentRegNo);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const accountNoValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

type ResultType = { status: number, message: string }

export const accountOpenDateValidation = (fieldName, value) => {
  const checkDate: ResultType | null = validatePastDateWithToday(fieldName, value);
  return checkDate ? [checkDate] : null;
};

export const maximumBalanceValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const asAtDateValidation = (fieldName, value) => {
  const checkDate = validatePastDateWithToday(fieldName, value);
  return checkDate ? [checkDate] : null;
};

export const asAtDateBalanceValidation = (fieldName, value, decimals) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumberWithDecimalPlaces(fieldName, value, decimals);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const averageDepositLastSixMonthsValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
export const averageWithdrawalLastSixMonthsValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkGrossValue = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkGrossValue) result.push(checkGrossValue);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
