export const lifeInsuranceValidationCheck = (
  isRecurse = false,
  liObject = {}
) => {

  if (isRecurse) {
    if (Object.keys(liObject).length > 0) {
      if (liObject["liSecuredValue"]) {
        let liSecuredValue = liObject["liSecuredValue"];
        liSecuredValue = parseFloat(liSecuredValue);
        if (
          liSecuredValue <= liObject["liFaceValue"] &&
          liSecuredValue <= liObject["liMarketValue"]
        ) {
          return {
            isError: false,
          };
        } else
          return {
            isError: true,
            message:
              "Face Value or Market Value cannot be less than of Secured Value...!",
          };
      } else
        return {
          isError: false,
        };
    } else
      return {
        isError: false,
      };
  }
};

export const ValidateWithSecuredValue = (value, parentVal) => {
  const securedValue = parseFloat(parentVal);
  value = parseFloat(value);
  if (!securedValue) return false;
  if (!value) return true;
  if (securedValue <= value) return false;
  return true;
};
