import { useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { decisionRuleTypes, masterDataDetails, postalCodeDetails } from "../../configs/mainStore";
import { loadMasterData } from "../../services/apiCalls";
import MasterDataProvider from "../MasterDataProvider";

export const useLoadMasterData: any = (appSettings) => {
  const [loadingMasterData, setLoadingMasterData] = React.useState(true);
  const postalCodeState = useState(postalCodeDetails);
  const decisionRules = useState(decisionRuleTypes);
  const masterData = useState(masterDataDetails);

  const loadData = async () => {
    const allMasterData = await loadMasterData(appSettings?.language.get());
    const masterDataResponse = allMasterData;

    if (masterDataResponse?.status === 1) {
      let postalCode = masterDataResponse?.data["POSTAL_CODE"]?.data;
      let structuredPostalCodes = postalCodeHandler(postalCode);
      postalCodeState.set(structuredPostalCodes);

      decisionRules.set(masterDataResponse?.data.DECISION_RULE_TYPE);
      // const dependencyKeys = [
      //   MASTER_DATA_KEYS?.CITY,
      //   MASTER_DATA_KEYS?.DISTRICT,
      //   MASTER_DATA_KEYS?.BUSINESS_SUB_SECTOR,
      // ];

      // let processDepMD = true;

      // dependencyKeys.forEach((key) => {
      //   if (!Object.keys(masterDataResponse?.data).includes(key)) {
      //     processDepMD = false;
      //   }
      // });

      //New master data object
      const newMasterData: any = {};

      /**
       * PREPARE NON DEPENDENCY MASTER DATA LIST
       *************************************************/

      const MASTER_DATA_SET = Object.keys(masterDataResponse?.data || []);

      MASTER_DATA_SET.forEach((element) => {
        if (masterDataResponse?.data[element].data != null && masterDataResponse?.data[element].data.length > 0) {
          const newData = masterDataResponse?.data[element]?.data?.map((selectedItem: any) => {
            const data = {};

            data["id"] = selectedItem?.CODE;
            data["name"] = selectedItem?.DESC;

            if (selectedItem?.DRD) {
              data["drd"] = selectedItem?.DRD;
            }

            if (selectedItem?.PCODE) {
              data["pcode"] = selectedItem?.PCODE;
            }
            if (selectedItem?.DSA_CODE) {
              data["dsaCode"] = selectedItem?.DSA_CODE;
            }
            return data;
          });

          newData.sort((a, b) => {
            if ((!a.drd && !b.drd) || a.drd === b.drd) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }
          });

          newMasterData[element] = newData;
        }
      });

      /**
       * PREPARING DEPENDENCY MASTER DATA
       ***********************************/

      // if (processDepMD) {
      //   newMasterData[MASTER_DATA_KEYS?.DISTRICT] = prepare_Dependency_Md(districtData);
      //   newMasterData[MASTER_DATA_KEYS.CITY] = prepare_Dependency_Md(cityData);
      //   newMasterData[MASTER_DATA_KEYS.NEAREST_BRANCH] = prepare_Dependency_Md(nearestBranch);
      //   newMasterData[MASTER_DATA_KEYS.BUSINESS_SUB_SECTOR] = prepare_Dependency_Md(businessSubSector);
      // }

      /**
       * SETTING MASTER DATA TO THE GLOBAL STATE
       *****************************************/

      masterData.set(newMasterData);
    } else {
      masterData.set({});
      return false;
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      (async function () {
        if (appSettings?.language.get()) {
          await loadData();
          MasterDataProvider.setMasterData(masterDataDetails);
          setLoadingMasterData(false);
        }
      })();
    }

    return () => {
      isMounted = false;
    };
  }, [appSettings?.language?.get()]);

  const postalCodeHandler = (data) => {
    let obj = {};
    for (let index = 0; index < data?.length; index++) {
      const element = data[index];
      // console.log("== desc ===", element["DESC"])
      let code = postalCodeId(element["DESC"]);
      let subElement = {};
      subElement["id"] = element?.CODE;
      subElement["name"] = element?.DESC;

      if (element?.DRD) {
        subElement["drd"] = element?.DRD;
      }

      if (element?.PCODE) {
        subElement["pcode"] = element?.PCODE;
      }
      obj[code] = subElement;
    }

    return obj;
  };

  const postalCodeId = (event) => {
    let str = event?.split(" - ");
    return str[0];
  };

  return { loadingMasterData };
};
