import { useHookstate } from "@hookstate/core";
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";

interface ConfigsProps {
    title: string;
    description: string;
    placeholder: string;
}

interface CreateNewConditionsProps {
    isEditing?: boolean;
    onHandleData: (data: ConfigsProps) => void;
    existingConfig?: ConfigsProps;
    title?: ""
}

const CreateNewConditions = ({ isEditing = false, onHandleData, existingConfig = undefined, title = "" }: CreateNewConditionsProps) => {
    const configs = useHookstate<ConfigsProps>({ title: "", description: "", placeholder: "" });
    const [variables, setVariables]: any = React.useState({});

    useEffect(() => {
        if (existingConfig) {
            configs.set(existingConfig);
        }

    }, [existingConfig])


    function removeUnsedTags(keys, tags) {
        const tagsToDelete: any = [];
        for (const key of keys) {
            if (!tags.includes(key)) {
                tagsToDelete.push(key);
            }
        }

        if (tagsToDelete.length === 0) {
            return;
        }

        const items = variables;
        for (const tag of tagsToDelete) {
            delete items[tag];
        }
        setVariables(items);

        try {
            const value: any = JSON.stringify(variables, undefined, 4);
            configs.placeholder.set(value);
        } catch (error) { }
    }


    function processVariables(e) {
        const text = e.target.value;
        const tags = text
            .split(" ")
            .filter((obj) => obj.includes("__"))
            .filter((obj) => obj != "__" && obj.endsWith("__") && obj.startsWith("__"));

        let variables = {}

        const keys = Object.keys(variables);
        for (const elem of tags) {
            if (!keys.includes(elem)) {
                const item = {};
                item[elem] = {
                    type: "manual",
                    traverse: "",
                    format: "",
                    hint: [
                        {
                            key: "",
                            value: "",
                        },
                    ],
                };
                variables = ({ ...variables, ...item });
            }
        }

        configs.placeholder.set(JSON.stringify(variables))

        if (keys.length - tags.length > 0) {
            removeUnsedTags(keys, tags);
        }
    }


    return (
        <Stack gap={2}>
            <Stack direction={'row'} justifyContent={"space-between"}>
                <Typography variant="subtitle1" color={"gray"}>{title}</Typography>
                <Tooltip title="Close" style={{ cursor: "pointer" }} onClick={closeGlobalModal}>
                    <CloseIcon color="error" fontSize="medium" />
                </Tooltip>
            </Stack>
            <Divider />
            <Stack direction={"row"} gap={2}>
                <Stack flex={1} gap={5}>
                    <TextBoxComponent
                        label="Title"
                        value={configs.title.get()}
                        fullWidth={true}
                        required={true}
                        helperText={"variables are not allowed to be defined in title"}
                        onChange={(e) => {
                            configs.title.set(e.target.value);
                        }}
                    />
                    <TextBoxComponent
                        label="Description"
                        value={configs.description.get()}
                        fullWidth={true}
                        maxRows={10}
                        helperText={"(define variables with __example__ as a format)"}
                        multiline={true}
                        onBlur={processVariables}
                        onChange={(e) => {
                            configs.description.set(e.target.value);
                        }}
                        required={true}
                    />
                </Stack>
                <Stack flex={1}>
                    <TextBoxComponent
                        label="Placeholder"
                        value={configs.placeholder.get()}
                        fullWidth={true}
                        onChange={(e) => {
                            configs.placeholder.set(e.target.value);
                        }}
                        required={true}
                        multiline={true}
                        rows={15}
                    />
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'flex-end'}>
                <ButtonComponent
                    variant="outlined"
                    loading={false}
                    onClick={() => {
                        onHandleData(configs.get());
                    }}
                    title={isEditing ? "Update" : "Save"}
                />
            </Stack>
        </Stack>
    )
}

export default CreateNewConditions