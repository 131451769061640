import { Downgraded, useHookstate } from "@hookstate/core";
import { Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { currentWorkflowStageDetails } from "../../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { abilityToRepayLoanDataKeys } from "../../Configs/FinancialPositionOfTheCompanyConfigs/FinancialPositionOfTheCompanyConfigs";
import AutoPopulatedLabel from "../../Helpers/AutoPopulatedLabel/AutoPopulatedLabel";
import TableBodyWrapper from "../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { CustomTableHeaderWrapperForBalanceSheet } from "../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import TableWrapper from "../../Helpers/TableContainer/TableWrapper/TableWrapper";
import { abilityToRepayLoanData } from "../../Stores/MicroAppraisalStore";
import BuildTableRowRevaamp from "./Components/BuildTableRowRevaamp";
import AbilityToPayLoanAndProposedLoanAmountGraph from "./Graph/BarGraph";

const AbilityToPayLoanAndProposedLoanAmountSummaryLayout = ({ creditFileData }) => {
    const abilityToRepayLoanDataState = useHookstate<any>(abilityToRepayLoanData);
    const { data: { netProfitBeforeInterestDepreciation = {} }, selectedYears } = abilityToRepayLoanDataState.attach(Downgraded).get();
    const { t: translate } = useTranslation();
    const { formData: { creditData: { interestRate, loanAmount } } } = creditFileData;
    const { trialData: { trailCalResult = [] } }: any = currentWorkflowStageDetails.attach(Downgraded).get();
    const result = trailCalResult.find((curr, index) => index === 1) || {};
    const { termlyInstallment = 0 } = result;

    const loanInstallmentAsaPercentageNetProfit = React.useMemo(() => {
        const thisYear = moment().year();
        const netProfitBeforeTax = Number(netProfitBeforeInterestDepreciation[thisYear] || 0);
        return Number(termlyInstallment / netProfitBeforeTax * 100).toFixed(2)
    }, [loanAmount, netProfitBeforeInterestDepreciation])

    return (
        <>
            <Stack width={"100%"} alignItems={"flex-start"}>
                <Stack>
                    <AbilityToPayLoanAndProposedLoanAmountGraph />
                </Stack>
            </Stack>
            <TableWrapper>
                <CustomTableHeaderWrapperForBalanceSheet
                    firstColTitle={translate("description")}
                    dataObject={selectedYears.filter(item => !!item?.isRatioAnalysisCal)}
                    style={{ justifyContent: "end", color: "#555555", fontWeight: "bold" }}
                />
                <TableBodyWrapper>
                    {
                        abilityToRepayLoanDataKeys.filter(({ type }) => type === "autoPopulated").map((item) => {
                            const props = { descriptionItem: item, index: item["title"] + item["dataKey"], abilityToRepayLoanDataState }

                            return <BuildTableRowRevaamp key={"BuildTableRowRevaamp" + item["dataKey"]} {...props} />
                        })
                    }
                </TableBodyWrapper>
            </TableWrapper>
            <Stack width={"100%"} pt={5} pb={5}>
                <Stack direction={"row"} flex={4} gap={1}>
                    <Stack flex={1}>
                        <AutoPopulatedLabel title={"Proposed Loan Installment"} value={termlyInstallment ? formatCurrency(termlyInstallment) : "N/A"} />
                    </Stack>
                    <Stack flex={1}>
                        <AutoPopulatedLabel title={"Interest Rate"} value={formatCurrency(interestRate) + "%"} />
                    </Stack>
                    <Stack flex={1}>
                        <AutoPopulatedLabel title={"Loan installment as a percentage of net profit"} value={(loanInstallmentAsaPercentageNetProfit ? formatCurrency(loanInstallmentAsaPercentageNetProfit) : "N/A") + "%"} />
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default AbilityToPayLoanAndProposedLoanAmountSummaryLayout;
