import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import { RenderingHelper } from "./Components";

export const EmailDetails = ({ emailDetails, index, nohead = false }) => {
  // const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);
  const typedData = (masterType) => {
    if (masterType) {
      if (masterType === 1) {
        return { name: "personal" };
      } else if (masterType === 2) {
        return { name: "Official" };
      } else return "";
    } else return "";
  };

  const emailType = typedData(emailDetails?.empEmailType);

  if (emailDetails?.empEmailAddress !== "" && emailDetails?.empEmailType !== "") {
    return (
      <Grid container item p={1} spacing={1} className="data-box">
        {nohead ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <div className="basic-font basic-font-color-bold font-size-16 align-left">
              Employment Contacts <div className="align-right">{"Section " + (index + 1)}</div>
            </div>
          </Grid>
        )}
        {RenderingHelper.addItem(emailDetails, "Email Type", ` ${emailType ? emailType.name : "N/A"}`)}
        {RenderingHelper.addItem(emailDetails, "empEmailAddress")}
      </Grid>
    );
  }

  return <></>;
};
