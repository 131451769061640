import { Grid, Stack } from "@mui/material"
import { SelectComponent } from "../../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { UserContactsInputComponent } from "../../../../../../../UserInputComponent/UserContactsInputComponent";

const EditEmailJobModalLayout = ({ onChange, emailData, isEdit, selectData, onSave }) => {

    return (
        <>
            <Grid container item spacing={2} m={1} xs={12} style={{ width: "100%" }}>
                <Grid item xs={3}>
                    <div className="basic-font font-size-16 basic-font-color-bold align-left">Email Template </div>
                </Grid>
                <Grid item xs={9}>
                    <SelectComponent
                        autoWidth={false}
                        onChange={(e) => onChange(e, 'templatesEmail')}
                        values={emailData.templatesEmail}
                        value={selectData?.get()?.templatesEmail?.id}
                        formSX={{ width: "100%" }}
                        size="small"
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} m={1} xs={12}>
                <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center" }}>
                    <div className="basic-font font-size-16 basic-font-color-bold align-left"> To Address</div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <UserContactsInputComponent
                        hierarchies={emailData.toAddress.hierarchies?.get() ? emailData.toAddress.hierarchies?.get()?.map(value => value.name) : []}
                        groups={emailData.toAddress.groups?.get() ? emailData.toAddress.groups?.get()?.map(value => value.name) : []}
                        users={emailData.toAddress.users?.get() ? emailData.toAddress.users?.get()?.map(value => value.name) : []}
                        onChange={(type, value) => onChange(type, value, 'toAddress')}
                        selectedHierarchies={selectData?.get()?.toAddress ? selectData?.get()?.toAddress.hierarchies?.map(value => value.name) : []}
                        selectedGroups={selectData?.get()?.toAddress ? selectData?.get()?.toAddress.groups?.map(value => value.name) : []}
                        selectedUsers={selectData?.get()?.toAddress ? selectData?.get()?.toAddress.users?.map(value => value.name) : []}
                    />
                </Grid>

                <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center" }}>
                    <div className="basic-font font-size-16 basic-font-color-bold align-left"> CC Address</div>
                </Grid>

                <Grid item xs={12} md={9}>
                    <UserContactsInputComponent
                        hierarchies={emailData.ccAddress.hierarchies?.get() ? emailData.ccAddress.hierarchies?.get()?.map(value => value.name) : []}
                        groups={emailData.ccAddress.groups?.get() ? emailData.ccAddress.groups?.get()?.map(value => value.name) : []}
                        users={emailData.ccAddress.users?.get()?.map(value => value.name)}
                        onChange={(type, value) => onChange(type, value, 'ccAddress')}
                        selectedHierarchies={selectData?.get()?.ccAddress ? selectData?.get()?.ccAddress.hierarchies?.map(value => value.name) : []}
                        selectedGroups={selectData?.get()?.ccAddress ? selectData?.get()?.ccAddress.groups?.map(value => value.name) : []}
                        selectedUsers={selectData?.get()?.ccAddress ? selectData?.get()?.ccAddress.users?.map(value => value.name) : []}
                    />
                </Grid>

                <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center" }}>
                    <div className="basic-font font-size-16 basic-font-color-bold align-left"> BCC Address</div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <UserContactsInputComponent
                        hierarchies={emailData.bccAddress.hierarchies?.get()?.map(value => value.name)}
                        groups={emailData.bccAddress.groups?.get()?.map(value => value.name)}
                        users={emailData.bccAddress.users?.get()?.map(value => value.name)}
                        onChange={(type, value) => onChange(type, value, 'bccAddress')}
                        selectedHierarchies={selectData?.get()?.bccAddress ? selectData?.get()?.bccAddress.hierarchies?.map(value => value.name) : []}
                        selectedGroups={selectData?.get()?.bccAddress ? selectData?.get()?.bccAddress.groups?.map(value => value.name) : []}
                        selectedUsers={selectData?.get()?.bccAddress ? selectData?.get()?.bccAddress.users?.map(value => value.name) : []}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default EditEmailJobModalLayout