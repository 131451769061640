import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, Grid, Stack } from '@mui/material'
import { standardDateFormatter } from "los-util"
import React, { useEffect } from 'react'
import CachingStorage from '../../../../../../services/cacheStorage'
import { closeBtn } from '../../../../../../styles'
import { formattedDate } from '../../../../../../utility/fomatters'
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent'
import { DatePickerComponent } from '../../../../../InputComponents/DatePickerComponent/DatePickerComponent'
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent'
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent'
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal'

function LetterSignatureModal(params) {
    const applicationId = params?.applicationId;
    const signatureSection = params?.section;
    const field = params?.name;

    const currentLocalStorage = CachingStorage.read('offerLetter') || {};

    const [signatureName, setSignatureName] = React.useState('');
    const [idNumber, setSignatureIdNumber] = React.useState('');
    const [signatureDate, setSignatureDate] = React.useState('');
    let initialData = {
        [applicationId]: { [signatureSection]: { "name": signatureName, "idNumber": idNumber } }
    }

    useEffect(() => {
        if (currentLocalStorage) {
            if (Object.keys(currentLocalStorage).includes(applicationId.toString())) {
                const signatureData = currentLocalStorage[applicationId.toString()][signatureSection];

                setSignatureDate(signatureData?.date);
                setSignatureIdNumber(signatureData?.idNumber);
                setSignatureName(signatureData?.name);
            }
        }
    }, [])

    const saveCondition = () => {
        if (currentLocalStorage) {
            if (Object.keys(currentLocalStorage).includes(applicationId.toString())) {
                currentLocalStorage[applicationId.toString()][signatureSection] = {
                    "name": signatureName,
                    "idNumber": idNumber,
                    "date": signatureDate,
                }
                CachingStorage.store('offerLetter', { ...initialData, ...currentLocalStorage });
            }
            CachingStorage.store('offerLetter', { ...initialData, ...currentLocalStorage });
        } else {
            CachingStorage.store('offerLetter', initialData);
        }
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
            params.callBackOnAction()
        }

        closeGlobalModal()
    }

    const closeCondition = () => {
        closeGlobalModal()
    }

    // let inputComponent;
    // let labelName;
    // if (params?.type === 'name') {
    //     labelName = 'Name'
    //     inputComponent = <TextBoxComponent
    //         value={managerName}
    //         onChange={(e) => {
    //             setManagerName(e.target.value)
    //         }}
    //         variant="outlined"
    //         size="small"

    //     />
    // } else if (params?.type === 'service-number') {
    //     labelName = 'Service Number'
    //     inputComponent = <TextBoxComponent
    //         value={managerServiceNumber}
    //         onChange={(e) => {
    //             setManagerServiceNumber(e.target.value)
    //         }}
    //         variant="outlined"
    //         size="small"

    //     />
    // }

    return (
        <Box>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <div className="basic-font font-size-18 basic-font-color-bold">Disbursment Of Facility</div>
            </Stack>

            <Grid container spacing={2} mt={1} className="modal-text-field">
                <Grid container item xs={12}>
                    {field === 'name' && (
                        <ModalInputComponent
                            title="Name"
                            component={<Grid xs={5} item>
                                <TextBoxComponent
                                    value={signatureName}
                                    onChange={(e) => {
                                        setSignatureName(e.target.value)
                                    }}
                                    variant="outlined"
                                    size="small"

                                />
                            </Grid>} isMandatory={false} showErrors={false} />
                    )}

                    {field === 'id' && (
                        <ModalInputComponent
                            title="Id Number"
                            component={<Grid xs={5} item>
                                <TextBoxComponent
                                    value={idNumber}
                                    onChange={(e) => {
                                        setSignatureIdNumber(e.target.value)
                                    }}
                                    variant="outlined"
                                    size="small"

                                />
                            </Grid>} isMandatory={false} showErrors={false} />
                    )}

                    {field === 'date' && (
                        <ModalInputComponent
                            title="Id Number"
                            component={<Grid xs={5} item>
                                <DatePickerComponent
                                    label="Select Date"
                                    value={formattedDate(signatureDate)}
                                    onChange={(date) => {
                                        setSignatureDate(standardDateFormatter(date));
                                    }}
                                    renderInput={(params) => <TextBoxComponent {...params} />}
                                />
                            </Grid>} isMandatory={false} showErrors={false} />
                    )}

                </Grid>
            </Grid>

            {/*  */}

            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
                <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={saveCondition} loadingbtn={true} color="info" />
            </Stack>
        </Box>
    )
}

export default LetterSignatureModal