import { useState } from "@hookstate/core";
import {
  Box,
  Button,
  CardContent,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  getScheme,
  getSector,
  getType,
} from "../../../../configs/constants/typeSectorScheme";
import {
  fetchApplicationDataCloneService,
  fetchApplicationHistoryData,
} from "../../../../services/applicationApis";
import { setStateFromTheApplicationFormData } from "../../../../services/setStateFromTheApplicationFormData";
import { ApplicationFormStore, useApplicationFormStore } from "../../../../utility/CustomHooks/useApplicationFormStates";
import { dateFormatWithTimeChange } from "../../../../utility/fomatters";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { toast } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import { convertReturnedDataToRawData } from "../Utilities/Utility";

const ApplicationHistoryModal = ({ oldNic, newNic, applicationId, currentParticipantType = 'personalData', currentParticipantId = 0 }) => {
  const [historyList, setHistoryList] = React.useState([]);
  const formStore: ApplicationFormStore = useApplicationFormStore();
  const loader = useState({ isFetchingData: true, isCloning: false })

  useEffect(() => {
    fetchApplicationHistory();
  }, []);

  const fetchApplicationHistory = async () => {
    try {
      const data = await fetchApplicationHistoryData({ oldNic, newNic });

      if (data.length > 0) {
        setHistoryList(data?.map((item: any) => ({
          applicationId: item?.applicationId,
          listViewData: JSON.parse(item?.listViewData ?? "{}"),
          submittedDate: item?.submittedDate,
        })));
      }
    } catch (error) {
      toast({ status: "error", message: "Error while fetching data" });
    } finally {
      loader.isFetchingData.set(false);
    }
  };

  const itemClicked = async ({ item, applicationClone }) => {
    const { applicationId } = item;
    const payload = { applicationId, target: applicationClone.target.get() ?? "", keys: [] };

    loader.isCloning.set(true);

    try {
      const formData = await fetchApplicationDataCloneService({ payload });

      if (Object.keys(formData).length > 0) {
        const sanitizedFormData = convertReturnedDataToRawData({ formData });

        if (currentParticipantType === 'personalData') {
          setStateFromTheApplicationFormData({ data: sanitizedFormData, ...formStore });

        } else if (currentParticipantType === 'jointBorrowerData') {
          setStateFromTheApplicationFormData({ data: sanitizedFormData, ...formStore?.jointBorrowerData[currentParticipantId] });

        } else if (currentParticipantType === 'securityData') {
          setStateFromTheApplicationFormData({ data: sanitizedFormData, ...formStore?.securityData?.guarantors[currentParticipantId] });
        }

        toast({ status: "success", message: "Data cloned successfully" });
      }

    } catch (error) {
      toast({ status: "error", message: "Error while fetching data" });
    } finally {
      loader.isCloning.set(false);
      closeGlobalModal();
    }
  };

  if (loader.isCloning.get() || loader.isFetchingData.get()) {
    return (
      <Stack className="data-box" padding={2} direction={'row'} justifyContent={'center'} spacing={2} flex={1} alignItems={"center"} component={Paper} elevation={0}>
        <Typography variant="body1">{loader.isCloning.get() ? "Please Wait! Almost done" : loader.isFetchingData.get() ? 'Getting Ready with Previous Application Form' : "Getting Ready"}...</Typography>
        <CircularProgressComponent size={30} />
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 100,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack>
            <Typography>Application History</Typography>
          </Stack>
          <Stack
            onClick={() => {
              closeGlobalModal();
            }}
          >
            <Typography
              variant="h6"
              style={{ cursor: "pointer", color: "black" }}
            >
              ❌
            </Typography>
          </Stack>
        </Stack>
        <Divider></Divider>
      </Stack>

      <Stack
        p={2}
        flexWrap={"wrap"}
        direction={"row"}
        gap={2}
        maxHeight={"400px"}
        overflow={"scroll"}
        style={{ overflowX: "hidden" }}
      >
        {historyList.length > 0 &&
          historyList.map((item: any, index) => (
            <Paper elevation={2}>
              <ApplicationHistoryCard item={item} itemClicked={itemClicked} currentParticipantType={currentParticipantType} />
            </Paper>
          ))}
      </Stack>
    </Stack>
  );
};

const ApplicationHistoryCard = ({ item, itemClicked, currentParticipantType }) => {
  const applicationClone: any = useState({});
  const {
    applicationId = "",
    submittedDate = "",
    listViewData: {
      primaryLastName = "",
      initials = "",
      type = "",
      sector = "",
      scheme = "",
      loanAmount = "",
    },
  } = item;

  return (
    <Box m={1} width={"350px"}>
      <CardContent>
        <Stack direction={"row"}>
          <Typography variant="h6" component="div" flex={1}>
            {initials + " " + primaryLastName}
          </Typography>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {applicationId}
          </Typography>
        </Stack>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {getType(type) + " " + getSector(sector) + " " + getScheme(scheme)}
        </Typography>
        <Stack width={"100%"}>
          <Typography variant="body2">{`Loan Amount: ${loanAmount}`}</Typography>
          <Typography variant="body2">{`Submitted At: ${dateFormatWithTimeChange(
            submittedDate
          )}`}</Typography>
        </Stack>
      </CardContent>
      <Divider />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={1}
      >
        {currentParticipantType === 'personalData' ? <Stack direction={"row"} alignItems={"center"}>
          <CheckBoxComponent
            checked={applicationClone.target.get() === "jb"}
            onChange={() => applicationClone.target.set("jb")}
            disabled={false}
          />
          <Typography variant="body2" color={"grey"}>
            Joint Borrower
          </Typography>
          <CheckBoxComponent
            checked={applicationClone.target.get() === "gr"}
            onChange={() => applicationClone.target.set("gr")}
            disabled={false}
          />
          <Typography variant="body2" color={"grey"}>
            Guarantor
          </Typography>
        </Stack> : <></>}


        <Stack direction={"row"}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => itemClicked({ item, applicationClone })}
          >
            Clone
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ApplicationHistoryModal;
