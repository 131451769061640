import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import {
  detachSubflowReferral
} from "../../../../../../services/creditFileApiCall";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../../../../GlobalToast";

import PreviewIcon from "@mui/icons-material/Preview";
import { bufferToBlobUrl } from "../../../../../../configs/base64Conversion";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";

const ReferDetachModal = (params) => {
  const initialData = { note: "", user: "" };

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [disabled, setIsDisabled] = React.useState<any>(false);

  const detachDocuemnt = async (item, groupId) => {
    setIsLoading(true);
    try {
      const params = {
        applicationid: item?.applicationid,
        execId: item?.sequence,
        productSubflowId: item?.applicationsubflowid,
        groupId: groupId,
      };
      const response = await detachSubflowReferral(params);

      if (response?.status === 1) {
        addToaster({
          status: "success",
          title: "Success",
          message: "Document detachement is success",
        });
        setIsLoading(false);
        setIsDisabled(true);
        closeGlobalModal();
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Document detachement failed",
      });
      setIsLoading(false);
    }
  };

  const previewClicked = (obj) => {
    let url = bufferToBlobUrl(obj?.fileData?.data, obj?.contentType, (progress) => { });
    window.open(
      `${url}`,
      "targetWindow",
      `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=500`
    );
  };

  let assignPermission = false;
  if (params?.referData.assignees?.length > 0) {
    if (params?.referData.assignees.includes(params?.currentUser) !== true) {
      assignPermission = true;
    }
  }

  return (
    <Box>
      <Grid container maxHeight={"calc(100vh - 200px)"} sx={{ overflowY: "auto" }}>
        <Stack spacing={1} m={1}>
          <ButtonComponent
            startIcon={<PreviewIcon />}
            title="Preview"
            variant="contained"
            onClick={() => previewClicked(params?.item?.attachements[0])}
            disabled={isLoading || disabled}
          />
        </Stack>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Detach"
          variant="contained"
          onClick={(e) => detachDocuemnt(params?.item, params?.groupId)}
          disabled={isLoading || assignPermission || disabled}
          loadingbtn={true}
          color="error"
        />
      </Stack>
    </Box>
  );
};

export default ReferDetachModal;
