import { Downgraded, useState } from "@hookstate/core";
import SyncIcon from "@mui/icons-material/Sync";
import { CircularProgress, Grid, IconButton, Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { userDetails } from "../../../../../configs/mainStore";
import { getClientsDetails, getCoreBankData } from "../../../../../services/bankServiceInvorker";
import { loadCustomerDataMap } from "../../../../../utility/helpers/creditFileUtility";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import CustomerCIFInformation from "./CustomerCIFInformation";

const LoanRelatedCIF = ({ applicationId, currentApplicationWfData, innerComponentData, data, tabsToEdit, isInvestmentAccount = false, callBackOnChange = (val: any) => { }, selectedCif = "" }) => {
  const isLoading = useState(true);
  const loading = useState({ send: false });
  const customerDataState: any = useState({});

  const { userId }: any = userDetails.attach(Downgraded).get();
  const noPrivilege = userId !== data?.currentAssignee;

  useEffect(() => {
    loadData();
  }, [data]);

  const loadData = async (force = false) => {
    const { dataMap: customerData = {} } = loadCustomerDataMap(data);

    if (Object.keys(customerData).length === 0) {
      return;
    }

    const allNicList = Object.values(customerData).reduce((allList: any, element: any) => {
      let elements = element;
      if (!(element instanceof Array)) {
        elements = [element];
      }
      elements.forEach(element => {
        const { newNic, oldNic } = element;
        if (newNic) allList.push(newNic);
        if (oldNic) allList.push(oldNic);
      });
      return allList;
    }, []);


    const [selectedCIFObj, res] = await Promise.all([getCoreBankData(applicationId, CORE_BANK_KEYS.CIF), getClientsDetails(allNicList, force)]);

    for (const [type, data] of Object.entries(customerData)) {
      let items: any = data;
      if (!(data instanceof Array)) {
        items = [data];
      }
      for (const element of items) {
        const { newNic, oldNic, individualId } = element;
        element.clientDetails = element.clientDetails || {};

        if (newNic && res?.[newNic]) {
          const { IndividualProfiles = [], JoinedProfiles = [] } = res[newNic];
          element.selectedCIF = selectedCIFObj?.[individualId]?.CIF;
          element.clientDetails[newNic] = { IndividualProfiles, JoinedProfiles };
        }
        if (oldNic && res?.[oldNic]) {
          const { IndividualProfiles = [], JoinedProfiles = [] } = res[oldNic];
          element.selectedCIF = selectedCIFObj?.[individualId]?.CIF;
          element.clientDetails[oldNic] = { IndividualProfiles, JoinedProfiles };
        }
      }
    }

    customerDataState.set(customerData);

    isLoading.set(false);
  };

  const actions = useCallback(() => {
    return {
      onRefreshData: async (id) => {
        await loadData(true);
      },
      reloadData: async () => {
        isLoading.set(true);
        await loadData();
        isLoading.set(false);
      },
      requestPayloadData: (payload) => {
        const { jb, primary } = customerDataState.get() || {};
        const primaryIndividualId = primary?.[0]?.individualId;
        if (!primaryIndividualId) return;
        for (const applicant of jb) {
          payload[applicant.individualId] = payload?.[primaryIndividualId];
        }
      }
    };
  }, []);

  const BuildComponent = () => {
    if (isLoading?.get()) {
      return <Stack width={"100%"} justifyContent={"center"} alignItems={"center"} height={"100%"}><CircularProgressComponent size={24} /></Stack>;
    }

    let data = customerDataState.attach(Downgraded).get() || {};
    if (!(data.primary instanceof Array)) {
      data.primary = [data.primary];
    }

    return <Stack flex={1}>
      {["primary", "jb", "gr"].map((key) => (data[key] as any).map(item => <CustomerCIFInformation type={key} payload={{ ...item, applicationId, noPrivilege, primary: data.primary }} actions={actions}></CustomerCIFInformation>))}
    </Stack>;
  }

  return <div className="mainSectionWrapper full-width full-height">
    <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={actions} disabled={noPrivilege}>
      <Stack>
        <IconButton
          size="small"
          color="primary"
          children={loading.send.get() ? <CircularProgress size={24} /> : <SyncIcon />}
          onClick={async () => {
            loading.send.set(true);
            await loadData(true);
            loading.send.set(false);
          }}
        />
      </Stack>
    </InnerSectionHeaderComponent>
    <Grid container className="inner-component-height padding-sm">
      <BuildComponent />
    </Grid>
  </div>;
};

export default LoanRelatedCIF;
