import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Stack, Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import { ButtonComponent } from "../../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { productDefaultDataFormatLoanPurposeConfig } from "../../../../configs/constants/productDefaultDataFormatLoanPurposeConfig";
import { productDetails, productMappingDetails } from "../../../../configs/mainStore";

const ProductTypeSelectComponent = (props) => {
  const productSelected: any = useState(productDefaultDataFormatLoanPurposeConfig);
  const productMasterData: any = useState(productDetails);
  let productData: any = [];
  const prodId = productSelected.productType.id.get();
  const productDataMapping: any = useState(productMappingDetails);
  const sectorId = productSelected.sectorType.id.get();
  const [title, setTitle]: any = React.useState("");
  const [description, setDescription]: any = React.useState("");
  const [charge, setCharge]: any = React.useState({});
  const [loading, setLoading]: any = React.useState(true);

  if (prodId && productDataMapping?.productMapping?.get()?.length > 0) {
    productData = productDataMapping?.productMapping?.get()?.find((prod) => prod.productId == prodId);
  }

  useEffect(() => {
    const chargeObj: any = {
      name: "",
      description: "",
    };

    if (props?.type == "update") {

      chargeObj.name = props?.data?.name;
      chargeObj.description = props?.data?.description;
      chargeObj.typeId = props?.data?.id;
    }
    setCharge(chargeObj);
    setLoading(false);
  }, [props])


  const createTemplateDispatcher = (type: any = "") => {
    const errors: any = [];

    for (const key in charge) {
      if (charge.hasOwnProperty(key) && (charge[key] === "" || charge[key] === 0)) {
        errors.push(key);
      }
    }

    if (errors.length > 0) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Please fill required fields!",
      });
    } else {
      props?.callBackOnSave(charge, props?.data?.id);
    }
  };

  if (loading) {
    return <CircularProgressComponent size={30} sx={{ zIndex: 100, margin: "auto" }} />;
  }

  return (
    <>
      <Stack flex={1} m={2}>
        <Stack display={"flex"} direction={"row"} mb={2}>
          <Stack flex={1}>
            <Typography variant={"h6"}>Create Template</Typography>
          </Stack>
        </Stack>
        <Stack direction={"column"}>
          <Stack mb={3}>
            <TextBoxComponent label="Title" value={charge?.name} fullWidth={true} onChange={(e) => setCharge((previousState) => ({ ...previousState, name: e.target.value }))} required={true} />
          </Stack>
          <Stack mb={3}>
            <TextBoxComponent label="Description" value={charge?.description} fullWidth={true} onChange={(e) => setCharge((previousState) => ({ ...previousState, description: e.target.value }))} required={true} />
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          <Stack flex={1}></Stack>
          <ButtonComponent startIcon={<CloseIcon />} title="Cancel" variant="outlined" onClick={() => closeGlobalModal()} loadingbtn={true} color="info" style={{ marginRight: "12px" }} />
          <ButtonComponent startIcon={<DoneIcon />} title="Submit" variant="contained" onClick={() => createTemplateDispatcher()} loadingbtn={true} color="info" />
        </Stack>
      </Stack>
    </>
  );
};

export default memo(ProductTypeSelectComponent);
