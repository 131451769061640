import { applicationFormSectionDetailsDelete } from "../../../../../../services/applicationApis";
import { openGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { Toast } from "../../../../GlobalToast";

const dispatchDeleteEvent = ({ }) => {
    openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "app-section-delete-confirm",
        close: false,
        modalParams: {
            textMessage: "This will delete all collaterals except guarantors!.",
            // callBackOnAction: () => collateralSectionDeletion(type, index),
        },
    });
}

export const collateralSectionDeletion = async ({ collateralDataState, itemIndex, collateralKeyString, applicationId }) => {
    try {
        const [mainKey, subKey, title] = collateralKeyString.split(":") || [];
        const path = `${mainKey}.${subKey}`
        const params = { applicationId, sectionTravers: `securityData.${path}[${itemIndex}]` }

        await applicationFormSectionDetailsDelete(params);
        collateralDataState.set(prev => prev.filter((item, index) => itemIndex !== index))

        Toast.success(`${title} Deleted Successfully!`, 'Success')

        setTimeout(function () {
            window.location.reload();
        }, 1000);
    } catch (error) {
        console.error(error)
    }
}