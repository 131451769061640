import { Grid, Stack } from "@mui/material";
import { numberToWords } from "los-util";
import { FC } from "react";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import { SinglePageBreak } from "../../CommonPageBreaks";

interface StandingInstructionsBodyPropType {
  data?: any;
  typeCaseClass?: any;
  coreBankData: any;
}

export const StandingInstructionsBody: FC<StandingInstructionsBodyPropType> = ({ data, coreBankData, typeCaseClass }) => {
  const payload: any = data;
  const jointBorrowerData = data?.formData?.jointBorrowerData;

  const { recoveryAccount, investmentAccount, branch, investmentAmount } = coreBankData;

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (payload.formData.personalData.newNic != null && payload.formData.personalData.newNic != "") {
        nic = payload.formData.personalData.newNic;
      } else if (payload.formData.personalData.oldNic != null && payload.formData.personalData.oldNic != "") {
        nic = payload.formData.personalData.oldNic;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  const renderJBSignatures = () => {
    return jointBorrowerData.map((jb, index) => {
      return (
        <>
          <Grid item xs={6} mb={2}>
            <Stack direction={"row"}>
              <Stack>{parseInt(index + 2)}.</Stack>
              <Stack ml={2}>
                <Stack>...................................................</Stack>
                <Stack mb={2}>Signature</Stack>
                <Stack direction={"row"} mb={2}>
                  NIC : <Stack width={5}></Stack>
                  <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("JB", jb)}</span>{" "}
                </Stack>
                <Stack>Date : ......................................</Stack>
              </Stack>
            </Stack>
          </Grid>
          {index % 2 == 0 && (
            <Grid item xs={12}>
              <SinglePageBreak />
            </Grid>
          )}
        </>
      );
    });
  };

  return (
    <>
      <Grid>
        <Stack>
          <Stack>The Manager</Stack>
          <Stack>Pradeshiya Sanwardhana Bank</Stack>
          <Grid mb={1}>
            <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span> Branch
            <br></br>
          </Grid>
        </Stack>
        <Grid>
          <Stack mb={2} alignItems={"center"}>
            <div style={{ textDecoration: "underline" }}>Authorization to debit the Account </div>
            <br></br>
          </Stack>
          <Stack mb={2} style={{ textAlign: "justify" }}>
            <span>
              I/ we hereby authorize you to debit the account bearing number <span className={`security-doc-type-variable ${typeCaseClass}`}>{recoveryAccount ?? "N/A"}</span> maintained by me /us at your Bank from a sum of <span className={`security-doc-type-variable ${typeCaseClass}`}>{numberToWords(investmentAmount)}</span> (Rs. {`${formatCurrency(investmentAmount, 2)}`}) on monthly basis and credit the said amount to the account bearing number <span className={`security-doc-type-variable ${typeCaseClass}`}>{investmentAccount ?? "N/A"}</span>
            </span>
            <br></br>
          </Stack>
          <Stack mb={4}>Yours faithfully,</Stack>
          <Stack>
            <Grid container>
              <Grid item xs={6} mb={2}>
                <Stack direction={"row"}>
                  <Stack>1.</Stack>
                  <Stack ml={2}>
                    <Stack>...................................................</Stack>
                    <Stack mb={2}>Signature</Stack>
                    <Stack direction={"row"} mb={2}>
                      NIC : <Stack width={5}></Stack>
                      <span className={`security-doc-type-variable ${typeCaseClass}`}>{renderNicDetails("PA", "")}</span>{" "}
                    </Stack>
                    <Stack>Date : ......................................</Stack>
                  </Stack>
                </Stack>
              </Grid>
              {jointBorrowerData?.length < 1 && <SinglePageBreak />}
              {jointBorrowerData?.length > 0 && renderJBSignatures()}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
