import React, { useState } from "react";
import { PatternFormat } from "react-number-format";
import { OnDemandValueValidator } from "../../../../../../../../services/onDemandValidators";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

const PhoneNumberBuilder = ({ value, onChange, metaData }) => {
  const { t: translate } = useTranslation();
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  return (
    <Stack pt={1} px={1}>
      <PatternFormat
        format="(###) ### ####"
        defaultValue={valueInput}
        label={translate("Telephone no")}
        fullWidth={true}
        allowEmptyFormatting={false}
        disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
        mask="_"
        customInput={TextBoxComponent}
        error={OnDemandValueValidator.phoneNumber(value[metaData?.dataKey])}
        onValueChange={(values, _) => {
          setValue(values?.value);
          value[metaData?.dataKey] = values?.value;
          onChange(metaData, value);
        }}
      />
    </Stack>
  );
};

export default PhoneNumberBuilder;
