export class ExitRemoveItem {
  private itemArray: any = [];

  constructor(objectArray) {
    this.itemArray = objectArray;
  }

  removeRemovedItem(): any {
    if (this.itemArray.length > 0) {
      return this.itemArray.filter((item: any) => item !== null && !item.hasOwnProperty("removedItem") && item["removedItem"] !== true);
    }
    return [];
  }
}
