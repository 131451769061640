import React, { useMemo } from "react";
import { Downgraded } from "@hookstate/core";
import { openGlobalModal } from "../../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../../GlobalToast";
import { TableRowWrapper } from "../../../../Helpers/TableContainer/TableRow/TableRowWrapper";
import TableColumnsTextBoxWrapperAlphanumericNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperAlphanumericNew";
import TableColumnsTextWrapperAnyNew from "../../../../Helpers/inputHelpers/TableColumnsTextWrapperAnyNew";
import TableColumnsTextBoxWrapperNumberNew from "../../../../Helpers/inputHelpers/TableColumnsTextBoxWrapperNumberNew";
import TableColumnsSelectBoxWrapperNew from "../../../../Helpers/inputHelpers/TableColumnsSelectBoxWrapperNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";

const LoanDetailsCribTableBuildRow = ({ data, callBackOnDelete }) => {
  const dataKeys: any[] = Object.keys(data) || [];
  const [totalOutstanding, setTotalOutstanding]: any = React.useState(0);

  useMemo(() => {
    let _outstanding = 0;

    dataKeys?.map((key, index) => {
      const { outstanding } = data[key];
      _outstanding += parseFloat(outstanding?.get());
    });

    setTotalOutstanding(_outstanding);
  }, [data]);

  const dataObj = data.attach(Downgraded).get();
  const onchangeHandler = (value, dataKey, dataIndex) => {
    dataObj[dataIndex][dataKey] = value;
    data?.set(dataObj);
  };

  const itemRowDeleteDispatcher = (key: any, type = "") => {
    if (key) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete the row item!.",
          callBackOnAction: () => callBackOnDelete(key, type),
        },
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty Item Key",
        message: "Item key is required to proceed the deletion",
      });
    }
  };

  return (
    <>
      {dataKeys?.length > 0 &&
        dataKeys?.map((key, index) => {
          const { business, nameoOfInstitution, type, grantedAmount, securities, reasonOfDelay, installment, outstanding } = data[key];

          return (
            <>
              <TableRowWrapper>
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "business"} value={business} index={index + "business"} title={""} dataKey={"business"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextWrapperAnyNew index={index + "nameoOfInstitution"} data={data} dataKey={"nameoOfInstitution"} dataIndex={key} onchangeHandler={onchangeHandler}>
                  <>{nameoOfInstitution?.get()}</>
                </TableColumnsTextWrapperAnyNew>
                <TableColumnsSelectBoxWrapperNew key={index + "type"} value={type} index={index + "type"} title={""} dataKey={"type"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "grantedAmount"} value={grantedAmount} index={index + "grantedAmount"} title={""} dataKey={"grantedAmount"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "securities"} value={securities} index={index + "securities"} title={""} dataKey={"securities"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperAlphanumericNew key={index + "reasonOfDelay"} value={reasonOfDelay} index={index + "reasonOfDelay"} title={""} dataKey={"reasonOfDelay"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "installment"} value={installment} index={index + "installment"} title={""} dataKey={"installment"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextBoxWrapperNumberNew key={index + "outstanding"} value={outstanding} index={index + "outstanding"} title={""} dataKey={"outstanding"} dataIndex={key} onchangeHandler={onchangeHandler} />
                <TableColumnsTextWrapperAnyNew key={index + "actions"} index={index + "actions"} style={{ textAlign: "right" }}>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => itemRowDeleteDispatcher(key, "loanCribBank")}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableColumnsTextWrapperAnyNew>
              </TableRowWrapper>
            </>
          );
        })}

      <TableRowWrapper>
        <TableColumnsTextWrapperAnyNew index={"total_label"}>
          <>Total</>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty1"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty2"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty3"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty4"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty5"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_empty6"}>
          <></>
        </TableColumnsTextWrapperAnyNew>
        <TableColumnsTextWrapperAnyNew index={"total_outstanding"}>{formatCurrency(totalOutstanding)}</TableColumnsTextWrapperAnyNew>
      </TableRowWrapper>
    </>
  );
};

export default LoanDetailsCribTableBuildRow;
