import { useState } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { addCribNote } from "../../../../../services/creditFileApiCall";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

export const AddCribNoteModal = (params) => {
  const [updating, setUpdating] = useState(false);
  const [cribNote, setCribNote] = useState("");

  const updateCribNote = async () => {
    const { participantId } = params.info;
    const {
      applicationId: [applicationId],
    } = params.application?.get();
    const data = { applicationId, participantId, cribNote };

    try {
      if (!cribNote) {
        addToaster({
          status: "error",
          title: "Error",
          message: `Crib note can not be empty.`,
        });
        return;
      }

      if (cribNote.length > 4000) {
        addToaster({
          status: "error",
          title: "Error",
          message: `Crib note length has been exceeded.`,
        });
        return;
      }

      setUpdating(true);
      const result = await addCribNote(data);
      if (result) {
        addToaster({
          status: "success",
          title: "Added",
          message: `Crib note added successfully.`,
        });

        if (params.onChange) {
          params.onChange(result);
        }
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to add crib note.",
      });
    } finally {
      setUpdating(false);
      closeGlobalModal();
    }
  };

  return (
    <Stack>
      <Stack direction={"row"}>
        <Typography> Add Note {params.title ? " - " + params.title : ""} </Typography>
      </Stack>
      <Divider></Divider>
      <Stack height={"250px"} mt={2} mb={2}>
        <TextBoxComponent required={1} label={"Add crib note"} multiline={true} rows={10} value={cribNote} onChange={(e) => setCribNote(e.target.value)}></TextBoxComponent>
      </Stack>
      <Divider></Divider>
      <Stack direction={"row-reverse"} spacing={2} mt={2} alignItems={"center"}>
        <ButtonComponent
          variant={"contained"}
          disabled={updating}
          title={"Add"}
          onClick={() => {
            updateCribNote();
          }}
        />
        <ButtonComponent
          variant={"outlined"}
          disabled={updating}
          title={"Close"}
          onClick={() => {
            closeGlobalModal();
          }}
        />
        {updating && <CircularProgressComponent size={25} />}
      </Stack>
    </Stack>
  );
};
