import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { branchDetails } from "../../../../configs/mainStore";
import { updateApplicationAssignee } from "../../../../services/configurationApis";
import { getUsersByGroupId } from "../../../../services/userPrivilegeService";
import { userName } from "../../../../utility/helpers/creditFileUtility";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

function AssigneeChangeModal(params) {
  const { applicationId, ownerGroup, branch, details, groups } = params;
  let branchId =
    branch?.id ||
    details?.originDataSet?.ORIGINATION_BRANCH ||
    params?.userData?.userDetails?.branch;
  const branchMap = branchDetails.branchMap;
  const [isLoading, setIsLoading] = React.useState(false);
  const [usersList, setUsersList] = React.useState({});
  const [selectedUser, setSelectedUser] = React.useState(null);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      let tempUserList: any = [];

      let usersListData = await getUsersByGroupId(
        ownerGroup,
        branchId,
        applicationId
      );

      const usersBranchMap: any = {
        singleBranch: [],
        multipleBranch: [],
      };

      if (usersListData) {
        for (let [key, value] of Object.entries(usersListData)) {
          const userId = parseInt(key.toString());
          if (value === 2) {
            usersBranchMap.multipleBranch.push({
              name: userName(userId),
              id: userId,
            });
          } else {
            usersBranchMap.singleBranch.push({
              name: userName(userId),
              id: userId,
            });
          }
        }
        tempUserList =
          usersBranchMap.multipleBranch.length > 0
            ? usersBranchMap.multipleBranch
            : usersBranchMap.singleBranch;

        setUsersList(
          tempUserList.filter((user) => user?.id !== details?.currentAssignee)
        );
      }

      setIsLoading(false);
    })();
  }, [params]);

  const saveCondition = async () => {
    if (!selectedUser) {
      addToaster({
        status: "error",
        title: "Current Assignee",
        message: "Error: No group selected. Please select a group to proceed with changing the assignee.",
      });

      return;
    }

    setIsLoading(true);
    const data = {
      applicationId: applicationId,
      assignee: selectedUser,
    };

    const res = await updateApplicationAssignee(data);

    setIsLoading(false);

    if (res?.status === 1) {
      addToaster({
        status: "success",
        title: "Current Assignee",
        message: `${applicationId} application assignment changed`,
      });
      params.callBackOnAction && params.callBackOnAction();
      closeGlobalModal();
    } else {
      addToaster({
        status: "error",
        title: "Current Assignee ",
        message: "Update Failed",
      });
    }
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction={"column"}>
          <div className="basic-font font-size-18 basic-font-color-bold">
            Change Assignee
          </div>
          <Typography variant="caption" color={"gray"}>
            Change the assignee manually
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column" mt={2}>
        <Typography variant="caption" color={"gray"}>
          Application Reference(s)
        </Typography>
        <Typography variant="subtitle1" fontWeight={100}>
          {applicationId instanceof Array
            ? applicationId.join(",")
            : applicationId}
        </Typography>
      </Stack>

      <Divider></Divider>

      <Stack mt={2} mb={2}>
        <Typography variant="subtitle1" fontWeight={100} mb={1}>
          Users from group{" "}
          {(groups || []).find((grp) => grp.id === ownerGroup)?.name}
        </Typography>

        {isLoading ? (
          <CircularProgressComponent size={30} />
        ) : (
          <AutoCompleteProxyComponent
            label={"Select Assignee"}
            value={selectedUser}
            options={usersList}
            fullWidth={true}
            cls="select_basic"
            sx={{ width: 300 }}
            onChange={(e) => {
              setSelectedUser(e?.id || null);
            }}
          />
        )}
      </Stack>

      <Divider></Divider>

      <Stack direction="row" spacing={2} justifyContent="end" pt={1} mt={1}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="outlined"
          onClick={closeCondition}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Assign"
          variant="contained"
          onClick={saveCondition}
          color="info"
          disabled={!selectedUser}
        />
      </Stack>
    </Box>
  );
}

export default AssigneeChangeModal;
