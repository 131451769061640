import moment from "moment";

export const onChangeHandler = ({ value, yearDataKey, itemDataKey, formData }) => {

    let nextYear = Number(moment().year()) + 1;
    let previousFormData = formData?.[yearDataKey] || {};

    if (yearDataKey === "variableRate") {
        let regex = /^$|^-?$|^-?(100|[1-9]?[0-9])$/;

        value = regex.test(value.toString()) ? value : formData?.[yearDataKey]?.[itemDataKey] || 0;
    }

    let objectData = {
        ...formData, [yearDataKey]: { ...previousFormData, [itemDataKey]: value }
    }

    // eslint-disable-next-line eqeqeq
    if ((yearDataKey === "variableRate" && value != formData?.[yearDataKey]?.[itemDataKey]) || yearDataKey == nextYear) {
        let followingYear = Number(moment().year()) + 2;
        let valueOfFollowingYear = 0;
        let variableRateValue: number = Number(objectData?.["variableRate"]?.[itemDataKey] || 0)
        let nextYearValue: number = Number(objectData?.[nextYear]?.[itemDataKey] || 0);
        previousFormData = objectData?.[followingYear] || {};

        valueOfFollowingYear = Math.floor(nextYearValue + (nextYearValue * variableRateValue) / 100);

        objectData = { ...objectData, [followingYear]: { ...previousFormData, [itemDataKey]: valueOfFollowingYear } }
    }

    return objectData;
}