import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AppConfig from "../../../utility/AppConfig";
import { dateFormatWithTimeChange } from "../../../utility/fomatters";
import { userName } from "../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";

export const DocumentDetailsInfoModal = ({ document }) => {
    const { details = "{}" } = document;
    const { name = "", desc = "" } = JSON.parse(details) || {};

    return <Stack direction={'column'}>
        <Stack direction={'row'} alignItems={"flex-start"} justifyContent={'space-between'} pb={1} pt={2} borderBottom={1} borderColor={grey[400]}>
            <Stack>
                <Typography>{name} </Typography>
                <Typography variant="caption" color={"gray"}>{desc} </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                <CloseIcon
                    onClick={() => {
                        closeGlobalModal();
                    }}
                />
            </Stack>
        </Stack>
        <Stack mt={2} spacing={2}>
            {document.uploadedBy && <Stack>
                <Typography variant="caption" color={"gray"}>Uploaded By</Typography>
                <Typography variant="body2">{userName(document.uploadedBy)} </Typography>
            </Stack>}
            {document.uploadedDate && <Stack>
                <Typography variant="caption" color={"gray"}>Uploaded Date</Typography>
                <Typography variant="body2">{dateFormatWithTimeChange(document.uploadedDate)} </Typography>
            </Stack>}
            {document.verifiedBy && <Stack>
                <Typography variant="caption" color={"gray"}>Verified By</Typography>
                <Typography variant="body2">{userName(document.verifiedBy)} </Typography>
            </Stack>}
            {document.verifiedDate && <Stack>
                <Typography variant="caption" color={"gray"}>Verified Date</Typography>
                <Typography variant="body2">{dateFormatWithTimeChange(document.verifiedDate)} </Typography>
            </Stack>}
            {document.fileName && <Stack>
                <Typography variant="caption" color={"gray"}>File Name</Typography>
                <Typography variant="body2">{document.fileName} </Typography>
            </Stack>}
            {document.documentId && AppConfig.control && <Stack>
                <Typography variant="caption" color={"gray"}>Document ID</Typography>
                <Typography variant="body2">{document.documentId} </Typography>
            </Stack>}
            {document.participantId && AppConfig.control && <Stack>
                <Typography variant="caption" color={"gray"}>Individual ID</Typography>
                <Typography variant="body2">{document.participantId} </Typography>
            </Stack>}
        </Stack>
        <Stack direction={'row-reverse'} mt={1} borderTop={1} borderColor={grey[400]} pt={2}>
            <ButtonComponent
                title="Close"
                variant="contained"
                onClick={() => closeGlobalModal()}
                color="info"
            />
        </Stack>
    </Stack>;
};
