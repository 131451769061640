import { Grid, Stack } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import ScoreCardRow from "./ScoreCardRow";

const RetailScoreCardLayout = ({ isLoading, scoreCardData, masterDataStore, view }) => {

    const FallbackLayout = () => <>
        <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
            <div className="basic-font basic-font-color-bold font-size-16 align-left">Borrower Risk</div>
        </Grid>
        <ScoreCardRow
            title={scoreCardData["age"]?.displayName}
            subTxt={scoreCardData["age"]?.displayCondition}
            score={scoreCardData["age"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["educationLevel"]?.displayName}
            subTxt={
                scoreCardData["educationLevel"]?.value
                    ? masterDataStore?.getMasterDataDescription(
                        MASTER_DATA_KEYS.EDUCATION_GRADE,
                        scoreCardData["educationLevel"]?.value
                    )
                    : "--"
            }
            score={scoreCardData["educationLevel"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["residenceType"]?.displayName}
            subTxt={
                scoreCardData["residenceType"]?.value
                    ? masterDataStore?.getMasterDataDescription(
                        MASTER_DATA_KEYS.RESIDENTIAL_STATUS,
                        scoreCardData["residenceType"]?.value
                    )
                    : "--"
            }
            score={scoreCardData["residenceType"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["employmentStatus"]?.displayName}
            subTxt={
                scoreCardData["employmentStatus"]?.value
                    ? scoreCardData["employmentStatus"]?.value === 1
                        ? "Permanent"
                        : "Non-permanent"
                    : "--"
            }
            score={scoreCardData["employmentStatus"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["employmentExperienceYears"]?.displayName}
            subTxt={scoreCardData["employmentExperienceYears"]?.displayCondition}
            score={scoreCardData["employmentExperienceYears"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["employmentType"]?.displayName}
            subTxt={
                scoreCardData["employmentType"]?.value
                    ? masterDataStore?.getMasterDataDescription(
                        MASTER_DATA_KEYS.EMPLOYMENT_TYPE,
                        scoreCardData["employmentType"]?.value
                    ) || "Self-Employed / Own Business"
                    : "--"
            }
            score={scoreCardData["employmentType"]?.score}
        />

        <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
            <div className="basic-font basic-font-color-bold font-size-16 align-left">Financial Risk</div>
        </Grid>
        <ScoreCardRow
            title={scoreCardData["dependentIncome"]?.displayName}
            subTxt={scoreCardData["dependentIncome"]?.displayCondition}
            score={scoreCardData["dependentIncome"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["percentageLoanInstallIncome"]?.displayName}
            subTxt={scoreCardData["percentageLoanInstallIncome"]?.displayCondition}
            score={scoreCardData["percentageLoanInstallIncome"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["debtHandlingCapacity"]?.displayName}
            subTxt={scoreCardData["debtHandlingCapacity"]?.displayCondition}
            score={scoreCardData["debtHandlingCapacity"]?.score}
        />

        <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
            <div className="basic-font basic-font-color-bold font-size-16 align-left">Transactional Risk</div>
        </Grid>
        <ScoreCardRow
            title={scoreCardData["salaryAssignment"]?.displayName}
            subTxt={scoreCardData["salaryAssignment"]?.displayCondition}
            score={scoreCardData["salaryAssignment"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["ltvRatio"]?.displayName}
            subTxt={scoreCardData["ltvRatio"]?.displayCondition}
            score={scoreCardData["ltvRatio"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["cribRecord"]?.displayName}
            subTxt={
                scoreCardData["cribRecord"]?.value
                    ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS.CRIB_RECODE, scoreCardData["cribRecord"]?.value)
                    : "--"
            }
            score={isLoading ? <CircularProgressComponent size={20} /> : scoreCardData["cribRecord"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["bankRelation"]?.displayName}
            subTxt={
                scoreCardData["bankRelation"]?.value
                    ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS.BANK_RELATION, scoreCardData["bankRelation"]?.value)
                    : "--"
            }
            score={scoreCardData["bankRelation"]?.score}
        />
        <ScoreCardRow
            title={scoreCardData["presenceOfRegulatedFinancialSystem"]?.displayName}
            subTxt={
                scoreCardData["presenceOfRegulatedFinancialSystem"]?.value
                    ? masterDataStore?.getMasterDataDescription(
                        MASTER_DATA_KEYS.PRE_REG_FIN_SYS,
                        scoreCardData["presenceOfRegulatedFinancialSystem"]?.value
                    )
                    : "--"
            }
            score={scoreCardData["presenceOfRegulatedFinancialSystem"]?.score}
        />
    </>;

    if (view.length) {
        return <Stack>
            {
                view.map(section => section.key === "overallScore" ? <></> : <Stack>
                    <Stack p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5">
                        <div className="basic-font basic-font-color-bold font-size-16 align-left">{section.label}</div>
                    </Stack>
                    {
                        section.children.map(child => <ScoreCardRow
                            title={child.label}
                            subTxt={child.caption}
                            score={child.score}
                        />)
                    }
                </Stack>)
            }
        </Stack>;
    }

    return <FallbackLayout />;
}


export default RetailScoreCardLayout