import React, { useEffect } from "react";

import { Box, Grid, Stack } from "@mui/material";
import { getCreditAuditList } from "../../../../../services/creditFileApiCall";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

import { Downgraded, useState } from "@hookstate/core";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import PersonIcon from "@mui/icons-material/Person";
import {
  appSetting,
  creditData,
  userDetails,
} from "../../../../../configs/mainStore";
import { getUserDetailsById } from "../../../../../services/userService";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { IconRowComponent } from "../IconRowComponent";

import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

const CreditAudit = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const appSettings: any = useState(appSetting);

  const [isLoading, setIsLoading] = React.useState(true);
  const [creditAuditItemList, setCreditAuditItemList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isChanged, setIsChanged] = React.useState(false);

  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData?.disbursed;

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getCreditAuditList(applicationId);

      if (data) {
        setCreditAuditItemList(data);
      } else {
        setErrorMessage("Something went Wrong!");
      }
      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const checkPrivilegeStatus = () => {
    let isDisabled = true
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee
    }
    return isDisabled
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
        disabled={checkPrivilegeStatus()}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent
              headMessage={"Error!"}
              errorMessage={errorMessage}
            />
          ) : creditAuditItemList?.length > 0 ? (
            creditAuditItemList?.map((item, i) => {
              return (
                <Box key={i}>
                  <Grid
                    className="row_list_item"
                    style={{
                      backgroundColor: `#1976d23d`,
                    }}
                    container
                    direction="column"
                    p={2}
                  >
                    <Grid container item direction="row" xs={12}>
                      <Grid container item sm={3} style={{ textAlign: "left" }}>
                        <IconRowComponent
                          Icon={PersonIcon}
                          subTxt={"Authorized By"}
                          mainTxt={
                            item?.authorizedBy
                              ? getUserDetailsById(item?.authorizedBy)[
                                appSettings.get().language
                              ]?.name
                              : "--"
                          }
                          color={"#1976d2"}
                        />
                      </Grid>
                      <Grid container item sm={4} style={{ textAlign: "left" }}>
                        <IconRowComponent
                          Icon={InsertInvitationIcon}
                          subTxt={"Authorized Date"}
                          mainTxt={
                            item?.authorizedDate
                              ? dateFormatWithTimeChange(item?.authorizedDate)
                              : "--"
                          }
                          color={"#1976d2"}
                        />
                      </Grid>
                      <Grid container item sm={5} style={{ textAlign: "left" }}>
                        <IconRowComponent
                          Icon={ChatBubbleIcon}
                          subTxt={"Comment"}
                          mainTxt={item?.comment ? item?.comment : "--"}
                          color={"#1976d2"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          ) : (
            <ErrorMessageComponent
              headMessage={"No data available"}
              errorMessage={""}
            />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default CreditAudit;
