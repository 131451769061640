import { Stack, Typography } from "@mui/material";
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  message?: String;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };
  err?: Error;
  errInfo?: ErrorInfo;

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.err = error;
    this.errInfo = errorInfo;
  }

  Mailto = ({ email, subject, body, children }) => {
    return <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}&body=${encodeURIComponent(body) || ""}`}>{children}</a>;
  };

  private mailBody() {
    let message: any = {};
    if (this.err) {
      message.error = this.err.message;
    }
    if (this.errInfo) {
      message.info = this.errInfo.componentStack;
    }
    return JSON.stringify(message);
  }

  public render() {
    if (this.state.hasError) {
      if (this.props?.message) {
        return (
          <Stack height={'100vh'} p={2} direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Typography>{this.props?.message}</Typography>
            <Stack direction={"row"} spacing={4} mt={2}>
              <Typography onClick={() => location.reload()} variant="caption" style={{ color: "blue", cursor: "pointer" }}>
                Retry
              </Typography>
              <Typography onClick={() => window.open(`mailto:creditsupport@xgengroup.com.au?subject=System%20Failure&body=${this.mailBody()}`)} variant="caption" style={{ color: "blue", cursor: "pointer" }}>
                Contact System Admin
              </Typography>
            </Stack>
          </Stack>
        );
      }
      return <h1>Sorry.. there was an error</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
