import { createState } from "@hookstate/core";
import { FinancialPositionOfTheCompanyFinalObjectState, abilityToRepayLoanDefaultDataState, comprehensiveProfitAndLoanAccountsState, generalizedProfitAndLoanAccountsState, profitAndLossAccountsTypeKey, purchaseInformationState, salesandPurchaseInformationState, viewModeState } from "./MicroAppraisalDefaultData";

const profitAndLossAccountsDefault = {
  revenueIncome: 0,
  deductionsConstOfGoods: 0,
  grossProfit: 0,
  employeeExpenses: 0,
  professionalServicesExpenses: 0,
  bankingFinanceExpenses: 0,
  generalBusinessExpenses: 0,
  vehicleExpnses: 0,
  netProfitBeforeTax: 0,
  taxes: 0,
  netProfitAfterTax: 0,
};

/*____________________________________________________GLOBAL STATE USING CREATE HOOKSTATE________________________________________________ */

export const comprehensiveProfitAndLoanAccountsData = createState(comprehensiveProfitAndLoanAccountsState);
export const profitAndLossAccountsTypeState = createState(profitAndLossAccountsTypeKey);
export const financialPositionOfTheCompanyFinalObject = createState(FinancialPositionOfTheCompanyFinalObjectState);
export const abilityToRepayLoanData = createState(abilityToRepayLoanDefaultDataState);
export const generalizedProfitAndLoanAccountsData = createState(generalizedProfitAndLoanAccountsState);
export const salesandPurchaseInformationData = createState(salesandPurchaseInformationState);
export const purchaseInformationStateData = createState(purchaseInformationState);
export const viewMode = createState(viewModeState);
