import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl } from "@mui/material";
import React from "react";
import { SelectComponent } from "../../../components/InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { addToaster } from "../../../components/OtherComponents/GlobalToast";
import { workflowUpdatedData } from "../../../configs/stores/workflow-stores/workflowStore";
import { OnDemandValueFormatter } from "../../../services/onDemandValidators";
import { addNewTab } from "../../../services/workflowApiCalls";

const CreateTab = ({ openAddTab, setOpenAddTab, workflowData }) => {
  const tab: any = useHookstate({
    tabKey: "",
    startStage: "",
    stopStage: "",
    workflowId: workflowData.value.id,
  });

  const [updating, setUpdating]: any = React.useState(false);

  const workflowUpdatedDataStateData: any = workflowUpdatedData.attach(Downgraded).get();

  const saveNewTab = async () => {
    let dataPayload = tab.attach(Downgraded).get();
    dataPayload["startStage"] = parseInt(dataPayload.startStage);
    dataPayload["stopStage"] = parseInt(dataPayload.stopStage);

    let alertPayload = {
      status: "success",
      title: "Saved",
      message: `Tab successfully saved !`,
    };

    try {
      setUpdating(true);
      const result = await addNewTab(dataPayload);
    } catch (error) {
      alertPayload = {
        status: "error",
        title: "Error",
        message: "Something went wrong in updating privileges !",
      };
    } finally {
      addToaster(alertPayload);
      setUpdating(false);
    }
    return true;
  };

  let emptyKeys = Object.keys(openAddTab ?? {}).length === 0;

  return (
    <Dialog open={openAddTab !== false} onClose={() => setOpenAddTab(false)}>
      <DialogTitle>{emptyKeys ? "Add " : "Update "} New Tab </DialogTitle>
      <DialogContent>
        <DialogContentText color={"gray"} fontWeight={200}>
          set tab key, start stage, end stage and preview stage
        </DialogContentText>
        <Box marginTop={5} />
        <TextBoxComponent label={"Tab Key"} defaultValue="" value={tab.tabKey.get()} fullWidth={true} onInput={OnDemandValueFormatter.alphabeticalUnderscore} onChange={(e) => tab.set({ ...tab.get(), tabKey: `${e.target.value.toLowerCase()}` })} required={true} />
        <Box marginTop={2} />
        <FormControl
          style={{
            minWidth: 170,
            marginRight: 3,
            width: 425,
          }}
        >
          <SelectComponent
            values={workflowUpdatedDataStateData?.wf_tracked_stages}
            defaultValue=""
            value={tab.startStage.get()}
            label={"Start Stage"}
            required={true}
            onChange={(e) => {
              tab.set({ ...tab.get(), startStage: `${e.target.value}` });
            }}
          />
        </FormControl>
        <Box marginTop={2} />
        <FormControl
          style={{
            minWidth: 170,
            marginRight: 3,
            width: 425,
          }}
        >
          <SelectComponent
            values={workflowUpdatedDataStateData?.wf_tracked_stages}
            value={tab.stopStage.get()}
            defaultValue=""
            label={"Freeze Stage"}
            required={true}
            onChange={(e) => {
              tab.set({ ...tab.get(), stopStage: `${e.target.value}` });
            }}
          />
        </FormControl>
        <Box marginTop={2} />
      </DialogContent>
      <DialogActions>
        <Button disabled={updating} onClick={() => setOpenAddTab(false)}>
          Cancel
        </Button>
        <Button
          disabled={updating}
          onClick={async () => {
            await saveNewTab();
            setOpenAddTab(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTab;
