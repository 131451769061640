import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ERROR_CODES } from "../../../../../configs/constants/errorCode";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { getApplicationTimelineApi } from "../../../../../services/creditFileApiCall";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

import { Downgraded, useState } from "@hookstate/core";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { applicationTimelineReferActionTypes } from "../../../../../configs/constants/applicationTimelineReferActionTypes";

import NoteIcon from "@mui/icons-material/Note";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { grey } from "@mui/material/colors";
import { dateFormatWithDate } from "../../../../../utility/fomatters";

const WorkflowHistory = ({ data, applicationId, currentApplicationWfData, innerComponentData }) => {
  const { wf_system_users }: any = useState(workflowMasterData);
  const [isLoading, setIsLoading] = React.useState(false);

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  const [list, setList] = React.useState<any>({});

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      let responseData = {};
      const params = {
        applicationId: applicationId,
      };
      const response = await getApplicationTimelineApi(params);

      if (response?.status === ERROR_CODES.success) {
        responseData = Object.values(response?.data?.data).map((obj?: any, i?: any) => {
          let assigneeUsers = wf_system_users?.get()?.find((usr) => usr?.id == obj?.ASSIGNEE);
          let actionedbyUsers = wf_system_users?.get()?.find((usr) => usr?.id == obj?.ACTIONED_BY);
          return {
            assigneeUsers: assigneeUsers?.fullName,
            actionedbyUsers: actionedbyUsers?.fullName,
            date: obj?.ACTION_START_AT,
            note: obj?.ACTION_NOTE,
            actionType: obj?.ACTION_TYPE,
          };
        });
      }

      setList(responseData);
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="full-width full-height">
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent size={30} sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", zIndex: 100 }} />
          ) : (
            <Grid container className="full-width border-radius-5" alignItems="center" p={1} style={{ background: "#f8f9fa" }}>
              {list?.length > 0 ? (
                <Timeline position="alternate">
                  {list.map((obj?: any, index?: any) => {
                    const isEvenItem = index % 2 === 0;
                    return (
                      <TimelineItem key={obj.id}>
                        <TimelineOppositeContent color="text.secondary" sx={{ fontStyle: "italic", margin: "auto 0" }}>
                          <Typography>{dateFormatWithDate(new Date(obj?.date).toLocaleDateString())}</Typography>
                          <Typography sx={{ fontWeight: 800 }}>{new Date(obj?.date).toLocaleTimeString()}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent style={!isEvenItem ? { display: "flex", justifyContent: "flex-end" } : {}}>
                          <Card
                            elevation={5}
                            sx={{
                              maxWidth: "75%",
                              width: "75%",
                              display: "flex",
                              justifyContent: "flex-start",
                              textAlign: "left",
                              borderRadius: "10px",
                            }}
                          >
                            <CardContent>
                              <Typography sx={{ color: grey[500] }}>{applicationTimelineReferActionTypes[obj?.actionType as keyof typeof applicationTimelineReferActionTypes]}</Typography>
                              <Box pl={3} pr={3} mt={3} sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                                <Stack direction="row" spacing={2}>
                                  <PersonIcon color="success" />
                                  <Typography sx={{ color: grey[500] }}>{obj?.actionedbyUsers}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                  <NoteIcon color="success" />
                                  <Typography sx={{ color: grey[500] }}>{obj?.note}</Typography>
                                </Stack>
                                {obj?.actionType !== "" && (
                                  <Stack direction="row" spacing={2}>
                                    <PersonAddIcon color="success" />
                                    <Typography sx={{ color: grey[500] }}>{obj?.assigneeUsers}</Typography>
                                  </Stack>
                                )}
                              </Box>
                            </CardContent>
                          </Card>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              ) : (
                <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
              )}
            </Grid>
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default WorkflowHistory;
