import axios from "axios";
import { addToaster, Toast } from "../components/OtherComponents/GlobalToast";
import appStrings from "../configs/constants/appStrings";
import { FORBIDDEN_CODE, MISSING_PRIVILEGE_CODE, NO_ACCESS_CODE } from "../configs/constants/contants";
import { generateHeaders } from "../utility/helpers/generateHeaders";
import { userSessionDelete } from "./sessionService";

export default class Api {
  private static instance: Api | null = null;
  private static token: any = null;
  private static stateHandler: any = null;
  triggered = false;

  private Api() { }

  /**
   * Headers objects with authentication token
   */
  static headers: any = {};

  /**
   * Api class instance getter
   * @_Note - Use this method to get the current instance of the Api class from where you want
   */
  public static getApi() {
    if (!Api.instance) Api.instance = new Api();
    return Api.instance;
  }

  /**
   *  Api class auth token and state handler method setter for current instance
   *  @_Important - This method should be only calls at useSetApiSettings.ts file
   */
  public static createApi(token, stateHandler) {
    this.token = token && token;
    this.stateHandler = stateHandler && stateHandler;
    this.headers = token && generateHeaders(token);
  }

  //----------------------------- API CALL METHODS --------------------------------

  /**
   * GET METHOD
   * @param endpoint = end point of the api
   * @param params  = parameters for pass with get call
   * @param headerType = header type of the api call ( html / json / formData)
   * @returns = api call response
   */

  async get(
    endpoint: any,
    params?: any,
    headerType: any = appStrings?.formObjects?.json,
    progress: Function | null = null,
    cached = false
  ) {
    try {
      let cachedPoint: any = null;
      if (cached && endpoint.includes("/")) {
        cachedPoint = endpoint.split("/");
        cachedPoint = cachedPoint[cachedPoint.length - 1];
        if (cachedPoint.includes("?")) {
          cachedPoint = cachedPoint.split("?")[0];
        }

        if (sessionStorage.getItem(cachedPoint)) {
          try {
            return JSON.parse(sessionStorage.getItem(cachedPoint) ?? "{}");
          } catch (error) {

          }
        }
      }
      const { data: response } = await axios.get(endpoint, {
        headers: Api.headers?.[headerType],
        params: params,
        onDownloadProgress:
          progress != null
            ? (progressEvent) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              progress(percentCompleted);
            }
            : undefined,
      });

      if (response?.status === 1 && cached) {
        sessionStorage.setItem(cachedPoint, JSON.stringify(response.data));
      }

      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  async getCached(
    endpoint: any,
    params?: any,
    headerType: any = appStrings?.formObjects?.json,
  ) {
    try {
      let cachedPoint: any = null;
      if (endpoint.includes("/")) {
        cachedPoint = endpoint.split("/");
        cachedPoint = cachedPoint[cachedPoint.length - 1];
        if (cachedPoint.includes("?")) {
          cachedPoint = cachedPoint.split("?")[0];
        }

        if (sessionStorage.getItem(cachedPoint)) {
          try {
            return JSON.parse(sessionStorage.getItem(cachedPoint) ?? "{}");
          } catch (error) {

          }
        }
      }
      const { data: response } = await axios.get(endpoint, {
        headers: Api.headers?.[headerType],
        params: params,
      });

      if (response?.status === 1) {
        sessionStorage.setItem(cachedPoint, JSON.stringify(response.data));
      }

      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  /**
   * POST METHOD
   * @param endpoint = end point of the api
   * @param params  = parameters for pass with get call
   * @param body  = data object to sent as body of the post api call
   * @param headerType = header type of the api call ( html / json / formData)
   * @returns = api call response
   */

  async post(endpoint: any, body: any, headerType: any = appStrings?.formObjects?.json) {
    try {
      const { data: response } = await axios.post(endpoint, body, {
        headers: Api.headers?.[headerType],
      });
      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  /**
   * PUT METHOD
   * @param endpoint = end point of the api
   * @param params  = parameters for pass with get call
   * @param body  = data object to sent as body of the post api call
   * @param headerType = header type of the api call ( html / json / formData)
   * @returns = api call response
   */

  async put(endpoint: any, body: any, headerType: any = appStrings?.formObjects?.json) {
    try {
      const { data: response } = await axios.put(endpoint, body, {
        headers: Api.headers?.[headerType],
      });
      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  /**
   * DELETE METHOD
   * @param endpoint = end point of the api
   * @param params  = parameters for pass with get call
   * @param headerType = header type of the api call ( html / json / formData)
   * @returns = api call response
   */

  async delete(endpoint: any, params?: any, headerType: any = appStrings?.formObjects?.json) {
    try {
      const { data: response } = await axios.delete(endpoint, {
        headers: Api.headers?.[headerType],
        params: params,
      });
      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  /**
   * PATCH METHOD
   * @param endpoint = end point of the api
   * @param params  = parameters for pass with get call
   * @param body  = data object to sent as body of the post api call
   * @param headerType = header type of the api call ( html / json / formData)
   * @returns = api call response
   */

  async patch(endpoint: any, body: any, headerType: any = appStrings?.formObjects?.json) {
    try {
      const { data: response } = await axios.patch(endpoint, body, {
        headers: Api.headers?.[headerType],
      });
      return response;
    } catch (error: any) {
      this.errorHandle(error);
    }
  }

  errorHandle = (error) => {
    const { request: { status = FORBIDDEN_CODE } = {}, message } = error;

    if (status === NO_ACCESS_CODE) {
      if (this.triggered) {
        return;
      }

      Toast.error("Access expired. Please contact the system admin.", "Error");
      this.triggered = true;
      return;
    }

    if (error?.response) {
      if (error.response.status === FORBIDDEN_CODE) {
        userSessionDelete();
      } else if (error.response.status === MISSING_PRIVILEGE_CODE) {
        if (sessionStorage.getItem("missingPrivilegeAlert") !== "popped") {
          sessionStorage.setItem("missingPrivilegeAlert", "popped");
          alert("Required privileges missing ! \n\nYou do not have the necessary privileges to perform this action. Please contact your system administrator or the relevant authority to request the required permissions. If you believe this is an error, please check your account settings or log in with an account that has the necessary privileges. \n\nFor further assistance, you can reach out to our support team at creditsupport@xgengroup.com.au.");
        }
        userSessionDelete();
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went wrong !",
        });
      }
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went wrong !",
      });
    }
  };
}
