import { Stack } from "@mui/material";
import { Box } from "@mui/system";

interface ScreenTitleProps {
  title?: String;
  description?: String;
  subDescription?: String;
  children?: any;
}

export const ScreenTitle = ({ title, description, subDescription = "", children = [] }: ScreenTitleProps) => {
  return <Stack justifyContent={"space-between"} flex={1} direction={"row"} px={2} py={1}>
    <Stack direction={"column"} flex={1}>
      <Box className="screen_title--basic">{title}</Box>
      <Box className="screen_description--basic" mt={1}>
        <Stack direction={"row"} flex={1}>
          <Box>{description}</Box>
          <Box paddingX={10}>{subDescription}</Box>
        </Stack>
      </Box>
    </Stack>
    <Stack>
      {children}
    </Stack>
  </Stack>;
};
