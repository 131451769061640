import { Downgraded } from "@hookstate/core";
import moment from "moment";
import { MASTER_DATA_KEYS as MD_KEYS } from '../../../../../../../../../../configs/constants/masterDataKeys';
import { userDetails } from "../../../../../../../../../../configs/mainStore";
import MasterDataProvider from "../../../../../../../../../../utility/MasterDataProvider";
import { getCopy } from "../../../../../../../../../../utility/util";
import { AssetOutPutDataObject, createAssetDataObject, CreateObjectOutPut, DataOfChildren } from "../../../FinancialPositionOfTheCompany/Helpers/SetStateFromBusinessData/BalanceSheet";

interface AnalysisOfLoanRequirementDefaultData {
    description: string;
    existingEquality: number;
    existingBank: number;
    proposedEquality: number;
    proposedBank: number;
    totalCost: number;
    existingEqualityContribution: number;
    existingBankContribution: number;
    proposedEqualityContribution: number;
    proposedBankContribution: number;
}

const defaultData: AnalysisOfLoanRequirementDefaultData = {
    description: "",
    existingEquality: 0,
    existingBank: 0,
    proposedEquality: 0,
    proposedBank: 0,
    totalCost: 0,
    existingEqualityContribution: 0,
    existingBankContribution: 0,
    proposedEqualityContribution: 0,
    proposedBankContribution: 0,
}

export interface RetuenedArray {
    data: AnalysisOfLoanRequirementDefaultData;
    createdBy: number;
    id: number;
}

export const setStateFromBusinessDataAnalysisOfLoanRequirement = ({ busineeData }): Record<number, RetuenedArray> => {
    const { totaleBusinessAssertOtherDetails } = busineeData;
    const masterDataStore = MasterDataProvider.provideMasterData();
    const assetData = { masterDataMap: MD_KEYS.ASSETS_CATEGORY, subMasterDataMap: MD_KEYS.ASSET_TYPE }
    const getDesc = masterDataStore.getMasterDataDescription;
    const { userId }: { userId: string } = userDetails.attach(Downgraded).get();

    let assetDataObject: CreateObjectOutPut | AssetOutPutDataObject = createAssetDataObject({ data: totaleBusinessAssertOtherDetails });

    const thisYear: number = moment().year();
    const followingYear: number = thisYear + 1;

    let dataIndex = 0;

    const retuenedArray: Record<number, RetuenedArray> = Object.entries(assetDataObject).reduce((retuenedArray: Record<number, RetuenedArray>, [key, currItem]: [string, DataOfChildren], index) => {

        const defaultDataCopy: AnalysisOfLoanRequirementDefaultData = getCopy(defaultData);

        if (!(currItem.data.hasOwnProperty(thisYear) || currItem.data.hasOwnProperty(followingYear))) return retuenedArray;

        let [category, type]: string[] = key.split(".");

        category = getDesc(assetData.masterDataMap, category);
        type = getDesc(assetData.subMasterDataMap, type);

        defaultDataCopy.description = `${category} - ${type}`;

        const { data } = currItem;

        defaultDataCopy.existingEquality = Number(data?.[thisYear] || 0);
        defaultDataCopy.proposedEquality = Number(data?.[followingYear] || 0);

        retuenedArray = { ...retuenedArray, [dataIndex]: { id: dataIndex, data: defaultDataCopy, createdBy: Number(userId) } };

        dataIndex += 1;

        return retuenedArray;
    }, {});

    return retuenedArray;
}