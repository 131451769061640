import { Dialog } from "@mui/material";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";
import {
  DialogBasePropsTypes,
  DialogActionPropsTypes,
  DialogContentPropsTypes,
  DialogTitlePropsTypes,
} from "../../../configs/interfaces";

//DIALOG COMPONENT
export const DialogComponent: FC<DialogBasePropsTypes> = ({
  open,
  ariaDescribedby,
  ariaLabelledby,
  BackdropComponent,
  children,
  classes,
  disableEscapeKeyDown,
  fullScreen,
  fullWidth,
  maxWidth,
  onBackdropClick,
  onClose,
  PaperComponent,
  PaperProps,
  scroll,
  sx,
  TransitionComponent,
  transitionDuration,
  TransitionProps,
}) => {
  return (
    <Dialog
      open={open}
      aria-describedby={ariaDescribedby}
      aria-labelledby={ariaLabelledby}
      BackdropComponent={BackdropComponent}
      children={children}
      classes={classes}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      PaperComponent={PaperComponent}
      PaperProps={PaperProps}
      scroll={scroll}
      //   sx={sx}
      TransitionComponent={TransitionComponent}
      transitionDuration={transitionDuration}
      TransitionProps={TransitionProps}
    />
  );
};

//DIALOG ACTIONS COMPONENT
export const DialogActionsComponent: FC<DialogActionPropsTypes> = ({
  children,
  classes,
  disableSpacing,
  sx,
}) => {
  return (
    <DialogActions
      children={children}
      classes={classes}
      disableSpacing={disableSpacing}
      sx={sx}
    />
  );
};

//DIALOG CONTENT COMPONENT
export const DialogContentComponent: FC<DialogContentPropsTypes> = ({
  children,
  classes,
  dividers,
  sx,
}) => {
  return (
    <DialogContent
      children={children}
      classes={classes}
      dividers={dividers}
      sx={sx}
    />
  );
};

//DIALOG CONTENTTEXT COMPONENT
export const DialogContentTextComponent: FC<DialogTitlePropsTypes> = ({
  children,
  classes,
  sx,
}) => {
  return <DialogContentText children={children} classes={classes} sx={sx} />;
};

//DIALOG TITLE COMPONENT
export const DialogTitleComponent: FC<DialogTitlePropsTypes> = ({
  children,
  classes,
  sx,
}) => {
  return <DialogTitle children={children} classes={classes} sx={sx} />;
};
