export const applicationTimelineReferActionTypes = {
  ASSIGN_TO_RISK_USER: "Assign to Risk User",
  ASSIGN_TO_LEGAL_USER: "Assign to Legal User",
  OPINION_ADDED: "Opinion Added",
  OPINION_APPROVED: "Opinion Approved",
  ASSIGN_TO_CAU_HEAD: "Assign to CAU Head",
  ASSIGN_TO_CAU_MANAGER: "Assign to CAU Manager",
  ADD_TASK: "Add Task",
  WORKFLOW_CHANGE: "Workflow Change",
  CONFIGURATION_CHANGE: "Configuration Change",
};
