import { inspectionDeductionsTypes } from "../../../../../../../utility/util";

export const filterAutoPopulatedKeys = (array) => {

    return array.filter((item) => {
        if (item.type !== "autoPopulated") return item.dataKey;
    });
};


export const profitAndLossAccountsType = [
    {
        id: 'generalized',
        name: "Generalized"
    },

    {
        id: 'comprehensive',
        name: "Comprehensive"
    },
];


export const doesTheApplicantHasEnoughFinancialInformation = [
    {
        id: 1,
        name: "Reliable Financial information available",
    },

    {
        id: 2,
        name: "New borrower - Forecast financials available",
    },

    {
        id: 3,
        name: "Financial information not available to Bank's satisfaction",
    },

];

export const revenueAndIncome = [
    {
        id: "1",
        name: "Income from Sales"
    },
    {
        id: "2",
        name: "Adjustments to Earnings"
    },
    {
        id: "3",
        name: "Sales of Assets"
    },
    {
        id: "4",
        name: "Gains from Legal Actions"
    },
    {
        id: "5",
        name: "Interest Received"
    },
    {
        id: "6",
        name: "Other"
    }
];

export const reductions = [...inspectionDeductionsTypes];


export const employeeExpenses = [];
export const generalBusinessExpenses = [];
export const vehicleExpenses = [];
export const bankingAndFinanceExpenses = [];
export const professionalServiceExpenseDD = [];
export const decsOfTaxes = [];

export const inspectionExpensesMap = {
    employeeAndLabourExpenses: [10, 11, 12, 13, 14, 15, 16, 17],
    generalBusinessExpenses: [3, 4, 7, 8, 26, 27, 28, 6, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,],
    professionalServiceExpense: [1, 18, 19, 20],
    bankingAndFinanceExpenses: [2, 21, 22, 24, 25],
    vehicleExpenses: [5, 9, 23, 29]
}




