import { Divider, Grid, Stack } from "@mui/material";
import { DisbursementAPICheck } from "./DisbursementAPICheck";
import { FraudCheck } from "./FraudCheck";
import { UnderwritingPolicyCheck } from "./UnderwritingPolicyCheck";

export const ActiveDecisions: React.FC = () => {
  return (
    <Grid className="inner-component-height" style={{ height: "calc(100vh - 80px)" }}>
      <Stack>
        <FraudCheck />
        <Divider />
        <UnderwritingPolicyCheck />
        <Divider />
        <DisbursementAPICheck />
      </Stack>
    </Grid>
  );
};
