import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fundReleaseStatusChipColors } from "../../../WorkflowComponents/utils";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { IconRowComponent } from "../IconRowComponent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useState } from "@hookstate/core";
import { getUserDetailsById } from "../../../../../services/userService";
import PersonIcon from "@mui/icons-material/Person";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { makeStyles, Paper, Typography } from "@mui/material";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { positions } from "@mui/system";
import { Padding } from "@mui/icons-material";

function TableHeaderCell(props) {
    return (
        <TableCell>
            <Typography
                style={{
                    color: "#747a88",
                    fontSize: 14,
                    opacity: 0.7
                }}
            >
                {props.value}
            </Typography>
        </TableCell>

    );
}

function TableDataCell(props) {
    return (
        <TableCell>
            <Typography style={{ color: "#747a88", fontSize: 14 }}>
                {props.value}
            </Typography>
        </TableCell>


    );
}

export default function FundReleaseApprovalTable(props) {
    const masterDataStore = MasterDataProvider.provideMasterData();
    const appSettings = props.appSettings;

    return (
        <TableContainer>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableHeaderCell value="Comment" />
                        <TableHeaderCell value="Actioned By" />
                        <TableHeaderCell value="Date" />
                        <TableHeaderCell value="Status" />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.fundReleaseItemList?.map((item, i) => {
                        return (
                            <TableRow>
                                <TableDataCell value={item?.comment} />
                                <TableDataCell
                                    value={
                                        item?.approvedBy
                                            ? getUserDetailsById(item?.approvedBy)[
                                                appSettings.get().language
                                            ]?.name
                                            : "--"
                                    }
                                />
                                <TableDataCell
                                    value={
                                        item?.approvedDate
                                            ? dateFormatWithTimeChange(item?.approvedDate)
                                            : "--"
                                    }
                                />
                                <TableDataCell
                                    value={
                                        <ChipsComponent
                                            label={
                                                item?.status
                                                    ? masterDataStore?.getMasterDataDescription(
                                                        MASTER_DATA_KEYS?.FUND_APPROVAL_STATUS,
                                                        item?.status
                                                    )
                                                    : "-"
                                            }
                                            color={
                                                fundReleaseStatusChipColors[item?.status]?.color
                                                    ? fundReleaseStatusChipColors[item?.status]?.color
                                                    : fundReleaseStatusChipColors["default"]?.color
                                            }
                                        />
                                    }
                                />
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}