export const FILE_UPLOAD_COMPONENT_STATUS = {
  noFile: 'NO_FILE',
  pending: 'PENDING',
  submitted: 'SUBMITTED',
};

export const FILE_STATUS: any = {
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
};
