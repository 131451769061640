import {Table, TableCell, TableContainer, TableRow} from "@mui/material";
import React from "react";
import IntermediateFormBuilder from "../../../IntermediateFormBuilder";
import ChildBuilder from "../../Builders/ChildrenBuilderMaster";

export const TableContainerBuilder = ({value, onChange, metaData, key}) => {
    return (
        <TableContainer key={"TableContainer" + key}>
            <Table key={"Table" + key}>
                {React.createElement(IntermediateFormBuilder, {
                    formChildren: metaData?.children,
                    value,
                    onChange,
                    key: "createdElement" + key
                })}
            </Table>
        </TableContainer>
    );
};

export const TableRowBuilder = ({value, onChange, metaData, key}) => {
    return (
        <TableRow key={"TableRow" + key}>
            {metaData?.children.map((element: any, index: number) => (
                <TableCell key={"TableCell" + index + element?.dataKey} padding={"normal"}>
                    <ChildBuilder formJsonData={element} value={value} onChange={onChange} isFromTbCell={true} key={element?.dataKey}/>
                </TableCell>
            ))}
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
};
