import { Downgraded } from '@hookstate/core';
import { Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { creditData } from '../../../../../configs/mainStore';
import {
  getConditionList
} from '../../../../../services/creditFileApiCall';
import { compare } from '../../../../../utility/other';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { ErrorMessageComponent } from '../../../ErrorMessageComponent';
import { openGlobalModal } from '../../../GlobalModal/GlobalModal';
import { CircularProgressComponent } from '../../../ProgressComponent/ProgressComponent';
import InnerSectionHeaderComponent from '../InnerSectionHeaderComponent';
import ConditionItemRow from './AgreemnetConditionItemRow';

import { hasPrivilegeAccess } from '../../../../../utility/helpers/creditFileUtility';

const AgreementCondition = ({ applicationId, currentApplicationWfData, innerComponentData }) => {
  const [agreementConditionListNew, setAgreementConditionListNew]: any = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const creditFileData: any = creditData.attach(Downgraded).get();

  useEffect(() => {
    initializeDataSource();
    return () => {
      sessionStorage.addedApprovingConditions = null;
    };
  }, []);

  const initializeDataSource = async () => {
    if (!applicationId) {
      return;
    }
    setIsLoading(true);
    const conditions = await getConditionList(applicationId, null, "Agreement Special Conditions");

    if (conditions.length) {
      let sortedList = conditions?.sort((a, b) => compare(b, a, 'id')) || [];
      setAgreementConditionListNew(sortedList);
    }

    setIsLoading(false);
  }

  const callBackOnAction = (newConditions) => {
    const conditions = JSON.parse(JSON.stringify(agreementConditionListNew));
    const sortedList = [...conditions, newConditions]?.sort((a, b) => compare(b, a, 'id'));

    setAgreementConditionListNew(sortedList);
  };

  const openCustomConditions = (modalBodyKey) => {
    openGlobalModal({
      modalSize: 'lg',
      title: '',
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        ...currentApplicationWfData,
      },
    });
  };

  const actionDelete = async (response) => {
    initializeDataSource();
  };

  const BaseContent = ({ children }) => {
    return <Stack>
      <Stack style={{ position: 'sticky', zIndex: 100, top: '0px' }}>
        <InnerSectionHeaderComponent
          innerComponentData={innerComponentData}
          applicationId={applicationId}
          currentApplicationWfData={currentApplicationWfData}
          callBackOnAction={callBackOnAction}
          modalSize="lg"
          disabled={!hasPrivilegeAccess()}
        >
          <ButtonComponent
            title={'Add New Conditions'}
            variant="contained"
            onClick={() => openCustomConditions('agreement-condition-modal')}
            disabled={!hasPrivilegeAccess()}
          />
          <Stack ml={2}></Stack>
        </InnerSectionHeaderComponent>
      </Stack>
      {children}
    </Stack>;
  }

  if (isLoading) {
    return <BaseContent>
      <CircularProgressComponent
        size={30}
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 100,
        }}
      />
    </BaseContent>;
  }

  if (!agreementConditionListNew?.length) {
    return <BaseContent><ErrorMessageComponent headMessage={'Data Not Available'} errorMessage={''} /></BaseContent>;
  }

  return <BaseContent>
    {agreementConditionListNew?.map((data: any, index) => (
      <Paper sx={{ marginBottom: 1, marginTop: 1 }}>
        <ConditionItemRow
          data={data}
          key={index}
          applicationId={applicationId}
          currentApplicationWfData={currentApplicationWfData}
          innerComponentData={innerComponentData}
          creditFileData={creditFileData}
          actionDelete={actionDelete}
        />
      </Paper>
    ))}
  </BaseContent>;
};

export default AgreementCondition;
