import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { creditData, formStructureSet, selectedApplicationReferenceToEditState, userDetails } from "../../../../../configs/mainStore";
import { fdAgainstLoansDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { saveAppFormDataJson } from "../../../../../services/apiCalls";
import { resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { FdAgainstLoans } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/FDAgainstLoans/FDAgainstLoans";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import { collateralSectionDeletion } from "./collateral-utils/Utility";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditFdAgainstLoans = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, tabsToEdit, innerComponentData }) => {
  const { t: translate } = useTranslation();
  const [loanArr, setLoanArr]: any = React.useState([]);
  const [fullObj, setFullObj]: any = React.useState({});
  const [count, setCount]: any = React.useState(1);
  const [loading, setLoading]: any = React.useState(false);
  const requestId = data?.requestId;
  const [fields, setFields]: any = React.useState({});
  const existingData = useState<Array<any>>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);
  let itemIndex = 0;
  const creditDetailsState: any = useState(creditData);
  const { paperDocs } = creditDetailsState?.formData?.securityData;
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();
  const exisitingObject = data?.formData?.securityData?.paperDocs?.fdAgainstLoans ?? [];

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const selectedApplicationReferenceId = useState(selectedApplicationReferenceToEditState);
  const appFormSet = useState(formStructureSet);
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const handleGetApplicationData = async () => {
    await getApplicationForm();
  };

  useEffect(() => {
    setFetching(true);
    handleGetApplicationData()
      .then(() => {
        setExistingData();
      })
      .then(() => {
        setFullObj(JSON.parse(JSON.stringify(data?.formData?.securityData?.paperDocs ?? {})));
      });
    setFetching(false);
  }, []);

  const getApplicationForm = async () => {
    const key = "fdAgainstLoans";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const setExistingData = () => {
    if (exisitingObject) {
      existingData.set(JSON.parse(JSON.stringify(exisitingObject)));
    }
  };

  const FD_AGAINST_LOAN_MANDATORY = [...getMandatoryFields(fields)];

  const handleFieldsAdding = () => {
    let fdAgainstLoans = {
      itemIndex: 0,
      entryId: 0,
      ...fdAgainstLoansDefault,
    };

    try {
      if (existingData.get()?.length > 0) {
        fdAgainstLoans["itemIndex"] = existingData.get()?.length;
        fdAgainstLoans["entryId"] = existingData.get()?.length;
      }
      const tempArray = existingData.get() ? [...existingData.get(), fdAgainstLoans] : [fdAgainstLoans];
      existingData.set(JSON.parse(JSON.stringify(tempArray)));
    } catch (error) { }
  };

  const handleSave = async (props) => {
    setLoading(true);
    let mandatoryRes: any[] = [];
    mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), FD_AGAINST_LOAN_MANDATORY);
    const finalArr: any = [];

    if ("fdAgainstLoans" in fullObj) {
      delete fullObj.fdAgainstLoans;
    }

    let keyIndex = 0;

    for (let index = 0; index < existingData.get().length; index++) {
      const element = existingData[index].get();

      if (!element || element === null) continue;

      // if (element?.removedItem) continue;

      let obj = {
        type: "paperdocs_fdAgainstLoans",
        itemIndex: index,
        entryId: index,
        ...element,
      };
      keyIndex += 1;
      finalArr[finalArr.length] = obj;
    }

    let finalObj = {
      paperDocs: {
        fdAgainstLoans: finalArr,
        ...fullObj,
      },
    };

    let errorArray = collateralValidationErrorArray("paperDocs", "fdAgainstLoans", finalObj);

    let lastValidation: Array<any> = [];

    if (Object.values(props).length > 0) {
      lastValidation = [...errorArray, ...mandatoryRes];
    }

    if (mandatoryRes?.length > 0) {
      const errorMessage: string = lastValidation.join("\n");
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${errorMessage}`,
      });
      setLoading(false);
    } else {
      let data = JSON.parse(JSON.stringify(finalObj));
      let res = await saveAppFormDataJson(requestId, "securityData", data);

      if (res?.status === 1) {
        paperDocs.set({ ...data.paperDocs });
        setLoading(false);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Security Details Updated",
        });
      } else if (res?.error) {
        setLoading(false);
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update Error",
        });
      } else {
        setLoading(false);
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    }
  };

  return (
    <div className="full-width full-height" style={{ position: "relative" }}>
      {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
      {isMainContactDetails && (
        // <Header handleFieldsAdding={handleFieldsAdding} />
        <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"FD Against Loans"} />
      )}

      {/* Security Form */}
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {existingData.get()?.length > 0 ? (
            existingData?.map((doc, index) => {
              if (!doc.get()) return <></>;
              const elementKeys = Object.keys(doc);
              if (!elementKeys.includes("removedItem")) {
                itemIndex = itemIndex + 1;
                return (
                  <Box display="flex" alignItems="center" mb={1} key={`FdAgainstLoans_${index}`}>
                    <Box style={{ width: "100%" }}>
                      <FdAgainstLoans doc={doc} fields={fields} index={itemIndex} isNotNeedComponentTitle={true} />
                    </Box>
                    <Box>
                      <Box pb={3}>
                        <Tooltip title={"Reset"}>
                          <Box>
                            <ButtonComponent
                              iconbtn={true}
                              onClick={() => {
                                resetInputItems(doc, "paperDocs", "loanProtection");
                              }}
                              style={{ border: "1px solid gray" }}
                              size="small"
                            >
                              <RestartAltIcon />
                            </ButtonComponent>
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box pt={3}>
                        <Tooltip title={"Delete"}>
                          <Box>
                            <ButtonComponent iconbtn children={<DeleteIcon />} onClick={() => {
                              collateralSectionDeletion({ applicationId, collateralDataState: existingData, itemIndex: index, collateralKeyString: "paperDocs:fdAgainstLoans:Paper Docs FD Against Loans" })
                            }} />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                );
              }
            })
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};
