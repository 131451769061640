import { useEffect, useState } from "react";
import { productDetails } from "../../../configs/mainStore";
import { Downgraded, useHookstate } from "@hookstate/core";
import { Divider, Stack, Typography } from "@mui/material";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { ComponentMapper } from "./ComponentMapper";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";

export default (props) => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [values, setValues]: any = useState({ types: [], sectors: [] });
    const [typeSector, setTypeSector]: any = useState("");
    const [selections, setSelections]: any = useState([]);
    const store: any = useHookstate(productDetails);

    let schemes: any = store?.productSchemesMap?.attach(Downgraded)?.get() || [];

    useEffect(() => {
        loadMappingData();
    }, []);

    const loadMappingData = async () => {
        try {
            let { dataMapOriginal, } = props;
            if (dataMapOriginal) {
                dataMapOriginal = dataMapOriginal[props.currentConfigs.typeSector];
            }

            const idmap = {};
            let _typeSector = props.currentConfigs.typeSector;
            const prev = props.storedConfigs.map((item) => {
                const obj = {
                    name: item.scheme,
                    desc: item.id,
                    id: [item.typeSectorId, item.schemeId].join(".")
                };
                _typeSector = item.typeSector;
                idmap[obj.id] = obj;
                return obj;
            });

            const current = props.currentConfigs.schemes.map((item) => {
                const id = [props.currentConfigs.typeSector, item].join(".");

                if (idmap[id]) {
                    return null;
                }
                return {
                    name: schemes?.[item]?.TYPE_NAME ?? "N/A",
                    id
                };
            }).filter(item => item);

            const sels: any = [];
            const preItemIds = prev.map(item => item.id);
            for (const item of current) {
                const { id } = item;
                if (preItemIds.includes(id)) {
                    sels.push(id);
                }
            }

            setTypeSector(_typeSector);
            // setSelections(sels);
            setValues({ prev, current, dataMapOriginal });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleSelection = async (status, data) => {
        let values: any = [];
        if (selections.includes(data.id)) {
            values = selections.filter(item => item != data.id);
        } else {
            values = [data.id, ...selections];
        }

        setSelections(values);
    }

    const handleUnpublish = async () => {
        await props.unpublish(selections);
        closeGlobalModal();
    }

    const PreviousMapping = () => {
        return <Stack flex={1}>
            <Stack direction={"row"}>
                <Typography>
                    Previous Mapping - {typeSector}
                </Typography>
            </Stack>
            <Divider />
            <Stack>
                <ComponentMapper title={"mappings currently used in system"} items={values.prev} loading={loading} height={"calc(100vh - 450px)"} />;
            </Stack>
        </Stack>;
    }

    const handleConfirm = async () => {
        setSubmitting(true);
        await props.confirm(values.current);
        setSubmitting(false);
        closeGlobalModal();
    }

    const CurrentMapping = () => {
        return <Stack flex={1}>
            <Stack direction={"row"}>
                <Typography>
                    Current Mapping - {typeSector}
                </Typography>
            </Stack>
            <Divider />
            <Stack>
                <ComponentMapper items={values.current} loading={loading} height={"20vh - 10px"} />
                {values.current.length > 0 && <Stack direction={"row-reverse"}>
                    {submitting ? <CircularProgressComponent size={30} /> :
                        <ButtonComponent size={"small"} variant={"outlined"} title={"Confirm"} onClick={handleConfirm}></ButtonComponent>}
                </Stack>}
            </Stack>
        </Stack>;
    }

    const RemovedMapping = () => {
        let { dataMapOriginal, currentConfigs } = props;

        if (!currentConfigs || !currentConfigs.schemes) {
            return <></>;
        }

        dataMapOriginal = dataMapOriginal?.[currentConfigs?.typeSector ?? ""];
        const schemesList = currentConfigs.schemes;

        if (!dataMapOriginal) {
            return <></>;
        }

        const list: any = [];
        for (const item of dataMapOriginal) {
            if (!schemesList.includes(item)) {
                list.push(item);
            }
        }

        const current = list.map((item) => {
            const id = [currentConfigs?.typeSector ?? "", item].join(".");

            return {
                name: schemes?.[item]?.TYPE_NAME ?? "N/A",
                id
            };
        }).filter(item => item);

        return <Stack flex={1}>
            <Stack direction={"row"}>
                <Typography>
                    Removed Mapping - {typeSector}
                </Typography>
            </Stack>
            <Divider />
            <Stack>
                <ComponentMapper items={current} loading={loading} height={"20vh"} />
                {current.length > 0 && <Stack direction={"row-reverse"}>
                    {submitting ? <CircularProgressComponent size={30} /> :
                        <ButtonComponent size={"small"} variant={"outlined"} title={"Confirm"} onClick={handleConfirm}></ButtonComponent>}
                </Stack>}
            </Stack>
        </Stack>;
    }

    if (loading) {
        return <></>;
    }

    return <Stack>
        <Stack direction={"row"} flex={1} spacing={2}>
            <PreviousMapping />
            <Stack borderRight={"solid 1px lightGray"}></Stack>
            <Stack flex={1}>
                <CurrentMapping />
                <RemovedMapping />
            </Stack>
        </Stack>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}></Divider>
        <Stack direction={"row-reverse"}>
            <ButtonComponent size={"small"} variant={"outlined"} title={"Close"} onClick={() => { closeGlobalModal() }}></ButtonComponent>
        </Stack>
    </Stack>;
};
