import { Box, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { ProductCatalogState } from "../../../layouts/ExpenseTypeConfig/Version-02/Layout"
import { getSystemMasterConfigs, saveScorCardQuestions } from "../../../services/applicationApis"
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent"
import { CheckBoxComponent } from "../../InputComponents/CheckBoxComponent/CheckBoxComponent"
import { DefaultScoreCardConfigResponse, ScoreCardLayoutResponse } from "../CreditFile/Components/CreditInspection/InspectionFormParking/ScoreCardQuestions/ScoreCardQuestionsProvider"
import { closeGlobalModal } from "../GlobalModal/GlobalModal"
import { Toast } from "../GlobalToast"
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent"

const ScorecardQuestionsMappingModal = ({ scoreCardTemplate, exisitingQuestions, productCatalog }:
    {
        scoreCardTemplate: ScoreCardLayoutResponse;
        exisitingQuestions: Map<string, ScoreCardLayoutResponse>;
        productCatalog: ProductCatalogState

    }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [scorecardQuestionTemplate, setScorecardQuestionTemplate] = React.useState<DefaultScoreCardConfigResponse>({} as DefaultScoreCardConfigResponse)
    const [selectedQuestions, setSelectedQuestions] = React.useState<Map<number, number>>(new Map<number, number>())

    useEffect(() => {
        (async () => {
            try {
                const defaultScoreCard: DefaultScoreCardConfigResponse = await getSystemMasterConfigs({ key: "default-score-card-questions-form" });
                setScorecardQuestionTemplate(defaultScoreCard);
                setSelectedQuestions(new Map(exisitingQuestions.get(scoreCardTemplate.key)?.questionIds.map((question) => [question, question])));
            } catch (error) {
                Toast.error("Error fetching scorecard questions", "Scorecard Questions");
                closeGlobalModal();
            } finally {
                setIsLoading(false)
            }
        })()
    }, [exisitingQuestions])

    if (isLoading) {
        return <Box sx={{ height: "200px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgressComponent size={25} />
        </Box>
    }

    const handleCheckBoxChange = (event: boolean, dataKeyMappingId: number) => {
        dataKeyMappingId = typeof dataKeyMappingId === "string" ? parseInt(dataKeyMappingId) : dataKeyMappingId

        if (event && !selectedQuestions.has(dataKeyMappingId)) {
            selectedQuestions.set(dataKeyMappingId, dataKeyMappingId)
        }

        if (!event && selectedQuestions.has(dataKeyMappingId)) {
            selectedQuestions.delete(dataKeyMappingId)
        }

        setSelectedQuestions(new Map(selectedQuestions))
    }

    const handleSaveConfiguredScorecardQuestions = async () => {
        try {
            const { type, sector, scheme, applicantType } = productCatalog;
            const exisitingQuestionsItem = exisitingQuestions.get(scoreCardTemplate.key);

            const response = await saveScorCardQuestions({
                "questionIds": Array.from(selectedQuestions.keys()),
                "scoreCardKey": scoreCardTemplate.key,
                "productCatalogue": [type, sector, scheme].join("-"),
                "customerType": applicantType
            });

            if (response) {
                if (exisitingQuestionsItem) {
                    exisitingQuestionsItem.questionIds = Array.from(selectedQuestions.keys());
                }

                Toast.success("Scorecard questions saved successfully", "Scorecard Questions");
            }
        } catch (error) {
            Toast.error("Error saving scorecard questions", "Scorecard Questions");
        }
        finally {
            closeGlobalModal();
        }
    }

    return (
        <Stack>
            <Stack gap={2}>
                <Stack height={'400px'} overflow={"auto"}>
                    <BuildQuestionList mainItem={scorecardQuestionTemplate} handleCheckBoxChange={handleCheckBoxChange} selectedQuestions={selectedQuestions} />
                </Stack>
                <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
                    <ButtonComponent variant="outlined" color="info" title={"Close"}
                        onClick={() => closeGlobalModal()}
                    />
                    <ButtonComponent variant="outlined" color="info" title={"Save"}
                        onClick={handleSaveConfiguredScorecardQuestions}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

const BuildQuestionList = ({ mainItem, handleCheckBoxChange, selectedQuestions }:
    {
        mainItem: Record<string, any>; handleCheckBoxChange: (boolean, number) => void,
        selectedQuestions: Map<number, number>
    }) => {

    if (mainItem instanceof Array) {
        return <>{mainItem.map((childItem) => <BuildQuestionList mainItem={childItem} handleCheckBoxChange={handleCheckBoxChange} selectedQuestions={selectedQuestions} />)}</>
    }

    if (mainItem.children.length) {
        return <BuildQuestionList mainItem={mainItem.children} handleCheckBoxChange={handleCheckBoxChange} selectedQuestions={selectedQuestions} />
    }

    return <Stack direction={"row"} gap={2} alignItems={"center"} py={1}>
        <CheckBoxComponent checked={selectedQuestions.has(Number(mainItem.dataKeyMappingId))} onChange={(e) => { handleCheckBoxChange(e.target.checked, Number(mainItem.dataKeyMappingId)) }} />
        <Typography color={"gray"} variant="subtitle2">{mainItem.title} ({mainItem.dataKeyMappingId})</Typography>
    </Stack>
}

export default ScorecardQuestionsMappingModal