import { Grid, Stack } from "@mui/material";
import { useEffect } from "react";

const ScoreCardRow = (params) => {
  return (
    <>
      <Grid container p={1} className="data-box">
        <Grid container item p={1}>
          <Grid item xs={5}>
            <Grid item>
              <Stack>
                <div className={`basic-font basic-font-color`}>{params.value.name}</div>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item>
              <Stack>
                <div className={`basic-font basic-font-color`}>{params.value.conditions}</div>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item>
              <Stack>
                <div className={`basic-font align-right`}>{params.value.score}</div>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ScoreCardRow;
