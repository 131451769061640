import { createContext, useContext } from "react";

export const RatioAnalysisContext = createContext<Object | undefined> ({});

export const useRatioAnalysisContext = (  ) => {

    const ratioAnalysisContext = useContext(RatioAnalysisContext);

    if (!ratioAnalysisContext) return {}

    return ratioAnalysisContext
}