import { Tooltip } from "@mui/material"
import AppConfig from "../AppConfig"

export default ({ children, text = "" }) => {
    return <div onClick={() => AppConfig.control && navigator.clipboard?.writeText(text)}>
        <Tooltip title={text}>
            <>
                {children}
            </>
        </Tooltip>
    </div>
}