import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { formatCurrency } from '../../../../../../../../../services/currencyFormater';
import { deleteMultipleInputItem } from '../../../../../../../../../services/multipleInputDelete';
import { ButtonComponent } from '../../../../../../../../InputComponents/ButtonComponent/ButtonComponent';
import TableBodyWrapper from '../TableBody/TableBodyWrapper';
import { TableColumnsElementWrapper, TableColumnsSelectBoxWrapperRevaamp, TableColumnsTextBoxWrapper, TableColumnsTextWrapper } from '../TableColumn/TableColumnsWrapper';
import { TableHeaderWrapper } from '../TableHeader/TableHeaderWrapper';
import { TableRowWrapper } from '../TableRow/TableRowWrapper';
import TableWrapper from '../TableWrapper/TableWrapper';
import { AutoGeneratedTableCalcaulations } from './Calculations/AutoGeneratedTableCal';



const AutoGeneratedTable = ({ formData, defaultDataObject, mainTitle, tableRawData }) => {

    const onNewRowAdding = () => formData.merge([JSON.parse(JSON.stringify({ ...defaultDataObject, key: Math.random() }))]);

    return (
        <Stack width={"100%"}>
            <Stack direction={"row"} alignItems={"center"} gap={2} style={{ backgroundColor: "#90caf9" }} px={1}>
                <Typography variant='subtitle2' color={"#36454F"}>{mainTitle}</Typography>
                <IconButton sx={{ verticalAlign: "middle", cursor: "pointer" }} onClick={onNewRowAdding}>
                    <AddIcon />
                </IconButton>
            </Stack>
            <TableWrapper>
                <TableHeaderWrapper headerArray={tableRawData.headerTitleMap} />
                <TableBodyWrapper>
                    {
                        formData.filter((element) => element && !element["removedItem"].get()).map((element, index) => {

                            return <TableRowWrapper key={element.key.get()}>
                                <AutoGeneratedCollumnBuilder columnItemTable={tableRawData.headerTitleMap} formData={element} />

                                <TableColumnsElementWrapper index={"index" + "delete" + index}>
                                    {
                                        index !== 0 ? <Tooltip title={"Delete"}>
                                            <Box>
                                                <ButtonComponent iconbtn children={<DeleteIcon />} onClick={() => deleteMultipleInputItem(element)} />
                                            </Box>
                                        </Tooltip> : <></>
                                    }
                                </TableColumnsElementWrapper>
                            </TableRowWrapper>
                        })
                    }
                    <TableRowWrapper>
                        <AutoGeneratedCollumnBuilder columnItemTable={tableRawData.constantRowMap} formData={formData} />
                    </TableRowWrapper>

                </TableBodyWrapper>
            </TableWrapper>
        </Stack>
    )
}

export default AutoGeneratedTable

const BuildAutoPopulatedBlock = ({ formData, calculationDependency, index }) => {

    const totalCalculator = (formData) => {
        const toBeCalculatedArray: number[] = [];

        calculationDependency.dataKeys.forEach((key) => {
            if (formData[key]) {

                if (Number(formData[key])) {
                    toBeCalculatedArray.push(formData[key]);

                } else toBeCalculatedArray.push(0);

            } else toBeCalculatedArray.push(0);
        });

        const autoGeneratedTableCalcaulations = new AutoGeneratedTableCalcaulations(toBeCalculatedArray, calculationDependency.typeOfCal);

        return {
            total: autoGeneratedTableCalcaulations.Output()
        }
    }

    const calculationResponse = useMemo(() => {

        if (formData.get() instanceof Array && formData.get().length > 0) {
            let total = 0;

            formData.get().filter((element) => element && !element["removedItem"]).forEach((element) => {
                const res = totalCalculator(element);
                total = total + res["total"];
            });

            return {
                total
            }

        } else {
            const res = totalCalculator(formData.get());

            return {
                ...res
            }
        }

    }, [formData.get()]);

    return <TableColumnsTextWrapper index={"BuildAutoPopulatedBlock_" + index}><React.Fragment> {formatCurrency(calculationResponse.total)}</React.Fragment></TableColumnsTextWrapper>;
}


const AutoGeneratedCollumnBuilder = ({ columnItemTable, formData }) => {

    if (columnItemTable.length === 0) {
        return <></>
    }

    return <React.Fragment>
        {
            columnItemTable.map((itemDetail: any) => {

                if (itemDetail.type === "select") {
                    return <TableColumnsSelectBoxWrapperRevaamp key={"TableColumnsSelectBoxWrapperRevaamp_" + itemDetail.title} index={itemDetail.title} value={formData[itemDetail.dataKey]} title={'Select'} options={itemDetail.options} />
                }

                if (itemDetail.type === "textBox") {
                    return <TableColumnsTextBoxWrapper value={formData[itemDetail.dataKey]} key={"TableColumnsSelectBoxWrapperRevaamp_" + itemDetail.title} index={itemDetail.title} title={''} />
                }

                if (itemDetail.type === "autoPopulated") {
                    return <BuildAutoPopulatedBlock key={"TableColumnsSelectBoxWrapperRevaamp_" + itemDetail.title} index={itemDetail.title} formData={formData} calculationDependency={itemDetail.calculation} />
                }

                if (itemDetail.type === "lable") {
                    return <TableColumnsTextWrapper index={"BuildAutoPopulatedBlock_" + itemDetail.title}><React.Fragment> {itemDetail.title}</React.Fragment></TableColumnsTextWrapper>
                }
            })
        }
    </React.Fragment>

}
