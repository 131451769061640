
export const formatCurrency = (value, digits = 0) => {
  if (value === null || value === undefined || value === "") {
    return value;
  } else if (!isNaN(value)) {
    const numberFormatter = Intl.NumberFormat("en-US", { minimumFractionDigits: digits });
    return numberFormatter.format(value);
  } else {
    return 0;
  }
};

export const formatCurrencyWithThousandSeperators = (nStr) => {
  if (nStr === null || nStr === undefined || nStr === "") {
    return nStr;
  }

  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};
