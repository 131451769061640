import React from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import TableBodyWrapper from "../../../Appraisal/Micro/Helpers/TableContainer/TableBody/TableBodyWrapper";
import { TableColumnsTextWrapper } from "../../../Appraisal/Micro/Helpers/TableContainer/TableColumn/TableColumnsWrapper";
import { CustomTableHeaderWrapperForBalanceSheet } from "../../../Appraisal/Micro/Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import { TableRowWrapper } from "../../../Appraisal/Micro/Helpers/TableContainer/TableRow/TableRowWrapper";
import TableWrapper from "../../../Appraisal/Micro/Helpers/TableContainer/TableWrapper/TableWrapper";

const SummaryTableBuilder = ({ headerArray, getAllCalculations, descriptionList, label, appraisalFormData }) => {
    const { t: translate } = useTranslation();

    const titleArray = ["grossProfit", "netProfitBeforeTax", "netProfitAfterTax", "totalAssets", "totalLiabilities", "equity"];

    const getAutoPopulatedValue = (year, mainDataKey) => {

        if (!getAllCalculations) return 0;

        else if (!getAllCalculations[mainDataKey]) return 0;

        else if (!getAllCalculations[mainDataKey][year]) return 0;

        else return Number(getAllCalculations[mainDataKey][year]);
    }

    return (
        <TableWrapper noBorder={true}>
            <CustomTableHeaderWrapperForBalanceSheet
                firstColTitle={translate(label)}
                dataObject={headerArray}
                style={{ fontWeight: "bold", color: "#555555" }}
            />
            <TableBodyWrapper>
                {descriptionList.map((item: any) => {
                    return (
                        <TableRowWrapper
                            key={"TableRowWrapper" + item.title + item.dataKey}
                        >
                            <TableColumnsTextWrapper
                                key={item.title}
                                index={item.title + item.dataKey}
                                style={titleArray.includes(item.dataKey) ? { fontWeight: "bold", color: "#555555" } : {}}
                            >
                                <React.Fragment>{item.title}</React.Fragment>
                            </TableColumnsTextWrapper>
                            {headerArray.map((year) => {
                                if (item.type === "textBox") {
                                    return (
                                        <TableColumnsTextWrapper
                                            key={year.dataKey + item.title + item.dataKey}
                                            index={year.dataKey}
                                            style={titleArray.includes(item.dataKey) ? { fontWeight: "bold", color: "#555555" } : {}}
                                        >
                                            <React.Fragment>{` ${formatCurrency(appraisalFormData?.[year.dataKey]?.[item.dataKey] || 0)}`}</React.Fragment>
                                        </TableColumnsTextWrapper>
                                    );
                                } else if (item.type === "autoPopulated") {
                                    return (
                                        <TableColumnsTextWrapper
                                            key={year.dataKey + item.title + item.dataKey}
                                            index={year.dataKey}
                                            style={titleArray.includes(item.dataKey) ? { fontWeight: "bold", color: "#555555" } : {}}
                                        >
                                            <React.Fragment>{` ${formatCurrency(getAutoPopulatedValue(year.dataKey, item.dataKey))}`}</React.Fragment>
                                        </TableColumnsTextWrapper>
                                    );
                                }
                                return <></>
                            })}
                        </TableRowWrapper>
                    );
                })}
            </TableBodyWrapper>
        </TableWrapper>
    );
}

export default SummaryTableBuilder;

