import { useState } from "@hookstate/core";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { isPermitted } from "../../../../../../../../utility/helpers/getIshavePermission";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { profitAndLossAccountsTypeState } from "../../Stores/MicroAppraisalStore";

interface SubSectionHeader {
  title: string;
  dropDownProps?: any | undefined;
  isNeedDropdown?: boolean;
  onSubmit?: () => void;
}

const SubSectionHeader: React.FC<SubSectionHeader> = ({ title = "", dropDownProps, isNeedDropdown = false, onSubmit }: SubSectionHeader) => {
  const currentKey = useState(profitAndLossAccountsTypeState);

  return (
    <Stack direction={"row"} sx={{ backgroundColor: "lightgrey" }} alignItems={"center"} justifyContent={"space-between"}>
      <Stack p={1}>
        <Typography variant="body1" color={"black"}> {title} </Typography>
      </Stack>
      {isNeedDropdown && dropDownProps &&
        <Stack p={1}>
          <AutoCompleteProxyComponent options={dropDownProps.options} onChange={(e) => currentKey.set(e.id)} label={""} cls="select_basic_for_auto" value={currentKey.get()} />
        </Stack>
      }
      {
        onSubmit ?
          <Box p={1}> <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={onSubmit} loadingbtn={true} color="info"
            disabled={isPermitted()}
          /></Box> : <></>
      }

    </Stack>
  );
};

export default React.memo(SubSectionHeader);
