import { FinancialPositionOfTheCompanyController } from "./CalcluationsInterface";

export class GeneralizedController extends FinancialPositionOfTheCompanyController {
    totalAssets = {};
    totalLiabilities = {};
    totalEquity = {};

    private assetsDataKeys: Array<any>;
    private liabilityDataKeys: Array<any>;

    constructor(formData, years, assetsDataKeys, liabilities) {
        super();
        this.formData = formData;
        this.years = years.filter((item) => item?.isRatioAnalysisCal);
        this.assetsDataKeys = assetsDataKeys;
        this.liabilityDataKeys = liabilities;
    }

    getTotalAssets() {
        this.totalAssets = this.getTotals(this.assetsDataKeys);
        return this.totalAssets;
    }

    getTotalLiabilities() {
        this.totalLiabilities = this.getTotals(this.liabilityDataKeys);

        return this.totalLiabilities;
    }

    getEquity() {
        let intermediateArray = this.getIncludedMainKeys();

        intermediateArray.length > 0 && intermediateArray.forEach(({ dataKey }) => {

            this.totalEquity = {
                ...this.totalEquity,
                [dataKey]: (this.totalAssets[dataKey] ?? 0) - (this.totalLiabilities[dataKey] ?? 0)
            }
        });

        return this.totalEquity;
    }
}
