import { Stack, Typography } from '@mui/material';

interface AutoPopulatedLabelProps {
    title: string;
    value: number | string
}

const AutoPopulatedLabel = ({ title, value }) => {

    return <Stack px={1} color={"gray"} flex={1}>
        <Typography variant={'subtitle2'}>{title}</Typography>
        <Typography variant={'subtitle1'}>{value ?? "-"}</Typography>
    </Stack>
}
export default AutoPopulatedLabel;