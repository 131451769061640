import {Stack, Typography} from "@mui/material";

const AutoPopulatedLabel = ({ metaData, alphaChildren, value, onChange }) => {

    return <Stack px={1} color={"gray"} pr={5}>
        <Typography variant={'subtitle2'}>{metaData?.title}</Typography>
        <Typography variant={'subtitle1'}>{value[metaData?.dataKey] ?? "-"}</Typography>
    </Stack>
}
export default AutoPopulatedLabel;

