import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

function ShareholderSelect({ options, typePrefix, selectedPreShareholderList, callBackOnShareholderSelect }) {
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [listItems, setListItems] = React.useState<readonly number[]>([]);

  useEffect(() => {
    const itemIndexArr: any[] = [];

    options.forEach((element, index) => {
      itemIndexArr.push(index);
    });
    setListItems(itemIndexArr);
  }, [options]);

  useEffect(() => {
    let type: any = "";

    if (typePrefix == "partnershipD_") {
      type = "partnerships";
    } else if (typePrefix == "individualD_") {
      type = "individuals";
    } else if (typePrefix == "directorD_") {
      type = "directors";
    }

    const dataObj = { ...selectedPreShareholderList };
    dataObj[type] = checked;

    callBackOnShareholderSelect(dataObj);
  }, [checked]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly number[]) => intersection(checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const customList = (title: React.ReactNode, items: readonly number[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "auto",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={getByLabelText(value)} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const getByLabelText = (value: any = "") => {
    let newNic: any = options[value][typePrefix + "newNic"] || "";
    let oldNic: any = options[value][typePrefix + "oldNic"] || "";
    let nic = newNic ? " - " + newNic : " - " + oldNic;
    let name: any = options[value][typePrefix + "name"] || "";

    return name + nic;
  };

  return (
    <Grid container justifyContent={"flex-start"} alignItems="center">
      <Grid item xs={12}>
        {customList("Choices", listItems)}
      </Grid>
    </Grid>
  );
}

export default React.memo(ShareholderSelect);
