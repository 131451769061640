import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import {
  creditData,
  productStageDocumentPermissionsState,
  userDetails,
} from "../../../../../../configs/mainStore";
import SecurityProductDocumentUploadLayout from "../../../../../../layouts/SecurityDocumentUploadLayout";

const SecurityDocumentUpload = ({
  applicationId,
  innerComponentData,
  tabsToEdit,
  currentApplicationWfData,
}) => {
  const creditFileData: any = useState(creditData);
  const { productId, applicantType, formData, disbursed }: any = creditFileData
    .attach(Downgraded)
    .get();
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== creditFileData?.currentAssignee?.get();

  const individualId = formData?.personalData?.individualId;
  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height">
        <SecurityProductDocumentUploadLayout
          applicationId={applicationId}
          productId={productId}
          applicantType={applicantType}
          documentTypeId={innerComponentData?.documentTypeId}
          documentSectionName={innerComponentData?.title}
          documentComponentId={innerComponentData?.id}
          participantId={individualId}
          disbursed={disbursed}
          disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
          participantType={innerComponentData?.participantType}
        />
      </div>
    </Grid>
  );
};

export default SecurityDocumentUpload;
