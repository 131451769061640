import { Downgraded } from "@hookstate/core";
import { sendFilledInpectionFormData } from "../../../../../../../../../services/apiCalls";
import { SectionKey } from "../inspectionKeyEnums";
import { inspectionForm, inspetionIdState } from "../inspectionStores/dataStore";

export type InspectionProps = {
  borrowers: {},
  summery: {},
  business: {},
  participation: {},
  collateral: {},
  scoreCard: {},
  otherQuestions: {},
  sustainableEnvironmentalAndSafeguard: {},
}

export const SaveInspectionChanges = async (sectionFormData, sectionKey: string, inspectionFormState, applicationId, individualId = "") => {
  const inspectionId: number | null = inspetionIdState.attach(Downgraded).get();

  if (sectionKey === SectionKey.primaryApplicantInspection) {
    const mainObjectKey = individualId;

    const intermediateObject = {
      [mainObjectKey]: { ...sectionFormData },
    };

    inspectionForm.borrowers.set(intermediateObject);
  } else {

    try {
      inspectionForm[sectionKey].set(sectionFormData);
    } catch (error) {
      const inspectionDetailsDowngraded = inspectionForm.attach(Downgraded).get();

      const dataToBeSent = {
        ...inspectionDetailsDowngraded,
        [sectionKey]: sectionFormData,
      }
      inspectionForm.set(dataToBeSent);
    }
  }

  const inspectionDetailsDowngraded = inspectionForm.attach(Downgraded).get();

  const { status }: any = await sendFilledInpectionFormData(applicationId, inspectionDetailsDowngraded, inspectionId);

  return {
    status,
  };
};
