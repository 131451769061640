import { Divider, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { Toast } from "../../../../../../../GlobalToast";

export const OtherQuestionsInCollaterals = ({ value, metaData, onChange }) => {
  const [otheQuestion, setOtherQuestions] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (value["otherQuestion"]) {
      setOtherQuestions(value["otherQuestion"]);
    }

    return () => {
      setOtherQuestions([]);
      setOpen(false);
    };
  }, []);

  const props = {
    open,
    setOpen,
    setOtherQuestions,
    otheQuestion,
    onChange,
    value,
    metaData,
  };

  return (
    <React.Fragment>
      <Stack direction={"row"} width={"100%"} p={1} justifyContent={"flex-end"}>
        <ButtonComponent title="Add Questions" variant="contained" onClick={() => setOpen(true)} loadingbtn={true} color="info" />
      </Stack>
      <AddQuestionModal {...props} />
      <Stack pt={2} width={"100%"} color={"gray"}>
        {otheQuestion.length > 0 &&
          otheQuestion.map((item: any, index) => {
            return (
              <React.Fragment key={`Fragment_${index}_element`}>
                <Stack p={1}>
                  <Stack color={"gray"} direction={"row"} alignItems={"center"}>
                    <Typography variant="caption" p={1}>
                      QUESTION:
                    </Typography>
                    <Typography variant="subtitle1">{item.question}</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="caption" p={1}>
                      ANSWER:
                    </Typography>
                    <Typography variant="subtitle1">{item.answer}</Typography>
                  </Stack>
                </Stack>
                <Divider />
              </React.Fragment>
            );
          })}
      </Stack>
    </React.Fragment>
  );
};

function AddQuestionModal({ open, setOpen, otheQuestion, setOtherQuestions, value, onChange, metaData }) {
  const handleClose = () => {
    setOpen(false);
  };

  const addQuestion = (question, answer) => {

    if (!question || !answer) {
      return Toast.error("Question or Answer cannot be empty!", 'Validation Error!')
    }

    const intermediateArray = [
      ...otheQuestion,
      {
        question,
        answer,
      },
    ];

    value["otherQuestion"] = intermediateArray;

    onChange(metaData, value);

    setOtherQuestions(intermediateArray);

    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const question = formJson.question;
            const answer = formJson.answer;
            addQuestion(question, answer);
          },
        }}
      >
        <DialogTitle>Other Questions</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="question" name="question" label="Question" type="test" fullWidth variant="outlined" />
          <TextField autoFocus margin="dense" id="answer" name="answer" label="Answer" type="text" fullWidth variant="outlined" multiline rows={3} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
