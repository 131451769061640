export const inspectionMessageMappings = (arr) => {
  let suffix = "field is mandatory";
  let finalArr: any = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];

    if (keyMapping[element]) {
      let msg;

      if (keyMapping[element].toLowerCase().endsWith("count")) {
        msg = keyMapping[element];
      } else {
        msg = keyMapping[element] + " " + suffix;
      }

      finalArr[finalArr.length] = msg;
    }
    else {
      finalArr[finalArr.length] = element;
    }
  }
  return finalArr;
};

export const inspectionMessageMappingsFromTitle = (arr) => {
  let suffix = "field is mandatory";
  let finalArr: any = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    let msg = element + " " + suffix;
    finalArr[finalArr.length] = msg;
  }
  return finalArr;
};

export const keyMapping = {
  grooming: "Personal grooming",
  smoker: "Smoker",
  punctuality: "Punctuality",
  reputation: "Reputation",
  trustWorthiness: "Trustworthiness",
  attitude: "Attitude",
  incomeStability: "Income Stability",
  financialDiscipline: "Financial Discipline",
  alcoholUse: "Alcohol Use",
  frequency: "Frequency",
  haveYouBeenAbroad: "Have You Been Abroad",
  roof: "Roof",
  walls: "Walls",
  noOfRooms: "Number of Rooms",
  drinkingWater: "Drinking Water",
  house: "House",
  extentOfProperty: "Extent of Property",
  floor: "Floor",
  toilets: "Toilets",
  electricity: "Electricity",
  transport: "Transport",
  modeOfTransport: "Mode of Transport",
  ownership: "Ownership",
  comment: "Comment",
  imgDocumentAttach: "Attached Images/Document",
  inspectionOfficer: "Inspection Officer",
  inspectionId: "Inspection Id",
  purposeOfInspection: "Purpose Of Inspection",
  date: "Date",
  time: "time",
  SalesandPurchaseInformationofFastMovingItemsOtherdata:
    "👉 Sales and Purchase Information of Fast Moving Items Section",
  salesandPurchaseInformationofFastMovingItemsOtherdata:
    "👉 Sales and Purchase Information of Fast Moving Items Section",
  businessIncome: "Business Income Section",
  deductionsCostsOfGoods: " Deductions Costs Of Goods",
  businessExpense: "Business Expense Section",
  businessLiabilityExpenses: "Business Liability Expenses Section",
  businessExpenses: "Business Expenses Section",
  stock: "👉 Working Capital - Stock Section",
  debtors: "👉 Working Capital - Debtors Section",
  creditors: "👉 Working Capital - Creditors Section",
  description: "Description",
  period: "Period",
  assetCategory: "Asset Category",
  assertType: "Asset Type",
  assertLiquidity: "Asset Liquidity",
  assertTimeFrame: "Asset Time Frame",
  forcedSaleValue: "Forced Sale Value",
  marketValue: "Market Value",
  assertLifeTime: "Asset Life Time",
  depreciationExpense: "Depreciation Expense",
  type: "Type",
  amount: "Amount",
  numberOfDays: "Number Of Days",
  cashInTill: "Cash In Till",
  recommendedProfit: "Recommended Profit",
  item: " Item",
  sourceOfIncome: "Source Of Income",
  cashBasisIncome: "Cash Basis Income",
  crediBasisIncome: "Credit Basis Income",
  income: "Income",
  annualBusinessIncome: "Annual Business Income",
  cultivatedLandareainAcres: "Cultivated Land area in Acres",
  availabilityofIrrigationFacilities: "Availability of Irrigation Facilities",
  ownershipOfLand: "Ownership of the land",
  ownershipofAgriculturalEquipment: "Ownership of agricultural equipment",
  availabiltyofPrivateInsurance: "Availabilty of Private Insurance",
  vulnerabilityClimaticAndEnvironmentalFactors: "Vulnerability to Climatic and Environmental Factors",
  totaleBusinessAssertOtherDetails: "Business Assets Section",
  liabilityType: "Liability Type",
  bankorInstitute: "Bank or Institute",
  limitOfOverDraft: "Limit of Overdraft",
  expiryDate: "Expiry Date",
  securityCategoryOverDraft: "Security Category",
  amountofTheFacility: "Loan / Lease Amount",
  installmentFrequency: "Installment Frequency",
  installmentAmount: "Installment Amount",
  currentOutstanding: "Current Outstanding",
  securityType: "Security Type",
  obligationType: "Obligation Type",
  numberofInstallmentsLeft: "Number of Installments Left",
  employeeType: "Employee Type",
  buyingPrice: "Buying Price",
  sellingPrice: "Selling Price",
  employementDetails: "👉 Employee Details Section",
  extractEmployeType: "|👉 Employee Type Count",
  permanent: "Existing Permanent Employees",
  contract: "Existing Contract Employees",
  extractAgeRange: "|👉 Employee Age Range Count",
  noOfFemale: "   |👉 Female Employees Count",
  noOfMale: "   |👉 Male Employees Count",
  belowFourty: "Age Below Fourty",
  aboveFourty: "Age Above Fourty Employees",
  isPayEpfEtfEmp: "No Of Employees who get payed EPF ETF",
  noOfPayEpfEtfEmp: "ETF and EPF pay for employees",
  recommendation: "Recommendation",
  markettingArrangement: "Marketting Arrangement",
  outStanding: "OutStanding",
  expenseType: "Expense Type",
  cashBasisExpense: "Cash Basis Expense",
  crediBasisExpense: "Credit Basis Expense",
  "expense": "Expense",
  belowSixteen: "Below age 16",
  betweenSixteenAndTwentyFive: "Between 16 and 25",
  betweenTwentySixAndThirtyFive: "Between 26 and 35",
  betweenThirtySixAndFortyFive: "Between 36 and 45",
  aboveFourtyFive: "Above 45",
  cashBasisDeductionsCOG: "Cash Basis Deductions",
  crediBasisDeductionsCOG: "Credit Basis Deductions",
  deductionsCOGType: "Type",
};
