import { Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { OnDemandValueFormatter } from "../../../../services/onDemandValidators";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";

export default (props) => {
  const [payload, setPayload] = useState({ id: -1, ...props?.data });
  const [submitting, setSubmitting] = useState(false);

  const checkParentStage = () => {
    const parentStageMap = props?.childrenParams?.stageParentMap;
    let currentStageId = props?.data?.id;
    for (const key in parentStageMap) {
      if (parentStageMap[key].children && parentStageMap[key].children.includes(currentStageId)) {
        return parseInt(key.toString());
      }
    }
  }
  const [selectParentStage, setSelectedParentStage] = useState(checkParentStage);

  const updateName = (e) => {
    const value = e.target.value;
    setPayload({ ...payload, name: value });
  };

  const updateDescription = (e) => {
    const value = e.target.value;
    setPayload({ ...payload, desc: value });
  };

  const modifyChildParentStages = (data, targetChildId, newParentId) => {
    for (const key in data) {
      if (data[key].children) {
        const childIndex = data[key].children.indexOf(targetChildId);
        if (childIndex > -1) {
          data[key].children.splice(childIndex, 1);
          if (newParentId in data) {
            data[newParentId].children.push(targetChildId);
          } else {
            return "";
          }
          return;
        }
      }
    }
  }

  const updateParentStage = (e) => {
    const value = e.id;
    setSelectedParentStage(value);
    modifyChildParentStages(props?.childrenParams?.stageParentMap, props?.data?.id, value);
    setPayload({ ...payload, parentStage: value });

  }


  const unpublishItem = async () => {
    setSubmitting(true);
    await props.remove(payload);
    setSubmitting(false);
    closeGlobalModal();
  };

  const validateAndSubmit = async () => {
    setSubmitting(true);
    if(!payload?.parentStage){
      payload.parentStage = selectParentStage;
    }
    await props.submit(payload);
    setSubmitting(false);
    closeGlobalModal();
  };

  const validate = (property: any) => {
    let errors: any = {
      name: [],
      desc: [],
    };

    if (!payload.name) {
      errors.name = [...errors.name, "Name can not be empty."];
    }

    return !property ? errors : errors[property];
  };

  const StageFields = (props) => {
    return (
      <>
        <Stack>
          <AutoCompleteProxyComponent
            options={props?.dropDownValues?.childrenParams?.parentStages}
            fullWidth={true}
            onChange={updateParentStage}
            label={"Parent Stage"}
            value={selectParentStage ?? ""}
            cls="select_basic"
          />
        </Stack>
      </>
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack>
          <Typography>
            Workflow {props.type}{" "}
            {`${payload?.id > 0 ? `(${payload?.id})` : ""}`}
          </Typography>
          <Typography variant="caption">
            add / edit workflow {props.type}
          </Typography>
        </Stack>
        <Stack
          onClick={() => {
            closeGlobalModal();
          }}
        >
          <Typography
            variant="h6"
            style={{ cursor: "pointer", color: "black" }}
          >
            ❌
          </Typography>
        </Stack>
      </Stack>
      <Divider></Divider>
      <Stack direction={"row"}>
        <Stack flex={3} spacing={3}>
          <Stack>
            <TextBoxComponent
              placeholder={"Name"}
              value={payload?.name}
              onChange={updateName}
              error={validate("name").length > 0}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
            ></TextBoxComponent>
            {validate("name").length > 0 && (
              <span style={{ color: "red" }}>{validate("name").join(" ")}</span>
            )}
          </Stack>
          <Stack>
            <TextBoxComponent
              placeholder={"Description"}
              value={payload?.desc}
              onChange={updateDescription}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
            ></TextBoxComponent>
          </Stack>
          {props.type === "stage" ? (<StageFields dropDownValues={props} />) : (<></>)}
        </Stack>
        <Stack flex={1}></Stack>
      </Stack>
      <Divider></Divider>
      {!submitting ? (
        <Stack direction={"row"} spacing={2}>
          {payload?.id > 0 && (
            <ButtonComponent
              variant={"outlined"}
              onClick={unpublishItem}
              title={"Unpublish"}
            ></ButtonComponent>
          )}
          <Stack flex={1}></Stack>
          <ButtonComponent
            variant={"outlined"}
            onClick={closeGlobalModal}
            title={"Close"}
          ></ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={validateAndSubmit}
            title={"Add"}
          ></ButtonComponent>
        </Stack>
      ) : (
        <CircularProgressComponent size={30} />
      )}
    </Stack>
  );
};
