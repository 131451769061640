import { Grid } from "@mui/material"
import { SelectComponent } from "../../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { UserContactsInputComponent } from '../../../../../../../UserInputComponent/UserContactsInputComponent';

const EditSMSJobModalLayout = ({ onChange, smsData, selectData, isEdit, onSave }) => {
  const h = selectData?.toNumber?.get().hierarchies?.map(value => value.name) || [];
  const g = selectData?.toNumber?.get().groups?.map(value => value.name) || [];
  const u = selectData?.toNumber?.get().users?.map(value => value.name) || [];

  return (
    <Grid container spacing={1}>
      <Grid container item spacing={2} m={1} mt={0} mb={0} xs={12} style={{ width: "100%" }}>
        <Grid item xs={3}>
          <div className="basic-font font-size-16 basic-font-color-bold align-left">SMS Template </div>
        </Grid>
        <Grid item xs={9}>
          <SelectComponent
            autoWidth={false}
            onChange={(e) => onChange(e, 'templatesSMS')}
            values={smsData.templatesSMS}
            value={selectData?.templatesSMS?.id?.get()}
            formSX={{ width: "100%" }}
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2} m={1} mt={0} mb={0} xs={12}>
        <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center" }}>
          <div className="basic-font font-size-16 basic-font-color-bold align-left"> To Number</div>
        </Grid>
        <Grid item xs={12} md={9}>
          <UserContactsInputComponent
            hierarchies={smsData.toNumber.hierarchies?.get()?.map(value => value.name)}
            groups={smsData.toNumber.groups?.get()?.map(value => value.name)}
            users={smsData.toNumber.users?.get()?.map(value => value.name)}
            onChange={onChange}
            selectedHierarchies={h}
            selectedGroups={g}
            selectedUsers={u}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditSMSJobModalLayout