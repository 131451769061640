import { State, useHookstate } from "@hookstate/core";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Divider, Paper, Stack, Tab } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";

const YearlyWorkingCapitalBuilder = ({ data, otherParams, id, Component }: { data: State<any>; otherParams: any; id: string; Component: React.ElementType }) => {
    const yearArray = useHookstate<string[]>([]);
    const isLoading = useHookstate(true);
    const selectedTab = useHookstate<string>('')

    const fnPreIntiliaze = () => {
        const currentYear = (moment().year()).toString();
        try {
            const useSelectedYears: string[] = Object.keys(data).sort((a, b) => Number(a) - Number(b));
            yearArray.set(useSelectedYears);
            selectedTab.set(useSelectedYears.includes(currentYear) ? currentYear : useSelectedYears[0]);
        } catch (error) {
            new Error(`${error}`);
        } finally {
            isLoading.set(false);
        }
    }

    useEffect(() => {
        fnPreIntiliaze();
    }, [])

    if (isLoading.get()) {
        return <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgressComponent size={25} />
        </Stack>
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number | string) => {
        newValue = newValue.toString()
        selectedTab.set(newValue)
    }

    return (
        <Stack padding={3.5} component={Paper} m={1}>
            <TabContext value={selectedTab.get()}>
                <TabList onChange={handleChange} aria-label="Working Capital - Stocks" sx={{ zIndex: 500 }}>
                    {yearArray.get().map((year, index) => <Tab key={`tab-${year}`} label={year} value={year.toString()} />)}
                </TabList>
                <Divider />
                {yearArray.get().map((year, index) => {
                    const props = { otherParams, data: data?.[year] }

                    return <TabPanel key={`${id}-tab-panel-${year}`} value={year}>
                        <Component {...props} />
                    </TabPanel>
                })}
            </TabContext>
        </Stack>
    )
}

export default
    YearlyWorkingCapitalBuilder;