import React from "react";
import ChildBuilder from "./Tools/Builders/ChildrenBuilderMaster";

export interface IntermediateFormBuilderProps {
  formChildren?: any;
  key?: string;
  type?: string;
  inspectionFormData?: any;
  setInspectionFormData?: any;
  metaData?: any;
  value?: any;
  onChange?: any;
  otherParams?: any;
}

const IntermediateFormBuilder: React.FC<IntermediateFormBuilderProps> = ({ formChildren, value, onChange, otherParams = {} }: IntermediateFormBuilderProps) => {
  const BuildContent = () => {
    return formChildren.map((alpha: any, index: number) => {
      return (
        <React.Fragment key={"intermediate-form-builder" + (alpha?.dataKey || index)}>
          <ChildBuilder formJsonData={alpha} value={value} onChange={onChange} key={alpha?.dataKey} otherParams={otherParams} />
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <BuildContent />
    </React.Fragment>
  );
};

export default IntermediateFormBuilder;
