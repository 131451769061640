import { Downgraded } from '@hookstate/core';
import { productDetails } from '../mainStore';

export const getType = (id) => {
  const { productTypes }: any = productDetails?.attach(Downgraded).get();
  return productTypes?.filter((type) => type?.TYPE_ID == id)[0]?.TYPE_NAME || '';
};

export const getSector = (id) => {
  const { productSectors }: any = productDetails?.attach(Downgraded).get();
  return productSectors?.filter((sector) => sector?.SECTOR_ID == id)[0]?.TYPE_NAME || '';
};

export const getScheme = (id) => {
  const { productSchemes }: any = productDetails?.attach(Downgraded).get();
  return productSchemes?.filter((scheme) => scheme?.SCHEME_ID == id)[0]?.TYPE_NAME || '';
};

export const getPrimaryApplicantType = (id) => {
  const { productApplicantsMap }: any = productDetails?.attach(Downgraded).get();
  return productApplicantsMap?.[id]?.TYPE_NAME || '';
};
