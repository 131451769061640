import { useState } from "@hookstate/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  downloadInspectionForm,
  getFilledPendingInpectionFormData,
} from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { toast } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import {
  InspectionProps,
  SaveInspectionChanges,
} from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappings } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import {
  ApplicationFormKey,
  SectionKey,
} from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import {
  inspectionForm,
  inspetionIdState,
} from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";

const PrimaryApplicantInspections = ({
  data,
  applicationId,
  innerComponentData,
  tabsToEdit,
}) => {
  const { t: translate } = useTranslation();
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const inspetionId = useState<any>(inspetionIdState);
  const { formData } = data;
  const creditData = data;

  const createAddressData = useMemo(() => {
    const { addressData } = formData;
    const { permanentAddress } = addressData;
    const { addressLine1, addressLine2, addressLine3, addressLine4 } =
      permanentAddress;
    let address = addressLine1 + "," + addressLine2;

    if (addressLine3 && addressLine4) {
      address = address + ", " + addressLine3 + ", " + addressLine4;
    } else if (addressLine3) {
      address = address + ", " + addressLine3;
    } else if (addressLine4) {
      address = address + ", " + addressLine4;
    }

    return {
      address,
    };
  }, [formData, applicationId]);

  let toBeSubmittedFormDetails: any = {
    borrowerName: creditData?.name,
    newNic: formData?.personalData?.newNic,
    oldNic: formData?.personalData?.oldNic,
    ...createAddressData,
    mobile: formData?.contactData?.primaryContact,
    individualId: formData?.personalData?.individualId,
    applicationId,
  };
  const [formDetails, setFormDetails]: any = React.useState(toBeSubmittedFormDetails);

  const otherParams: any = useMemo(() => {
    return {
      formData,
      applicationId,
      innerComponentData,
      tabsToEdit,
    }
  }, [formData, applicationId]);

  useEffect(() => {
    const preLoader = async () => {
      inspectionFormDate();
    };
    preLoader();
  }, [data]);

  const inspectionFormDate = async () => {
    let { response, status }: any = await getFilledPendingInpectionFormData(applicationId);

    let inspetionIdVal = null;

    if (status === 1 && Object.keys(response).length > 0) {
      inspetionIdVal = response["ID"] ?? null;
      inspetionId.set(response["ID"] ?? null);
      response = response ? JSON.parse(response["INSPECTION_DATA"]) : {};
    }

    inspectionFormState.borrowers.set(response["borrowers"]);

    let borrowers = response?.borrowers[formData?.personalData?.individualId] || {};

    setFormDetails({
      ...borrowers ?? {},
      ...formDetails,
      inspetionId: inspetionIdVal,
    });
  };

  const onChange = useCallback((metaData: any, value: any) => {
    setFormDetails(value);
  }, [formDetails]);

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(
        downloadInspectionForm,
        SectionKey.primaryApplicantInspection
      );
    } catch (error: any) {
      GlobalLogger.log("inspection form", error.message);
    }
  };

  const onSubmit = useCallback(async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(
      ApplicationFormKey.primaryApplicantInspection,
      theDataTobeSubmitted,
      SectionKey.primaryApplicantInspection
    );

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges =
        inspectionMessageMappings(errorArray).join("\n");
      return toast(({
        status: "error",
        message: `${preparedErrorMessagges}`,
      }));
    }

    const respose: any = await SaveInspectionChanges(
      theDataTobeSubmitted,
      SectionKey.primaryApplicantInspection,
      inspectionFormState,
      applicationId,
      formData?.personalData?.individualId
    );

    if (respose.status == 1) return toast({ status: "success", message: "Successfully Submitted Inpection Form" });

    else return toast({ status: "error", message: "Something went wrong while Submitting Inspection Form..!" });

  }, [formDetails]);

  const params = useMemo(() => {
    return {
      onChange,
      onSubmit,
      otherParams,
      applicationDataObject: formDetails,
      formId: ApplicationFormKey.primaryApplicantInspection,
      title: translate("primaryApplicantInspection"),
      onDownload: downloadInspectionFormTemplate,
    }
  }, [formDetails, data]);

  return (
    <ErrorBoundary>
      <FormBuilder {...params} />
    </ErrorBoundary>
  );
};

export default PrimaryApplicantInspections;
