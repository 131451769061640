import { useTranslation } from "react-i18next";
import { applicationSummaryDetailsHints } from "../../../../../configs/constants/contants";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../configs/constants/masterDataKeys";
import AppConfig from "../../../../../utility/AppConfig";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import {
  creditCardAnnualAmount,
  incomeExpenseFrequency,
  overDraftAnnualAmount,
  pawningAnnualAmount,
  summaryCalculationSettings,
} from "../../../../../utility/util";
import { JBController } from "./JBController";

export class Controller {
  dataPayload: any;
  incomePayload: any;
  expensePayload: any;
  creditDetails: any;
  masterDataStore: any;
  lifeInsuranceData: any;
  liabilityData: any;
  jbController: JBController;
  toolTipPayload: any;
  productMasterData: any;
  productCatalogue: any = "";
  athamaruwaCatelogue: any = "";
  salaryProportionConfigs: any;
  currentProductCatalog: any;



  getCurrentProductCatalog = (creditDetails) => {
    let { type, sector, scheme }: any = creditDetails;

    if (!scheme) {
      return "";
    }

    return [type, sector, scheme].join("-");
  };

  constructor(data, credit, masterData, productMasterData) {
    this.dataPayload = data;

    try {
      this.creditDetails = credit?.get();
    } catch (error) {
      this.creditDetails = credit || {};
    }

    try {
      this.masterDataStore = masterData?.get();
    } catch (error) {
      this.masterDataStore = masterData || {};
    }

    try {
      this.productMasterData = productMasterData?.get();
    } catch (error) {
      this.productMasterData = productMasterData || {};
    }

    try {
      this.productCatalogue = `${this.creditDetails?.type}-${this.creditDetails?.sector}-${this.creditDetails?.scheme}`;
    } catch (error) {
      this.productCatalogue = "";
    }

    try {
      this.athamaruwaCatelogue = AppConfig.config.athamaruCatalog;
    } catch (error) {
      this.athamaruwaCatelogue = "";
    }


    try {
      this.salaryProportionConfigs = AppConfig.config.salaryProportionConfigs;
    } catch (error) {
      this.salaryProportionConfigs = {}
    }

    try {
      this.currentProductCatalog = this.getCurrentProductCatalog(this.creditDetails)
    } catch (error) {
      this.currentProductCatalog = ""
    }

    ["lifeInsuranceData", "liabilityData", "incomePayload", "expensePayload", "toolTipPayload"].forEach((key) => {
      this[key] = (this.dataPayload && this.dataPayload[key]) || {};
    });

    this.jbController = new JBController(data);
  }

  // Income And Expenses Details - INCOME

  get salaryProportionValidIdsArr(): any[] {
    const masterDataStoreInstance = MasterDataProvider.provideMasterData();
    const incomeHolders = masterDataStoreInstance?.getMasterData(MD_KEYS?.INCOME_HOLDER);
    const salaryProportionValidIds: any = [];

    incomeHolders?.get().map((obj) => {
      if (obj.name == "Primary Applicant") {
        salaryProportionValidIds.push(obj.id);
      }
    });
    return salaryProportionValidIds;
  }

  get annualSalary(): number {
    let total = 0.0;
    let jbTotal = 0.0;

    if (!this.incomePayload || !this.incomePayload.personnelIncome) {
      return total;
    }

    // Main Applicant salary details
    // personnelIncome -> averageVariableAllowance, basicSalary, fixedAllowance, lastAverageDeduction, lastThreeAverageDeduction

    let personnelIncome = [];
    try {
      personnelIncome = this.incomePayload["personnelIncome"].get();
    } catch (error) {
      personnelIncome = this.incomePayload["personnelIncome"];
    }

    let segments: any = [];

    try {
      segments = personnelIncome.map(this.calculateNetAnnualIncome);
    } catch (error) {
      segments = segments;
    }

    if (segments.length > 0) {
      let sum = segments.reduce((sum, value) => sum + value);
      total = sum;
    }

    // JB's details
    if (summaryCalculationSettings?.addJB === 1) {
      if (this.jbController.annualSalary) {
        total += this.jbController.annualSalary;
      }
    }

    return total;
  }

  calculateNetAnnualIncome = (obj: any) => {
    try {
      let netIncome =
        parseFloat(obj.basicSalary.toString()) +
        parseFloat((obj.fixedAllowance || "0").toString()) +
        parseFloat((obj.averageVariableAllowance || "0").toString()) -
        parseFloat((obj.lastThreeAverageDeduction || "0").toString());
      let annualTerms = obj.frequencyForMonth * obj.frequencyForYear;
      let netAnnualIncome = netIncome * annualTerms;
      return netAnnualIncome;
    } catch (error) {
      return 0.0;
    }
  };

  calculateAnnualSalary = (obj: any) => {
    try {
      return (
        parseFloat(obj.basicSalary.toString()) +
        parseFloat((obj.fixedAllowance || "0").toString()) +
        parseFloat((obj.averageVariableAllowance || "0").toString()) -
        parseFloat((obj.lastThreeAverageDeduction || "0").toString())
      );
    } catch (error) {
      return 0.0;
    }
  };

  calculateSalary = (obj: any) => {
    this.salaryProportionConfigs = AppConfig?.config?.salaryProportionConfigs ?? {};
    const salaryProportionValidIdsList = this.salaryProportionValidIdsArr;
    let { basicSalary = 0, fixedAllowance = 0, averageVariableAllowance = 0 } = obj;

    try {
      if (salaryProportionValidIdsList.length) {
        if (salaryProportionValidIdsList.includes(obj.incomeHolder)) {
          if (Object.keys(this.salaryProportionConfigs).includes(this.currentProductCatalog)) {
            let incomeValue: any = 0.0;
            let salaryProportionFactor = 0.0;

            if (this.salaryProportionConfigs[this.currentProductCatalog][obj.sourceOfIncome]) {
              incomeValue = basicSalary;
              salaryProportionFactor = this.salaryProportionConfigs[this.currentProductCatalog][obj.sourceOfIncome]
            }
            return (Number(incomeValue) + Number(fixedAllowance) + Number(averageVariableAllowance)) * salaryProportionFactor;
          } else return (Number(basicSalary) + Number(fixedAllowance) + Number(averageVariableAllowance)) * this.dataPayload?.salaryProportionFactor;
        } else return 0.0;
      } else return 0.0;

    } catch (error) {
      return 0.0;
    }
  };

  getSalary = (incomeData) => {
    let segments;
    try {
      segments = incomeData?.get().map(this.calculateSalary);
    } catch (error) {
      segments = incomeData.map(this.calculateSalary);
    }

    let sum = segments.reduce((sum, value) => sum + value);
    return sum;
  };

  get annualBusiness(): number {
    let total = 0.0;

    if (!this.incomePayload || !this.incomePayload.businessIncome) {
      return total;
    }

    // Main Applicant business details
    // businessIncome.income
    total =
      total +
      this.extractApplicantIncomeValue("businessIncome", "income", "incomeBusiness") -
      this.extractApplicantIncomeValue("businessIncome", "expense", "expenseBusiness");

    // JB's salary details
    if (summaryCalculationSettings?.addJB === 1) {
      total = total + this.jbController.annualBusiness;
    }

    return total;
  }

  private extractApplicantIncomeValue(node1, node2, field) {
    try {
      let incomeData: any = [];

      try {
        incomeData = this.incomePayload[node1].get();
      } catch (error) {
        incomeData = this.incomePayload[node1] || {};
      }

      let incomes: any = [];

      try {
        // incomes = incomeData[node2].map((obj) => parseFloat(obj[field] || "0"));
        incomes = incomeData[node2].map((obj) => {
          return this.calculateNetIncomeOrExpense(obj, node1, field);
        });
      } catch (error) {
        incomes = incomes;
      }

      let sum =
        incomes.length === 0
          ? 0
          : incomes.reduce((total, num) => {
            return total + num;
          });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  calculateNetIncomeOrExpense = (obj: any, node1: any, field: any) => {
    const { t: translate } = useTranslation();

    const frequencyData = incomeExpenseFrequency.map((f) => ({
      ...f,
      name: translate(f.name),
    }));

    try {
      let value = parseFloat(obj[field] || "0");
      let annualTerms: any = "";
      let annualValue: any = "";

      if (node1 == "businessIncome") {
        if (field == "incomeBusiness") {
          annualTerms = obj.incomeFrequencyForMonthBusiness * obj.incomeFrequencyForYearBusiness;
          annualValue = value * annualTerms;
        } else if (field == "expenseBusiness") {
          annualTerms = obj.expenseFrequencyForMonthBusiness * obj.expenseFrequencyForYearBusiness;
          annualValue = value * annualTerms;
        }
      } else if (node1 == "cultivationIncome") {
        if (field == "seasonalIncome") {
          let frequencyObj: any = {};

          frequencyData.forEach((frequency) => {
            if (frequency.id == obj.incomeFrequencyCultivation) {
              frequencyObj = frequency;
            }
          });
          annualTerms = frequencyObj.defaultFrequencyForMonth * frequencyObj.defaultFrequencyForYear;
          annualValue = value * annualTerms;
        } else if (field == "seasonalExpense") {
          let frequencyObj: any = {};

          frequencyData.forEach((frequency) => {
            if (frequency.id == obj.expenseFrequencyCultivation) {
              frequencyObj = frequency;
            }
          });
          annualTerms = frequencyObj.defaultFrequencyForMonth * frequencyObj.defaultFrequencyForYear;
          annualValue = value * annualTerms;
        }
      }

      return annualValue;
    } catch (error) {
      return 0.0;
    }
  };

  get annualCultivation(): number {
    let total = 0.0;
    // Main Applicant salary details

    if (!this.incomePayload || !this.incomePayload.cultivationIncome) {
      return total;
    }

    total =
      total +
      this.extractApplicantIncomeValue("cultivationIncome", "income", "seasonalIncome") -
      this.extractApplicantIncomeValue("cultivationIncome", "expense", "seasonalExpense");

    // JB's details
    if (summaryCalculationSettings?.addJB === 1) {
      if (this.jbController.annualCultivation) {
        total += this.jbController.annualCultivation;
      }
    }

    return total;
  }

  get annualTotalIncome(): number {
    let total = 0.0;
    for (const salary of [this.annualSalary, this.annualBusiness, this.annualCultivation]) {
      total = total + parseFloat(salary.toString());
    }
    return total;
  }

  // Income And Expenses Details - EXPENSES

  get annualOther(): number {
    let total = 0.0;

    // Main Applicant salary details
    if (!this.expensePayload || !this.expensePayload.expenses) {
      return total;
    }

    let expenses: any = [];
    try {
      expenses = this.expensePayload.expenses.get();
    } catch (error) {
      expenses = this.expensePayload.expenses || {};
    }

    let expenseTotal = 0.0;
    try {
      expenseTotal = expenses
        .map((obj) => {
          const expAmount = parseFloat((obj.expenseAmount || "0.0").toString());
          const monthly = parseFloat((obj.frequencyForAnnual || "1.0").toString());
          const annually = parseFloat((obj.frequencyForMonth || "1.0").toString());

          return expAmount * monthly * annually;
        })
        .reduce((sum, value) => sum + value);
    } catch (error) { }

    let lifeInsuranceData: any[] = [];
    try {
      lifeInsuranceData = this.lifeInsuranceData.get();
    } catch (error) {
      lifeInsuranceData = this.lifeInsuranceData || [];
    }

    try {
      let premium = 0;

      try {
        premium = lifeInsuranceData
          .map((obj) => parseFloat((obj?.monthlyPremium || "0")?.toString()))
          .reduce((sum, value) => sum + value);
      } catch (error) {
        premium = premium;
      }

      const premiumAnnual = premium * 12;

      total = total + expenseTotal + premiumAnnual;
    } catch (error) {
      total = total;
    }

    // JB's details
    if (summaryCalculationSettings?.addJB === 1) {
      if (this.jbController.annualOther) total = total + this.jbController.annualOther;
    }

    return total;
  }

  get annualLiability(): number {
    let total = 0.0;

    // Main Applicant salary details
    if (!this.expensePayload || !this.expensePayload.expenses) {
      return total;
    }

    try {
      let liabilities: any = [];
      try {
        liabilities = this.liabilityData.loanLease.get();
      } catch (error) {
        liabilities = this.liabilityData.loanLease || [];
      }

      let leaseTotalAmounts: any = [];

      try {
        leaseTotalAmounts = liabilities.map((obj) => {
          if (obj.obligationType == 1) {
            const amountLeft = parseFloat((obj.installmentLeft || "0.0").toString());
            const installment = parseFloat((obj.installmentAmount || "0.0").toString());

            // return loanLeaseAnnualAmount(amountLeft, installment);

            return installment;
          } else {
            return 0.0;
          }
        });
      } catch (error) { }

      const leaseTotalAmount =
        leaseTotalAmounts.length > 0 ? leaseTotalAmounts.reduce((sum, value) => sum + value) : 0.0;

      try {
        liabilities = this.liabilityData.pawning.get();
      } catch (error) {
        liabilities = this.liabilityData.pawning || [];
      }

      let pawningTotalAmounts: any = [];

      try {
        pawningTotalAmounts = liabilities.map((obj) => {
          const outstanding = parseFloat((obj.outstanding || "0.0").toString());
          const insterest = parseFloat((obj.annualInterest || "0.0").toString());

          return pawningAnnualAmount(outstanding, insterest);
        });
      } catch (error) { }

      const pawningTotalAmount =
        pawningTotalAmounts.length > 0 ? pawningTotalAmounts.reduce((sum, value) => sum + value) : 0.0;

      const creditCardTotalAmount = this.extractApplicantLiabilityValue("creditCard", "limit", creditCardAnnualAmount);
      const overDraftTotalAmount = this.extractApplicantLiabilityValue(
        "overdraft",
        "outstanding",
        overDraftAnnualAmount
      );

      const _creditLoanAmount = creditCardTotalAmount / 12;
      const _overDraftAmount = overDraftTotalAmount / 12;
      const _pawningAmount = pawningTotalAmount / 12;

      total = total + _creditLoanAmount + leaseTotalAmount + _overDraftAmount + _pawningAmount;
    } catch (error) {
      total = total;
    }

    // JB's details
    if (summaryCalculationSettings?.addJB === 1) {
      if (this.jbController.annualLiability) {
        total += this.jbController.annualLiability;
      }
    }

    return total;
  }

  private extractApplicantLiabilityValue(node, field, evaluator: any = null) {
    try {
      let liabilityData: any = [];

      try {
        liabilityData = this.liabilityData[node].get();
      } catch (error) {
        liabilityData = this.liabilityData[node] || {};
      }

      let liabilities: any = [];

      try {
        liabilities = liabilityData.map((obj) =>
          !evaluator ? parseFloat(obj[field] || "0") : evaluator(parseFloat(obj[field] || "0"))
        );
      } catch (error) {
        liabilities = liabilities;
      }

      let sum =
        liabilities.length === 0
          ? 0
          : liabilities.reduce((total, num) => {
            return total + num;
          });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  get annualTotalExpenses(): number {
    return this.annualOther + this.annualLiabilityServiceExpenses;
  }

  // Income And Expenses Summary - INCOME
  get summaryAnnualIncome(): number {
    return this.annualTotalIncome;
  }

  get summaryAnnualExpenses(): number {
    return this.annualTotalExpenses;
  }

  get summaryAnnualNetIncome(): number {
    return parseFloat(this.summaryAnnualIncome.toString()) - parseFloat(this.summaryAnnualExpenses.toString());
  }

  get summaryMonthNetIncome(): number {
    return this.summaryAnnualNetIncome / 12.0;
  }

  // Income And Expenses Summary - EXPENSES
  get summaryMaxInstallments(): number {
    return this.summaryMonthNetIncome * this.dataPayload?.salaryProportionFactor;
  }

  // New Feasible Loan Instalment
  get feasibleLoanInstallment(): any {
    let feasibleInstallment = 0;
    let longTermDeduction = 0;
    let personnelIncome = [];
    let totalIncome = 0;

    try {
      personnelIncome = this.incomePayload["personnelIncome"].get() || [];
    } catch (error) {
      personnelIncome = this.incomePayload["personnelIncome"] || [];
    }

    try {
      personnelIncome.map((value: any) => {
        totalIncome =
          totalIncome +
          parseFloat((value?.basicSalary || "0").toString()) +
          parseFloat((value?.fixedAllowance || "0").toString()) +
          parseFloat((value?.averageVariableAllowance || "0").toString());
        longTermDeduction = longTermDeduction + parseFloat((value?.lastThreeAverageDeduction || 0).toString());
      });

      if (this.productCatalogue == this.athamaruwaCatelogue) {
        feasibleInstallment = this.annualTotalIncome - this.annualTotalExpenses - longTermDeduction;
      } else {
        if (isProductOf()) {
          feasibleInstallment = this.annualTotalIncome - this.annualTotalExpenses - longTermDeduction;
        } else {
          feasibleInstallment = this.salaryPropotion - longTermDeduction - this.annualLiability;
        }
      }

      return feasibleInstallment;
    } catch (error) {
      return feasibleInstallment;
    }
  }

  get summaryMaxLoanAmount(): number {
    let term = this.creditDetails?.loanTerms;
    if (term instanceof String) {
      term = parseFloat(term.toString());
    }
    return this.summaryMaxInstallments * (term || 0.0);
  }

  get summaryGrossLoanRequirement(): number {
    // main applicant
    let approvedLoanAmount = this.creditDetails?.loanAmount || 0.0;

    return approvedLoanAmount;
  }

  get summaryRecommendedLoanAmount(): number {
    let maxAmount = 7500000;
    let approvedLoanAmount = this.creditDetails?.loanAmount || 0.0;
    approvedLoanAmount = approvedLoanAmount > maxAmount ? maxAmount : approvedLoanAmount;
    return approvedLoanAmount;
  }

  get summaryLoanTerm(): number {
    let term = this.creditDetails?.loanTerms || 0;
    if (term instanceof String) {
      term = parseFloat(term.toString());
    }
    return term;
  }

  get summaryInstallement(): number {
    return 0.0;
  }

  get salaryPropotion(): number {
    const salaryPayload = this.incomePayload?.personnelIncome;
    let salaryPropotion: number = 0;

    try {
      salaryPropotion = salaryPayload.length ? this.getSalary(salaryPayload) : 0;
    } catch (error) {
      new Error("Controller | salaryPropotion() => " + error);
    } finally {
      return salaryPropotion;
    }
  }

  get annualLiabilityServiceExpenses(): number {
    let total = 0.0;
    total = this.annualLiability * 12;
    return total;
  }

  get incomeDetails(): Array<Object> {
    return [
      {
        key: "Salaried Annual Income",
        value: this.annualSalary,
        tooltip: applicationSummaryDetailsHints?.salaryAnnualIncome ?? "",
      },
      {
        key: "Business Annual Income",
        value: this.annualBusiness,
        tooltip: applicationSummaryDetailsHints?.businessAnnualIncome ?? "",
      },
      {
        key: "Cultivation Annual Income",
        value: this.annualCultivation,
        tooltip: applicationSummaryDetailsHints?.cultivateAnnualIncome ?? "",
      },
      {
        key: "Total Annual Income",
        value: this.annualTotalIncome,
        tooltip: applicationSummaryDetailsHints?.totalAnnualIncome ?? "",
      },
    ];
  }

  get expenseDetails(): Array<Object> {
    return [
      {
        key: "Living Expenses",
        value: this.annualOther,
        tooltip: applicationSummaryDetailsHints?.livingExpenses ?? "",
      },
      {
        key: "Monthly Liability Services Expenses",
        value: this.annualLiability,
        tooltip: applicationSummaryDetailsHints?.liabilityServicesExpenses ?? "",
      },
      {
        key: "Annual Liability Services Expenses",
        value: this.annualLiabilityServiceExpenses,
        tooltip: applicationSummaryDetailsHints?.annualLiabilityServicesExpenses ?? "",
      },
      { key: "", value: "" },
      {
        key: "Annual Expenses + Annual Liability Services Expenses Of the Primary applicant and all the borrowers",
        value: this.annualTotalExpenses,
        tooltip: applicationSummaryDetailsHints?.totalAnnualExpenses ?? "",
      },
    ];
  }

  get incomeAndExpenseSummary(): Array<Object> {
    return [
      {
        key: "Annual Income",
        value: this.summaryAnnualIncome,
        tooltip: applicationSummaryDetailsHints?.annualIncome ?? "",
      },
      {
        key: "Annual Expenses",
        value: this.summaryAnnualExpenses,
        tooltip: applicationSummaryDetailsHints?.annualExpenses ?? "",
      },
      {
        key: "Annual Net Income",
        value: this.summaryAnnualNetIncome,
        tooltip: applicationSummaryDetailsHints?.annualNetIncome ?? "",
      },
      {
        key: "Month Net Income",
        value: this.summaryMonthNetIncome,
        tooltip: applicationSummaryDetailsHints?.monthNetIncome ?? "",
      },
    ];
  }

  get productDetails() {
    const scheme =
      this.productMasterData?.productSchemesMap?.[this.creditDetails?.scheme && this.creditDetails?.scheme]?.[
      "TYPE_NAME"
      ];
    const type =
      this.productMasterData?.productSchemesMap?.[this.creditDetails?.scheme && this.creditDetails?.scheme]?.[
      "TYPE_NAME"
      ];
    const sector =
      this.productMasterData?.productSchemesMap?.[this.creditDetails?.scheme && this.creditDetails?.scheme]?.[
      "TYPE_NAME"
      ];
    const salaryProportionPercentage = this.dataPayload?.salaryProportionFactor * 100;

    return {
      scheme: scheme || "-",
      type: type || "-",
      sector: sector || "-",
      salaryProportionPercentage: salaryProportionPercentage || "-",
    };
  }

  salaryProportionToolTip = (tooltip) => {

    const { salaryProportion = undefined } = AppConfig.config?.underWrittingPolicyConfigs?.[this.productCatalogue]?.loanSummary?.tooltip || {};
    // scheme name, sector name, type name
    if (tooltip.conditional) {
      tooltip.populated = `For ${this.productDetails?.scheme} (${this.creditDetails?.productCode}) Calculated From - ${salaryProportion ? salaryProportion : `(Gross Salary * ${this.productDetails?.salaryProportionPercentage}%)`} `;
    } else {
      tooltip.populated = "";
    }
    return tooltip;
  };

  feasibleLoanInstallmentToolTip = (tooltip) => {
    // scheme name, sector name, type name
    if (this.productCatalogue == this.athamaruwaCatelogue) {
      tooltip.populated = `For ${this.productDetails?.scheme} (${this.creditDetails?.productCode}) Calculated From - (Total Income - Long Term Deductions  - Total Expenses)`;
    } else {
      if (tooltip.conditional) {
        tooltip.populated = `For ${this.productDetails?.scheme} (${this.creditDetails?.productCode}) Calculated From - (Salary proportion ${this.productDetails?.salaryProportionPercentage}%) - Long Term Deductions  - total obligations (except indirect Loan/lease)`;
      } else {
        tooltip.populated = "";
      }
    }
    return tooltip;
  };

  get loanSummary(): Array<Object> {
    return [
      {
        key: "Salary Proportion",
        value: this.salaryPropotion,
        tooltip: this.salaryProportionToolTip(applicationSummaryDetailsHints?.salaryProportion),
      },
      // { key: "Feasible Loan Installment", value: this.summaryMaxInstallments },
      {
        key: "Feasible Loan Installment",
        value: this.feasibleLoanInstallment >= 0 ? this.feasibleLoanInstallment : "0",
        tooltip: this.feasibleLoanInstallmentToolTip(applicationSummaryDetailsHints?.feasibleLoanInstallment),
      },
      {
        key: "Gross Loan Requirement",
        value: this.summaryGrossLoanRequirement,
        tooltip: applicationSummaryDetailsHints?.grossLoanRequirement ?? "",
      },
      {
        key: "Recommended Loan Amount",
        value: this.summaryRecommendedLoanAmount,
        tooltip: applicationSummaryDetailsHints?.recommendedLoanAmount ?? "",
      },
      {
        key: "Loan Tenor",
        value: this.summaryLoanTerm,
        tooltip: applicationSummaryDetailsHints?.loanTenor ?? "",
        round: true,
      },
    ];
  }
}

export const typedData = (masterData, masterType, key) => masterData[masterType]?.find((t) => t?.id === key);
