import appStrings from "../configs/constants/appStrings";
import { incomeComparedDataState, inquiryOfObligationsComparedObject, otherParticipantCompareState, securityDataStateStructure } from "../configs/stores/applicationForm";
import { isArray, isObject } from "../utility/helpers/getObjectTypes";
import { keyValueSanitizer, mainKeyFilterForSanitizing } from "../utility/helpers/validateMandatoryHandler";
import { compareState } from "./compareState";

/**
 *  Service for setting existing user application data to hook state
 * @param data = currently existing user application data
 *
 * // These are main section hook state objects that you wish to set values from user existing data
 * @param latestApplicationData
 * @param personalData
 * @param creditData
 * @param contactData
 * @param addressData
 * @param educationData
 * @param employedData
 * @param businessData
 * @param incomeData
 * @param expenseData
 * @param assetsData
 * @param bankData
 * @param lifeInsuranceData
 */

export const setStateFromTheApplicationFormData = ({ data, personalData, creditData, singleCustomerViewData, contactData, addressData, educationData, employedData, businessData, incomeData, expenseData, assetsData, bankData, guarantorData, jointBorrowerData, taxDetailsData, inquiryOfObligationsData, lifeInsuranceData, securityData }) => {
    let userData = data

    /**
     * Setting existing user data for comparisons with new user input to identify changes
     */

    /**--------------------------------------------------------------------------------------------------------------------------------------
     * prepareState :- prepareState is taken three parameters which are
     *                  1. existing Data (Default Data)
     *                  2. the key of the object (ex: personalData, creditData ...etc)
     *                  3. db stored data
     *                 In this case if db stored data is an object, its created a new object and return it. if its not its return an array.
     *                        i.currentState = Default Data;
     *                       ii.incomingState = db stored data object or array
     *
     * compareState :- compareState is taken three parameters which are
     *                  1. existing Data (Default Data)
     *                  2. incomingState (is comming from *prepareState)
     *                  3. objects with empty array values (not Mandatory)
     *                  for more details refer compareState.ts file.
     * ---------------------------------------------------------------------------------------------------------------------------------------
     */

    //personal data
    if (userData?.[appStrings?.mainSectionKeys?.personalData]) {
        const { currentState, incomingState } = prepareState(personalData, appStrings?.mainSectionKeys?.personalData, userData);
        const comparedState = compareState(currentState, incomingState);
        personalData.set({ ...comparedState });
    }

    //credit data
    if (userData?.[appStrings?.mainSectionKeys?.creditData]) {
        const { currentState, incomingState } = prepareState(creditData, appStrings?.mainSectionKeys?.creditData, userData);
        const comparedState = compareState(currentState, incomingState);
        creditData.set({ ...comparedState });
    }
    //Athamaru Account Details
    if (userData?.[appStrings?.mainSectionKeys?.singleCustomerViewData]) {
        const { currentState, incomingState } = prepareState(singleCustomerViewData, appStrings?.mainSectionKeys?.singleCustomerViewData, userData);
        const comparedState = compareState(currentState, incomingState);
        singleCustomerViewData.set({ ...comparedState });
    }

    //contact data
    if (userData?.[appStrings?.mainSectionKeys?.contactData]) {
        const { currentState, incomingState } = prepareState(contactData, appStrings?.mainSectionKeys?.contactData, userData);
        const comparedState = compareState(currentState, incomingState);
        contactData.set({ ...comparedState });
    }

    //address data
    if (userData?.[appStrings?.mainSectionKeys?.addressData]) {
        const { currentState, incomingState } = prepareState(addressData, appStrings?.mainSectionKeys?.addressData, userData);
        const comparedState = compareState(currentState, incomingState);
        addressData.set({ ...comparedState });
    }

    //education data
    if (userData?.[appStrings?.mainSectionKeys?.educationData]) {
        const { currentState, incomingState } = prepareState(educationData, appStrings?.mainSectionKeys?.educationData, userData);
        const comparedState = compareState(currentState, incomingState);
        educationData.set({ ...comparedState });
    }

    //employment data
    if (userData?.[appStrings?.mainSectionKeys?.employedData]) {
        const { currentState, incomingState } = prepareState(employedData, appStrings?.mainSectionKeys?.employedData, userData);
        const comparedState = compareState(currentState, incomingState);
        employedData.set(comparedState);
    }

    //business data
    if (userData?.[appStrings?.mainSectionKeys?.businessData]) {
        const { currentState, incomingState } = prepareState(businessData, appStrings?.mainSectionKeys?.businessData, userData);
        const comparedState = compareState(currentState, incomingState);
        businessData.set(comparedState);
    }

    //income data
    if (userData?.[appStrings?.mainSectionKeys?.incomeData]) {
        const { currentState, incomingState } = prepareState(incomeData, appStrings?.mainSectionKeys?.incomeData, userData);

        //workaround for populate the state

        const comparedState = compareState(incomeComparedDataState, incomingState, currentState);
        incomeData.set(comparedState);
    }

    //expense data
    if (userData?.[appStrings?.mainSectionKeys?.expenseData]) {
        const { currentState, incomingState } = prepareState(expenseData, appStrings?.mainSectionKeys?.expenseData, userData);
        const comparedState = compareState(currentState, incomingState);
        expenseData.set(comparedState);
    }

    //assets data
    if (userData?.[appStrings?.mainSectionKeys?.assetsData]) {
        const { currentState, incomingState } = prepareState(assetsData, appStrings?.mainSectionKeys?.assetsData, userData);
        const comparedState = compareState(currentState, incomingState);
        assetsData.set(comparedState);
    }
    //life Insurance data
    if (userData?.[appStrings?.mainSectionKeys?.lifeInsuranceData]) {
        const { currentState, incomingState } = prepareState(lifeInsuranceData, appStrings?.mainSectionKeys?.lifeInsuranceData, userData);
        const comparedState = compareState(currentState, incomingState);
        lifeInsuranceData.set(comparedState);
    }

    //bank data
    if (userData?.[appStrings?.mainSectionKeys?.bankData]) {
        const { currentState, incomingState } = prepareState(bankData, appStrings?.mainSectionKeys?.bankData, userData);
        const comparedState = compareState(currentState, incomingState);
        bankData.set(comparedState);
    }

    //guarantor data
    if (userData?.[appStrings?.mainSectionKeys?.guarantorData] && userData?.[appStrings?.mainSectionKeys?.guarantorData].length > 0) {
        const { currentState, incomingState }: any = prepareState(guarantorData, appStrings?.mainSectionKeys?.guarantorData, userData);
        const comparedState = compareState(currentState, incomingState);
        guarantorData.set(comparedState);
    }

    //joint borrower data
    if (userData?.[appStrings?.mainSectionKeys?.jointBorrowerData]) {
        if (userData?.[appStrings?.mainSectionKeys?.jointBorrowerData].length > 0) {
            const { currentState, incomingState }: any = prepareState(jointBorrowerData, appStrings?.mainSectionKeys?.jointBorrowerData, userData);
            const comparedState = compareState([otherParticipantCompareState], incomingState);
            jointBorrowerData.set(comparedState);
            mainKeyFilterForSanitizing(jointBorrowerData);
        } else {
            personalData.addJointBorrower.set(0)
        }
    }

    //tax data
    if (userData?.[appStrings?.mainSectionKeys?.taxDetailsData]) {
        const { currentState, incomingState } = prepareState(taxDetailsData, appStrings?.mainSectionKeys?.taxDetailsData, userData);
        const comparedState = compareState(currentState, incomingState);
        taxDetailsData.set(comparedState);
    }

    // Inquiry of Obligation data
    if (userData?.[appStrings?.mainSectionKeys?.inquiryOfObligationsData]) {
        const { currentState, incomingState } = prepareState(inquiryOfObligationsData, appStrings?.mainSectionKeys?.inquiryOfObligationsData, userData);
        const comparedState = compareState(inquiryOfObligationsComparedObject, incomingState, currentState);
        inquiryOfObligationsData.set(comparedState);
    }

    // Security dta
    if (userData?.[appStrings?.mainSectionKeys?.securityData]) {
        const { currentState, incomingState } = prepareState(securityData, appStrings?.mainSectionKeys?.securityData, userData);
        const comparedState = compareState(securityDataStateStructure, incomingState, currentState);
        securityData.set(comparedState);
        securityDetailsToBeSanitized(securityData);
    }
};

//-------------------------------------------------------------------------------------------------------

/**
 *
 * @param latestApplicantData = hook state state which contains latest user inputs
 * @param sectionName = object key of latestApplicantData
 * @param data = data you wish to set
 */
export const updateStateFromUserData = (latestApplicantData, sectionName, data, pureObject = false) => {
    const currentDataObj: any = {
        ...JSON.parse(JSON.stringify(latestApplicantData.value)),
    };

    currentDataObj[sectionName] = data && pureObject ? data : JSON.parse(data);

    latestApplicantData.set(currentDataObj);
};

//--------------------------------- Helper functions ------------------------------

const prepareState = (existingState, stateKey, incomingChanges) => {
    const currentState = JSON.parse(JSON.stringify(existingState?.["value"]));
    let incomingState: any = null;
    if (isObject(incomingChanges?.[stateKey])) {
        incomingState = { ...incomingChanges?.[stateKey] };
    } else if (isArray(incomingChanges?.[stateKey])) {
        incomingState = incomingChanges?.[stateKey];
    }

    return {
        currentState,
        incomingState,
    };
};

const securityDetailsToBeSanitized = (comparedState, recurse = false) => {
    for (const key in comparedState) {
        if (!key) return;
        if (key === "guarantors") {
            mainKeyFilterForSanitizing(comparedState[key]);
        } else if (key === "sectionErrorStatus") {
            continue;
        } else if (key === "securityType") {
            continue;
        } else {
            if (recurse) {
                let retunedValue = keyValueSanitizer(comparedState[key]);
                retunedValue = JSON.parse(JSON.stringify(retunedValue));
                comparedState[key].set(retunedValue);
            } else {
                securityDetailsToBeSanitized(comparedState[key], true);
            }
        }
    }
};
