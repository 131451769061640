import { Downgraded } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { userDetails } from "../../../../../configs/mainStore";
import { getCoreBankData } from "../../../../../services/bankServiceInvorker";
import { disbursementChecklistItemStatusChange, getDisbursementChecklistList } from "../../../../../services/creditFileApiCall";
import { convertNumberToWords } from "../../../../../utility/util";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import DisbursementCheckListRow from "./DisbursementCheckListRow";
import DisbursementVerticalTabComponent from "./DisbursementVerticalTabComponent";

const DisbursementCheckList = ({ data, applicationId }) => {
  const [selectedTabLabel, setSelectedTabLabel]: any = React.useState<string>('Second Disbursement');
  const [disbursementScheduleData, setDisbursementScheduleData]: any = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [doneChecklistList, setDoneCheckList] = React.useState<any>();
  const [pendingChecklistList, setPendingCheckList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isChanged, setIsChanged]: any = React.useState(false);
  const [isDisabled, setIsDisabled]: any = React.useState(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  let tabList: any = [];
  let display: any = true;

  const innerComponentData = {
    id: "disbursement_check_list",
    title: selectedTabLabel,
    componentId: "DisbursementCheckList",
    headerActionButton: true,
    headerActionButtonTitle: "Create Checklist",
    modalBodyKey: "disbursement-checklist",
  };

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getDisbursementChecklistList(applicationId, 'disbursement', selectedTabLabel.replaceAll(" ", "_").toLowerCase());

      if (data) {
        setDoneCheckList(data?.fundReleaseDoneList);
        setPendingCheckList(data?.fundReleasePendingList);
      } else {
        setErrorMessage("Something went Wrong!");
      }
      setIsLoading(false);
    })();
  }, [applicationId, isChanged, selectedTabLabel]);

  const statusUpdate = async (itemId, isChecked, setIsChecked) => {
    setIsChecked(!isChecked);

    const result = await disbursementChecklistItemStatusChange(
      applicationId,
      itemId,
      isChecked
    );

    if (result?.msg) {
      addToaster({
        status: "success",
        title: "Updated",
        message: "Check List Item Updated",
      });
    } else if (result?.error) {
      addToaster({
        status: "warning",
        title: "Not Updated",
        message: "Update Error",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went Wrong!",
      });
    }
  };

  useEffect(() => {
    (async function () {
      const disbursementSchedule = await getCoreBankData(
        applicationId,
        CORE_BANK_KEYS.DISB_SCHEDULE
      );

      setDisbursementScheduleData(disbursementSchedule);
    })();
  }, []);

  if (disbursementScheduleData) {
    for (const [key, value] of Object.entries(disbursementScheduleData)) {
      const data: any = key;
      tabList.push(`${convertNumberToWords(data)} Disbursement`)
    }
  }

  const callBackOnAction = () => {
    setIsChanged(!isChanged);
  };

  const onClickCreate = (modalBodyKey) => {

    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        applicationId: applicationId,
        type: 'disbursement',
        subType: selectedTabLabel.replaceAll(" ", "_").toLowerCase(),
        callBackOnAction: callBackOnAction,
      },
    });
  };

  return (
    <div>
      {
        display ? <Stack direction={'row'}>
          <Stack>
            <DisbursementVerticalTabComponent
              textColor={"primary"}
              variant={"scrollable"}
              indicatorColor={"primary"}
              tabs={tabList}
              setSelectedTabLabel={setSelectedTabLabel}
              disbursementScheduleData={disbursementScheduleData}
              labelStyle={{
                textTransform: "none",
                fontSize: "16px",
                fontFamily: "Poppins, Medium",
                borderRight: 1,
                borderColor: "divider",
                alignItems: "flex-start",
              }} />
          </Stack> <Stack flex={1}>
            <InnerSectionHeaderComponent
              innerComponentData={innerComponentData}
              applicationId={applicationId}
              currentApplicationWfData={{}}
              callBackOnAction={callBackOnAction}
              disabled={undefined}
            >
              <ButtonComponent title={"Create Checklist"} variant="contained" onClick={() => onClickCreate(innerComponentData?.modalBodyKey)} />
              <Box ml={0.5} mr={0.5}></Box>
            </InnerSectionHeaderComponent>
            <Grid container className="inner-component-height">
              <Stack spacing={1} m={1} className="full-width">
                {isLoading ? (
                  <CircularProgressComponent
                    size={30}
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: 100,
                    }}
                  />
                ) : errorMessage ? (
                  <ErrorMessageComponent
                    headMessage={"Error!"}
                    errorMessage={errorMessage}
                  />
                ) : pendingChecklistList?.length > 0 ||
                  doneChecklistList?.length > 0 ? (
                  <>
                    {pendingChecklistList?.map((item, i) => {
                      return (
                        <DisbursementCheckListRow
                          itemObj={item}
                          statusUpdate={statusUpdate}
                          key={i}
                          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                        />
                      );
                    })}
                    {doneChecklistList?.map((item, i) => {
                      return (
                        <DisbursementCheckListRow
                          itemObj={item}
                          statusUpdate={statusUpdate}
                          key={i}
                          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                        />
                      );
                    })}
                  </>
                ) : (
                  <ErrorMessageComponent
                    headMessage={"No data available"}
                    errorMessage={""}
                  />
                )}
              </Stack>
            </Grid>
          </Stack>
        </Stack> : <></>
      }
    </div>
  );

};

export default DisbursementCheckList;
