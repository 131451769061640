import { Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

interface PreviewRowProps {
  Icon?: any;
  subTxt: string;
  mainTxt: string;
  color?: any;
  designation?: string;
  secondaryTxt?: string;
}

export const IconRowComponent: FC<PreviewRowProps> = ({ Icon, subTxt = "", mainTxt, color, secondaryTxt = "", designation = "" }) => {
  return (
    <Stack direction="row" spacing={2} alignItems={"center"}>
      {Icon && (
        <Grid item>
          <Icon
            sx={{
              width: "12px",
              height: "12px",
              color: color,
              background: `${color}3d`,
              borderRadius: "30px",
              padding: "9px 10px",
            }}
          />
        </Grid>
      )}
      <Grid item xs={24} style={{ textAlign: "left" }}>
        {subTxt && (
          <Grid item xs={24}>
            <Typography variant="caption" color={grey[500]}>
              {subTxt}
            </Typography>
          </Grid>
        )}
        <Grid item xs={24} component="div">
          <div style={{
            display: "inline-block",
            whiteSpace: "pre-line",
            flexWrap: "wrap",
            wordWrap: "break-word",
          }}>{mainTxt}</div>
          {secondaryTxt && <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{secondaryTxt}</div>}
          {designation && <div style={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "small" }}>{designation}</div>}
        </Grid>
      </Grid>
    </Stack>
  );
};
