import { Downgraded, useState } from "@hookstate/core";
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { creditData, workflowMasterData } from "../../../../../configs/mainStore";
import { getProductStageOrder } from "../../../../../services/configurationApis";
import { getHistoryDataForApplication } from "../../../../../services/creditFileApiCall";
import { revertStageTo } from "../../../../../services/workflowApiCalls";
import { userName } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

export default (props) => {
    const creditFileData: any = creditData.attach(Downgraded).get();
    const { wf_stages }: any = useState(workflowMasterData);
    const handler = props.revertHandled || (() => { });
    const reverting = useState<boolean>(false);
    const wfStages = wf_stages.attach(Downgraded).get();
    let { previous = false, payload, applicationId }: any = props;

    const loading = useState<boolean>(true);

    useEffect(() => {
        if (!previous && payload) {
            loading.set(false);
        } else {
            getLastStageFromHistory();
        }
    }, []);


    /**
     * Retrieves the last stage from the history of an application.
     *
     * @return {Promise<void>} - A promise that resolves when the last stage is retrieved.
     */
    const getLastStageFromHistory = async () => {
        try {
            let [res, stageData] = await Promise.all([getHistoryDataForApplication(applicationId), getProductStageOrder()]);
            const currentWfStage = stageData[creditFileData?.currentProductCatalogKey];
            for (let i = currentWfStage.length - 1; i >= 0; i--) {
                if (currentWfStage[i] === creditFileData.currentWFStage) {
                    payload.nextStage = currentWfStage[i - 1];
                    break;
                }
            }

            const latestRecord = res?.[0];
            const latestRecordPrev = res?.[1];
            if (latestRecord) {
                payload.applicationId = applicationId;
                payload.sequence = latestRecord.sequence - 1;
                payload.actionBy = latestRecordPrev.actionBy;
                payload.assignTo = latestRecord.actionBy;
            }
        } catch (e) {
        } finally {
            loading.set(false);
        }
    };

    const renderStage = (stageId) => {
        let stage = wfStages.filter((el) => el.id === stageId);
        return stage?.[0]?.name;
    };

    const revertToStage = async () => {
        try {
            reverting.set(true);
            const stageData = await revertStageTo(payload.applicationId, payload.sequence, payload.nextStage, props?.type);
            if (stageData) {
                handler(stageData);
            }
            reverting.set(false);
            closeGlobalModal();
        } catch (e) {
            closeGlobalModal();
        }
    }

    const BuildStageReset = () => {
        if (!payload) {
            return <></>;
        }

        const items = [
            { "title": "Action By", "value": userName(payload.actionBy) },
            { "title": "Assign To", "value": userName(payload.assignTo) },
            { "title": " ---------------- ", "value": " -------------------------------- " },
            { "title": "Current Stage", "value": renderStage(creditFileData.currentWFStage) },
            { "title": "Target Stage", "value": renderStage(payload.nextStage) },
            { "title": "Application Id", "value": payload.applicationId },
        ];

        const BuildItem = ({ title, value }) => {
            if (value?.toString()?.includes("-------")) {
                return <Divider sx={{ marginTop: '10px', marginBottom: '10px' }}></Divider>;
            }
            return <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginBottom: '10px' }} alignItems={'center'}>
                <Typography variant="subtitle1" fontWeight={200} color={'gray'}>{title}</Typography>
                <ChipsComponent label={<Typography variant="caption">{value}</Typography>}></ChipsComponent>
            </Stack>;
        }

        return <Stack>
            <Stack flexWrap={'wrap'} mt={2}>
                {items.map((obj: any, index) => (
                    <BuildItem key={index} title={obj.title} value={obj.value} />
                ))}
            </Stack>

            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }}></Divider>

            <Stack direction={'row-reverse'}>
                <ButtonComponent
                    variant="contained"
                    size={'small'}
                    onClick={() => {
                        closeGlobalModal();
                    }}
                    title={"Close"} />
                <Stack ml={2}></Stack>
                {reverting.get() && <CircularProgressComponent size={24} />}
                {!reverting.get() && <ButtonComponent
                    variant="contained"
                    size={'small'}
                    color={'warning'}
                    onClick={revertToStage}
                    title={"Apply"} />}
            </Stack>
        </Stack>;
    }

    return <Stack>
        <Stack direction={'row'}>
            <Typography variant="subtitle2"> Application Workflow Stage Change </Typography>
        </Stack>
        <Divider></Divider>
        {loading.get() && <Stack m={2} height={"250px"} alignItems={'center'} justifyContent={'center'}><CircularProgressComponent size={24} /></Stack>}
        {!loading.get() && <BuildStageReset></BuildStageReset>}
    </Stack>;
}
