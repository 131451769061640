import { Downgraded } from "@hookstate/core";
import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { postalCodeDetails } from "../../../../../../../configs/mainStore";
import isProductOf from "../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { LengthItemValidate } from "../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { addItem } from "../Helpers";

export const BusinessDetailsPartnership = ({ business, isMainContactDetails }) => {
  const partnershipData = LengthItemValidate.validatedArray(business?.partnershipDetails || []);
  const masterData = MasterDataProvider.provideMasterData();
  const postalCodeState = postalCodeDetails.attach(Downgraded).get();

  const masterDataObjProvider = (mapedPartnershipData) => {
    const province = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.PROVINCE, mapedPartnershipData?.partnershipD_province);
    const district = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.DISTRICT, mapedPartnershipData?.partnershipD_distric);
    const postalCode = masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.POSTAL_CODE, postalCodeState[mapedPartnershipData?.partnershipD_postalCode]?.id);
    return {
      province,
      district,
      postalCode,
    };
  };
  const BuildPartnerShipDataDetails = ({ partnerShipData, index, masterDataObject }) => {
    return (
      <Grid mb={2} container key={`container-build-partner-${index}`}>
        <Grid container item p={1} spacing={1} className="data-box">
          {addItem(partnerShipData, "Name", partnerShipData?.partnershipD_name ?? "N/A")}
          {addItem(partnerShipData, "New NIC", partnerShipData?.partnershipD_newNic ?? "N/A")}
          {addItem(partnerShipData, "Old NIC", partnerShipData?.partnershipD_oldNic ?? "N/A")}
          {addItem(partnerShipData, "Relationship Witht The Primary Applicant", partnerShipData?.partnershipD_relationshipWithtThePrimaryApplicant ?? "N/A")}
          {addItem(partnerShipData, "Address Line 1", partnerShipData?.partnershipD_addressLine1 ?? "N/A")}
          {addItem(partnerShipData, "Address Line 2", partnerShipData?.partnershipD_addressLine2 ?? "N/A")}
          {addItem(partnerShipData, "Address Line 3", partnerShipData?.partnershipD_addressLine3 ?? "N/A")}

          {addItem(partnerShipData, "Address Line 4", partnerShipData?.partnershipD_addressLine4 ?? "N/A")}
          {addItem(partnerShipData, "City", partnerShipData?.partnershipD_city ?? "N/A")}

          {addItem(partnerShipData, "Province", masterDataObject?.province ?? "N/A")}
          {addItem(partnerShipData, "District", masterDataObject?.district ?? "N/A")}
          {addItem(partnerShipData, "Postal Code", masterDataObject?.postalCode ?? "N/A")}
        </Grid>
      </Grid>
    );
  };

  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <div className="full-width margin-xs-b">
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
          Partnership Details
        </Grid>

        {partnershipData.length > 0 &&
          LengthItemValidate.validatedArray(partnershipData).map((partnerShipData, index) => {
            const masterDataObject = masterDataObjProvider(partnerShipData);
            return <BuildPartnerShipDataDetails index={partnerShipData?.dataId || index} partnerShipData={partnerShipData} masterDataObject={masterDataObject} />;
          })}
      </div>
    );
  } else {
    return <></>;
  }
};
