export class LengthItemValidate {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() { }

  private static isArrayEmpty(array: any[]): any[] {
    return array.length > 0 ? array : [];
  }

  private static isFilteredArray(array: Array<any>): Array<any> {
    array = array.filter(item => item);
    try {
      return array.filter((obj) => Object.keys(obj).length > 0 && obj.get() !== null);
    } catch (error) {
      return array.filter((obj) => Object.keys(obj).length > 0 && obj !== null);
    }
  }

  private static filterRemovedItems(array: Array<any>): any[] {
    try {
      return array.filter((obj) => !obj["removedItem"].get());
    } catch (error) {
      return array.filter((obj) => !obj["removedItem"]);
    }
  }

  static validatedArray(array: any): Array<any> {

    if (!array) return [];

    array = this.isArrayEmpty(array);

    array = this.isFilteredArray(array);

    array = this.filterRemovedItems(array);

    return this.isArrayEmpty(array) ? array : [];
  }
}
