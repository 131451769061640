import { Grid } from "@mui/material";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { creditComponentTitleMapping } from "../../../../../configs/constants/contants";

export const SummaryAccordion = ({
  handleAccordionExpand,
  componentId,
  componentMapping,
  otherParams,
  expanded,
}) => {
  const onClickIcon = (e, index) => {};
  return (
    <Accordion
      key={componentId}
      expanded={expanded == componentId}
      onChange={handleAccordionExpand(componentId)}
      sx={{ marginBottom: 2}}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{  backgroundColor: "#e6f0fa", height: '10px' }}
      >
        {/* <Grid
          container
          // style={{ backgroundColor: "#e6f0fa" }}
          className="align-center"
        > */}
          <Grid item xs>
            <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">
              {creditComponentTitleMapping[componentId]}
            </div>
          </Grid>
        {/* </Grid> */}
        {/* <Typography className={"warning-credit"}>{creditComponentTitleMapping[componentId]}</Typography> */}
      </AccordionSummary>
      <AccordionDetails>
        {componentId &&
          componentMapping[componentId] &&
          React.createElement(componentMapping[componentId], otherParams)}
      </AccordionDetails>
    </Accordion>
  );
};
