import { Stack } from '@mui/material'
import { TrailCalculatorLayout } from '../../../../TrailCalculator/TrailCalculatorLayout'

const TrialCalculator = ({ dataCollection }) => {
    return (
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ width: 1000 }}>
            <TrailCalculatorLayout isDirect={true} />
        </Stack>
    )
}

export default TrialCalculator