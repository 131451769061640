import { useState } from "@hookstate/core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getApplicationJson } from "../../../../services/applicationApis";
import useApplication from "../../../../services/useApplication";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { Toast } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

const nic = ({ newNic = "", oldNic = "" }) => [oldNic, newNic].filter(obj => obj).join("/");
const fullName = ({ initialsInFull = "", primaryLastName = "" }) => {
  return [initialsInFull || "", primaryLastName || ""]?.filter(obj => obj)?.join(" ");
}
const validApplicant = ({ personalData }) => {
  return personalData?.individualId?.toString() && personalData?.individualId?.toString() != "0" && personalData?.dob && nic(personalData || {})?.replaceAll("/", "")?.length > 0;
};

const ApplicationSubmit = ({ data, applicationId, currentApplicationWfData, productData, }) => {
  const submitting = useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const index = useState(-1);
  const [validityMap, setValidityMap] = React.useState({});
  const [saveContent, setSaveContent]: any = React.useState(null);

  const { isLoading, error, application, saveEditedContent, syncApplication, resubmitApplication, deleteInvalidApplicants } = useApplication(getApplicationJson, applicationId, refresh);

  useEffect(() => {
    if (!application?.APPLICANT_FORM_DATA) {
      return;
    }

    const map = {};

    const { personalData = {}, securityData: { guarantors = [] } = {}, jointBorrowerData = [] } = application?.APPLICANT_FORM_DATA || {};

    try {
      map["mainApplicant"] = validApplicant(personalData);
      map["guarantors"] = guarantors.map(gr => validApplicant(gr.personalData));
      map["jointBorrowers"] = (jointBorrowerData)?.map(jb => validApplicant(jb.personalData));
    } catch (e) {
      console.log(e);
    }

    setValidityMap(map);
  }, [application]);

  const onChanged = (content) => {
    const diff = JSON.stringify(application?.APPLICANT_FORM_DATA) != JSON.stringify(content);
    setSaveContent(diff ? JSON.stringify(content) : null);
  }

  const hasInValid = () => {
    const { personalData, securityData: { guarantors = [] } = {}, jointBorrowerData = [] } = application?.APPLICANT_FORM_DATA || {};
    for (const grt of [{ personalData: personalData }, ...guarantors, ...jointBorrowerData]) {
      if (!validApplicant(grt.personalData)) {
        return true;
      }
    }
    return false;
  }

  const mainApplicantNotSynced = application?.APPLICANT_FORM_DATA?.personalData?.individualId?.toString() === "0";

  const ContentValidator = ({ onChanged }) => {
    const [content, setContent]: any = React.useState({});
    const [mode, setMode] = React.useState(-1);
    const [node, setNode] = React.useState(null);
    const [subSegments, setSubSegments]: any = React.useState({});

    useEffect(() => {
      const segmentation = {};
      let data = JSON.parse(JSON.stringify(application?.APPLICANT_FORM_DATA));
      segment(data);

      segmentation[-1] = JSON.parse(JSON.stringify(application?.APPLICANT_FORM_DATA));;
      segmentation[3] = data?.securityData?.guarantors || [];
      segmentation[2] = data?.jointBorrowerData || [];

      delete data?.securityData?.guarantors;
      delete data?.jointBorrowerData;

      segmentation[1] = data;;

      setContent(segmentation);
    }, []);

    const segment = (data) => {
      const segmentation = {};

      segmentation[1] = [{ individualId: data?.personalData?.individualId, nic: nic(data?.personalData), name: fullName(data?.personalData || {}) }];
      segmentation[2] = (data?.jointBorrowerData || []).map((item, index) => { return { index, individualId: item?.personalData?.individualId, nic: nic(item?.personalData || {}), name: fullName(item?.personalData || {}) } });
      segmentation[3] = (data?.securityData?.guarantors || []).map((item, index) => { return { index, individualId: item?.personalData?.individualId, nic: nic(item?.personalData), name: fullName(item?.personalData || {}) } });

      setSubSegments(segmentation);
    }

    return <Stack flex={1} direction={"row"} spacing={1}>
      <Stack flex={0.3} >
        <Stack height={"100% "}>
          {Object.keys(subSegments).map((index) => {
            const seg = subSegments[index];
            const segTitle = index === "1" ? "Main Applicant" : index === "2" ? "Joint Borrower" : "Guarantor";
            return <Stack mt={1}>
              <Typography variant="caption">{segTitle}</Typography>
              {
                seg.map((item, idx) => {
                  return <Stack key={parseInt(index) + 200} direction={"row"}>
                    <Stack><ContentCopyIcon sx={{ color: 'blue', fontSize: '8pt', marginTop: '5px', width: '20px', cursor: "pointer" }} onClick={() => { navigator.clipboard?.writeText(JSON.stringify(content?.[index]?.[idx] || content?.[index] || {})); Toast.success(item.name + " copied") }} /></Stack>
                    <Stack flex={1}><Typography variant="caption" color={'gray'}>{item.name}</Typography></Stack>
                    {validApplicant(content?.[index]?.[idx] || content?.[index]) ? <CheckIcon sx={{ color: 'green', fontSize: '12pt', marginTop: '5px', width: '20px' }}></CheckIcon> : <DeleteIcon onClick={() => deleteInvalidApplicants(index, submitting)} sx={{ color: 'red', fontSize: '12pt', marginTop: '5px', width: '20px' }} />}
                  </Stack>;
                })
              }
            </Stack>;
          })}
        </Stack>
        <Divider></Divider>
        <Stack direction={"row"} my={1}>
          <ButtonComponent size={"small"} onClick={() => {
            let details = content?.[-1];
            let [jb, gr] = [details.hasOwnProperty("jointBorrowerData"), details.securityData.hasOwnProperty("guarantors")];
            if (mode === 1) {
              details = content?.[1];
              if (jb) {
                details.jointBorrowerData = content?.[2];
              }
              if (gr) {
                details.securityData.guarantors = content?.[3];
              }
            } else if (mode === 2) {
              details = content?.[-1];
              details.jointBorrowerData = content?.[2];
            } else if (mode === 3) {
              details = content?.[-1];
              details.securityData.guarantors = content?.[3];
            }
            onChanged(details);
          }} title={"Check"} variant={'outlined'}></ButtonComponent>
        </Stack>
        {(subSegments[mode] && subSegments[mode].length > 0) && <Stack direction={"row"} my={1}>
          {subSegments[mode].map((applicant: any, idx) => {
            const { individualId, nic, index } = applicant;
            const title = individualId || nic;
            return <ButtonComponent size="small" key={parseInt(idx) + 350}
              onClick={() => {
                const cont = content?.[mode]?.[index];
                setNode(cont);
              }}
              title={title.toString()}></ButtonComponent>;
          })}
        </Stack>}
        <Stack direction={"row"} my={1}>
          <ButtonComponent size="small" onClick={() => {
            setMode(1);
            setNode(null);
          }} title={"PA"} variant={mode === 1 ? 'contained' : 'none'}></ButtonComponent>
          <ButtonComponent size="small" onClick={() => {
            setMode(2);
            setNode(null);
          }} title={"JB"} variant={mode === 2 ? 'contained' : 'none'}></ButtonComponent>
          <ButtonComponent size="small" onClick={() => {
            setMode(3);
            setNode(null);
          }} title={"GR"} variant={mode === 3 ? 'contained' : 'none'}></ButtonComponent>
          <ButtonComponent size="small" onClick={() => {
            setMode(-1);
            setNode(null);
          }} title={"All"} variant={mode === -1 ? 'contained' : 'none'}></ButtonComponent>
        </Stack>
      </Stack>
      <Stack flex={1} width={"100%"}>
        <TextBoxComponent inputProps={{ style: { fontSize: 10 } }} multiline={true} style={{ fontSize: "8pt" }} value={JSON.stringify(node || content[mode], undefined, 2)} rows={20} onChange={(e) => {
          const data = JSON.parse(e.target.value);
          setContent({ ...content, [mode]: data });
        }}></TextBoxComponent>
      </Stack>
    </Stack >;
  }

  if (isLoading) {
    return <Stack direction={"column"} flex={1} sx={{ maxHeight: '200px' }}>
      <Typography variant="subtitle1" color={"gray"} sx={{ mt: 1 }}>
        Application Data
      </Typography>
      <Stack direction={"row"} flex={1} justifyContent={"center"} alignItems={"center"} sx={{ height: "100px" }}>
        <CircularProgressComponent size={20} />
      </Stack>
    </Stack>;
  }

  return <Stack flex={1}>
    <Stack direction={"row"}>
      <Stack flex={1}>
        <Typography variant="subtitle1" color={"gray"} sx={{ mt: 1 }}>
          Application Data
        </Typography>
      </Stack>
      <CloseIcon
        onClick={() => {
          closeGlobalModal();
        }}
      />
    </Stack>
    <Typography variant="caption" color={"gray"} sx={{ mb: 2 }}>
      {!hasInValid() ? "Applicant details are synced" : "Applicant details are partially synced"}
    </Typography>
    <Divider></Divider>
    <Stack direction={"row"} flex={1} alignItems={'start'}>
      {isLoading || submitting.get() ? <CircularProgressComponent size={20} /> : <ContentValidator onChanged={onChanged}></ContentValidator>}
    </Stack>
    <Divider></Divider>
    <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
      {!hasInValid() && <Stack></Stack>}
      {hasInValid() && <Stack direction="row" spacing={2}>
        {index.get() === -1 && <ButtonComponent size={'small'} title="Refresh" startIcon={<RefreshIcon />} variant="outlined" onClick={() => { setRefresh(!refresh); setSaveContent(null); }} loadingbtn={true} color="success" />}
        {mainApplicantNotSynced && <ButtonComponent size={'small'} title="Sync" startIcon={<RefreshIcon />} variant="outlined" onClick={() => syncApplication(data, index, submitting)} loadingbtn={true} color="info" />}
        {mainApplicantNotSynced && <ButtonComponent size={'small'} title="Submit" startIcon={<RefreshIcon />} variant="outlined" onClick={() => resubmitApplication(data, index, submitting)} loadingbtn={true} color="info" />}
        {saveContent && <ButtonComponent size={'small'} title="Save" variant="outlined" onClick={() => saveEditedContent(saveContent)} loadingbtn={true} color="info" />}
      </Stack>}
      {!hasInValid() && <Stack direction="row">
        <ButtonComponent size={'small'} title="Close" variant="outlined" onClick={() => closeGlobalModal()} color="info" />
      </Stack>}
    </Stack>
  </Stack>;
};

export default ApplicationSubmit;
