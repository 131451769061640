import Api from "./apiCallsHandler";
import { endpoints } from "../configs/endpoints";

const api = Api.getApi();

/**
 * download collection histories
 */
export const downloadCollectionHistories = async (data) => {
  try {
    const res = await api.post(`${endpoints.downloadCollectionHistories}`, {
      ...data,
    });

    return res;
  } catch (error) {
    return null;
  }
};

export const downloadCmsContracts = async (data) => {
  try {
    const res = await api.post(`${endpoints.downloadCmsContracts}`, {
      ...data,
    });

    return res;
  } catch (error) {
    return null;
  }
};
