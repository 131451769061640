const appFromBuilder = {
  formId: "",
  participantType: 1,
  form: [],
  otherParticipantForms: [],
  otherParticipantForm: [],
  update: false,
  masterData: [],

  sectionsLoading: false,
  isPrimaryApplicant: true,
  primaryApplicantUpdated: false,
  previousPrimaryApplicantForm: [],

  tempFormsHolder: [],
  productDataList: [],
  productData: undefined,
};

export { appFromBuilder };
