import { addToaster } from "../components/OtherComponents/GlobalToast";

/**
 *  Use this function to display an alert
 * @param is you have to pass true for returning error alert
 * @message text ypu want to display
 */

export const formSavingAlert = (error = false, message: any = null) => {
  error
    ? addToaster({
        status: "error",
        title: "Error",
        message: message ? message : "Something went wrong",
      })
    : addToaster({
        status: "success",
        title: "Done",
        message: message ? message : "Saved successfully !",
      });
};
