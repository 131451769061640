import { Downgraded, useState } from "@hookstate/core";
import { Box, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { creditData } from "../../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { DuplicateKeyReceivedActivateState, InsuranceAvailableActivateState, WarrantyAvailableSection } from "../../SecurityTypeSubComponents/SecurityTypeSubComponent";

export const ImmovableMachineryEquipement = (props) => {
  const { t: translate } = useTranslation();
  const [loanArr, setLoanArr]: any = React.useState([]);
  const [fullObj, setFullObj]: any = React.useState({});
  const [count, setCount]: any = React.useState(1);
  const [loading, setLoading]: any = React.useState(false);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const masterDataStore: any = MasterDataProvider.provideMasterData();

  const lawyersData: any[] = [
    { id: 1, name: "Lawyer1" },
    { id: 2, name: "Lawyer2" },
  ];

  const MasterDateValueState = {
    immMachineTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MACHINE_TYPE),
    districtMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.DISTRICT),
    manufactureCodeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CODE),
    conditionMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CONDITIONS),
    immThirdPartyDealerCodeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.THIRD_PARTY_DEALER_CODE),
    immTaxTypeMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE),
    immSourceOfPowerMasterData: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.SOURCE_POWER),
    lawyersMasterData: lawyersData,
  };

  const { immValueOfSecurity, immSecuredValue, immDescription, immMachineType, immForceSaleValue, immChasisNumber, immEngineNumber, immHPOrLienNotedOn, immMakeHpOrCc, immAddress, immDistrict, immCity, immManufacturerName, immManufactureCode, immYearOfManufacture, immRegistrationNumber, immExpiryDate, immPurchasedYear, immCondition, immSourceOfPower, immSupplierOrDealerName, immSupplierAddress, immSuppliersDistrict, immSuppliersCity, immThirdPartyDealerCode, immInvoiceNumber, immInvoiceDate, immInvoicePurchaseValue, immTaxType, immTaxNumber, immTaxAmount, immLifespanNumberOfYears, immLifespanNumberOfUnits, immLifespanDepretiationRate, immMarketValue, immCountryOfManufacture, immUsedPeriodAfterPurchased, immDateCommencementInsurance, immMovablePropertyMortgageDeedNo, immLawyerPreparedMortgageDeed, immDatePreparedMortgageDeed, immDateRegistrationMortgageDeed, immOfficeNameRegistrationMortgageDeed, immFinalRegisteredFolioNumber, immDateFinalRegisteredFolio, immModel, immCapacity } = props?.fields;

  return (
    <>
      <Stack spacing={1} m={1} className="full-width">
        <Box mt={2} p={2} className="securityWrapper">
          <Fragment>
            {props?.isNotNeedComponentTitle ? (
              <></>
            ) : (
              <InputFieldsStack>
                <Typography variant="subtitle1" pl={2.5}>
                  Immovable Machinery Equipment
                  {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                </Typography>
              </InputFieldsStack>
            )}
            <InputFieldsStack>
              <InputGrid visibility={immValueOfSecurity.visible}>
                <TextBoxComponent
                  value={formatCurrency(props?.doc?.immValueOfSecurity.get())}
                  onChange={(e) => {
                    props?.doc?.immValueOfSecurity.set(e.target.value);
                  }}
                  label={translate("Value of the Security")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immValueOfSecurity.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immSecuredValue.visible}>
                <TextBoxComponent
                  value={formatCurrency(props?.doc?.immSecuredValue.get())}
                  onChange={(e) => {
                    props?.doc?.immSecuredValue.set(e.target.value);
                  }}
                  label={translate("Secured Value")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immSecuredValue.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immDescription.visible}>
                <TextBoxComponent
                  value={props?.doc?.immDescription.get()}
                  onChange={(e) => {
                    props?.doc?.immDescription.set(e.target.value);
                  }}
                  label={translate("Description")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immDescription.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immMarketValue.visible}>
                <TextBoxComponent value={formatCurrency(props?.doc?.immMarketValue.get())} onChange={(e) => props?.doc?.immMarketValue.set(e.target.value)} label={translate("Market Value")} required={immMarketValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
              </InputGrid>
              <InputGrid visibility={immForceSaleValue.visible}>
                <TextBoxComponent value={formatCurrency(props?.doc?.immForceSaleValue.get())} onChange={(e) => props?.doc?.immForceSaleValue.set(e.target.value)} label={translate("Force Sale Value")} onInput={OnDemandValueFormatter.numeric} required={immForceSaleValue.mandatory === 1 ? true : false} />
              </InputGrid>
            </InputFieldsStack>

            <InputFieldsStack>
              <InputGrid visibility={immMachineType.visible}>
                <TextBoxComponent value={props?.doc?.immMachineType.get()} onChange={(e) => props?.doc?.immMachineType.set(e.target.value)} label={translate("Machine Type")} required={immMachineType.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
              </InputGrid>
              <InputGrid visibility={immChasisNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.immChasisNumber.get()}
                  onChange={(e) => {
                    props?.doc?.immChasisNumber.set(e.target.value);
                  }}
                  label={translate("Chasis Number / Identification Number")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={immChasisNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immEngineNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.immEngineNumber.get()}
                  onChange={(e) => {
                    props?.doc?.immEngineNumber.set(e.target.value);
                  }}
                  label={translate("Engine Number")}
                  onInput={OnDemandValueFormatter.alphaNumericUpper}
                  required={immEngineNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immModel.visible}>
                <TextBoxComponent
                  value={props?.doc?.immModel.get()}
                  onChange={(e) => {
                    props?.doc?.immModel.set(e.target.value);
                  }}
                  label={"Model"}
                  onInput={OnDemandValueFormatter.any}
                  required={immModel.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immCapacity.visible}>
                <TextBoxComponent
                  value={props?.doc?.immCapacity.get()}
                  onChange={(e) => {
                    props?.doc?.immCapacity.set(e.target.value);
                  }}
                  label={"Capacity"}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                  required={immCapacity.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immHPOrLienNotedOn.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immHPOrLienNotedOn.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immHPOrLienNotedOn.set(preparedDate);
                    }
                  }}
                  label={`${translate("HP/Lien Noted On")} translate("Date of Quatation")`}
                  enableFuture={false}
                  disablePast={false}
                  required={immHPOrLienNotedOn.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immMakeHpOrCc.visible}>
                <TextBoxComponent
                  value={props?.doc?.immMakeHpOrCc.get()}
                  onChange={(e) => {
                    props?.doc?.immMakeHpOrCc.set(e.target.value);
                  }}
                  label={translate("Make & HP/CC")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={immMakeHpOrCc.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immAddress.visible}>
                <TextBoxComponent
                  value={props?.doc?.immAddress.get()}
                  onChange={(e) => {
                    props?.doc?.immAddress.set(e.target.value);
                  }}
                  label={translate("Address")}
                  onInput={OnDemandValueFormatter.any}
                  required={immAddress.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immDistrict.visible}>
                <SelectComponent label={translate("District")} value={props?.doc?.immDistrict.get()} values={MasterDateValueState.districtMasterData} required={immDistrict.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immDistrict.set(e.target.value)} defaultPlaceholder={true} />
              </InputGrid>
              <InputGrid visibility={immCity.visible}>
                <TextBoxComponent
                  value={props?.doc?.immCity.get()}
                  onChange={(e) => {
                    props?.doc?.immCity.set(e.target.value);
                  }}
                  label={translate("City")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immCity.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immCountryOfManufacture.visible}>
                <TextBoxComponent
                  value={props?.doc?.immCountryOfManufacture.get()}
                  onChange={(e) => {
                    props?.doc?.immCountryOfManufacture.set(e.target.value);
                  }}
                  label={translate("Country of Manufacture")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immCountryOfManufacture.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immUsedPeriodAfterPurchased.visible}>
                <TextBoxComponent
                  value={props?.doc?.immUsedPeriodAfterPurchased.get()}
                  onChange={(e) => {
                    props?.doc?.immUsedPeriodAfterPurchased.set(e.target.value);
                  }}
                  label={translate("Used Period After Purchased")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={immUsedPeriodAfterPurchased.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immManufacturerName.visible}>
                <TextBoxComponent
                  value={props?.doc?.immManufacturerName.get()}
                  onChange={(e) => {
                    props?.doc?.immManufacturerName.set(e.target.value);
                  }}
                  label={translate("Manufacturer Name")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immManufacturerName.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immManufactureCode.visible}>
                <TextBoxComponent label={translate("Manufacturer Code")} value={props?.doc?.immManufactureCode.get()} values={MasterDateValueState.manufactureCodeMasterData} required={immManufactureCode.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immManufactureCode.set(e.target.value)} />
              </InputGrid>
              <InputGrid visibility={immYearOfManufacture.visible}>
                <NumericFormat
                  label={"Year Of Manufacture"}
                  customInput={TextBoxComponent}
                  variant="outlined"
                  value={props?.doc?.immYearOfManufacture.get()}
                  required={immYearOfManufacture.mandatory === 1 ? true : false}
                  onInput={OnDemandValueFormatter.currentYearFormat}
                  fullWidth={true}
                  onValueChange={(e: any) => {
                    const { value } = e;
                    props?.doc?.immYearOfManufacture.set(value);
                  }}
                />
              </InputGrid>
              <InputGrid visibility={immRegistrationNumber.visible}>
                <NumericFormat
                  label={"Registration Number"}
                  customInput={TextBoxComponent}
                  onInput={OnDemandValueFormatter.alphaNumericUpper}
                  variant="outlined"
                  thousandSeparator={true}
                  value={props?.doc?.immRegistrationNumber.get()}
                  decimalScale={2}
                  required={immRegistrationNumber.mandatory === 1 ? true : false}
                  fullWidth={true}
                  onValueChange={(e: any) => {
                    const { value } = e;
                    props?.doc?.immRegistrationNumber.set(value);
                  }}
                />
              </InputGrid>
              <InputGrid visibility={immExpiryDate.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immExpiryDate.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immExpiryDate.set(preparedDate);
                    }
                  }}
                  label={`${translate("Expiry Date")} (DD/MM/YYYY)`}
                  enableFuture={false}
                  disablePast={false}
                  required={immExpiryDate.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immPurchasedYear.visible}>
                <TextBoxComponent
                  value={props?.doc?.immPurchasedYear.get()}
                  onChange={(e) => {
                    props?.doc?.immPurchasedYear.set(e.target.value);
                  }}
                  label={translate("Purchased Year")}
                  onInput={OnDemandValueFormatter.yearFormat}
                  required={immPurchasedYear.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immCondition.visible}>
                <SelectComponent label={translate("Condition")} value={props?.doc?.immCondition.get()} values={MasterDateValueState.conditionMasterData} required={immCondition.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immCondition.set(e.target.value)} defaultPlaceholder={true} />
              </InputGrid>
              <InputGrid visibility={immSourceOfPower.visible}>
                <SelectComponent label={translate("Source Of Power")} value={props?.doc?.immSourceOfPower.get()} values={MasterDateValueState.immSourceOfPowerMasterData} required={immSourceOfPower.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immSourceOfPower.set(e.target.value)} defaultPlaceholder={true} />
              </InputGrid>
            </InputFieldsStack>
            <InputFieldsStack>
              <InputGrid visibility={immSupplierOrDealerName.visible}>
                <TextBoxComponent
                  value={props?.doc?.immSupplierOrDealerName.get()}
                  onChange={(e) => {
                    props?.doc?.immSupplierOrDealerName.set(e.target.value);
                  }}
                  label={translate("Supplier/Dealer Name")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immSupplierOrDealerName.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immSupplierAddress.visible}>
                <TextBoxComponent
                  value={props?.doc?.immSupplierAddress.get()}
                  onChange={(e) => {
                    props?.doc?.immSupplierAddress.set(e.target.value);
                  }}
                  label={translate("Supplier Address")}
                  onInput={OnDemandValueFormatter.any}
                  required={immSupplierAddress.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immSuppliersDistrict.visible}>
                <SelectComponent label={translate("District")} value={props?.doc?.immSuppliersDistrict.get()} values={MasterDateValueState.districtMasterData} required onChange={(e) => props?.doc?.immSuppliersDistrict.set(e.target.value)} defaultPlaceholder={true} />
              </InputGrid>
              <InputGrid visibility={immSuppliersCity.visible}>
                <TextBoxComponent
                  value={props?.doc?.immSuppliersCity.get()}
                  onChange={(e) => {
                    props?.doc?.immSuppliersCity.set(e.target.value);
                  }}
                  label={translate("City")}
                  onInput={OnDemandValueFormatter.alphabeticalSpaces}
                  required={immSuppliersDistrict.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immThirdPartyDealerCode.visible}>
                <TextBoxComponent label={translate("Third Party Delaer Code")} value={props?.doc?.immThirdPartyDealerCode.get()} required={immThirdPartyDealerCode.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immThirdPartyDealerCode.set(e.target.value)} />
              </InputGrid>
            </InputFieldsStack>

            <InputFieldsStack>
              <InputGrid visibility={immInvoiceNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.immInvoiceNumber.get()}
                  onChange={(e) => {
                    props?.doc?.immInvoiceNumber.set(e.target.value);
                  }}
                  label={translate("Invoice Number")}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                  required={immInvoiceNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immInvoiceDate.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immInvoiceDate.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immInvoiceDate.set(preparedDate);
                    }
                  }}
                  label={`${translate("Invoice Date")} (DD/MM/YYYY)`}
                  enableFuture={false}
                  disablePast={false}
                  required={immInvoiceDate.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immInvoicePurchaseValue.visible}>
                <TextBoxComponent
                  value={formatCurrency(props?.doc?.immInvoicePurchaseValue.get())}
                  onChange={(e) => {
                    props?.doc?.immInvoicePurchaseValue.set(e.target.value);
                  }}
                  label={translate("Invoice Purchase Value")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immInvoicePurchaseValue.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
            <InputFieldsStack>
              <InputGrid visibility={immTaxType.visible}>
                <TextBoxComponent label={translate("Tax Type")} value={props?.doc?.immTaxType.get()} required={immTaxType.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immTaxType.set(e.target.value)} />
              </InputGrid>
              <InputGrid visibility={immTaxNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.immTaxNumber.get()}
                  onChange={(e) => {
                    props?.doc?.immTaxNumber.set(e.target.value);
                  }}
                  label={translate("Tax Number")}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                  required={immTaxNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immTaxAmount.visible}>
                <TextBoxComponent
                  value={formatCurrency(props?.doc?.immTaxAmount.get())}
                  onChange={(e) => {
                    props?.doc?.immTaxAmount.set(e.target.value);
                  }}
                  label={translate("Tax Amount")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immTaxAmount.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
            <DuplicateKeyReceivedActivateState props={props} />
            <InputFieldsStack>
              <InputGrid visibility={immLifespanNumberOfYears.visible}>
                <TextBoxComponent
                  value={props?.doc?.immLifespanNumberOfYears.get()}
                  onChange={(e) => {
                    props?.doc?.immLifespanNumberOfYears.set(e.target.value);
                  }}
                  label={translate("Lifespan Number Of Years")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immLifespanNumberOfYears.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immLifespanNumberOfUnits.visible}>
                <TextBoxComponent
                  value={props?.doc?.immLifespanNumberOfUnits.get()}
                  onChange={(e) => {
                    props?.doc?.immLifespanNumberOfUnits.set(e.target.value);
                  }}
                  label={translate("Lifespan Number Of Units")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immLifespanNumberOfUnits.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immLifespanDepretiationRate.visible}>
                <TextBoxComponent
                  value={props?.doc?.immLifespanDepretiationRate.get()}
                  onChange={(e) => {
                    props?.doc?.immLifespanDepretiationRate.set(e.target.value);
                  }}
                  label={translate("Lifespan Depretiation Rate")}
                  onInput={OnDemandValueFormatter.numeric}
                  required={immLifespanDepretiationRate.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
            <WarrantyAvailableSection props={props} />
            <InsuranceAvailableActivateState props={props} />
            <InputFieldsStack>
              <InputGrid visibility={immMovablePropertyMortgageDeedNo.visible}>
                <TextBoxComponent
                  value={props?.doc?.immMovablePropertyMortgageDeedNo.get()}
                  onChange={(e) => {
                    props?.doc?.immMovablePropertyMortgageDeedNo.set(e.target.value);
                  }}
                  label={translate("Movable Property Mortgage Deed No")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={immMovablePropertyMortgageDeedNo.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immLawyerPreparedMortgageDeed.visible}>
                <TextBoxComponent label={translate("The lawyer who prepared Movable property Mortgage Deed")} onInput={OnDemandValueFormatter.alphabeticalSpaces} value={props?.doc?.immLawyerPreparedMortgageDeed.get()} required={immLawyerPreparedMortgageDeed.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.immLawyerPreparedMortgageDeed.set(e.target.value)} />
              </InputGrid>
              <InputGrid visibility={immDatePreparedMortgageDeed.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immDatePreparedMortgageDeed.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immDatePreparedMortgageDeed.set(preparedDate);
                    }
                  }}
                  label={`${translate("Date of prepared and Certified Mortgage Deed")} (DD/MM/YYYY)`}
                  enableFuture={false}
                  disablePast={false}
                  required={immDatePreparedMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immDateRegistrationMortgageDeed.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immDateRegistrationMortgageDeed.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immDateRegistrationMortgageDeed.set(preparedDate);
                    }
                  }}
                  label={`${translate("Date of Registration of Mortgage Deed")} (DD/MM/YYYY)`}
                  enableFuture={false}
                  disablePast={false}
                  required={immDateRegistrationMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immOfficeNameRegistrationMortgageDeed.visible}>
                <TextBoxComponent
                  value={props?.doc?.immOfficeNameRegistrationMortgageDeed.get()}
                  onChange={(e) => {
                    props?.doc?.immOfficeNameRegistrationMortgageDeed.set(e.target.value);
                  }}
                  label={translate("Office Name Registration Mortgage Deed")}
                  onInput={OnDemandValueFormatter.alphaNumericSpaces}
                  required={immOfficeNameRegistrationMortgageDeed.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immFinalRegisteredFolioNumber.visible}>
                <TextBoxComponent
                  value={props?.doc?.immFinalRegisteredFolioNumber.get()}
                  onChange={(e) => {
                    props?.doc?.immFinalRegisteredFolioNumber.set(e.target.value);
                  }}
                  label={translate("Final Registered Folio Number")}
                  onInput={OnDemandValueFormatter.alphaNumeric}
                  required={immFinalRegisteredFolioNumber.mandatory === 1 ? true : false}
                />
              </InputGrid>
              <InputGrid visibility={immDateFinalRegisteredFolio.visible}>
                <ValidateDatePicker
                  value={formattedDate(props?.doc?.immDateFinalRegisteredFolio.get())}
                  onChange={(date) => {
                    const preparedDate = standardDateFormatter(date);
                    if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                      props?.doc?.immDateFinalRegisteredFolio.set(preparedDate);
                    }
                  }}
                  label={`${translate("Date of Final Registered Folio")} (DD/MM/YYYY)`}
                  enableFuture={false}
                  disablePast={false}
                  required={immDateFinalRegisteredFolio.mandatory === 1 ? true : false}
                />
              </InputGrid>
            </InputFieldsStack>
          </Fragment>
        </Box>
      </Stack>
    </>
  );
};
