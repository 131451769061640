import { Box, Grid, Paper, Stack } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import React, { useEffect } from "react";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { Downgraded, useState } from "@hookstate/core";

import { SwitchComponent } from "../../InputComponents/SwitchComponent/SwitchComponent";
import { TextBoxComponent } from "../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../CommunicationService/CMTextInput";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../GlobalToast";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { appSetting, userDetails } from "../../../configs/mainStore";
import { languageListStore } from "../../../configs/stores/languageStore";
import { isPrivilegeHave } from "../../../services/utilService";
import { privilegeKeys } from "../../../configs/constants/privilegeKeys";
import { addNewUserGroupApi, editUserGroupApi, getUserGroupById } from "../../../services/userPrivilegeService";
import { createApplicationsHistory } from "../../../services/applicationHistoryApi";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";

export const AddEditUserGroupComponent = ({ userGroupListChanged, editGroupItem, reloadComponent }) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();
  const appSettings: any = useState(appSetting);
  const { systemLanguageList }: any = useState(languageListStore);
  const languageList: any = systemLanguageList.attach(Downgraded).get();

  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  // const [fieldErrors, setFieldErrors] = React.useState<any>({});

  const [values, setValues] = React.useState<any>({});

  const [isActive, setIsActive] = React.useState(true);
  const [languageData, setLanguageData] = React.useState<any>([]);

  useEffect(() => {
    const languagesObj = appSettings?.language.get() && languageList[appSettings?.language.get()];
    const languagesArray = Object.values(languagesObj).sort();
    let languageData: any = languagesArray.map((value) => value);

    setLanguageData(languagesArray.map((value) => value));
    setValues({});

    if (editGroupItem?.id) {
      (async () => {
        setIsLoading(true);
        const data = await getUserGroupById(editGroupItem?.id);

        if (data?.groupId === editGroupItem?.id) {
          setIsActive(editGroupItem?.isActive === 1 ? true : false);
          setIsEdit(true);

          let tempValues;
          languageData?.map((item: any) => {
            let tempLang = (item?.CODE).toLowerCase();

            tempValues = {
              ...tempValues,
              ...{ [`${tempLang}Name`]: data[item?.CODE]?.name, [`${tempLang}Desc`]: data[item?.CODE]?.desc },
            };
          });

          setValues(tempValues);
        } else {
          addToaster({
            status: "error",
            title: "Update Error",
            message: "Something went Wrong",
          });
        }
        setIsLoading(false);
      })();
    } else {
      setIsActive(true);
      setIsEdit(false);

      setValues({});
    }
  }, [editGroupItem]);

  const onChangeField = (fieldName, value) => {
    // fieldValidateHandler(fieldName, value);
    setValues({ ...values, ...{ [fieldName]: value } });

    // console.log(fieldName, value);
  };

  const updateNewUserGroup = async () => {
    setUpdateLoading(true);

    let userGroupData: any = [];
    languageData?.map((item) => {
      let tempLang = (item?.CODE).toLowerCase();

      userGroupData.push({ languageId: item?.CODE, name: values[`${tempLang}Name`] ?? "", desc: values[`${tempLang}Desc`] ?? "" });
    });

    let result;

    if (isEdit) {
      result = await editUserGroupApi(editGroupItem?.id, isActive, userGroupData);
    } else {
      result = await addNewUserGroupApi(isActive, userGroupData);
    }

    if (result) {
      addToaster({
        status: "success",
        title: isEdit ? "Group Updated" : "New Group Inserted",
        message: result,
      });

      setValues({});
      setIsActive(true);
      userGroupListChanged();
      reloadComponent();
      closeGlobalModal();

      try {
        if (isEdit) {
          let obj = {
            ud: editGroupItem?.id,
            isActive: isActive,
            userGroupData: userGroupData,
          };
          createApplicationsHistory(0, `Update User Group`, null, obj, "/audit/log/workflow/manageusergroup");
        } else {
          let obj = {
            isActive: isActive,
            userGroupData: userGroupData,
          };
          createApplicationsHistory(0, `Create User Group`, null, obj, "/audit/log/workflow/manageusergroup");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      addToaster({
        status: "error",
        title: "Update Error",
        message: "Something went Wrong",
      });
    }

    setUpdateLoading(false);
  };

  return (
    <Box m={1}>
      {isLoading ? (
        <CircularProgressComponent size={30} sx={{ top: "50%", right: "20%", position: "absolute", zIndex: 100 }} />
      ) : (
        <Paper elevation={0} className={isEdit ? "light-gray-bg" : ""}>
          <Box p={1}>
            <Grid container className="align-center" columns={8} pl={1} pr={1}>
              <Grid item xs={8} sm={8} className="align-center">
                <Box className="screen_description--basic">
                  <p className="basic-font basic-font-color font-size-20 font-weight-600" style={{ marginBottom: 0, marginTop: 0 }}>
                    {isEdit ? `Change User Group` : "Create User Group"}
                  </p>
                  <p className="basic-font basic-font-color font-size-16 font-weight-100" style={{ marginBottom: 0, marginTop: 0 }}>
                    {isEdit && `${editGroupItem?.name}`}
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Grid container columns={8} p={1}>
              <Grid item xs={8} sm={8}>
                <CMTextInput
                  desc={"Published"}
                  component={
                    <SwitchComponent
                      commonStatus={true}
                      checked={isActive}
                      onChange={(e) => {
                        setIsActive(e.target.checked);
                      }}
                      disabled={updateLoading}
                    />
                  }
                />
              </Grid>
              {languageData?.map((item: any, i) => {
                let tempLang = (item?.CODE).toLowerCase();
                return (
                  <Stack key={i} direction={"column"}>
                    <Stack flex={1} ml={1}>
                      <CMTextInput
                        desc={`Name (${item?.DESCRIPTION})`}
                        component={
                          <TextBoxComponent
                            onChange={(e) => {
                              onChangeField(`${tempLang}Name`, e.target.value);
                            }}
                            multiline={true}
                            value={values[`${tempLang}Name`] ? values[`${tempLang}Name`] : ""}
                            disabled={updateLoading}
                          />
                        }
                      />
                    </Stack>
                    <Stack flex={1} ml={1}>
                      <CMTextInput
                        desc={`Description (${item?.DESCRIPTION})`}
                        component={
                          <TextBoxComponent
                            onChange={(e) => {
                              onChangeField(`${tempLang}Desc`, e.target.value);
                            }}
                            multiline={true}
                            value={values[`${tempLang}Desc`] ? values[`${tempLang}Desc`] : ""}
                            disabled={updateLoading}
                          />
                        }
                      />
                    </Stack>
                  </Stack>
                );
              })}

              <hr style={{ borderTop: "1px solid #454c661a", marginTop: "20px", width: "100%" }} />

              <Grid item xs={8} sm={8} textAlign="end" mt={1}>
                {isEdit && (
                  <ButtonComponent
                    startIcon={<RestartAltIcon />}
                    title={"RESET"}
                    variant="contained"
                    onClick={() => {
                      setValues({});
                      closeGlobalModal();
                    }}
                    disabled={updateLoading || !isPrivilegeHave(userPrivilege, privilegeKeys.RESET_USER_GROUP_BUTTON)}
                    loadingbtn={true}
                    loading={updateLoading}
                    style={{ marginRight: "5px", backgroundColor: "red" }}
                  />
                )}
                <ButtonComponent
                  startIcon={<SendIcon />}
                  title={isEdit ? "CHANGE GROUP" : "ADD NEW GROUP"}
                  variant="contained"
                  onClick={() => {
                    updateNewUserGroup();
                  }}
                  disabled={updateLoading || !isPrivilegeHave(userPrivilege, privilegeKeys.CREATE_USER_GROUP)}
                  loadingbtn={true}
                  loading={updateLoading}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
