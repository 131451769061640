import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { ERROR_CODES } from '../../../../../../configs/constants/errorCode';
import { userDetails, workflowMasterData } from '../../../../../../configs/mainStore';
import { getUserGroupsForReports } from '../../../../../../services/applicationApis';
import {
  applicationTimelineApi,
  getGroupownersByGroupId,
  saveReferData,
} from '../../../../../../services/creditFileApiCall';
import { getUsersByGroupId } from '../../../../../../services/userPrivilegeService';
import { closeBtn } from '../../../../../../styles';
import { userName } from '../../../../../../utility/helpers/creditFileUtility';
import { responseSuccessCode } from '../../../../../../utility/other';
import { referStatus } from '../../../../../../utility/util';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { SelectComponent } from '../../../../../InputComponents/SelectComponent/SelectComponent';
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';
import { addToaster } from '../../../../GlobalToast';
import { CircularProgressComponent } from '../../../../ProgressComponent/ProgressComponent';

const OfficerHeadReferModal = (params) => {
  const initialData = { note: '', user: '' };
  const showErrors = useState(false);
  const { wf_system_users, wf_groups }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const userData = params?.userData;

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
  const [referGroupsList, setReferGroupsList] = React.useState<any>([]);
  const [referGroup, setReferGroup] = React.useState<any>([]);
  const [headOfficer, setHeadOfficer] = React.useState<any>(false);
  const [headOfficerList, setHeadOfficerList] = React.useState<any>(false);
  const [headOfficerValue, setHeadOfficerValue] = React.useState<any>(null);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      let tempUserList = {};
      const res = await getGroupownersByGroupId(params?.officerGroupId);

      const uniqueRes = new Set(res);

      if (res) {
        tempUserList = Array.from(uniqueRes.values()).map((obj) => {
          return {
            name: userName(obj),
            id: obj,
          };
        });

        setHeadOfficerList(tempUserList);
      }
      setIsLoading(false);
    })();
  }, []);

  const saveRefer = async () => {
    setIsLoading(true);
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == 'active');
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: params?.officerGroupId,
        cauOfficer: true,
        ...referState.get(),
      };

      const res = await saveReferData(data);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: 'success',
          title: 'Success',
          message: res?.data?.msg,
        });

        const referenceId = res?.data?.data?.referId;

        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          assignee: headOfficerValue,
          actionNote: referState?.note?.get() || '',
          actionedBy: loginUser?.userId?.get(),
          actionType: 'ASSIGN_TO_CAU_MANAGER',
        };

        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);

        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: 'success',
            title: 'Success',
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: 'error',
            title: 'Error',
            message: 'Timeline Update Failed',
          });
        }

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
    setIsLoading(false);
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === '' || !referState.user.get();
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const getReferGroupId = async (groupID) => {
    let usersListData = await getUsersByGroupId(groupID);

    const correspondingValues = Object.keys(usersListData).map((key) => {
      const id = parseInt(key);
      const object = wf_system_users?.find((obj) => obj?.get().id === id);
      return {
        name: object?.fullName,
        id: object?.id,
      };
    });

    setDropdownUserList(correspondingValues);
  };

  useEffect(() => {
    (async () => {
      const referalIDList = await getUserGroupsForReports();
      let tempUserGroupList: any = [];
      wf_groups?.get()?.map((item: any) => {
        if (
          item?.id === referalIDList?.legalGroupId ||
          item?.id === referalIDList?.riskGroupId ||
          item?.id === referalIDList?.insDepGroupId
        ) {
          tempUserGroupList.push({ name: item?.name, id: item?.id });
        }
      });

      setReferGroupsList(tempUserGroupList);
    })();
  }, []);

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ''}
          />
        </Grid>
        {isLoading ? (
          <CircularProgressComponent size={25} />
        ) : (
          headOfficerList && (
            <Grid container item xs={12}>
              <ModalInputComponent
                title="CA Hub Head"
                component={
                  <SelectComponent
                    onChange={(e) => {
                      resetError();
                      setHeadOfficerValue(e.target.value);
                      referState.user.set(e.target.value);
                    }}
                    values={headOfficerList}
                    value={headOfficerValue}
                    size="small"
                    label={'Select CA Hub Head'}
                  />
                }
                isMandatory={true}
                showErrors={showErrors.get() && !referState.user.get()}
              />
            </Grid>
          )
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          loadingbtn={isLoading}
          loading={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default OfficerHeadReferModal;
