import { Downgraded } from "@hookstate/core";
import { getTheFieldKeyIfNeeded } from "./structureValidation";
// import { agricultureValidationMap } from "../../../components/OtherComponents/ApplicationForm/Sections/AgricultureDetails/Utilities/validation_map_agriculture";

export const agricultureDetailsValidationCollector = (structure, state, isGuarantor = false, isJointBorrower = false,): string[] => {
    return []
}

export const validationAgricultureDetails = (stateData): string[] => {
    let errorArray: string[] = [];
    try {
        stateData = stateData.attach(Downgraded).get();
    } catch (error) {
        stateData = stateData;
    }

    if (!stateData) return errorArray;

    if (Array.isArray(stateData)) {
        let responseErroArray: string[] = stateData.find((item) => validationAgricultureDetails(item).length > 0);
        errorArray = [...errorArray, ...responseErroArray];
    }

    let responseConvertedFlatObj = convertedFlatObj(stateData);

    return errorArray;
}

const convertedFlatObj = (stateData) => {

    return Object.keys(stateData).reduce((errorArray: any, key) => {
        let item = stateData[key];

        if (!item) return errorArray;

        if (typeof item === 'string') {
            item = getTheFieldKeyIfNeeded(item, key)
            errorArray = [...errorArray, item];
        };

        if (Array.isArray(item)) {
            item = validationAgricultureDetails(item);
            errorArray = [...errorArray, ...item];
        }

        if (Object.keys(item).length > 0) {
            item = convertedFlatObj(item);
            errorArray = [...errorArray, ...item]
        }
        return errorArray;
    }, []);
}

const mandatoryHandler = (dataKey, dataValue) => {
    // const { mandatory, selectiveMandatory } = agricultureValidationMap;
}

const selectiveMandatoryHandler = () => {
}
