import { Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { userName } from "../../../../../../utility/helpers/creditFileUtility";


const ProcessFlow = ({ items, stageMap, stageColors }) => {
    const [selectedIndex, setSelectedIndex]: any = React.useState(-1);

    const formatDuration = (durationInHours) => {
        const minutes = Math.round((durationInHours * 60) % 60);
        const hours = Math.floor(durationInHours % 24);
        const days = Math.floor(durationInHours / 24);

        return `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h ` : ""}${minutes > 0 ? `${minutes}m` : ""}`.trim();
    };

    return (
        <>
            <Paper style={{ margin: 0, position: "relative" }} >
                <Stack direction={"row"} spacing={0.2} style={{ top: "0px", position: "sticky", zIndex: 100, backgroundColor: "white" }}>
                    {items.map(obj => <Stack key={obj.ExecSequence} flex={obj.duration} style={{ backgroundColor: stageColors?.[obj.stage]?.backgroundColor ?? "red", cursor: "pointer", userSelect: "none", border: obj.ExecSequence === selectedIndex ? 'solid 2px lightBlue' : 'none' }} alignItems={"center"} justifyContent={"center"} onClick={(evt) => setSelectedIndex(obj.ExecSequence)}>
                        <Tooltip
                            title={<Stack>
                                <Stack>{stageMap?.[obj.stage] || ""}</Stack>
                                <Stack>{formatDuration(obj.duration)}</Stack>
                            </Stack>}
                            sx={{ ":hover": "background: 'white'" }}
                        >
                            <Stack height={30} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                                <Typography variant="caption" fontSize={"6pt"}> {formatDuration(obj.duration)} </Typography>
                            </Stack>
                        </Tooltip>
                    </Stack>)}
                </Stack>
            </Paper>
            <Stack mt={2}>
                {
                    items.map((obj) => <Stack direction={"row"} spacing={2} py={1} style={{ border: obj.ExecSequence === selectedIndex ? 'solid 2px lightBlue' : 'none' }}>
                        <Stack direction={"row"} spacing={1} flex={1}>
                            <Typography variant="caption" color={"gray"} width={100}> {`${formatDuration(obj.duration)}`}</Typography>
                            <Typography variant="caption" flex={1}> {stageMap?.[obj.stage] || ""} </Typography>
                        </Stack>
                        <Stack flex={2}>
                            <Typography variant="caption" flex={1}> Action By {userName(obj.actionBy)} </Typography>
                        </Stack>
                    </Stack>)
                }
            </Stack>
        </>
    )
}

export default ProcessFlow