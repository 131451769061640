import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../components/OtherComponents/GlobalModal/GlobalModal";

const CustomEntryModal = ({ callBackOnAction, value, label }) => {
    const [text, setText] = React.useState(value || "");

    return <Stack>
        {label && <Typography variant="subtitle2" color={"gray"}>{label}</Typography>}
        <Stack mb={2}>
            <TextBoxComponent value={text} onChange={(e) => {
                setText(e.target.value);
            }}></TextBoxComponent>
        </Stack>
        <Divider></Divider>
        <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <ButtonComponent size={"small"} variant={"contained"} title={"Apply"} onClick={() => {
                callBackOnAction(text);
            }}></ButtonComponent>
            <ButtonComponent size={"small"} variant={"outlined"} title={"Close"} onClick={() => {
                closeGlobalModal();
            }}></ButtonComponent>
        </Stack>
    </Stack>;
};

export default CustomEntryModal;