import { Box, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

export const LoanProtectionInsurance = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const insuranceDetailsDescription = [
    { id: 1, name: "Pension Loan" },
    { id: 2, name: "Housing Loan" },
  ];

  const { lpIssuingAuthority, lpInsuranceAgreementPolicy, lpPolicyDate, lpPolicyNumber, lpDocumentNumber, lpNoOfDocuments, lpFaceValue, lpDueDate, lpDescription, lpInsuranceDetailsDescription } = props?.fields; // Instead of Visibility equals 1

  return (
    <div className="full-width full-height">
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    Loan Protection Insurance {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={lpIssuingAuthority?.visible}>
                  {props?.doc?.lpIssuingAuthority.get() && props?.doc?.lpIssuingAuthority.get().toString().length > 7 ? (
                    <TextBoxComponent
                      value={props?.doc?.lpIssuingAuthority.get()}
                      onChange={(e) => {
                        props?.doc?.lpIssuingAuthority.set(e.target.value);
                      }}
                      label={translate("Issuing Authority")}
                      onInput={OnDemandValueFormatter.alphaNumericSpaces}
                      required={lpIssuingAuthority?.mandatory === 1 ? true : false}
                    />
                  ) : (
                    <SelectComponent
                      label={translate("Issuing Authority")}
                      value={props?.doc?.lpIssuingAuthority.get()}
                      values={masterDataStore.getMasterData(MD_KEYS?.INSURANCE_COMPANY_TYPE)}
                      required={lpIssuingAuthority?.mandatory === 1 ? true : false}
                      onChange={(e) => {
                        props?.doc?.lpIssuingAuthority.set(e.target.value);
                      }}
                      defaultPlaceholder={true}
                    />
                  )}
                </InputGrid>
                <InputGrid visibility={lpInsuranceAgreementPolicy?.visible}>
                  <SelectComponent label={translate("Insurance Policy Type")} value={props?.doc?.lpInsuranceAgreementPolicy.get()} values={masterDataStore.getMasterData(MD_KEYS?.INSURANCE_POLICY_TYPE)} required={lpInsuranceAgreementPolicy?.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.lpInsuranceAgreementPolicy.set(e.target.value)} defaultPlaceholder={true} />
                </InputGrid>
                <InputGrid visibility={lpInsuranceDetailsDescription?.visible}>
                  <SelectComponent label={translate("Insurance Details Description")} value={props?.doc?.lpInsuranceDetailsDescription.get()} values={masterDataStore.getMasterData(MD_KEYS?.INSURANCE_DETAILS_DESCRIPTION)} required={lpInsuranceDetailsDescription?.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.lpInsuranceDetailsDescription.set(e.target.value)} defaultPlaceholder={true} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={lpPolicyDate?.visible}>
                  {/* <ValidateDatePicker
                    value={formattedDate(props?.doc?.lpPolicyDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (
                        date &&
                        preparedDate !== null &&
                        preparedDate !== "Invalid Date"
                      ) {
                        props?.doc?.lpPolicyDate.set(preparedDate);
                      }
                    }}
                    label={translate("Policy Date")}
                    enableFuture={false}
                    disablePast={false}
                    required={lpPolicyDate?.mandatory === 1 ? true : false}
                  /> */}

                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.lpPolicyDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.lpPolicyDate.set(preparedDate);
                      }
                    }}
                    label={translate("Policy Date (DD/MM/YYYY)")}
                    enableFuture={false}
                    disablePast={false}
                    required={lpPolicyDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={lpPolicyNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.lpPolicyNumber.get()} onChange={(e) => props?.doc?.lpPolicyNumber.set(e.target.value)} label={translate("Policy Number")} onInput={OnDemandValueFormatter.alphaNumeric} required={lpPolicyNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={lpDocumentNumber?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.lpDocumentNumber.get()}
                    onChange={(e) => {
                      props?.doc?.lpDocumentNumber.set(e.target.value);
                    }}
                    label={translate("Document Number")}
                    onInput={OnDemandValueFormatter.alphaNumeric}
                    required={lpDocumentNumber?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={lpNoOfDocuments?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.lpNoOfDocuments.get()}
                    onChange={(e) => {
                      props?.doc?.lpNoOfDocuments.set(e.target.value);
                    }}
                    label={translate("No of Documents")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={lpNoOfDocuments?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={lpFaceValue?.visible}>
                  <NumericFormat
                    label={translate("Face Value")}
                    customInput={TextBoxComponent}
                    variant="outlined"
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.lpFaceValue.get())}
                    decimalScale={2}
                    required={lpFaceValue?.mandatory === 1 ? true : false}
                    fullWidth={true}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.lpFaceValue.set(value);
                    }}
                    onInput={OnDemandValueFormatter.numeric} // as per the request, restricted to enter only numbers - 14/06/2024
                  />
                </InputGrid>
                <InputGrid visibility={lpDueDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.lpDueDate.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.lpDueDate.set(preparedDate);
                      }
                    }}
                    label={translate("Due Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={true}
                    required={lpDueDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack marginBottom={2.5}>
                <InputGrid visibility={lpDescription?.visible} variant={VARIANT?.extraLongGrid}>
                  <TextBoxComponent value={props?.doc?.lpDescription.get()} onChange={(e) => props?.doc?.lpDescription.set(e.target.value)} label={translate("Description")} multiline={true} required={lpDescription?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
