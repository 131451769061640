import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useState } from "@hookstate/core";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { taskStoreState } from "../../../../../configs/stores/credit-file-store/creditFileStore";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { updateTask } from "../../../../../services/creditFileApiCall";
import { addToaster } from "../../../GlobalToast";
import { responseSuccessCode } from "../../../../../utility/other";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { taskAction, taskStatus } from "../../../../../utility/util";
import { closeBtn, doneBtn, rejectBtn } from "../../../../../styles";
import { taskActionUpdatedState } from "../../../../../configs/mainStore";

const TaskActionModal = (params) => {
  const initialData = {
    title: params?.title,
    description: params?.description,
    actionNote: "",
    taskId: params?.taskId,
  };

  const { taskList }: any = useState(taskStoreState);
  const taskState: any = useState(initialData);
  const showErrors = useState(false);
  const taskActionDone = useState(taskActionUpdatedState);

  const updateTaskHandle = async (status) => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const action = Object.keys(taskAction)?.find((act) => taskAction[act]?.key == status);
      const taskStat = Object.keys(taskStatus)?.find((sts) => taskStatus[sts]?.key == "closed");

      const data = {
        taskId: taskState.taskId.get(),
        action: action,
        taskStatus: taskStat,
        actionNote: taskState.actionNote.get(),
        applicationId: params?.applicationId,
      };
      const res: any = await updateTask(data);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.data?.msg,
        });

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }

        // Task action state update
        taskActionDone.set(!taskActionDone?.get());
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return taskState.actionNote.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Action Task</div>
      </Stack>

      <Grid container spacing={1} mt={2} rowSpacing={2} className="modal-text-field">
        {/* <Grid container item xs={12}>
                    <Grid item xs={6} md={3} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Task Id</div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <div className="align-left font-size-14 basic-font basic-font-color-bold">{taskState?.taskId?.get()}</div>
                    </Grid>
                </Grid> */}
        <ModalInputComponent
          title="Task Id"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">
              {taskState?.taskId?.get()}
            </div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Title"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">
              {taskState?.title?.get()}
            </div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Task Description"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">
              {taskState?.description?.get()}
            </div>
          }
          isMandatory={false}
          showErrors={false}
        />
        {/* <Grid container item xs={12}>
                    <Grid item xs={6} md={3} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Title</div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <div className="align-left font-size-14 basic-font basic-font-color-bold">{taskState?.title?.get()}</div>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={6} md={3} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Description</div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <div className="align-left font-size-14 basic-font basic-font-color-bold">{taskState?.description?.get()}</div>
                    </Grid>
                </Grid> */}

        <ModalInputComponent
          title="Action Note"
          component={
            <TextBoxComponent
              value={taskState?.actionNote?.get()}
              onChange={(e) => {
                resetError();
                taskState.actionNote.set(e.target.value);
              }}
              variant="outlined"
              size="small"
              multiline={true}
              minRows={4}
            />
          }
          isMandatory={true}
          showErrors={showErrors.get() && taskState.actionNote.get() === ""}
        />
        {/* <Grid container item xs={12}>
                    <Grid item xs={6} md={3} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Action Note</div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <TextBoxComponent
                            value={taskState?.actionNote?.get()}
                            onChange={(e) => taskState.actionNote.set(e.target.value)}
                            variant="outlined"
                            size="small"
                            multiline={true}
                            minRows={4}
                        />
                    </Grid>
                </Grid> */}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeTask}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<NotInterestedIcon />}
          title="Reject"
          variant="contained"
          onClick={() => updateTaskHandle("reject")}
          // disabled={disableSave()}
          loadingbtn={true}
          style={rejectBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Done"
          variant="contained"
          onClick={() => updateTaskHandle("done")}
          // disabled={disableSave()}
          loadingbtn={true}
          style={doneBtn}
        />
      </Stack>
    </Box>
  );
};

export default TaskActionModal;
