import { Box, FormLabel } from "@mui/material";
import { FC } from "react";
import { FormLabelPropsTypes } from "../../../configs/interfaces";

export const FormLabelComponent: FC<FormLabelPropsTypes> = ({
  children,
  classes,
  color,
  component,
  disabled,
  error,
  filled,
  focused,
  required,
  sx,
  id,
}) => {
  return (
    <Box className="input-label-small">{children}</Box>
    // <FormLabel
    //   id={id}
    //   children={children}
    //   classes={classes}
    //   color={color}
    //   component={component}
    //   disabled={disabled}
    //   error={error}
    //   filled={filled}
    //   focused={focused}
    //   required={required}
    //   sx={sx}
    //   className="input-label-small"
    // />
  );
};

export const FormLabelComponentResponsive: FC<FormLabelPropsTypes> = ({
  children,
}) => {
  return (
    <Box className="input-label-small-responsive">{children}</Box>
  );
};