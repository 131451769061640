import { Divider, Stack, Typography } from '@mui/material'

const SubTitleTypeTwo = ({ title }) => {
    return <>
        <Stack>
            <Typography variant='subtitle1' color={"gray"}>{title}</Typography>
        </Stack>
        <Divider />
    </>
}

export default SubTitleTypeTwo