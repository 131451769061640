import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { creditData, userDetails } from "../../../../configs/mainStore";
import ProductDocumentUploadLayout from "../../../../layouts/ProductDocumentUploadLayout";
import { getIsDisbursed } from "../../../../utility/helpers/getIsDisbursed";

const ProductGuarantorDocuments = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const guarantorArr = data?.formData?.securityData?.guarantors;

  const creditFileData: any = useState(creditData);
  const { productId, applicantType }: any = creditFileData.attach(Downgraded).get();
  const disbursed = creditFileData?.disbursed?.get();
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  const checkPrivilegeStatus = () => {
    let isDisabled = true
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee
    }
    return isDisabled
  }
  
  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height">
        {guarantorArr &&
          guarantorArr.map((item, i) => {
            if (item?.personalData?.individualId !== "0") {
              return (
                <ProductDocumentUploadLayout
                  key={i}
                  applicationId={applicationId}
                  productId={productId}
                  applicantType={applicantType}
                  documentTypeId={innerComponentData?.documentTypeId}
                  documentSectionName={innerComponentData?.title}
                  documentComponentId={innerComponentData?.id}
                  participantId={item?.personalData?.individualId}
                  personalData={item?.personalData}
                  disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
                  participantType={innerComponentData?.participantType}
                  isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                  disbursed={disbursed}
                />
              );
            }
          })}
      </div>
    </Grid>
  );
};

export default ProductGuarantorDocuments;
