import URL from "../configs/connection";

export const endpoints = {
  loadMasterData: `${URL.REACT_APP_BACKEND_HOST}/los/masterdata`,
  saveAppFormJson: `${URL.REACT_APP_BACKEND_HOST}/los/save-app-form-json`,
  loadAppFromSections: `${URL.REACT_APP_BACKEND_HOST}/los/app-form-sections`,
  loadAppFromProductSections: `${URL.REACT_APP_BACKEND_HOST}/los/app-form-prd-section`,
  getUploadedFiles: `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-management/getImages`,
  saveLeadPersonalInfo: `${URL.REACT_APP_BACKEND_HOST}/los/applicant-request-personal`,
  saveLeadCreditDetails: `${URL.REACT_APP_BACKEND_HOST}/los/applicant-request-credit`,
  loadPendingApplication: `${URL.REACT_APP_BACKEND_HOST}/los/pendingApplication`,
  saveApplicationFormJson: `${URL.REACT_APP_BACKEND_HOST}/los/appFormData`,
  deleteApplicationsStructureNode: `${URL.REACT_APP_BACKEND_HOST}/los/applications/structure`,
  validateData: `${URL.REACT_APP_BACKEND_HOST}/decision-engine/field/async`,
  loadChannelData: `${URL.REACT_APP_BACKEND_HOST}/trade-credit-service/channels/`,

  searchEndPoint: `${URL.REACT_APP_BACKEND_HOST}/los/pendingApplication`,
  partialSave: `${URL.REACT_APP_BACKEND_HOST}/los/saveapplication`,
  stagesOrder: `${URL.REACT_APP_BACKEND_HOST}/los/stages/order`,
  stagesTasks: `${URL.REACT_APP_BACKEND_HOST}/los/masterdata/workflow/stages/tasks`,
  stagesEvaluation: `${URL.REACT_APP_BACKEND_HOST}/los/stages/de/evaluate`,
  submitApplication: `${URL.REACT_APP_BACKEND_HOST}/los/submitApplication`,
  loadPartiallySavedForms: `${URL.REACT_APP_BACKEND_HOST}/los/viewpartialsaved`,
  loadSelectedPartialForms: `${URL.REACT_APP_BACKEND_HOST}/los/loadpartialsaved`,
  createApplicationForm: `${URL.REACT_APP_BACKEND_HOST}/los/new-application-form`,
  getAppForms: `${URL.REACT_APP_BACKEND_HOST}/los/app-form-list`,
  deleteAppForm: `${URL.REACT_APP_BACKEND_HOST}/los/delete-app-form`,
  updateAppForm: `${URL.REACT_APP_BACKEND_HOST}/los/update-app-form`,
  getFormById: `${URL.REACT_APP_BACKEND_HOST}/los/applicationForm`,
  getApplicationsFormHistories: `${URL.REACT_APP_BACKEND_HOST}/los/applications/forms/history`,
  updateApplicationTabs: `${URL.REACT_APP_BACKEND_HOST}/los/applications/control/tabs`,
  updateApplicationInactiveTabs: `${URL.REACT_APP_BACKEND_HOST}/los/applications/inactive/tabs`,
  differApplicationsEndpoint: `${URL.REACT_APP_BACKEND_HOST}/los/applications/differ`,
  getOtherFormPrdData: `${URL.REACT_APP_BACKEND_HOST}/los/other-participant-app-form-prd-data`,
  getOtherParticipantForm: `${URL.REACT_APP_BACKEND_HOST}/los/participant-application-form`,
  loadApplicationForm: `${URL.REACT_APP_BACKEND_HOST}/los/app-form`,
  loadApplicationFormConfigMeta: `${URL.REACT_APP_BACKEND_HOST}/los/forms/configs/meta`,
  applicationFormCounts: `${URL.REACT_APP_BACKEND_HOST}/los/application-count`,
  loadApplicationFormProductAssociations: `${URL.REACT_APP_BACKEND_HOST}/los/forms/associate`,
  uiJobDataSave: `${URL.REACT_APP_BACKEND_HOST}/los/uiJobDataSave`,
  registerUser: `${URL.REACT_APP_BACKEND_HOST}/auth/user`,
  updateUserExtended: `${URL.REACT_APP_BACKEND_HOST}/auth/user-update`,
  getUserDetails: `${URL.REACT_APP_BACKEND_HOST}/auth/users?userId=`,
  switchUserSession: `${URL.REACT_APP_BACKEND_HOST}/auth/session?userId=`,
  resetUserPassword: `${URL.REACT_APP_BACKEND_HOST}/auth/accounts/reset`,
  changePassword: `${URL.REACT_APP_BACKEND_HOST}/auth/change-password`,
  loadUsers: `${URL.REACT_APP_BACKEND_HOST}/auth/users`,
  updateWorkflowStage: `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/update-workflow-stage`,
  saveBranches: `${URL.REACT_APP_BACKEND_HOST}/branches/save`,
  saveDLALimits: `${URL.REACT_APP_BACKEND_HOST}/auth/user-dla`,
  saveInspectionLimits: `${URL.REACT_APP_BACKEND_HOST}/auth/inspections/limits`,
  inspectionValidate: `${URL.REACT_APP_BACKEND_HOST}/los/inspections/validate`,
  fetchDLALimits: `${URL.REACT_APP_BACKEND_HOST}/auth/user-dla`,
  fetchUserBranches: `${URL.REACT_APP_BACKEND_HOST}/auth/user-branches`,
  allUsersManagedByMe: `${URL.REACT_APP_BACKEND_HOST}/auth/users/managed-by-user`,
  lockUser: `${URL.REACT_APP_BACKEND_HOST}/auth/lock-user`,
  saveUserBranches: `${URL.REACT_APP_BACKEND_HOST}/auth/assign-branches`,
  underWrittingPolicyConfigs: `${URL.REACT_APP_BACKEND_HOST}/los/product/underwriting/configs`,

  getInspectionFormStructure: `${URL.REACT_APP_BACKEND_HOST}/los/forms?dataKey=`,

  submitBurrowerUpdate: `${URL.REACT_APP_BACKEND_HOST}/los/submitApplicationBorrowerUpdate`,
  manageWorkflowReferral: `${URL.REACT_APP_BACKEND_HOST}/los/referrals/processes/manage`,
  manageWorkflowJobTypes: `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/stages/manage`,
  getWorkflowJobTypes: `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/stages/manage`,
  getUserUpdatedPrivilege: `${URL.REACT_APP_BACKEND_HOST}/auth/privileges/refresh?userId=`,
  getPrivilegeMainSectionList: `${URL.REACT_APP_BACKEND_HOST}/auth/privileges/sections/main`,
  getPrivilegeSubSectionList: `${URL.REACT_APP_BACKEND_HOST}/auth/privileges/sections/sub`,
  getAssignedPrivilegeSectionList: `${URL.REACT_APP_BACKEND_HOST}/auth/privileges/sections`,
  updatePrivilegeConfigurationItems: `${URL.REACT_APP_BACKEND_HOST}/auth/privileges`,
  downloadCollectionHistories: `${URL.REACT_APP_BACKEND_HOST}/cms/collections/histories/loans`,
  downloadCmsContracts: `${URL.REACT_APP_BACKEND_HOST}/cms/collections/histories/contracts`,
  stageTransferPreConditionCheckConfig: `${URL.REACT_APP_BACKEND_HOST}/los/stages/de/evaluate`,
  getStageTransferPreConditionCheckConfig: `${URL.REACT_APP_BACKEND_HOST}/los/stages/de/evaluate`,
  addPredefinedCondition: `${URL.REACT_APP_BACKEND_HOST}/los/conditions/predefined`,
  addNewChargeType: `${URL.REACT_APP_BACKEND_HOST}/los/charges/templates`,
  addManageWorkflowReferral: `${URL.REACT_APP_BACKEND_HOST}/los/conditions/predefined`,
  stageDocumentProductConfig: `${URL.REACT_APP_BACKEND_HOST}/los/configuration/products/documents/stages`,
  getStageDocumentProductConfig: `${URL.REACT_APP_BACKEND_HOST}/los/documents/privileges/permissions`,
  stageDocumentPermissions: `${URL.REACT_APP_BACKEND_HOST}/los/documents/privileges/permissions`,
  documentUnLinked: `${URL.REACT_APP_BACKEND_HOST}/los/applications/documents/unlinked`,
  archivedDocuments: `${URL.REACT_APP_BACKEND_HOST}/los/documents/archives`,
  stageRoles: `${URL.REACT_APP_BACKEND_HOST}/los/stages/actors/groups?workflowId=`,
  productConditions: `${URL.REACT_APP_BACKEND_HOST}/los/products/conditions/offerletter`,
  productConditionsDelete: `${URL.REACT_APP_BACKEND_HOST}/los/products/conditions`,
  applicationSummary: `${URL.REACT_APP_BACKEND_HOST}/los/application/application-summary`,
  watchlist: `${URL.REACT_APP_BACKEND_HOST}/watchlist`,
  productLoanPurposeMappingUrl: `${URL.REACT_APP_BACKEND_HOST}/los/products/purpose`,
  productExpenseMappingUrl: `${URL.REACT_APP_BACKEND_HOST}/los/products/expense`,
  creditDataByApplicationId: `${URL.REACT_APP_BACKEND_HOST}/los/credit-data/participants`,
  inspectionLimitApi: `${URL.REACT_APP_BACKEND_HOST}/auth/inspections/limits`,
  inspectionEvaluationLimitApi: `${URL.REACT_APP_BACKEND_HOST}/auth/inspections/evaluation/limits`,
  inspectionEvaluationCollateral: `${URL.REACT_APP_BACKEND_HOST}/auth/inspections/evaluation/collateral`,
  reportsApplicationStatus: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/applications/status`,
  reportDisbursement: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/disbursements/applications`,
  reportScoreCard: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/scorecards`,
  reportRevertSummary: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/reverts/summary`,
  reportReviewOfficerPerformance: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/officers/review/performance`,
  reportLoanDisbursementGrowth: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/disbursements/growth/applications`,
  productDisbursementDashboard: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/disbursements/growth/products`,
  reportSecurityDisbursementGrowth: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/disbursements/growth/security`,
  reportFundSourceGrowth: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/disbursements/growth/fundsource`,
  reportRepaymentPattern: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/repayments/patterns`,
  reportInsuranceRenewal: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/insurances/renewals`,
  reportSecurityCoveredRatios: `${URL.REACT_APP_BACKEND_HOST}/analytics/v1/reports/security/coverratio`,
  sendInspectionFilledData: `${URL.REACT_APP_BACKEND_HOST}/los/inspections`,
  getInspectionFilledPendingDate: `${URL.REACT_APP_BACKEND_HOST}/los/inspections?applicationId=`,
  downloadInspectionForm: `${URL.REACT_APP_BACKEND_HOST}/file-serve`,
  getScoreCardGroup: `${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/get`,
  applicationJsonData: `${URL.REACT_APP_BACKEND_HOST}/los/submit/application/json?applicationId=`,
  getScoreCardGroupRateFieldMapApi: `${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/map`,
  getScoreCardGroupRateFieldSelectedApi: `${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/map/selected`,
  updateScoreCardGroupRateMapApi: `${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards/group/map/update`,
  getSubWorkflows: `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow`,
  createSubWorkflowsReferral: `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/create/referral`,
  referralChangeAssignee: `${URL.REACT_APP_BACKEND_HOST}/los/referral/change-assignee`,
  forceChangeCurrentAssignee: `${URL.REACT_APP_BACKEND_HOST}/los/applications-force-assign`,
  forceChangeCurrentStage: `${URL.REACT_APP_BACKEND_HOST}/los/applications-force-stage-assign`
};
