import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { securityDocumentAlignTypes } from "../../../../../configs/constants/contants";

function CommonContentAlignType({ alignType, textAlignHandler }) {
  return (
    <>
      <SelectComponent label={"Align Type"} value={alignType} values={securityDocumentAlignTypes} onChange={(e) => textAlignHandler(e.target.value)} required={false} defaultPlaceholder={true} />
    </>
  );
}

export default CommonContentAlignType;
