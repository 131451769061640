import CloseIcon from "@mui/icons-material/Close";
import { ListSubheader, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { userName } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";


const TaskAssigneeModal = (params) => {
  const [checked, setChecked] = useState(null);


  const groupNameMapping = params.groupNames;

  const closeCondition = () => {
    closeGlobalModal();
  };

  const handleChange = (userId) => {
    if (checked === userId) {
      setChecked(null);
    } else {
      setChecked(userId);
    }
  };

  return (
    <>
      <div>
        <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
          Change Task Assignee
        </Typography>
        <Stack direction={"column"}>
          {Object.keys(params?.list ?? {})?.map((key, index) => (
            <div>
              <ListSubheader sx={{ backgroundColor: "lightgray" }}>
                {groupNameMapping[key]}
              </ListSubheader>
              {params.list[key].map((item) => (
                <Stack
                  direction={"row"}
                  py={2}
                  key={`item-${userName(item.userId)}-${userName(item.userId)}`}
                >
                  <CheckBoxComponent
                    checked={checked === item.userId}
                    disabled={false}
                    onChange={() => {
                      handleChange(item.userId);
                    }}
                  />
                  <Stack flex={1} justifyContent={"center"}>
                    {userName(item.userId).trim() ? userName(item.userId) : "N/A"}
                  </Stack>
                  <Stack> {item.userId} </Stack>
                </Stack>
              ))}
            </div>
          ))}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <ButtonComponent
            title="Close"
            startIcon={<CloseIcon />}
            variant="contained"
            onClick={closeCondition}
            loadingbtn={true}
            color="info"
          />
          <ButtonComponent
            title="Save"
            variant="contained"
            onClick={() => params.handleSaveAssignee(checked)}
            loadingbtn={true}
            color="info"
          />
        </Stack>
      </div>
    </>
  );
};

export default TaskAssigneeModal;
