import { Downgraded, useState } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import { userDetails } from "../../../../configs/mainStore";
import { addScoreCardGroup } from "../../../../services/apiCalls";
import { isPrivilegeHave } from "../../../../services/utilService";
import { responseSuccessCode } from "../../../../utility/other";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SwitchComponent } from "../../../InputComponents/SwitchComponent/SwitchComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";

const ScoreCardGroupFormLayout = ({ title, selectData, scoreCardGroupName, langArray, isUpdate, lang }) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();

  const [publish, setPublish] = React.useState<any>(true);
  const [name, setName] = React.useState<any>("");
  const [description, setDescription] = React.useState<any>("");

  const loading = useState(false);

  const onSave = async (e, isUpdate) => {
    let res: any = {};

    let isValid = false;

    let langData: any = {};

    if (name !== "" && description !== "") {
      langData[lang] = {
        lang: lang,
        scoreCardGroupName: name,
        scoreCardGroupDescription: description,
      };

      if (isUpdate) {
        // res = await updateScoreCardGroup({
        //   publish: publish,
        //   descriptions: langData,
        //   scoreCardGroupId: selectScoreCardGroup.attach(Downgraded).get().id,
        // });
      } else {
        res = await addScoreCardGroup({
          publish: publish,
          payload: langData,
        });
      }
    }

    if (res?.status == responseSuccessCode) {
      addToaster({ status: "success", title: "Success", message: "Score Card Created" });
      closeGlobalModal();
    } else {
      addToaster({ status: "error", title: "Error", message: "Error Creating Score Card Group" });
    }
  };

  return (
    <Box p={1}>
      <Grid container className="align-center" pl={1} pr={1}>
        <Grid item xs={12} className="align-center">
          <Box className="screen_description--basic">
            <Stack direction="row" alignItems="center" spacing={2}>
              <p
                className="basic-font basic-font-color font-size-20 font-weight-600"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                {isUpdate ? `Update` : `Create`} Score Card Group
              </p>
              <p
                className="basic-font basic-font-color font-size-16 font-weight-100"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                {isUpdate && `(${scoreCardGroupName})`}
              </p>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container p={1}>
        <Grid item xs={12}>
          <Box style={{ marginTop: 8, marginBottom: 8 }}>
            <div
              className="basic-font basic-font-color font-size-16 font-weight-100"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <Stack direction="row" alignItems="center">
                <div> Published </div>
                <SwitchComponent
                  commonStatus={true}
                  checked={publish}
                  onChange={(e) => {
                    setPublish(!publish);
                  }}
                  disabled={loading.get()}
                />
              </Stack>
            </div>
          </Box>
        </Grid>

        <Grid container spacing={1} mt={1} rowSpacing={1}>
          <Grid item xs={12}>
            <Box style={{ marginTop: 8, marginBottom: 8 }}>
              <div
                className="basic-font basic-font-color font-size-16 font-weight-100"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                <span> Name </span>
                <TextBoxComponent
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  multiline={true}
                  disabled={loading.get()}
                />
              </div>
            </Box>
            <Box style={{ marginTop: 8, marginBottom: 8 }}>
              <div
                className="basic-font basic-font-color font-size-16 font-weight-100"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                <span> Description</span>
                <TextBoxComponent
                  value={description}
                  onChange={(e: any) => setDescription(e.target.value)}
                  multiline={true}
                  disabled={loading.get()}
                  sx={{ fontSize: 16 }}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
        <Stack direction={'row'} flex={1} mt={4} spacing={2}>
          <Stack flex={1}></Stack>
          <ButtonComponent
            title={isUpdate ? "Update Score Card Group" : "Add New Score Card Group"}
            variant="contained"
            onClick={(e) => onSave(e, isUpdate)}
            disabled={loading.get() || !isPrivilegeHave(userPrivilege, privilegeKeys.CERATE_NEW_WF_BUTTON)}
            loading={loading.get()}
          />
          <ButtonComponent
            variant={"outlined"}
            disabled={loading.get()}
            title={"Close"}
            onClick={() => {
              closeGlobalModal();
            }}
          />
        </Stack>
      </Grid>{" "}
    </Box>
  );
};

export default ScoreCardGroupFormLayout;
