import { Downgraded, useState } from "@hookstate/core";
import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { getAppraisalData, saveAppraisalData } from "../../../../../../../../services/creditFileApiCall";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { addToaster } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { disableAccess } from "../../../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { AnalysisOfLoanApplicantVersionOne } from "../AnalysisLoanApplicant/AnalysisOfLoanApplicantVersionOne";
import { AnalysisOfLoanApplicantVersionTwo } from "../AnalysisLoanApplicant/AnalysisOfLoanApplicantVersionTwo";

const AnalysisLoanApplicantWrapper = (props) => {
  const { data, applicationId, innerComponentData, tabsToEdit } = props;

  const isLoading = useState(true);
  const appraisalData: any = useState({});
  const [version, setVersion]: any = React.useState(2);

  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  useEffect(() => {
    getAnalysisLoanApplicatantData();
    return () => { };
  }, []);

  const getAnalysisLoanApplicatantData = async () => {
    const params = {
      applicationId: applicationId,
      section: "analysis_loan_applicant",
    };
    const response: any = await getAppraisalData(params);

    const { loanApplicantList, bankRelation, version } = response;

    if (version) {
      setVersion(parseInt(version));
    } else if (loanApplicantList || bankRelation) {
      setVersion(1);
    } else {
      setVersion(2);
    }
    appraisalData.set(response || {});

    isLoading.set(false);
  };

  const changeLoanApplicantData = async (userId, appraisalData = {}) => {
    let analysisLoanApplicantData: any = appraisalData;

    let dataObj: any = {};

    if (version != 2) {
      analysisLoanApplicantData = {
        ...appraisalData,
        userId,
        createdBy: userId,
        created: new Date(),
      };

      dataObj = {
        bankRelation: analysisLoanApplicantData.bankRelation,
        loanApplicantList: [analysisLoanApplicantData],
      };
    } else {
      dataObj = {
        version: 2,
        ...appraisalData,
        createdBy: userId,
        createdAt: new Date(),
      };
    }

    const res: any = await saveAppraisalData({
      section: "analysis_loan_applicant",
      applicationId: applicationId,
      data: dataObj,
    });

    if (res?.status == responseSuccessCode) {
      if (version != 2) {
        // setLoanApplicantList([...loanApplicantList, analysisLoanApplicantData]);
        // analysisLoanApplicantDetails.set(JSON.parse(JSON.stringify(analysisLoanApplicantDefaultValues)));
      } else {
        // analysisLoanApplicantDetailsNewVersion.set(JSON.parse(JSON.stringify(analysisLoanApplicantDefaultValuesNewVersion)));
      }

      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg || "Data saved successfully!",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }

    return res?.data || {};
  };

  const params = {
    changeLoanApplicantData,
    formData: data?.formData,
    currentAssignee: data?.currentAssignee,
    disabled: checkPrivilegeStatus,
    version,
    appraisalData: appraisalData.attach(Downgraded).get(),
  };

  const versionMap = {
    1: AnalysisOfLoanApplicantVersionOne,
    2: AnalysisOfLoanApplicantVersionTwo,
  };

  const Header = () => {
    return (
      <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 102, top: "0pt" }} justifyContent={"center"}>
        <Typography className="basic-font basic-font-color-bold">Analysis of the Loan Applicant</Typography>
      </Stack>
    );
  };

  if (isLoading?.get()) {
    return (
      <Paper>
        <Stack>
          <Header />
          <Stack height={"50vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
            <CircularProgressComponent size={30} />
          </Stack>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper>
      <Stack>
        <Header />
        {React.createElement(versionMap[version], { ...params })}
      </Stack>
    </Paper>
  );
};

export default AnalysisLoanApplicantWrapper;
