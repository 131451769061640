import React, { memo } from "react";

const Application = () => {
  return (
    <>
      {/* <div id="1" style={{ height: '100px' }}>Identification Details </div>
      <div id="2" style={{ height: '100px' }}>Product and Loan Information </div>
      <div id="3" style={{ height: '100px' }}>Contact Details </div>
      <div id="4" style={{ height: '100px' }}>Address Information </div>
      <div id="5" style={{ height: '100px' }}>Educational Information </div>
      <div id="6" style={{ height: '100px' }}>Employment Details </div>
      <div id="7" style={{ height: '100px' }}>Business Details </div>
      <div id="8" style={{ height: '100px' }}>Asset Details </div>
      <div id="9" style={{ height: '100px' }}>Income and Expenses Details</div>
      <div id="10" style={{ height: '100px' }}>Bank Details </div>
      <div id="11" style={{ height: '100px' }}>Liability Details </div> */}
      Render Application Here
      <button>Some thing</button>
    </>
  );
};

export default Application;
