import { addToaster } from "../components/OtherComponents/GlobalToast";
import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
import URL from "../configs/connection";

const api = Api.getApi();

const smsTemplateListUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/sms-template-list?languageId=`;
const addNewSmsTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/add-new-sms-template`;
const smsTemplateByIdUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/sms-template-by-id?templateId=`;
const editSmsTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/edit-sms-template`;
const smsTemplateDefByIdLanguageUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/sms-definition-by-template-id-language?`;
const smsTemplateDefLanguagesByIdUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/available-sms-definition-lan-by-template-id?`;
const addNewSmsTemplateDefinitionUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/add-new-sms-definition`;
const editSmsDefinitionUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/edit-sms-definition`;
const templateFiledMappingListUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/template-field-list?languageId=`;
const createNewPlaceholder = `${URL.REACT_APP_BACKEND_HOST}/los/create/placeholder`;
const updatePlaceholder = `${URL.REACT_APP_BACKEND_HOST}/los/update/placeholder`;

export const getSmsTemplateList = async (languageId) => {
  try {
    const data = await api.get(`${smsTemplateListUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addNewSmsTemplateApi = async (isActive: any, templateData: any) => {
  try {
    const data = await api.post(`${addNewSmsTemplateUrl}`, {
      isActive: isActive ? 1 : 0,
      templateData,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSmsTemplateById = async (templateId) => {
  try {
    const data = await api.get(`${smsTemplateByIdUrl}${templateId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const editSmsTemplateApi = async (templateId: any, isActive: any, templateData: any) => {
  try {
    const data = await api.patch(`${editSmsTemplateUrl}`, { templateId, isActive: isActive ? 1 : 0, templateData });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSmsTemplateDefLanguagesById = async (templateId) => {
  try {
    const data = await api.get(`${smsTemplateDefLanguagesByIdUrl}templateId=${templateId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSmsTemplateDefByIdLanguage = async (templateId, languageId) => {
  try {
    const data = await api.get(`${smsTemplateDefByIdLanguageUrl}templateId=${templateId}&languageId=${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addNewSmsTemplateDefinitionApi = async (templateId: any, languageId: any) => {
  try {
    const data = await api.post(`${addNewSmsTemplateDefinitionUrl}`, {
      templateId,
      languageId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    } else {
      let msg = "Something went Wrong!";

      if (data?.data?.errorMessage) {
        msg = data?.data?.errorMessage;
      }

      addToaster({
        status: "error",
        title: "Update Error",
        message: msg,
      });
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const editSmsDefinitionApi = async (templateId: any, languageId: any, definition: any) => {
  try {
    const data = await api.patch(`${editSmsDefinitionUrl}`, { templateId, languageId, definition });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getTemplateFiledMappingList = async (languageId) => {
  try {
    const data = await api.get(`${templateFiledMappingListUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createNewPlaceholderApi = async (isActive: any, templateData: any) => {
  try {
    const data = await api.post(`${createNewPlaceholder}`, {
      isActive: isActive ? 1 : 0,
      templateData,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updatePlaceholderApi = async (params) => {
  try {
    const data = await api.patch(`${updatePlaceholder}`, params);

    return data;
  } catch (error) {
    return null;
  }
};
