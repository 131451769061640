import { useHookstate } from "@hookstate/core";
import { Divider, Stack, Typography } from "@mui/material";
import React, { Suspense, lazy } from "react";
import Loading from "react-loading";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { toast } from "../../../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { productDetails } from "../../../configs/mainStore";
import { createProductFromCatalog } from "../../../services/productApis";

export default (props) => {
    const CloseIcon = lazy(() => import("@mui/icons-material/Close"));
    const [loading, setLoading] = React.useState(false);
    const productMasterData: any = useHookstate(productDetails);

    if (!props.catalog) {
        return <></>;
    }

    const [type, sector, scheme] = (props.catalog.includes("-")) ? props.catalog?.split("-") : props.catalog?.split(".");

    const productResolveMap = {
        productType: (type) =>
            productMasterData?.productTypesMap?.get()?.[type]?.["TYPE_NAME"],
        productSector: (sector) =>
            productMasterData?.productSectorsMap?.get()?.[sector]?.["TYPE_NAME"],
        productScheme: (scheme) =>
            productMasterData?.productSchemesMap?.get()?.[scheme]?.["TYPE_NAME"],
    }

    const createProduct = async () => {
        let payload = { message: "Product created successfully.", status: "success" };

        try {
            setLoading(true);
            props.setUpdating(props.catalog);
            const data = await createProductFromCatalog({ ...props });
            props.callback(data);
        } catch (error) {
            payload.status = "error";
            payload.message = "Failed to create new product.";
        } finally {
            toast(payload);
            setLoading(false);
            props.setUpdating("");
        }
    }

    return (
        <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="subtitle2">
                    {"Create New Product"}
                </Typography>
                <Suspense fallback={<Loading></Loading>}>
                    <CloseIcon
                        onClick={closeGlobalModal}
                    />
                </Suspense>
            </Stack>
            <Divider></Divider>
            <Stack>
                <Typography color={"gray"}>
                    New product will be created to {productResolveMap.productType(type)} {productResolveMap.productSector(sector)} {productResolveMap.productScheme(scheme)}
                </Typography>
            </Stack>
            {!loading ? <Stack direction={"row-reverse"} spacing={2}>
                <ButtonComponent size={"small"} variant={"outlined"} onClick={createProduct} title={"Create"}></ButtonComponent>
                <ButtonComponent variant={"contained"} onClick={closeGlobalModal} title={"Cancel"}></ButtonComponent>
            </Stack> : <Stack direction={"row-reverse"} spacing={2}><CircularProgressComponent size={25} /></Stack>}
        </Stack>
    );
}