import { Downgraded, useState } from "@hookstate/core";
import { Box, Chip, Divider, List, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { masterDataDetails, productDetails, renderingDetails, userDetails } from "../../../../../configs/mainStore";
import { getAssociatedLoans } from "../../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../../services/currencyFormater";
import { CollateralDetails } from "../../../ApplicationLeadManagement/Application/Helpers/isHaveCollateralIntermediate";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const BuildLoansList = ({ items }) => {
    const productMasterData: any = useState(productDetails);
    const masterData: any = useState(masterDataDetails);
    const renderState: any = useState(renderingDetails);
    const userData = userDetails.attach(Downgraded).get();

    const handleApplicationClick = async (applicationId, status, userId) => {
        if (status == 2) {
            if (applicationId) {
                sessionStorage.currentApplication = applicationId;
                window.location.reload();
            }
        } else {
            addToaster({
                status: "error",
                title: "Application Error",
                message: "Application is Not Submitted!",
            });
        }
    };

    return (
        <List>
            {[
                items.map((item) => {
                    const type =
                        productMasterData?.productTypesMap?.get()?.[item?.productType && item?.productType]?.["TYPE_NAME"];
                    const sector =
                        productMasterData?.productSectorsMap?.get()?.[item?.productType && item?.sector]?.["TYPE_NAME"];
                    const scheme =
                        productMasterData?.productSchemesMap?.get()?.[item?.productType && item?.scheme]?.["TYPE_NAME"];

                    const fundSource = masterData?.FUND_SOURCE?.get()?.find((t) => t.id == item?.fundSource);

                    const listView = JSON.parse(item.listView);

                    return (
                        <Stack>
                            <Stack px={2} py={2}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            Facility:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">{scheme}</Typography>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} mt={2} spacing={2}>
                                        {/* <Typography variant="subtitle2" color={"gray"}>
                      Security:{" "}
                    </Typography> */}
                                        {item?.collateral != null && item?.collateral != "" && item?.collateral != "{}" ? (
                                            <CollateralDetails key={item?.applicantId} collateral={item?.collateral} label={"Secured"} />
                                        ) : (
                                            <Chip size="small" label={"Unsecured"}></Chip>
                                        )}
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} mt={2} spacing={2}>
                                        <Box
                                            onClick={() => handleApplicationClick(item?.applicationId, item.status, item.currentAssignee)}
                                            className={`${item.applicationId !== "-"
                                                ? "application-manager_application-id basic-font font-size-14"
                                                : "application-manager_application-dash basic-font font-size-14"
                                                }`}
                                            style={{ textAlign: "left" }}
                                        >
                                            {item.applicationId}
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} mt={2}>
                                    <Stack spacing={2} direction={"row"} flex={1}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            Loan Amount:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">{formatCurrency(item.loanAmount, 2)}</Typography>
                                    </Stack>
                                    <Stack spacing={2} direction={"row"} flex={1}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            Rate:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {listView.interestRate ? `${listView.interestRate} %` : "-"}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={2} direction={"row"} flex={1}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            Terms:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">{listView?.loanTerms ?? "-"}</Typography>
                                    </Stack>
                                    <Stack spacing={2} direction={"row"} flex={1}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            LTV:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">{"-"}</Typography>
                                    </Stack>
                                    <Stack spacing={2} direction={"row"} flex={1}>
                                        <Typography variant="subtitle2" color={"gray"}>
                                            Funding:{" "}
                                        </Typography>
                                        <Typography variant="subtitle2">{fundSource.name ?? "-"}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Divider></Divider>
                        </Stack>
                    );
                }),
            ]}
        </List>
    );
};

const AssociatedLoans = (props) => {
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setItems([]);

            const response = await getAssociatedLoans(props?.applicationId);

            if (response?.status === 1) {
                const {
                    data: { data: _data },
                } = response;

                setItems(_data);
            } else {
                setItems([]);
            }
            setLoading(false);
        })();
    }, [props]);

    return (
        <Stack>
            <Stack style={{ backgroundColor: "#e6f0fa", height: "40pt" }} justifyContent={"center"} px={2}>
                <Typography>Loans</Typography>
            </Stack>
            {loading === true && (
                <CircularProgressComponent size={30} sx={{ left: "0", right: "0", margin: "1rem auto 0" }} />
            )}
            {items.length === 0 && (
                <ErrorMessageComponent headMessage={"Empty"} errorMessage={"No Associated Loans found!"} />
            )}
            <BuildLoansList items={items}></BuildLoansList>
        </Stack>
    );
};

export default AssociatedLoans;
