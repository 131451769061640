import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import moment from "moment";
import { masterDataDetails } from "../../../../../configs/mainStore";
import { currentEmployedDuration, formattedDate, previousEmployedDuration } from "../../../../../utility/fomatters";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import { CurrentEmployments } from "./Employment/CurrentEmployment";
import { EmploymentExperience } from "./Employment/DetailsHeader";
import { PreviousEmployeements } from "./Employment/PreviousEmployments";

const EmploymentDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const employedData: any = data?.formData?.employedData || {};
  const { applicationLastUpdatedDate } = data;
  const deprecatedDateFormatterToggle = moment(formattedDate("1/2/2024")).isAfter(applicationLastUpdatedDate);

  const getValidDate = (date) => {
    if (deprecatedDateFormatterToggle) {
      return date;
    } else {
      return formattedDate(date);
    }
  };

  const currentEmpData: any[] = [];
  const previousEmpData: any[] = [];

  if (employedData.currentEmployment != null) {
    if (employedData.currentEmployment.length > 0) {
      employedData.currentEmployment
        ?.filter((obj) => obj)
        ?.filter((obj) => !obj.removedItem)
        ?.map((obj: any) => {
          obj.employmentTimePeriod = currentEmployedDuration(getValidDate(obj.startDate));
          currentEmpData.push(obj);
        });
    }
  }

  if (employedData.previousEmployment != null) {
    if (employedData.previousEmployment.length > 0) {
      employedData.previousEmployment
        ?.filter((obj) => obj)
        ?.filter((obj) => !obj.removedItem)
        ?.map((obj: any) => {
          obj.employmentTimePeriod = previousEmployedDuration(obj.startDate, obj.endDate);
          previousEmpData.push(obj);
        });
    }
  }

  const currentEmployeements = employedData?.currentEmployment;
  const previousEmployments = employedData?.previousEmployment;

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Employment Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
            {!isMainContactDetails && (
              <>
                <Grid container style={{ backgroundColor: "#e6f0fa" }} className="align-center" mb={1.5}>
                  <Grid item xs={12}>
                    <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">Employment Details</div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
              {/* BASIC DETAILS */}
              {employedData?.experience ? <EmploymentExperience experience={employedData.experience} /> : <></>}

              <Grid container>
                {/* CURRENT EMPLOYEEMENT */}
                <CurrentEmployments currentEmployeements={currentEmployeements} applicationLastUpdatedDate={applicationLastUpdatedDate} />

                {/* PREVIOUS EMPLOYEEMENTS */}
                {<PreviousEmployeements previousEmployeements={previousEmployments} applicationLastUpdatedDate={applicationLastUpdatedDate} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmploymentDetails;
