import { useState } from '@hookstate/core'
import ApparaisalTabularComponent from '../../Helpers/HrizontalTabPanel/HorizontalTabPanel'
import MainHeader from '../../Helpers/MainHeader/MainHeader'
import BalanceSheet from './Components/BalanceSheet/BalanceSheet'
import ProfitAndLossAccountsType from './Components/ProfitAndLossAccountsType/ProfitAndLossAccountsType'

const AuditedFinancialsDetails = ({ applicationId }) => {
  const currentTabKey = useState(0);

  let financialPositionOfTheCompanyTitle = {
    0: "Profit and Loss Accounts Data",
    1: "Balance Sheet",
  }

  const componentsMapping = {
    0: ProfitAndLossAccountsType,
    1: BalanceSheet,
  }

  const props = { title: financialPositionOfTheCompanyTitle, currentHorizintalTabKey: currentTabKey, allTabComponents: componentsMapping, applicationId, onChange: (event, newKey) => { currentTabKey.set(newKey) }, formData: "", isAmountBelowTwoPointFive: false, onSaveChanges: () => { } }
  
  return (
    <MainHeader title={"Audited Financials Details"} onSubmit={undefined}>
      <ApparaisalTabularComponent {...props} />
    </MainHeader>
  )
}

export default AuditedFinancialsDetails
