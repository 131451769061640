import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { ButtonComponent } from '../components/InputComponents/ButtonComponent/ButtonComponent';
import { closeGlobalModal } from '../components/OtherComponents/GlobalModal/GlobalModal';

declare global {
    interface Window {
        JSONEditor: any;
    }
}

const JsonEditorComponent = ({ jsonData, options }) => {
    const editorRef: any = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://cdn.jsdelivr.net/npm/jsoneditor@latest/dist/jsoneditor.min.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/jsoneditor@latest/dist/jsoneditor.min.js';
        script.async = true;
        script.onload = () => {
            editorRef.current = new window.JSONEditor(containerRef.current!, options);
            editorRef.current.set(jsonData);
        };
        document.body.appendChild(script);

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
            }
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, [jsonData]);

    return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />;
};

export const JsonEditorComponentModal = (props) => {
    return <Stack height={"calc(100vh - 300px)"}>
        <JsonEditorComponent jsonData={props?.jsonData} options={props?.options}></JsonEditorComponent>
        <Stack direction={"row-reverse"} mt={2}>
            <ButtonComponent onClick={() => closeGlobalModal()} title={"Close"}></ButtonComponent>
            <ButtonComponent onClick={() => navigator.clipboard?.writeText(JSON.stringify(props?.jsonData || "{}"))} title={"Copy"}></ButtonComponent>
        </Stack>
    </Stack>
};

export default JsonEditorComponent;
