export const tmDictionary = {
  language: 'மொழி',
  back: 'மீண்டும்',
  published: 'வெளியிடப்பட்டது',
  unpublished: 'வெளியிடப்படவில்லை',
  saveChanges: 'மாற்றங்களை சேமியுங்கள்',
  updated: 'புதுப்பிக்கப்பட்டது',
  updateFail: 'புதுப்பித்தல் தோல்வியடைந்தது',
  error: 'பிழை',
  authModule: {
    login: 'உள்நுழைய',
    loginSubTxt: 'தொடர பயனர்பெயர் மற்றும் கடவுச்சொல்லை உள்ளிடவும்',
    username: 'பயனர் பெயர்',
    password: 'கடவுச்சொல்',
    enterUsername: 'பயனர்பெயரை உள்ளிடவும்',
    enterPassword: 'கடவுச்சொல்லை உள்ளிடவும்',
    continue: 'தொடரவும்',
    pleaseFill: 'தேவையான புலங்களை நிரப்பவும்!',
  },
  smsModule: {
    head: 'எஸ்எம்எஸ் டெம்ப்ளேட்கள்',
    createSms: 'எஸ்எம்எஸ் டெம்ப்ளேட்டை உருவாக்கவும்',
    form: {
      createSmsTitle: 'புதிய SMS டெம்ப்ளேட்டை உருவாக்கவும்',
      published: 'வெளியிடப்பட்டது',
      enName: 'ஆங்கிலப் பெயர்',
      enDesc: 'ஆங்கில விளக்கம்',
      siName: 'சிங்களப் பெயர்',
      siDesc: 'சிங்கள விளக்கம்',
      tmName: 'தமிழ் பெயர்',
      tmDesc: 'தமிழ் விளக்கம்',
      createBtn: 'புதிய டெம்ப்ளேட்டைச் சேர்க்கவும்',
      editSmsTitle: 'எஸ்எம்எஸ் டெம்ப்ளேட்டை மாற்றவும்',
      editBtn: 'டெம்ப்ளேட்டை மாற்றவும்',
      updated: 'டெம்ப்ளேட் புதுப்பிக்கப்பட்டது',
      newAdded: 'புதிய டெம்ப்ளேட் செருகப்பட்டது',
    },
    placeholders: 'ப்ளாஸ்ஹோல்டர்கள்',
    message: 'செய்தி',
    placeholdersIns: 'உங்களுக்கு விருப்பமான ப்ளேஸ்ஹோல்டர்களைத் தேர்ந்தெடுக்கவும்',
    tmpUpdated: 'டெம்ப்ளேட் புதுப்பிக்கப்பட்டது',
  },
  errorMsg: {
    required: 'தேவை!',
    requiredAlert: 'தேவையான புலங்களை நிரப்பவும்',
    updateError: 'புதுப்பித்தல் பிழை',
    someError: 'ஏதோ தவறு நடைபெற்றிருக்கிறது',
    notFoundTmp: 'டெம்ப்ளேட் ஐடி கிடைக்கவில்லை',
    noData: 'தகவல் இல்லை!',
    emptyTmp: 'வெற்று டெம்ப்ளேட் பட்டியல்',
  },
};
