import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../services/onDemandValidators";
import { generateUniqueId, getCopy } from "../../../../../../../../../utility/util";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import AlertDialogBox from "../../../../../../../AlertComponent/AlertComponent";

const GridDataTable = ({ openModal, setOpenModal, sourceArray, children, defaultDataObject, actionReset, headerTitle, addButtonInvisible = false }: any) => {
  const [amount, setAmount] = useState(0);
  const [numberOfDays, setNumberOfDays] = useState(0);

  useEffect(() => {
    const worker = new Worker(new URL('../Workers/workingCapital.ts', import.meta.url));

    if (sourceArray.get() instanceof Array) {
      worker.postMessage(getCopy(sourceArray.value));

      worker.onmessage = function (e) {
        const { amount, numberOfDays } = e.data;
        setAmount(amount);
        setNumberOfDays(numberOfDays);
      };
    }

    return () => {
      worker.terminate();
    }
  }, [sourceArray]);

  const onNewFieldAdding = () => {
    if (!addButtonInvisible) {
      sourceArray.merge([{ ...defaultDataObject, dataId: generateUniqueId() }]);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleOk = () => {
    sourceArray?.set([getCopy(defaultDataObject)]);
    if (actionReset) {
      actionReset();
    }
    setOpenModal(false);
  };

  return (
    <>
      <Grid container rowSpacing={1.5}>
        <Grid item xs={12} md={12}>
          <Grid container gap={1}>
            {headerTitle.map((title) => (
              <Grid item xs={2}>
                <Typography>{title}</Typography>
              </Grid>
            ))}
            <Grid item xs={2}>
              {!addButtonInvisible ? (
                <Stack direction={"row"}>
                  <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={onNewFieldAdding} />
                </Stack>
              ) : <></>}

            </Grid>
          </Grid>
        </Grid>
        {children}
        <Grid item xs={12} md={12}>
          <Grid container gap={1}>
            <Grid item xs={4} md={4} style={{ marginRight: "8px" }}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={"Total"} value={""} fullWidth={true} onChange={(e) => { }} disabled={true} />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={numberOfDays} fullWidth={true} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={formatCurrency(amount)} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialogBox open={openModal} handleClose={handleCancel} handleOk={handleOk} mainTitle={""} subTitle={"If you select OK, you will be lost every thing that you entered."} description={"Select OK to continue."} />
    </>
  );
};

export default GridDataTable;
