import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import { addItem } from "./Helpers";

export const BusinessAddress = ({ address }) => {
  const masterDataStore = MasterDataProvider.provideMasterData();

  const province = masterDataStore?.getMasterDataDescription(
    MASTER_DATA_KEYS?.PROVINCE,
    address?.province
  );
  const district = masterDataStore?.getMasterDataDescription(
    MASTER_DATA_KEYS?.DISTRICT,
    address?.district
  );
  const gnDivision = masterDataStore?.getMasterDataDescription(
    MASTER_DATA_KEYS?.GN_DIVISION,
    address?.gnDivision ?? address?.empGnDivision
  );
  const dsDivision = masterDataStore?.getMasterDataDescription(
    MASTER_DATA_KEYS?.DS_DIVISION,
    address?.dsDivision
  );

  return (
    <MandatoryFilterComponent
      items={address}
      mandatory={["addressLine1", "addressLine2"] as never[]}
      options={{ title: "Address Details" }}>
      <Grid container item p={1} spacing={1} className="data-box">
        {addItem(address, "addressLine1", address?.addressLine1 ? address?.addressLine1 : "N/A")}
        {addItem(address, "addressLine2", address?.addressLine2 ? address?.addressLine2 : "N/A")}
        {addItem(address, "addressLine3", address?.addressLine3 ? address?.addressLine3 : "N/A")}
        {addItem(address, "addressLine4", address?.addressLine4 ? address?.addressLine4 : "N/A")}
        {addItem(address, "city", address?.city ? address?.city : "N/A")}
        {addItem(address, "district", district ? district : "N/A")}
        {addItem(address, "postalCode", address?.postalCode ? address?.postalCode : "N/A")}
        {addItem(address, "province", province ? province : "N/A")}
      </Grid>
    </MandatoryFilterComponent>
  );
};
