import { useEffect, useState } from "react";
import AppConfig from "../utility/AppConfig";

String.prototype.startsWith || (String.prototype.startsWith = function (word) {
    return this.lastIndexOf(word, 0) === 0;
});

String.prototype.endsWith || (String.prototype.endsWith = function (word) {
    return this.indexOf(word, this.length - word.length) !== -1;
});

export default class CachingStorage {
    private cacheMap = {};
    private static instance = new CachingStorage();
    private static requestLock = {};
    static Keys = {
        STAGE_DE: "stageDE",
        STAGE_ORDER: "stagesOrder",
        REPORTS: "reports",
        INSPECTION_USER_LIST: "inspectionUserList",
        EXPIRED: "accessexpired"
    }

    constructor() {
        this.cacheMap = {};
    }

    public static cache(key) {
        return CachingStorage.instance.cacheMap[key];
    }

    public static read(key) {
        try {
            let data = CachingStorage.instance.cacheMap[key];
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            return data;
        } catch (error) {
            return null;
        }
    }

    public static store(key, data) {
        try {
            if (typeof data === 'string' && data.includes(":")) {
                data = JSON.parse(data);
            }
            CachingStorage.instance.cacheMap[key] = data;
        } catch (error) {
            console.log(error);
        }
    }

    public static async invoke(key, fn) {
        try {
            let _data = CachingStorage.read(key);

            if (_data) {
                return _data;
            }

            if (CachingStorage.requestLock[key]) {
                return;
            }

            CachingStorage.requestLock[key] = fn;
            _data = await fn();
            delete CachingStorage.requestLock[key];

            if (_data?.data) {
                _data = _data?.data || _data;

                if (typeof _data === "string") {
                    _data = JSON.parse(_data);
                }
            }

            if (_data) {
                CachingStorage.store(key, _data);
                if (AppConfig.control) {
                    sessionStorage.setItem(key, JSON.stringify(_data));
                }
            }
            return _data;
        } catch (error) {
            console.log(error);
            delete CachingStorage.requestLock[key];
            return null;
        }
    }

    public static useInvoke(key, fn) {
        const [lock, setLock] = useState<boolean>(true);
        const [data, setData] = useState<any>();
        const [requesting, setRequesting] = useState<boolean>(true);

        useEffect(() => {
            let _data = CachingStorage.read(key);
            if (_data) {
                commit(_data);
                return;
            }

            if (CachingStorage.requestLock[key]) {
                lock && setRequesting(true);
                return;
            }

            CachingStorage.requestLock[key] = fn;

            lock && requestData();

            return () => {
                setLock(false);
            };
        }, [key]);

        const requestData = async () => {
            let _data: any = null;
            try {
                _data = await fn();
                _data = _data?.data || _data;
            } catch (error) {

            } finally {
                CachingStorage.store(key, _data);

                commit(_data);
                delete CachingStorage.requestLock[key];
            }
        }

        const commit = (_data) => {
            lock && setData(_data);
            lock && setRequesting(false);
        }

        return { requesting, data };
    }

    public static clear(key) {
        if (key.includes("*")) {
            for (const [ckey, value] of Object.entries(CachingStorage.instance.cacheMap)) {
                if (ckey.startsWith(key.replaceAll("*", ""))) {
                    delete CachingStorage.instance.cacheMap[ckey];
                }
            }
        } else {
            delete CachingStorage.instance.cacheMap[key];
        }
    }

    public static clearAll() {
        CachingStorage.instance.cacheMap = {};
    }
}