import { Downgraded } from "@hookstate/core";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler, validateWithStructure } from "./structureValidation";

export const expenseDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState) => {
  let employementSetionState: any[] = [];
  let finalKeyArr: string[] = [];

  if (isGuarantor || isJointBorrower) {
    employementSetionState = participantState?.employedData?.currentEmployment?.attach(Downgraded).get() || [];
    let employmentTypes: boolean[] = employementSetionState.map((employement) => Number(employement?.employmentStatus || 0) === 2).filter((item: boolean) => item === true)
    let availableExpenses = state?.expenses.filter((expenses) => {

      if (expenses?.expenseType.get()) {
        return expenses;
      }
    });

    if (employmentTypes.length && !availableExpenses.length) {
      return [];
    }
  }

  if (state?.expenses?.get().length < 1) {
    return messageMappings["expenseType"];
  }

  let errorArray = validateWithStructure(structure, state);
  let errArrInArrays = formStateArrayTypeHandler({ structure, state: state?.expenses });

  finalKeyArr = errArrInArrays.length ? [...errorArray, ...errArrInArrays] : errorArray.length ? errorArray : [];

  const preparedErrorMessages = messageMappings(removeDuplicates(finalKeyArr));
  return preparedErrorMessages;
};

const removeDuplicates = (array: any) => {
  return array.reduce((acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]), []);
};
