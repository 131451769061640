import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../../../../../CommunicationService/CMTextInput";
import { closeGlobalModal } from "../../../../../../../GlobalModal/GlobalModal";

const RequirementComment = (props) => {
  const { callBackOnSave } = props;

  const [comment, setComment]: any = React.useState("");

  const commentChangeHandler = (value: any = "") => {
    setComment(value);
  };

  return (
    <Stack>
      <Stack>
        <Typography color={"gray"} variant="subtitle1">Loan Requirement</Typography>
      </Stack>
      <Divider></Divider>
      <Stack mt={2}>
        <CMTextInput
          desc={"Comment"}
          component={
            <TextBoxComponent
              onChange={(e) => {
                commentChangeHandler(e.target.value);
              }}
              multiline={true}
              minRows={5}
              value={comment}
              disabled={false}
            />
          }
          error={false}
        />
      </Stack>
      <Stack direction={"row-reverse"} mt={2} spacing={2}>
        <ButtonComponent title="Close" onClick={closeGlobalModal} variant="outlined" />
        <ButtonComponent title="Save" onClick={() => callBackOnSave(comment)} variant="contained" />
      </Stack>
    </Stack>
  );
};

export default RequirementComment;
