import { createRef, FC, useEffect, useState } from "react";

import { Box } from "@mui/material";

import { Downgraded, useHookstate } from "@hookstate/core";
import { creditData, multipleDisbursementState } from "../../../configs/mainStore";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import {
  getProductApplicationType,
} from "../../../services/documentUploadService";
import { ErrorMessageComponent } from "../ErrorMessageComponent";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { ProductDocumentUploadSubSection } from "./ProductDocumentUploadSubSection";

interface Props {
  applicationId: any;
  productId: any;
  applicantType: any;
  documentTypeId?: any;
  documentSectionName?: any;
  documentComponentId?: any;
  participantId?: any;
  personalData?: Object;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  reloadComponent?: any;
  isSecurityDocumentUpload?: any;
  currentWorkflowStageId?: any;
  participantType?: any;
}

export const ProductDocumentUploadComponent: FC<Props> = ({
  applicationId,
  productId,
  applicantType,
  documentTypeId,
  documentSectionName,
  documentComponentId,
  participantId,
  personalData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  reloadComponent,
  isLoggedInUserNotTheAssignee,
  participantType,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [documentTypes, setDocumentTypes] = useState<any>([]);
  const [uploadedDocumentTypes, setuploadedDocumentTypes] = useState<any>([]);
  const [mandatoryCount, setMandatoryCount] = useState<any>("");
  const [uploadedMandatoryCount, setUploadedMandatoryCount] = useState<any>("");
  const [documentCount, setDocumentCount] = useState<any>("");

  const creditFileData: any = creditData.attach(Downgraded).get();
  const keyString = `${creditFileData?.formData?.creditData?.type}-${creditFileData?.formData?.creditData?.sector}-${creditFileData?.formData?.creditData?.scheme}`;
  let tabList: any = [];
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const multipleDisbursement: any = useHookstate(multipleDisbursementState);

  useEffect(() => {
    setErrorMessage("");
    (async () => {
      loadDocuments();
    })();
  }, [productId, applicantType, documentTypeId]);

  let multipleDisbursementDocument = () => {

    if (multipleDisbursement.isHaveMultipleDisbursement.get()) {
      documentTypes.forEach(obj => {
        const matchingObject = obj.primary.find(item => item.documentKey === 'RECEIPT');

        const correspondingObject = documentDetailsList[102];

        let foundPending = false;

        Object.keys(multipleDisbursement.disbSchedule.get()).forEach(key => {
          const disbursement = multipleDisbursement.disbSchedule.get()[key];

          if (!foundPending && disbursement.status === 'Pending' || disbursement.status === 'Complete') {
            const modifiedCorrespondingObject = { ...correspondingObject };
            modifiedCorrespondingObject.EN = { ...correspondingObject.EN, name: `Loan Receipt - S${key}` };

            const combinedKeys = `${correspondingObject.documentKey}_${key}.pdf`;
            const foundFile = uploadedDocumentTypes.find(item => item.fileName === combinedKeys);

            const fileName = foundFile ? combinedKeys : '';

            tabList.push({
              ...modifiedCorrespondingObject,
              individualId: matchingObject?.individualId,
              isMandatory: 1,
              fileName,
              scheduleId: Number(key),
              ref: createRef(),
              disbStatus: disbursement.status
            });

            if (disbursement.status === 'Pending') {
              foundPending = true;
            }
          }
        });

        if (Array.isArray(obj.primary)) {
          obj.primary = obj.primary.filter(item => item.documentKey !== 'RECEIPT');
        }
      });
    }
    return tabList;
  };

  const loadDocuments = async () => {
    let resFileData = await getProductApplicationType(
      applicationId,
      documentTypeId,
      participantId,
      keyString,
      participantType
    );

    let mandatoryCount = 0;
    const filterUploadedMandatory: any = [];

    if (resFileData && Object.keys(resFileData).length > 0) {
      setIsLoading(false);
      setuploadedDocumentTypes(resFileData?.selectedApplicationDocuments);
      setDocumentTypes(resFileData?.selectedDocumentTypes);

      if (resFileData?.selectedDocumentTypes?.length > 0) {
        const selectedDocumentTypes = resFileData?.selectedDocumentTypes[0];

        let documentlist: any = [];

        for (const obj in selectedDocumentTypes) {
          if (selectedDocumentTypes[obj].map) {
            selectedDocumentTypes[obj].map((item) => {
              documentlist.push(item);
              if (item.isMandatory === 1) {
                mandatoryCount++;
              }
            });
          }
        }

        for (const obj1 of documentlist) {
          let matched = false;
          for (const obj2 of resFileData?.selectedApplicationDocuments) {
            if (obj1.individualId === obj2.individualId && obj1.documentId === obj2.documentId) {
              matched = true;
              break;
            } else if (obj1.individualId === undefined && obj1.documentId === obj2.documentId) {
              matched = true;
              break;
            }
          }
          if (matched) {
            filterUploadedMandatory.push(obj1);
          }
        }

        setDocumentCount(documentlist?.length);
      }

      setMandatoryCount(mandatoryCount);
      setUploadedMandatoryCount(filterUploadedMandatory?.length);
    } else {
      setErrorMessage("Something went Wrong!");
    }
  }

  const reloadContent = () => {
    loadDocuments();
    reloadComponent(true);
  }

  return (
    <Box sx={{ textAlign: "center" }}>
      {isLoading ? (
        errorMessage ? (
          <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
        ) : (
          <CircularProgressComponent
            size={30}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 100,
            }}
          />
        )
      ) : (
        <ProductDocumentUploadSubSection
          applicationId={applicationId}
          applicantType={applicantType}
          documentTypes={documentTypes}
          uploadedDocumentTypes={uploadedDocumentTypes}
          documentSectionName={documentSectionName}
          mandatoryCount={mandatoryCount}
          uploadedMandatoryCount={uploadedMandatoryCount}
          participantId={participantId}
          documentComponentId={documentComponentId}
          personalData={personalData}
          creditFileData={creditFileData}
          onSyncSuccess={onSyncSuccess}
          onSync={onSync}
          sectionLoading={sectionLoading}
          disbursed={disbursed}
          disableAccordingToTab={disableAccordingToTab}
          reloadComponent={reloadContent}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
          documentCount={documentCount}
          multipleDisbursementDocument={multipleDisbursementDocument()}
        />
      )}
    </Box>
  );
};
