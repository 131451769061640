import { Paper, Table, TableContainer } from "@mui/material";
import React from "react";

type styles = React.CSSProperties

const TableWrapper = ({ children, noBorder = false }) => {

  let styles = noBorder ? {} : { border: "1px solid #e6f0fa", borderRadius: "5px" }

  return (
    <TableContainer component={Paper} elevation={0} style={{ paddingTop: "10px", paddingBottom: "10px", height: "100%" }}>
      <Table style={{ margin: "5px 0", ...styles }}>{children}</Table>
    </TableContainer>
  );
};
export default TableWrapper;
