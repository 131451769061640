import React, { useEffect } from "react";

import { Downgraded, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { followUpChecklistStatus } from "../../../../../configs/constants/contants";
import {
  appSetting,
  creditData,
  userDetails
} from "../../../../../configs/mainStore";
import {
  followupChecklistItemStatusChange,
  getFollowUpChecklistList,
} from "../../../../../services/creditFileApiCall";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { dateFormatWithDate } from "../../../../../utility/fomatters";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { IconRowComponent } from "../IconRowComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { getUser } from "../../../../../utility/helpers/creditFileUtility";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";

const FollowUpChecklist = ({ data, applicationId, innerComponentData }) => {
  const appSettings: any = useState(appSetting);
  const masterDataStore = MasterDataProvider.provideMasterData();

  const [isLoading, setIsLoading] = React.useState(true);
  const [doneChecklistList, setDoneCheckList] = React.useState<any>();
  const [pendingChecklistList, setPendingCheckList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isChanged, setIsChanged] = React.useState(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getFollowUpChecklistList(applicationId);

      if (data) {
        setDoneCheckList(data?.fundReleaseDoneList);
        setPendingCheckList(data?.fundReleasePendingList);
      } else {
        setErrorMessage("Something went Wrong!");
      }
      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const statusUpdate = async (itemId, isChecked, setIsChecked) => {
    setIsChecked(!isChecked);

    const result = await followupChecklistItemStatusChange(
      applicationId,
      itemId,
      isChecked
    );

    if (result?.msg) {
      addToaster({
        status: "success",
        title: "Updated",
        message: "Check List Item Updated",
      });
    } else if (result?.error) {
      addToaster({
        status: "warning",
        title: "Not Updated",
        message: "Update Error",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went Wrong!",
      });
    }
  };

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
        disabled={
          getIsDisbursed(
            privilegeKeys.TASKS_TAB_CREATE_FOLLOW_CHECK_LIST,
            disbursed
          ) || isLoggedInUserNotTheAssignee
        }
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent
              headMessage={"Error!"}
              errorMessage={errorMessage}
            />
          ) : pendingChecklistList?.length > 0 ||
            doneChecklistList?.length > 0 ? (
            <>
              {pendingChecklistList?.map((item, i) => {
                return (
                  <FollowUpChecklistRow
                    itemObj={item}
                    statusUpdate={statusUpdate}
                    key={i}
                    isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                  />
                );
              })}
              {doneChecklistList?.map((item, i) => {
                return (
                  <FollowUpChecklistRow
                    itemObj={item}
                    statusUpdate={statusUpdate}
                    key={i}
                    isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                  />
                );
              })}
            </>
          ) : (
            <ErrorMessageComponent
              headMessage={"No data available"}
              errorMessage={""}
            />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

const FollowUpChecklistRow = ({
  isLoggedInUserNotTheAssignee,
  itemObj,
  statusUpdate,
}) => {
  const [isChecked, setIsChecked] = React.useState<any>(false);

  useEffect(() => {
    itemObj?.status === followUpChecklistStatus.done
      ? setIsChecked(true)
      : setIsChecked(false);
  }, []);

  const InfoRow = () => {
    return (
      <Stack
        direction="row"
        flex={1}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        mt={2}
        justifyContent={"flex-end"}
      >
        <IconRowComponent
          Icon={isChecked ? DoneIcon : CloseIcon}
          subTxt={"Status"}
          mainTxt={isChecked ? "Done" : "Pending"}
          color={isChecked ? "#39a21a" : "#ff1b1b"}
        />
        <IconRowComponent
          Icon={InsertInvitationIcon}
          subTxt={"Created Date"}
          mainTxt={
            itemObj?.createdDate
              ? dateFormatWithDate(itemObj?.createdDate)
              : "--"
          }
          color={isChecked ? "#39a21a" : "#ff1b1b"}
        />
        <IconRowComponent
          Icon={InsertInvitationIcon}
          subTxt={"Completed Date"}
          mainTxt={
            itemObj?.completedDate
              ? dateFormatWithDate(itemObj?.completedDate)
              : "--"
          }
          color={isChecked ? "#39a21a" : "#ff1b1b"}
        />
      </Stack>
    );
  };

  const InfoDetailsRow = () => {
    const renderUser = (userId) => {
      return getUser(userId)?.name ?? "";
    };

    return (
      <Stack
        direction="row"
        flex={1}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={4}
        mt={2}
        justifyContent={"flex-end"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography color={"grey"} variant={"caption"}>
            {"Created By:"}
          </Typography>
          <Typography variant={"caption"}>
            {renderUser(itemObj.createdBy)}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography color={"grey"} variant={"caption"}>
            {"Created At:"}
          </Typography>
          <Typography variant={"caption"}>
            {itemObj?.createdDate
              ? dateFormatWithDate(itemObj?.createdDate)
              : "--"}
          </Typography>
        </Stack>
        {itemObj?.completedDate && (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography variant={"caption"} color={"grey"}>
              {"Completed At:"}
            </Typography>
            <Typography variant={"caption"}>
              {itemObj?.completedDate
                ? dateFormatWithDate(itemObj?.completedDate)
                : "--"}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  return (
    <Box>
      <Grid
        className="row_list_item"
        style={{
          boxShadow: `0px 0px 10px ${isChecked ? "#39a21a" : "#ff1b1b"}3d`,
        }}
        container
        direction="column"
        p={2}
      >
        <Grid container direction="row">
          <Grid container style={{ textAlign: "left" }} alignItems={"center"}>
            <CheckBoxComponent
              checked={isChecked}
              onChange={() => {
                statusUpdate(itemObj?.id, isChecked, setIsChecked);
              }}
              disabled={
                getIsDisbursed(
                  privilegeKeys.TASKS_TAB_UPDATE_FOLLOW_CHECK_LIST_CHECK,
                  disbursed
                ) || isLoggedInUserNotTheAssignee
              }
            />
            <Grid flex={1}>
              <IconRowComponent
                subTxt={""}
                mainTxt={itemObj?.itemName}
                color={isChecked ? "#39a21a" : "#ff1b1b"}
              />
            </Grid>

            <IconRowComponent
              Icon={isChecked ? DoneIcon : CloseIcon}
              subTxt={""}
              mainTxt={isChecked ? "Done" : "Pending"}
              color={isChecked ? "#39a21a" : "#ff1b1b"}
            />
          </Grid>

          <InfoDetailsRow></InfoDetailsRow>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FollowUpChecklist;
