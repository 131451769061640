import React, { FC, useEffect } from "react";
import { useState } from "@hookstate/core";

import { Box } from "@mui/material";

import { ProductDocumentUploadComponent } from "../components/OtherComponents/SecurityDocumentUploadComponent/ProductDocumentUploadComponent";
import { documentUploadData } from "../configs/stores/document-upload-store/documentUploadStore";
import { getDocumentDetailsList } from "../services/documentUploadService";
import { CircularProgressComponent } from "../components/OtherComponents/ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../components/OtherComponents/ErrorMessageComponent";
import { useTranslation } from "react-i18next";
import RiskDocumentsList from "../components/OtherComponents/CreditFile/Components/Risk/RiskDocumentsList";

interface SecurityDocumentUploadLayoutPropTypes {
  applicationId?: any;
  productId?: any;
  applicantType?: any;
  documentTypeId?: any;
  documentSectionName?: any;
  documentComponentId?: any;
  participantId?: any;
  personalData?: Object;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  isSecurityDocumentUpload?: any;
  currentWorkflowStageId?: any;
  participantType?: any;
}

function SecurityDocumentUploadLayout({
  applicationId,
  productId,
  applicantType,
  documentTypeId,
  documentSectionName,
  documentComponentId,
  participantId,
  personalData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  isLoggedInUserNotTheAssignee,
  participantType,
}: SecurityDocumentUploadLayoutPropTypes) {
  const { t: translate } = useTranslation();
  const { documentDetailsList }: any = useState(documentUploadData);

  const [isLoading, setIsLoading] = React.useState(true);
  const [documentDetailItemList, setDocumentDetailItemList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    (async () => {
      let data;

      if (Object.values(documentDetailsList.get()).length <= 0) {
        data = await getDocumentDetailsList();
      } else {
        setDocumentDetailItemList(documentDetailsList.get());
        setIsLoading(false);
      }

      if (data) {
        documentDetailsList.set(data);
        setDocumentDetailItemList(data);
        setIsLoading(false);
      } else {
        setErrorMessage("Something went Wrong!");
      }
    })();
  }, []);

  const reloadComponent = async (element?: any) => {
    setIsLoading(true);
    let data: any;

    if (Object.values(documentDetailsList.get()).length <= 0) {
      data = await getDocumentDetailsList();
    } else {
      setDocumentDetailItemList(documentDetailsList.get());
      setIsLoading(false);
    }

    if (data) {
      documentDetailsList.set(data);
      setDocumentDetailItemList(data);
      setIsLoading(false);
    } else {
      setErrorMessage("Something went Wrong!");
    }
  };
  // console.log("===documentDetailItemList===", documentDetailItemList)
  return (
    <Box m={1}>
      {isLoading ? (
        errorMessage ? (
          <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
        ) : (
          <CircularProgressComponent
            size={30}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 100,
            }}
          />
        )
      ) : Object.keys(documentDetailItemList).length > -1 ? (
        <ProductDocumentUploadComponent
          applicationId={applicationId}
          productId={productId}
          applicantType={applicantType}
          documentTypeId={documentTypeId}
          documentSectionName={documentSectionName}
          documentComponentId={documentComponentId}
          participantId={participantId}
          personalData={personalData}
          onSyncSuccess={onSyncSuccess}
          onSync={onSync}
          sectionLoading={sectionLoading}
          disbursed={disbursed}
          disableAccordingToTab={disableAccordingToTab}
          reloadComponent={reloadComponent}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
          participantType={participantType}
        />
      ) : (
        <ErrorMessageComponent
          headMessage={translate("errorMsg.noData")}
          errorMessage={"Empty Document List"}
        />
      )}
    </Box>
  );
}

export default SecurityDocumentUploadLayout;
