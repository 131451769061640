import { Stack, TableCell } from "@mui/material";
import React, { memo, useState } from "react";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { formattedDate, standardDateFormatter } from "../../../../../../../../utility/fomatters";

interface TableColumnsDatePickerWrapperProps {
  value: any;
  index: string | number;
  title: string;
  data?: any;
  style?: any;
  dataKey?: any;
  onchangeHandler?: any;
  dataIndex?: any;
}

const TableColumnsDatePickerWrapperNew: React.FC<TableColumnsDatePickerWrapperProps> = ({ index, value, title, data = {}, dataKey = "", dataIndex, onchangeHandler = () => {} }: TableColumnsDatePickerWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  const [inputValue, setInputValue] = useState(value?.get());

  const handleValueChange = (value) => {
    setInputValue(value);
    onchangeHandler(value, dataKey, dataIndex);
  };

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <ValidateDatePicker
            label={"Issued Date"}
            value={formattedDate(inputValue)}
            onChange={(date) => {
              handleValueChange(standardDateFormatter(date));
            }}
            enableFuture={false}
          />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default memo(TableColumnsDatePickerWrapperNew);
