import { FC } from "react";
import Chip from "@mui/material/Chip";
import { ChipsPropTypes } from "../../../configs/interfaces";
import { Tooltip } from "@mui/material";

export const ChipsComponent: FC<ChipsPropTypes> = ({
  avatar,
  children,
  classes,
  clickable,
  color,
  component,
  deleteIcon,
  disabled,
  icon,
  label,
  onDelete,
  size,
  sx,
  variant,
  onClick
}) => {
  return (
    <Tooltip title={label}>
      <Chip
        label={label}
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
        avatar={avatar}
        classes={classes}
        clickable={clickable}
        color={color}
        component={component}
        disabled={disabled}
        icon={icon}
        size={size}
        sx={sx}
        variant={variant}
        children={children}
      />
    </Tooltip>
  );
};
