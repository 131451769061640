import AddCircleIcon from "@mui/icons-material/AddCircle";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, Grid } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import Dropzone from "react-dropzone";

import { useState } from "@hookstate/core";
import { FILE_UPLOAD_COMPONENT_STATUS } from "../../../configs/constants/documentUploadKeys";
import { appSetting } from "../../../configs/mainStore";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import { addToaster } from "../GlobalToast";
import { LinearProgressWithLabel } from "../LinearWithValueLabel/LinearWithValueLabel";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";

interface FileUploadComponentProps {
  fileDetails: any;
  uploadDataObject: any;
  isPreviewHave?: Boolean;
  fileUploadApi?: any;
  referralSeqId?: number;
}

export type Ref = any;

export const FileUploadComponent = forwardRef<Ref, FileUploadComponentProps>(
  (
    { fileDetails, uploadDataObject, isPreviewHave, fileUploadApi, referralSeqId }: FileUploadComponentProps,
    ref: any
  ) => {
    const appSettings: any = useState(appSetting);
    const currentRef = fileDetails?.ref;

    const [imgStatus, setImgStatus] = React.useState(FILE_UPLOAD_COMPONENT_STATUS.noFile);
    const [file, setFile] = React.useState<any>();
    const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isRequiredFilled, setIsRequiredFilled] = React.useState(true);
    const [previewLoading, setPreviewLoading] = React.useState(false);
    const [closeClicked, setCloseClicked] = React.useState(false);

    const { previewedDocument }: any = useState(documentUploadData);

    useEffect(() => {
      if (isPreviewHave && !closeClicked) {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);
      }

      if (referralSeqId) {
        uploadDataObject.referralSeqId = referralSeqId;
      }
    }, [referralSeqId]);

    useEffect(() => {
      // removeSelectedImg();
    }, []);

    useImperativeHandle(currentRef, () => ({
      uploadSingleImg() {
        return uploadDocumentData().then((res) => {
          return res;
        });
      },
    }));

    const onDrop = useCallback((acceptedFiles) => {
      setFileUploadProgress(0);
      if (acceptedFiles.length === 1) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );

        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.pending);
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    }, []);

    const removeSelectedImg = () => {
      setFile(null);
      setFileUploadProgress(0);

      if (isPreviewHave) {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    };

    const errorMessage = (fileRejections: any) => {
      if (fileRejections.length === 1) {
        fileRejections[0].errors.map((item) => {
          addToaster({
            status: "error",
            title: "File Error",
            message: item?.message,
          });
        });
      }
    };
    const uploadDocumentData = () => {
      setIsUploading(true);

      if (file && fileUploadApi) {
        return fileUploadApi(uploadDataObject, file, setFileUploadProgress).then((data) => {
          if (data?.status) {
            setIsUploading(false);
            setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);

            setIsUploading(false);
            return { status: true, data: data?.data, message: "Success" };
          } else {
            if (fileDetails.isMandatory) {
              setIsRequiredFilled(false);
            }

            setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
            setFile(null);

            setIsUploading(false);
            return { status: false, message: data?.message };
          }
        });
      } else {
        if (isPreviewHave) {
          setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);
        } else {
          if (fileDetails.isMandatory) {
            setIsRequiredFilled(false);
          }

          setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
          setFile(null);
        }
        setIsUploading(false);
        return Promise.resolve({ status: false, message: "Empty Data" });
      }
    };

    const renderPlaceholders = () => {
      if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile) {
        return (
          <Dropzone
            accept={fileDetails?.allowedFormats}
            maxFiles={1}
            multiple={false}
            maxSize={fileDetails?.maxFileSize}
            onDropRejected={(fileRejections) => errorMessage(fileRejections)}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <Grid
                  style={{ height: "125px", cursor: "pointer" }}
                  container
                  item
                  // spacing={1}
                  direction="row"
                  sm={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sm={12}>
                    <input {...getInputProps()} />
                    <AddCircleIcon style={{ color: "#4386be", fontSize: 40 }} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Dropzone>
        );
      } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending) {
        return (
          <>
            {isUploading ? (
              <Grid
                style={{
                  backgroundColor: "#dde9f3",
                  borderRadius: "5px",

                  height: "125px",
                  border: "1px solid #4386be99",
                }}
                container
                item
                direction="row"
                sm={12}
                alignItems="end"
                justifyContent="center"
              >
                <Grid item sm={11} sx={{ mb: "10px" }}>
                  <LinearProgressWithLabel value={fileUploadProgress} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                style={{
                  backgroundColor: "#dde9f3ed",
                  borderRadius: "5px",

                  height: "125px",
                  border: "1px solid #4386be99",
                }}
                container
                item
                direction="row"
                sm={12}
                alignItems="start"
                justifyContent="center"
              >
                <Grid item sm={12} style={{ textAlign: "end" }}>
                  <DisabledByDefaultIcon
                    onClick={() => {
                      removeSelectedImg();
                    }}
                    style={{ color: "#4386be", fontSize: 30, cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted) {
        return (
          <Grid
            style={{ height: "125px", position: "relative" }}
            container
            item
            direction="row"
            sm={12}
            alignItems="center"
            justifyContent="center"
          >
            <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
              <Dropzone
                accept={fileDetails?.allowedFormats}
                maxFiles={1}
                multiple={false}
                maxSize={fileDetails?.maxFileSize}
                onDropRejected={(fileRejections) => errorMessage(fileRejections)}
                onDrop={(acceptedFiles) => {
                  setCloseClicked(true);
                  onDrop(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <section>
                      <div>
                        <input {...getInputProps()} />
                        <FileUploadIcon style={{ color: "#4386be", fontSize: 30, cursor: "pointer" }} />
                      </div>
                    </section>
                  </Box>
                )}
              </Dropzone>
            </Box>
            <Grid item sm={12}>
              {previewLoading ? (
                <CircularProgressComponent size={50} />
              ) : (
                <FindInPageIcon
                  style={{ color: "#4386be", fontSize: 40, cursor: "pointer" }}
                // onClick={() => previewClicked()}
                />
              )}
            </Grid>
          </Grid>
        );
      }
    };

    return (
      <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
        <Box
          style={
            imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile
              ? {
                borderRadius: "5px",
                backgroundColor: "#4386be2e",
                margin: "auto",
                border: `${isRequiredFilled ? "1px solid #4386be99" : "3px solid red"}`,
                textAlign: "center",
              }
              : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending
                ? {
                  borderRadius: "5px",
                  margin: "auto",
                  backgroundImage: `url(${file?.preview})`,
                  backgroundSize: "cover",
                }
                : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted
                  ? {
                    borderRadius: "5px",
                    backgroundColor: "#4386be2e",
                    margin: "auto",
                    border: "1px solid #4386be99",
                    textAlign: "center",
                  }
                  : {}
          }
        >
          {renderPlaceholders()}
        </Box>
      </Grid>
    );
  }
);
