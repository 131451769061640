import { Grid } from "@mui/material";

function CommonSecurityDocHeader({ documentName, textCase }) {
  return (
    <>
      <Grid container pt={1} className="border-radius-5">
        <img src="/images/rdb.png" alt="" width={200} style={{ margin: "auto" }} />
      </Grid>
      <Grid container pt={2} className="border-radius-5">
        <div className="basic-font-color-bold font-size-16" style={{ margin: "auto" }}>
          Pradeshiya Sanwardhana Bank
        </div>
      </Grid>
      <Grid container pb={5} mt={1} className="border-radius-5">
        <div className={`basic-font-color-bold font-size-18 ${textCase != null && textCase == "upper" ? "sec-document-uppercase-heading" : ""}`} style={{ margin: "auto" }}>
          {documentName || ""}
        </div>
      </Grid>
    </>
  );
}

export default CommonSecurityDocHeader;
