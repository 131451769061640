import { ERROR_CODES } from "../configs/constants/errorCode";
import Api from "./apiCallsHandler";
import URL from "../configs/connection";
const api = Api.getApi();

const getDocumentDetailsUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/document-list`;
const getProductDocumentDetailsUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/document-list-by-product`;
const getUploadedProductDocument = `${URL.REACT_APP_BACKEND_HOST}/document-management/get-application-document-list`;
const getProductApplicationTypeUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/document-list-by-application-type`;
const getDocumentTypeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/documents/catalog`;

export const getDocumentDetailsList = async () => {
  try {
    const data = await api.get(getDocumentDetailsUrl, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDocumentDataById = (documentDetailsList, temp) => {
  const documentList: any = Object.values(documentDetailsList);
  let objectYoBeReturned: any = {};

  if (documentList.length > 0) {
    if (temp?.documentId) {
      return documentList.find(
        (value: any) => value.documentId === temp?.documentId
      );
    } else {
      Object.keys(temp).map((key) => {
        if (temp[key] && temp[key].length > 0) {
          for (const object of temp[key]) {
            let temArray = getDocumentDataById(documentDetailsList, object);
            objectYoBeReturned = {...temArray};
          }
        }
      });
      return objectYoBeReturned;
    }
  } else {
    return [];
  }
};

export const getDocumentListByCatalogApplicant = async (
  productId,
  applicantType,
  documentTypeId
) => {
  try {
    const data = await api.post(getProductDocumentDetailsUrl, {
      productId,
      applicantType,
      documentTypeId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getUploadedDocumentInProduct = async (applicationId) => {
  try {
    const data = await api.post(getUploadedProductDocument, { applicationId });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const checkIfAlreadyUploaded = (
  documentId?: any,
  documentList?: any,
  individualId?: any
) => {
  let isHave = false;
  let status = 0;

  if (documentList) {
    for (let i = 0; i < documentList.length; i++) {
      if (individualId === undefined) {
        if (documentId === documentList[i]?.documentId) {
          isHave = true;
          status = documentList[i]?.status;
          break;
        }
      } else {
        if (
          documentId === documentList[i]?.documentId &&
          individualId === documentList[i]?.individualId
        ) {
          isHave = true;
          status = documentList[i]?.status;
          break;
        }
      }
    }
  }

  return { isHave, status };
};

export const checkIfAlreadyUploadedSecurityDoc = (
  documentId?: any,
  documentList?: any,
  individualId?: any,
  fileName?: any,
  scheduleId?: any,
  documentKey?: any,
) => {
  let isHave = false;
  let status = 0;

  const combinedKeys = `${documentKey}_${scheduleId}.pdf`;
  const foundFile = documentList.find(item => item.fileName === combinedKeys);
  
  if (documentList) {
    for (let i = 0; i < documentList.length; i++) {
      if (foundFile) {
        isHave = true;
        
        status = foundFile?.status;
      }
    }
  }

  return { isHave, status };
};

export const getProductApplicationType = async (
  applicationId?: any,
  documentTypeId?: any,
  participantId?: any,
  keyString?: any,
  participantType?: any
) => {
  try {
    const data = await api.get(getProductApplicationTypeUrl, {
      applicationId,
      documentTypeId,
      participantId,
      keyString,
      participantType,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDocumentType = async (params) => {
  try {
    const data = await api.get(getDocumentTypeUrl, { ...params });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
