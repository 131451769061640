/* eslint-disable react-hooks/exhaustive-deps */
import { Downgraded, useHookstate, useState } from "@hookstate/core";
import { Paper, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessageComponent } from "../../../../../../../../ErrorMessageComponent";
import { toast } from "../../../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../../../ProgressComponent/ProgressComponent";
import {
  getBalanceSheet,
  getInspectionBusinesData,
  saveBalanceSheetData,
} from "../../../../Api/AppraisalApi";
import SubSectionHeader from "../../../../Helpers/SubSectionHeader/SubSectionHeader";
import { GernaralizedComprehensiveTitleArray, gernaralizedComprehensiveTitleArray } from "../../../../Stores/MicroAppraisalDefaultData";
import { AssetOutPutDataObject, DataOfYears, balanceSheetSetStateFromTheBusinessData } from "../../Helpers/SetStateFromBusinessData/BalanceSheet";
import BalanceSheetBuilder from "./BalanceSheetBuilder";

const BalanceSheet = ({ applicationId, inspectionBusinessData }) => {
  const { t: translate } = useTranslation();
  const formData = useHookstate<Record<string, AssetOutPutDataObject>>({});
  const [allTableHeaderTitles, setAllTableHeaderTitles] = React.useState<GernaralizedComprehensiveTitleArray[]>([...gernaralizedComprehensiveTitleArray]);
  const isLoading = useState(true);

  useEffect(() => {
    getBalanceSheetData(applicationId);
  }, []);

  const onSaveForm = async () => {
    const { assetData, equity, liabilityData } = calCulateData;

    const dataTobeSave = {
      applicationId,
      balanceSheetData: {
        data: { ...JSON.parse(JSON.stringify(formData.value)) },
        totals: { totalAssets: assetData, totalLiabilities: liabilityData, equity },
        selectedYears: allTableHeaderTitles,
        isPublish: 0,
      },
    };

    const response = await saveBalanceSheetData(dataTobeSave);

    if (response.status === 1) toast({ status: "success", message: `${translate("balanceSheet")} saved successfully !` });

    else toast({ status: "error", message: `Error on Saving ${translate("balanceSheet")}!` });
  };

  const getBalanceSheetData = async (applicationId) => {
    let dataObjOfForm = {};
    try {
      const { data }: any = await getBalanceSheet(applicationId);
      if (Object.values(data).length) {
        dataObjOfForm = data.data;
        setAllTableHeaderTitles(data.selectedYears)
      }
      else {
        inspectionBusinessData = await getInspectionBusinesData(applicationId);
        dataObjOfForm = balanceSheetSetStateFromTheBusinessData({ busineeData: inspectionBusinessData });
      }
    } catch (error) {

    } finally {
      formData.set(dataObjOfForm)
      isLoading.set(false);
    }
  };

  // const additionalAssetsOrLiabilitiesHandler = () => {
  //   openGlobalModal({
  //     modalSize: "lg", bodyId: "balance-sheet-modal", close: false,
  //     modalParams: { assetDescription, liabilityDescription },
  //   });
  // }

  const calCulateData = useMemo(() => {

    const specialCalForAssetDetail = () => {
      let { data = {} } = formData?.["assetData"].attach(Downgraded).get() || {};

      return Object.entries(data).reduce((totalObject, [dataKey, { data }]: any) => {
        const timeFrameKey = dataKey.split(".")[2] || 1;
        const prevData = totalObject[timeFrameKey] || {};

        allTableHeaderTitles.forEach(({ dataKey }: { dataKey: string | number }) => {
          if (parseFloat(dataKey.toString())) {
            prevData[dataKey] = Number(prevData?.[dataKey] || 0) + Number(data?.[dataKey] || 0);
          }
        });
        return { ...totalObject, [timeFrameKey]: prevData }
      }, {})
    }

    const calTotal = (key): Record<number, number> => {
      let { data = {} } = formData?.[key].attach(Downgraded).get() || {};

      return Object.values(data).reduce((total: Record<number, number>, { data }: any) => {
        allTableHeaderTitles.forEach(({ dataKey }: { dataKey: string | number }) => {
          if (parseFloat(dataKey.toString())) {
            total[dataKey] = Number(total?.[dataKey] || 0) + Number(data?.[dataKey] || 0);
          }
        });
        return total;
      }, {});
    };

    const calDefference = (assetData, liabilityData): DataOfYears => {
      return allTableHeaderTitles.reduce((defferenceObj: DataOfYears, currItem: GernaralizedComprehensiveTitleArray) => {
        defferenceObj[currItem.dataKey] = Number(assetData[currItem.dataKey] || 0) - Number(liabilityData[currItem.dataKey] || 0);

        return defferenceObj
      }, {})
    }

    let returnedToBe = { assetData: calTotal("assetData"), liabilityData: calTotal("liabilityData"), assetDataPartedCal: specialCalForAssetDetail() };

    return { equity: calDefference(returnedToBe.assetData, returnedToBe.liabilityData), ...returnedToBe };
  }, [formData.assetData.value, formData.liabilityData.value, allTableHeaderTitles]);

  if (!Object.keys(formData.get()).length) {
    return <ErrorMessageComponent headMessage={""} errorMessage={"No Data Avaible to Show"} showImg={false} />
  }

  return (
    <Stack width={"100%"} gap={2} component={Paper} elevation={0}>
      <SubSectionHeader
        isNeedDropdown={false}
        title={"Balance Sheet"}
        onSubmit={onSaveForm}
        dropDownProps={{
          options: [],
          profitAndLossAccounts: "",
        }}
      />
      {/* 
      <Stack direction={"row"} pt={2} alignItems={"center"} gap={2}>
        <ButtonComponent startIcon={<AddIcon />} onClick={additionalAssetsOrLiabilitiesHandler} variant={"contained"} title={"Add additional Assets or Liabilities"} />
      </Stack> */}

      {isLoading.get() ?
        <CircularProgressComponent size={30} sx={{ top: '50%', right: '50%', position: 'absolute', zIndex: 100 }} />
        :
        <BalanceSheetBuilder formData={formData} headerTitles={allTableHeaderTitles} calCulateData={calCulateData} />
      }

    </Stack>
  );
};

export default BalanceSheet;
