type InspectionExpensesType = { id: number, name: string }
export const widthDefault = "95%";

export const inspectionBusinessLiabilityExpensesCategories: InspectionExpensesType[] = [
    { id: 1, name: "Loan / Lease" },
    { id: 2, name: "Overdraft" },
    { id: -1, name: "No Other Liabilities" },
]

export const inspectionBusinessLiabilityExpensesType: InspectionExpensesType[] = [
    { id: 1, name: "Loan" },
    { id: 2, name: "Lease" },
    // { id: 3, name: "Overdraft" },
]