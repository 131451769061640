import React from "react";
import { Grid, Stack, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { useState } from "@hookstate/core";
import ContentExtractor from "../../../../../utility/helpers/ContentExtractor";

const APIItem = ({ data }) => {
  const { wf_system_users }: any = useState(workflowMasterData);
  const updatedDate = data.updatedDate
    ? dateFormatWithTimeChange(data.updatedDate)
    : "";
  const updatedBy = wf_system_users
    ?.get()
    ?.find((usr) => usr?.id == data?.updatedBy);

  const apiTitleMap = {
    createLoanAcc: "Create Loan Account API",
    addGuarantors: "Add Guarantors API",
    addLoanDetails: "Add Loan Details API",
    loanDisbursement: "Loan Disbursement API",
  };

  const ItemComponent = ({
    title,
    val,
    width = 4,
    error = false,
    response = null,
  }) => {
    return (
      <Grid item xs={24} sm={12} md={width}>
        <Stack>
          <div className="basic-font basic-font-color-bold  font-size-14">
            {val}
          </div>
          <Stack direction={"row"}>
            <div className="basic-font basic-font-color opacity-7 font-size-12">
              {" "}
              {title}{" "}
            </div>
            {error && (
              <>
                <ContentExtractor text={`${response}`}>
                  <Tooltip title={response ?? " "} className="align-center">
                    <InfoIcon
                      sx={{
                        color: "#FF0000",
                        fontSize: "18px",
                        marginLeft: "10px",
                      }}
                      onClick={() => { }}
                    />
                  </Tooltip>
                </ContentExtractor>
              </>
            )}
          </Stack>
        </Stack>
      </Grid>
    );
  };

  return (
    <Grid
      container
      columns={24}
      item
      spacing={1}
      style={{
        backgroundColor: `${data?.status == 1 ? "#1cd5963d" : "#ff90903d"}`,
        margin: "2px",
        borderRadius: "5px",
      }}
      p={1}
    >
      <ItemComponent
        title="API Name"
        val={apiTitleMap?.[data?.apiKey]}
        width={6}
      />
      <ItemComponent
        title="Status"
        val={data?.status == 1 ? "Success" : "Failure"}
      />
      <ItemComponent title="Last Update By" val={updatedBy?.fullName} />
      <ItemComponent title="Last Updated Time" val={updatedDate} width={6} />
      <ItemComponent
        title="Retry Count"
        val={data?.retryCount}
        error={data?.status === 0}
        response={data.response}
      />
    </Grid>
  );
};

export default APIItem;
