import { Box, Grid, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { closeBtn } from "../../../../../styles";
import { addToaster } from "../../../GlobalToast";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { Downgraded, useHookstate } from "@hookstate/core";
import { updateMasterDataTitle } from "../../../../../services/configurationApis";
import { createApplicationsHistory } from "../../../../../services/applicationHistoryApi";

const configuration = {
  title: undefined,
  id: undefined,
};

const MasterDataTitleEditModal = (params) => {
  const payload: any = useHookstate(configuration);
  const title = "Master Data Title";

  const closeDialog = () => {
    closeGlobalModal();
  };

  const actionProceed = async () => {
    let data: any = payload.attach(Downgraded).get();

    data.id = params.id;

    let alertPayload = {
      status: "error",
      title: "Failed",
      message: "Failed  ",
    };

    try {
      if (!data.id) {
        throw new Error("Required details missing.");
      }

      let response: any = await updateMasterDataTitle(data);
      if (response.status === 1) {
        alertPayload.status = "success";
        alertPayload.title = "Success";
        alertPayload.message = title + " Changed.";
      }

      try {
        createApplicationsHistory(0, `Update Master Data Title`, null, data, "/audit/log/masterdata/managemasterdata");
      } catch (error) {
        console.log(error);
      }
    } catch (error: any) {
      alertPayload.message = error.message;
    } finally {
      addToaster(alertPayload);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{title}</div>
      </Stack>
      <Stack>
        <CMTextInput
          desc={`Edit "${params.title}"`}
          component={
            <TextBoxComponent
              defaultValue={params.title}
              onChange={(e) => {
                payload.title.set(e.target.value);
              }}
              multiline={true}
              value={payload.title.get()}
            />
          }
        />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="end" pt={3} mt={2} pr={2} pb={1}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeDialog} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Change" variant="contained" onClick={actionProceed} loadingbtn={true} color="info" />
      </Stack>
    </Box>
  );
};

export default MasterDataTitleEditModal;
