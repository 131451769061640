export enum calculationType {
    "add" = "addition",
    "subs" = "subtraction",
    "multi" = "multiplication"
}



export class AutoGeneratedTableCalcaulations {

    private dataKeyDependancyArry: Array<string> = [];
    private calculationType: Array<calculationType> = [];

    constructor(dataKeyDependancyArry, calculationType) {
        this.calculationType = calculationType;
        this.dataKeyDependancyArry = dataKeyDependancyArry;
    }

    private addition(accumulator: number, newvalue: number): number {
        return Number(accumulator) + Number(newvalue);
    }

    private subtraction(accumulator: number, newvalue: number): number {
        return Number(accumulator) - Number(newvalue);
    }

    private multiplication(accumulator: number, newvalue: number) {
        return Number(accumulator) * Number(newvalue);
    }

    private intermediate(type) {

        const callingFn = {
            add: this.addition,
            subs: this.subtraction,
            multi: this.multiplication
        }

        return callingFn[type];
    }

    Output() {
        return this.dataKeyDependancyArry.reduce(this.intermediate(this.calculationType), 0);
    }



}