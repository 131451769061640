import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import React from "react";
import { masterDataDetails } from "../../../../../configs/mainStore";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";

const EducationalDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const eduData: any = data?.formData?.educationData;
  const higherEducationLevel = masterData?.EDUCATION_GRADE?.get()?.find((t) => t.id == eduData?.primaryEducationGrade);

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Education Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
              Education Details
            </Grid>

            <Grid container>
              <Grid container item p={1} className="data-box">
                <Grid item xs={12}>
                  <DetailItem title="Highest Level of education" value={higherEducationLevel?.name ? higherEducationLevel.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                </Grid>
              </Grid>

              <Grid container item p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5 sub-header-height">
                <div className="basic-font basic-font-color-bold font-size-16 align-left">Higher Education</div>
              </Grid>

              {eduData.awardTypes != null && eduData.awardTypes != undefined ? (
                <>
                  {eduData?.awardTypes?.length > 0 &&
                    eduData?.awardTypes
                      ?.filter((obj) => obj)
                      ?.filter((obj) => !obj.removedItem)
                      ?.map((awardType, index) => {
                        const awardTypeData = masterData?.AWARD_TYPE?.get()?.find((t) => t.id == awardType?.awardType);
                        const nvqLevel = masterData?.NVQ_TYPE?.get()?.find((t) => t.id == awardType?.nvqLevel);
                        return (
                          <Grid container key={index} className="data-box">
                            <Grid container item p={1}>
                              <Grid item xs={3}>
                                <DetailItem title="Award Type" value={awardTypeData?.name ? awardTypeData.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Award Name" value={awardType?.awardName ? awardType.awardName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Awarded Year" value={awardType?.awardYear ? awardType?.awardYear : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                            </Grid>
                            <Grid container item p={1}>
                              <Grid item xs={3}>
                                <DetailItem title="Course Duration" value={`${awardType?.awardDurationYears ? awardType?.awardDurationYears : 0} Years and ${awardType?.awardDurationMonths ? awardType?.awardDurationMonths : 0} Months`} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Institution Name" value={awardType?.instituteName ? awardType.instituteName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="NVQ Level" value={nvqLevel?.name ? nvqLevel.name : ""} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                </>
              ) : (
                <ErrorMessageComponent headMessage={""} errorMessage={"No Data Available"} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EducationalDetails;
