import { FC } from "react";
import { Box } from "@mui/system";
import { SUB_SECTION_WHITE_SPACE_VALUES, SUB_SECTION_WRAPPER_VALUES } from "../../../configs/constants/styleValues";
import { SubSectionName } from "../SubSectionName/SubSectionName";
import { Paper } from "@mui/material";
import { MainSectionsWrapperPropsTypes } from "../../../configs/interfaces";

export const SubSectionsWrapper: FC<MainSectionsWrapperPropsTypes> = ({ children, title = null }) => {
  return (
    <Box m={SUB_SECTION_WRAPPER_VALUES.margin} p={SUB_SECTION_WRAPPER_VALUES.padding}>
      <Paper>
        <Box m={SUB_SECTION_WHITE_SPACE_VALUES.margin} p={SUB_SECTION_WHITE_SPACE_VALUES.padding}>
          {title && <SubSectionName title={title} />}
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
