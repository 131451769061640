import { useState } from "@hookstate/core";
import moment from "moment";
import React from "react";
import CachingStorage from "../../../../../../../../../services/cacheStorage";
import { deleteMultipleInputItem } from "../../../../../../../../../services/multipleInputDelete";
import { select_basic } from "../../../../../../../../../styles";
import { userName } from "../../../../../../../../../utility/helpers/creditFileUtility";
import { MultipleInput, MultipleInputWrapper } from "../../../../../../../../InputComponents/MultipleInputFields/MultipleInputFieldsExtends";
import { SelectComponent } from "../../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateTimePicker from "../../../../../../../../InputComponents/ValidateTimePicker/ValidateTimePicker";
import ValidateDatePicker from "../../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FieldSectionWrapper } from "../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { InputFieldsStack } from "../../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../../InputGrid/InputGrid";
import { SubSectionsWrapper } from "../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import { inspectionOfficerData } from "../../Helpers/inspectionStores/dataStore";
import { inspectionOfficerDetails } from "../../Helpers/inspectionStores/inspectionMainStore";

type UserData = { id: number | string, name: string };

const InpectionFormOfficerDetails = ({ value, onChange, metaData, otherParams }) => {
  const valueSet = useState<any[]>(inspectionOfficerData);
  const defaultObject = JSON.parse(JSON.stringify(inspectionOfficerDetails));

  const getInspectionFormOfficerList: UserData[] = React.useMemo(() => {
    let { users = [] } = CachingStorage.read(CachingStorage.Keys.INSPECTION_USER_LIST) || {};
    return users.reduce((filteredUsers, id) => {
      return [...filteredUsers, { id, name: userName(id) }]
    }, [])
  }, [])

  const [recommendations, setRecommendations]: any[] = React.useState([
    { id: 1, name: "Recommendation One" },
    { id: 2, name: "Recommendation Two" },
  ]);

  return (
    <SubSectionsWrapper title={""}>
      <FieldSectionWrapper>
        <FieldSectionWrapper>
          <MultipleInputWrapper label={"Inspection Officer Details"} sourceArray={valueSet} defaultDataObject={defaultObject} mandatoryKeys={[]}>
            {valueSet
              .filter((detail) => !detail["removedItem"].get() === true)
              .map((detail: any, index) => {
                const inspectionDate = detail?.date.get() !== "" && detail?.date.get() !== null ? moment(detail?.date.get()) : "";
                const inspectionTime = detail?.time?.get() !== "" && detail?.time.get() !== null ? moment(detail?.time?.get()) : "";

                return (
                  <MultipleInput key={`InpectionFormOfficerDetails_${index}`} selectedItem={detail} index={index} deleteFunction={() => deleteMultipleInputItem(detail)}>
                    <InputFieldsStack>
                      <InputGrid visibility={1}>
                        <SelectComponent
                          label={"Inspection Officer"}
                          value={detail?.inspectionOfficer?.get()}
                          values={getInspectionFormOfficerList}
                          className={select_basic}
                          required={1}
                          onChange={(e) => {
                            detail?.inspectionOfficer?.set(e.target.value);
                          }}
                        />
                      </InputGrid>
                      <InputGrid visibility={1}>
                        <TextBoxComponent
                          label={"Inspection Id"}
                          value={detail?.inspectionId?.get()}
                          required={1}
                          onChange={(e) => {
                            detail?.inspectionId?.set(e.target.value);
                          }}
                        />
                      </InputGrid>
                      <InputGrid visibility={1}>
                        <TextBoxComponent
                          label={"Purpose of Inspection"}
                          value={detail?.purposeOfInspection?.get()}
                          required={1}
                          onChange={(e) => {
                            detail?.purposeOfInspection?.set(e.target.value);
                          }}
                        />
                      </InputGrid>
                      <InputGrid visibility={1}>
                        <ValidateDatePicker
                          label={"Inspection Date"}
                          required={1}
                          onChange={(date) => {
                            if (date) {
                              detail?.date?.set(date._d.toString());
                            }
                          }}
                          value={inspectionDate}
                        />
                      </InputGrid>
                      <InputGrid visibility={1}>
                        <ValidateTimePicker
                          label="Inspection Time"
                          value={inspectionTime}
                          required={1}
                          onChange={(date) => {
                            if (date) {
                              detail?.time?.set(date._d.toString());
                            }
                          }}
                        />
                      </InputGrid>
                      <InputGrid visibility={1}>
                        <SelectComponent
                          label={"Recommendation"}
                          value={detail?.recommendation?.get()}
                          values={recommendations}
                          className={select_basic}
                          required={1}
                          onChange={(e) => {
                            detail?.recommendation?.set(e.target.value);
                          }}
                        />
                      </InputGrid>
                    </InputFieldsStack>
                  </MultipleInput>
                );
              })}
          </MultipleInputWrapper>
        </FieldSectionWrapper>
      </FieldSectionWrapper>
    </SubSectionsWrapper>
  );
};

export default InpectionFormOfficerDetails;
