import { Downgraded } from "@hookstate/core";
import { workflowMasterData } from "../../configs/mainStore";

export const getCurrentWorkflowStage = (currentWorkflowStage) => {
  const { wf_stages }: any = workflowMasterData.attach(Downgraded).get();
  const stage = wf_stages.find((currentStage) => currentStage.id == currentWorkflowStage);
  // console.log('=== stage ===', workflowMasterData);

  return stage ? stage.name : null;
};

export const getCurrentWorkflow = (currentWorkflowStage) => {
  const { wf_stages }: any = workflowMasterData.attach(Downgraded).get();
  const stage = wf_stages.find((currentStage) => currentStage.id == currentWorkflowStage);

  return stage ? stage.name : null;
};

export const getParentWorkflowStage = (currentWorkflowStage) => {
  const { wf_parent_stages }: any = workflowMasterData.attach(Downgraded).get();
  const stage = wf_parent_stages.find((currentStage) => currentStage.id === currentWorkflowStage);
  return stage ? stage.name : null;
};
