import { Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";

const DatePickerBuilder = ({ value, onChange, metaData }) => {
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  useEffect(() => {
    if (!value[metaData?.dataKey]) {
      setValue(moment());
      value[metaData?.dataKey] = moment()
      onChange(metaData, value);
    }
  }, [])

  return (
    <Stack maxWidth={"100%"} px={1} pt={1}>
      <ValidateDatePicker
        label={metaData?.title}
        required={metaData?.render?.required ?? false}
        sx={{ width: "340px" }}
        onChange={(date) => {
          date = date ? date._d.toString() : null;
          setValue(date);
          value[metaData?.dataKey] = date
          onChange(metaData, value);
        }}
        value={moment(valueInput)}
      />
    </Stack>
  );
};

export default DatePickerBuilder;
