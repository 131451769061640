import { Downgraded, State } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Grid, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators";
import { getCopy } from "../../../../../../../../../../utility/util";
import { ButtonComponent } from "../../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import GridDataTable from "../../../../../FormBuilder/Tools/Helpers/Components/GridDataTable";
import { stock } from "../../../../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

export const WorkingCapital = ({ otherParams, data: stocksData }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { isAmountAboveTwoPointFiveAndEqual } = otherParams;
  const types = useRef(otherParams.typeOfStock);

  useEffect(() => {
    const downgradedStocksData = stocksData.attach(Downgraded).get()
    types.current = otherParams.typeOfStock.filter(({ id }) => !downgradedStocksData.map((item) => item?.type).includes(id));
  }, []);

  const handleReset = (stateData: State<any>) => {

    stateData?.set(prev => ({ ...prev, ...getCopy(stock) }))
  }

  const getStockTypes = ({ stock }) => {
    let availableTypes = getCopy(types.current || []);

    try {
      const selectedTypes = stocksData?.get()
        .filter((itemObj) => !itemObj?.removedItem)
        .filter((stockItem) => stockItem?.dataId !== stock.dataId.get())
        .map((item) => item?.type)
        .filter(Boolean);

      availableTypes = availableTypes.filter(({ id }) => !selectedTypes.includes(id));
    } catch (error) {
      console.error("Error in getStockTypes: ", error);
    } finally {
      return availableTypes;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <SubSectionsWrapper>
          <FieldSectionWrapper>
            <GridDataTable
              openModal={openModal}
              setOpenModal={setOpenModal}
              addButtonInvisible={stocksData?.get().filter((itemObj) => !itemObj?.removedItem?.get()).length === otherParams.typeOfStock.length}
              sourceArray={stocksData}
              defaultDataObject={JSON.parse(JSON.stringify(stock))}
              headerTitle={["Type", "Description", "Number of Days", "Amount",]}
            >
              {stocksData
                ?.filter((itemObj) => !itemObj?.removedItem?.get()).map((items, index) => {
                  const props = { otherParams, items, index, isAmountAboveTwoPointFiveAndEqual, stocksData, handleReset }
                  return <BuildLayout {...props} />
                })}
            </GridDataTable>
          </FieldSectionWrapper>
        </SubSectionsWrapper>
      </Grid>
    </Grid>
  );
};

const BuildLayout = ({ otherParams, items, index, isAmountAboveTwoPointFiveAndEqual, stocksData, handleReset }) => {

  const sanitizedStockTypes = React.useMemo(() => {
    const currentlySelectedTypes = stocksData?.get()
      .filter((itemObj) => !itemObj?.removedItem)
      .filter((stockItem) => stockItem?.dataId !== items.dataId.get())
      .map((item) => item?.type)
      .filter(Boolean);

    return otherParams.typeOfStock.filter(({ id }) => !currentlySelectedTypes.includes(id));
  }, [stocksData.get()])

  return <Grid item xs={12} md={12} key={items?.dataId.get() || index}>
    <Grid container gap={1}>
      <Grid item xs={2}>
        <SelectComponent
          values={sanitizedStockTypes}
          value={items?.type?.get()}
          onChange={(e) => items?.type?.set(e.target.value)}
          label={"Type"}
          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
        />
      </Grid>
      <Grid item xs={2}>
        <TextBoxComponent
          label={"Description"}
          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
          value={items?.description?.get()}
          fullWidth={true}
          onChange={(e) => items?.description?.set(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextBoxComponent
          label={"Number of Days"}
          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
          value={items?.numberOfDays?.get()}
          fullWidth={true}
          onChange={(e) => {
            if (e.target.value >= 0 && e.target.value <= 500) {
              items?.numberOfDays?.set(e.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextBoxComponent
          label={"Amount"}
          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
          value={formatCurrency(items?.amount?.get())}
          onInput={OnDemandValueFormatter.numeric}
          fullWidth={true}
          onChange={(e) => items?.amount?.set(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Stack direction={"row"}>
          <ButtonComponent
            iconbtn
            children={<RotateLeftIcon />}
            onClick={() => handleReset(items)}
          />
          {index !== 0 && <ButtonComponent
            iconbtn
            children={<DeleteIcon />}
            onClick={() => {
              stocksData.set((prev) => prev.filter((_, i) => i !== index));
            }}
          />}
        </Stack>
      </Grid>
    </Grid>
  </Grid>
}
